import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetAccessRulesQuery, useGetClientTagGroupsQuery, useGetPerkQuery, useGetSeatingAreasTablesQuery } from '@sevenrooms/core/api'
import { useParams } from '@sevenrooms/core/navigation'
import { Formats, legacyAdapter } from '@sevenrooms/core/timepiece'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { flattenAudience } from '@sevenrooms/mgr-access-rules-slideout/components/BookingChannels/BookingChannels.zod'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { CreateEditExclusivePerkForm } from './CreateEditExclusivePerkForm'

const EXCLUSIVE_AUDIENCE_HIERARCHY_NAME = 'Exclusive Access'

export function CreateEditExclusivePerk() {
  const today = legacyAdapter({ format: Formats.iso, value: new Date().toISOString() }, Formats.MonthDayYearByDash)

  const { perkId } = useParams<{ perkId?: string }>()
  const { venueId } = useVenueContext()

  const { data: perk, isLoading: isPerkLoading } = useGetPerkQuery(perkId ? { venueId, perkId } : skipToken)
  const { data: accessRulesByDay, isLoading: isAccessRulesLoading } = useGetAccessRulesQuery({ startDate: today, venueId })
  const { data: seatingAreasTables, isLoading: isSeatingAreasTablesLoading } = useGetSeatingAreasTablesQuery({ venueId })
  const { data: accessRulesSlideoutData, isLoading: isAccessRulesSlideoutDataLoading } = useAccessRulesSlideoutData()
  const { data: clientTagGroups, isLoading: isClientTagGroupsLoading } = useGetClientTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })

  const exclusiveAccessAudienceId = useMemo(() => {
    const audienceHierarchy = flattenAudience(accessRulesSlideoutData?.audienceHierarchy).find(
      audienceHierarchy => audienceHierarchy.name === EXCLUSIVE_AUDIENCE_HIERARCHY_NAME
    )
    return audienceHierarchy?.value
  }, [accessRulesSlideoutData?.audienceHierarchy])

  const isLoading =
    isPerkLoading || isAccessRulesLoading || isSeatingAreasTablesLoading || isAccessRulesSlideoutDataLoading || isClientTagGroupsLoading

  return isLoading ||
    !accessRulesByDay ||
    !seatingAreasTables ||
    !accessRulesSlideoutData ||
    !clientTagGroups ||
    !exclusiveAccessAudienceId ? (
    <Loader />
  ) : (
    <CreateEditExclusivePerkForm
      perk={perk}
      accessRulesByDay={accessRulesByDay}
      seatingAreasTables={seatingAreasTables}
      accessRulesSlideoutData={accessRulesSlideoutData}
      clientTagGroups={clientTagGroups}
      exclusiveAccessAudienceId={exclusiveAccessAudienceId}
    />
  )
}
