import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, Grid } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { generateTimeSlots } from '../../shared'
import { ScheduleLocales } from '../Schedule.locales'
import { ScheduleTestId } from '../Schedule.testIds'
import { ShiftRestrictable } from './ShiftRestrictable'
import type { ScheduleProps } from '../ScheduleProps'

export function CustomTimeRangeSelector(props: ScheduleProps) {
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useAppContext().venueSettings
  const { field } = props
  const timeSlots = generateTimeSlots(startOfDayTime)
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <ShiftRestrictable {...props}>
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 6'}>
          <Label primary={formatMessage(ScheduleLocales.firstReservationLabel)}>
            <FormSelect
              data-test={ScheduleTestId.startTimeSelect}
              options={timeSlots}
              field={field.prop('startTime')}
              withEmpty
              hideEmptyOption
              placeholder={formatMessage(ScheduleLocales.firstReservationLabel)}
            />
          </Label>
        </Box>
        <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 6'}>
          <Label primary={formatMessage(ScheduleLocales.lastReservationLabel)}>
            <FormSelect
              data-test={ScheduleTestId.endTimeSelect}
              options={timeSlots}
              field={field.prop('endTime')}
              withEmpty
              hideEmptyOption
              placeholder={formatMessage(ScheduleLocales.lastReservationLabel)}
            />
          </Label>
        </Box>
      </Grid>
    </ShiftRestrictable>
  )
}
