import React from 'react'
import { PerkTypeEnum, type PerkType, type Disabled } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { RadioGroup, Label } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'

interface PerkTypeFormProps {
  perkTypeField: Field<PerkType>
  disabled: Disabled
}

export function PerkTypeForm({ disabled, perkTypeField }: PerkTypeFormProps) {
  const { formatMessage } = useLocales()

  const { perkType, perkTypeInfo, perkTypeGlobalDescription, perkTypeGlobalLabel, perkTypeLocalDescription, perkTypeLocalLabel } =
    perksMessages

  return (
    <Label primary={<Text fontWeight="bold">{formatMessage(perkType)}</Text>} info={<>{formatMessage(perkTypeInfo)}</>}>
      <RadioGroup
        data-test="sr-perk-form-global-or-local"
        choices={[
          {
            disabled,
            description: formatMessage(perkTypeGlobalDescription),
            label: formatMessage(perkTypeGlobalLabel),
            value: PerkTypeEnum.GLOBAL,
          },
          {
            disabled,
            description: formatMessage(perkTypeLocalDescription),
            label: formatMessage(perkTypeLocalLabel),
            value: PerkTypeEnum.LOCAL,
          },
        ]}
        disabled={disabled}
        field={perkTypeField}
      />
    </Label>
  )
}
