import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  accountAndAuthentication: {
    id: 'paymentSettings.FreedomPay.accountAndAuthentication',
    defaultMessage: 'FreedomPay Account & Authentication',
  },
  esKey: {
    id: 'paymentSettings.FreedomPay.esKey',
    defaultMessage: 'ES Key',
  },
  storeId: {
    id: 'paymentSettings.FreedomPay.storeId',
    defaultMessage: 'Store ID',
  },
  terminalId: {
    id: 'paymentSettings.FreedomPay.terminalId',
    defaultMessage: 'Terminal ID',
  },
  settings: {
    id: 'paymentSettings.FreedomPay.settings',
    defaultMessage: 'Settings',
  },
  settingsLabel: {
    id: 'paymentSettings.FreedomPay.settingsLabel',
    defaultMessage: 'Payment methods must also be configured in your FreedomPay account. ',
  },
  isAppleAndGooglePayEnabled: {
    id: 'paymentSettings.FreedomPay.isAppleAndGooglePayEnabled',
    defaultMessage: 'Enable ApplePay and GooglePay',
  },
} as const)
