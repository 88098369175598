import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Loader, SlideOut, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reviewsMessages } from '../../../locales'
import { AISummaryCard, type AISummaryCardProps } from './AISummaryCard'

export interface AISummarySlideOutProps {
  title: string
  sectionProps: AISummaryCardProps[]
  isFetching: boolean
  show: boolean
  setShow: Function
  lastWeek: Function
  nextWeek: Function
  nextWeekDisabled: boolean
}

export function AISummarySlideOut({
  title,
  sectionProps,
  isFetching,
  show,
  setShow,
  lastWeek,
  nextWeek,
  nextWeekDisabled,
}: AISummarySlideOutProps) {
  const { formatMessage } = useLocales()

  const footer = (
    <HStack ml="auto" spacing="s">
      <Button variant="secondary" onClick={() => lastWeek()} data-test="button-last-week-slide-out">
        {formatMessage(reviewsMessages.aiSummaryLastWeek)}
      </Button>
      <Button variant="secondary" disabled={nextWeekDisabled} onClick={() => nextWeek()} data-test="button-next-week-slide-out">
        {formatMessage(reviewsMessages.aiSummaryNextWeek)}
      </Button>
    </HStack>
  )

  return (
    <SlideOut
      title={title}
      show={show}
      onCloseComplete={() => setShow(false)}
      noTopPadding
      footer={footer}
      background="secondaryBackground"
      data-test="ai-summary-slide-out"
    >
      <Box backgroundColor="infoBackground" p="s" mt="lm" mb="lm" borderRadius="s">
        <Text>{formatMessage(reviewsMessages.aiSummarySlideoutMessage)}</Text>
      </Box>
      {!isFetching ? (
        <VStack spacing="lm">
          {sectionProps.map(props => (
            <AISummaryCard key={props.sectionTitle} {...props} compact={false} />
          ))}
        </VStack>
      ) : (
        <VStack height="100%" justifyContent="center" textAlign="center" pb="l" spacing="m">
          <Text>{formatMessage(reviewsMessages.aiSummarySlideoutLoadingMessage)}</Text>
          <Loader />
        </VStack>
      )}
    </SlideOut>
  )
}
