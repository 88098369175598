import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, FieldErrors, FormNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useAccessRuleContext } from '../../shared'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import { isArReservingHighPacing } from '../utils'
import { CustomPacingEdit } from './CustomPacingEdit'
import { PacingHeldWarning } from './PacingHeldWarning'
import { PacingWarning } from './PacingWarning'
import type { PacingProps } from './PacingProps'

export function PacingEdit({ field }: PacingProps) {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()
  const { venueSettings } = useAppContext()
  const maxCoversPerSeatingInterval = useWatch(field.prop('maxCoversPerSeatingInterval'))
  const customPacing = useWatch(field.prop('customPacing'))
  const setCustomPacing = useWatch(field.prop('setCustomPacing'))
  const isPacingHeld = useWatch(field.prop('isPacingHeld'))
  const showPacingWarning = isPacingHeld && isArReservingHighPacing(shifts, maxCoversPerSeatingInterval, customPacing)

  const holdPacingTooltip = (
    <VStack spacing="s">
      <Text color="lightFont">{formatMessage(PacingLocales.treatAsBlockedTooltip)}</Text>
      <Anchor data-test={PacingTestId.customPacingTooltip} href="https://help.sevenrooms.com/hc/en-us/articles/360025628471-Access-Rules">
        {formatMessage(PacingLocales.moreAboutThisSetting)}
      </Anchor>
    </VStack>
  )
  const excludeFromShiftPacingTooltip = (
    <VStack spacing="s">
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(PacingLocales.excludeFromShiftPacingTooltipTitle)}
      </Text>
      <Text color="lightFont">{formatMessage(PacingLocales.excludeFromShiftPacingTooltipBody)}</Text>
    </VStack>
  )

  return (
    <>
      <VStack spacing="sm" alignItems="start">
        <Label primary={formatMessage(PacingLocales.label)}>
          <Box width="10.3em">
            <FormNumberInput
              data-test={PacingTestId.defaultPacingInput}
              field={field.prop('maxCoversPerSeatingInterval')}
              decimalScale={0}
              hideErrorMessage
            />
          </Box>
          <FieldErrors data-test={PacingTestId.defaultPacingErrors} field={field.prop('maxCoversPerSeatingInterval')} />
        </Label>

        <Checkbox
          data-test={PacingTestId.setCustomPacingCheckbox}
          field={field.prop('setCustomPacing')}
          onChange={e => {
            if (!e.currentTarget.checked) {
              field.prop('customPacing').set({})
            }
          }}
        >
          {formatMessage(PacingLocales.customPacingOption)}
        </Checkbox>
        {setCustomPacing && (
          <Box width="43.2em">
            <CustomPacingEdit field={field} shifts={shifts} />
          </Box>
        )}

        <HStack spacing="xs">
          <Checkbox
            data-test={PacingTestId.holdPacingCheckbox}
            field={field.prop('isPacingHeld')}
            description={
              isPacingHeld ? (
                <PacingHeldWarning maxCoversPerSeatingInterval={maxCoversPerSeatingInterval} customPacing={customPacing ?? {}} />
              ) : undefined
            }
            info={holdPacingTooltip}
            extra={
              showPacingWarning ? (
                <PacingWarning maxCoversPerSeatingInterval={maxCoversPerSeatingInterval} customPacing={customPacing ?? {}} />
              ) : undefined
            }
          >
            {formatMessage(PacingLocales.isPacingHeldOption)}
          </Checkbox>
        </HStack>

        {venueSettings?.excludeFromShiftPacingEnabled && (
          <Checkbox
            data-test={PacingTestId.excludeFromShiftPacingCheckbox}
            field={field.prop('excludeFromShiftPacing')}
            info={excludeFromShiftPacingTooltip}
          >
            {formatMessage(PacingLocales.excludeFromShiftPacing)}
          </Checkbox>
        )}
      </VStack>
    </>
  )
}
