import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { GuestDurationPickerLocales } from '../GuestDurationPicker.locales'
import { GuestDurationPickerTestId } from '../GuestDurationPicker.testIds'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function GuestDurationPickerReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()

  const { guestMustSpecifyDuration, durationMin, durationMax } = accessRule.guestDurationPicker

  return guestMustSpecifyDuration ? (
    <ReportPart caption={formatMessage(GuestDurationPickerLocales.title)} data-test={GuestDurationPickerTestId.report}>
      <ReportLine name={formatMessage(GuestDurationPickerLocales.specifyDurationLabel)}>
        <Text>{formatMessage(commonMessages.yes)}</Text>
      </ReportLine>
      {durationMin && (
        <ReportLine name={formatMessage(GuestDurationPickerLocales.durationMin)}>
          <Text>{formatMessage(GuestDurationPickerLocales.durationTimeFormat, getHoursAndMinutes(durationMin))}</Text>
        </ReportLine>
      )}
      {durationMax && (
        <ReportLine name={formatMessage(GuestDurationPickerLocales.durationMax)}>
          <Text>{formatMessage(GuestDurationPickerLocales.durationTimeFormat, getHoursAndMinutes(durationMax))}</Text>
        </ReportLine>
      )}
    </ReportPart>
  ) : null
}
