/* eslint-disable max-classes-per-file */
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import DatePicker from 'js/3p/react-datepicker-v1'
import { ValueWrapper, ValueBox, InlineText, InvalidMessage } from './CustomAutotagShared'

require('../../../../../../../../../css/react/react-datepicker-v1.css')

const TimePickerInnerWrapper = styled.div`
  position: absolute;
  top: 40px;
  z-index: 99;
  ${props => props.alignRight && 'right: 0px;'};
`

const ValueBoxCSS = css`
  height: 35px;
  width: 72px;
  text-align: center;
  justify-content: center;
`

const FIELDS = {
  START_TIME: 'START_TIME',
  END_TIME: 'END_TIME',
}

class CustomAutotagTimePicker extends React.PureComponent {
  handleClickOutside = () => {
    this.props.closeCalendar()
  }

  render() {
    const { id, time, locale, onChange, alignRight } = this.props
    return (
      <TimePickerInnerWrapper id={id} alignRight={alignRight} className="svr-datepicker react-datepicker-v1">
        <DatePicker inline selected={time} locale={locale} onChange={onChange} showTimeSelect showTimeSelectOnly timeIntervals={15} />
      </TimePickerInnerWrapper>
    )
  }
}

const TimePickerWrapper = onClickOutside(CustomAutotagTimePicker)

class CustomAutotagTimeRangePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isActive: null }
  }

  startTimeBoxClickHandler = () => this.setState({ isActive: FIELDS.START_TIME })

  endTimeBoxClickHandler = () => this.setState({ isActive: FIELDS.END_TIME })

  closeCalendar = () => this.setState({ isActive: null })

  startTimeChangeHandler = newStartTime => {
    const newEndTime = this.props.value[1]
    newStartTime.set('milliseconds', 0)
    newStartTime.set('seconds', 0)
    if (newStartTime.isAfter(newEndTime)) {
      newEndTime.set('date', newEndTime.get('date') + 1)
    }
    this.props.onChange([newStartTime, newEndTime])
  }

  endTimeChangeHandler = newEndTime => {
    const startTime = this.props.value[0]
    newEndTime.set('milliseconds', 0)
    newEndTime.set('seconds', 0)
    const startDate = startTime.get('date')
    const endDate = newEndTime.get('date')
    if (startDate !== endDate && newEndTime.isAfter(startTime)) {
      newEndTime.set('date', startDate)
    }
    if (newEndTime.isBefore(startTime)) {
      newEndTime.set('date', newEndTime.get('date') + 1)
    }
    this.props.onChange([this.props.value[0], newEndTime])
  }

  getTime = momentTime => momentTime.format('LT')

  render() {
    const { id, value, locale, invalidMessage, isConfigStateValid } = this.props
    const [startTime, endTime] = value
    const isValid = isConfigStateValid || !!this.state.isActive || !startTime.isSame(endTime)
    return (
      <ValueWrapper
        id={id}
        css={css`
          width: 200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
        `}
      >
        <ValueBox isValid={isValid} onClick={this.startTimeBoxClickHandler} css={ValueBoxCSS}>
          {this.getTime(startTime)}
        </ValueBox>
        {this.state.isActive === FIELDS.START_TIME && (
          <TimePickerWrapper time={startTime} locale={locale} onChange={this.startTimeChangeHandler} closeCalendar={this.closeCalendar} />
        )}
        <InlineText>and</InlineText>
        <ValueBox isValid={isValid} onClick={this.endTimeBoxClickHandler} css={ValueBoxCSS}>
          {this.getTime(endTime)}
        </ValueBox>
        {this.state.isActive === FIELDS.END_TIME && (
          <TimePickerWrapper
            time={endTime}
            locale={locale}
            onChange={this.endTimeChangeHandler}
            closeCalendar={this.closeCalendar}
            alignRight
          />
        )}
        {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
      </ValueWrapper>
    )
  }
}

export default CustomAutotagTimeRangePicker
