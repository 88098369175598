import React from 'react'
import { LoadingSettings } from '../LoadingSettings'

export function LoadingSettingsNoHeader() {
  return (
    <>
      <div style={{ height: '175px', width: '100%' }} />
      <LoadingSettings />
    </>
  )
}
