import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'posiSettings.ConfirmDisconnectIntegrationDialog.title',
    defaultMessage: 'Are you sure you want to disconnect this integration?',
  },
  content: {
    id: 'posiSettings.ConfirmDisconnectIntegrationDialog.content',
    defaultMessage: 'You will lose all previously saved credentials.',
  },
  disconnect: {
    id: 'posiSettings.ConfirmDisconnectIntegrationDialog.disconnect',
    defaultMessage: 'Disconnect',
  },
  disconnectIntegrationSuccess: {
    id: 'posiSettings.ConfirmDisconnectIntegrationDialog.disconnectIntegrationSuccess',
    defaultMessage: 'Successfully disconnected {posiType} integration.',
  },
  disconnectIntegrationError: {
    id: 'posiSettings.ConfirmDisconnectIntegrationDialog.disconnectIntegrationError',
    defaultMessage: 'Failed to disconnect {posiType} integration.',
  },
} as const)
