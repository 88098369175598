import { useState } from 'react'
import { useDisconnectStripeMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { StripeDisconnectDialog } from '../StripeDisconnectDialog'
import { messages } from './StripeDisconnectButton.locales'

export function StripeDisconnectButton() {
  const { formatMessage } = useLocales()
  const [isModalActive, setIsModalActive] = useState(false)
  const { venueId } = useVenueContext()
  const [disconnectStripe] = useDisconnectStripeMutation()

  const onClose = () => setIsModalActive(false)
  const onClick = async () => {
    await disconnectStripe({ venueId })
    onClose()
  }

  return (
    <>
      <Menu>
        <MenuItem onClick={() => setIsModalActive(true)}>
          <Icon name="VMSWeb-trash" />
          {formatMessage(messages.stripeDisconnect)}
        </MenuItem>
      </Menu>
      <Window active={isModalActive} data-test="payment-settings-stripe-disconnect-dialog">
        <StripeDisconnectDialog onClose={onClose} onSubmit={onClick} />
      </Window>
    </>
  )
}
