import { defineMessages } from '@sevenrooms/core/locales'

export const OrderRoomNumberLocales = defineMessages({
  loadingTitle: {
    id: 'OrderRoomNumberLocales.loading.title',
    defaultMessage: 'Loading Room Numbers',
  },
  orderingRoomNumbersTitle: {
    id: 'order.roomNumbers.title',
    defaultMessage: 'Room Numbers',
  },
  orderingRoomNumbersTableHeader: {
    id: 'order.roomNumbers.table.header',
    defaultMessage: 'Room Number',
  },
  addOrderingRoomNumbersTitle: {
    id: 'order.roomNumbers.add',
    defaultMessage: 'Add New Room Number',
  },
  deleteAllOrderingRoomNumbersTitle: {
    id: 'order.roomNumbers.delete.all.title',
    defaultMessage: 'Delete All Rooms',
  },
  deleteAllOrderingRoomNumbersButton: {
    id: 'order.roomNumbers.delete.all.button',
    defaultMessage: 'Delete {roomNumbersCount}',
  },
  addOrderingRoomNumbersModalDescription: {
    id: 'order.roomNumbers.modal.description',
    defaultMessage: 'Separate numbers by commas or line breaks',
  },
  addOrderingRoomNumbersPlaceholder: {
    id: 'order.roomNumbers.add.placeholder',
    defaultMessage: 'ex. 500, 501, 502, 503, 504, 505, 506, 507, 508, 509...',
  },
  addOrderingRoomNumbersButtonText: {
    id: 'order.roomNumbers.add.button.text',
    defaultMessage: 'Add {calculateRoomNumbersCount}',
  },
  addOrderingRoomNumbersValidationError: {
    id: 'order.roomNumbers.validation.error',
    defaultMessage: 'Invalid input above, check again.',
  },
  addOrderingRoomNumbersAllRoomNumbersExistError: {
    id: 'order.roomNumbers.validation.allRoomNumbersExist',
    defaultMessage: 'All room numbers in list already exist.',
  },
  orderingRoomNumbersPlaceholder: {
    id: 'order.roomNumbers.placeholder',
    defaultMessage: 'Unassigned',
  },
  notificationLoadingError: {
    id: 'order.roomNumbers.loading.error',
    defaultMessage: 'Unable to fetch room numbers, please try again later or contact customer support',
  },
  notificationOneItemSaveSuccess: {
    id: 'order.roomNumbers.save.success',
    defaultMessage: '“{roomNumber}“ Room Number Added',
  },
  notificationSaveSuccess: {
    id: 'order.roomNumbers.save.success',
    defaultMessage: '{roomNumbersCount} Room Numbers Added',
  },
  notificationSaveError: {
    id: 'order.roomNumbers.save.error',
    defaultMessage: 'Something went wrong. Please refresh your page and try again',
  },
  notificationDeleteSuccess: {
    id: 'order.roomNumbers.delete.success',
    defaultMessage: '“{roomNumber}” is deleted',
  },
  notificationDeleteError: {
    id: 'order.roomNumbers.delete.error',
    defaultMessage: 'Something went wrong. Please refresh your page and try again',
  },
  deleteText: {
    id: 'order.roomNumbers.delete.text',
    defaultMessage: 'Are you sure you want to delete “{roomNumber}“ ?',
  },
  deleteAllText: {
    id: 'order.roomNumbers.deleteAll.text',
    defaultMessage: 'Are you sure you want to delete all Room Numbers',
  },
  notificationDeleteAllRoomNumbersSuccess: {
    id: 'order.roomNumbers.deleteAllRoomNumbers.success',
    defaultMessage: '{roomNumbersCount} Room Numbers Deleted',
  },
  deleteModalWarning: {
    id: 'order.roomNumbers.delete.warning',
    defaultMessage: 'Warning',
  },
  noRooms: {
    id: 'order.roomNumbers.empty',
    defaultMessage: 'No rooms have been created yet',
  },
} as const)
