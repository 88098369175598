import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { DisplayIfSelected } from '../../../ChargeSettings'
import { TimeBefore } from '../../../shared'
import { PaymentPolicyLocales } from '../../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import { RadioSection } from './RadioComponents'
import type { PaymentPolicyForm } from '../../PaymentPolicy.zod'
import type { PaymentPolicyProps } from '../PaymentPolicyProps'

export function CancelRadios({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  const choices: RadioChoice<PaymentPolicyForm['cancelCutoffChoice']>[] = useMemo(
    () => [
      {
        value: 'ANY_TIME',
        label: formatMessage(PaymentPolicyLocales.cancelChoiceAnyTime),
      },
      {
        value: 'NEVER',
        label: formatMessage(PaymentPolicyLocales.cancelChoiceNever),
      },
      {
        value: 'CUTOFF',
        label: formatMessage(PaymentPolicyLocales.cancelChoiceUntilCutoff),
        description: (
          <DisplayIfSelected field={field.prop('cancelCutoffChoice')} option="CUTOFF">
            <TimeBefore data-test={PaymentPolicyTestId.cancelCutoff} field={field.prop('cancelCutoff')} />
          </DisplayIfSelected>
        ),
      },
    ],
    [formatMessage, field]
  )
  return (
    <RadioSection
      data-test={PaymentPolicyTestId.cancelRadios}
      choices={choices}
      field={field.prop('cancelCutoffChoice')}
      primaryLabel={formatMessage(PaymentPolicyLocales.customerCanCancelLonger)}
      secondaryLabel={formatMessage(PaymentPolicyLocales.customerCanCancelDescription)}
    />
  )
}
