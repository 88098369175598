import { useContext } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleSlideoutSectionControllerContext, AccessRuleTooltip } from '../../shared'
import { SharedTestId } from '../../shared/shared.testIds'
import { PartySizeLocales } from '../PartySize.locales'

interface MissingDurationWarningTooltipProps {
  minPartySize?: number | null
  durationsMinPartySize?: number
  maxPartySize?: number | null
  durationsMaxPartySize?: number
}

export function MissingDurationWarningTooltip({
  minPartySize,
  durationsMinPartySize,
  maxPartySize,
  durationsMaxPartySize,
}: MissingDurationWarningTooltipProps) {
  const { formatMessage } = useLocales()

  const { tryFocusSection } = useContext(AccessRuleSlideoutSectionControllerContext)

  const missingMinDurations = Boolean(minPartySize && durationsMinPartySize && minPartySize < durationsMinPartySize)
  const missingMaxDurations = Boolean(maxPartySize && durationsMaxPartySize && maxPartySize > durationsMaxPartySize)

  return missingMinDurations || missingMaxDurations ? (
    <Box display="inline" m="xs">
      <AccessRuleTooltip data-test={SharedTestId.cutoffTimeWarning} variant="warning" size="sm">
        <Text color="lightFont">{formatMessage(PartySizeLocales.missingDurationWarning)}</Text>
        <Button data-test="test" onClick={() => tryFocusSection('durations')}>
          {formatMessage(PartySizeLocales.buttonToDurations)}
        </Button>
      </AccessRuleTooltip>
    </Box>
  ) : (
    <></>
  )
}
