import { useMemo, useCallback } from 'react'
import { type OrderingRoomNumbersPayload, orderingRoomNumbersApi, OrderingRoomNumbersAdapter } from '@sevenrooms/core/api'
import { useForm, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { TextArea, Button, Label } from '@sevenrooms/core/ui-kit/form'
import { notify, VStack, Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useCreateRoomNumberSchema } from './CreateRoomNumbers.zod'
import { OrderRoomNumberLocales } from './OrderingRoomNumbers.locales'

export interface CreateRoomNumberModalProps {
  onDiscard: () => void
  allRoomNumbers: string[]
}
export function CreateRoomNumberModal({ onDiscard, allRoomNumbers }: CreateRoomNumberModalProps) {
  const schema = useCreateRoomNumberSchema()
  const form = useForm(schema, {
    defaultValues: {
      roomNumbers: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const { formatMessage } = useLocales()
  const {
    handleSubmit,
    trigger,
    field,
    formState: { errors, isSubmitting },
  } = form
  const { venueId } = useVenueContext()
  const [postOrderingRoomNumber] = orderingRoomNumbersApi.useCreateRoomNumbersMutation()

  const submit = useCallback(
    async (formData: OrderingRoomNumbersPayload) => {
      const { roomNumbers } = formData
      const roomNumbersArr = OrderingRoomNumbersAdapter.toArray(roomNumbers)
      if (roomNumbersArr.filter(x => !allRoomNumbers.includes(x)).length === 0) {
        notify({ content: formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersAllRoomNumbersExistError), type: 'error' })
      } else {
        const response = await postOrderingRoomNumber({ venueId, roomNumbers })
        if ('error' in response) {
          notify({ content: formatMessage(OrderRoomNumberLocales.notificationSaveError), type: 'error' })
        } else if (response.data.length === 1) {
          notify({
            content: formatMessage(OrderRoomNumberLocales.notificationOneItemSaveSuccess, {
              roomNumber: response.data[0] ? response.data[0].number : '',
            }),
            type: 'success',
          })
        } else {
          notify({
            content: formatMessage(OrderRoomNumberLocales.notificationSaveSuccess, {
              roomNumbersCount: response.data.length,
            }),
            type: 'success',
          })
        }
        onDiscard()
      }
    },
    [allRoomNumbers, formatMessage, onDiscard, postOrderingRoomNumber, venueId]
  )

  const onSubmit = useCallback(() => {
    trigger().then(isValid => {
      if (isValid) {
        handleSubmit(submit)()
      }
    })
  }, [handleSubmit, submit, trigger])

  const disabled = useMemo(() => Object.keys(errors).length !== 0 || isSubmitting, [errors, isSubmitting])

  const roomNumbers = useWatch(field.prop('roomNumbers'))

  const calculateRoomNumbersCount = useMemo(() => {
    const roomNumbersArr = OrderingRoomNumbersAdapter.toArray(roomNumbers)
    return roomNumbersArr.length === 0 ? '' : `(${roomNumbersArr.length})`
  }, [roomNumbers])

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <ModalTitle title={formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersTitle)} />
      </ModalHeader>
      <ModalBody>
        <VStack spacing="s" data-test="add-room-number-options">
          <Label primary={formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersModalDescription)}>
            <TextArea
              field={field.prop('roomNumbers')}
              onInput={() => form.clearErrors()}
              placeholder={formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersPlaceholder)}
            />
          </Label>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={() => onDiscard()} data-test="sr-create-room-number-modal-discard">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" disabled={disabled} onClick={onSubmit} data-test="sr-create-room-number-modal-add">
            {formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersButtonText, { calculateRoomNumbersCount })}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
