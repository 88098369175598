import { useLocales } from '@sevenrooms/core/locales'
import { FollowShiftDefaultsModal } from '../../shared/FollowShiftDefaultsModal'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { DefaultPaymentPolicy } from './DefaultPaymentPolicy'

export interface PaymentPolicyDefaultsModalProps {
  onDone?: () => void
}

export function PaymentPolicyDefaultsModal({ onDone }: PaymentPolicyDefaultsModalProps) {
  const { formatMessage } = useLocales()

  return (
    <FollowShiftDefaultsModal title={formatMessage(PaymentPolicyLocales.defaultSettingsModalTitle)} onDone={onDone}>
      <DefaultPaymentPolicy />
    </FollowShiftDefaultsModal>
  )
}
