import type { VenueMarketingStatusResponse } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { ActionBanner, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { marketingHomeMessages } from '../locales'

export interface ActionItemsProps {
  marketingStatus: VenueMarketingStatusResponse
  onClickBookieActionItem: () => void
  venue: Venue
}

export function ActionItems({ marketingStatus, onClickBookieActionItem, venue }: ActionItemsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <VStack height="100%" width="430px" justifyContent="center" ml="4xl" pl="l" spacing="s">
      {marketingStatus.bookieConnectionNeeded && (
        <Box onClick={onClickBookieActionItem}>
          <ActionBanner
            title={formatMessage(marketingHomeMessages.enableBookieTitle)}
            description={formatMessage(marketingHomeMessages.enableBookieDescription)}
            to="#"
            pt="s"
            pb="s"
            pr="m"
            pl="m"
          />
        </Box>
      )}
      {marketingStatus.groupVenuesToOnboard > 0 && (
        <ActionBanner
          title={formatMessage(marketingHomeMessages.onboardVenuesTitle, { count: marketingStatus.groupVenuesToOnboard })}
          description={formatMessage(marketingHomeMessages.onboardVenuesDescription, { count: marketingStatus.groupVenuesToOnboard })}
          to={marketingStatus.onboardingLink}
          isExternal
          pt="s"
          pb="s"
          pr="m"
          pl="m"
        />
      )}
      {!marketingStatus.automatedEmailsEnabled && (
        <ActionBanner
          title={formatMessage(marketingHomeMessages.enableEmailsTitle)}
          description={formatMessage(marketingHomeMessages.enableEmailsDescription)}
          to={nav.href(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } })}
          pt="s"
          pb="s"
          pr="m"
          pl="m"
        />
      )}
      {!marketingStatus.spendPerCoverSet && (
        <ActionBanner
          title={formatMessage(marketingHomeMessages.setSpendPerCoverTitle)}
          description={formatMessage(marketingHomeMessages.setSpendPerCoverDescription)}
          to={nav.href(routes.manager.profileInformation, { params: { venueKey: venue.urlKey } })}
          isExternal
          pt="s"
          pb="s"
          pr="m"
          pl="m"
        />
      )}
      {!marketingStatus.googleReviewSiteLink && (
        <ActionBanner
          title={formatMessage(marketingHomeMessages.addReviewSiteLinksTitle)}
          description={formatMessage(marketingHomeMessages.addReviewSiteLinksDescription)}
          to={nav.href(routes.manager.profileInformation, { params: { venueKey: venue.urlKey } })}
          isExternal
          pt="s"
          pb="s"
          pr="m"
          pl="m"
        />
      )}
    </VStack>
  )
}
