import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import type { Perk } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Label, MultiSelectTree, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { autotagSlideOutMessages } from '../../locales'
import { AutotagExpander } from './AutotagExpander'

export interface AutotagPerksSelectProps {
  selectedPerkIds: string[]
  autotagPerks: Perk[]
  onChange: (value: string[]) => void
  disabled: boolean
  errorMessage: string
  isCustomAutotag?: boolean
}

export const perkToTreeItem = (perk: Perk, selectedPerkIds: string[]) => ({
  id: perk.id,
  value: perk.id,
  label: perk.internalName,
  checked: selectedPerkIds.includes(perk.id),
})

export const updateTreeData = (perks: Perk[] = [], selectedPerkIds: string[] = []) => {
  const globalPerks = perks.filter(perk => perk.isGlobal).map(perk => perkToTreeItem(perk, selectedPerkIds))
  const localPerks = perks.filter(({ isGlobal }) => !isGlobal).map(perk => perkToTreeItem(perk, selectedPerkIds))

  const treeData = [
    {
      id: 'Global',
      label: 'Global',
      value: 'Global',
      section: 'Global',
      children: globalPerks,
    },
    {
      id: 'Local',
      label: 'Local',
      value: 'Local',
      section: 'Local',
      children: localPerks,
    },
  ]

  return treeData
}

export const getNewPerkIds = (treeItem: TreeNode, selectedPerkIds: string[]) =>
  selectedPerkIds.includes(treeItem.id) ? selectedPerkIds.filter(perkId => perkId !== treeItem.id) : selectedPerkIds.concat(treeItem.id)

const ActionIcon = styled(StyledIcons.XS)``

export function AutotagPerksSelect({
  selectedPerkIds = [],
  autotagPerks,
  onChange,
  disabled,
  errorMessage,
  isCustomAutotag = false,
}: AutotagPerksSelectProps) {
  const { formatMessage } = useLocales()
  const [expanded, setExpanded] = useState(Boolean(selectedPerkIds.length))
  const uuid = v4()

  const treeData: TreeNode[] = useMemo(() => updateTreeData(autotagPerks, selectedPerkIds), [autotagPerks, selectedPerkIds])

  const handleOnChange = (treeItem: TreeNode) => {
    const newPerkIds = getNewPerkIds(treeItem, selectedPerkIds)
    onChange(newPerkIds)
  }

  const handleOnClickDelete = () => {
    // collapse expander
    setExpanded(false)
    // delete perks
    onChange([])
  }

  if (isCustomAutotag) {
    return (
      <MultiSelectTree
        placeholder={formatMessage(autotagSlideOutMessages.perksSelectDescription)}
        id={uuid}
        displayAsTextWhenCollapsed
        errorMessage={errorMessage}
        treeData={treeData}
        onChange={handleOnChange}
        disabled={disabled}
        containerWidth="448px"
      />
    )
  }

  return (
    <AutotagExpander
      title={
        <>
          <ActionIcon>{VmsIcons.Plus}</ActionIcon>
          <span>&nbsp;{formatMessage(autotagSlideOutMessages.addPerks)}</span>
        </>
      }
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <>
        <HStack justifyContent="space-between">
          <Label
            htmlFor={uuid}
            primary={formatMessage(autotagSlideOutMessages.perksSelectLabelPrimary)}
            info={<>{formatMessage(autotagSlideOutMessages.perksSelectLabelInfo)}</>}
          />
          <Box mb="s">
            <Button
              data-test="perk-delete-button"
              onClick={handleOnClickDelete}
              variant="tertiary-warning"
              size="s"
              icon="VMSWeb-trash"
              disabled={disabled}
            >
              {formatMessage(autotagSlideOutMessages.perksSelectDeleteButton)}
            </Button>
          </Box>
        </HStack>
        <MultiSelectTree
          placeholder={formatMessage(autotagSlideOutMessages.perksSelectMultiSelectTreeDescription)}
          id={uuid}
          displayAsTextWhenCollapsed
          errorMessage={errorMessage}
          treeData={treeData}
          onChange={handleOnChange}
          disabled={disabled}
        />
      </>
    </AutotagExpander>
  )
}
AutotagPerksSelect.propTypes = {
  selectedPerkIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autotagPerks: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
}
