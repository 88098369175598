import { defineMessages } from '@sevenrooms/core/locales'

export const autotagSlideOutMessages = defineMessages({
  addPerks: {
    id: 'autotagSlideOut.addPerks',
    defaultMessage: 'Add Perks',
  },
  addAdditionalNotes: {
    id: 'autotagSlideOut.addAdditionalNotes',
    defaultMessage: 'Add Additional Notes',
  },
  additionalNotesDescription: {
    id: 'autotagSlideOut.additionalNotesDescription',
    defaultMessage: 'ie. standard of procedures, special notes for front of house team, etc',
  },
  additionalNotesLabelInfo: {
    id: 'autotagSlideOut.additionalNotesLabel',
    defaultMessage:
      'Front of house team can access this information on the reservation slideout on both web and mobile and the daily/program notes section.',
  },
  additionalNotesLabelPrimary: {
    id: 'autotagSlideOut.additionalNotesLabelPrimary',
    defaultMessage: 'Additional Notes',
  },
  perksSelectDeleteButton: {
    id: 'autotagSlideOut.perksSelectDeleteButton',
    defaultMessage: 'Delete',
  },
  perksSelectDescription: {
    id: 'autotagSlideOut.perksSelectDescription',
    defaultMessage: 'Search and select perks',
  },
  perksSelectLabelInfo: {
    id: 'autotagSlideOut.perksSelectLabel',
    defaultMessage: "Perks will be on the reservation slideout on both web and mobile when it's applicable.",
  },
  perksSelectLabelPrimary: {
    id: 'autotagSlideOut.perksSelectLabelPrimary',
    defaultMessage: 'What perks do you want to add?',
  },
  perksSelectMultiSelectTreeDescription: {
    id: 'autotagSlideOut.perksSelectMultiSelectTreeDescription',
    defaultMessage: 'Search and select perks',
  },
})
