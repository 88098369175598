import { useCallback } from 'react'
import { orderingRoomNumbersApi } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { notify, Modal, ModalActions, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { OrderRoomNumberLocales } from './OrderingRoomNumbers.locales'

export interface deleteAllRoomNumbers {
  onDiscard: () => void
  roomNumbersCount: number
}
export function DeleteAllRoomNumberModal({ onDiscard, roomNumbersCount }: deleteAllRoomNumbers) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const [deleteOrderingRoomNumber] = orderingRoomNumbersApi.useDeleteAllRoomNumberMutation()
  const onSubmit = useCallback(async () => {
    const response = await deleteOrderingRoomNumber({ venueId })
    if ('error' in response) {
      notify({ content: formatMessage(OrderRoomNumberLocales.notificationDeleteError), type: 'error' })
    } else {
      notify({
        content: formatMessage(OrderRoomNumberLocales.notificationDeleteAllRoomNumbersSuccess, { roomNumbersCount }),
        type: 'success',
      })
    }
    onDiscard()
  }, [deleteOrderingRoomNumber, formatMessage, onDiscard, roomNumbersCount, venueId])

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <ModalTitle title={formatMessage(OrderRoomNumberLocales.deleteAllText)} />
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={() => onDiscard()} data-test="sr-delete-all-room-number-modal-discard">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={onSubmit} data-test="sr-open-delete-all-room-number-modal">
            {formatMessage(OrderRoomNumberLocales.deleteAllOrderingRoomNumbersButton, { roomNumbersCount })}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
