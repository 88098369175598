import { useLocales } from '@sevenrooms/core/locales'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { POSICircleLogo } from '../../components'
import { messages } from './AuthenticationStatusCard.locales'

export interface AuthenticationStatusCardProps {
  logo: string
  title: string
  docLink: string
  isAuthenticated: boolean
}

export function AuthenticationStatusCard({ logo, title, docLink, isAuthenticated }: AuthenticationStatusCardProps) {
  const { formatMessage } = useLocales()
  return (
    <VStack backgroundColor="primaryBackground" width="100%">
      <HStack spacing="m" pt="m" pb="m" pl="m" pr="m" flexGrow="1" alignItems="center" justifyContent="space-between">
        <HStack spacing="s" justifyContent="center" alignItems="center">
          <POSICircleLogo logo={logo} title={title} />
          <VStack spacing="s" pl="m" justifyContent="center" alignItems="start">
            <Text textStyle="h2">{title}</Text>
            <Text>
              <Anchor href={docLink}>{formatMessage(messages.docLinkLabel)}</Anchor>
            </Text>
          </VStack>
        </HStack>
        <HStack spacing="s" alignItems="center">
          <svg width="8" height="8">
            <circle cx="4" cy="4" r="4" fill={isAuthenticated ? '#008a67' : '#ff943e'} />
          </svg>
          <span style={{ fontSize: 'x-small', letterSpacing: '3px', color: '#6b6b6b' }}>{formatMessage(messages.authenticationLabel)}</span>
        </HStack>
      </HStack>
    </VStack>
  )
}
