import { useEffect } from 'react'
import { useGetVenueProfileQuery, useGetVenueUsersProfilesQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { VenueProfileForm } from '../../components'
import { venueProfileMessages } from '../../locales/venueProfileMessages'

export function VenueProfile() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { data: venueProfile, isError: isVenueProfileError } = useGetVenueProfileQuery({ venueId })
  const { data: venueUsers, isError: isVenueUsersError } = useGetVenueUsersProfilesQuery({ venueId })
  const isError = isVenueProfileError || isVenueUsersError

  useEffect(() => {
    if (isError) {
      notify({
        content: formatMessage(venueProfileMessages.fetchError),
        type: 'error',
      })
    }
  }, [isError, formatMessage])

  return venueProfile && venueUsers ? (
    <VenueProfileForm venueProfile={venueProfile} venueUsers={venueUsers} />
  ) : (
    <Box pb="m">
      <Loader />
    </Box>
  )
}
