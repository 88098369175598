import { useMemo } from 'react'
import type { BookingNotifications, BookingDeliveryMethod, ReminderDeliveryMethod, FeedbackOptions } from '@sevenrooms/core/domain'
import { createZodSchema, type InferZodSchema, z } from '@sevenrooms/core/form'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import { useAdditionalInfoValues } from '../AdditionalValues.zod'
import { useExcludedAudienceForm } from '../components/ExcludedAudience/ExcludedAudience.zod'
import { useShiftTimeForm } from '../ShiftOptions.zod'

export type BookingNotificationsValues = InferZodSchema<typeof useBookingNotificationsValues>
const useBookingNotificationsValues = createZodSchema(z => z.custom<BookingNotifications>())

export type FeedbackOptionsValues = InferZodSchema<typeof useFeedbackOptionsValues>
const useFeedbackOptionsValues = createZodSchema(z => z.custom<FeedbackOptions>())

export type ReminderDeliveryMethodValues = InferZodSchema<typeof useReminderDeliveryMethodValues>
const useReminderDeliveryMethodValues = createZodSchema(z => z.custom<ReminderDeliveryMethod>())
const useBookingDeliveryMethodValues = () => z.custom<BookingDeliveryMethod>()

export type GuestEmailSMSForm = InferZodSchema<typeof useGuestEmailSMSForm>

export const useGuestEmailSMSForm = createZodSchema(z => {
  const shiftTime = useShiftTimeForm()
  const image = useImageForm()
  const bookingNotificationsValues = useBookingNotificationsValues()
  const bookingDeliveryMethodValues = useBookingDeliveryMethodValues()
  const reminderDeliveryMethodValues = useReminderDeliveryMethodValues()
  const additionalInfoValues = useAdditionalInfoValues()
  const feedbackOptionsValues = useFeedbackOptionsValues()
  const excludedAutotags = useExcludedAudienceForm()

  return useMemo(
    () =>
      z
        .object({
          bookingNotifications: z.array(bookingNotificationsValues),
          sendReservationReminderShow: z.boolean(),
          sendReservationReminder: z.array(shiftTime),
          sendReservationReminderNightlifeShow: z.boolean(),
          sendReservationReminderTimeNightlife: z.string().nullable(),
          sendReservationReminderDayNightlife: z.string().nullable(),
          reminderDeliveryMethod: reminderDeliveryMethodValues,
          bookingDeliveryMethod: bookingDeliveryMethodValues,
          customQuestion: z.string().nullable(),
          arrivalSms: z.string(),
          isArrivalSms: z.boolean(),
          feedbackEmailsShow: z.boolean(),
          feedbackEmails: z.array(shiftTime),
          sendEmailConfirmations: z.boolean(),
          feedbackOptions: z.array(feedbackOptionsValues),
          logo: image.optional().nullable(),
          emailLogoHeight: z.number(),
          venueColorShow: additionalInfoValues,
          venueColor: z.string(),
          altColorShow: additionalInfoValues,
          altColor: z.string(),
          guestListQRCodeEnabled: z.boolean(),
        })
        .merge(excludedAutotags),

    [
      z,
      bookingDeliveryMethodValues,
      bookingNotificationsValues,
      shiftTime,
      reminderDeliveryMethodValues,
      additionalInfoValues,
      feedbackOptionsValues,
      image,
      excludedAutotags,
    ]
  )
})
