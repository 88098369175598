import { type Field, useWatch } from '@sevenrooms/core/form'
import type { PropsWithChildren } from 'react'

/**
 * @deprecated
 * Do not reuse this. It will be removed in {@link https://sevenrooms.atlassian.net/browse/VX-3745 VX-3745}
 */
export function DisplayIfChecked({ field, children }: PropsWithChildren<{ field: Field<boolean> }>) {
  const checked = useWatch(field)
  return checked ? <>{children}</> : null
}

/**
 * @deprecated
 * Do not reuse this. It will be removed in {@link https://sevenrooms.atlassian.net/browse/VX-3747 VX-3747}
 */
export function DisplayIfSelected<T extends string>({ field, option, children }: PropsWithChildren<{ field: Field<T>; option: T }>) {
  const choice = useWatch(field)
  return choice === option ? <>{children}</> : null
}
