import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, Grid } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import {
  type GoogleReserveSeatingAreas,
  googleReserveSeatingOptions,
  type TheForkSeatingAreas,
  theForkSeatingOptions,
} from '../integrationOptions'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import type { PropsWithChildren } from 'react'

export interface SeatingAreaIntegrationFieldsProps {
  googleReserveField: Field<GoogleReserveSeatingAreas>
  theForkField: Field<TheForkSeatingAreas>
}

export function SeatingAreaIntegrationFields({ theForkField, googleReserveField }: SeatingAreaIntegrationFieldsProps) {
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings
  const { formatMessage } = useLocales()

  return (
    <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
      {isGoogleBookingEnabled && (
        <IntegrationField label={formatMessage(SeatingAreasLocales.googleReserveLabel)}>
          <FormSelect data-test={SeatingAreasTestId.googleReserveField} options={googleReserveSeatingOptions} field={googleReserveField} />
        </IntegrationField>
      )}
      {isTheforkIntegrationEnabled && (
        <IntegrationField label={formatMessage(SeatingAreasLocales.theForkLabel)}>
          <FormSelect data-test={SeatingAreasTestId.theForkField} options={theForkSeatingOptions} field={theForkField} />
        </IntegrationField>
      )}
    </Grid>
  )
}

function IntegrationField({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <Box maxWidth="100%" gridColumn="auto / span 4">
      <Label primary={label}>{children}</Label>
    </Box>
  )
}
