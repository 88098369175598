import * as React from 'react'
import { useCallback } from 'react'
import { type RouteComponentProps, withRouter } from 'react-router'
import { Prompt as OldPrompt } from 'react-router-dom'
import { TransitionBlocker } from './TransitionBlocker'

interface TransitionBlockerProps extends RouteComponentProps {
  modal: React.ReactElement
  isBlocked: boolean
  blockBeforeUnload?: boolean
  onConfirmTransition?: (path: string) => void
  skipQuery?: boolean
}

// Could be removed in the future when we stop using several react routers of different versions
function Blocker({ modal, isBlocked, blockBeforeUnload = true, history, onConfirmTransition, skipQuery }: TransitionBlockerProps) {
  const onConfirmTransitionHandler = useCallback(
    (pathname: string) => {
      history.push(pathname)
      onConfirmTransition?.(pathname)
    },
    [history, onConfirmTransition]
  )

  return (
    <TransitionBlocker
      modal={modal}
      isBlocked={isBlocked}
      blockBeforeUnload={blockBeforeUnload}
      onConfirmTransition={onConfirmTransitionHandler}
      // For old router version transitions
      renderCustomBlocker={handleBlockedRoute => <OldPrompt when message={handleBlockedRoute} />}
      skipQuery={skipQuery}
    />
  )
}

export const TransitionBlockerWithOldRouterSupport = withRouter(Blocker)
