import { defineMessages } from '@sevenrooms/core/locales'

export const ReservationTagsLocales = defineMessages({
  title: {
    id: 'ar.reservationTags.title',
    defaultMessage: 'Reservation Tags',
  },
  subCaption: {
    id: 'ar.reservationTags.subCaption',
    defaultMessage: 'Label reservations booked through this Access Rule for your front of house staff.',
  },
  description: {
    id: 'ar.reservationTags.description',
    defaultMessage: 'Ex: Add "Patio" tag so the front of house staff know that the guest has booked the Patio.',
  },
  modalBody: {
    id: 'ar.reservationTags.modalBody',
    defaultMessage: 'Restoring default settings will clear all edits. No reservation tags will be associated with this Access Rule.',
  },
  label: {
    id: 'ar.reservationTags.label',
    defaultMessage: 'Reservation Tags:',
  },
  searchPlaceholder: {
    id: 'ar.reservationTags.searchPlaceholder',
    defaultMessage: 'Search Reservation Tags',
  },
} as const)
