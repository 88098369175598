import { useMemo } from 'react'
import { useForm, type UseForm, useFormContext, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingsBlock, SettingsBlockInputs } from '../SettingsBlock'
import { SettingValue } from '../SettingValue'
import { TextInput } from '../TextInput'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './CybersourceRedux3ds.locales'
import type { PaymentIntegration, PaymentIntegrationConfiguration, PaymentIntegrationSettings } from '../interfaces'

function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()

  return (
    <ViewSettingsBlock>
      <Text textStyle="body1Bold">{formatMessage(messages.accountAndAuthentication)}</Text>
      <SettingValue name={(currentConfiguration?.merchantId as string) || '-'} title={formatMessage(messages.merchantId)} />
      <SettingValue name={(currentConfiguration?.apiKeyId as string) || '-'} title={formatMessage(messages.apiKeyId)} />
      <SettingValue name={(currentConfiguration?.apiKey as string) || '-'} title={formatMessage(messages.apiKey)} />
    </ViewSettingsBlock>
  )
}

export function useCS3DSReduxForm(formDefault?: PaymentIntegrationSettings) {
  const schema = useMemoSchemaCS3DSRedux() as unknown as z.ZodObject<{
    [key: string]: z.ZodDefault<z.ZodOptional<z.ZodString>> | z.ZodString | z.ZodBoolean
  }>
  return useForm(schema, { defaultValues: formDefault })
}

function SettingsForm() {
  const { formatMessage } = useLocales()
  const { field } = useFormContext() as UseForm<z.ZodType<SchemaCS3DSRedux>>
  return (
    <>
      <SettingsBlock header={formatMessage(messages.cybersourceAccount)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('merchantId')}
            label={formatMessage(messages.merchantId)}
            placeholder={formatMessage(messages.merchantId)}
            description={formatMessage(messages.merchantIdLabel, {
              a: (chunks: string[]) => <Anchor href={BusinessCenterLink}>{chunks}</Anchor>,
            })}
            data-test="payment-settings-cs-mid-input"
          />
          <TextInput
            field={field.prop('apiKeyId')}
            label={formatMessage(messages.apiKeyId)}
            placeholder={formatMessage(messages.apiKeyId)}
            description={formatMessage(messages.apiKeyIdLabel, {
              a: (chunks: string[]) => <Anchor href={BusinessCenterLink}>{chunks}</Anchor>,
            })}
            data-test="payment-settings-cs-keyid-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('apiKey')}
            label={formatMessage(messages.apiKey)}
            placeholder={formatMessage(messages.apiKey)}
            description={formatMessage(messages.apiKeyLabel, {
              a: (chunks: string[]) => <Anchor href={BusinessCenterLink}>{chunks}</Anchor>,
            })}
            data-test="payment-settings-cs-key-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
    </>
  )
}

function createSchemaCS3DSRedux(requiredErrorMessage: string) {
  return z.object({
    merchantId: z.string().trim().min(1, requiredErrorMessage).default(''),
    apiKeyId: z.string().trim().min(1, requiredErrorMessage).default(''),
    apiKey: z.string().trim().min(1, requiredErrorMessage).default(''),
  })
}
type SchemaCS3DSRedux = z.infer<ReturnType<typeof createSchemaCS3DSRedux>>

function useMemoSchemaCS3DSRedux() {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(commonMessages.required)

  return useMemo(() => createSchemaCS3DSRedux(requiredErrorMessage), [requiredErrorMessage])
}

export const BusinessCenterLink = 'https://ebc2.cybersource.com/ebc2/'

export const CybersourceRedux3ds: PaymentIntegration = {
  currentConfiguration: {},
  integrationName: AccountTypes.CYBERSOURCE_3DS_REDUX,
  ShowCurrentSettings,
  SettingsForm,
  formDefault: {},
  useFormHook: useCS3DSReduxForm,
}
