import React from 'react'
import styled from 'styled-components'
import { SlideoutContent } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

export const Content = styled(SlideoutContent)`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const Heading = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSize.subheader};
  justify-content: space-between;
  padding: 36px 24px ${props => props.theme.gutter.standard} 24px;
`

export const SubHead = styled.div`
  display: flex;
`

export const Title = styled.div`
  font-weight: bold;
  margin-left: ${props => props.theme.gutter.standard};
`

export const Description = styled.div`
  font-size: ${props => props.theme.fontSize.body};
  padding: 0 ${props => props.theme.gutter.triple} 0;
`

const Indicator = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.body};
  line-height: 28px;
  margin-left: 5px;
  text-transform: uppercase;
  width: 30px;
`

const IndicatorOn = styled(Indicator)`
  color: ${props => props.theme.color.teal};
`

export const TextIndicator = ({ on }) => (on ? <IndicatorOn>On</IndicatorOn> : <Indicator>Off</Indicator>)

export const Tiers = styled.div`
  margin: 0 ${props => props.theme.gutter.double};
`

const TierBase = styled.div`
  border: 1px solid ${props => props.theme.color.greyMuted};
  border-radius: 4px;
  font-size: ${props => props.theme.fontSize.body};
  margin: ${props => props.theme.gutter.standard} 0;
  padding: ${props => props.theme.gutter.double};
`

export const Tier = styled(TierBase)`
  cursor: ${props => (props.isActive ? 'pointer' : 'default')};
  background: ${props => (props.isActive ? 'none' : props.theme.color.greyMuted)};
  &:hover {
    background: ${props => (props.isActive ? props.theme.color.rowHover : props.theme.color.greyMuted)};
  }
`

export const TierEditing = styled(TierBase)`
  border-color: ${props => (props.error ? props.theme.color.errorAccent : props.theme.color.greyMuted)};
`

export const TierTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: ${props => props.theme.gutter.double};
`

export const TierContent = styled.div``

export const TierEditor = styled(TierContent)`
  line-height: 3.7;
`

export const TighterTierEditor = styled(TierEditor)`
  line-height: 2.7;
`

export const Rename = styled.span`
  color: ${props => props.theme.color.teal};
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.small};
  font-weight: normal;
  margin-left: 10px;
  text-decoration: underline;
  text-transform: lowercase;
`

export const TierDelete = styled.div`
  color: ${props => props.theme.color.errorAccent};
  cursor: pointer;
  display: inline-block;
  float: right;
`

export const AddTier = styled.div`
  color: ${props => props.theme.color.teal};
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.small};
  font-weight: bold;
  margin: 10px;
  text-decoration: underline;
  text-transform: uppercase;
`

export const DeleteWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Yes, Remove Tag"
    explanation="Are you sure you want to remove this tier? You will not be able to make additional changes until we are done pulling the tags."
  />
)

export const LastUpdated = styled.div`
  margin-bottom: ${props => props.theme.gutter.triple};
  font-size: ${props => props.theme.fontSize.small};
  color: #888c92;
  padding: 0 ${props => props.theme.gutter.triple} 0;
`
