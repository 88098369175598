import { useMemo } from 'react'
import type { PerkAvailabilityTimes } from '@sevenrooms/core/api'
import { type PerkAvailabilityType, PerkAvailabilityTypeEnum, type Perk } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  type TimeRangePickerForm,
  useTimeRangePickerForm,
  dateRangePickerMessages,
  formTimeRangePickerMessages,
} from '@sevenrooms/core/ui-kit/form'
import { START_TIME, END_TIME } from '../../constants'

export type PerkAvailabilityRestrictionsFormData = ZodSchema<typeof usePerkAvailabilityRestrictionsForm>
export type PerkAvailabilityRestrictionsFormField = Field<PerkAvailabilityRestrictionsFormData>

export function usePerkAvailabilityRestrictionsForm() {
  const timeRange = useTimeRangePickerForm({ startOfDayTime: '0:00' })
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z
        .object({
          perkAvailabilityType: z.custom<PerkAvailabilityType>(),
          dateRange: z.object({
            startDate: z.date().nullable(),
            endDate: z.date().nullable(),
            isInfinite: z.boolean(),
          }),
          isRestrictedByDateRange: z.boolean(),
          isRestrictedByWeekOfDayAndTimeRange: z.boolean(),
          dowOffered: z.number().array(),
          timeRanges: z.array(timeRange),
        })
        .superRefine(
          (
            { isRestrictedByWeekOfDayAndTimeRange, perkAvailabilityType, dowOffered, timeRanges, isRestrictedByDateRange, dateRange },
            ctx
          ) => {
            if (perkAvailabilityType === PerkAvailabilityTypeEnum.AVAILABILITY_RESTRICTION) {
              if (isRestrictedByWeekOfDayAndTimeRange) {
                if (!dowOffered?.length) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.too_small,
                    inclusive: true,
                    minimum: 1,
                    path: ['dowOffered'],
                    type: 'array',
                  })
                }
                dowOffered.forEach(day => {
                  const startTime = timeRanges?.[day]?.startTime
                  const endTime = timeRanges?.[day]?.endTime

                  if (startTime && endTime && typeof startTime === 'string' && typeof endTime === 'string') {
                    if (startTime > endTime) {
                      ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: formatMessage(formTimeRangePickerMessages.errorGreaterThanEnd),
                        path: [`timeRanges.${day}`],
                      })
                    } else if (startTime === endTime) {
                      ctx.addIssue({
                        path: [`timeRanges.${day}`],
                        code: z.ZodIssueCode.custom,
                        message: formatMessage(formTimeRangePickerMessages.errorSameTime),
                      })
                    }
                  }
                })
              }
              if (isRestrictedByDateRange) {
                if (!dateRange?.startDate) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(dateRangePickerMessages.startDateRequired),
                    path: ['dateRange.startDate'],
                  })
                }
                if (!(dateRange?.isInfinite || dateRange?.endDate)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(dateRangePickerMessages.endDateRequired),
                    path: ['dateRange.endDate'],
                  })
                } else if (dateRange.endDate != null && dateRange.startDate != null && dateRange.endDate <= dateRange.startDate) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(dateRangePickerMessages.wrongEndDate),
                    path: ['dateRange.startDate'],
                  })
                }
              }
            }
          }
        ),
    [timeRange, formatMessage]
  )
}

export const getPerkAvailabilityRestrictionsFormDefaultValues = (
  perk?: Perk,
  startOfDayTime?: string
): PerkAvailabilityRestrictionsFormData => ({
  perkAvailabilityType: perk?.perkAvailabilityType || PerkAvailabilityTypeEnum.ANY_TIME,
  isRestrictedByDateRange: perk?.isRestrictedByDateRange || false,
  isRestrictedByWeekOfDayAndTimeRange: perk?.isRestrictedByWeekOfDayAndTimeRange || false,
  dowOffered: perk?.dowOffered.map((day, index) => (day ? index : -1)).filter(dayIndex => dayIndex !== -1) || [0, 1, 2, 3, 4, 5, 6],
  dateRange: getDateRange({ perk }),
  timeRanges: perk?.perkAvailabilityTimes
    ? perkAvailabilityTimesToTimeRanges({ perkAvailabilityTimes: perk?.perkAvailabilityTimes })
    : Array(7).fill({
        startTime: startOfDayTime,
        endTime: END_TIME,
      }),
})

// API endDate: null is equal to is infinite
// On create isInfinite is true, endDate is null
// On update, use perk.endDate
export const getDateRange = ({ perk }: { perk: Perk | undefined }) => ({
  startDate: perk?.startDate?.toJsDate() || new Date(),
  endDate: perk?.endDate?.toJsDate() || null,
  isInfinite: perk?.endDate === null || !perk?.id,
})

export const perkAvailabilityTimesToTimeRanges = ({
  perkAvailabilityTimes,
}: {
  perkAvailabilityTimes: PerkAvailabilityTimes
}): TimeRangePickerForm[] => {
  const timeRangePickerForms: TimeRangePickerForm[] = [1, 2, 3, 4, 5, 6, 7].map(perkAvailabilityTime => ({
    ...(perkAvailabilityTimes?.[perkAvailabilityTime] || {
      startTime: START_TIME,
      endTime: END_TIME,
    }),
  }))

  return timeRangePickerForms
}
