// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'

export const ExperienceFormEditorWrapper = styled.div`
  .fr-wrapper {
    border: ${`1px solid ${theme.colors.borders}`} !important;
    border-bottom-width: 2px !important;
    overflow-y: hidden !important;
    border-radius: 0 0 ${theme.borderRadius.s} ${theme.borderRadius.s} !important;
  }
  .fr-toolbar.fr-top {
    border-top-left-radius: ${theme.borderRadius.s} !important;
    border-top-right-radius: ${theme.borderRadius.s} !important;
  }
  .fr-second-toolbar {
    border: 0 !important;
  }
  .fr-view {
    border-radius: 0 !important;
  }
  .fr-view {
    border: 0;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    overflow: auto;
  }
  .fr-view table {
    margin-bottom: 0px;
  }
  .fr-view table td {
    padding: 0px;
  }
  .fr-view table td,
  .fr-view table th {
    border: none;
  }
`
