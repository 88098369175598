import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { UpgradesLocales } from '../Upgrades.locales'

export function DefaultUpgrades() {
  const { shifts } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  return (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart caption={[formatMessage(accessRulesMessages.shift), formatMessage(UpgradesLocales.modalUpgrades)]}>
          {shifts.map(row => (
            <ReportLine key={row.id} name={row.name}>
              {row.upsellCategories.length > 0 ? row.upsellCategories.join(', ') : formatMessage(commonMessages.none)}
            </ReportLine>
          ))}
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
