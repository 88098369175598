import type { BackwriterReviewSummaryItem } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { reviewsMessages } from '../../../locales'

export interface AISummaryCardProps {
  sectionId: string
  sectionTitle: string
  data?: BackwriterReviewSummaryItem[]
  emptyMessage: string
  setShowSlideout: Function
  isFetching: boolean
  compact: boolean
}

export function AISummaryCard({ sectionId, sectionTitle, emptyMessage, data, setShowSlideout, isFetching, compact }: AISummaryCardProps) {
  const { formatMessage } = useLocales()

  let content
  if (isFetching) {
    content = (
      <VStack justifyContent="center" textAlign="center" minHeight="150px">
        <Loader />
      </VStack>
    )
  } else if (data && data.length > 0) {
    content = (
      <Box ml="s" data-test="ai-summary-card-content">
        <List spacing="s">
          {data.map(({ title, description }) => (
            <ListItem key={title}>
              {compact ? (
                <Text>{title}</Text>
              ) : (
                <>
                  <Text fontWeight="bold">{title}: </Text>
                  <Text>{description}</Text>
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    )
  } else {
    content = (
      <Text color="secondaryFont" fontStyle="italic">
        {emptyMessage}
      </Text>
    )
  }

  return (
    <Box width="100%" height="100%" data-test="ai-summary-card">
      <BaseSection title={sectionTitle} collapsed={false} padding="m">
        <Box p="m" minHeight="150px">
          {content}
        </Box>
        {compact && (
          <HStack justifyContent="end" mb="m">
            <Button variant="tertiary" onClick={() => setShowSlideout(true)} data-test={`ai-summary-see-details-${sectionId}`}>
              {formatMessage(reviewsMessages.aiSummarySeeDetails)}
            </Button>
          </HStack>
        )}
      </BaseSection>
    </Box>
  )
}
