import React from 'react'
import { css } from 'styled-components'
import {
  ValueWrapper,
  InlineText,
  InvalidMessage,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagShared'
import PositiveIntInput from 'svr/component-lib/Generic/TextInputs/PositiveIntInput'

const CustomAutotagDateDelta = ({ id, value, onChange, isValid, invalidMessage }) => (
  <div>
    <ValueWrapper
      id={`${id}-value-wrapper`}
      css={`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 120px;
      `}
    >
      <PositiveIntInput
        inputId={id}
        value={value}
        onChange={onChange}
        customInputWrapper={css`
          height: 35px;
          width: 59px;
        `}
        customInput={css`
          height: 35px;
          box-sizing: border-box;
          border-width: 1px;
          border-color: #dbdcde;
          outline: none;
          font-size: 14px;
        `}
        allowEmpty
      />
      <InlineText>day(s)</InlineText>
    </ValueWrapper>

    {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
  </div>
)

export default CustomAutotagDateDelta
