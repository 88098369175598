import { defineMessages } from '@sevenrooms/core/locales'

export const promoCodeMessages = defineMessages({
  promoCodeCreateTitle: {
    id: 'promoCode.create.title',
    defaultMessage: 'Promo Code Creation',
  },
  promoCodeEditTitle: {
    id: 'promoCode.edit.title',
    defaultMessage: 'Edit Promo Code',
  },
  promoCodeDescription: {
    id: 'promoCode.create.description',
    defaultMessage: 'These promo codes are used at checkout on the widgets.',
  },
  promoCodeSaveErrorMessage: {
    id: 'promoCode.save.message.error',
    defaultMessage: 'There was an error when saving the promo code. Please try again.',
  },
  promoCodeSaveDuplicateErrorMessage: {
    id: 'promoCode.save.message.duplicateError',
    defaultMessage: 'This promo code already exists',
  },
  promoCodeCreateSuccessMessage: {
    id: 'promoCode.create.message.success',
    defaultMessage: 'Promo code has been successfully created.',
  },
  promoCodeEditSuccessMessage: {
    id: 'promoCode.edit.message.success',
    defaultMessage: 'Promo code has been successfully saved.',
  },
  promoCodeLoadErrorMessage: {
    id: 'promoCode.page.load.error',
    defaultMessage: 'Unable to load the page, please try again.',
  },

  promoCodeFormGeneralSection: {
    id: 'promoCode.form.general.section',
    defaultMessage: 'General',
  },
  promoCodeFormNameLabel: {
    id: 'promoCode.form.name.label',
    defaultMessage: 'Name',
  },
  promoCodeFormNameDescription: {
    id: 'promoCode.form.name.description',
    defaultMessage: 'This is for internal use only.',
  },
  promoCodeFormNamePlaceholder: {
    id: 'promoCode.form.name.placeholder',
    defaultMessage: 'Eg. Happy Hour 20% Discount',
  },
  promoCodeFormNameErrorMessage: {
    id: 'promoCode.form.name.error.message',
    defaultMessage: 'This is a required field.',
  },
  promoCodeFormDescriptionLabel: {
    id: 'promoCode.form.name.description',
    defaultMessage: 'Internal Description (optional)',
  },
  promoCodeFormDescriptionPlaceholder: {
    id: 'promoCode.form.description.placeholder',
    defaultMessage: 'Enter description',
  },
  promoCodeFormCodeTypeLabel: {
    id: 'promoCode.form.codeType.description',
    defaultMessage: 'What venue(s) can this code be used at',
  },
  promoCodeFormCodeLabel: {
    id: 'promoCode.form.code.label',
    defaultMessage: 'Code',
  },
  promoCodeFormCodeDescription: {
    id: 'promoCode.form.code.description',
    defaultMessage: 'The code that the customer will input at checkout.',
  },
  promoCodeFormCodePlaceholder: {
    id: 'promoCode.form.code.placeholder',
    defaultMessage: 'Eg. HAPPY20',
  },
  promoCodeFormCodeErrorMessage: {
    id: 'promoCode.form.code.error.message',
    defaultMessage: 'This is a required field.',
  },
  promoCodeFormPlatformApplyTypeLabel: {
    id: 'promoCode.form.platformApplyType.label',
    defaultMessage: 'Where can this code be used',
  },
  promoCodeFormPlatformApplyTypePlaceholder: {
    id: 'promoCode.form.platformApplyType.placeholder',
    defaultMessage: 'Select widget(s)',
  },
  promoCodeFormPlatformApplyTypeErrorMessage: {
    id: 'promoCode.form.platformApplyType.error.message',
    defaultMessage: 'This is a required field.',
  },

  promoCodeFormPromoTypesSection: {
    id: 'promoCode.form.promoTypes.section',
    defaultMessage: 'Promo Types',
  },
  promoCodeFormDiscountedValueLabel: {
    id: 'promoCode.form.discountedValue.label',
    defaultMessage: 'Discounted value',
  },
  promoCodeFormDiscountedValueFixedAmountErrorMessage: {
    id: 'promoCode.form.discountedValue.fixedAmount.error',
    defaultMessage: 'Please enter a dollar amount.',
  },
  promoCodeFormDiscountedValuePercentageErrorMessage: {
    id: 'promoCode.form.discountedValue.percentage.error',
    defaultMessage: 'Please enter a percentage.',
  },
  promoCodeFormFixedAmountLabel: {
    id: 'promoCode.form.fixedAmount.label',
    defaultMessage: 'Up to a fixed amount cap',
  },
  promoCodeFormUpFixedAmountTooltipTitle: {
    id: 'promoCode.form.upFixedAmount.tooltip.title',
    defaultMessage: 'Why should I set a fixed amount cap?',
  },
  promoCodeFormUpFixedAmountTooltipContent: {
    id: 'promoCode.form.upFixedAmount.tooltip.content',
    defaultMessage: 'Set a cap on a percentage promo to prevent a very large a discount when a big order is placed',
  },
  promoCodeFormFixedAmountCapErrorMessage: {
    id: 'promoCode.form.fixedAmountCap.error',
    defaultMessage: 'Please enter a dollar amount.',
  },
  promoCodeFormMinSubtotalErrorMessage: {
    id: 'promoCode.form.minSubtotal.error',
    defaultMessage: 'Please enter a dollar amount.',
  },
  promoCodeFormRequireMinimumSubtotalLabel: {
    id: 'promoCode.form.requireMinimumSubtotal.label',
    defaultMessage: 'Require minimum subtotal for guest to use this code',
  },

  promoCodeFormUsageSection: {
    id: 'promoCode.form.usage.section',
    defaultMessage: 'Usage',
  },
  promoCodeFormPromoUsageLabel: {
    id: 'promoCode.form.usage.label',
    defaultMessage: 'How many total times can this code be used?',
  },
  promoCodeFormPromoLimitLabel: {
    id: 'promoCode.form.limit.label',
    defaultMessage: 'Maximum number of total uses',
  },
  promoCodeFormPromoLimitPlaceholder: {
    id: 'promoCode.form.limit.placeholder',
    defaultMessage: 'Ex: 100',
  },
  promoCodeFormPromoUsageUnlimitedChoice: {
    id: 'promoCode.form.usage.unlimited',
    defaultMessage: 'Unlimited use',
  },
  promoCodeFormPromoUsageLimitedChoice: {
    id: 'promoCode.form.usage.limited',
    defaultMessage: 'Specific number of uses',
  },
  promoCodeFormPromoUsageLimitedChoiceTooltipContent: {
    id: 'promoCode.form.usage.limited.tooltip.content',
    defaultMessage: 'We do not support number of uses per guest because we do not require a guest to sign in to check out.',
  },
  promoCodeFormPromoLimitError: {
    id: 'promoCode.form.limit.error',
    defaultMessage: 'Must be at least {uses}, which is the current number used.',
  },

  promoCodeFormLifespanSection: {
    id: 'promoCode.form.lifespan.section',
    defaultMessage: 'Lifespan',
  },

  promoCodeFormAdvancedSection: {
    id: 'promoCode.form.advanced.section',
    defaultMessage: 'Advanced Settings',
  },
  promoCodeFormAdvancedSectionDescription: {
    id: 'promoCode.form.advanced.section,description',
    defaultMessage: 'These settings are optional.',
  },
  promoCodeFormPromoComponentLabel: {
    id: 'promoCode.form.promoComponent.label',
    defaultMessage: 'Application of code',
  },
  promoCodeFormPromoComponentAllChoice: {
    id: 'promoCode.form.promoComponent.all',
    defaultMessage: 'I want to apply this code on both the base reservation price and upgrades',
  },
  promoCodeFormPromoComponentBasePriceChoice: {
    id: 'promoCode.form.promoComponent.basePrice',
    defaultMessage: 'I want to only apply this code on the base reservation price (this does not include upgrades)',
  },
  promoCodeFormPromoComponentUpgradesChoice: {
    id: 'promoCode.form.promoComponent.upgrades',
    defaultMessage: 'I want to only apply this on upgrades (this does not include base reservation price)',
  },
  promoCodeFormCreditCardLabel: {
    id: 'promoCode.form.creditCard.label',
    defaultMessage: 'Credit Card Type',
  },
  promoCodeFormCreditCardDescription: {
    id: 'promoCode.form.creditCard.description',
    defaultMessage: 'Select a specific credit card type to limit the promo.',
  },
  promoCodeFormCreditCardNoneLabel: {
    id: 'promoCode.form.creditCard.none.label',
    defaultMessage: 'All (Mastercard, AMEX, Discover, VISA, Diners Club, JCB)',
  },
  promoCodeFormCreditCardAmericanExpressLabel: {
    id: 'promoCode.form.creditCard.americanExpress.label',
    defaultMessage: 'AMEX',
  },
  promoCodeFormCreditCardDiscoverLabel: {
    id: 'promoCode.form.creditCard.discover.label',
    defaultMessage: 'Discover',
  },
  promoCodeFormCreditCardMasterCardLabel: {
    id: 'promoCode.form.creditCard.masterCard.label',
    defaultMessage: 'Mastercard',
  },
  promoCodeFormCreditCardVisaLabel: {
    id: 'promoCode.form.creditCard.visa.label',
    defaultMessage: 'Visa',
  },
  promoCodeFormCreditCardDinersClubLabel: {
    id: 'promoCode.form.creditCard.dinersClub.label',
    defaultMessage: 'Diners Club',
  },
  promoCodeFormCreditCardJCBLabel: {
    id: 'promoCode.form.creditCard.JCB.label',
    defaultMessage: 'JCB',
  },
  promoCodeFormEventsLabel: {
    id: 'promoCode.form.events.label',
    defaultMessage: 'Select a specific event to have promo',
  },
  promoCodeFormEventsAllLabel: {
    id: 'promoCode.form.events.all.label',
    defaultMessage: 'All events',
  },
  promoCodeUnsavedChangesModalTitle: {
    id: 'promoCode.modal.unsavedChanges.title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
} as const)
