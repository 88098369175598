import * as React from 'react'
import { DividerLine, VStack } from '@sevenrooms/core/ui-kit/layout'
import { EmailCampaignPerformanceContext, EmailCampaignPerformance } from '@sevenrooms/marketing'
import { oneTimeEmailCenterMessages } from '../../locales'
import { OneTimeEmailCampaigns } from '../OneTimeEmailCampaigns'

export interface OneTimeEmailsProps {}

export function OneTimeEmails() {
  const [campaignPerformanceCollapsed, setCampaignPerformanceCollapsed] = React.useState<boolean>(true)

  return (
    <VStack backgroundColor="primaryBackground" borderColor="borders" borderRadius="s">
      <EmailCampaignPerformanceContext isOneTimeCampaignList>
        <EmailCampaignPerformance
          collapsed={campaignPerformanceCollapsed}
          onCollapsedChange={setCampaignPerformanceCollapsed}
          isOneTimeCampaignList
          messages={oneTimeEmailCenterMessages}
        />

        <DividerLine margin="none" />

        <OneTimeEmailCampaigns />
      </EmailCampaignPerformanceContext>
    </VStack>
  )
}
