import { defineMessages } from '@sevenrooms/core/locales'

export const priorityAlertsTiersMessages = defineMessages({
  tierOneLabel: {
    id: 'settings.reservations.priorityAlertsTiers.tierOneLabel',
    defaultMessage: 'Tier 1',
  },
  tierTwoLabel: {
    id: 'settings.reservations.priorityAlertsTiers.tierTwoLabel',
    defaultMessage: 'Tier 2',
  },
  tierThreeLabel: {
    id: 'settings.reservations.priorityAlertsTiers.tierThreeLabel',
    defaultMessage: 'Tier 3',
  },
  everyoneElseTierLabel: {
    id: 'settings.reservations.priorityAlertsTiers.everyoneElseTierLabel',
    defaultMessage: 'Everyone else',
  },
} as const)
