import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'paymentsSettings.DocumentationBanner.title',
    defaultMessage: 'Having trouble choosing a payment processor?',
  },
  description: {
    id: 'paymentsSettings.DocumentationBanner.description',
    defaultMessage: 'Check out our Payments Overview guide to learn more about your options, features and things to consider.',
  },
  button: {
    id: 'paymentsSettings.DocumentationBanner.button',
    defaultMessage: 'Explore integrations',
  },
} as const)
