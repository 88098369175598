import type { Shift } from '@sevenrooms/core/domain'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { generateOrderedTimeSlots, calculateTotalMinutes } from '../shared/utils'

export const HIGH_PACING_THRESHOLD = 5

export interface TimeSlot {
  id: string
  label: string
}

export function generateTimeSlots(startOfDayTime: string): TimeSlot[] {
  return generateOrderedTimeSlots(startOfDayTime).map(({ hours, mins, label }) => ({
    id: calculateTotalMinutes(hours, mins),
    label,
  }))
}

export function transformPacingMinutesToDisplayTime(customPacing: Record<string, number | null> | {}) {
  const formattedCustomPacing: Record<string, number> = {}
  for (const [key, value] of Object.entries(customPacing)) {
    if (value != null) {
      const minutes = Number(key)
      formattedCustomPacing[TimeOnly.from({ minutes }).formatSTime()] = value
    }
  }
  return formattedCustomPacing
}

function isTimeSlotInShift(timeSlot: string, shift: Shift) {
  if (!shift.startTime || !shift.endTime) {
    return false
  }
  const time = Number.parseInt(timeSlot)
  const startTime = shift.startTime.toMinutes()
  const endTime = shift.endTime.toMinutes()
  return startTime <= endTime ? time >= startTime && time <= endTime : time <= endTime || time >= startTime
}

export function collateTimeSlots(shift: Shift, arDefaultPacing: number, customPacing: Record<string, number | null | undefined>) {
  const pacingEntries: { [key: string]: { shiftPacing: number; arPacing: number } } = {}
  Object.entries(shift.customPacing).forEach(([timeSlot, shiftPacing]) => {
    pacingEntries[timeSlot] = {
      shiftPacing,
      arPacing: customPacing[timeSlot] ?? arDefaultPacing,
    }
  })
  Object.entries(customPacing)
    .filter(([timeSlot, _pacing]) => !(timeSlot in pacingEntries) && isTimeSlotInShift(timeSlot, shift))
    .forEach(([timeSlot, arPacing]) => {
      pacingEntries[timeSlot] = {
        shiftPacing: shift.defaultPacing,
        arPacing: arPacing ?? 0,
      }
    })
  return pacingEntries
}

export function isArReservingHighPacing(
  shifts: Shift[],
  maxCoversPerSeatingInterval: number | null,
  customPacing?: Record<string, number | null | undefined>
) {
  const arDefaultPacing = maxCoversPerSeatingInterval ?? 0
  const isDefaultPacingHigh = shifts.some(shift => shift.defaultPacing - arDefaultPacing <= HIGH_PACING_THRESHOLD)
  const isCustomPacingHigh = shifts.some(shift =>
    Object.entries(collateTimeSlots(shift, arDefaultPacing, customPacing ?? {})).some(
      ([_key, { shiftPacing, arPacing }]) => shiftPacing - arPacing <= HIGH_PACING_THRESHOLD
    )
  )
  return isDefaultPacingHigh || isCustomPacingHigh
}
