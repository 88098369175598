import { useGetAccessRulesListQuery } from '@sevenrooms/core/api'
import type { Experience } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { ExperienceAccessRules } from '@sevenrooms/mgr-offers/experiences/components/ExperienceAccessRules/ExperienceAccessRules'
import type { SpaceFormType } from './Space.zod'

interface AccessRulesProps {
  field: Field<SpaceFormType>
  experience?: Experience
}

export function AccessRules({ field, experience }: AccessRulesProps) {
  const { venueId } = useVenueContext()
  const { data: accessRuleData, isLoading: isARListLoading } = useGetAccessRulesListQuery({ venueId })
  const { data: accessRulesSlideoutData, isLoading: isARSlideoutDataLoading } = useAccessRulesSlideoutData()

  return isARListLoading || isARSlideoutDataLoading ? (
    <Loader />
  ) : (
    <ExperienceAccessRules
      experience={experience}
      accessRuleData={accessRuleData}
      accessRulesSlideoutData={accessRulesSlideoutData}
      unlinkedAccessRuleIDsField={field.prop('unlinkedAccessRuleIDs')}
      linkedAccessRuleIDsField={field.prop('linkedAccessRuleIDs')}
    />
  )
}
