import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DurationsLocales } from './Durations.locales'

export type DurationsForm = ZodSchema<typeof useDurationsForm>

export type DurationsConfigType = 'ALL_PARTY_SIZES' | 'BY_PARTY_SIZE'

export function useDurationsForm() {
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z
        .object({
          useShiftDurations: z.boolean(),
          durationsConfigType: z.custom<DurationsConfigType>(),
          durationAllPartySizes: z.number().nullable(),
          durationsByPartySize: z.object({
            '1': z.number().nullable(),
            '2': z.number().nullable(),
            '3': z.number().nullable(),
            '4': z.number().nullable(),
            '5': z.number().nullable(),
            '6': z.number().nullable(),
            '7': z.number().nullable(),
            '8': z.number().nullable(),
            '9': z.number().nullable(),
            '10': z.number().nullable(),
          }),
        })
        .superRefine(({ useShiftDurations, durationsConfigType, durationAllPartySizes }, ctx) => {
          if (useShiftDurations) {
            return
          }

          if (durationsConfigType === 'ALL_PARTY_SIZES' && !durationAllPartySizes) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(DurationsLocales.errorDurationRequired),
              path: ['durationAllPartySizes'],
            })
          }
        }),
    [formatMessage]
  )
}

export function getInitialDurations(accessRule?: AccessRule): DurationsForm {
  const durations = Object.values(accessRule?.durationMinutesByPartySize ?? {})
  const useDurationAllPartySizes = durations.every(x => x === durations[0])

  return {
    useShiftDurations: !durations.some(x => x),
    durationsConfigType: useDurationAllPartySizes ? 'ALL_PARTY_SIZES' : 'BY_PARTY_SIZE',
    durationAllPartySizes: useDurationAllPartySizes ? durations[0] ?? null : null,
    durationsByPartySize: transformDurations(accessRule?.durationMinutesByPartySize ?? {}),
  }
}

export function transformDurations(durations: Record<string, number>) {
  return {
    '1': durations['1'] ?? null,
    '2': durations['2'] ?? null,
    '3': durations['3'] ?? null,
    '4': durations['4'] ?? null,
    '5': durations['5'] ?? null,
    '6': durations['6'] ?? null,
    '7': durations['7'] ?? null,
    '8': durations['8'] ?? null,
    '9': durations['9'] ?? null,
    '10': durations['10'] ?? null,
  }
}
