import { useState } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { PaymentPolicyDefaultsModal } from './PaymentPolicyDefaultsModal'

export interface FollowShiftProps {
  field: Field<boolean>
}

export function FollowShift({ field }: FollowShiftProps) {
  return (
    <Checkbox data-test={PaymentPolicyTestId.followShiftSettingsForPayment} field={field}>
      <FollowShiftMessage />
    </Checkbox>
  )
}

export function FollowShiftMessage() {
  const { formatMessage } = useLocales()
  const [isDefaultsOpen, setIsDefaultsOpen] = useState(false)
  return (
    <>
      {formatMessage(PaymentPolicyLocales.followShiftSettingsLabel, {
        a: (chunks: string[]) => (
          <Button variant="tertiary" noPadding onClick={() => setIsDefaultsOpen(true)} data-test="follow-shift-button">
            {chunks}
          </Button>
        ),
      })}
      <Window active={isDefaultsOpen}>
        <PaymentPolicyDefaultsModal onDone={() => setIsDefaultsOpen(false)} />
      </Window>
    </>
  )
}
