import { AccessRuleTimeUnitEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { timeBeforeForm } from '@sevenrooms/mgr-access-rules-slideout/components/shared'

export type PerkSelectAccessRuleModalFormData = ZodSchema<typeof usePerkSelectAccessRuleModalForm>
export type PerkSelectAccessRuleModalFormField = Field<PerkSelectAccessRuleModalFormData>

export function usePerkSelectAccessRuleModalForm() {
  return z.object({
    releaseTableTimeData: timeBeforeForm,
    accessRuleToCloneIds: z.array(z.string()),
  })
}

export const getPerkSelectAccessRuleModalDefaultValues = (): PerkSelectAccessRuleModalFormData => ({
  releaseTableTimeData: {
    count: 60,
    unit: AccessRuleTimeUnitEnum.DAYS,
    beforeTime: '0',
  },
  accessRuleToCloneIds: [],
})
