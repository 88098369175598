import type { Shift } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { ScheduleBlock } from '../MultiDayScheduleGrid/ScheduleBlock'

const BACKGROUND_COLOR = '#3E93D126'

interface ShiftBlockProps {
  shift: Shift
  date: DateOnly
  column: number
  startSortOrder: number
  endSortOrder: number
  isLeft?: boolean
  isRight?: boolean
  disableLabel?: boolean
}

export function ShiftBlock({
  shift,
  date,
  column,
  startSortOrder,
  endSortOrder,
  isLeft = true,
  isRight = true,
  disableLabel = false,
}: ShiftBlockProps) {
  const showAccessRuleSlideout = useAccessRuleSlideout()

  const showLabel = Boolean(!disableLabel && isLeft && shift.shiftCategory)
  const borderStyles = {
    borderTopLeftRadius: isLeft && !showLabel ? '3px' : 0,
    borderBottomLeftRadius: isLeft ? '3px' : 0,
    borderTopRightRadius: isRight ? '3px' : 0,
    borderBottomRightRadius: isRight ? '3px' : 0,
  }

  return (
    <ScheduleBlock columnStart={column} columnEnd={column} rowStart={startSortOrder} rowEnd={endSortOrder} zIndex={2}>
      <Tooltip
        title={
          <Stack direction="column">
            <Typography fontSize={14}>{shift.name}</Typography>
            <Typography fontSize={12}>
              {shift.startTime?.formatSTime()} - {shift.endTime?.formatSTime()}
            </Typography>
          </Stack>
        }
      >
        <Box
          onClick={() =>
            showAccessRuleSlideout({ shiftCategories: shift.shiftCategory ? [shift.shiftCategory] : [], startDate: date.toJsDate() })
          }
          sx={{
            ...borderStyles,
            height: `100%`,
            width: `100%`,
            borderTopLeftRadius: 0,
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
          }}
        >
          {showLabel && (
            <Box
              sx={{
                position: 'absolute',
                top: '-1.125rem',
                height: '1.125rem',
                paddingLeft: 2,
                paddingRight: 2,
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                backgroundColor: BACKGROUND_COLOR,
              }}
            >
              <Typography fontSize={11} fontWeight={500}>
                {shift.shiftCategory}
              </Typography>
            </Box>
          )}
        </Box>
      </Tooltip>
    </ScheduleBlock>
  )
}
