import moment, { type Moment } from 'moment-timezone'
import { useState } from 'react'
import { useGetReviewSummaryQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Header } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { reviewsMessages } from '../../../locales'
import { AISummaryCard, type AISummaryCardProps } from './AISummaryCard'
import { AISummarySlideOut } from './AISummarySlideOut'

const DATE_FORMAT = 'YYYY-MM-DD'

export function AISummary() {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()

  const [showSlideout, setShowSlideout] = useState<boolean>(false)

  const originalEndDate = moment().startOf('isoWeek')
  const [endDate, setEndDate] = useState<Moment>(originalEndDate)
  const startDate = endDate?.clone().subtract(7, 'days')

  const { data: reviewSummary, isFetching } = useGetReviewSummaryQuery({
    venueId,
    startDate: startDate?.format(DATE_FORMAT) ?? '',
    endDate: endDate?.format(DATE_FORMAT) ?? '',
  })

  const lastWeek = () => {
    setEndDate(endDate => endDate?.clone().subtract(7, 'days'))
  }
  const nextWeek = () => {
    setEndDate(endDate => endDate?.clone().add(7, 'days'))
  }
  const nextWeekDisabled = endDate >= originalEndDate

  const formatDate = (date: Moment) =>
    date?.toDate().toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })

  const title = formatMessage(reviewsMessages.aiSummaryHeader, {
    icon: <Icon name="VMSWeb-action-stars" color="attentionNew" />,
    start: formatDate(startDate),
    end: formatDate(endDate),
  })

  const baseSectionProps = { setShowSlideout, isFetching, compact: true }

  const sectionProps: AISummaryCardProps[] = [
    {
      sectionId: 'guest-highlights',
      sectionTitle: formatMessage(reviewsMessages.aiGuestHighlights, {
        icon: <Icon name="VMSWeb-smile" size="lg" color="success" />,
      }),
      data: reviewSummary?.positiveFeedback,
      emptyMessage: formatMessage(reviewsMessages.aiGuestHighlightsNotAvailable),
      ...baseSectionProps,
    },
    {
      sectionId: 'guest-lowlights',
      sectionTitle: formatMessage(reviewsMessages.aiGuestLowlights, {
        icon: <Icon name="VMSWeb-sad" size="lg" color="warning" />,
      }),
      data: reviewSummary?.negativeFeedback,
      emptyMessage: formatMessage(reviewsMessages.aiGuestLowlightsNotAvailable),
      ...baseSectionProps,
    },
    {
      sectionId: 'areas-for-improvement',
      sectionTitle: formatMessage(reviewsMessages.aiAreasForImprovement, {
        icon: <Icon name="VMSWeb-lightbulb" size="lg" color="status" />,
      }),
      data: reviewSummary?.suggestions,
      emptyMessage: formatMessage(reviewsMessages.aiAreasForImprovementNotAvailable),
      ...baseSectionProps,
    },
  ]

  return (
    <>
      <Box borderRadius="none" backgroundColor="infoBackground" p="m" data-test="ai-summary-container">
        <HStack justifyContent="space-between">
          <Header type="h2" mb="m">
            {title}
          </Header>
          <HStack>
            <Button variant="tertiary" onClick={() => lastWeek()} data-test="button-last-week">
              {'<'} {formatMessage(reviewsMessages.aiSummaryLastWeek)}
            </Button>
            <Button variant="tertiary" onClick={() => nextWeek()} disabled={nextWeekDisabled} data-test="button-next-week">
              {formatMessage(reviewsMessages.aiSummaryNextWeek)} {'>'}
            </Button>
          </HStack>
        </HStack>
        <Box height={200}>
          <HStack width="100%" spacing="m" justifyContent="space-between" alignItems="stretch">
            {sectionProps.map(props => (
              <AISummaryCard key={props.sectionId} {...props} />
            ))}
          </HStack>
        </Box>
      </Box>
      <AISummarySlideOut
        title={title}
        sectionProps={sectionProps}
        isFetching={isFetching}
        show={showSlideout}
        setShow={setShowSlideout}
        lastWeek={lastWeek}
        nextWeek={nextWeek}
        nextWeekDisabled={nextWeekDisabled}
      />
    </>
  )
}
