import { defineMessages } from '@sevenrooms/core/locales'

export const smsCenterMessages = defineMessages({
  campaignPerformanceChartNoData: {
    id: 'smsCenter.smsCenter.campaignPerformanceChartNoData',
    defaultMessage: 'No text campaign data for that time range.',
  },
  campaignPerformanceChartError: {
    id: 'smsCenter.smsCenter.campaignPerformanceChartError',
    defaultMessage: 'Error during fetching text campaign data.',
  },
  sms: {
    id: 'smsCenter.sms',
    defaultMessage: 'SMS',
  },
  campaignPerformanceRecipientsMMS: {
    id: 'campaignCenter.campaignPerformance.recipientsMMS',
    defaultMessage: 'Messages Sent',
  },
  campaignPerformanceRecipientsSMS: {
    id: 'campaignCenter.campaignPerformance.recipientsSMS',
    defaultMessage: 'Segments Sent',
  },
  campaignText: {
    id: 'campaignText',
    defaultMessage: 'Automated SMS',
  },
  deactivateSMSCampaignModalDeactivateOneTitle: {
    id: 'smsCenter.deactivateSMSCampaignModal.deactivateOneTitle',
    defaultMessage: 'Are you sure you want to turn off "{smsCampaignName}"?',
  },
  deactivateSMSCampaignModalDeactivateOneBody: {
    id: 'smsCenter.deactivateSMSCampaignModal.deactivateOneBody',
    defaultMessage: 'Turning this sms off will prevent it from being sent out in the future, even when the set activity is triggered.',
  },
  deactivateSMSCampaignModalCancel: {
    id: 'smsCenter.deactivateSMSCampaignModal.cancel',
    defaultMessage: 'Keep it on',
  },
  deactivateSMSCampaignModalConfirm: {
    id: 'smsCenter.deactivateSMSCampaignModal.confirm',
    defaultMessage: 'Turn it off',
  },
  deactivateSMSCampaignModalErrorMessage: {
    id: 'smsCenter.deactivateSMSCampaignModal.errorMessage',
    defaultMessage: 'Unable to deactivate {smsCampaignName}, please try again later.',
  },
  activateSMSCampaignErrorMessage: {
    id: 'smsCenter.activateSMSCampaignErrorMessage',
    defaultMessage: 'Unable to activate {smsCampaignName}, please try again later.',
  },
  active: {
    id: 'smsCenter.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'smsCenter.inactive',
    defaultMessage: 'Inactive',
  },
  draft: {
    id: 'smsCenter.draft',
    defaultMessage: 'Draft',
  },
  archived: {
    id: 'smsCenter.archived',
    defaultMessage: 'Archived',
  },
  sending: {
    id: 'smsCenter.sending',
    defaultMessage: 'Sending',
  },
  sent: {
    id: 'smsCenter.sent',
    defaultMessage: 'Completed',
  },
  complete: {
    id: 'smsCenter.complete',
    defaultMessage: 'Completed',
  },
  completed: {
    id: 'smsCenter.completed',
    defaultMessage: 'Completed',
  },
  confirmModalTitle: {
    id: 'smsCenter.confirmModalTitle',
    defaultMessage: 'Are you sure you want to archive this Automated SMS?',
  },
  confirmModalInformation: {
    id: 'smsCenter.confirmModalInformation',
    defaultMessage: 'Archiving this Automated SMS will remove it from the active sms center.',
  },
  confirmModalHistoryInfo: {
    id: 'smsCenter.confirmModalHistoryInfo',
    defaultMessage: 'We will keep all of the Automated SMS’s historical performance data.',
  },
  confirmModalDelete: {
    id: 'smsCenter.confirmDelete',
    defaultMessage: 'Confirm Archive',
  },
  smsCampaignTurnOff: {
    id: 'smsCenter.smsCampaignTurnOff',
    defaultMessage: 'Turn off Automated SMS',
  },
  smsCampaignTurnOn: {
    id: 'smsCenter.smsCampaignTurnOff',
    defaultMessage: 'Turn on Automated SMS',
  },
  smsArchiveSuccess: {
    id: 'smsCenter.smsArchiveSuccess',
    defaultMessage: 'Automated SMS archived successfully',
  },
  smsArchiveError: {
    id: 'smsCenter.smsArchiveError',
    defaultMessage: 'Automated SMS archive failed',
  },
} as const)
