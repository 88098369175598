import type { SMSCampaign } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignBuilderMessages, smsBuilderMessages } from '@sevenrooms/marketing'

export interface SMSDetailsProps {
  campaign?: SMSCampaign
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any
  isFetching?: boolean
  autoFocus?: boolean
  disabled?: boolean
}

export function SMSDetails({ campaign, isFetching, fields, autoFocus, disabled }: SMSDetailsProps) {
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(smsBuilderMessages.smsDetailsTitle)}>
      <Box p="lm">
        <HStack spacing="lm" alignItems="flex-start">
          {isFetching ? (
            <Box width="100%">
              <Loader />
            </Box>
          ) : (
            <>
              <VStack spacing="lm" width="50%">
                <Label primary={formatMessage(smsBuilderMessages.smsName)} secondary={formatMessage(campaignBuilderMessages.internalName)}>
                  <FormInput
                    autoFocus={autoFocus}
                    defaultValue={campaign?.campaignName}
                    field={fields?.prop('campaignName')}
                    placeholder={formatMessage(smsBuilderMessages.campaignNamePlaceholder)}
                    autoComplete="none"
                    disabled={disabled}
                  />
                </Label>
              </VStack>
            </>
          )}
        </HStack>
      </Box>
    </BaseSection>
  )
}
