import type { InsightsData, InsightTypeGroup, Insight } from '@sevenrooms/core/domain'

const hasInsights = (group: InsightTypeGroup) => group.insightTypes?.reduce((total, type) => total + (type?.insights?.length ?? 0), 0) !== 0

export const parseInsights = (data: InsightsData) => {
  const { insights, insightTypes, insightTypeGroups, insightCategories, accessData } = data

  const types = insightTypes.map(type => ({
    ...type,
    insights: insights.filter(insight => insight.insightTypeKey === type.key && (insight.featuredRank === null || !accessData.fullAccess)),
  }))

  const typeGroups = insightTypeGroups
    .map(typeGroup => ({
      ...typeGroup,
      insightTypes: types.filter(type => type.insightTypeGroupKey === typeGroup.key).sort((a, b) => a.sortOrder - b.sortOrder),
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder)

  const displayGroups = [...typeGroups.filter(group => hasInsights(group)), ...typeGroups.filter(group => !hasInsights(group))]

  return insightCategories
    .map(category => ({
      ...category,
      insightTypeGroups: displayGroups.filter(typeGroup => typeGroup.categoryKey === category.key),
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder)
}

export const extractHighlights = (insights: Insight[]) =>
  insights
    .filter(insight => insight.featuredRank !== null)
    .sort((a, b) => (a.featuredRank && b.featuredRank ? a.featuredRank - b.featuredRank : 0))
    .slice(0, 3)
