import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  cardLabel: {
    id: 'posiSettings.BaseAutomaticCheckCreationCard.cardLabel',
    defaultMessage: 'Automatic Check Creation',
  },
  cardSubHeadingLabel: {
    id: 'posiSettings.BaseAutomaticCheckCreationCard.cardSubHeadingLabel',
    defaultMessage: 'Automatically create a check in the POS when a reservation is seated in SevenRooms. <a>Learn More</a>',
  },
} as const)
