import { type Disabled, ConciergeEnabledEnum, type ConciergeEnabled } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'

export interface PerkSettingsAdditionalInformationFormProps {
  additionalInformationField: Field<string>
  disabled: Disabled
  conciergeEnabledField: Field<ConciergeEnabled>
}

export function PerkSettingsAdditionalInformationForm({
  additionalInformationField,
  disabled,
  conciergeEnabledField,
}: PerkSettingsAdditionalInformationFormProps) {
  const { formatMessage } = useLocales()
  const { perkAdditionalInformationPrimary, perkAdditionalInformationSecondary, perkAdditionalInformationInfo } = perksMessages

  const conciergeEnabled = useWatch(conciergeEnabledField)

  if (conciergeEnabled === ConciergeEnabledEnum.NO) {
    return null
  }
  return (
    <VStack spacing="m">
      <Label
        primary={formatMessage(perkAdditionalInformationPrimary)}
        secondary={formatMessage(perkAdditionalInformationSecondary)}
        info={<>{formatMessage(perkAdditionalInformationInfo)}</>}
      >
        <FormInput data-test="sr-perk-form-additional-information" disabled={disabled} field={additionalInformationField} />
      </Label>
    </VStack>
  )
}
