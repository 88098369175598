import {
  PDRAmenitiesEnum,
  PDRRoomTypeEnum,
  PDRUnitOfMeasurementEnum,
  type PDRAmenitiesEnumOption,
  type PDRRoomTypeEnumOption,
} from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormSelect, FormMultiSelect, FormNumberInput, FileUploader } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { ExperiencesLocales } from '../../Experiences.locales'
import type { CreateEditExperienceFormData } from '../../CreateEditExperienceForm.zod'

interface DetailsProps {
  field: Field<CreateEditExperienceFormData>
}
export function Details({ field }: DetailsProps) {
  const { formatMessage } = useLocales()
  const fieldPdrDetails = field.prop('pdrDetails')

  return (
    <BaseSection title={formatMessage(ExperiencesLocales.pdrDetails)}>
      <VStack spacing="lm" p="lm">
        <HStack spacing="lm">
          <Label primary={formatMessage(ExperiencesLocales.pdrMinSeatedCapacity)} width="100%">
            <FormNumberInput field={fieldPdrDetails.prop('pdrMinSeatedCapacity')} />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrMaxSeatedCapacity)} width="100%">
            <FormNumberInput field={fieldPdrDetails.prop('pdrMaxSeatedCapacity')} />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrMinStandingCapacity)} width="100%">
            <FormNumberInput field={fieldPdrDetails.prop('pdrMinStandingCapacity')} />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrMaxStandingCapacity)} width="100%">
            <FormNumberInput field={fieldPdrDetails.prop('pdrMaxStandingCapacity')} />
          </Label>
        </HStack>

        <HStack spacing="lm">
          <Label primary={formatMessage(ExperiencesLocales.pdrRoomType)} width="100%">
            <FormSelect
              field={fieldPdrDetails.prop('pdrRoomType')}
              options={Object.entries(PDRRoomTypeEnum).map(([roomId, label]) => ({
                id: roomId as PDRRoomTypeEnumOption,
                label,
              }))}
              withEmpty
            />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrSquareFootage)} width="100%">
            <FormNumberInput
              field={fieldPdrDetails.prop('pdrSquareFootage')}
              placeholder={formatMessage(ExperiencesLocales.pdrSquareFootagePlaceholder)}
            />
          </Label>
          <Label primary={formatMessage(ExperiencesLocales.pdrUnitOfMeasurement)} width="100%">
            <FormSelect
              field={fieldPdrDetails.prop('pdrUnitOfMeasurement')}
              options={Object.entries(PDRUnitOfMeasurementEnum).map(([unitId, label]) => ({
                id: unitId,
                label,
              }))}
              withEmpty
            />
          </Label>
        </HStack>

        <VStack>
          <Label primary={formatMessage(ExperiencesLocales.pdrAmenities)} />
          <FormMultiSelect
            field={fieldPdrDetails.prop('pdrAmenityList')}
            options={Object.entries(PDRAmenitiesEnum).map(([amenitiesId, label]) => ({
              id: amenitiesId as PDRAmenitiesEnumOption,
              label,
            }))}
          />
        </VStack>

        <VStack maxWidth="50%">
          <Label primary={formatMessage(ExperiencesLocales.menu)} secondary={formatMessage(ExperiencesLocales.menuDescription)} />
          <FileUploader field={field.prop('menu')} hideDropZone />
        </VStack>
      </VStack>
    </BaseSection>
  )
}
