import type { OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'

export function SubjectLineAndPreview({ emailCampaign }: { emailCampaign: OneTimeEmailCampaignType }) {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(campaignCenterMessages.campaignPerformanceSubjectLineAndPreview)} maxWidth="none">
      <VStack p="lm" overflow="hidden">
        <HStack p="m" borderRadius="m" borderColor="borders">
          <Icon name="VMSWeb-account-circle" size="3x" color="margin" />
          <VStack spacing="xs" ml="s" overflow="hidden" width="100%">
            <HStack justifyContent="space-between">
              <Text fontWeight={500}>{venue?.name}</Text>
              <Text fontSize="s">{emailCampaign.sentDateTimeLocal?.formatFMonthNYearSTime()}</Text>
            </HStack>
            <Text fontWeight={700}>{emailCampaign.emailSubject}</Text>
            {emailCampaign.previewText && (
              <Text fontSize="s" color="secondaryFont" textOverflow="ellipsis">
                {emailCampaign.previewText}
              </Text>
            )}
          </VStack>
        </HStack>
      </VStack>
    </BaseSection>
  )
}
