import _ from 'lodash'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormMultiSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, Grid, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Tag } from '@sevenrooms/core/ui-kit/typography'
import { referralsMessages } from '../../locales'
import type { ReferralClientFormFields } from './ReferralClientForm.zod'

export type ReferralClientType = 'new' | 'existing'

export interface ReferralClientFormProps {
  disabled: boolean
  fields: ReferralClientFormFields
  globalPerks: SelectOption[]
  localPerks: SelectOption[]
  type: ReferralClientType
}

export function ReferralClientForm({ type, fields, disabled, globalPerks, localPerks }: ReferralClientFormProps) {
  const vxTheme = useTheme()
  const { formatMessage } = useLocales()
  const tag = useWatch(fields.prop('tagName'))
  const colorHex = useWatch(fields.prop('colorHex'))
  const noGlobalPerks = _.isEmpty(globalPerks)
  const noLocalPerks = _.isEmpty(localPerks)

  const renderGlobalPerks = () => (
    <Box gridRowStart={2} maxWidth="450px">
      <FormMultiSelect
        data-test={`global-perks-${type}-client`}
        options={globalPerks}
        field={fields.prop('globalPerks')}
        scrollLock
        disabled={disabled || noGlobalPerks}
        usePills={false}
        useCheckboxItems
        searchable
        showRemoveAllButton
        placeholder={formatMessage(referralsMessages.referralSearchPerks)}
      />
    </Box>
  )

  const renderLocalPerks = () => (
    <Box gridRowStart={3} maxWidth="450px">
      <FormMultiSelect
        data-test={`local-perks-${type}-client`}
        options={localPerks}
        field={fields.prop('localPerks')}
        scrollLock
        disabled={disabled || noLocalPerks}
        usePills={false}
        useCheckboxItems
        searchable
        showRemoveAllButton
        placeholder={formatMessage(referralsMessages.referralSearchPerks)}
      />
    </Box>
  )

  return (
    <Grid gridTemplateColumns="4fr 8fr" gridTemplateRows="repeat(5, min-content)" alignItems="start" columnGap="lm" rowGap="m">
      <Box gridColumnStart={1} gridRowStart={1}>
        <Label secondary={formatMessage(referralsMessages.reservationTag)} htmlFor={`reservation-tag-${type}-client`} />
      </Box>

      <Box gridColumnStart={1} gridRowStart={2}>
        <Label secondary={formatMessage(referralsMessages.referralGlobalPerks)} htmlFor={`global-perks-${type}-client`} />
      </Box>

      <Box gridColumnStart={1} gridRowStart={3}>
        <Label secondary={formatMessage(referralsMessages.referralLocalPerks)} htmlFor={`local-perks-${type}-client`} />
      </Box>

      <VStack gridColumnStart={2} spacing="xs" alignItems="start" gridRowStart={1}>
        <Tag backgroundColor={colorHex ?? vxTheme.colors.warning}>{tag}</Tag>
      </VStack>

      {noGlobalPerks ? (
        <Tooltip displayAction="hover" content={<>{formatMessage(referralsMessages.referralNoGlobalPerks)}</>}>
          {renderGlobalPerks()}
        </Tooltip>
      ) : (
        renderGlobalPerks()
      )}

      {noLocalPerks ? (
        <Tooltip displayAction="hover" content={<>{formatMessage(referralsMessages.referralNoLocalPerks)}</>}>
          {renderLocalPerks()}
        </Tooltip>
      ) : (
        renderLocalPerks()
      )}
    </Grid>
  )
}
