import React from 'react'
import onClickOutside from 'react-onclickoutside'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { AutotagTierType, TierAttributes, AutotagConfigType } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const visitChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))
const monthChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))
const venueChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

class VisitsTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  handleClickOutside(e) {
    if (!this.props.tier.name_display && this.originalName === '') {
      this.props.removeTier()
    }
  }

  render() {
    const name = this.props.tier.name_display
    const tierType = this.props.tier.tier_type
    const { attributes } = this.props.tier

    const { isActive, id } = this.props

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TierEditor>
              Tag clients with
              <DropdownArrowsPicker
                {...dropProps}
                choices={visitChoices}
                value={attributes.MIN_VISITS}
                style={{ ...dropStyle, width: '120px', minWidth: '120px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_VISITS, value)}
              />
              or more visits in the past
              <DropdownArrowsPicker
                {...dropProps}
                choices={monthChoices}
                value={attributes.PAST_MONTHS_MAX}
                style={{ ...dropStyle, width: '120px', minWidth: '120px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MAX, value)}
              />
              month(s)
              {tierType === AutotagTierType.VISITS_GLOBAL_TIER ? (
                <span>
                  {' '}
                  and has visited at least
                  <DropdownArrowsPicker
                    {...dropProps}
                    choices={venueChoices}
                    value={attributes.MIN_VENUES}
                    style={{ ...dropStyle, width: '120px', minWidth: '120px' }}
                    optionsContainerStyle={{ maxHeight: 200 }}
                    onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_VENUES, value)}
                  />
                  venue(s).
                </span>
              ) : (
                '.'
              )}
            </TierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Clients with at least <strong>{attributes.MIN_VISITS}</strong> visits in the past{' '}
              <strong>{attributes.PAST_MONTHS_MAX}</strong> month(s)
              {this.props.configType === AutotagConfigType.VISITS_GLOBAL ? (
                <>
                  {' '}
                  and has visited at least <strong>{attributes.MIN_VENUES}</strong> venue(s).
                </>
              ) : (
                '.'
              )}
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default onClickOutside(VisitsTier)
