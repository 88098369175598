import { defineMessages } from '@sevenrooms/core/locales'

export const googleReviewLocales = defineMessages({
  checkAuth: {
    id: 'googleReview.checkAuth',
    defaultMessage: 'Click here to reauthenticate your Google Business account.',
  },
  confirmDeleteReplyTitle: {
    id: 'googleReview.confirmDeleteReplyTitle',
    defaultMessage: 'Delete Response',
  },
  confirmDeleteReplyDescription: {
    id: 'googleReview.confirmDeleteReplyDescription',
    defaultMessage: 'Are you sure you want to delete this response?',
  },
  confirmDeleteButtonLabel: {
    id: 'googleReview.confirmDeleteButtonLabel',
    defaultMessage: 'Delete',
  },
  confirmDiscardReplyTitle: {
    id: 'googleReview.confirmDiscardReplyTitle',
    defaultMessage: 'Unsent Response',
  },
  confirmDiscardReplyDescription: {
    id: 'googleReview.confirmDiscardReply',
    defaultMessage: 'Unsent response will not be saved once you close this window',
  },
  confirmDiscardButtonLabel: {
    id: 'googleReview.confirmDiscardButtonLabel',
    defaultMessage: 'Discard Changes',
  },
  editReplyButtonLabel: {
    id: 'googleReview.replyButtonLabel',
    defaultMessage: 'Update Response',
  },
  repliedDate: {
    id: 'googleReview.repliedDate',
    defaultMessage: ' Replied {date}',
  },
  replyButtonLabel: {
    id: 'googleReview.replyButtonLabel',
    defaultMessage: 'Post Response',
  },
  replyPlaceholder: {
    id: 'googleReview.replyPlaceholder',
    defaultMessage: 'Respond to this Google review...',
  },
  sendStatusLoading: {
    id: 'googleReview.sendStatusLoading',
    defaultMessage: ' Sending to Google',
  },
  sendStatusDeletingReply: {
    id: 'googleReview.sendStatusDeletingReply',
    defaultMessage: ' Deleting reply',
  },
  sendStatusDeleteSuccess: {
    id: 'googleReview.sendStatusDeleteSuccess',
    defaultMessage: ' Deleted reply on Google',
  },
  sendStatusFailure: {
    id: 'googleReview.sendStatusFailure',
    defaultMessage: " This response didn't send. Please try again.",
  },
  sendStatusReplySuccess: {
    id: 'googleReview.sendStatusReplySuccess',
    defaultMessage: ' Successfully posted on Google',
  },
  updateReplyPlaceholder: {
    id: 'googleReview.updateReplyPlaceholder',
    defaultMessage: 'This will overwrite any existing response...',
  },
  slideoutTitle: {
    id: 'googleReview.slideoutTitle',
    defaultMessage: 'RESPOND TO REVIEW',
  },
  submittedBy: {
    id: 'googleReview.submittedBy',
    defaultMessage: 'Submitted by {author} on {date}',
  },
})
