import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  saveSuccess: {
    id: 'posiSettings.EditPage.saveSuccess',
    defaultMessage: 'Successfully saved {posiType} settings.',
  },
  saveError: {
    id: 'posiSettings.EditPage.saveError',
    defaultMessage: 'Error saving {posiType} settings.',
  },
} as const)
