import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'
import { useReferralLevelForm } from '../ReferralLevelForm'
import { useReferrersEmailConfigurationsForm } from '../ReferrersEmailConfigurationsForm'

export type ReferrersFormData = ZodSchema<typeof useReferrersForm>
export type ReferrersFormFields = Field<ReferrersFormData>

export const useReferrersForm = () => {
  const referralLevelFormSchema = useReferralLevelForm()
  const referrersEmailConfigurationsFormSchema = useReferrersEmailConfigurationsForm()

  return useMemo(
    () =>
      z.object({
        referrerAutotagTiers: z.array(referralLevelFormSchema),
        referralLinkSubject: z.string().optional(),
        referralLinkBody: z.string().optional(),
        referralLink: z.string().optional(),
        referrerEmailConfigurations: referrersEmailConfigurationsFormSchema,
      }),
    [referralLevelFormSchema, referrersEmailConfigurationsFormSchema]
  )
}
