import { type ReactNode, useMemo } from 'react'
import type { ChargePerType } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Label, Checkbox, FormCurrencyInput, FormNumberInput, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { CheckboxAlignedBox, StandardWidthBox, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { DisplayIfChecked, DisplayIfSelected } from '../../../ChargeSettings'
import { TimeBefore } from '../../../shared'
import { PaymentPolicyLocales } from '../../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import type { ChargeChoicesForm, PartySizeType } from '../../PaymentPolicy.zod'
import type { PaymentPolicyProps } from '../PaymentPolicyProps'

export interface RadioSectionProps<T extends string | number> {
  choices: RadioChoice<T>[]
  field: Field<T>
  primaryLabel: ReactNode
  secondaryLabel?: ReactNode
  'data-test': string
}

export function RadioSection<T extends string | number>({ 'data-test': testId, ...props }: RadioSectionProps<T>) {
  return (
    <VStack spacing="s">
      <Label primary={<Text fontWeight="bold">{props.primaryLabel}</Text>} secondary={props.secondaryLabel} />
      <RadioGroup data-test={testId} choices={props.choices} field={props.field} />
    </VStack>
  )
}

export type CutoffProps = PaymentPolicyProps & {
  label: string
  description: string
}

export function Cutoff({ field, label, description }: CutoffProps) {
  return (
    <VStack spacing="xs">
      <Checkbox data-test={PaymentPolicyTestId.setChargeCutoff} field={field.prop('setChargeCutoff')} description={description}>
        {label}
      </Checkbox>
      <CheckboxAlignedBox>
        <DisplayIfChecked field={field.prop('setChargeCutoff')}>
          <TimeBefore data-test={PaymentPolicyTestId.chargeCutoff} field={field.prop('chargeCutoff')} />
        </DisplayIfChecked>
      </CheckboxAlignedBox>
    </VStack>
  )
}

export function MinPartySize({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  const options: SelectOption<PartySizeType>[] = useMemo(
    () => [
      {
        id: 'all',
        label: formatMessage(PaymentPolicyLocales.partySizeChoiceAll),
      },
      {
        id: 'gt',
        label: formatMessage(PaymentPolicyLocales.partySizeChoiceGt),
      },
    ],
    [formatMessage]
  )

  return (
    <HStack spacing="sm" alignItems="center">
      <Text>{formatMessage(PaymentPolicyLocales.for)}</Text>
      <StandardWidthBox>
        <FormSelect
          data-test={PaymentPolicyTestId.partySizeFilter}
          options={options}
          field={field.prop('partySizeType')}
          searchable={false}
        />
      </StandardWidthBox>
      <DisplayIfSelected field={field.prop('partySizeType')} option="gt">
        <PartySize field={field} />
      </DisplayIfSelected>
    </HStack>
  )
}

function PartySize({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  return (
    <>
      <StandardWidthBox>
        <FormNumberInput data-test={PaymentPolicyTestId.minPartySize} field={field.prop('partySizeMin')} />
      </StandardWidthBox>
      <Text>{formatMessage(PaymentPolicyLocales.guests)}</Text>
    </>
  )
}

export interface ChargeChoicesProps {
  field: Field<ChargeChoicesForm>
  'data-test': string
}
export function ChargeChoices({ field, 'data-test': testId }: ChargeChoicesProps) {
  const { venueCurrencySymbol } = useAppContext()
  const options = useChargeChoices()

  return (
    <HStack data-test={testId} spacing="sm" alignItems="top">
      <StandardWidthBox>
        <FormCurrencyInput data-test={`${testId}-amount`} field={field.prop('amount')} currencySymbol={venueCurrencySymbol} />
      </StandardWidthBox>
      <StandardWidthBox>
        <FormSelect data-test={`${testId}-chargeType`} options={options} field={field.prop('chargeType')} searchable={false} />
      </StandardWidthBox>
    </HStack>
  )
}

export function useChargeChoices(): SelectOption<ChargePerType>[] {
  const { formatMessage } = useLocales()
  return useMemo(
    () => [
      {
        id: 'person',
        label: formatMessage(PaymentPolicyLocales.payPerChoicePerson),
      },
      {
        id: 'person_slot',
        label: formatMessage(PaymentPolicyLocales.payPerChoicePersonSlot),
      },
      {
        id: 'reservation',
        label: formatMessage(PaymentPolicyLocales.payPerChoiceReservation),
      },
      {
        id: 'reservation_slot',
        label: formatMessage(PaymentPolicyLocales.payPerChoiceReservationSlot),
      },
    ],
    [formatMessage]
  )
}
