import { Stack } from '@sevenrooms/react-components/components/Stack'
import { useMediaQuery } from '@sevenrooms/react-components/hooks/useMediaQuery'
import { CreateAccessRuleButton } from './CreateAccessRuleButton'
import { ViewAccessLogButton } from './ViewAccessLogButton'
import { ViewReservationWidgetButton } from './ViewReservationWidgetButton'

export function AccessRulesActionButtons() {
  const isSmallScreen = useMediaQuery('(max-width:1250px)')

  return (
    <Stack id="access-rules-header-action-buttons" direction={isSmallScreen ? 'column' : 'row'} spacing={2}>
      <ViewAccessLogButton />
      <ViewReservationWidgetButton />
      <CreateAccessRuleButton />
    </Stack>
  )
}
