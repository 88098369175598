import React, { useCallback, useMemo, useState } from 'react'
import { useGetPerkListQuery, useDeletePerkMutation } from '@sevenrooms/core/api'
import { type Perk, PerkAccessTypeEnum } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Form, FormInput, Menu, MenuItem, MultiSelectTag, type Category, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  HStack,
  Box,
  Flex,
  Loader,
  Tooltip,
  Window,
  notify,
  Spreadsheet,
  EmptyState,
  FiltersPanel,
  Cell,
  BasicCell,
  basicCellSortFn,
  type DataTableColumn,
  type Row,
} from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Link, Tag, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext, useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import perksImage from '../../assets/images/perks.svg'
import { perksMessages } from '../../locales'
import { PerkAvailability } from '../PerkAvailability'
import { PerkDeleteModal } from '../PerkDeleteModal'
import { useFiltersSchema } from './PerksTable.zod'
import { PerkTableHeader } from './PerkTableHeader'

export interface PerksTableProps {}

export const noop = () => {}

export function PerksTable() {
  const [selectedPerk, setSelectedPerk] = useState<Perk | null>(null)
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue, venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const permissions = useNavigationPermissionsContext()
  const {
    data,
    isFetching: isPerksFetching,
    isError,
  } = useGetPerkListQuery({
    venueId,
  })
  const [deletePerk, { isLoading: isDeleteLoading }] = useDeletePerkMutation()

  const filtersSchema = useFiltersSchema()
  const filtersForm = useForm(filtersSchema, {
    defaultValues: {
      selectedTags: [],
    },
  })
  const isExclusiveAccessPerksEnabled = venueSettings?.is_exclusive_access_perks_enabled
  const isEarlyAccessPerksEnabled = venueSettings?.is_early_access_perks_enabled
  const { field: filters, watch } = filtersForm
  const [search, selectedTags] = watch(['search', 'selectedTags'])

  const perks = useMemo(
    () =>
      data?.filter(perk => {
        if (selectedTags?.length) {
          const selectedTagNames = selectedTags.map(tag => tag.label)
          if (!perk.associatedClientTags.concat(perk.associatedReservationTags).some(tag => selectedTagNames.includes(tag.label))) {
            return false
          }
        }

        if (search?.length) {
          return perk.internalName.toLowerCase().includes(search.toLowerCase())
        }

        return true
      }) ?? [],
    [data, search, selectedTags]
  )

  const defaultPerks = useMemo(() => perks.filter(perk => perk.perkType === PerkAccessTypeEnum.DEFAULT), [perks])
  const exclusiveAccessPerks = useMemo(
    () => (isExclusiveAccessPerksEnabled ? perks.filter(perk => perk.perkType === PerkAccessTypeEnum.EXCLUSIVE_ACCESS) : []),
    [perks, isExclusiveAccessPerksEnabled]
  )
  const earlyAccessPerks = useMemo(
    () => (isEarlyAccessPerksEnabled ? perks.filter(perk => perk.perkType === PerkAccessTypeEnum.EARLY_ACCESS) : []),
    [perks, isEarlyAccessPerksEnabled]
  )
  const visiblePerks = [...earlyAccessPerks, ...exclusiveAccessPerks, ...defaultPerks]

  const { tagOptions, tagCategories } = useMemo(() => createFilterTagOptions(data), [data])

  const onPerkEditClick = useCallback(
    (perk: Perk) => {
      const getPerkNavPath = () => {
        switch (perk.perkType) {
          case PerkAccessTypeEnum.EXCLUSIVE_ACCESS:
            return routes.manager2.marketing.perks2.editExclusive
          case PerkAccessTypeEnum.EARLY_ACCESS:
            return routes.manager2.marketing.perks2.editEarlyAccess
          default:
            return routes.manager2.marketing.perks2.edit
        }
      }

      nav.push(getPerkNavPath(), {
        params: { venueKey, perkId: perk.id },
      })
    },
    [nav, venueKey]
  )

  const onPerkDeleteClick = useCallback(
    (perk: Perk) => {
      nav.push(routes.manager2.marketing.perks2.deleteModal, {
        params: { venueKey },
      })

      setSelectedPerk(perk)
    },
    [setSelectedPerk, nav, venueKey]
  )

  const tryDeletePerk = useCallback(async () => {
    nav.push(routes.manager2.marketing.perks2, {
      params: { venueKey },
    })

    try {
      await deletePerk({ venueId, perkId: selectedPerk?.id ?? '' }).unwrap()

      notify({ type: 'success', content: formatMessage(perksMessages.deleteSuccessMessage) })
    } catch {
      notify({ type: 'error', content: formatMessage(perksMessages.deleteErrorMessage) })
    }

    setSelectedPerk(null)
  }, [deletePerk, selectedPerk, venueId, formatMessage, nav, venueKey])

  const columns = React.useMemo<DataTableColumn<Perk>[]>(
    () => [
      {
        key: 'internalName',
        header: formatMessage(perksMessages.internalPerksName),
        accessor: 'internalName',
        render: (perk, index) => <BasicCell primary={perk.internalName} data-test={`perks-${index}-internal-perks-name`} />,
        attach: 'left',
        minWidth: 220,
        maxWidth: 220,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        key: 'isGlobal',
        header: formatMessage(perksMessages.type),
        accessor: 'isGlobal',
        render: (perk, index) => (
          <BasicCell
            primary={perk.isGlobal ? formatMessage(commonMessages.global) : formatMessage(commonMessages.local)}
            data-test={`perks-${index}-perk-type`}
          />
        ),
        minWidth: 100,
        maxWidth: 100,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        key: 'associatedTags',
        header: formatMessage(perksMessages.associatedTags),
        accessor: 'associatedReservationTags',
        render: (perk, index) => (
          <Box data-test={`perks-${index}-associated-tags`}>
            {perk.associatedClientTags.concat(perk.associatedReservationTags).map(tag => (
              <Tag
                backgroundColor={tag.categoryColor ?? theme.colors.defaultTagColor}
                key={tag.label}
                icon={tag.isAutotag ? 'VMSWeb-refresh' : undefined}
              >
                <Tooltip content={<span>{tag.label}</span>} displayAction="hover">
                  <Box maxWidth="239px" display="flex">
                    <Text textOverflow="ellipsis" overflow="hidden">
                      {tag.label}
                    </Text>
                  </Box>
                </Tooltip>
              </Tag>
            ))}
          </Box>
        ),
        minWidth: 239,
        maxWidth: 239,
      },
      {
        key: 'venueId',
        header: formatMessage(perksMessages.createdFrom),
        accessor: 'venueId',
        render: (perk, index) => <BasicCell primary={perk.venueCreatedFrom} data-test={`perks-${index}-created-from`} />,
        minWidth: 180,
        maxWidth: 180,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        key: 'perkAvailabilityTimes',
        header: formatMessage(perksMessages.availability),
        accessor: 'perkAvailabilityTimes',
        render: (perk, index) => (
          <Cell data-test={`perks-${index}-perk-availability-times`}>
            <PerkAvailability perk={perk} />
            {perk.isRestrictedByDateRange && (
              <Box mt="xs">
                <Text color="secondaryFont">
                  {perk.startDate?.formatNYearNMonthNDay()} -{' '}
                  {perk.endDate?.formatNYearNMonthNDay() ?? formatMessage(commonMessages.indefinite).toLowerCase()}
                </Text>
              </Box>
            )}
          </Cell>
        ),
      },
      {
        key: 'isConcierge',
        header: formatMessage(perksMessages.concierge),
        accessor: 'isConcierge',
        render: (perk, index) => (
          <Cell data-test={`perks-${index}-is-concierge `}>{perk.isConcierge ? <Icon name="VMSWeb-check" /> : ''}</Cell>
        ),
        sortable: true,
        sortType: isConciergeSortFn,
        width: 97,
        minWidth: 97,
        maxWidth: 97,
      },
      {
        key: 'action',
        header: '',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
        render: (value, index) => (
          <Menu scrollLock data-test={`perk-menu-${index}`}>
            {value.isGlobal && value.venueId !== venueId ? (
              <MenuItem data-test="edit-perk-menu-item">
                <Tooltip content={<span>{formatMessage(perksMessages.disabledEditButton)}</span>} displayAction="hover">
                  <Text color="deactivated">
                    <Icon name="VMSWeb-edit" />
                    {formatMessage(commonMessages.edit)}
                  </Text>
                </Tooltip>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => onPerkEditClick(value)} data-test="edit-perk-menu-item">
                <Icon name="VMSWeb-edit" />
                <Text>{formatMessage(commonMessages.edit)}</Text>
              </MenuItem>
            )}
            {value.isGlobal && value.venueId !== venueId ? (
              <MenuItem data-test="archive-perk-menu-item">
                <Tooltip content={<span>{formatMessage(perksMessages.disabledArchiveButton)}</span>} displayAction="hover">
                  <Text color="deactivated">
                    <Icon name="VMSWeb-trash" />
                    {formatMessage(commonMessages.archive)}
                  </Text>
                </Tooltip>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => onPerkDeleteClick(value)} data-test="archive-perk-menu-item">
                <Icon name="VMSWeb-trash" />
                {formatMessage(commonMessages.archive)}
              </MenuItem>
            )}
          </Menu>
        ),
        attach: 'right',
        expandable: true,
        minWidth: 64,
        maxWidth: 64,
      },
    ],
    [formatMessage, onPerkEditClick, onPerkDeleteClick, venueId]
  )

  const defaultPerksEmptyState = useMemo(() => {
    const hasDefaultPerks = data?.some(perk => perk.perkType === PerkAccessTypeEnum.DEFAULT)

    if (!hasDefaultPerks) {
      return (
        <>
          <EmptyState
            title={formatMessage(perksMessages.emptyTableTitleNoData)}
            image={perksImage}
            description={
              <>
                {formatMessage(perksMessages.emptyTableDescriptionNoDataText)}&nbsp;
                <Link
                  to={nav.href(routes.manager2.marketing.perks2.create, {
                    params: { venueKey },
                  })}
                  data-test="create-default-perk"
                >
                  {formatMessage(perksMessages.emptyTableDescriptionNoDataLink)}
                </Link>
              </>
            }
            alt={`${formatMessage(perksMessages.emptyTableDescriptionNoDataText)} ${formatMessage(
              perksMessages.emptyTableDescriptionNoDataLink
            )}`}
          />
        </>
      )
    } else if (!defaultPerks.length) {
      const message =
        search?.length && !selectedTags?.length
          ? formatMessage(perksMessages.emptyTableDescriptionSearch, { search })
          : formatMessage(perksMessages.emptyTableDescriptionFilters)

      return <EmptyState description={<Text color="secondaryFont">{message}</Text>} alt={message} />
    }

    return null
  }, [defaultPerks, formatMessage, nav, data, search, venueKey, selectedTags])

  const earlyAccessEmptyState = useMemo(() => {
    const hasEarlyAccessPerk = data?.some(perk => perk.perkType === PerkAccessTypeEnum.EARLY_ACCESS)

    if (!hasEarlyAccessPerk) {
      return (
        <EmptyState
          title={formatMessage(perksMessages.emptyEarlyAccessTableTitleNoData)}
          description={
            <HStack>
              {formatMessage(perksMessages.emptyTableDescriptionNoDataText)}&nbsp;
              <Link
                to={nav.href(routes.manager2.marketing.perks2.createEarlyAccess, {
                  params: { venueKey },
                })}
                data-test="create-exclusive-perk"
              >
                {formatMessage(perksMessages.emptyEarlyAccessTableDescriptionNoDataLink)}
              </Link>
            </HStack>
          }
          pt="m"
          pb="m"
        />
      )
    } else if (!earlyAccessPerks.length) {
      const message =
        search?.length && !selectedTags?.length
          ? formatMessage(perksMessages.emptyTableDescriptionSearch, { search })
          : formatMessage(perksMessages.emptyTableDescriptionFilters)

      return <EmptyState description={<Text color="secondaryFont">{message}</Text>} />
    }

    return null
  }, [earlyAccessPerks, formatMessage, nav, data, search, venueKey, selectedTags])

  const exclusiveAccessEmptyState = useMemo(() => {
    const hasExclusivePerk = data?.some(perk => perk.perkType === PerkAccessTypeEnum.EXCLUSIVE_ACCESS)

    if (!hasExclusivePerk) {
      return (
        <>
          <EmptyState
            title={formatMessage(perksMessages.emptyExclusiveTableTitleNoData)}
            description={
              <HStack>
                {formatMessage(perksMessages.emptyExclusiveTableDescriptionNoDataText)}&nbsp;
                {permissions?.canAdministrateShiftsAccessRules ? (
                  <Link
                    to={nav.href(routes.manager2.marketing.perks2.createExclusive, {
                      params: { venueKey },
                    })}
                    data-test="create-exclusive-perk"
                  >
                    {formatMessage(perksMessages.emptyExclusiveTableDescriptionNoDataLink)}
                  </Link>
                ) : (
                  <Tooltip
                    displayAction="hover"
                    content={<>{formatMessage(perksMessages.emptyExclusiveTableDisabledDescription)}</>}
                    alignment="top"
                  >
                    <Text color="deactivated">{formatMessage(perksMessages.emptyExclusiveTableDescriptionNoDataLink)}</Text>
                  </Tooltip>
                )}
              </HStack>
            }
            pt="m"
            pb="m"
          />
        </>
      )
    } else if (!exclusiveAccessPerks.length) {
      const message =
        search?.length && !selectedTags?.length
          ? formatMessage(perksMessages.emptyTableDescriptionSearch, { search })
          : formatMessage(perksMessages.emptyTableDescriptionFilters)

      return <EmptyState description={<Text color="secondaryFont">{message}</Text>} />
    }

    return null
  }, [exclusiveAccessPerks, formatMessage, nav, data, search, venueKey, selectedTags, permissions?.canAdministrateShiftsAccessRules])

  const filterForms = (
    <Form {...filtersForm} onSubmit={noop} onInvalid={noop}>
      <FiltersPanel width="100%" spacing="s">
        {tagOptions?.length > 0 && (
          <Flex flexBasis="350px">
            <MultiSelectTag
              data-test="perks-tags-filter"
              searchable
              field={filters.prop('selectedTags')}
              categories={tagCategories}
              options={tagOptions}
              disabled={isPerksFetching}
              placeholder={formatMessage(perksMessages.perksTagsFilterPlaceholder)}
            />
          </Flex>
        )}

        <Flex flexBasis="330px" ml="auto">
          <FormInput
            field={filters.prop('search')}
            disabled={isPerksFetching}
            placeholder={formatMessage(perksMessages.perksSearchPlaceholder)}
          />
        </Flex>
      </FiltersPanel>
    </Form>
  )

  const renderPreComponent = useCallback(
    ({ index }: { index: number }) => {
      if (index === 0 && isEarlyAccessPerksEnabled) {
        return (
          <>
            <PerkTableHeader perkType={PerkAccessTypeEnum.EARLY_ACCESS} />
            {earlyAccessEmptyState}
            {!earlyAccessPerks.length &&
              (isExclusiveAccessPerksEnabled ? (
                <>
                  <PerkTableHeader perkType={PerkAccessTypeEnum.EXCLUSIVE_ACCESS} />
                  {exclusiveAccessEmptyState}
                  {!exclusiveAccessPerks.length && (
                    <>
                      <PerkTableHeader />
                      {defaultPerksEmptyState}
                    </>
                  )}
                </>
              ) : (
                <>
                  <PerkTableHeader />
                  {defaultPerksEmptyState}
                </>
              ))}
          </>
        )
      }

      if ((index === 0 && !earlyAccessPerks.length) || (index === 1 && earlyAccessPerks.length)) {
        return isExclusiveAccessPerksEnabled ? (
          <>
            <PerkTableHeader perkType={PerkAccessTypeEnum.EXCLUSIVE_ACCESS} />
            {exclusiveAccessEmptyState}
            {!exclusiveAccessPerks.length && (
              <>
                <PerkTableHeader />
                {defaultPerksEmptyState}
              </>
            )}
          </>
        ) : (
          <>
            <PerkTableHeader />
            {defaultPerksEmptyState}
          </>
        )
      }

      return defaultPerksEmptyState
    },
    [
      isEarlyAccessPerksEnabled,
      isExclusiveAccessPerksEnabled,
      earlyAccessPerks,
      exclusiveAccessPerks,
      defaultPerksEmptyState,
      earlyAccessEmptyState,
      exclusiveAccessEmptyState,
    ]
  )

  const renderPostComponent = useCallback(
    ({ index }: { index: number }) => {
      if (index === earlyAccessPerks.length - 1 && !exclusiveAccessPerks.length && !defaultPerks.length) {
        return isExclusiveAccessPerksEnabled ? (
          <>
            <PerkTableHeader perkType={PerkAccessTypeEnum.EXCLUSIVE_ACCESS} />
            {exclusiveAccessEmptyState}
            {!exclusiveAccessPerks.length && (
              <>
                <PerkTableHeader />
                {defaultPerksEmptyState}
              </>
            )}
          </>
        ) : (
          <>
            <PerkTableHeader />
            {defaultPerksEmptyState}
          </>
        )
      }

      if (exclusiveAccessPerks.length && ((index === 0 && !earlyAccessPerks.length) || (index === 1 && earlyAccessPerks.length))) {
        return (
          <>
            <PerkTableHeader />
            {defaultPerksEmptyState}
          </>
        )
      }

      return null
    },
    [earlyAccessPerks, exclusiveAccessPerks, defaultPerks, isExclusiveAccessPerksEnabled, defaultPerksEmptyState, exclusiveAccessEmptyState]
  )

  if (!venue || isError) {
    notify({ content: formatMessage(perksMessages.fetchErrorMessage), type: 'error' })
    return null
  }

  if (isPerksFetching || isDeleteLoading) {
    return (
      <Box pb="m">
        <Loader />
      </Box>
    )
  }

  return (
    <>
      <Spreadsheet
        data={visiblePerks}
        filterPanel={!!data?.length && filterForms}
        columns={columns}
        autoResetExpanded={false}
        noPagination
        placeholder={
          !visiblePerks?.length && (
            <>
              {isEarlyAccessPerksEnabled && (
                <>
                  <PerkTableHeader perkType={PerkAccessTypeEnum.EARLY_ACCESS} />
                  {earlyAccessEmptyState}
                </>
              )}
              {isExclusiveAccessPerksEnabled && (
                <>
                  <PerkTableHeader perkType={PerkAccessTypeEnum.EXCLUSIVE_ACCESS} />
                  {exclusiveAccessEmptyState}
                </>
              )}
              {(isEarlyAccessPerksEnabled || isExclusiveAccessPerksEnabled) && <PerkTableHeader />}
              {defaultPerksEmptyState}
            </>
          )
        }
        {...((isEarlyAccessPerksEnabled || isExclusiveAccessPerksEnabled) && {
          renderPreComponent,
          renderPostComponent,
        })}
      />
      {selectedPerk && (
        <Surface destination={routes.manager2.marketing.perks2.deleteModal}>
          <Window>
            <PerkDeleteModal
              closeHref={nav.closeSurfaceHref(routes.manager2.marketing.perks2.deleteModal, { params: { venueKey } })}
              onClose={() => setSelectedPerk(null)}
              isLoading={isDeleteLoading}
              onSubmit={tryDeletePerk}
              tagOptions={selectedPerk?.associatedReservationTags.concat(selectedPerk.associatedClientTags)}
              perk={selectedPerk}
            />
          </Window>
        </Surface>
      )}
    </>
  )
}

const compareRows = (rowAValue: boolean, rowBValue: boolean) => (rowAValue > rowBValue ? 1 : -1)
export const isConciergeSortFn = (rowA: Row, rowB: Row, columnId: string) => {
  const rowAValue = Boolean(rowA.values[columnId as string]?.props.children)
  const rowBValue = Boolean(rowB.values[columnId as string]?.props.children)
  return compareRows(rowAValue, rowBValue)
}

export const createFilterTagOptions = (perks?: Perk[]) => {
  const tagOptions: TagOption[] = []
  const tagCategories: Category[] = []
  if (perks) {
    const tagsSet = new Set()
    const tagCategoriesSet = new Set()
    perks.forEach(perk => {
      perk.associatedReservationTags.concat(perk.associatedClientTags).forEach((tag, idx) => {
        if (!tagsSet.has(tag.label)) {
          tagOptions.push({
            id: tag.label + idx,
            label: tag.label,
            categoryId: tag.categoryId,
          })
          tagsSet.add(tag.label)
        }
        if (!tagCategoriesSet.has(tag.categoryId)) {
          tagCategories.push({
            id: tag.categoryId,
            name: '',
            color: tag.categoryColor ?? theme.colors.defaultTagColor,
          })
        }
      })
    })
  }
  return { tagOptions, tagCategories }
}
