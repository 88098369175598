import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'ApiAudienceList.title',
    defaultMessage: 'Custom audiences',
  },
  titleDescription: {
    id: 'ApiAudienceList.titleDescription',
    defaultMessage: 'Custom Audiences are used to share availability via API to other applications or websites',
  },
  baseUrl: {
    id: 'ApiAudienceList.baseUrl',
    defaultMessage: 'Base url',
  },
  copy: {
    id: 'ApiAudienceList.copy',
    defaultMessage: 'Copy',
  },
  tableHeaderName: {
    id: 'ApiAudienceList.tableHeaderName',
    defaultMessage: 'Name',
  },
  tableHeaderClientId: {
    id: 'ApiAudienceList.tableHeaderClientId',
    defaultMessage: 'Client ID',
  },
  tableHeaderWidgetUrl: {
    id: 'ApiAudienceList.tableHeaderWidgetUrl',
    defaultMessage: 'Widget URL',
  },
  tableHeaderActive: {
    id: 'ApiAudienceList.tableHeaderActive',
    defaultMessage: 'Is active',
  },
  apiLink: {
    id: 'ApiAudienceList.apiLink',
    defaultMessage: 'API documentation',
  },
  addNew: {
    id: 'ApiAudienceList.addNew',
    defaultMessage: 'Add',
  },
  edit: {
    id: 'ApiAudienceList.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'ApiAudienceList.delete',
    defaultMessage: 'Delete',
  },
  dismiss: {
    id: 'ApiAudienceList.dismiss',
    defaultMessage: 'Dismiss',
  },
  deleteWarningHeader: {
    id: 'ApiAudienceList.deleteWarningHeader',
    defaultMessage: 'Warning',
  },
  deleteWarning: {
    id: 'ApiAudienceList.deleteWarning',
    defaultMessage: 'Do you want to delete the audience?',
  },
  deleteSuccess: {
    id: 'ApiAudienceList.deleteSuccess',
    defaultMessage: 'Audience deleted successfully',
  },
  deleteError: {
    id: 'ApiAudienceList.deleteError',
    defaultMessage: 'Error deleting audience',
  },
} as const)
