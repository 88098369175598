import { dateFilters } from 'mgr/pages/single-venue/marketing/reducers/Filters'

const srOnly = ['sevenrooms']

export const isSevenroomsChannelsOnly = channels => {
  const channelSites = Object.keys(channels)
  return channelSites.length === srOnly.length && srOnly.every((e, i) => channelSites[i] === e)
}

// TODO deal with invalid filters
export const convertFilters = (filters, specificFilters) => {
  const filtersList = {
    ...(filters.rating &&
      filters.rating !== 'all' && {
        ratings: filters.rating,
      }),
    ...(filters.feedbackType &&
      filters.feedbackType !== 'all' && {
        feedback_type: filters.feedbackType,
      }),
    ...(filters.platform &&
      filters.platform !== 'all' && {
        sites: filters.platform,
      }),
    ...(filters.date && filters.date !== 'all' && filters.date === 'custom'
      ? dateFilters.find(name => name.value === filters.date).convert(filters.startDate, filters.endDate)
      : dateFilters.find(name => name.value === filters.date).convert()),
    ...(filters.search && { q: filters.search }),
    ...((filters.page || filters.page === 0) && {
      offset: filters.page * filters.pageSize,
      limit: filters.pageSize,
    }),
    ...(filters.sortKey &&
      filters.sortDirection && {
        sort: `${filters.sortDirection === 'desc' ? '-' : ''}${filters.sortKey}`,
      }),
    ...(filters.emailType && {
      email_type: filters.emailType,
    }),
    ...(filters.campaignIds && {
      campaign_ids: filters.campaignIds,
    }),
  }

  if (specificFilters) {
    return specificFilters.reduce((acc, current) => {
      const filter = filtersList[current]

      if (filter) {
        acc[current] = filter
      }
      return acc
    }, {})
  }

  return filtersList
}
