import { defineMessages } from '@sevenrooms/core/locales'

export const policiesAndVerbiageMessages = defineMessages({
  sectionReservationRequestEmail: {
    id: 'settings.general.section.reservationRequestEmail',
    defaultMessage: 'Reservation Request Email',
  },
  reservationRequestTitle: {
    id: 'settings.general.reservationRequest.title',
    defaultMessage: 'Reservation request email paragraph',
  },
  sectionReservationSms: {
    id: 'settings.general.section.reservationSms',
    defaultMessage: 'Reservation SMS',
  },
  reservationSmsTitle: {
    id: 'settings.general.reservationRequest.title',
    defaultMessage: 'SMS opt in for reservation',
  },
  sectionSmsMessages: {
    id: 'settings.general.section.smsMessages',
    defaultMessage: 'SMS Messages',
  },
  waitlistAdditionSmsTitle: {
    id: 'settings.general.waitlistAdditionSms.title',
    defaultMessage: 'Waitlist Addition SMS via Mobile App',
  },
  waitlistTableReadySmsTitle: {
    id: 'settings.general.waitlistTableReadySms.title',
    defaultMessage: 'Waitlist Table Ready SMS',
  },
  reservationTableReadySmsTitle: {
    id: 'settings.general.reservationTableReadySms.title',
    defaultMessage: 'Reservation Table Ready SMS',
  },
  addCustomSmsMessage: {
    id: 'settings.general.addCustomSmsMessage',
    defaultMessage: 'Add custom SMS message',
  },
  customSmsMessage: {
    id: 'settings.general.customSmsMessage',
    defaultMessage: 'Custom SMS message #{index}',
  },
  sectionReservationFeedback: {
    id: 'settings.general.section.reservationFeedback',
    defaultMessage: 'Reservation Feedback Email',
  },
  reservationFeedbackHeaderTitle: {
    id: 'settings.general.reservationRequest.title',
    defaultMessage: 'Reservation feedback email header',
  },
  reservationFeedbackTextTitle: {
    id: 'settings.general.reservationRequest.title',
    defaultMessage: 'Reservation feedback header text',
  },
  reservationFiveStarTitle: {
    id: 'settings.general.reservationFiveStar.title',
    defaultMessage: 'Rservation five star feedback text',
  },
  reservationFiveStarAdditionalTitle: {
    id: 'settings.general.reservationFiveStarAdditional.title',
    defaultMessage: 'Reservation five star additional feedback text',
  },
  reservationFourToOneTitle: {
    id: 'settings.general.reservationFourToOne.title',
    defaultMessage: 'Reservation four to one star feedback text',
  },
  reservationFourToOneAdditionalTitle: {
    id: 'settings.general.reservationFourToOneAdditional.title',
    defaultMessage: 'Reservation four to one star additional feedback text',
  },
  reservationRecommendationTitle: {
    id: 'settings.general.reservationRecommendation.title',
    defaultMessage: 'Reservation feedback recommendation text',
  },
  reservationFeedbackDescriptionTitle: {
    id: 'settings.general.reservationFeedbackDescription.title',
    defaultMessage: 'Reservation feedback email description',
  },
  sectionGuestlistEmail: {
    id: 'settings.general.section.guestlistEmail',
    defaultMessage: 'Guestlist Email',
  },
  guestlistHeaderTitle: {
    id: 'settings.general.guestlistHeader.title',
    defaultMessage: 'Guestlist email header',
  },
  guestlistParagraphTitle: {
    id: 'settings.general.guestlistParagraph.title',
    defaultMessage: 'Guestlist request email paragraph',
  },
  guestlistPolicyTitle: {
    id: 'settings.general.guestlistPolicy.title',
    defaultMessage: 'Guestlist email policy',
  },
  sectionReservationPolicy: {
    id: 'settings.general.section.reservationPolicy',
    defaultMessage: 'Reservation Policy Verbiage',
  },
  customCheckoutPolicyTitle: {
    id: 'settings.general.customCheckoutPolicy.title',
    defaultMessage: 'Custom required checkout policy',
  },
  waitlistPolicyTitle: {
    id: 'settings.general.waitlistPolicy.title',
    defaultMessage: 'Agree to waitlist policy',
  },
  sectionMarketingPolicy: {
    id: 'settings.general.section.marketingPolicy',
    defaultMessage: 'Marketing Policy Verbiage',
  },
  groupMarketingPolicyTitle: {
    id: 'settings.general.groupMarketingPolicy.title',
    defaultMessage: 'Venue Group marketing policy',
  },
  specificMarketingPolicyTitle: {
    id: 'settings.general.specificMarketingPolicy.title',
    defaultMessage: 'Venue specific marketing policy',
  },
  sectionBookingPolicy: {
    id: 'settings.general.section.bookingPolicy.title',
    defaultMessage: 'Booking Policy Verbiage',
  },
  bookingPolicyTitle: {
    id: 'settings.general.bookingPolicy.title',
    defaultMessage: 'Default booking policy / A note from us',
  },
  cancellationPolicyTitle: {
    id: 'settings.general.cancellationPolicy.title',
    defaultMessage: 'Default cancellation policy (bookings where credit card is required)',
  },
  sectionSpecialAttention: {
    id: 'settings.general.section.specialAttention',
    defaultMessage: 'Special Attention Verbiage',
  },
  specialAttentionHeaderTitle: {
    id: 'settings.general.specialAttentionHeader.title',
    defaultMessage: 'Special attention message header text',
  },
  specialAttentionTextTitle: {
    id: 'settings.general.specialAttentionText.title',
    defaultMessage: 'Special attention message body text',
  },
  leaveBlankToUseDefault: {
    id: 'settings.general.leaveBlankToUseDefault',
    defaultMessage: 'Leave blank to use the defaults or type in custom text',
  },
})
