import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { GuestDurationPickerLocales } from './GuestDurationPicker.locales'

export type GuestDurationPickerForm = ZodSchema<typeof useGuestDurationPickerForm>

export function useGuestDurationPickerForm() {
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z
        .object({
          guestMustSpecifyDuration: z.boolean(),
          durationMin: z.number({ required_error: formatMessage(GuestDurationPickerLocales.durationMinRequired) }).nullable(),
          durationMax: z.number({ required_error: formatMessage(GuestDurationPickerLocales.durationMaxRequired) }).nullable(),
        })
        .superRefine(({ guestMustSpecifyDuration, durationMin, durationMax }, ctx) => {
          if (guestMustSpecifyDuration) {
            if (!durationMin) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(GuestDurationPickerLocales.durationMinRequired),
                path: ['durationMin'],
              })
            } else if (!durationMax) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(GuestDurationPickerLocales.durationMaxRequired),
                path: ['durationMax'],
              })
            } else if (durationMin > durationMax) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(GuestDurationPickerLocales.durationMaxAfterMin),
                path: ['durationMax'],
              })
            }
          }
        }),
    [formatMessage]
  )
}

export function getInitialGuestDurationPicker(accessRule?: AccessRule): GuestDurationPickerForm {
  return {
    guestMustSpecifyDuration: Boolean(accessRule?.durationMin && accessRule.durationMax),
    durationMin: accessRule?.durationMin ?? null,
    durationMax: accessRule?.durationMax ?? null,
  }
}
