import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  tabTitle: {
    id: 'paymentsSettings.PaymentIntegrations.tabTitle',
    defaultMessage: 'Payment Processors',
  },
  title: {
    id: 'paymentsSettings.PaymentIntegrations.title',
    defaultMessage: 'Payment Processors',
  },
  noGateways: {
    id: 'paymentsSettings.PaymentIntegrations.noGateways',
    defaultMessage: 'Payment integrations are not configured for current country.',
  },
  description: {
    id: 'paymentsSettings.PaymentIntegrations.description',
    defaultMessage:
      'Sign up for a payment processor below to start accepting payments directly through your Booking Widget, Events Widget and Paylinks. <a>Learn more</a>',
  },
} as const)
