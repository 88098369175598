import { useLocales } from '@sevenrooms/core/locales'
import { NumberInput } from '@sevenrooms/core/ui-kit/core'
import { Label, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import { Delimiter } from '../components/Delimiter'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { usePromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'

export function UsageSection() {
  const { formatMessage } = useLocales()
  const { usageChoices } = usePromoCodeResourcesContext()
  const { usage, setUsage, limit, uses, setLimit, isLimitValid } = usePromoCodeContext()

  return (
    <BaseSection title={formatMessage(promoCodeMessages.promoCodeFormUsageSection)}>
      <Box p="lm">
        <RadioGroup
          data-test="promo-code-form-usage-group-field"
          name="usageGroup"
          choices={usageChoices}
          selected={usage}
          onChange={selected => setUsage(selected.value)}
        />
        {usage === 'LIMITED' && (
          <>
            <Delimiter />
            <Label primary={formatMessage(promoCodeMessages.promoCodeFormPromoLimitLabel)}>
              <NumberInput
                data-test="promo-code-form-limit-field"
                decimalScale={0}
                value={limit}
                invalid={!isLimitValid}
                hideErrorMessage={isLimitValid}
                errorMessage={formatMessage(promoCodeMessages.promoCodeFormPromoLimitError, { uses })}
                placeholder={formatMessage(promoCodeMessages.promoCodeFormPromoLimitPlaceholder)}
                onChange={setLimit}
              />
            </Label>
          </>
        )}
      </Box>
    </BaseSection>
  )
}
