import React from 'react'
import { type Field, useWatch } from '@sevenrooms/core/form'
import type { TimeRangePickerForm } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { numberToDayOfTheWeek } from '../../constants'
import { PerkTimeRangeForm } from './PerkTimeRangeForm'

interface PerkTimeRangesFormProps {
  disabled: boolean
  perkTimeRangeFields: Field<Array<TimeRangePickerForm>>
  dowOfferedField: Field<number[]>
}

export function PerkTimeRangesForm({ disabled, perkTimeRangeFields, dowOfferedField }: PerkTimeRangesFormProps) {
  const dowOffered = useWatch(dowOfferedField)

  return (
    <VStack spacing="m">
      {dowOffered
        .sort((a, b) => a - b)
        .map(day => (
          <PerkTimeRangeForm
            key={day}
            disabled={disabled}
            data-test={`sr-perk-time-range-${numberToDayOfTheWeek[day]}`}
            timeRangeField={perkTimeRangeFields.prop(`${day}`)}
            dayOfTheWeek={day}
          />
        ))}
    </VStack>
  )
}
