import { useMemo } from 'react'
import { useGetVenueUsersQuery } from '@sevenrooms/core/api'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Box, Loader, Spreadsheet } from '@sevenrooms/core/ui-kit/layout'
import { Link, Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { userAccountsLocales } from './UserAccountsLocales'

export function UserAccountsSettings() {
  const { formatMessage } = useLocales()
  const { venueSettings, venueId, venueUrlKey } = useAppContext()
  const nav = useNavigation()

  const { data, isFetching } = useGetVenueUsersQuery({ venueId })
  const columns = useMemo(
    () => [
      {
        header: formatMessage(userAccountsLocales.tableViewName),
        render: (value: { id: string; email: string; fullName: string }) => (
          <VStack>
            <Link
              to={nav.href(routes.manager2.settings.userAccountsSettings.detailed, {
                params: {
                  venueKey: venueUrlKey,
                  id: value.id,
                },
              })}
            >
              {value.fullName}
            </Link>
            <Text color="secondaryFont">{value.email}</Text>
          </VStack>
        ),
        key: 'fullName',
      },
      {
        header: formatMessage(userAccountsLocales.tableViewJobTile),
        render: (value: { jobTitle: string }) => <Text>{value.jobTitle || '-'}</Text>,
        key: 'jobTitle',
      },
      {
        header: formatMessage(userAccountsLocales.tableViewUserLevel),
        render: (value: { privilegeLevel: string }) => <Text>{value.privilegeLevel || '-'}</Text>,
        key: 'privilegeLevel',
      },
      {
        header: formatMessage(userAccountsLocales.tableViewEmailNotifications),
        render: (value: { receiveFeedbackSummaryEmail: boolean }) => {
          let emailNotification = '-'
          if (value.receiveFeedbackSummaryEmail) {
            emailNotification = formatMessage(userAccountsLocales.emailNotificationsFeedbackSummary)
          }
          return <Text>{emailNotification}</Text>
        },
        key: 'emailNotifications',
      },
    ],
    [formatMessage, nav, venueUrlKey]
  )

  const spreadsheetData = useMemo(
    () =>
      data?.map(venueUser => ({
        id: venueUser.id,
        email: venueUser.user.email,
        fullName: FormatService.formatFullName(venueUser.user.firstName, venueUser.user.lastName, venueSettings?.reservationDisplayOrder),
        jobTitle: venueUser.jobTitle,
        privilegeLevel: venueUser.privilegeLevel,
        receiveFeedbackSummaryEmail: venueUser.receiveFeedbackSummaryEmail,
      })) || [],
    [data, venueSettings?.reservationDisplayOrder]
  )

  return (
    <>
      <SettingsPageMeta title={formatMessage(userAccountsLocales.title)} />
      <SettingsPageContent
        title={formatMessage(userAccountsLocales.title)}
        description={formatMessage(userAccountsLocales.description, {
          link: (chunks: string[]) => (
            <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360004984551-User-Access-Levels-Roles-and-Permissions">
              {chunks}
            </Anchor>
          ),
        })}
        actions={
          <HStack spacing="s">
            <Button variant="secondary" onClick={() => {}} data-test="button-admin-settings">
              {formatMessage(userAccountsLocales.buttonAdminSettings)}
            </Button>
            <Button variant="primary" onClick={() => {}} data-test="button-admin-settings">
              {formatMessage(userAccountsLocales.buttonCreateUser)}
            </Button>
          </HStack>
        }
      >
        <Box ml="lm" maxWidth="968px">
          {isFetching ? <Loader /> : <Spreadsheet data={spreadsheetData} columns={columns} noPagination noBorder />}
        </Box>
      </SettingsPageContent>
    </>
  )
}
