import { useGetObjectActivityLogsQuery } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DividerLine,
  Flex,
  ModalActions,
  ModalTitle,
  HStack,
  VStack,
  Window,
  Loader,
} from '@sevenrooms/core/ui-kit/layout'
import { Text, List, ListItem } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { activityLogMessages } from '../shared/ActivityLog.locales'

export interface ActivityLogModalInterface {
  entityObjectId: string
  title: string
  isActive: boolean
  setIsActive: (isActive: boolean) => void
  onClose: () => void
}

export function ActivityLogModal({ entityObjectId, title, isActive, setIsActive, onClose }: ActivityLogModalInterface) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data, isLoading } = useGetObjectActivityLogsQuery({ venueId, entityObjectId }, { refetchOnMountOrArgChange: true })

  return (
    <Window active={isActive} setIsActive={setIsActive}>
      <Modal ariaLabel="main" width="100%" minWidth="748px">
        <ModalHeader onClose={onClose}>
          <ModalTitle title={formatMessage(activityLogMessages.title, { title })} />
        </ModalHeader>
        <ModalBody p="none" ml="lm" mr="lm" backgroundColor="secondaryBackground" borderRadius="s">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {data?.activities?.map((activityLog, idx) => (
                <div key={activityLog.id}>
                  {idx !== 0 && <DividerLine />}
                  <Flex width="100%" p="m">
                    <HStack width="25%">
                      <Text fontWeight="bold">{activityLog.formattedTimestamp}</Text>
                    </HStack>
                    <HStack width="75%">
                      <VStack spacing="s">
                        <HStack>
                          <Text fontWeight="bold">{activityLog.changelogHeader}:</Text>
                        </HStack>
                        {activityLog.changelog?.length ? (
                          <List spacing="s">
                            {activityLog.changelog.map(change => (
                              <ListItem key={change}>
                                <Text>{change}</Text>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Text>{formatMessage(activityLogMessages.noChanges)}</Text>
                        )}
                      </VStack>
                    </HStack>
                  </Flex>
                </div>
              ))}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button variant="primary" onClick={onClose} data-test="imageEditPopup-cancel">
              {formatMessage(commonMessages.close)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
