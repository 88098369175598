import { useMemo } from 'react'
import type { CampaignActivityItem } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { BaseSection, BorderedBox } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { getPercentageOfTotal } from '@sevenrooms/mgr-marketing/utils'
import { Card } from './Card'

export function CampaignAnalysis({ activity, sendTime }: { activity: CampaignActivityItem; sendTime?: DateTime }) {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const cards = useMemo(
    () => [
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceRecipientEngagement),
        items: [
          {
            value: getPercentageOfTotal(activity.opens, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceOpens),
          },
          {
            value: getPercentageOfTotal(activity.clicks, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceClickToOpenRate),
          },
          {
            value: getPercentageOfTotal(activity.reservations, activity.opens),
            title: formatMessage(campaignCenterMessages.campaignPerformanceConversion),
          },
        ],
      },
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceConversion),
        items: [
          {
            value: `${activity.reservations} (${activity.covers})`,
            title: `${formatMessage(campaignCenterMessages.campaignPerformanceReservations)} (${formatMessage(
              campaignCenterMessages.campaignPerformanceCovers
            )})`,
          },
          { value: activity.orders, title: formatMessage(campaignCenterMessages.campaignPerformanceOnlineOrders) },
          {
            value: `${venue.currencySymbol} ${Math.round(activity.totalRevenue)}`,
            title: formatMessage(campaignCenterMessages.campaignPerformanceTotalRevenue),
          },
        ],
      },
      {
        heading: formatMessage(campaignCenterMessages.campaignPerformanceDelivery),
        items: [
          { value: activity.recipients, title: formatMessage(campaignCenterMessages.campaignPerformanceRecipients) },
          {
            value: getPercentageOfTotal(activity.unsubscribed, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceUnsubscribeRate),
          },
          {
            value: getPercentageOfTotal(activity.bounces, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceBounceRate),
          },
          {
            value: getPercentageOfTotal(activity.complaints, activity.recipients),
            title: formatMessage(campaignCenterMessages.campaignPerformanceComplaintRate),
          },
        ],
      },
    ],
    [formatMessage, activity, venue.currencySymbol]
  )
  const toDate = DateTime.from(new Date().toISOString()).formatNYearNMonthNDay()
  const fromDate = sendTime?.formatNYearNMonthNDay() ?? ''

  return (
    <BaseSection
      title="Email Analysis"
      description={formatMessage(campaignCenterMessages.campaignDateRangeDescription, { fromDate, toDate })}
    >
      <BorderedBox m="lm">
        {cards.map(({ heading, items }) => (
          <Card heading={heading} items={items} key={heading} />
        ))}
      </BorderedBox>
    </BaseSection>
  )
}
