import { MarketingCampaignType } from 'mgr/pages/shared/utils/Constants'

import { srDelete, srGet, srPost } from '@sevenrooms/core/api'

export const createCampaign = (email_campaign, venueId) => {
  const path = `/api-yoa/email_campaigns`
  return srPost(
    path,
    {
      email_campaign,
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw 'Failed to save'
    }
    return response.data || {}
  })
}

export const saveCampaign = (email_campaign, venueId, campaignId) => {
  const path = `/api-yoa/email_campaigns/${campaignId}`
  return srPost(
    path,
    {
      email_campaign,
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw 'Failed to save'
    }
    return response.data || {}
  })
}

export const deleteCampaign = (venueId, campaignId) => {
  const path = `/api-yoa/email_campaigns/${campaignId}`
  return srDelete(
    path,
    {
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw 'Failed to delete'
    }
    return response.data || {}
  })
}

export const sendTestCampaign = (email_campaign, recipient_emails, venueId) => {
  const path = `/api-yoa/email_campaign_test`
  return srPost(
    path,
    {
      email_campaign,
      recipient_emails,
      venue: venueId,
    },
    true
  ).then(response => {
    if (response.error) {
      throw 'Failed to send test'
    }
    return response.data || {}
  })
}

export const getEmailCampaignActivity = (venue, params) =>
  srGet(
    `/api-yoa/email_campaign_activity`,
    {
      ...params,
      venue: venue.id,
      include_deleted: true,
      campaign_type: MarketingCampaignType.ONGOING,
    },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the email campaign activity report', e)
    return e
  })

export const getEmailCampaigns = (venue, params) =>
  srGet(
    `/api-yoa/email_campaigns`,
    { ...params, venue: venue.id, campaign_type: MarketingCampaignType.ONGOING },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the email campaigns', e)
    return e
  })
