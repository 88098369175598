import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReferralLevelFormData = ZodSchema<ReturnType<typeof useReferralLevelForm>>
export type ReferralLevelFormFields = Field<ReferralLevelFormData>

export const useReferralLevelForm = () =>
  useMemo(
    () =>
      z.object({
        key: z.number(),
        name: z.string().min(1),
        conditionThreshold: z.number().positive().nullable(),
        globalPerks: z.array(z.string()),
        localPerks: z.array(z.string()),
        additionalNotes: z.string().optional(),
        referralEmailCampaign: z.object({
          campaignName: z.string(),
          campaignId: z.string(),
        }),
        colorHex: z.string().optional(),
      }),
    []
  )
