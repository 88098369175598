import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticPrepaymentRedemptionCard } from '../BaseAutomaticPrepaymentRedemptionCard'
import type { AutomaticPrepaymentRedemptionCardFormField } from '../AutomaticPrepaymentRedemptionCardForm.zod'

export interface SquareAutomaticPrepaymentRedemptionCardProps {
  field: AutomaticPrepaymentRedemptionCardFormField
}

export function SquareAutomaticPrepaymentRedemptionCard({ field }: SquareAutomaticPrepaymentRedemptionCardProps) {
  return (
    <BaseAutomaticPrepaymentRedemptionCard
      docLink={posiMetaDataMap.SQUARE.docLink}
      isAutomaticPrepaymentRedemptionEnabledField={field.prop('isAutomaticPrepaymentRedemptionEnabled')}
    />
  )
}
