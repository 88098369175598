import { type ConciergeEnabled, ConciergeEnabledEnum, type Disabled } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelectTree, type MultiSelectTreeForm, type TreeNode, Label } from '@sevenrooms/core/ui-kit/form'
import { perksMessages } from '../../locales'

interface PerkConciergesSelectFormProps {
  conciergeEnabledField: Field<ConciergeEnabled>
  conciergesField: Field<MultiSelectTreeForm[]>
  disabled: Disabled
  conciergeTreeData: TreeNode[]
}

export function PerkConciergesSelectForm({
  conciergeEnabledField,
  conciergeTreeData,
  conciergesField,
  disabled,
}: PerkConciergesSelectFormProps) {
  const { formatMessage } = useLocales()
  const { perkConciergesPrimaryLabel } = perksMessages

  const conciergeEnabled = useWatch(conciergeEnabledField)
  if (conciergeEnabled === ConciergeEnabledEnum.NO) {
    return null
  }

  return (
    <Label primary={formatMessage(perkConciergesPrimaryLabel)}>
      <MultiSelectTree disabled={disabled} data-test="sr-perk-concierges" treeData={conciergeTreeData} field={conciergesField} />
    </Label>
  )
}
