import constate from 'constate'
import { useMemo } from 'react'
import type { CreditCardType, PlatformApplyType, PromoComponent, PromoRangeApplyType, UsageType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import type { TreeNode, RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Tooltip } from '../components/Tooltip'
import { promoCodeMessages } from '../locales'
import { usePromoCodeManagementResourcesContext } from './usePromoCodeManagementResourcesContext'

function usePromoCodeResources() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { platformApplyTypeDisplayNames, codeTypeDisplayNames, discountTypeDisplayNames, dateRangeDescriptions, dateRangeDisplayNames } =
    usePromoCodeManagementResourcesContext()

  const usageDisplayNames: Record<UsageType, string> = useMemo(
    () => ({
      UNLIMITED: formatMessage(promoCodeMessages.promoCodeFormPromoUsageUnlimitedChoice),
      LIMITED: formatMessage(promoCodeMessages.promoCodeFormPromoUsageLimitedChoice),
    }),
    [formatMessage]
  )

  const promoComponentDisplayNames: Record<PromoComponent, string> = useMemo(
    () => ({
      ALL: formatMessage(promoCodeMessages.promoCodeFormPromoComponentAllChoice),
      BASE_PRICE: formatMessage(promoCodeMessages.promoCodeFormPromoComponentBasePriceChoice),
      UPGRADES: formatMessage(promoCodeMessages.promoCodeFormPromoComponentUpgradesChoice),
    }),
    [formatMessage]
  )
  const creditCardDisplayNames: Record<CreditCardType, string> = useMemo(
    () => ({
      NONE: formatMessage(promoCodeMessages.promoCodeFormCreditCardNoneLabel),
      AMERICAN_EXPRESS: formatMessage(promoCodeMessages.promoCodeFormCreditCardAmericanExpressLabel),
      DISCOVER: formatMessage(promoCodeMessages.promoCodeFormCreditCardDiscoverLabel),
      MASTER_CARD: formatMessage(promoCodeMessages.promoCodeFormCreditCardMasterCardLabel),
      VISA: formatMessage(promoCodeMessages.promoCodeFormCreditCardVisaLabel),
      DINERS_CLUB: formatMessage(promoCodeMessages.promoCodeFormCreditCardDinersClubLabel),
      JCB: formatMessage(promoCodeMessages.promoCodeFormCreditCardJCBLabel),
    }),
    [formatMessage]
  )

  const usageChoices: RadioChoice<UsageType>[] = useMemo(
    () =>
      Object.entries(usageDisplayNames).map(([value, label]) => ({
        label,
        value: value as UsageType,
        info:
          value === 'LIMITED' ? (
            <Tooltip content={formatMessage(promoCodeMessages.promoCodeFormPromoUsageLimitedChoiceTooltipContent)} />
          ) : undefined,
      })),
    [formatMessage, usageDisplayNames]
  )

  const widgetChoices: TreeNode<PlatformApplyType>[] = useMemo(() => {
    const options: TreeNode<PlatformApplyType>[] = []
    if (!venue) {
      return options
    }
    if (venue.isSevenRoomsOrderingEnabled) {
      options.push({
        id: 'ORDERING',
        value: 'ORDERING',
        label: platformApplyTypeDisplayNames.ORDERING,
      })
    }
    if (venue.diningWidgetEnabled) {
      options.push({
        id: 'RESERVATION_WIDGET',
        value: 'RESERVATION_WIDGET',
        label: platformApplyTypeDisplayNames.RESERVATION_WIDGET,
      })
    }
    if (venue.eventWidgetEnabled) {
      options.push({
        id: 'EVENT_WIDGET',
        value: 'EVENT_WIDGET',
        label: platformApplyTypeDisplayNames.EVENT_WIDGET,
      })
    }
    return options
  }, [platformApplyTypeDisplayNames, venue])

  const codeTypeChoices: SelectOption[] = useMemo(
    () =>
      Object.entries(codeTypeDisplayNames).map(([id, label]) => ({
        label,
        id,
      })),
    [codeTypeDisplayNames]
  )

  const promoTypeChoices: RadioChoice<'DOLLAR_DISCOUNT' | 'PERCENT_DISCOUNT'>[] = useMemo(
    () =>
      Object.entries(discountTypeDisplayNames).map(
        ([value, label]) =>
          ({
            label,
            value,
          } as RadioChoice<'DOLLAR_DISCOUNT' | 'PERCENT_DISCOUNT'>)
      ),
    [discountTypeDisplayNames]
  )

  const lifespanChoices: RadioChoice<string>[] = useMemo(
    () =>
      Object.entries(dateRangeDisplayNames).map(([value, label]) => ({
        label,
        value,
        description: dateRangeDescriptions[value as PromoRangeApplyType],
      })),
    [dateRangeDescriptions, dateRangeDisplayNames]
  )

  const promoComponentChoices: RadioChoice<string>[] = useMemo(
    () =>
      Object.entries(promoComponentDisplayNames).map(([value, label]) => ({
        label,
        value,
      })),
    [promoComponentDisplayNames]
  )

  const creditCardChoices: SelectOption[] = useMemo(
    () =>
      Object.entries(creditCardDisplayNames).map(([id, label]) => ({
        label,
        id,
      })),
    [creditCardDisplayNames]
  )

  const allEventsNode: TreeNode = useMemo(
    () => ({
      id: 'all',
      value: 'all',
      label: formatMessage(promoCodeMessages.promoCodeFormEventsAllLabel),
      checked: true,
      expanded: true,
      children: [],
    }),
    [formatMessage]
  )

  return {
    widgetChoices,
    codeTypeChoices,
    promoTypeChoices,
    lifespanChoices,
    promoComponentChoices,
    creditCardChoices,
    usageChoices,
    allEventsNode,
  }
}

export const [PromoCodeResourcesContext, usePromoCodeResourcesContext] = constate(usePromoCodeResources)
