import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
`

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  color: #888c92;

  ${props =>
    props.current &&
    `
    color: #161718;
  `};
`

const SeparatorContainer = styled.div`
  font-size: 13px;
  padding: 0 ${props => props.theme.gutter.standard};
`

const RouteContainer = styled.div`
  padding: ${props => props.theme.gutter.standard};
  font-size: ${props => props.theme.fontSize.button};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.primary};
    background: #e8f5ff;
  }

  ${props =>
    props.index === 0 &&
    `
    padding-left: 0;
  `};

  ${props =>
    props.current &&
    `
    &:hover {
      cursor: text;
      color: #161718;
      background: white;
    }
  `};
`

const DefaultSeparator = () => (
  <SeparatorContainer>
    <FontAwesomeIcon icon={faChevronRight} />
  </SeparatorContainer>
)

// TODO add dynamic responsiveness by calculating the width of the container and resizing
// breadcrumb containers
class Breadcrumbs extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onRouteClick = this.onRouteClick.bind(this)
  }

  onRouteClick(route, isCurrent) {
    const { history, onRouteClick } = this.props

    if (typeof onRouteClick === 'function') {
      onRouteClick(route)
    } else if (!isCurrent) {
      history.push(route.route)
    }
  }

  render() {
    const { routes, separator } = this.props

    return (
      <BreadcrumbContainer data-test="sr-breadcrumb-container">
        {routes.map((route, index) => {
          const current = index + 1 === routes.length

          return (
            <Breadcrumb current={current} key={index} data-test="sr-breadcrumb">
              <RouteContainer
                data-test="sr-breadcrumb-route"
                index={index}
                onClick={() => this.onRouteClick(route, current)}
                current={current}
              >
                {route.name}
              </RouteContainer>
              {!current && separator}
            </Breadcrumb>
          )
        })}
      </BreadcrumbContainer>
    )
  }
}

Breadcrumbs.defaultProps = {
  separator: <DefaultSeparator />,
  routes: [],
  onRouteClick: null,
}

export default Breadcrumbs
