import { useState } from 'react'
import type { BookieIntegrationStatus } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Modal, ModalHeader, Spinner, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import bookieLogo from '../images/bookie-logo.png'
import bookieModalBackground from '../images/bookie-modal-background.png'
import { marketingHomeMessages } from '../locales'

export interface BookieIntegrationModalProps {
  bookieIntegrationStatus?: BookieIntegrationStatus
  bookieIntegrationUrl?: string
  showBookieModal: boolean
  setShowBookieModal: (value: boolean) => void
}

export function BookieIntegrationModal({
  bookieIntegrationStatus,
  bookieIntegrationUrl,
  showBookieModal,
  setShowBookieModal,
}: BookieIntegrationModalProps) {
  const { formatMessage } = useLocales()
  const [integrationStarted, setIntegrationStarted] = useState(!!bookieIntegrationStatus)

  const statusMessage =
    bookieIntegrationStatus && bookieIntegrationStatus === 'VALIDATED_CREDENTIALS'
      ? formatMessage(marketingHomeMessages.bookieCommunicatingWaitingForMapping)
      : formatMessage(marketingHomeMessages.bookieCommunicatingStateStart)

  function handleClick() {
    setIntegrationStarted(true)
    window.open(bookieIntegrationUrl, 'bookieWindow', 'menubar=0,width=800,height=800')
  }

  return (
    <Window active={showBookieModal} setIsActive={setShowBookieModal}>
      <Modal ariaLabel="Modal">
        <ModalHeader
          onClose={() => {
            setShowBookieModal(false)
          }}
        />
        {integrationStarted ? (
          <VStack height="100%" width="960px" p="xl" spacing="l" justifyContent="center" alignItems="center">
            <Box>
              <img src={bookieLogo} alt="Bookie" />
            </Box>
            <Spinner />
            <Box>
              <Text fontSize="xxl" lineHeight="xxl">
                {statusMessage}
              </Text>
            </Box>
            <VStack
              alignItems="center"
              backgroundColor="primaryInfoBackground"
              borderRadius="s"
              justifyContent="center"
              p="m"
              spacing="s"
              width="100%"
            >
              <Box>
                <Text fontWeight="bold">{formatMessage(marketingHomeMessages.bookieCommunicatingKeepWindowOpen)}</Text>
              </Box>
              <Box>
                <Text>{formatMessage(marketingHomeMessages.bookieCommunicatingProcessTime)}</Text>
              </Box>
              <Box pt="m">
                <a href="https://trysevenrooms.chilipiper.com/book/crm-and-marketing-suite-onboarding-queue" target="new">
                  <Text textDecoration="underline">{formatMessage(marketingHomeMessages.bookieCommunicatingHelp)}</Text>
                </a>
              </Box>
            </VStack>
          </VStack>
        ) : (
          <HStack>
            <VStack backgroundImage={bookieModalBackground} width="480px" />
            <VStack height="100%" width="480px" p="xl" spacing="l">
              <Box>
                <img src={bookieLogo} alt="Bookie" />
              </Box>
              <Box>
                <Text fontSize="3xl" lineHeight="3xl">
                  {formatMessage(marketingHomeMessages.bookieIntegrationHeader)}
                </Text>
              </Box>
              <Box>
                <Text color="secondaryFont" fontSize="xl" lineHeight="xl">
                  {formatMessage(marketingHomeMessages.bookieIntegrationBody)}
                </Text>
              </Box>
              <VStack pt="m">
                <Box mb="none" pb="none">
                  <Button data-test="bookie-connect-button" onClick={handleClick}>
                    {formatMessage(marketingHomeMessages.bookieConnectButton)}
                  </Button>
                </Box>
                <Box pt="xs">
                  <Text color="secondaryFont" fontSize="xs" lineHeight="s">
                    {formatMessage(marketingHomeMessages.bookieConnectDisclaimer)}
                  </Text>
                </Box>
              </VStack>
            </VStack>
          </HStack>
        )}
      </Modal>
    </Window>
  )
}
