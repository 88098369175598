import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack, Box, Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { WhatsAppIntegration } from '../Integrations'
import { messagingIntegrationMessages } from './MessagingIntegrations.locales'

export function MessagingIntegrations() {
  const { formatMessage } = useLocales()

  return (
    <>
      <SettingsPageMeta title={formatMessage(messagingIntegrationMessages.tabTitle)} />
      <SettingsPageContent
        title={formatMessage(messagingIntegrationMessages.title)}
        description={formatMessage(messagingIntegrationMessages.whatsapp)}
        secondHeaderMaxWidth="968px"
      >
        <Box maxWidth="968px">
          <VStack spacing="l" flexWrap="wrap" p="m">
            <HStack flexWrap="wrap">
              <Flex flex={2} flexWrap="wrap" p="s" backgroundColor="primaryBackground" borderTopLeftRadius="s" borderBottomLeftRadius="s">
                <WhatsAppIntegration />
              </Flex>
            </HStack>
          </VStack>
        </Box>
      </SettingsPageContent>
    </>
  )
}
