import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  accountAndAuthentication: {
    id: 'paymentSettings.NetworkInternationalDirectPurchase.accountAndAuthentication',
    defaultMessage: 'Network International Account & Authentication',
  },
  outletReference: {
    id: 'paymentSettings.NetworkInternationalDirectPurchase.outletReference',
    defaultMessage: 'Outlet Reference',
  },
  ecomAccountKey: {
    id: 'paymentSettings.NetworkInternationalDirectPurchase.ecomAccountKey',
    defaultMessage: 'E-Comm Account Key',
  },
  hostedSessionKey: {
    id: 'paymentSettings.NetworkInternationalDirectPurchase.hostedSessionKey',
    defaultMessage: 'Hosted Session Key',
  },
  webhookKey: {
    id: 'paymentSettings.NetworkInternationalDirectPurchase.webhookKey',
    defaultMessage: 'Webhook Key',
  },
} as const)
