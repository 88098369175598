import { useMemo } from 'react'
import type { ConciergeAccessKeyMap } from '@sevenrooms/core/api'
import { type Concierge, type ConciergeEnabled, ConciergeEnabledEnum, type Perk } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { type TreeNode, useMultiSelectTreeForm } from '@sevenrooms/core/ui-kit/form'

export type PerkConciergeSettingsFormData = ZodSchema<typeof usePerkConciergeSettingsForm>
export type PerkConciergeSettingsFormField = Field<PerkConciergeSettingsFormData>

export function usePerkConciergeSettingsForm() {
  const multiSelectTreeForm = useMultiSelectTreeForm()

  return useMemo(
    () =>
      z
        .object({
          conciergeEnabled: z.custom<ConciergeEnabled>(),
          additionalInformation: z.string(),
          concierges: multiSelectTreeForm,
        })
        .superRefine(({ additionalInformation, conciergeEnabled, concierges }, ctx) => {
          if (conciergeEnabled === ConciergeEnabledEnum.YES) {
            if (!additionalInformation) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                inclusive: true,
                minimum: 1,
                path: ['additionalInformation'],
                type: 'string',
              })
            }
            if (additionalInformation?.length > 500) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_big,
                inclusive: true,
                maximum: 500,
                path: ['additionalInformation'],
                type: 'string',
              })
            }
            if (!concierges?.length) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                inclusive: true,
                minimum: 1,
                path: ['concierges'],
                type: 'array',
              })
            }
          }
        }),
    [multiSelectTreeForm]
  )
}

export const getPerkConciergeSettingsFormDefaultValue = (
  perk?: Perk,
  conciergeAccess?: boolean,
  venueConcierges?: Concierge[]
): PerkConciergeSettingsFormData => ({
  conciergeEnabled: conciergeAccess && perk?.isConcierge === true ? ConciergeEnabledEnum.YES : ConciergeEnabledEnum.NO,
  additionalInformation: perk?.additionalInformation || '',
  concierges: getConciergesSelectedFormState({
    conciergeAccessKeymap: perk?.conciergeAccessKeymap,
    perkId: perk?.id,
    venueConcierges,
  }),
})

export const getConciergesSelectedFormState = ({
  conciergeAccessKeymap,
  perkId,
  venueConcierges,
}: {
  conciergeAccessKeymap: ConciergeAccessKeyMap | null | undefined
  perkId?: string
  venueConcierges?: Concierge[]
}): TreeNode<string>[] =>
  venueConcierges?.map(concierge => ({
    value: concierge.id,
    checked: perkId ? Boolean(conciergeAccessKeymap?.[concierge.id]) : true,
    id: concierge.id,
    label: concierge.name,
  })) || []

export const getConciergeAccessKeymap = ({
  conciergesFormState,
  venueConcierges,
}: {
  conciergesFormState: TreeNode<string>[]
  venueConcierges?: Concierge[]
}): ConciergeAccessKeyMap => {
  const conciergeAccessKeymap: ConciergeAccessKeyMap = {}

  conciergesFormState.forEach(formConcierge => {
    const concierge = venueConcierges?.find(({ id }) => id === formConcierge.id)

    if (concierge) {
      conciergeAccessKeymap[concierge.id] = concierge.name
    }
  })

  return conciergeAccessKeymap
}
