import React, { type PropsWithChildren } from 'react'
import type { Shift } from '@sevenrooms/core/domain'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateRangePicker, DayOfWeekPicker } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ShiftSelectorComponent } from '@sevenrooms/mgr-access-rules-slideout/components/Schedule/components'
import { messages } from '../../locales/offerTemplates.locales'
import { RecommendationTip } from '../RecommendationTip'
import type { CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

export function NewAccessRuleScheduling({
  form,
  allShifts,
}: {
  form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>>
  allShifts: Shift[] | undefined
}) {
  const { formatMessage } = useLocales()
  const { field, getValues } = form
  const values = getValues()

  return (
    <VStack pt="lm" pb="lm" mt="lm" spacing="lm" width="496px">
      <Text textStyle="h2" textAlign="center">
        {formatMessage(messages.newAccessRuleSchedulingStepTitle, {
          offerName: (
            <Text color="primarySelected" textStyle="h2" fontWeight="bold">
              {values.name}
            </Text>
          ),
        })}
      </Text>
      <RecommendationTip
        header={formatMessage(messages.recommendationTipHelpfulTip)}
        description={formatMessage(messages.newAccessRuleSchedulingHelpfulTip)}
        headerIcon="helpfulTip"
      />
      <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingDate)}>
        <HStack pt="xs" pb="xxs">
          <Flex flex="1">
            <Text textStyle="body1Bold">{formatMessage(messages.newAccessRuleSchedulingStartDate)}</Text>
          </Flex>
          <Flex flex="1">
            <Text textStyle="body1Bold">{formatMessage(messages.newAccessRuleSchedulingEndDate)}</Text>
          </Flex>
        </HStack>
        <Box pb="m">
          <DateRangePicker
            data-test="date-range-picker"
            id="date-range-picker"
            field={field.prop('schedule').prop('dateRange')}
            infinite
            showToLabel={false}
          />
        </Box>
      </AccessRuleDetailsFieldWrapper>
      <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingDayOfTheWeek)}>
        <Box pt="s" pb="none">
          <DayOfWeekPicker
            data-test="day-of-week-picker"
            name="day-of-week-picker"
            field={field.prop('schedule').prop('selectedDays')}
            hideSelectAllDays
          />
        </Box>
      </AccessRuleDetailsFieldWrapper>
      <AccessRuleDetailsFieldWrapper label={formatMessage(messages.newAccessRuleSchedulingShifts)}>
        <Box pb="m" pt="s">
          {allShifts && <ShiftSelectorComponent field={field.prop('schedule')} shifts={allShifts} />}
        </Box>
      </AccessRuleDetailsFieldWrapper>
    </VStack>
  )
}

function AccessRuleDetailsFieldWrapper({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <VStack spacing="xs" borderWidth="s" borderRadius="s" borderColor="dividerLines" pt="m" pl="m" pr="m">
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </VStack>
  )
}
