import { defineMessages } from '@sevenrooms/core/locales'

export const accessRuleBulkEditLocales = defineMessages({
  reloadData: {
    id: 'accessRuleBulkEdit.reloadData',
    defaultMessage: 'Reload Data',
  },
  title: {
    id: 'accessRuleBulkEdit.title',
    defaultMessage: 'Access Rule Bulk Editor',
  },
})
