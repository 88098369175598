import { LINK_REVIEW_SUCCESS, UNLINK_REVIEW_SUCCESS } from 'mgr/actualslideout/actions/ActionTypes'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import { fetchReviews, triggerGuestSatisfactionExport } from 'mgr/lib/services/ReviewServices'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { convertFilters } from 'mgr/pages/single-venue/marketing/utils/data'
import { OPEN_SEARCH_MODAL, INIT } from 'mgr/pages/single-venue/search/actions/ActionTypes'

// TODO loader
export const tryLoadReviews = venue => (dispatch, getState) => {
  const state = getState()

  dispatch({
    type: ActionTypes.LOAD_REVIEWS_START,
  })

  dispatch({
    type: INIT,
    venueKey: state.appState.venue.urlKey,
  })

  const filters = convertFilters(state.reviews.filters)

  return fetchReviews({ venue: venue.id, ...filters })
    .then(response =>
      dispatch({
        type: ActionTypes.LOAD_REVIEWS_SUCCESS,
        results: response.data.results,
        stats: response.data.stats,
        total: response.data.total,
        lastUpdated: response.data.last_updated_dt,
        appState: state.appState,
      })
    )
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('an error occurred fetching reviews', e)
      dispatch({
        type: ActionTypes.LOAD_REVIEWS_FAIL,
      })
      dispatch(GlobalActions.showErrorMessage('Fetching reviews failed, please try again or contact support'))

      return e
    })
}

export const onChangeVenue = venue => (dispatch, getState) => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })

  // TODO fix when app state, venue settings issues are fixed for SPA
  // dispatch(tryLoadReviews(venue))
}

export const openSearch = review => (dispatch, getState) => {
  dispatch({
    type: OPEN_SEARCH_MODAL,
    review,
  })

  window.metric.track('GuestSatisfaction.ClickedSearchForMatches')
}

export const openReview = data => (dispatch, getState) => {
  const review = data ? data.original : null
  dispatch({
    type: ActionTypes.OPEN_REVIEW,
    selected: review,
  })
}

export const setReviewReply = review => ({ type: ActionTypes.SET_REVIEW_REPLY, review })

const openLinkedActual = actualId => (dispatch, getState) => {
  const { appState } = getState()
  const venueId = appState.venue.id
  if (globalInit.venueSettings.use_supafly) {
    SvrManager.ActualSlideout.viewActual(actualId, venueId, () => {
      SvrManager.ActualSlideout.scrollOnLoad()
    })
  } else {
    ReservationSlideOut.showReservation(actualId)
  }
}

const openLinkedOrder = orderId => (dispatch, getState) => {
  const { appState } = getState()
  const venueId = appState.venue.id
  SvrManager.OrderSlideout.viewOrder(venueId, orderId)
}

export const smartRowClick = rowData => dispatch => {
  dispatch(openReview(null))
  SvrManager.ActualSlideout.closeSlideout()
  if (rowData && rowData.original && rowData.original.actual_id) {
    dispatch(openLinkedActual(rowData.original.actual_id))
  } else if (rowData && rowData.original && rowData.original.order_id) {
    dispatch(openLinkedOrder(rowData.original.order_id))
  } else {
    dispatch(openReview(rowData))
  }

  window.metric.track('GuestSatisfaction.ClickedIntoReviewOrFeedbackRow')
}

export const linkReview = review => dispatch => {
  dispatch({
    type: LINK_REVIEW_SUCCESS,
    review,
  })
}

export const unlinkReview = review => dispatch => {
  dispatch({
    type: UNLINK_REVIEW_SUCCESS,
    review,
  })
}

export const startLoading = () => dispatch => dispatch({ type: ActionTypes.LOAD_REVIEWS_START })

export const tryTriggerGuestSatisfactionExport = () => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  const filters = convertFilters(state.reviews.filters)

  window.metric.track('GuestSatisfaction.ClickedExportReviews')

  dispatch({
    type: ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT,
  })

  return triggerGuestSatisfactionExport({ venue: venue.id, ...filters })
    .then(response => {
      if (response.status !== 200) {
        throw new Error(response)
      }

      dispatch({
        type: ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT_SUCCESS,
      })
      dispatch(GlobalActions.showSuccessMessage(response.data.msg))
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('an error occurred triggering export', e)

      dispatch(GlobalActions.showErrorMessage('Export failed, please try again later or contact support.'))
      dispatch({
        type: ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT_FAIL,
      })

      return e
    })
}
