import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  type GridRowsProp,
  type GridColDef,
  type GridRenderCellParams,
  type GridSortModel,
  DataGrid,
} from '@mui/x-data-grid'
import React, { useState, useCallback, useMemo } from 'react'
import type { AccessRule, AccessRules, ShiftsByDate } from '@sevenrooms/core/domain'
import { Box } from '@sevenrooms/react-components/components/Box'

interface GroupedAccessListProps {
  columns: GridColDef[]
  rows: GridRowsProp
  groupBy: string
  shifts: ShiftsByDate | undefined
  accessRules: AccessRules | AccessRule[] | undefined
}

interface GroupRow {
  id: string
  isGroup: boolean
  shiftCategory?: string
  rule?: string
  schedule?: string
  time?: string
  pax?: string
  seatingArea?: string
  slotDescription?: string
  paymentPolicy?: string
  audience?: string
  duration?: string
  coverLimit?: string
  bookStartEndTime?: string
  offers?: string
  upgrades?: string
}

function AccessRulesDataGrid({ columns: propColumns, rows: propRows, groupBy, shifts, accessRules }: GroupedAccessListProps) {
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({})

  // Memoized columns with the group column
  const columns = useMemo(() => {
    const groupColumn: GridColDef = {
      field: groupBy,
      headerName: propColumns.find(col => col.field === groupBy)?.headerName ?? groupBy.charAt(0).toUpperCase() + groupBy.slice(1),
      width: 100,
      sortable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isGroup ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {expandedGroups[params.row.id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            {params.row[groupBy]}
          </Box>
        ) : null,
    }

    const updatedColumns = propColumns.filter(col => col.field !== groupBy)
    return [groupColumn, ...updatedColumns]
  }, [expandedGroups, groupBy, propColumns])

  // Initial sorted child rows
  const [sortedChildRows, setSortedChildRows] = useState<Record<string, GroupRow[]>>(() => {
    const initialSortedChildRows: Record<string, GroupRow[]> = {}

    propRows.forEach(row => {
      if (!(row as GroupRow).isGroup) {
        const groupValue = row[groupBy]
        if (!initialSortedChildRows[groupValue]) {
          initialSortedChildRows[groupValue] = []
        }
        initialSortedChildRows[groupValue]?.push(row as GroupRow)
      }
    })

    // Sort each group's child rows in ascending order by default
    Object.keys(initialSortedChildRows).forEach(groupValue => {
      initialSortedChildRows[groupValue]?.sort((a, b) => {
        const ruleA = a.rule ?? ''
        const ruleB = b.rule ?? ''

        if (ruleA < ruleB) {
          return -1
        } else if (ruleA > ruleB) {
          return 1
        }
        return 0
      })
    })

    return initialSortedChildRows
  })

  // Memoized rows with grouping
  const rows = useMemo(() => {
    const groupedRows: Record<string, GroupRow> = {}
    propRows.forEach(row => {
      const groupValue = row[groupBy]
      if (!groupedRows[groupValue]) {
        groupedRows[groupValue] = {
          id: `group-${groupValue}`,
          [groupBy]: groupValue,
          isGroup: true,
        }
      }
    })

    const rowsWithGroups: GroupRow[] = []
    Object.values(groupedRows).forEach(groupRow => {
      rowsWithGroups.push(groupRow)
      const groupValue = groupRow[groupBy as keyof GroupRow]
      if (expandedGroups[groupRow.id] && typeof groupValue === 'string') {
        rowsWithGroups.push(...(sortedChildRows[groupValue] || []))
      }
    })
    return rowsWithGroups
  }, [expandedGroups, groupBy, propRows, sortedChildRows])

  // Toggle group expanded state
  const toggleGroup = useCallback((groupId: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }, [])

  // Handle sort model change
  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length === 0 || !sortModel[0]?.field || !sortModel[0]?.sort) {
        setSortedChildRows(prev => ({ ...prev }))
        return
      }

      const { field, sort } = sortModel[0]

      const newSortedChildRows = { ...sortedChildRows }
      Object.keys(newSortedChildRows).forEach(groupId => {
        const groupRows = newSortedChildRows[groupId]
        if (groupRows) {
          newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
            const fieldKey = field as keyof GroupRow
            const aValue = a[fieldKey] ?? ''
            const bValue = b[fieldKey] ?? ''

            if (aValue < bValue) {
              return sort === 'asc' ? -1 : 1
            }
            if (aValue > bValue) {
              return sort === 'asc' ? 1 : -1
            }
            return 0
          })
        }
      })

      setSortedChildRows(newSortedChildRows)
    },
    [sortedChildRows]
  )

  // Handle group row click
  const handleGroupRowClick = useCallback(
    (params: { row: GroupRow }) => {
      if (params.row.isGroup) {
        toggleGroup(params.row.id)
      } else {
        // eslint-disable-next-line no-console
        console.log('Child row clicked:', params.row)
      }
    },
    [toggleGroup]
  )

  if (!shifts || !accessRules) {
    return null
  }

  return (
    <DataGrid
      disableColumnSelector={false}
      disableColumnResize
      slots={{
        toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
          </GridToolbarContainer>
        ),
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'rule', sort: 'asc' }],
        },
      }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      sortingMode="server"
      getRowClassName={params => (params.row.isGroup ? 'group-header' : '')}
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSortModelChange}
      onRowClick={handleGroupRowClick}
      isRowSelectable={() => false}
      hideFooter
      sx={{
        height: '100%',
        mx: 0,
        '& .MuiDataGrid-row.group-header': {
          fontWeight: 'bold',
          backgroundColor: 'grey.100',
          cursor: 'pointer',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus-visible':
          {
            outline: 'none !important',
          },
        '& .MuiDataGrid-row:hover .MuiDataGrid-cell': {
          cursor: 'pointer',
        },
      }}
    />
  )
}

export { AccessRulesDataGrid }
