import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelectRangePicker } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { partySizeRangePickerMessages } from './PartySizeRangePicker.messages'
import type { PartySizeRangePickerForm } from './PartySizeRangePicker.zod'

export interface PartySizeRangePickerProps {
  field: Field<PartySizeRangePickerForm>
}

export function PartySizeRangePicker({ field }: PartySizeRangePickerProps) {
  const { formatMessage } = useLocales()

  const options = useMemo(
    (): SelectOption<number | string>[] =>
      Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        label: String(index + 1),
      })),
    []
  )

  return (
    <VStack>
      <FormSelectRangePicker
        field={field}
        minSelectProps={{ options, label: formatMessage(partySizeRangePickerMessages.partySizeMin) }}
        maxSelectProps={{ options, label: formatMessage(partySizeRangePickerMessages.partySizeMax) }}
      />
    </VStack>
  )
}
