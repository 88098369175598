import { useMemo } from 'react'
import type { Perk } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'

export type PerkAssociatedTagsFormData = ZodSchema<typeof usePerkAssociatedTagsForm>
export type PerkAssociatedTagsFormField = Field<PerkAssociatedTagsFormData>

export function usePerkAssociatedTagsForm() {
  const tagOptionSchema = useMultiSelectTagOptionForm()
  return useMemo(
    () =>
      z.object({
        clientTags: tagOptionSchema,
        reservationTags: tagOptionSchema,
      }),
    [tagOptionSchema]
  )
}

export const getPerkAssociatedTagsFormDefaultValues = (perk?: Perk): PerkAssociatedTagsFormData => ({
  clientTags: perk?.associatedClientTags ?? [],
  reservationTags: perk?.associatedReservationTags ?? [],
})
