import { useMemo } from 'react'
import type { Shift } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { Box, BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { generateTimeSlots } from '../utils'
import { CustomPacingRow } from './CustomPacingRow'
import type { PacingForm } from '../Pacing.zod'

export interface CustomPacingEditProps {
  shifts: Shift[]
  field: Field<PacingForm>
}

export function CustomPacingEdit(props: CustomPacingEditProps) {
  const { startOfDayTime } = useAppContext().venueSettings
  const timeSlots = useMemo(() => generateTimeSlots(startOfDayTime), [startOfDayTime])

  return (
    <BaseSection>
      <Box height="327px" overflow="scroll" p="m">
        <VStack spacing="s">
          {timeSlots.map(timeSlot => (
            <CustomPacingRow
              key={timeSlot.id}
              timeSlotId={timeSlot.id}
              timeSlotLabel={timeSlot.label}
              defaultPacing={props.field.prop('maxCoversPerSeatingInterval')}
              customPacing={props.field.prop('customPacing')}
            />
          ))}
        </VStack>
      </Box>
    </BaseSection>
  )
}
