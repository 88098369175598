import React, { useEffect, useMemo, useState } from 'react'
import { useLazyGetPOSILocationsQuery } from '@sevenrooms/core/api'
import {
  type POSICheckTriggers,
  type POSICourseStatus,
  POSITypeEnum,
  type SimphonyConfig,
  type SimphonyLocation,
  type SimphonyMenuItem,
  type SimphonyRevenueCenter,
  type POSIType,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { LoadingSettingsNoHeader } from '../../../../Loading'
import { SimphonyAuthenticationCard } from '../../../AuthenticationCard'
import { SimphonyAutomaticCheckCreationCard } from '../../../AutomaticCheckCreationCard'
import { SimphonyAutomaticPrepaymentRedemptionCard } from '../../../AutomaticPrepaymentRedemptionCard'
import { CourseStatusMappingCard } from '../../../CourseStatusMappingCard'
import { SimphonyLocationSelectionCard } from '../../../LocationSelectionCard/SimphonyLocationSelectionCard'
import { TableMappingCard } from '../../../TableMappingCard'
import { BaseEditPageForm } from '../BaseEditPageForm'
import { useSavePOSIConfig } from '../hooks'
import { messages } from './SimphonyEditPageForm.locales'
import {
  convertSimphonyEditPageFormDataToSimphonyConfig,
  getSimphonyEditPageFormDefaultValues,
  type SimphonyEditPageFormData,
  useSimphonyEditPageForm,
} from './SimphonyEditPageForm.zod'
import type { EditPageFormProps } from '../EditPageForm'

export interface SimphonyEditPageFormProps extends EditPageFormProps {
  config: SimphonyConfig
  locations: SimphonyLocation[]
  inServiceStatuses: POSICourseStatus[]
  checkTriggers?: POSICheckTriggers
  menuItems: SimphonyMenuItem[]
  isFetching: boolean
  refetchConfig: () => void
  courseStatusMappingErrorBanners?: React.ReactNode
}

export function SimphonyEditPageForm({
  config,
  locations,
  inServiceStatuses,
  checkTriggers,
  menuItems,
  isFetching,
  refetchConfig,
  courseStatusMappingErrorBanners,
}: SimphonyEditPageFormProps) {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()

  // Lazy query to fetch revenue centers upon location change
  // Managed here because data is used in Location card and Automatic Check Creation card
  const [fetchRevenueCenters, { data: revenueCenters, isSuccess: isFetchRevenueCentersSuccess, isError: isFetchRevenueCentersError }] =
    useLazyGetPOSILocationsQuery()

  // Show a toast notification if we fail to fetch revenue centers
  useEffect(() => {
    if (isFetchRevenueCentersError) {
      notify({
        type: 'error',
        content: formatMessage(messages.fetchRevenueCentersError),
      })
    }
  }, [formatMessage, isFetchRevenueCentersError])

  // If the config has a location on page load, fetch revenue centers to populate dropdown
  useEffect(() => {
    if (config) {
      if (config.locationId) {
        fetchRevenueCenters({ venueId, posiType: venue.posIntegrationType as POSIType, parentLocationId: config.locationId })
      }
    }
  }, [config, fetchRevenueCenters, venue.posIntegrationType, venueId])

  // Initialize the form
  const defaultValues = useMemo(() => getSimphonyEditPageFormDefaultValues(config), [config])
  const { saveConfig, isSaving } = useSavePOSIConfig()
  const form = useForm(useSimphonyEditPageForm(), {
    defaultValues,
  })
  const { field, formState, watch, setValue } = form
  const { isDirty } = formState

  const watchSelectedLocationId = watch('locationCard.locationId')
  const [selectedLocationId, setSelectedLocationId] = useState<string>(watchSelectedLocationId)
  useEffect(() => {
    if (watchSelectedLocationId !== selectedLocationId) {
      setValue('locationCard.checkPollingRvcs', [])
      setValue('automaticCheckCreationCard.checkCreationRvc', '')
      setValue('automaticPrepaymentRedemptionCard.depositMenuItemId', '')
      setSelectedLocationId(watchSelectedLocationId)

      // Fetch Revenue Centers for the new location
      fetchRevenueCenters({ venueId, posiType: venue.posIntegrationType as POSIType, parentLocationId: watchSelectedLocationId })
    }
  }, [watchSelectedLocationId, setValue, selectedLocationId, fetchRevenueCenters, venueId, venue.posIntegrationType])

  const disabled = useMemo(() => !isDirty || isSaving || isFetching, [isDirty, isSaving, isFetching])

  const handleSave = async (data: SimphonyEditPageFormData) => {
    await saveConfig({
      posiType: POSITypeEnum.SIMPHONY,
      config: convertSimphonyEditPageFormDataToSimphonyConfig({ config, data }),
      refetchConfig,
    })
  }
  const isLocation = !!config.locationId

  if (isLocation && !isFetchRevenueCentersSuccess) {
    return <LoadingSettingsNoHeader />
  }

  return (
    <Form {...form} onSubmit={data => handleSave(data)} onInvalid={() => {}}>
      <BaseEditPageForm posiType={POSITypeEnum.SIMPHONY} disabled={disabled}>
        <SimphonyAuthenticationCard field={field.prop('authenticationCard')} />
        <SimphonyLocationSelectionCard
          field={field.prop('locationCard')}
          locations={locations}
          revenueCenters={revenueCenters ? (revenueCenters as SimphonyRevenueCenter[]) : []}
          selectedLocationId={selectedLocationId}
        />
        <SimphonyAutomaticCheckCreationCard
          field={field.prop('automaticCheckCreationCard')}
          config={config}
          revenueCenters={revenueCenters ? (revenueCenters as SimphonyRevenueCenter[]) : []}
        />
        <SimphonyAutomaticPrepaymentRedemptionCard field={field.prop('automaticPrepaymentRedemptionCard')} menuItems={menuItems} />
        {venueSettings?.pos_enable_table_mapping_wizard && <TableMappingCard />}
        <CourseStatusMappingCard
          field={field.prop('courseStatusMappingCard')}
          inServiceStatuses={inServiceStatuses}
          isFetching={isFetching}
          checkTriggers={checkTriggers}
          posiType={POSITypeEnum.SIMPHONY}
          errorBanners={courseStatusMappingErrorBanners}
          isLocation={isLocation}
        />
      </BaseEditPageForm>
    </Form>
  )
}
