import { useLocales } from '@sevenrooms/core/locales'
import { Box, DividerLine, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './BaseLocationSelectionCard.locales'
import type { PropsWithChildren } from 'react'

export interface BaseLocationSelectionCardData {
  docLink: string
}

export function BaseLocationSelectionCard({ children, docLink }: PropsWithChildren<BaseLocationSelectionCardData>) {
  /**
   * This component is a wrapper for Location Selection Card components.
   */
  const { formatMessage } = useLocales()

  return (
    <VStack backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
      <HStack spacing="m" pt="m" pb="l" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
        <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
          <Text textStyle="h2">{formatMessage(messages.configurationLabel)}</Text>
          <Text>
            <Anchor href={docLink}>{formatMessage(messages.docLinkLabel)}</Anchor>
          </Text>
          <Box width="100%">
            <DividerLine mr="none" ml="none" />
          </Box>
          {children}
        </VStack>
      </HStack>
    </VStack>
  )
}
