import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { PartySizeLocales } from './PartySize.locales'

export type PartySizeForm = ZodSchema<typeof usePartySizeForm>

export function usePartySizeForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z
        .object({
          min: z
            .number()
            .gt(0, { message: formatMessage(PartySizeLocales.minNonZero) })
            .nullable(),
          max: z
            .number()
            .gt(0, { message: formatMessage(PartySizeLocales.maxNonZero) })
            .nullable(),
        })
        .superRefine(({ min, max }, ctx) => {
          if (max && !min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PartySizeLocales.minRequired),
              path: ['min'],
            })
          }
          if (min && !max) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PartySizeLocales.maxRequired),
              path: ['max'],
            })
          }
          if (min && max && max < min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PartySizeLocales.maxGreaterThanMin),
              path: ['max'],
            })
          }
        }),
    [formatMessage]
  )
}

export function getInitialPartySize(accessRule?: AccessRule): PartySizeForm {
  return { max: accessRule?.partySizeMax ?? null, min: accessRule?.partySizeMin ?? null }
}
