import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetEmailCampaignByIdQuery, useGetVenueProfileQuery, type OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useDestination } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OneTimeEmailCampaignViewerForm } from '../../components'

export function OneTimeEmailCampaignViewer() {
  const { venueId } = useVenueContext()
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.viewOneTimeEmail)
  const { campaignId } = params

  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data, isFetching: isEmailCampaignFetching } = useGetEmailCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const emailCampaign = data?.emailCampaign
  const isFetching = isVenueProfileFetching || isEmailCampaignFetching

  return isFetching || !venueProfile || !emailCampaign ? (
    <Loader />
  ) : (
    <OneTimeEmailCampaignViewerForm emailCampaign={emailCampaign as OneTimeEmailCampaignType} venueProfile={venueProfile} />
  )
}
