import type { PromoComponent } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Label, MultiSelectTree, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { SimpleSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { usePromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'

export function AdvancedSettingsSection() {
  const { formatMessage } = useLocales()
  const { promoComponentChoices, creditCardChoices } = usePromoCodeResourcesContext()
  const {
    selectedCreditCard,
    setSelectedCreditCard,
    selectedPromoComponent,
    setSelectedPromoComponent,
    eventsChoices,
    setSelectedEvents,
    widgets,
  } = usePromoCodeContext()
  const selectedWidgets = widgets.filter(selectedWidget => selectedWidget.checked)
  const isOnlyEventWidgetSelected = selectedWidgets.length === 1 && selectedWidgets[0]?.value === 'EVENT_WIDGET'
  const isOnlyReservationWidgetSelected = selectedWidgets.length === 1 && selectedWidgets[0]?.value === 'RESERVATION_WIDGET'
  const isCreditCardHidden = true // there are problems with applying promo for several card types, temporarily hide this widget

  return (
    <SimpleSection
      title={formatMessage(promoCodeMessages.promoCodeFormAdvancedSection)}
      description={formatMessage(promoCodeMessages.promoCodeFormAdvancedSectionDescription)}
      initiallyCollapsed
    >
      <VStack p="lm" spacing="lm" width="496px">
        {isOnlyReservationWidgetSelected && (
          <>
            <Label primary={formatMessage(promoCodeMessages.promoCodeFormPromoComponentLabel)}>
              <RadioGroup
                data-test="promo-code-form-promo-component-field"
                name="promoComponent"
                choices={promoComponentChoices}
                selected={selectedPromoComponent}
                onChange={selected => setSelectedPromoComponent(selected.value as PromoComponent)}
              />
            </Label>
            {!isCreditCardHidden && (
              <Label
                primary={formatMessage(promoCodeMessages.promoCodeFormCreditCardLabel)}
                secondary={formatMessage(promoCodeMessages.promoCodeFormCreditCardDescription)}
              >
                <Select
                  data-test="promo-code-form-credit-cards-field"
                  options={creditCardChoices}
                  value={selectedCreditCard}
                  searchable={false}
                  onChange={setSelectedCreditCard}
                />
              </Label>
            )}
          </>
        )}
        {isOnlyEventWidgetSelected && (
          <Label primary={formatMessage(promoCodeMessages.promoCodeFormEventsLabel)}>
            <MultiSelectTree
              data-test="promo-code-form-events-field"
              showMoreLabel=""
              treeData={eventsChoices}
              onChange={(_option, selectedNodes) => {
                setSelectedEvents(selectedNodes)
              }}
            />
          </Label>
        )}
      </VStack>
    </SimpleSection>
  )
}
