import { useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationCoverLimitLocales } from '../ReservationCoverLimit.locales'
import type { ReservationCoverLimitProps } from './ReservationCoverLimitProps'

export function CollapsedReservationCoverLimit({ field }: ReservationCoverLimitProps) {
  const { formatMessage } = useLocales()
  const { count, type, guaranteeBookings } = useWatch(field)

  return count != null ? (
    <Text fontSize="m">
      <VStack spacing="s">
        <Pair
          left={formatMessage(ReservationCoverLimitLocales.defaultsKey)}
          right={formatMessage(ReservationCoverLimitLocales.selectionSummary, { type, count })}
        />
        <Pair
          left={formatMessage(ReservationCoverLimitLocales.guaranteeBookingsSummaryKey)}
          right={formatMessage(guaranteeBookings ? commonMessages.yes : commonMessages.no)}
        />
      </VStack>
    </Text>
  ) : null
}
