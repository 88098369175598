import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  cardLabel: {
    id: 'posiSettings.BaseAutomaticPrepaymentRedemptionCard.cardLabel',
    defaultMessage: 'Seamless Prepayment Sync',
  },
  cardSubHeadingLabel: {
    id: 'posiSettings.BaseAutomaticPrepaymentRedemptionCard.cardSubHeadingLabel',
    defaultMessage: 'Reservation prepayments seamlessly synced to the POS. <a>Learn More</a>',
  },
} as const)
