import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { CheckboxAlignedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { ScheduleLocales } from '../Schedule.locales'
import { ScheduleTestId } from '../Schedule.testIds'
import { ShiftSelector } from './ShiftSelector'
import type { ScheduleProps } from '../ScheduleProps'
import type { PropsWithChildren } from 'react'

export function ShiftRestrictable(props: PropsWithChildren<ScheduleProps>) {
  const { field } = props
  const { formatMessage } = useLocales()
  const restrictToShifts = useWatch(field.prop('restrictToShifts'))

  return (
    <VStack spacing="sm">
      {props.children}
      <Checkbox data-test={ScheduleTestId.restrictToShiftsCheckBox} field={field.prop('restrictToShifts')}>
        {formatMessage(ScheduleLocales.restrictToShiftsLabel)}
      </Checkbox>
      {restrictToShifts && (
        <CheckboxAlignedBox>
          <ShiftSelector {...props} />
        </CheckboxAlignedBox>
      )}
    </VStack>
  )
}
