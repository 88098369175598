import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { TimeBefore } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { perksMessages } from '../../locales'
import type { PerkSelectAccessRuleModalFormField } from './PerkSelectAccessRuleModalForm.zod'

interface PerkSelectAccessRuleModalBookingProps {
  field: PerkSelectAccessRuleModalFormField
}

export function PerkSelectAccessRuleModalBooking({ field }: PerkSelectAccessRuleModalBookingProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="s" mt="s" height="320px">
      <Text>{formatMessage(perksMessages.earlyAccessRuleModalReservationLabel)}</Text>
      <TimeBefore field={field.prop('releaseTableTimeData')} data-test="time-before" />
    </VStack>
  )
}
