import { type PropsWithChildren, useCallback } from 'react'
import { useWatch } from '@sevenrooms/core/form'
import { FormToggle, type FormToggleProps } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'

export interface ToggleTogglerProps extends FormToggleProps {
  title: string
  onShow?: () => void
  onHide?: () => void
}

export function ToggleToggler({ title, onShow, onHide, children, ...props }: PropsWithChildren<ToggleTogglerProps>) {
  const checked = useWatch(props.field)

  const toggle = useCallback(
    value => {
      if (value) {
        onShow?.()
      } else {
        onHide?.()
      }
    },
    [onHide, onShow]
  )

  return (
    <>
      <FormToggle {...props} onChange={toggle} label={title} />
      {checked && children && <Box mt="sm">{children}</Box>}
    </>
  )
}
