// eslint-disable-next-line no-restricted-imports
import { startOfWeek } from 'date-fns/startOfWeek'
import { useContext, useMemo } from 'react'
import { useGetAccessRulesListQuery, useGetAccessRulesQuery, useGetShiftsScheduleQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { getDateFNSLocale, DateOnly } from '@sevenrooms/core/timepiece'
import { SettingsPageContent, SettingsPageContext, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Divider } from '@sevenrooms/react-components/components/Divider'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { AccessRulesActionButtons } from '../../components/ActionButtons/AccessRulesActionButtons'
import { ActionsBar } from '../../components/ActionsBar/ActionsBar'
import { AccessRuleSlideoutProvider } from '../../contexts/AccessRuleSlideoutProvider'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'
import { AccessRulesCalendar } from '../AccessRulesCalendar/AccessRulesCalendar'
import { AccessRulesList } from '../AccessRulesList/AccessRulesList'

function AccessRulesSettingsContainer({ children }: React.PropsWithChildren<{}>) {
  const headerHeight = useContext(SettingsPageContext)

  return (
    <Box height="100%" sx={{ backgroundColor: theme => theme.palette.common.white }}>
      <Stack height="100%" maxHeight={`calc(100vh - ${headerHeight}px)`} padding={4} boxSizing="border-box">
        {children}
      </Stack>
    </Box>
  )
}

export function AccessRulesSettings() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueLocale } = useAppContext()
  const locale = getDateFNSLocale(venueLocale)

  const [{ view, range, date }] = useAccessRulesUrlParams()

  const [start, end, overviewDates] = useMemo(() => {
    const start = startOfWeek(date.toJsDate(), { weekStartsOn: 0 })
    return [
      start,
      new Date(new Date(start).setDate(start.getDate() + 6)),
      Array.from({ length: 7 }, (_, i: number) => new Date(new Date(start).setDate(start.getDate() + i))).map(x => DateOnly.fromDate(x)),
    ]
  }, [date])

  const shifts = useGetShiftsScheduleQuery({
    venueId: venue.id,
    startDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(start),
    endDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(end),
  })

  // Note: Each of these queries returns a different structured object, so we need to handle them differently in child components.
  // It could either be an AccessRules object (for date ranges) or an array of AccessRule objects (when grabbing all).
  const useListOverviewQuery = range === AccessRuleRange.OVERVIEW && view === AccessRuleView.LIST
  const accessRulesByRange = useGetAccessRulesQuery(
    {
      venueId: venue.id,
      startDate: range === AccessRuleRange.DAY ? date.toIso() : start.toISOString(),
      endDate: range === AccessRuleRange.DAY ? date.toIso() : end.toISOString(),
    },
    { skip: useListOverviewQuery }
  )
  const accessRulesOverview = useGetAccessRulesListQuery({ venueId: venue.id, startDate: date.toIso() }, { skip: !useListOverviewQuery })

  const accessRules = useListOverviewQuery ? accessRulesOverview : accessRulesByRange

  if (!venue) {
    return null
  }

  return (
    <ThemeProvider theme={vmsTheme}>
      <LocalizationProvider adapterLocale={locale}>
        <AccessRuleSlideoutProvider>
          <SettingsPageMeta title={formatMessage(accessRulesSettingsMessages.title)} />
          <SettingsPageContent
            secondHeaderMaxWidth="100%"
            secondHeaderTextMaxWidth="90%"
            title={formatMessage(accessRulesSettingsMessages.title)}
            description={formatMessage(accessRulesSettingsMessages.subtitle, {
              a: (chunks: string[]) => (
                <Link
                  data-test="access-rules-settings-help"
                  href="https://help.sevenrooms.com/hc/en-us/categories/360004583192-Availability"
                >
                  {chunks}
                </Link>
              ),
            })}
            actions={<AccessRulesActionButtons />}
          >
            <AccessRulesSettingsContainer>
              <ActionsBar />
              <Divider sx={{ marginBottom: 4, marginTop: 4 }} />
              {!shifts.isFetching && !accessRules.isFetching && (
                <>
                  {view === AccessRuleView.CALENDAR ? (
                    <Box width="100%" height="100%" sx={{ overflowY: 'auto' }}>
                      <AccessRulesCalendar overviewDates={overviewDates} shifts={shifts.data} />
                    </Box>
                  ) : (
                    <Box width="100%" height="100%" sx={{ overflowY: 'auto' }}>
                      <AccessRulesList accessRules={accessRules.data} shifts={shifts.data} />
                    </Box>
                  )}
                </>
              )}
            </AccessRulesSettingsContainer>
          </SettingsPageContent>
        </AccessRuleSlideoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
