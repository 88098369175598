import { useMemo } from 'react'
import type { PerkAvailabilityTimes } from '@sevenrooms/core/api'
import {
  type Concierge,
  ConciergeEnabledEnum,
  type Perk,
  PerkAccessTypeEnum,
  type PerkAvailabilityType,
  type PerkType,
  PerkTypeEnum,
  type PerkAssociatedAccessRulesData,
} from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { DateTime } from '@sevenrooms/core/timepiece'
import type { TimeRangePickerForm } from '@sevenrooms/core/ui-kit/form'
import {
  getPerkAssociatedTagsFormDefaultValues,
  usePerkAssociatedTagsForm,
} from '../../components/PerkAssociatedTagsForm/PerkAssociatedTagsForm.zod'
import {
  getPerkAvailabilityRestrictionsFormDefaultValues,
  usePerkAvailabilityRestrictionsForm,
} from '../../components/PerkAvailabilityRestrictionsForm/PerkAvailabilityRestrictionsForm.zod'
import {
  usePerkConciergeSettingsForm,
  getConciergeAccessKeymap,
  getPerkConciergeSettingsFormDefaultValue,
} from '../../components/PerkConciergeSettingsForm/PerkConciergeSettingsForm.zod'
import { getPerkNameFormDefaultValue, usePerkNameForm } from '../../components/PerkNameForm/PerkNameForm.zod'
import { getPerkVisibilityFormDefaultValue, usePerkVisibilityForm } from '../../components/PerkVisibilityForm/PerkVisibilityForm.zod'
import { START_TIME, END_TIME } from '../../constants'

export type CreateEditPerkFormData = ZodSchema<typeof useCreateEditPerkForm>
export type CreateEditPerkFormField = Field<CreateEditPerkFormData>

export const useCreateEditPerkForm = () => {
  const associatedTags = usePerkAssociatedTagsForm()
  const internalName = usePerkNameForm()
  const visibility = usePerkVisibilityForm()
  const availabilityRestrictions = usePerkAvailabilityRestrictionsForm()
  const conciergeSettings = usePerkConciergeSettingsForm()

  return useMemo(
    () =>
      z.object({
        visibility,
        internalName,
        associatedTags,
        conciergeSettings,
        availabilityRestrictions,
        perkType: z.custom<PerkType>(),
        venueId: z.string().optional(),
      }),
    [associatedTags, internalName, visibility, availabilityRestrictions, conciergeSettings]
  )
}

export const getCreateEditPerkDefaultValues = ({
  perk,
  venueConcierges,
  conciergeAccess,
  startOfDayTime = START_TIME,
}: {
  perk?: Perk
  venueConcierges?: Concierge[]
  conciergeAccess: boolean
  startOfDayTime?: string
}): CreateEditPerkFormData => ({
  internalName: getPerkNameFormDefaultValue(perk),
  visibility: getPerkVisibilityFormDefaultValue(perk),
  availabilityRestrictions: getPerkAvailabilityRestrictionsFormDefaultValues(perk, startOfDayTime),
  perkType: perk?.isGlobal === true ? PerkTypeEnum.GLOBAL : PerkTypeEnum.LOCAL,
  conciergeSettings: getPerkConciergeSettingsFormDefaultValue(perk, conciergeAccess, venueConcierges),
  venueId: perk?.venueId || '',
  associatedTags: getPerkAssociatedTagsFormDefaultValues(perk),
})

// convert form state back to type Perk
// add transform here if the form state does not match the Perk interface
export const formToPerk = ({
  createEditPerkFormData,
  venueConcierges,
  associatedAccessRulesData,
}: {
  createEditPerkFormData: CreateEditPerkFormData
  venueConcierges?: Concierge[]
  associatedAccessRulesData?: PerkAssociatedAccessRulesData
}): Omit<Perk, 'venueId' | 'id' | 'venueCreatedFrom'> => ({
  isGlobal: createEditPerkFormData.perkType === PerkTypeEnum.GLOBAL,
  startDate:
    createEditPerkFormData.availabilityRestrictions.dateRange?.startDate &&
    DateTime.fromJsDateSafe(createEditPerkFormData.availabilityRestrictions.dateRange?.startDate),
  endDate:
    createEditPerkFormData.availabilityRestrictions.dateRange?.isInfinite === true
      ? null
      : createEditPerkFormData.availabilityRestrictions.dateRange?.endDate &&
        DateTime.fromJsDateSafe(createEditPerkFormData.availabilityRestrictions.dateRange?.endDate),
  isConcierge: createEditPerkFormData.conciergeSettings.conciergeEnabled === ConciergeEnabledEnum.YES,
  dowOffered: [...Array(7)].map((i, index) => createEditPerkFormData.availabilityRestrictions.dowOffered.includes(index)),
  additionalInformation: createEditPerkFormData.conciergeSettings.additionalInformation || '',
  conciergeAccessKeymap: getConciergeAccessKeymap({
    conciergesFormState: createEditPerkFormData.conciergeSettings.concierges,
    venueConcierges,
  }),
  internalName: createEditPerkFormData.internalName,
  externalName: createEditPerkFormData.visibility.externalName,
  displayedExternally: createEditPerkFormData.visibility.displayedExternally,
  usingInternalName: !createEditPerkFormData.visibility.displayedExternally ? true : createEditPerkFormData.visibility.usingInternalName,
  isRestrictedByDateRange: createEditPerkFormData.availabilityRestrictions.isRestrictedByDateRange,
  isRestrictedByWeekOfDayAndTimeRange: createEditPerkFormData.availabilityRestrictions.isRestrictedByWeekOfDayAndTimeRange,
  perkAvailabilityTimes: timeRangesToPerkAvailabilityTimes({
    perkAvailabilityType: createEditPerkFormData.availabilityRestrictions.perkAvailabilityType,
    timeRanges: createEditPerkFormData.availabilityRestrictions.timeRanges,
    dowOffered: createEditPerkFormData.availabilityRestrictions.dowOffered,
  }),
  perkAvailabilityType: createEditPerkFormData.availabilityRestrictions.perkAvailabilityType,
  perkType: PerkAccessTypeEnum.DEFAULT,
  associatedAccessRulesData: associatedAccessRulesData || {},
  associatedClientTags: createEditPerkFormData.associatedTags.clientTags ?? [],
  associatedReservationTags: createEditPerkFormData.associatedTags.reservationTags ?? [],
})

export const timeRangesToPerkAvailabilityTimes = ({
  timeRanges,
  dowOffered,
  perkAvailabilityType,
}: {
  timeRanges?: TimeRangePickerForm[]
  dowOffered: number[]
  perkAvailabilityType: PerkAvailabilityType
}): PerkAvailabilityTimes => {
  if (perkAvailabilityType === 'ANY_TIME') {
    return {
      '1': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '2': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '3': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '4': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '5': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '6': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
      '7': {
        startTime: START_TIME,
        endTime: END_TIME,
      },
    }
  }

  const perkAvailabilityTimes: PerkAvailabilityTimes = {}

  timeRanges?.forEach((timeRange, index) => {
    if (dowOffered.includes(index)) {
      // PerkAvailabilityTimes days 1 - 7
      perkAvailabilityTimes[index + 1] = {
        startTime: timeRange?.startTime || START_TIME,
        endTime: timeRange?.endTime || END_TIME,
      }
    }
  })

  return perkAvailabilityTimes
}

export const getTagsGroupsToRemovePerkFrom = (perk: Perk, createEditPerkFormData: CreateEditPerkFormData): string[] => {
  const tagGroupsToRemovePerkFrom = new Set<string>()
  const initialSelection = [...perk.associatedClientTags, ...perk.associatedReservationTags]
  const newSelection = [...createEditPerkFormData.associatedTags.clientTags, ...createEditPerkFormData.associatedTags.reservationTags]
  const updatedTagIds = new Set(newSelection.map(tagOption => tagOption.id))
  initialSelection.forEach(tagOption => {
    if (!updatedTagIds.has(tagOption.id) && !tagGroupsToRemovePerkFrom.has(tagOption.categoryId)) {
      tagGroupsToRemovePerkFrom.add(tagOption.categoryId)
    }
  })
  return Array.from(tagGroupsToRemovePerkFrom)
}
