import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Box, DividerLine, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header } from '@sevenrooms/core/ui-kit/typography'
import { referralsMessages } from '../../locales'
import { ReferralLevelForm } from '../ReferralLevelForm'
import { ReferrersEmailConfigurationsForm } from '../ReferrersEmailConfigurationsForm'
import type { ReferrersFormFields } from './ReferrersForm.zod'

export interface ReferrersFormProps {
  disabled: boolean
  fields: ReferrersFormFields
  globalPerks: SelectOption[]
  localPerks: SelectOption[]
}

export function ReferrersForm({ fields, disabled, ...props }: ReferrersFormProps) {
  const { formatMessage } = useLocales()
  const referrerAutotagTiers = fields.prop('referrerAutotagTiers')
  const referralLinkSubject = useWatch(fields.prop('referralLinkSubject'))
  const referralLinkBody = useWatch(fields.prop('referralLinkBody'))
  const referralLink = useWatch(fields.prop('referralLink'))

  return (
    <VStack spacing="lm">
      {referrerAutotagTiers.map((field, data) => (
        <VStack key={data.key} spacing="m">
          <Box>
            <Header type="h4">
              {formatMessage(referralsMessages.referralLevel)}&nbsp;{data.key + 1}
            </Header>
          </Box>
          <ReferralLevelForm fields={field} level={data.key} disabled={disabled} {...props} />
          <DividerLine margin="none" />
        </VStack>
      ))}

      <Header type="h3">{formatMessage(referralsMessages.communicationConfigurations)}</Header>
      <ReferrersEmailConfigurationsForm
        fields={fields.prop('referrerEmailConfigurations')}
        referralLinkSubject={referralLinkSubject ?? ''}
        referralLinkBody={referralLinkBody ?? ''}
        referralLink={referralLink ?? ''}
      />
    </VStack>
  )
}
