import { useLocales } from '@sevenrooms/core/locales'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Button } from '@sevenrooms/react-components/components/Button'
import { accessRulesSettingsMessages } from '../../locales'

export function ViewReservationWidgetButton() {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()
  const permissions = useNavigationPermissionsContext()

  const reservationWidgetLink = `${window.location.origin}/reservations/${venueUrlKey}`

  return permissions?.canViewReportingFull ? (
    <Button
      variant="outlined"
      href={reservationWidgetLink}
      target="_blank"
      id="btn-view-reservation-widget"
      data-test="btn-view-reservation-widget"
    >
      {formatMessage(accessRulesSettingsMessages.viewReservationWidget)}
    </Button>
  ) : null
}
