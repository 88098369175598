import _ from 'lodash'
import { useMemo } from 'react'
import type { OneTimeEmailCampaignType, OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import {
  MarketingCampaignOneTimeSendTypeEnum,
  OneTimeEmailCampaignStatusEnum,
  type GenericTagGroup,
  type VenueProfile,
} from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import {
  emailBuilderMessages,
  getPlainTextFromHtml,
  getImageFromHtml,
  getEmailBody,
  campaignBuilderMessages,
  getInvalidReplyToAddress,
  useEmailDetailsForm,
  getEmailDetailsDefaultValues,
  useAudienceForm,
  getAudienceDefaultValues,
} from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import {
  getOneTimeSendingScheduleDefaultValues,
  useOneTimeSendingScheduleForm,
} from '../OneTimeSendingSchedule/OneTimeSendingScheduleForm.zod'

export type OneTimeEmailCampaignFormData = ZodSchema<typeof useOneTimeEmailCampaignForm>
export type OneTimeEmailCampaignFormField = Field<OneTimeEmailCampaignFormData>

const emailContentFieldSchema = z.object({ value: z.string().optional() })

export function useOneTimeEmailCampaignForm() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const sendingSchedule = useOneTimeSendingScheduleForm()
  const emailDetails = useEmailDetailsForm()
  const audience = useAudienceForm()

  return useMemo(
    () =>
      z
        .object({
          campaignState: z.nativeEnum(OneTimeEmailCampaignStatusEnum),
          emailSender: emailContentFieldSchema,
          subjectLine: emailContentFieldSchema,
          emailPreview: emailContentFieldSchema,
          emailBodySections: z.array(emailContentFieldSchema).min(1),
          topolTemplate: z.any().optional(),
        })
        .merge(emailDetails)
        .merge(audience)
        .merge(sendingSchedule)
        .superRefine((formData, ctx) => {
          if (formData.campaignState === OneTimeEmailCampaignStatusEnum.ACTIVE) {
            if (!formData.scheduleType) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'undefined',
                path: ['scheduleType'],
              })
            }
            if (!formData.replyToAddress) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'undefined',
                path: ['replyToAddress'],
              })
            }
            if (!formData.recipientAutotags || formData.recipientAutotags.length < 1) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                type: 'array',
                minimum: 1,
                inclusive: true,
                path: ['recipientAutotags'],
              })
            }
            if (formData.recipientAutotags?.some(tag => tag.disabled)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: ` `,
                path: ['recipientAutotags'],
              })
            }
            if (formData.excludedAutotags?.some(tag => tag.disabled)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: ` `,
                path: ['excludedAutotags'],
              })
            }
            if (!formData.emailSender.value) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'undefined',
                path: ['emailSender.value'],
              })
            }
            if (!formData.subjectLine.value) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'undefined',
                path: ['subjectLine.value'],
              })
            }
            if (
              !formData.emailBodySections[0]?.value ||
              !(getPlainTextFromHtml(formData.emailBodySections[0]?.value) || getImageFromHtml(formData.emailBodySections[0]?.value).length)
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'undefined',
                path: ['emailBodySections.0.value'],
              })
            }

            if (formData.scheduleType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED) {
              if (!formData.scheduledDay) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_date,
                  path: ['scheduledDay'],
                })
              }
              if (!formData.scheduledTime) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['scheduledTime'],
                })
              }
              if (formData.scheduledTime && formData.scheduledDay) {
                const scheduledDate = new Date(
                  _.isDate(formData.scheduledDay) ? formData.scheduledDay : `${formData.scheduledDay}T00:00:00`
                )
                if (formData.scheduledTime) {
                  const time = TimeOnly.from(formData.scheduledTime).getInfo()

                  scheduledDate.setHours(time.hours)
                  scheduledDate.setMinutes(time.minutes)
                  scheduledDate.setSeconds(time.seconds)
                }
                const today = new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: venue.timezone,
                  })
                )
                if (today.getTime() > scheduledDate.getTime()) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(campaignBuilderMessages.timeInThePastError),
                    path: ['scheduledTime'],
                  })
                }
              }
            }
          }
          if (formData.replyToAddress) {
            const invalidAddress = getInvalidReplyToAddress(formData.replyToAddress)
            if (typeof invalidAddress !== 'undefined') {
              ctx.addIssue({
                validation: 'email',
                code: z.ZodIssueCode.invalid_string,
                message: formatMessage(emailBuilderMessages.invalidEmailAddresses, { invalidAddress }),
                path: ['replyToAddress'],
              })
            }
          }
        }),
    [formatMessage, sendingSchedule, venue.timezone, audience, emailDetails]
  )
}

export const getOneTimeEmailBuilderFormDefaultValues = (
  source?: OneTimeEmailCampaignType | OneTimeEmailCampaignTemplate,
  clientTagGroups?: GenericTagGroup[],
  VenueProfile?: VenueProfile
): OneTimeEmailCampaignFormData => ({
  ...getEmailDetailsDefaultValues(source),
  campaignState: source?.status || OneTimeEmailCampaignStatusEnum.ACTIVE,
  emailSender: !source?.emailSender || source?.emailSender === 'N/A' ? { value: '' } : { value: _.escape(source?.emailSender) },
  subjectLine: !source?.emailSubject || source?.emailSubject === 'N/A' ? { value: '' } : { value: _.escape(source?.emailSubject) },
  emailPreview: { value: source?.previewText ?? '' },
  emailBodySections: [{ value: getEmailBody({ emailCampaign: source as OneTimeEmailCampaignType }) }],
  ...getOneTimeSendingScheduleDefaultValues(source),
  ...getAudienceDefaultValues(source, clientTagGroups),
  expressConsentOverride: VenueProfile?.marketingDoubleOptInEnabled ? true : source?.expressConsentOverride ?? false,
  topolTemplate: source?.topolTemplate ?? null,
})
