import { useMemo } from 'react'
import { useGetVenueFeeQuery } from '@sevenrooms/core/api'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormMultiSelect, FormNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack, HStack, Loader, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { spacesMessages } from '../../spaces.locales'
import type { SpaceFormType } from './Space.zod'

interface FeesProps {
  field: Field<SpaceFormType>
  isFeesIntegrationEnabled: boolean
}

export function Fees({ field, isFeesIntegrationEnabled }: FeesProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data: feesData, isFetching: isFetchingFees } = useGetVenueFeeQuery({ venueId })

  const feesOptions = useMemo(
    () =>
      feesData?.fees
        .filter(fee => fee.id != null)
        .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
        .map(fee => ({ id: fee.id, label: fee.name })) ?? [],
    [feesData?.fees]
  ) as { id: string; label: string }[]

  return isFetchingFees ? (
    <Loader />
  ) : (
    <BaseSection title={formatMessage(spacesMessages.fees)} description={formatMessage(spacesMessages.feesDescription)}>
      <VStack spacing="lm" p="lm">
        <VStack spacing="m">
          <Label
            primary={<Text fontWeight="bold">{formatMessage(spacesMessages.minimumSpendRangeTitle)}</Text>}
            secondary={formatMessage(spacesMessages.minimumSpendRangeDescription)}
          />
          <HStack width="50%" spacing="lm">
            <Label primary={formatMessage(spacesMessages.minimumSpendRangeMinimum)} width="100%">
              <FormNumberInput field={field.prop('pdrMinSpendMin')} allowNegative={false} />
            </Label>
            <Label primary={formatMessage(spacesMessages.minimumSpendRangeMaximum)} width="100%">
              <FormNumberInput field={field.prop('pdrMinSpendMax')} allowNegative={false} />
            </Label>
          </HStack>
        </VStack>
        {isFeesIntegrationEnabled ? (
          <>
            <DividerLine color="borders" ml="none" mr="none" />
            <VStack spacing="m">
              <Label
                primary={<Text fontWeight="bold">{formatMessage(spacesMessages.allOtherFees)}</Text>}
                secondary={formatMessage(spacesMessages.allOtherFeesDescription)}
                width="50%"
              >
                <FormMultiSelect field={field.prop('pdrFees')} options={feesOptions} />
              </Label>
            </VStack>
          </>
        ) : null}
      </VStack>
    </BaseSection>
  )
}
