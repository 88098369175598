// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in ORDER-819
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, BaseSection } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { promoCodeManagementMessages } from '../locales'
import { PromoCodeList } from './PromoCodeList'

export interface PromoCodeManagementProps {}

export function PromoCodeManagement() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta title={formatMessage(promoCodeManagementMessages.promoCodesTitle)} />
      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={formatMessage(promoCodeManagementMessages.promoCodesTitle)}
        description={formatMessage(promoCodeManagementMessages.promoCodesDescription)}
        actions={
          <Button
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => {
              nav.replace(routes.manager2.marketing.promoCode, { params: { venueKey: venue.urlKey, promoCodeKey: 'new' } })
            }}
            id="create-new-promo-code"
            data-test="create-new-promo-code"
          >
            {formatMessage(promoCodeManagementMessages.promoCodesCreateBtn)}
          </Button>
        }
      >
        <PromoCodeListContainer p="lm" data-test="promo-code-list-container">
          <BaseSection title={formatMessage(promoCodeManagementMessages.promoCodesSectionTitle)} maxWidth="calc(100vw - 310px)">
            <Box p="lm">
              <PromoCodeList />
            </Box>
          </BaseSection>
        </PromoCodeListContainer>
      </SettingsPageContent>
    </>
  )
}

const PromoCodeListContainer = styled(Box)`
  // for using page with old layout/navigation bar
  background-color: ${props => props.theme.color.lightGrey};
`
