import { useMemo } from 'react'
import { ContactInfoEnum, TallyEnum } from '@sevenrooms/core/domain'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'

export type ContactInfoNightlifeValues = InferZodSchema<typeof useContactInfoNightlifeValues>

const useContactInfoNightlifeValues = createZodSchema(z =>
  z.union([
    z.literal(ContactInfoEnum.phone),
    z.literal(ContactInfoEnum.email),
    z.literal(ContactInfoEnum.phoneoremail),
    z.literal(ContactInfoEnum.lastname),
    z.literal(ContactInfoEnum.arrivalTime),
    z.literal(ContactInfoEnum.seatingArea),
    z.literal(ContactInfoEnum.salutation),
  ])
)

export type GlistTallyValues = InferZodSchema<typeof useGlistTallyValues>

const useGlistTallyValues = createZodSchema(z =>
  z.union([z.literal(TallyEnum.genderCover), z.literal(TallyEnum.cover), z.literal(TallyEnum.no)])
)

export type ChargeTallyValues = InferZodSchema<typeof useChargeTallyValues>

const useChargeTallyValues = createZodSchema(z => z.union([z.literal(TallyEnum.genderCover), z.literal(TallyEnum.gender)]))

export type ReservationsNightlifeForm = InferZodSchema<typeof useReservationsNightlifeForm>

export const useReservationsNightlifeForm = createZodSchema(z => {
  const contactInfoNightlifeValues = useContactInfoNightlifeValues()
  const glistTallyValues = useGlistTallyValues()
  const chargeTallyValues = useChargeTallyValues()

  return useMemo(
    () =>
      z.object({
        contactInfo: z.array(contactInfoNightlifeValues),
        etaFrom: z.string(),
        etaTo: z.string(),
        etaInterval: z.string(),
        multiReservation: z.boolean().optional(),
        startDay: z.string(),
        defaultSeatingArea: z.string(),
        showCovers: z.boolean().optional(),
        glistTally: glistTallyValues,
        chargeTally: chargeTallyValues,
        maxWaitTime: z.string(),
        withInterval: z.string(),
        autoRemoveTiming: z.string(),
      }),
    [z, contactInfoNightlifeValues, glistTallyValues, chargeTallyValues]
  )
})
