import type { OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Grid, BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages, EmailCampaignPreview } from '@sevenrooms/marketing'
import { CampaignProgressLine } from '../CampaignProgressLine'
import { TopClicksPerformance } from './TopClicksPerformance'

export function CampaignPerformanceClicks({ emailCampaign }: { emailCampaign: OneTimeEmailCampaignType }) {
  const { formatMessage } = useLocales()

  return (
    <VStack>
      <Grid gap="lm" pb="lm" gridTemplateColumns="minmax(0px, 1fr) auto">
        <VStack spacing="lm">
          <BaseSection
            title={formatMessage(campaignCenterMessages.campaignPerformanceClicksExcellentTitle)}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceClicksExcellentDescription)}
          >
            <CampaignProgressLine performance={60} standard={40} />
          </BaseSection>
          <BaseSection
            title={formatMessage(campaignCenterMessages.campaignPerformanceTop5ClicksTitle)}
            description={formatMessage(campaignCenterMessages.campaignPerformanceTop5ClicksDescription)}
          >
            <TopClicksPerformance />
          </BaseSection>
        </VStack>
        <VStack>
          <EmailCampaignPreview simpleView template={emailCampaign.emailBody} />
        </VStack>
      </Grid>
    </VStack>
  )
}
