import { defineMessages } from '@sevenrooms/core/locales'

export const EventSelectorLocales = defineMessages({
  eventSelectDays: {
    id: 'eventSelector.eventSelect.days',
    defaultMessage: 'Days',
  },
  eventSelectDateRange: {
    id: 'eventSelector.eventSelect.dateRange',
    defaultMessage: 'Date Range',
  },
  eventSelectTime: {
    id: 'eventSelector.eventSelect.time',
    defaultMessage: 'Time(s)',
  },
  eventSelectShiftCategories: {
    id: 'eventSelector.eventSelect.shiftCategories',
    defaultMessage: 'Shift categories',
  },
  eventSelectPartySize: {
    id: 'eventSelector.eventSelect.partySize',
    defaultMessage: 'Party Size',
  },
  modalTitle: {
    id: 'eventSelector.modalTitle',
    defaultMessage: 'Select Event(s) to link to your Offer',
  },
  unlinkTitle: {
    id: 'eventSelector.unlinkTitle',
    defaultMessage: 'Are you sure you want to unlink from {name}?',
  },
  unlinkEvent: {
    id: 'eventSelector.unlinkEvent',
    defaultMessage: 'Unlink Event',
  },
  followShrift: {
    id: 'eventSelector.followShrift',
    defaultMessage: 'Follow Shift',
  },
  allEventsAreAlreadyLinked: {
    id: 'experience.allEventsAreAlreadyLinked',
    defaultMessage: 'All Events are already linked to other Offers',
  },
} as const)
