import type { ShiftsByDate } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { AccessRulesScheduleGrid } from '../../components/AccessRulesScheduleGrid/AccessRulesScheduleGrid'
import { AccessRuleRange } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'

interface AccessRulesCalendarProps {
  overviewDates: DateOnly[]
  shifts: ShiftsByDate | undefined
}
export function AccessRulesCalendar({ overviewDates, shifts }: AccessRulesCalendarProps) {
  const [, setParams] = useAccessRulesUrlParams()

  const onClickDayHeader = (date: DateOnly) => {
    setParams({ range: AccessRuleRange.DAY, date })
  }

  return <AccessRulesScheduleGrid dates={overviewDates} onClickDayHeader={onClickDayHeader} shifts={shifts} />
}
