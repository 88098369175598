export const columns = [
  { field: 'shiftCategory', headerName: 'Shift Category', groupBy: true },
  { field: 'rule', headerName: 'Rule', width: 200 },
  { field: 'schedule', headerName: 'Schedule', width: 150 },
  { field: 'time', headerName: 'Time', width: 150 },
  { field: 'pax', headerName: 'Pax', width: 100 },
  { field: 'seatingArea', headerName: 'Seating Area', width: 150 },
  { field: 'slotDescription', headerName: 'Slot Description', width: 150 },
  { field: 'paymentPolicy', headerName: 'Payment Policy', width: 200 },
  { field: 'audience', headerName: 'Audience', width: 200 },
  { field: 'duration', headerName: 'Duration', width: 150 },
  { field: 'coverLimit', headerName: 'Cover Limit', width: 150 },
  { field: 'bookStartEndTime', headerName: 'Book Start/End Time', width: 200 },
  { field: 'offers', headerName: 'Offers', width: 200 },
  { field: 'upgrades', headerName: 'Upgrades', width: 200 },
]

export const rows = [
  {
    id: 1,
    shiftCategory: 'Lunch',
    rule: 'Group Dinner Rule',
    schedule: 'Monday-Friday',
    time: '13:00 - 15:30',
    pax: '4-9',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Prepay and Deposit',
    audience: 'Reservation Widget',
    duration: '114 minutes',
    coverLimit: '55',
    bookStartEndTime: '8:00 - 9:00',
    offers: 'Priority seating for $10',
    upgrades: 'Add priority seating for $10',
  },
  {
    id: 2,
    shiftCategory: 'Breakfast',
    rule: 'Lunch Hour Special',
    schedule: 'Everyday',
    time: '17:30 - 18:00',
    pax: '3-5',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '104 minutes',
    coverLimit: '57',
    bookStartEndTime: '8:00 - 9:30',
    offers: 'Free drink with entree',
    upgrades: 'Include a dessert for $3',
  },
  {
    id: 3,
    shiftCategory: 'Breakfast',
    rule: 'Lunch Hour Special',
    schedule: 'Saturday-Sunday',
    time: '16:30 - 18:30',
    pax: '4-11',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay and Deposit',
    audience: 'Reservation Widget',
    duration: '81 minutes',
    coverLimit: '84',
    bookStartEndTime: '8:30 - 10:00',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 4,
    shiftCategory: 'Dinner',
    rule: 'Lunch Hour Special',
    schedule: 'Saturday-Sunday',
    time: '8:00 - 9:30',
    pax: '2-10',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '96 minutes',
    coverLimit: '25',
    bookStartEndTime: '9:00 - 10:30',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 5,
    shiftCategory: 'Dinner',
    rule: 'Happy Hour Discount',
    schedule: 'Monday-Friday',
    time: '14:00 - 17:00',
    pax: '2-9',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Deposit Required',
    audience: 'Reservation Widget',
    duration: '98 minutes',
    coverLimit: '70',
    bookStartEndTime: '6:00 - 9:30',
    offers: 'Free drink with entree',
    upgrades: 'Include a starter for $6',
  },
  {
    id: 6,
    shiftCategory: 'Lunch',
    rule: 'Early Bird Special',
    schedule: 'Saturday-Sunday',
    time: '12:30 - 14:30',
    pax: '3-8',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '120 minutes',
    coverLimit: '60',
    bookStartEndTime: '12:00 - 14:00',
    offers: '10% off for early birds',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 7,
    shiftCategory: 'Dinner',
    rule: 'Weekend Brunch Rule',
    schedule: 'Saturday-Sunday',
    time: '18:00 - 21:00',
    pax: '4-12',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay Required',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '80',
    bookStartEndTime: '17:30 - 20:30',
    offers: 'Priority seating for $10',
    upgrades: 'Include a dessert for $3',
  },
  {
    id: 8,
    shiftCategory: 'Breakfast',
    rule: 'Morning Special Rule',
    schedule: 'Monday-Friday',
    time: '7:00 - 10:00',
    pax: '2-4',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '50',
    bookStartEndTime: '6:30 - 9:30',
    offers: '20% off for early birds',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 9,
    shiftCategory: 'Lunch',
    rule: 'Exclusive Event',
    schedule: 'Saturday-Sunday',
    time: '12:00 - 15:00',
    pax: '2-6',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Deposit Required',
    audience: 'Reservation Widget',
    duration: '75 minutes',
    coverLimit: '40',
    bookStartEndTime: '11:30 - 14:30',
    offers: 'Free drink with entree',
    upgrades: 'Add a wine pairing for $15',
  },
  {
    id: 10,
    shiftCategory: 'Breakfast',
    rule: 'Morning Special Rule',
    schedule: 'Saturday-Sunday',
    time: '8:30 - 10:00',
    pax: '2-5',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '45',
    bookStartEndTime: '7:30 - 9:30',
    offers: '10% off for early birds',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 11,
    shiftCategory: 'Dinner',
    rule: 'Group Dinner Rule',
    schedule: 'Everyday',
    time: '18:00 - 20:30',
    pax: '4-12',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay and Deposit',
    audience: 'Reservation Widget',
    duration: '120 minutes',
    coverLimit: '70',
    bookStartEndTime: '17:30 - 20:00',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add priority seating for $10',
  },
  {
    id: 12,
    shiftCategory: 'Lunch',
    rule: 'Lunch Hour Special',
    schedule: 'Monday-Friday',
    time: '13:00 - 15:00',
    pax: '3-6',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Prepay Required',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '35',
    bookStartEndTime: '12:30 - 14:30',
    offers: 'Priority seating for $10',
    upgrades: 'Include a starter for $6',
  },
  {
    id: 13,
    shiftCategory: 'Dinner',
    rule: 'Exclusive Event',
    schedule: 'Monday-Friday',
    time: '18:30 - 21:00',
    pax: '5-10',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Deposit Required',
    audience: 'Reservation Widget',
    duration: '100 minutes',
    coverLimit: '80',
    bookStartEndTime: '18:00 - 20:30',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add a wine pairing for $15',
  },
  {
    id: 14,
    shiftCategory: 'Breakfast',
    rule: 'Morning Special Rule',
    schedule: 'Monday-Friday',
    time: '6:30 - 8:30',
    pax: '2-5',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '30',
    bookStartEndTime: '6:00 - 8:00',
    offers: '10% off for early birds',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 15,
    shiftCategory: 'Lunch',
    rule: 'Happy Hour Discount',
    schedule: 'Saturday-Sunday',
    time: '12:00 - 14:00',
    pax: '3-7',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Prepay Required',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '40',
    bookStartEndTime: '11:30 - 13:30',
    offers: 'Free drink with entree',
    upgrades: 'Add a wine pairing for $15',
  },
  {
    id: 16,
    shiftCategory: 'Dinner',
    rule: 'Weekend Brunch Rule',
    schedule: 'Saturday-Sunday',
    time: '19:00 - 21:30',
    pax: '4-12',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay and Deposit',
    audience: 'Reservation Widget',
    duration: '120 minutes',
    coverLimit: '75',
    bookStartEndTime: '18:30 - 21:00',
    offers: 'Priority seating for $10',
    upgrades: 'Include a dessert for $3',
  },
  {
    id: 17,
    shiftCategory: 'Breakfast',
    rule: 'Early Bird Special',
    schedule: 'Monday-Friday',
    time: '7:30 - 9:30',
    pax: '2-6',
    seatingArea: 'bar',
    slotDescription: 'bar',
    paymentPolicy: 'Pay on Arrival',
    audience: 'Reservation Widget',
    duration: '90 minutes',
    coverLimit: '25',
    bookStartEndTime: '7:00 - 9:00',
    offers: '10% off for early birds',
    upgrades: 'Add bottomless coffee for $5',
  },
  {
    id: 18,
    shiftCategory: 'Lunch',
    rule: 'Group Dinner Rule',
    schedule: 'Everyday',
    time: '13:00 - 15:00',
    pax: '4-10',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Deposit Required',
    audience: 'Reservation Widget',
    duration: '100 minutes',
    coverLimit: '60',
    bookStartEndTime: '12:30 - 14:30',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Include a starter for $6',
  },
  {
    id: 19,
    shiftCategory: 'Dinner',
    rule: 'Exclusive Event',
    schedule: 'Saturday-Sunday',
    time: '19:30 - 21:30',
    pax: '5-12',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay and Deposit',
    audience: 'Reservation Widget',
    duration: '120 minutes',
    coverLimit: '90',
    bookStartEndTime: '19:00 - 21:00',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add a wine pairing for $15',
  },
  {
    id: 20,
    shiftCategory: 'Dinner',
    rule: 'Weekend Dinner Rule',
    schedule: 'Saturday-Sunday',
    time: '18:30 - 20:30',
    pax: '6-15',
    seatingArea: 'main',
    slotDescription: 'main',
    paymentPolicy: 'Prepay Required',
    audience: 'Reservation Widget',
    duration: '120 minutes',
    coverLimit: '100',
    bookStartEndTime: '18:00 - 20:00',
    offers: 'Free dessert for groups over 8',
    upgrades: 'Add a wine pairing for $15',
  },
]
