import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { PreviewImage, Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { getAccessRuleDefaults } from '../../../AccessRule.zod'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext, useIsDefault } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import { NotBookableTooltip } from './NotBookableTooltip'
import { useUpgradesSummaryFromField } from './useUpgradesSummary'
import type { GuestFacingProps } from './GuestFacingProps'

export function CollapsedGuestFacing({ field, bookingChannels, paymentPolicy }: GuestFacingProps) {
  const { formatMessage } = useLocales()
  const context = useAccessRuleContext()
  const AccessRuleDefaults = getAccessRuleDefaults(context)
  const { experiences } = context

  const guestFacing = useWatch(field)
  const { isDefault } = useIsDefault(field, AccessRuleDefaults.guestFacing)

  const upgradesSummary = useUpgradesSummaryFromField(paymentPolicy.prop('bundledUpgrades'))

  // requires Booking Channels Audience section
  const hasUnsupportedAudiences = false

  const showTooltip = !guestFacing.allowUnsupported && hasUnsupportedAudiences && !isDefault
  const summary = [
    guestFacing.timeslotDescription
      ? {
          left: formatMessage(GuestFacingLocales.widgetDescriptionLabel),
          right: guestFacing.timeslotDescription,
        }
      : undefined,

    guestFacing.title
      ? {
          left: formatMessage(GuestFacingLocales.titleLabel),
          right: guestFacing.title,
        }
      : undefined,

    guestFacing.description
      ? {
          id: guestFacing.description,
          left: formatMessage(GuestFacingLocales.descriptionLabel),
          right: <HTMLContent content={guestFacing.description} data-test={GuestFacingTestId.htmlDescription} />,
        }
      : undefined,

    upgradesSummary
      ? {
          id: upgradesSummary,
          left: formatMessage(GuestFacingLocales.includesLabel),
          right: (
            <VStack spacing="s">
              {upgradesSummary.split('\n\n').map(group => (
                <VStack key={group}>
                  {group.split('\n').map(line => (
                    <Text key={line}>{line}</Text>
                  ))}
                </VStack>
              ))}
            </VStack>
          ),
        }
      : undefined,

    guestFacing.image
      ? {
          id: guestFacing.image.name,
          left: formatMessage(GuestFacingLocales.photoLabel),
          right: (
            <PreviewImage maxHeight="100px" alt={guestFacing.image.name} src={guestFacing.image.preview ?? guestFacing.image.rawUrl} />
          ),
        }
      : undefined,

    guestFacing.offer
      ? {
          left: formatMessage(GuestFacingLocales.offerLabel),
          right: experiences.filter(({ id }) => id === guestFacing.offer).map(({ name, status }) => `${name} (${status})`)[0],
        }
      : undefined,

    {
      // eslint-disable-next-line no-nested-ternary
      id: showTooltip ? 'some-audiences-are-not-bookable' : guestFacing.allowUnsupported ? 'allow-unsupported' : 'do-not-allow-unsupported',
      left: formatMessage(accessRulesMessages.additionalSettings),
      right: guestFacing.allowUnsupported ? (
        formatMessage(GuestFacingLocales.bookOnUnsupportedLabel)
      ) : (
        <Text>
          {formatMessage(GuestFacingLocales.notBookableSummary, {
            strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
          })}
          <NotBookableTooltip field={field} bookingChannels={bookingChannels} />
        </Text>
      ),
    },
  ]
  return <VStack spacing="s">{summary.map(s => s && <Pair key={s.id ?? s.right} left={`${s.left}:`} right={s.right} />)}</VStack>
}
