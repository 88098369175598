import moment from 'moment-timezone'
import React from 'react'
import { Chart } from 'react-google-charts'
import Theme from 'mgr/layout/Theme'
import SummaryBar from 'mgr/pages/single-venue/marketing/components/shared/SummaryBar'

const getColumnHeaders = dateRange => [dateRange, 'Tags added', 'Tags removed']

const getChartOptions = dateRange => ({
  colors: [Theme.color.positive, Theme.color.negative],
  theme: 'material',
  hAxis: {
    title: dateRange,
    slantedText: true,
    slantedTextAngle: 45,
  },
  chartArea: { left: 10, top: 10, width: '100%', height: 260 },
  legend: { position: 'none' },
  animation: {
    startup: true,
    easing: 'linear',
    duration: 1500,
  },
})

// TODO fix responsive issues
class AutoTagsActivityChart extends React.PureComponent {
  render() {
    const { hasData = true, isLoading = false, dateRange, lastUpdated = moment(), data, rebuildState } = this.props
    const formattedData = [getColumnHeaders(dateRange), ...data]
    const duration = moment.duration(moment().diff(moment.utc(lastUpdated)))
    const hours = Math.round(duration.asHours())
    const additionalMessage = !Number.isNaN(hours) && `Last Auto-tag applied: ${hours} hour${hours > 1 ? 's' : ''} ago`

    return (
      <SummaryBar
        hasData={hasData}
        title="Auto-tags Activity"
        isLoading={isLoading}
        additionalMessage={additionalMessage}
        rebuildState={rebuildState}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : data.length ? (
          <Chart chartType="ColumnChart" options={getChartOptions(dateRange)} data={formattedData} width="100%" height="350px" />
        ) : (
          <div>No Auto-tags data yet.</div>
        )}
      </SummaryBar>
    )
  }
}

export default AutoTagsActivityChart
