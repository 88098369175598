import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticCheckCreationCard } from '../BaseAutomaticCheckCreationCard'
import type { AutomaticCheckCreationCardFormField } from '../AutomaticCheckCreationCardForm.zod'

export interface LightspeedKAutomaticCheckCreationCardProps {
  field: AutomaticCheckCreationCardFormField
}

export function LightspeedKAutomaticCheckCreationCard({ field }: LightspeedKAutomaticCheckCreationCardProps) {
  return (
    <BaseAutomaticCheckCreationCard
      docLink={posiMetaDataMap.LIGHTSPEED_K.docLink}
      isAutomaticCheckCreationEnabledField={field.prop('isAutomaticCheckCreationEnabled')}
    />
  )
}
