import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetExperiencesQuery,
  useGetVenueProfileQuery,
  useGetClientTagGroupsQuery,
  type OneTimeSMSCampaignType,
  useGetSMSCampaignByIdQuery,
  useGetBySMSTemplateIdQuery,
  type OneTimeSMSCampaignTemplate,
} from '@sevenrooms/core/api'
import { ExperienceStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OneTimeSMSCampaignForm } from './OneTimeSMSCampaignForm'

export function OneTimeSMSCampaign() {
  const { venue, venueId } = useVenueContext()
  const venueKey = venue?.urlKey ?? ''

  const { params: editParams } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.editOneTimeSMS)
  const { query: createQuery } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS)

  const { campaignToDuplicateId, parentTemplateId } = createQuery
  const campaignId = editParams ? editParams.campaignId : campaignToDuplicateId

  const { data: autoTags, isFetching: isVenueClientTagsFetching } = useGetClientTagGroupsQuery(venueKey ? { venueKey } : skipToken)
  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data: experiencesData, isFetching: isExperiencesDataFetching } = useGetExperiencesQuery({
    venueId,
    status: ExperienceStatusEnum.ACTIVE,
  })
  const { data, isFetching: isSMSCampaignFetching } = useGetSMSCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const { data: parentTemplate, isFetching: isParentTemplateFetching } = useGetBySMSTemplateIdQuery(
    parentTemplateId && venueId ? { campaignId: parentTemplateId, venueId } : skipToken
  )

  const isFetching =
    isVenueProfileFetching || isExperiencesDataFetching || isSMSCampaignFetching || isVenueClientTagsFetching || isParentTemplateFetching
  const isDuplicate = !!campaignToDuplicateId

  return isFetching || !venueProfile || !experiencesData || !autoTags ? (
    <Loader />
  ) : (
    <OneTimeSMSCampaignForm
      smsCampaign={data?.data as OneTimeSMSCampaignType}
      venueProfile={venueProfile}
      experiencesData={experiencesData}
      isDuplicate={isDuplicate}
      isEdit={!!(campaignId && !isDuplicate)}
      autoTags={autoTags}
      parentTemplate={parentTemplate as OneTimeSMSCampaignTemplate}
    />
  )
}
