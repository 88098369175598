import { useMemo } from 'react'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'
import { useShiftTimeForm, useSendTimeForm } from '../ShiftOptions.zod'

export type InternalTeamEmailsForm = InferZodSchema<typeof useInternalTeamEmailsForm>

export const useInternalTeamEmailsForm = createZodSchema(z => {
  const shiftTime = useShiftTimeForm()
  const sendTime = useSendTimeForm()

  return useMemo(
    () =>
      z.object({
        reservationSummarySendTimeShow: z.boolean(),
        reservationSummarySendTime: z.array(shiftTime),
        reservationSummarySendTimeNightlife: z.array(sendTime),
        feedbackSummarySendTimeShow: z.boolean(),
        feedbackSummarySendTime: z.array(sendTime),
        aiFeedbackSummaryEmailEnabled: z.boolean(),
        resolutionReportSendTimeShow: z.boolean(),
        resolutionReportSendTime: z.array(sendTime),
        reminderCutoff: z.string().nullable(),
        reminderFasterFrequency: z.string().nullable(),
        reminderSlowFrequency: z.string().nullable(),
        weeklySummaryDow: z.string().nullable(),
        weeklySummaryTod: z.string().nullable(),
      }),

    [z, shiftTime, sendTime]
  )
})
