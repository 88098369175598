import { type Field, useWatch } from '@sevenrooms/core/form'
import { useAccessRuleContext } from '../shared'
import { MAX_PARTY_SIZE, MIN_PARTY_SIZE, PARTY_SIZES } from './Constants'
import type { DurationsForm } from './Durations.zod'
import type { PartySizeForm } from '../PartySize/PartySize.zod'

export interface PartySizeRangeDuration {
  start: number
  end: number
  enabled: boolean
  plusGreaterThan: boolean
  duration?: number
}

export function getDurationsForPartySizeRanges(
  minPartySize: number,
  maxPartySize: number,
  enabledMinPartySize: number,
  enabledMaxPartySize: number,
  durationMinutesByPartySize?: Record<string, number>
) {
  const durationsForPartySizeRanges: PartySizeRangeDuration[] = []

  if (!durationMinutesByPartySize) {
    return durationsForPartySizeRanges
  }

  for (let partySize = minPartySize; partySize <= maxPartySize; partySize += 1) {
    const duration = durationMinutesByPartySize[partySize] ?? (partySize === MAX_PARTY_SIZE ? durationMinutesByPartySize['-1'] : undefined)
    const previous = durationsForPartySizeRanges.at(-1)
    const enabled = partySize >= enabledMinPartySize && partySize <= enabledMaxPartySize
    const includesGreaterThan = partySize === maxPartySize && partySize >= MAX_PARTY_SIZE
    if (previous && duration === previous.duration && previous.enabled === enabled) {
      previous.end = partySize
      previous.plusGreaterThan = includesGreaterThan
    } else {
      durationsForPartySizeRanges.push({ start: partySize, end: partySize, duration, enabled, plusGreaterThan: includesGreaterThan })
    }
  }

  return durationsForPartySizeRanges
}

export function getDurationMinutesByPartySizeFromField(durations: DurationsForm) {
  const durationMinutesByPartySize: Record<string, number> | null = {}

  if (!durations.useShiftDurations) {
    for (const partySize of PARTY_SIZES) {
      if (durations.durationsConfigType === 'BY_PARTY_SIZE') {
        const duration = durations.durationsByPartySize[partySize]
        if (duration) {
          durationMinutesByPartySize[partySize] = duration
        }
      } else if (durations.durationAllPartySizes) {
        durationMinutesByPartySize[partySize] = durations.durationAllPartySizes
      }
    }
  }

  return durationMinutesByPartySize
}

/**
 * Return the minimum and maximum party sizes that can be booked on an access rule based on that rule's min/max party size configuration,
 * if set, or the min/max party size of any shifts the rule is associated with.
 *
 * partySizeIsRestricted is true if not all party sizes that can be configured for duration by party size can be booked
 */
export function useBookablePartySizeRange(partySizeField: Field<PartySizeForm>) {
  const { min, max } = useWatch(partySizeField)
  const { shifts } = useAccessRuleContext()

  const minPartySize = min ?? Math.min(...shifts.map(x => x.minPartySize))
  const maxPartySize = max ?? Math.max(...shifts.map(x => x.maxPartySize))
  const partySizeIsRestricted =
    Boolean(minPartySize && minPartySize > MIN_PARTY_SIZE) || Boolean(maxPartySize && maxPartySize < MAX_PARTY_SIZE)

  return { minPartySize, maxPartySize, partySizeIsRestricted }
}
