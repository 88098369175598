import type { CampaignActivityItem, OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { EmailCampaignPreview } from '@sevenrooms/marketing'
import { CampaignAnalysis } from './CampaignAnalysis'
import { CampaignDetails } from './CampaignDetails'

export function CampaignPerformanceOverview({
  emailCampaign,
  activity,
}: {
  emailCampaign: OneTimeEmailCampaignType
  activity: CampaignActivityItem
}) {
  return (
    <HStack spacing="lm" pb="lm">
      <VStack spacing="lm" flex={1}>
        <CampaignAnalysis activity={activity} sendTime={emailCampaign.sentDateTimeLocal} />{' '}
        <CampaignDetails emailCampaign={emailCampaign} />
      </VStack>
      <VStack>
        <EmailCampaignPreview simpleView template={emailCampaign.emailBody} />
      </VStack>
    </HStack>
  )
}
