import { useMemo } from 'react'
import {
  AccessRuleTagRestrictionEnum,
  type AccessRule,
  type Policy,
  type Shift,
  type SeatingAreaToTables,
  type ShiftCategory,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'
import { BookingChannelsLocales } from './components/BookingChannels/BookingChannels.locales'
import { getDefaultClientTags, getInitialBookingChannels, useBookingChannelsForm } from './components/BookingChannels/BookingChannels.zod'
import { BookingWindowLocales } from './components/BookingWindow/BookingWindow.locales'
import { getInitialBookingWindow, bookingWindow } from './components/BookingWindow/BookingWindow.zod'
import { PARTY_SIZES } from './components/Durations/Constants'
import { DurationsLocales } from './components/Durations/Durations.locales'
import { getInitialDurations, transformDurations, useDurationsForm } from './components/Durations/Durations.zod'
import { getInitialGuestDurationPicker, useGuestDurationPickerForm } from './components/GuestDurationPicker/GuestDurationPicker.zod'
import { getInitialGuestFacing, useGuestFacingForm } from './components/GuestFacing/GuestFacing.zod'
import { getInitialPacing, usePacingForm } from './components/Pacing/Pacing.zod'
import { getInitialPartySize, usePartySizeForm } from './components/PartySize/PartySize.zod'
import { getInitialPaymentPolicy, getPaymentPolicyDefaults, usePaymentPolicyForm } from './components/PaymentPolicy/PaymentPolicy.zod'
import { getInitialReservationCoverLimit, useReservationCoverLimitForm } from './components/ReservationCoverLimit/ReservationCoverLimit.zod'
import { getInitialReservationTags, parseTagHash } from './components/ReservationTags/ReservationTags.zod'
import { getInitialSchedule, useScheduleForm } from './components/Schedule/Schedule.zod'
import { getInitialSeatingAreas, useSeatingAreasForm } from './components/SeatingAreas/SeatingAreas.zod'
import { checkTimeBeforeError, checkTimeBeforeUpperBound, getAccessTimeUpperBound } from './components/shared/utils'
import { getInitialUpgrades, useUpgradesForm } from './components/Upgrades/Upgrades.zod'
import { getOverlappingShifts } from './utils'
import type { AudienceHierarchy, Upsells, TagGroup, AccessRuleDefaultParams } from './AccessRule.types'
// eslint-disable-next-line no-restricted-imports
import type { FieldPath } from 'react-hook-form'

export type AccessRuleForm = ZodSchema<typeof useAccessRuleForm>
export type AccessRulePath = FieldPath<AccessRuleForm>
export type AccessRuleSection = keyof AccessRuleForm

export function useAccessRuleForm() {
  const { formatMessage } = useLocales()

  const bookingChannels = useBookingChannelsForm()
  const guestFacing = useGuestFacingForm()
  const pacing = usePacingForm()
  const partySize = usePartySizeForm()
  const paymentPolicy = usePaymentPolicyForm()
  const reservationCoverLimit = useReservationCoverLimitForm()
  const schedule = useScheduleForm()
  const seatingAreas = useSeatingAreasForm()
  const upgrades = useUpgradesForm()
  const guestDurationPicker = useGuestDurationPickerForm()
  const durations = useDurationsForm()
  const reservationTags = useMultiSelectTagOptionForm()

  return useMemo(
    () =>
      z
        .object({
          name: z.string().min(1, formatMessage(commonMessages.required)).default(''),
          allowChannelsWithoutCCHolds: z.boolean(),
          isOverride: z.boolean(),
          bookingChannels,
          bookingWindow,
          guestDurationPicker,
          durations,
          guestFacing,
          pacing,
          partySize,
          paymentPolicy,
          reservationCoverLimit,
          reservationTags,
          schedule,
          seatingAreas,
          upgrades,
        })
        .superRefine(({ bookingWindow, bookingChannels, durations, partySize }, ctx) => {
          if (bookingChannels.length === 1) {
            if (!bookingWindow.startTime.count) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingWindowLocales.startTimeRequired),
                path: ['bookingWindow', 'startTime', 'count'],
              })
            }
            if (checkTimeBeforeUpperBound(bookingWindow.startTime)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingWindowLocales.accessTimeExceedsBoundaryError, {
                  unit: bookingWindow.startTime.unit,
                  count: getAccessTimeUpperBound(bookingWindow.startTime.unit),
                }),
                path: ['bookingWindow', 'startTime', 'count'],
              })
            }
            if (checkTimeBeforeError(bookingWindow.startTime, bookingWindow.cutoffTime)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingWindowLocales.cutoffTimeBasicError),
                path: ['bookingWindow', 'cutoffTime', 'count'],
              })
            }
          }
          if (checkTimeBeforeUpperBound(bookingWindow.cutoffTime)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(BookingWindowLocales.accessTimeExceedsBoundaryError, {
                unit: bookingWindow.cutoffTime.unit,
                count: getAccessTimeUpperBound(bookingWindow.cutoffTime.unit),
              }),
              path: ['bookingWindow', 'cutoffTime', 'count'],
            })
          }

          if (bookingChannels.length > 1) {
            if (bookingChannels.some(bookingChannel => checkTimeBeforeError(bookingChannel.startTime, bookingWindow.cutoffTime))) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingWindowLocales.cutoffTimeChannelsError),
                path: ['bookingWindow', 'cutoffTime', 'count'],
              })
              bookingChannels.forEach((bookingChannel, idx) => {
                if (checkTimeBeforeError(bookingChannel.startTime, bookingWindow.cutoffTime)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(BookingChannelsLocales.startTimeCutoffError),
                    path: ['bookingChannels', idx, 'startTime', 'count'],
                  })
                }
              })
            }
            if (bookingChannels.some(bookingChannel => checkTimeBeforeUpperBound(bookingChannel.startTime))) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(BookingWindowLocales.accessTimeExceedsBoundaryError, {
                  unit: bookingWindow.startTime.unit,
                  count: getAccessTimeUpperBound(bookingWindow.startTime.unit),
                }),
                path: ['bookingWindow', 'startTime', 'count'],
              })

              bookingChannels.forEach((bookingChannel, idx) => {
                if (checkTimeBeforeUpperBound(bookingChannel.startTime)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(BookingWindowLocales.accessTimeExceedsBoundaryError, {
                      unit: bookingChannel.startTime.unit,
                      count: getAccessTimeUpperBound(bookingChannel.startTime.unit),
                    }),
                    path: ['bookingChannels', idx, 'startTime', 'count'],
                  })
                }
              })
            }
          }

          // Durations by Party Size
          if (durations.durationsConfigType === 'BY_PARTY_SIZE') {
            for (const ps of PARTY_SIZES) {
              if (
                (!partySize.min || partySize.min <= parseInt(ps)) &&
                (!partySize.max || partySize.max >= parseInt(ps)) &&
                !durations.durationsByPartySize[ps]
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: formatMessage(DurationsLocales.errorDurationRequired),
                  path: ['durations', 'durationsByPartySize', ps],
                })
              }
            }
          }
        }),
    [
      formatMessage,
      bookingChannels,
      guestDurationPicker,
      durations,
      guestFacing,
      pacing,
      partySize,
      paymentPolicy,
      reservationCoverLimit,
      reservationTags,
      schedule,
      seatingAreas,
      upgrades,
    ]
  )
}

export function getInitialState(arState: {
  accessRule?: AccessRule
  allShifts: Shift[]
  audienceHierarchy: AudienceHierarchy[]
  currencyCode: string
  editPhoto: string
  name: string
  startDate: Date
  startTimeDisplay?: string
  endTimeDisplay?: string
  shiftCategories: ShiftCategory[]
  policies: Policy[]
  seatingAreaToTables: SeatingAreaToTables[]
  startOfDayTime: string
  tagGroups: Map<string, TagGroup>
  clientTagGroups: Map<string, TagGroup>
  upsells: Upsells
  accessRuleDefaults: AccessRuleForm
  selectedDate?: Date
}): AccessRuleForm {
  const {
    accessRule,
    accessRuleDefaults,
    allShifts,
    audienceHierarchy,
    currencyCode,
    editPhoto,
    name,
    startDate,
    startTimeDisplay,
    endTimeDisplay,
    shiftCategories,
    policies,
    seatingAreaToTables,
    startOfDayTime,
    tagGroups,
    clientTagGroups,
    upsells,
    selectedDate,
  } = arState
  const schedule = getInitialSchedule({
    accessRule,
    startDate,
    startTimeDisplay,
    endTimeDisplay,
    shiftCategories,
    startOfDayTime,
  })
  const shifts = getOverlappingShifts(allShifts, schedule, selectedDate)

  return {
    name,
    allowChannelsWithoutCCHolds: accessRule?.ignoreCcFor3PBookers ?? false,
    isOverride: accessRule?.isOverride ?? false,
    bookingChannels: getInitialBookingChannels({
      accessRule,
      audienceHierarchy,
      defaultBookingChannels: accessRuleDefaults.bookingChannels,
      startOfDayTime,
      clientTagGroups,
    }),
    bookingWindow: getInitialBookingWindow({ accessRule, defaultBookingWindow: accessRuleDefaults.bookingWindow }),
    guestFacing: getInitialGuestFacing({ accessRule, editPhoto, accessRuleDefaults }),
    pacing: getInitialPacing(accessRule),
    partySize: getInitialPartySize(accessRule),
    paymentPolicy: getInitialPaymentPolicy({
      accessRule,
      currencyCode,
      policies,
      upsells,
      paymentPolicyDefaults: accessRuleDefaults.paymentPolicy,
    }),
    reservationCoverLimit: getInitialReservationCoverLimit(accessRule),
    reservationTags: getInitialReservationTags({ accessRule, tagGroups }),
    schedule,
    seatingAreas: getInitialSeatingAreas({ accessRule, shifts, seatingAreaToTables }),
    upgrades: getInitialUpgrades({ accessRule, upsells }),
    guestDurationPicker: getInitialGuestDurationPicker(accessRule),
    durations: getInitialDurations(accessRule),
  }
}

export function getInitialAccessRule({
  defaultBookingPolicyId,
  defaultCancelPolicyId,
  accessRule,
  audienceHierarchy,
  upsells,
  clientTagGroups,
  policies,
}: AccessRuleDefaultParams): AccessRuleForm {
  const accessRuleDefaults = getAccessRuleDefaults({
    defaultBookingPolicyId: accessRule?.bookingPolicyId ?? defaultBookingPolicyId,
    defaultCancelPolicyId,
    clientTagGroups,
    policies,
  })

  return {
    ...accessRuleDefaults,
    bookingChannels: getInitialBookingChannels({
      accessRule,
      audienceHierarchy,
      defaultBookingChannels: accessRuleDefaults.bookingChannels,
      startOfDayTime: '06:00:00',
      clientTagGroups,
    }),
    bookingWindow: getInitialBookingWindow({ accessRule, defaultBookingWindow: accessRuleDefaults.bookingWindow }),
    paymentPolicy: {
      ...accessRuleDefaults.paymentPolicy,
      useShiftPaymentAndPolicy: accessRule?.useShiftPaymentAndPolicy ?? accessRuleDefaults.paymentPolicy.useShiftPaymentAndPolicy,
    },
    upgrades: upsells ? getInitialUpgrades({ upsells, accessRule }) : accessRuleDefaults.upgrades,
    reservationTags: parseTagHash(accessRule?.reservationTags ?? []),
  }
}

export function getAccessRuleDefaults({
  defaultBookingPolicyId,
  defaultCancelPolicyId,
  policies,
  clientTagGroups,
  defaultValues,
}: AccessRuleDefaultParams): AccessRuleForm {
  const paymentPolicyDefaults = getPaymentPolicyDefaults({
    defaultBookingPolicyId,
    defaultCancelPolicyId,
    policies,
    clientTagGroups,
  })
  return {
    name: '',
    allowChannelsWithoutCCHolds: false,
    isOverride: false,
    bookingChannels: [
      {
        audienceTierId: null,
        tagRestriction: AccessRuleTagRestrictionEnum.NONE,
        viewClientTags: { tags: getDefaultClientTags(clientTagGroups), hasDeletedTags: false },
        bookClientTags: { tags: getDefaultClientTags(clientTagGroups), hasDeletedTags: false },
        selected: [
          {
            id: 'DIRECT_CHANNELS',
            value: 'DIRECT_CHANNELS',
            label: 'Direct Booking Channels',
            checked: true,
          },
        ],
        startTime: {
          count: 90,
          unit: 'DAYS',
          beforeTime: '0',
        },
      },
    ],
    bookingWindow: {
      startTime: {
        count: 90,
        unit: 'DAYS',
        beforeTime: '0',
      },
      cutoffTime: {
        count: 0,
        unit: 'MINUTES',
        beforeTime: '0',
      },
    },
    guestDurationPicker: {
      guestMustSpecifyDuration: false,
      durationMin: null,
      durationMax: null,
    },
    durations: {
      useShiftDurations: true,
      durationsConfigType: 'ALL_PARTY_SIZES',
      durationAllPartySizes: null,
      durationsByPartySize: transformDurations({}),
    },
    guestFacing: {
      timeslotDescription: '',
      title: '',
      description: '',
      image: null,
      offer: defaultValues?.guestFacing?.offer ?? null,
      allowUnsupported: false,
      minSpend: null,
    },
    pacing: {
      maxCoversPerSeatingInterval: null,
      customPacing: {},
      isPacingHeld: false,
      setCustomPacing: false,
      excludeFromShiftPacing: false,
    },
    partySize: {
      min: null,
      max: null,
    },
    paymentPolicy: paymentPolicyDefaults,
    reservationCoverLimit: {
      count: null,
      type: 'UNLIMITED',
      guaranteeBookings: false,
    },
    schedule: {
      accessTimeType: 'ALL',
      restrictToShifts: false,
      // has to come from selectd shift
      shiftCategories: ['DINNER'],
      startTime: '11:00:00',
      endTime: '15:00:00',
      specificTimes: [],
      dateRange: {
        // has to come from selection
        startDate: new Date(),
        endDate: null,
        isInfinite: true,
      },
      selectedDays: [...Array(7).keys()],
    },
    seatingAreas: {
      selection: [],
      treatAsBlocked: false,
      googleReserveSeatingArea: 'NOT_USING',
      theForkSeatingArea: 'INSIDE',
    },
    upgrades: {
      edited: false,
      selection: [],
      includeUpgrades: 'included',
    },
    reservationTags: [],
  }
}
