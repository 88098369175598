import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { Box } from '@sevenrooms/react-components/components/Box'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticCheckCreationCard } from '../BaseAutomaticCheckCreationCard'
import { messages } from './SquareAutomaticCheckCreationCard.locales'
import type { AutomaticCheckCreationCardFormField } from '../AutomaticCheckCreationCardForm.zod'

export interface SquareAutomaticCheckCreationCardProps {
  field: AutomaticCheckCreationCardFormField
  isLocation: boolean
}

export function SquareAutomaticCheckCreationCard({ field, isLocation }: SquareAutomaticCheckCreationCardProps) {
  const { formatMessage } = useLocales()
  return (
    <BaseAutomaticCheckCreationCard
      docLink={posiMetaDataMap.SQUARE.docLink}
      isAutomaticCheckCreationEnabledField={field.prop('isAutomaticCheckCreationEnabled')}
      disabled={!isLocation}
    >
      {!isLocation && (
        <Box width="100%">
          <Banner
            type="info"
            title={formatMessage(messages.locationRequiredBannerTitle)}
            description={formatMessage(messages.locationRequiredBannerDescription)}
          />
        </Box>
      )}
    </BaseAutomaticCheckCreationCard>
  )
}
