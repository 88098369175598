import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Blink } from '@sevenrooms/core/ui-kit/animation'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../AccessRules.locales'
import { SharedTestId } from './shared.testIds'

export interface StatusTagProps {
  isActive: boolean
  isEdited?: boolean
}

export function StatusTag({ isActive, isEdited }: StatusTagProps) {
  const { formatMessage } = useLocales()
  return (
    <Box mt="auto" mb="auto">
      <HStack spacing="s">
        <Blink isActive={isActive}>
          <Status data-test={SharedTestId.statusRestoredDefaults} icon="VMSWeb-check-circle-outline" kind="success">
            {formatMessage(accessRulesMessages.defaultSettingsRestored)}
          </Status>
        </Blink>
        {isEdited && !isActive && (
          <StatusLabel data-test={SharedTestId.statusEdited} variant="status">
            {formatMessage(commonMessages.edited)}
          </StatusLabel>
        )}
      </HStack>
    </Box>
  )
}
