import { useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Pair } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { UpgradesLocales } from '../Upgrades.locales'
import { SelectedCategoriesView } from './SelectedCategoriesView'
import type { UpgradesFieldsProps } from './UpgradesProps'

export function CollapsedUpgrades({ field, options }: UpgradesFieldsProps) {
  const { formatMessage } = useLocales()
  const { selection, includeUpgrades } = useWatch(field)

  return (
    <Text fontSize="m">
      <Pair
        left={formatMessage(UpgradesLocales.collapsedContentLabel)}
        right={
          includeUpgrades === 'excluded' ? (
            <Text>{formatMessage(commonMessages.none)}</Text>
          ) : (
            <SelectedCategoriesView selection={selection} options={options} />
          )
        }
      />
    </Text>
  )
}
