import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Radio } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import type { PerkCreateAccessRuleModalFormField } from './PerkCreateAccessRuleModalForm.zod'

interface PerkCreateAccessRuleModalAccessRulesProps {
  field: PerkCreateAccessRuleModalFormField
  accessRulesOptions: SelectOption[]
}

export function PerkCreateAccessRuleModalAccessRules({ field, accessRulesOptions }: PerkCreateAccessRuleModalAccessRulesProps) {
  const { formatMessage } = useLocales()
  const cloneExistingAccessRule = useWatch(field.prop('cloneExistingAccessRule'))

  return (
    <>
      <VStack spacing="s" mt="s" height="320px">
        <Radio
          disabled={accessRulesOptions.length === 0}
          field={field.prop('cloneExistingAccessRule')}
          value={1}
          checked={!!cloneExistingAccessRule}
        >
          {formatMessage(perksMessages.accessRuleModalCloneAccessRule)}{' '}
          <StatusLabel variant="success">{formatMessage(perksMessages.accessRuleModalCloneAccessRuleRecommended)}</StatusLabel>
        </Radio>
        {!!cloneExistingAccessRule && (
          <VStack pl="lm" mt="s">
            <FormSelect
              options={accessRulesOptions}
              field={field.prop('accessRuleToCloneId')}
              placeholder={formatMessage(perksMessages.accessRuleModalSelectAccessRule)}
              searchable={false}
            />
          </VStack>
        )}
        <VStack mt="m">
          <Radio field={field.prop('cloneExistingAccessRule')} value={0} checked={!cloneExistingAccessRule}>
            {formatMessage(perksMessages.accessRuleModalCreateAccessRule)}
          </Radio>
        </VStack>
      </VStack>
    </>
  )
}
