import { useCallback } from 'react'
import { useSaveConfigMutation } from '@sevenrooms/core/api'
import type { POSIConfig, POSIType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { messages } from './useSavePOSIConfig.locales'

export const useSavePOSIConfig = () => {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [savePOSIConfigMutation, { isLoading: isSaving }] = useSaveConfigMutation()

  const saveConfig = useCallback(
    async ({ posiType, config, refetchConfig }: { posiType: POSIType; config: POSIConfig; refetchConfig: () => void }) => {
      try {
        await savePOSIConfigMutation({ venueId, config, posiType }).unwrap()
        refetchConfig()
        notify({
          content: formatMessage(messages.saveSuccess, { posiType }),
          type: 'success',
        })
      } catch (error) {
        notify({
          content: formatMessage(messages.saveError, { posiType }),
          type: 'error',
        })
      }
    },
    [formatMessage, savePOSIConfigMutation, venueId]
  )

  return { saveConfig, isSaving }
}
