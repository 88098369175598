import { useState } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleTooltip } from '../../shared'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import { ConflictModal } from './ConflictModal'
import type { PacingForm } from '../Pacing.zod'

export interface PacingWarningProps {
  customPacing: PacingForm['customPacing']
  maxCoversPerSeatingInterval: number | null
}

export function PacingWarning({ customPacing, maxCoversPerSeatingInterval }: PacingWarningProps) {
  const { formatMessage } = useLocales()
  const [conflictIsActive, setConflictIsActive] = useState(false)

  return (
    <>
      <AccessRuleTooltip variant="warning" size="lg" data-test={PacingTestId.reservedButton}>
        <VStack spacing="s" data-test={PacingTestId.reservedTooltip}>
          <Text color="lightFont" fontWeight="bold">
            {formatMessage(commonMessages.warning)}
          </Text>
          <Text color="lightFont">{formatMessage(PacingLocales.allPacingReservedWarning)}</Text>
          <Button data-test="see-details-button" onClick={() => setConflictIsActive(true)}>
            {formatMessage(commonMessages.seeDetails)}
          </Button>
        </VStack>
      </AccessRuleTooltip>
      <Window active={conflictIsActive}>
        <ConflictModal
          onClose={() => setConflictIsActive(false)}
          customPacing={customPacing}
          maxCoversPerSeatingInterval={maxCoversPerSeatingInterval}
          data-test={PacingTestId.conflictModal}
        />
      </Window>
    </>
  )
}
