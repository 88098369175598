import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  authenticationLabel: {
    id: 'posiSettings.AuthenticationStatusCard.isAuthenticatedMessage',
    defaultMessage: 'AUTHENTICATED',
  },
  docLinkLabel: {
    id: 'posiSettings.AuthenticationStatusCard.docLinkLabel',
    defaultMessage: 'View Docs',
  },
} as const)
