import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useMultiSelectTreeForm } from '@sevenrooms/core/ui-kit/form'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { UpgradesLocales } from './Upgrades.locales'
import type { Upsells } from '../../AccessRule.types'

export type UpgradesForm = ZodSchema<typeof useUpgradesForm>

export function useUpgradesForm() {
  const { paymentsEnabled } = useAppContext().venueSettings
  const { formatMessage } = useLocales()

  const selection = useMultiSelectTreeForm<{ isRequired?: boolean; isPaid?: boolean }>()
  return useMemo(
    () =>
      z
        .object({
          edited: z.boolean(),
          selection,
          includeUpgrades: z.union([z.literal('included'), z.literal('excluded')]),
        })
        .superRefine(({ edited, selection, includeUpgrades }, ctx) => {
          if (edited && includeUpgrades === 'included') {
            if (selection.length === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(UpgradesLocales.noUpgradesError),
                path: ['selection'],
              })
            }
            if (!paymentsEnabled && selection.some(({ value }) => value.isPaid)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(UpgradesLocales.missingPaymentError),
                path: ['selection'],
              })
            }
          }
        }),
    [selection, formatMessage, paymentsEnabled]
  )
}

export function getInitialUpgrades(arState: { accessRule?: AccessRule; upsells: Upsells }): UpgradesForm {
  const { accessRule, upsells } = arState

  const selection =
    accessRule?.upsellCategories.map(id => ({
      id,
      label: upsells.categories[id]?.label ?? '',
      value: {
        isRequired: upsells.categories[id]?.minQuantityType !== 'NONE_REQUIRED',
        isPaid: upsells.inventory.filter(item => item.categoryId === id).some(({ price }) => price > 0),
      },
      checked: true,
    })) ?? []

  const includeUpgrades = accessRule != null && !accessRule.isUsingShiftUpsells && selection.length === 0 ? 'excluded' : 'included'
  return {
    edited: includeUpgrades === 'excluded' || selection.length > 0,
    selection,
    includeUpgrades,
  }
}
