import { Fragment } from 'react'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface CardItem {
  title: string
  value: string | number
}

export function Card({ heading, items }: { heading: string; items: CardItem[] }) {
  return (
    <VStack>
      <Box backgroundColor="margin" textAlign="center" p="xs">
        <Text color="primaryIcons" fontWeight={400}>
          {heading.toUpperCase()}
        </Text>
      </Box>
      <HStack alignItems="center" justifyContent="space-around" p="lm">
        {items.map(({ value, title }, index: number) => (
          <Fragment key={title}>
            {index > 0 && <Box width="1px" height="30px" backgroundColor="margin" />}
            <VStack alignItems="center" spacing="s" width="120px">
              <Text fontSize="xl" fontWeight={600} color="primaryFont">
                {value}
              </Text>
              <Text fontSize="s">{title}</Text>
            </VStack>
          </Fragment>
        ))}
      </HStack>
    </VStack>
  )
}
