import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReferralClientFormData = ZodSchema<ReturnType<typeof useReferralClientForm>>
export type ReferralClientFormFields = Field<ReferralClientFormData>

export const useReferralClientForm = () =>
  useMemo(
    () =>
      z.object({
        key: z.number(),
        tagName: z.string().min(1),
        globalPerks: z.array(z.string()),
        localPerks: z.array(z.string()),
        colorHex: z.string().optional(),
      }),
    []
  )
