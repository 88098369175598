import { subscriptionWidgetSettingsApi } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { MainContent } from './MainContent'
import { subscriptionLocales } from './SubscriptionLocales'

export function SubscriptionWidgetSettings() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data: subscriptionWidgetSettings, isFetching } = subscriptionWidgetSettingsApi.useGetSubscriptionWidgetSettingsQuery({ venueId })

  return (
    <>
      <SettingsPageMeta title={formatMessage(subscriptionLocales.title)} />
      {isFetching ? <Loader /> : <MainContent subscriptionWidgetSettings={subscriptionWidgetSettings} />}
    </>
  )
}
