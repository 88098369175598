import type { AccessRuleAudienceTier } from '@sevenrooms/core/domain'
import type { ZodSchema } from '@sevenrooms/core/form'
import type { timeBeforeForm } from '@sevenrooms/mgr-access-rules-slideout/components/shared'

export type PerkEditAudienceTierModalFormData = ZodSchema<typeof timeBeforeForm>

export const getPerkEditAudienceTierModalDefaultValues = (tier: AccessRuleAudienceTier): PerkEditAudienceTierModalFormData => ({
  unit: tier.startType,
  count: tier.startNum ?? null,
  beforeTime: tier.startHour ?? '0',
})
