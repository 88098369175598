import React from 'react'
import { CustomAutotagSearchDropdownSingle } from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagSearchDropdownSingle'

function CustomAutotagBooleanDropDown(props) {
  const options = [
    { value: true, key: 'true', displayText: 'True' },
    { value: false, key: 'false', displayText: 'False' },
  ]
  return <CustomAutotagSearchDropdownSingle options={options} {...props} />
}

export default CustomAutotagBooleanDropDown
