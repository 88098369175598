import { useMemo } from 'react'
import { OrderingRoomNumbersAdapter } from '@sevenrooms/core/api'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { OrderRoomNumberLocales } from './OrderingRoomNumbers.locales'

export function useCreateRoomNumberSchema() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z.object({
        roomNumbers: z
          .string()
          .min(1)
          .refine(
            roomNumber =>
              OrderingRoomNumbersAdapter.validateRoomNumbersByCommaSeparator(roomNumber) ||
              OrderingRoomNumbersAdapter.validateRoomNumbersByNewLineSeparator(roomNumber),
            {
              message: formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersValidationError),
            }
          ),
      }),
    [formatMessage]
  )
}
