import { useState, useMemo } from 'react'
import { type Experience, OfferTypeEnum, ExperienceLinkTypeEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Radio } from '@sevenrooms/core/ui-kit/form'
import { Box, Clickable, Flex, Modal, ModalBody, ModalFooter, ModalHeader } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Paragraph } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { ExperiencesLocales } from './Experiences.locales'

interface LinkObject {
  default?: string
  sevenX?: string
}
interface ExperienceLinkModalProps {
  linkObject?: LinkObject
  activeExperience: Experience | null
  closeExperienceLinkModal: () => void
}
export function ExperienceLinkModal({ linkObject, closeExperienceLinkModal, activeExperience }: ExperienceLinkModalProps) {
  const { venue } = useVenueContext()
  const [selectedLinkType, setSelectedLinkType] = useState(ExperienceLinkTypeEnum.OPEN_ACCESS)
  const selectOpenAccess = () => setSelectedLinkType(ExperienceLinkTypeEnum.OPEN_ACCESS)
  const selectExclusiveAccess = () => setSelectedLinkType(ExperienceLinkTypeEnum.EXCLUSIVE_ACCESS)

  const currentLink = useMemo(
    () => (selectedLinkType === ExperienceLinkTypeEnum.OPEN_ACCESS ? linkObject?.default : linkObject?.sevenX),
    [linkObject?.default, linkObject?.sevenX, selectedLinkType]
  )
  const copyLink = () => navigator.clipboard.writeText(currentLink || '')
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Modal" data-test="offers-directory-modal">
      <ModalHeader onClose={() => closeExperienceLinkModal()} />
      <ModalBody>
        <Flex flexGrow="1" flexDirection="column" pt="m">
          <Header type="h1" textAlign="center">
            {activeExperience ? activeExperience.name : formatMessage(ExperiencesLocales.offersDirectory)}
          </Header>
          {activeExperience && activeExperience.offerType === OfferTypeEnum.EVENT && (
            <Header type="h2" textAlign="center">
              {formatMessage(ExperiencesLocales.wouldYouLikeToCopyLinkForThisOffer)}
            </Header>
          )}

          {(!activeExperience || activeExperience.offerType !== OfferTypeEnum.EVENT) && (
            <Box mt="xl" mb="m">
              <Header type="h2" fontSize="xl" textAlign="center">
                {formatMessage(ExperiencesLocales.whatTypeOfLinkDoYouWantToCreate)}
              </Header>
              <Flex justifyContent="space-between" mt="l" mb="l">
                <Clickable
                  borderTopLeftRadius="s"
                  borderBottomLeftRadius="s"
                  boxSizing="content-box"
                  onClick={selectOpenAccess}
                  borderColor="borders"
                  textAlign="center"
                  pt="xxl"
                  pb="xxl"
                  pl="l"
                  pr="l"
                  position="relative"
                  width="260px"
                  cursor="pointer"
                >
                  <Box position="absolute" top="sm" left="sm">
                    <Radio checked={selectedLinkType === ExperienceLinkTypeEnum.OPEN_ACCESS} />
                  </Box>
                  <Box ml="s" mr="s" mb="s">
                    <Header type="h1" fontWeight="bold" textAlign="center">
                      {formatMessage(ExperiencesLocales.openAccess)}
                    </Header>
                  </Box>
                  <Paragraph color="description">
                    {formatMessage(ExperiencesLocales.openAccessDescription, {
                      offerType: activeExperience
                        ? formatMessage(ExperiencesLocales.activeExperienceName, { name: activeExperience.name })
                        : formatMessage(ExperiencesLocales.anyActiveOffer),
                    })}
                  </Paragraph>
                </Clickable>
                <Clickable
                  borderTopRightRadius="s"
                  borderBottomRightRadius="s"
                  boxSizing="content-box"
                  onClick={selectExclusiveAccess}
                  borderColor="borders"
                  textAlign="center"
                  pt="xxl"
                  pb="xxl"
                  pl="l"
                  pr="l"
                  position="relative"
                  width="260px"
                  cursor="pointer"
                >
                  <Box position="absolute" top="sm" left="sm">
                    <Radio checked={selectedLinkType === ExperienceLinkTypeEnum.EXCLUSIVE_ACCESS} />
                  </Box>
                  <Box ml="s" mr="s" mb="s">
                    <Header type="h1" fontWeight="bold" textAlign="center">
                      {formatMessage(ExperiencesLocales.exclusiveAccess)}
                    </Header>
                  </Box>
                  <Paragraph color="description">
                    {formatMessage(ExperiencesLocales.exclusiveAccessDescription, {
                      offerType: activeExperience
                        ? formatMessage(ExperiencesLocales.activeExperienceName, { name: activeExperience.name })
                        : formatMessage(ExperiencesLocales.anyActiveOffer),
                    })}
                  </Paragraph>
                </Clickable>
              </Flex>
            </Box>
          )}

          <Flex mb="xl" justifyContent="space-between">
            <Input value={currentLink} disabled readOnly fullWidth />
            <Button data-test="experience-modal-copy-button" onClick={copyLink}>
              {formatMessage(ExperiencesLocales.copyLink)}
            </Button>
          </Flex>
          <Box mt="3xl">
            {!activeExperience || activeExperience.offerType !== OfferTypeEnum.EVENT ? (
              <Paragraph color="description" textAlign="center">
                {formatMessage(ExperiencesLocales.haveNotSetUpAccessRule, {
                  offerType: activeExperience ? formatMessage(ExperiencesLocales.thisOffer) : formatMessage(ExperiencesLocales.yourOffers),
                })}
                &nbsp;
                <Link to={`/manager/${venue.urlKey}/manage/capacity/accessrules`} target="_blank">
                  {formatMessage(commonMessages.here)}
                </Link>
              </Paragraph>
            ) : (
              <Paragraph color="description" textAlign="center">
                {formatMessage(ExperiencesLocales.haveNotSetUpEvent)}&nbsp;
                <Link to={`/manager/${venue.urlKey}/events`} target="_blank">
                  {formatMessage(commonMessages.here)}
                </Link>
              </Paragraph>
            )}
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Flex flexDirection="row-reverse" pt="s">
          <Button data-test="close-experience-modal-button" onClick={closeExperienceLinkModal}>
            {formatMessage(commonMessages.close)}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}
