import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { promoCodeManagementMessages } from '../locales'

export interface DeletePromoCodeModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: () => void
}

export function DeletePromoCodeModal({ onConfirm, closeHref }: DeletePromoCodeModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  if (!venue) {
    return null
  }

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(promoCodeManagementMessages.promoCodesDeleteModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text whiteSpace="nowrap">{formatMessage(promoCodeManagementMessages.promoCodesDeleteModalContent)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="tertiary"
            href={nav.closeSurfaceHref(routes.manager2.marketing.promoCodesManagement.deleteModal, { params: { venueKey: venue.urlKey } })}
            data-test="sr-promo-code-discard-delete"
          >
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            variant="primary-warning"
            href={nav.closeSurfaceHref(routes.manager2.marketing.promoCodesManagement.deleteModal, { params: { venueKey: venue.urlKey } })}
            onClick={() => onConfirm()}
            data-test="sr-promo-code-confirm-delete"
          >
            {formatMessage(commonMessages.delete)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
