import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetExperienceQuery } from '@sevenrooms/core/api'
import { useLocation, useParams, useScrollToTop } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { CreateEditExperienceForm } from './experiences/CreateEditExperienceForm'

export function CreateEditExperience() {
  const { experienceId } = useParams<{ experienceId?: string }>()
  const { venueId } = useVenueContext()
  const { pathname } = useLocation()
  useScrollToTop(pathname)
  const { data: experience, isFetching } = useGetExperienceQuery(experienceId ? { venueId, experienceId } : skipToken)
  return isFetching ? <Loader /> : <CreateEditExperienceForm experience={experience} />
}
