import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { CollapsedPaymentPolicy, DefaultPaymentPolicy, PaymentPolicyFields, type PaymentPolicySectionProps } from './components'
import { AllowUnsupported } from './components/AllowUnsupported'
import { PaymentPolicyLocales } from './PaymentPolicy.locales'
import { PaymentPolicyTestId } from './PaymentPolicy.testIds'

export function PaymentPolicy(props: PaymentPolicySectionProps) {
  const { field } = props
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      data-test={PaymentPolicyTestId.section}
      title={formatMessage(PaymentPolicyLocales.title)}
      description={formatMessage(PaymentPolicyLocales.description)}
      subCaption={formatMessage(PaymentPolicyLocales.subCaption)}
      contentWhenCollapsed={<CollapsedPaymentPolicy {...props} />}
      defaultSettings={<DefaultPaymentPolicy />}
      sectionControllerName="paymentPolicy"
      field={field}
      defaultSettingsFooter={<AllowUnsupported field={props.allowChannelsWithoutCCHolds} />}
    >
      <PaymentPolicyFields {...props} />
    </AccessRuleSlideoutSection>
  )
}
