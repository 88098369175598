import React from 'react'
import styled from 'styled-components'

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  ${props => props.css};
`

const Divider = styled.div`
  background-color: #dbdcde;
  height: 2px;
  width: 50%;
`

const DividerText = styled.span`
  color: #161718;
  padding: 0 15px;
`

const CustomAutotagDivider = ({ text, css }) => (
  <DividerWrapper css={css}>
    <Divider />
    <DividerText>{text}</DividerText>
    <Divider />
  </DividerWrapper>
)

export default CustomAutotagDivider
