import { useMemo } from 'react'
import { useForm, type UseForm, useFormContext, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingsBlock, SettingsBlockInputs } from '../SettingsBlock'
import { SettingValue } from '../SettingValue'
import { TextInput } from '../TextInput'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './NetworkInternationalDirectPurchase.locales'
import type { PaymentIntegration, PaymentIntegrationSettings, PaymentIntegrationConfiguration } from '../interfaces'

function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()

  return (
    <ViewSettingsBlock>
      <Text textStyle="body1Bold">{formatMessage(messages.accountAndAuthentication)}</Text>
      <SettingValue name={(currentConfiguration?.outletReference as string) || '-'} title={formatMessage(messages.outletReference)} />
      <SettingValue name={(currentConfiguration?.ecomAccountKey as string) || '-'} title={formatMessage(messages.ecomAccountKey)} />
      <SettingValue name={(currentConfiguration?.hostedSessionKey as string) || '-'} title={formatMessage(messages.hostedSessionKey)} />
      <SettingValue name={(currentConfiguration?.webhookKey as string) || '-'} title={formatMessage(messages.webhookKey)} />
    </ViewSettingsBlock>
  )
}

export function useNIDirectPurchaseForm(formDefault?: PaymentIntegrationSettings) {
  const schema = useMemoSchemaNIDirectPurchase() as unknown as z.ZodObject<{
    [key: string]: z.ZodDefault<z.ZodOptional<z.ZodString>> | z.ZodString | z.ZodBoolean
  }>
  return useForm(schema, { defaultValues: formDefault })
}

function SettingsForm() {
  const { formatMessage } = useLocales()
  const { field } = useFormContext() as UseForm<z.ZodType<SchemaNIDirectPurchase>>

  return (
    <SettingsBlock header={formatMessage(messages.accountAndAuthentication)}>
      <SettingsBlockInputs>
        <TextInput
          field={field.prop('outletReference')}
          label={formatMessage(messages.outletReference)}
          placeholder={formatMessage(messages.outletReference)}
          data-test="payment-settings-ni-outlet-ref-input"
        />
        <TextInput
          field={field.prop('hostedSessionKey')}
          label={formatMessage(messages.hostedSessionKey)}
          placeholder={formatMessage(messages.hostedSessionKey)}
          data-test="payment-settings-ni-hosted-session-key-input"
        />
      </SettingsBlockInputs>
      <SettingsBlockInputs>
        <TextInput
          field={field.prop('ecomAccountKey')}
          label={formatMessage(messages.ecomAccountKey)}
          placeholder={formatMessage(messages.ecomAccountKey)}
          data-test="payment-settings-ni-ecom-account-key-input"
        />
        <TextInput
          field={field.prop('webhookKey')}
          label={formatMessage(messages.webhookKey)}
          placeholder={formatMessage(messages.webhookKey)}
          data-test="payment-settings-ni-webhook-key-input"
        />
      </SettingsBlockInputs>
    </SettingsBlock>
  )
}

function createSchemaNIDirectPurchase(requiredErrorMessage: string) {
  return z.object({
    outletReference: z.string().trim().min(1, requiredErrorMessage).default(''),
    ecomAccountKey: z.string().trim().min(1, requiredErrorMessage).default(''),
    hostedSessionKey: z.string().trim().min(1, requiredErrorMessage).default(''),
    webhookKey: z.string().trim().min(1, requiredErrorMessage).default(''),
  })
}
type SchemaNIDirectPurchase = z.infer<ReturnType<typeof createSchemaNIDirectPurchase>>

function useMemoSchemaNIDirectPurchase() {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(commonMessages.required)

  return useMemo(() => createSchemaNIDirectPurchase(requiredErrorMessage), [requiredErrorMessage])
}

export const NetworkInternationalDirectPurchase: PaymentIntegration = {
  currentConfiguration: {},
  integrationName: AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE,
  ShowCurrentSettings,
  SettingsForm,
  formDefault: {},
  useFormHook: useNIDirectPurchaseForm,
}
