import { useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { GeneralDocumentationLink } from '../../Integrations'
import { messages } from './DocumentationBanner.locales'

export function DocumentationBanner() {
  const { formatMessage } = useLocales()

  return (
    <Banner
      title={formatMessage(messages.title)}
      description={formatMessage(messages.description)}
      type="info"
      action={formatMessage(messages.button)}
      onAction={() => window.open(GeneralDocumentationLink)}
      data-test="payment-settings-documentation-banner"
    />
  )
}
