import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReviewSitesFormData = ZodSchema<ReturnType<typeof useReviewSitesForm>>
export type ReviewSitesFormFields = Field<ReviewSitesFormData>
export type ReviewSiteFieldKey =
  | 'reviewSiteGoogle'
  | 'reviewSiteGrubhub'
  | 'reviewSiteOpenTable'
  | 'reviewSiteOpenTableUsa'
  | 'reviewSiteOpenRice'
  | 'reviewSiteTheFork'
  | 'reviewSiteTripadvisor'
  | 'reviewSiteYelp'
export type ReviewSiteDirectEnabledKey = 'reviewSiteGoogleDirectEnabled'

const urlFieldOptional = z.string().url().nullable().or(z.literal(''))

export const useReviewSitesForm = () =>
  useMemo(
    () =>
      z.object({
        reviewSiteGoogle: urlFieldOptional,
        reviewSiteGrubhub: urlFieldOptional,
        reviewSiteOpenTable: urlFieldOptional,
        reviewSiteOpenTableUsa: urlFieldOptional,
        reviewSiteOpenRice: urlFieldOptional,
        reviewSiteTheFork: urlFieldOptional,
        reviewSiteTripadvisor: urlFieldOptional,
        reviewSiteYelp: urlFieldOptional,
        reviewSiteGoogleDirectEnabled: z.boolean(),
      }),
    []
  )

export const getReviewSitesFormDefaultValues = (source: VenueProfile): ReviewSitesFormData => ({
  reviewSiteGoogle: source.reviewSiteGoogle,
  reviewSiteGrubhub: source.reviewSiteGrubhub,
  reviewSiteOpenTable: source.reviewSiteOpenTable,
  reviewSiteOpenTableUsa: source.reviewSiteOpenTableUsa,
  reviewSiteOpenRice: source.reviewSiteOpenRice,
  reviewSiteTheFork: source.reviewSiteTheFork,
  reviewSiteTripadvisor: source.reviewSiteTripadvisor,
  reviewSiteYelp: source.reviewSiteYelp,
  reviewSiteGoogleDirectEnabled: source.reviewSiteGoogleDirectEnabled,
})
