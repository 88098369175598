import { useMemo, useState, useCallback } from 'react'
import type { Experience, Event } from '@sevenrooms/core/domain'
import { type Field, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, Box, Spreadsheet, Window, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { ExperiencesLocales } from '../../Experiences.locales'
import { EventSelectorModal } from './EventSelectorModal'
import { UnlinkEventModal } from './UnlinkEventModal'

interface AttachEventProps {
  experience?: Experience
  eventsData?: Event[]
  unlinkedEventIDsField: Field<string[]>
  linkedEventIDsField: Field<string[]>
}
export function AttacheEvent({ experience, eventsData, unlinkedEventIDsField, linkedEventIDsField }: AttachEventProps) {
  const unlinkedEventIDsController = useController(unlinkedEventIDsField)
  const linkedEventIDsController = useController(linkedEventIDsField)

  const [isLinkToEventModalOpen, setLinkToEventModalOpen] = useState(false)
  const [eventToUnlink, setEventToUnlink] = useState<null | Event>(null)
  const { formatMessage } = useLocales()

  const columns = useMemo<DataTableColumn<Event>[]>(
    () => [
      {
        header: formatMessage(ExperiencesLocales.eventName),
        render: (value: Event) => <Text>{value.name || '-'}</Text>,
        key: 'name',
      },
      {
        header: formatMessage(ExperiencesLocales.eventSchedule),
        render: (value: Event) => <Text>{value.schedule || ''}</Text>,
        key: 'startDate',
      },
      {
        header: '',
        render: (value: Event, index: number) => (
          <Box textAlign="right">
            <a
              href={`/manager/${window.globalInit.venueId}/events?selected_event_id=${value.id}`}
              target="_blank"
              rel="noreferrer"
              data-test={`open-event-${index}`}
            >
              <Icon name={Icons['VMSWeb-open-in-new']} color="primaryIcons" />
            </a>
          </Box>
        ),
        key: 'openEvent',
      },
      {
        header: '',
        render: (value: Event, index: number) => (
          <Button variant="tertiary" icon="VMSWeb-unlink" onClick={() => setEventToUnlink(value)} data-test={`unlink-${index}`} />
        ),
        key: 'unlink',
      },
    ],
    [formatMessage]
  )

  const events = useMemo<Event[]>(
    () =>
      eventsData
        ? eventsData.filter(
            event =>
              new Date() < new Date(`${event.endDate} ${event.eventEndTime}`) &&
              (!event.experienceId || event.experienceId === 'None' || (experience && event.experienceId === experience?.id))
          )
        : [],
    [eventsData, experience]
  )
  const selectedEvents = useMemo(
    () =>
      eventsData
        ? eventsData.filter(
            event =>
              new Date() < new Date(`${event.endDate} ${event.eventEndTime}`) &&
              ((event.experienceId === experience?.id && !unlinkedEventIDsController.field.value?.includes(event.id)) ||
                linkedEventIDsController.field.value?.includes(event.id))
          )
        : [],
    [eventsData, experience?.id, linkedEventIDsController.field.value, unlinkedEventIDsController.field.value]
  )
  const unlinkEvent = useCallback(() => {
    if (eventToUnlink) {
      if (linkedEventIDsController.field.value && linkedEventIDsController.field.value.includes(eventToUnlink.id)) {
        linkedEventIDsController.field.onChange(linkedEventIDsController.field.value.filter(eventId => eventId !== eventToUnlink.id))
      } else {
        unlinkedEventIDsController.field.onChange(
          unlinkedEventIDsController.field.value ? [...unlinkedEventIDsController.field.value, eventToUnlink.id] : [eventToUnlink.id]
        )
      }
    }
    setEventToUnlink(null)
  }, [eventToUnlink, linkedEventIDsController.field, unlinkedEventIDsController.field])
  return (
    <>
      <Box>
        <BaseSection
          title={formatMessage(ExperiencesLocales.associatedEvents)}
          description={
            experience ? (
              formatMessage(ExperiencesLocales.linkOfferToEventText)
            ) : (
              <Status kind="warning">{formatMessage(ExperiencesLocales.eventWarning)}</Status>
            )
          }
        >
          <Box p="lm">
            {unlinkedEventIDsController.field.value || linkedEventIDsController.field.value ? (
              <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
                <Text fontStyle="italic">{formatMessage(ExperiencesLocales.saveChangesToCompleteEvent)}</Text>
              </Box>
            ) : (
              selectedEvents.length === 0 && (
                <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
                  <Text fontStyle="italic">{formatMessage(ExperiencesLocales.rememberToLinkEvent)}</Text>
                </Box>
              )
            )}
            <Spreadsheet data={selectedEvents} columns={columns} noPagination noBorder />
            <Box mt="lm">
              <Button
                icon="VMSWeb-link"
                variant="tertiary"
                noPadding
                disabled={!experience}
                onClick={() => setLinkToEventModalOpen(true)}
                data-test="link-event-modal-button"
              >
                {formatMessage(ExperiencesLocales.linkEvent)}
              </Button>
            </Box>
          </Box>
        </BaseSection>
      </Box>

      <Window active={isLinkToEventModalOpen}>
        <EventSelectorModal
          setSelectedEventIds={linkedEventIDsController.field.onChange}
          selectedEventIds={linkedEventIDsController.field.value}
          close={() => setLinkToEventModalOpen(false)}
          events={events.filter(event => !event.experienceId || unlinkedEventIDsController.field.value?.includes(event.id))}
        />
      </Window>
      <Window active={eventToUnlink !== null}>
        <UnlinkEventModal onDiscard={() => setEventToUnlink(null)} onSubmit={unlinkEvent} eventName={eventToUnlink?.name || ''} />
      </Window>
    </>
  )
}
