import { defineMessages } from '@sevenrooms/core/locales'

export const AccessRuleSelectorLocales = defineMessages({
  accessRuleSelectDays: {
    id: 'accessRuleSelector.accessRuleSelect.days',
    defaultMessage: 'Days',
  },
  accessRuleSelectDateRange: {
    id: 'accessRuleSelector.accessRuleSelect.dateRange',
    defaultMessage: 'Date Range',
  },
  accessRuleSelectTime: {
    id: 'accessRuleSelector.accessRuleSelect.time',
    defaultMessage: 'Time(s)',
  },
  accessRuleSelectShiftCategories: {
    id: 'accessRuleSelector.accessRuleSelect.shiftCategories',
    defaultMessage: 'Shift categories',
  },
  accessRuleSelectPartySize: {
    id: 'accessRuleSelector.accessRuleSelect.partySize',
    defaultMessage: 'Party Size',
  },
  modalTitle: {
    id: 'accessRuleSelector.modalTitle',
    defaultMessage: 'Select Access Rule(s) to link to your Offer',
  },
  unlinkTitle: {
    id: 'accessRuleSelector.unlinkTitle',
    defaultMessage: 'Are you sure you want to unlink from {name}?',
  },
  unlinkAccessRule: {
    id: 'accessRuleSelector.unlinkAccessRule',
    defaultMessage: 'Unlink Access Rule',
  },
  followShrift: {
    id: 'accessRuleSelector.followShrift',
    defaultMessage: 'Follow Shift',
  },
  allAccessRulesAreAlreadyLinked: {
    id: 'accessRuleSelector.allAccessRulesAreAlreadyLinked',
    defaultMessage: 'All Access Rules are already linked to other Offers',
  },
} as const)
