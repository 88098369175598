import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './RecommendedIntegrationStripe.locales'

export function RecommendedIntegrationStripe() {
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="m">
      <HStack spacing="m">
        <Box>
          <Icon name="VMSWeb-checkbox-marked-circle" color="success" size="2x" />
        </Box>
        <VStack>
          <Text textStyle="body1Bold">{formatMessage(messages.stripeSpeed)}</Text>
          <Text textStyle="body1" color="secondaryFont">
            {formatMessage(messages.stripeSpeedLabel)}
          </Text>
        </VStack>
      </HStack>
      <HStack spacing="m">
        <Box>
          <Icon name="VMSWeb-checkbox-marked-circle" color="success" size="2x" />
        </Box>
        <VStack>
          <Text textStyle="body1Bold">{formatMessage(messages.stripeReliability)}</Text>
          <Text textStyle="body1" color="secondaryFont">
            {formatMessage(messages.stripeReliabilityLabel)}
          </Text>
        </VStack>
      </HStack>
      <HStack spacing="m">
        <Box>
          <Icon name="VMSWeb-checkbox-marked-circle" color="success" size="2x" />
        </Box>
        <VStack>
          <Text textStyle="body1Bold">{formatMessage(messages.stripeSupport)}</Text>
          <Text textStyle="body1" color="secondaryFont">
            {formatMessage(messages.stripeSupportLabel)}
          </Text>
        </VStack>
      </HStack>
    </VStack>
  )
}
