import styled from 'styled-components'

export const ActionButton = styled.button`
  ${props => props.theme.primaryButton};
  background: ${props => props.theme.color.teal};
  height: 46px;
  line-height: 46px;
  padding-left: ${props => props.theme.gutter.triple};
  padding-right: ${props => props.theme.gutter.triple};
  width: auto;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  :hover {
    opacity: 0.8;
  }
`
