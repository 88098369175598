import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { PartySizeLocales } from '../PartySize.locales'

export function DefaultPartySize() {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()

  return (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart caption={[formatMessage(accessRulesMessages.shift), formatMessage(PartySizeLocales.title)]}>
          {shifts.map(shift => (
            <ReportLine key={shift.id} name={shift.name}>
              <>
                {shift.minPartySize} - {shift.maxPartySize}
              </>
            </ReportLine>
          ))}
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
