import { defineMessages } from '@sevenrooms/core/locales'

export const ReservationCoverLimitLocales = defineMessages({
  limitRequired: {
    id: 'ar.reservationCoverLimit.limitRequired',
    defaultMessage: 'Maximum reservation or cover limit is required',
  },
  title: {
    id: 'ar.reservationCoverLimit.title',
    defaultMessage: 'Reservation or Cover Limit',
  },
  description: {
    id: 'ar.reservationCoverLimit.description',
    defaultMessage:
      "Ex: You're offering a Prime Rib dinner, and you have a limited number of inventory. You limit the number of covers to account for that.",
  },
  subCaption: {
    id: 'ar.reservationCoverLimit.subCaption',
    defaultMessage: 'Limit the total number of reservations or covers that can be booked online through this Access Rule.',
  },
  label: {
    id: 'ar.reservationCoverLimit.label',
    defaultMessage: 'Maximum reservation or cover limit per day',
  },
  placeholder: {
    id: 'ar.reservationCoverLimit.placeholder',
    defaultMessage: 'Enter count',
  },
  tooltip: {
    id: 'ar.reservationCoverLimit.tooltip',
    defaultMessage: 'Setting limit to 0 will prevent guests from booking this Access Rule online.',
  },
  defaultsKey: {
    id: 'ar.reservationCoverLimit.defaultsKey',
    defaultMessage: 'Reservation or Cover Limit:',
  },
  defaultsValue: {
    id: 'ar.reservationCoverLimit.defaultsValue',
    defaultMessage: 'Unlimited',
  },
  selectionSummary: {
    id: 'ar.reservationCoverLimit.selectionSummary',
    defaultMessage: `{type, select,
      COVERS {
        {count, plural,
          one {1 cover per day}
          other {# covers per day}
        }
      }
      RESERVATIONS {
        {count, plural,
          one {1 reservation per day}
          other {# reservations per day}
        }
      }
      other {unlimited per day}
    }`,
  },
  reservations: {
    id: 'ar.reservationCoverLimit.reservations',
    defaultMessage: 'Reservations',
  },
  covers: {
    id: 'ar.reservationCoverLimit.covers',
    defaultMessage: 'Covers',
  },
  maxLimitPerDay: {
    id: 'ar.reservationCoverLimit.maxLimitPerDay',
    defaultMessage: 'Max limit per day',
  },
  guaranteeBookings: {
    id: 'ar.reservationCoverLimit.guaranteeBookings',
    defaultMessage: 'Guarantee Bookings: Reservations up to this limit will be allowed even if there is no shift availability',
  },
  guaranteeBookingsTooltip: {
    id: 'ar.reservationCoverLimit.guaranteeBookingsTooltip',
    defaultMessage: 'This setting ensures contractual commitments to third-parties can be met even if your staff overbooks the Shift',
  },
  guaranteeBookingsSummaryKey: {
    id: 'ar.reservationCoverLimit.guaranteeBookingsSummaryKey',
    defaultMessage: 'Guarantee Bookings:',
  },
  guaranteeBookingsReportKey: {
    id: 'ar.reservationCoverLimit.guaranteeBookingsReportKey',
    defaultMessage: 'Guarantee Bookings',
  },
} as const)
