import { useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import type { SMSCampaignTemplate } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { SimpleOverlay, Flex, Box, BorderedBox, SlideOut } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { oneTimeSMSCenterMessages } from '../locales'
import { SMSPreviewCard } from './SMSPreviewCard'
import { SMSTemplatePreviewFooter } from './SMSTemplatePreviewFooter'

export interface SMSTemplatePreviewProps {
  template: SMSCampaignTemplate
  cardBackgroudColor: ThemeColor
  cardHeight: string
  cardWidth: string
  scrollable: boolean
}

const PREVIEW_PHONE_WIDTH = '264px'
const PREVIEW_PHONE_HEIGHT = '528px'

export function SMSTemplatePreview({ template, scrollable, cardBackgroudColor, cardHeight, cardWidth }: SMSTemplatePreviewProps) {
  const { formatMessage } = useLocales()
  const [showSlideOut, setShowSlideOut] = useState(false)
  const [showPreviewBtn, setShowPreviewBtn] = useState(false)

  return (
    <BorderedBox
      backgroundColor={cardBackgroudColor}
      maxWidth={cardWidth}
      minWidth={cardWidth}
      height={cardHeight}
      maxHeight={cardHeight}
      position="relative"
      overflow={scrollable ? 'scroll' : 'hidden'}
      pt="m"
      pb="m"
      pl="sm"
      pr="sm"
      onMouseEnter={() => setShowPreviewBtn(true)}
      onMouseLeave={() => setShowPreviewBtn(false)}
    >
      <SMSPreviewCard messageBody={template.messageBody} mediaUrl={template.messageMedia ? template.messageMedia[0] : undefined} />
      <SimpleOverlay
        flexDirection="column"
        justifyContent="center"
        show={showPreviewBtn}
        alignItems="center"
        flexGrow="1"
        onClick={() => {
          setShowSlideOut(true)
          setShowPreviewBtn(false)
        }}
      >
        <Button data-test="sms-preview-template">{formatMessage(oneTimeSMSCenterMessages.previewTemplateTitle)}</Button>
      </SimpleOverlay>
      <SlideOut
        title={formatMessage(oneTimeSMSCenterMessages.previewTemplateTitle)}
        onCloseComplete={() => setShowSlideOut(false)}
        show={showSlideOut}
        enableAutoFocus
        enableFocusLock
        enableReturnFocus
        enableBackdrop
        width={550}
        footer={<SMSTemplatePreviewFooter templateId={template.id} />}
      >
        <Flex justifyContent="center" alignItems="center" width="100%" minHeight="100%">
          <StyledBoxAsPhone width={PREVIEW_PHONE_WIDTH} height={PREVIEW_PHONE_HEIGHT} borderColor="primaryFont" overflow="hidden">
            <Box width="100%" height="100%" p="s" overflowY="auto">
              <SMSPreviewCard messageBody={template.messageBody} mediaUrl={template.messageMedia ? template.messageMedia[0] : undefined} />
            </Box>
          </StyledBoxAsPhone>
        </Flex>
      </SlideOut>
    </BorderedBox>
  )
}

const StyledBoxAsPhone = styled(Box)`
  border-width: 8px;
  border-radius: 25px;
`
