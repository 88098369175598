import { defineMessages } from '@sevenrooms/core/locales'

export const insightsMessages = defineMessages({
  insightsTitle: {
    id: 'insights.insightsTitle',
    defaultMessage: 'Revenue Management Insights Dashboard',
  },
  insightsDescription: {
    id: 'insights.insightsDescription',
    defaultMessage:
      'Explore Insights and implement changes to ensure you allocate the right seat to the right guest at the right time at the right price — maximizing your revenue.',
  },
  insightsSideNavHeaderInsights: {
    id: 'insights.insightsSideNavTitleInsights',
    defaultMessage: 'Insights',
  },
  insightsSideNavSubHeaderInsightsDashboard: {
    id: 'insights.insightsSideNavSubHeaderInsightsDashboard',
    defaultMessage: 'Insights Dashboard <span>{newInsightsCount}</span>',
  },
  insightsSideNavSubHeaderInsightsDashboardNoCount: {
    id: 'insights.insightsSideNavSubHeaderInsightsDashboardNoCount',
    defaultMessage: 'Insights Dashboard',
  },
  insightsSideNavHeaderReports: {
    id: 'insights.insightsSideNavTitleReports',
    defaultMessage: 'Reports',
  },
  // There is a type over_allocated_durations within the missallocation
  // type group; also an assumption that it's the only kind of misallocation
  // in the forseable future, hence this top-level use of language
  misallocationTitle: {
    id: 'insights.overAllocationTitle',
    defaultMessage: 'Over-allocated Durations',
  },
  misallocationDescription: {
    id: 'insights.overAllocationDescription',
    defaultMessage: 'Ensure your table durations are configured correctly to ensure business efficiency and customer satisfaction.',
  },
  reduceCancellationsTitle: {
    id: 'insights.reduceCancellationsTitle',
    defaultMessage: 'Reduce Cancellations',
  },
  reduceCancellationsDescription: {
    id: 'insights.reduceCancellationsDescription',
    defaultMessage:
      'Late cancellations can cause you to incur a high cost of no-shows or cancellations. Implementing a cancellation fee can mitigate cancellations and recoup losses.',
  },
  channelOptimizationTitle: {
    id: 'insights.channelOptimizationTitle',
    defaultMessage: 'Channel Optimization',
  },
  channelOptimizationDescription: {
    id: 'insights.channelOptimizationDescription',
    defaultMessage:
      'Paid 3rd party booking channels should be used at times where direct demand is not sufficient to fill your restaurant. In cases where you are taking 3rd party reservations but turning away direct demand, consider reallocating inventory away from 3rd parties.',
  },
  suggestedActionsChannelOptimizationSlideoutDescription: {
    id: 'insights.suggestedActionsChannelOptimizationSlideoutDescription',
    defaultMessage: `<p>Balancing inventory between multiple systems can be challenging, and knowing when to restrict 3rd party inventory
    is key to ensuring that you maximize your direct demand channels. This helps reduce covers costs to 3rd parties, ensures you’re
    building your guest database with important data, and builds brand loyalty with your guests.</p>
    <p>If you are turning away people searching on your widget, while also filling tables during those same periods with 3rd party paid
    bookings, you should consider reducing inventory on 3rd parties and reallocating that to direct channels.</p>`,
  },
  availabilityOptimizationTitle: {
    id: 'insights.availabilityOptimizationTitle',
    defaultMessage: 'Availability Optimization',
  },
  availabilityOptimizationDescription: {
    id: 'insights.availabilityOptimizationDescription',
    defaultMessage:
      'Shifts and Access Rules are the foundation to your ability to drive Revenue with SevenRooms. We’re monitoring your configurations and will suggest changes that can help you make more from your current demand.',
  },
  featureUsageLogoText: {
    id: 'insights.featureUsageLogoText',
    defaultMessage: 'Logos are featured in all guest-facing emails. Uploading a logo helps build brand recognition with each email.',
  },
  featureUsageLogoProofLabel: {
    id: 'insights.featureUsageLogoProofLabel',
    defaultMessage: 'Logo Upload Status',
  },
  logoUploadProofTitle: {
    id: 'insights.logoUploadProofTitle',
    defaultMessage: 'Venue Logo',
  },
  featureUsageMarketingAutomationsText: {
    id: 'insights.featureUsageMarketingAutomationsText',
    defaultMessage: `<p>SevenRooms Marketing Automations enables you to send targeted marketing emails to specific segments of your client
    database based on specific triggers to help bring guests back in for repeat visits. Our 10+ default campaign types include Thank You
    campaigns, reengagment campaigns, welcome back campaigns, feedback follow-up campaigns and more.</p>
    <p>Additionally, you can easily measure open rates, click rates, bookings, and spend (if POS integration enabled) to understand which
    campaigns are the most impactful. Once you enable these automated campaigns, they will run automatically in the background.</p>`,
  },
  featureUsageMarketingAutomationsProofLabel: {
    id: 'insights.featureUsageMarketingAutomationsProofLabel',
    defaultMessage: 'Marketing Automations Status',
  },
  featureUsageMarketingAutomationsProofTitle: {
    id: 'insights.featureUsageMarketingAutomationsProofTitle',
    defaultMessage: 'Marketing Automations',
  },
  floorplanOptimizationTitle: {
    id: 'insights.floorplanOptimizationTitle',
    defaultMessage: 'Floorplan Optimization',
  },
  floorplanOptimizationDescription: {
    id: 'insights.floorplanOptimizationDescription',
    defaultMessage:
      'Your Floorplan configuration has a direct relationship with the number of covers you are able to serve each Shift. Ensuring you are configured for success is pivotal for optimizing your revenue.',
  },
  featureUsagePriorityAlertsText: {
    id: 'insights.featureUsagePriorityAlertsText',
    defaultMessage: `<p>You’re already taking Requests on your Reservation Widget, but haven’t enabled Priority Alerts which can alert
    the waiting guests automatically when a reservation becomes available for their requested time.</p>
    <p>With Priority Alerts, you can prioritize the guests that matter most to you so they receive an Alert first. You can also choose
    which party sizes are alerted automatically and which you would prefer to handle manually via Requests.</p>`,
  },
  featureUsagePriorityAlertsProofLabel: {
    id: 'insights.featureUsagePriorityAlertsProofLabel',
    defaultMessage: 'Priority Alerts Status',
  },
  featureUsagePriorityAlertsProofTitle: {
    id: 'insights.featureUsagePriorityAlertsProofTitle',
    defaultMessage: 'Priority Alerts',
  },
  addCancellationFeeText: {
    id: 'insights.addCancellationFeeText',
    defaultMessage: `<p>Implementing a cancellation policy is useful in reducing last minute cancellations. When you have unmet demand
    for reservations, each cancellation is a direct hit to your potential revenue.</p>
    <p>Use the cancellation and party size data below to determine which party sizes you would like to implement a Cancellation Policy
    for. Access Rules that don't follow your Default Shift Setting will need to be adjusted as well.</p>`,
  },
  addCancellationFeeStep1: {
    id: 'insights.addCancellationFeeStep1',
    defaultMessage: 'Select <strong>Save Credit Card Details for Later</strong>',
  },
  addCancellationFeeStep2: {
    id: 'insights.addCancellationFeeStep2',
    defaultMessage: 'Under <strong>Require credit card for</strong> make your desired selection',
  },
  addCancellationFeeStep3: {
    id: 'insights.addCancellationFeeStep3',
    defaultMessage: 'Select <strong>Automatic Charge</strong> and input an amount per person (calculations below use $25)',
  },
  featureUsageTitle: {
    id: 'insights.featureUsageTitle',
    defaultMessage: 'SevenRooms Feature Enablement',
  },
  featureUsageDescription: {
    id: 'insights.featureUsageDescription',
    defaultMessage:
      'SevenRooms has a variety of capabilities which are intended to help you run operations with ease. From saving time, to driving revenue, we offer a number of capabilities that will help you reach your goals.',
  },
  statusNewText: {
    id: 'insights.statusNewText',
    defaultMessage: 'New',
  },
  statusDoItForMeText: {
    id: 'insights.statusDoItForMeText',
    defaultMessage: 'Assigned to SevenRooms Support',
  },
  statusDoItAutomatically: {
    id: 'insights.statusDoItAutomatically',
    defaultMessage: 'Done and made the suggested changes with SevenRooms automation',
  },
  statusNewLabel: {
    id: 'insights.statusNewLabel',
    defaultMessage: 'New Insight',
  },
  statusNewLabelShort: {
    id: 'insights.statusNewLabelShort',
    defaultMessage: 'New',
  },
  statusTrackingLabel: {
    id: 'insights.statusTrackingLabel',
    defaultMessage: 'Done',
  },
  statusOptimizedLabel: {
    id: 'insights.statusOptimizedLabel',
    defaultMessage: 'Optimized',
  },
  statusDeclinedLabel: {
    id: 'insights.statusDeclinedLabel',
    defaultMessage: 'Declined',
  },
  statusEmptyLabel: {
    id: 'insights.statusEmptyLabel',
    defaultMessage: 'No new insights!',
  },
  statusChangeRequestedLabel: {
    id: 'insights.statusChangeRequestedLabel',
    defaultMessage: 'Requesting change',
  },
  statusTitle: {
    id: 'insights.statusTitle',
    defaultMessage: 'Status',
  },
  revenueTitle: {
    id: 'insights.revenueTitle',
    defaultMessage: 'Revenue',
  },
  coversTitle: {
    id: 'insights.coversTitle',
    defaultMessage: 'Covers',
  },
  insightsEmptyMessage: {
    id: 'insights.insightsEmptyMessage',
    defaultMessage: 'Everything looks good on our end. Check back for more Insights at the beginning of every month.',
  },
  insightSuggestedActionTitle: {
    id: 'insights.suggestedActionTitle',
    defaultMessage: 'Suggested Action',
  },
  insightSuggestedActionDescription: {
    id: 'insights.suggestedActionDescription',
    defaultMessage: 'Steps to make the change:',
  },
  insightSuggestedActionTakenQuestion: {
    id: 'insights.suggestedActionTakenQuestion',
    defaultMessage: 'Have you made this suggested change?',
  },
  insightSuggestedActionAutomatedQuestion: {
    id: 'insights.insightSuggestedActionAutomatedQuestion',
    defaultMessage: 'Do you want to make the suggested changes?',
  },
  suggestedActionEditShiftStep: {
    id: 'insights.suggestedActionEditShiftStep',
    defaultMessage: 'Open <strong>{shiftName} Shift</strong> and click <strong>Edit All Days</strong>',
  },
  suggestedActionNavigatetoCapacitySettingsStep: {
    id: 'insights.suggestedActionNavigatetoCapacitySettingsStep',
    defaultMessage: '<strong>Navigate to Capacity Settings</strong>',
  },
  suggestedActionNavigatetoPaymentPolicyStep: {
    id: 'insights.suggestedActionNavigatetoPaymentPolicyStep',
    defaultMessage: 'Navigate to <strong>Payment and Policy Settings</strong>',
  },
  suggestedActionUploadLogo: {
    id: 'insights.suggestedActionUploadLogo',
    defaultMessage: 'Scroll to Transactional Email Design and upload your Logo',
  },
  suggestedActionReviewEmailAndSave: {
    id: 'insights.suggestedActionReviewEmailAndSave',
    defaultMessage: 'Preview your email and <strong>Save Changes</strong>',
  },
  suggestedActionSaveStep: {
    id: 'insights.suggestedActionSaveStep',
    defaultMessage: 'Click <strong>Save</strong>',
  },
  suggestedActionSaveShiftAndRepeatStep: {
    id: 'insights.suggestedActionSaveShiftAndRepeatStep',
    defaultMessage:
      'Click <strong>Save</strong> and be sure to repeat if you have created overrides or future seasonal versions of this shift',
  },
  suggestedActionNavigatetoShiftLinkText: {
    id: 'insights.suggestedActionNavigatetoShiftLinkText',
    defaultMessage: '{icon} Open Shift Settings',
  },
  suggestedActionNavigatetoReservationSettingsStep: {
    id: 'insights.suggestedActionNavigatetoGeneralSettingsStep',
    defaultMessage: '<link>{icon} Open Reservation Settings</link>',
  },
  suggestedActionsScrollToAlertsStep: {
    id: 'insights.suggestedActionsScrollToAlertsStep',
    defaultMessage: 'Scroll to <strong>Priority Alerts</strong> and toggle the setting on',
  },
  suggestedActionsFollowPromptsStep: {
    id: 'insights.suggestedActionsFollowPromptsStep',
    defaultMessage: 'Follow the prompts for additional configurations',
  },
  suggestedActionsPrioritySaveChangesStep: {
    id: 'insights.suggestedActionsSaveChangesStep',
    defaultMessage: '<strong>Save Changes</strong>',
  },
  suggestedActionNavigatetoGeneralSettingsStep: {
    id: 'insights.suggestedActionNavigatetoGeneralSettingsStep',
    defaultMessage: '<link>{icon} Open General Settings</link>',
  },
  suggestedActionsClickOnShiftStep: {
    id: 'insights.suggestedActionsClickOnShiftStep',
    defaultMessage: 'Click on {shiftName} Shift',
  },
  suggestedActionsEditAllDaysStep: {
    id: 'insights.suggestedActionsEditAllDaysStep',
    defaultMessage: 'Select <strong>Edit</strong>, then <strong>Edit All Days</strong>',
  },
  suggestedActionsNavigateCapacitySettingsStep: {
    id: 'insights.suggestedActionsNavigateCapacitySettingsStep',
    defaultMessage: 'Navigate to <strong>Capacity Settings</strong>',
  },
  suggestedActionsIncreaseDefaultStep: {
    id: 'insights.suggestedActionsIncreaseDefaultStep',
    defaultMessage: 'Consider increasing your <strong>default pacing</strong> value',
  },
  suggestedActionsIncreaseCustomStep: {
    id: 'insights.suggestedActionsIncreaseCustomStep',
    defaultMessage: 'Consider increasing any <strong>custom pacing</strong> values for specific times that you may have set',
  },
  suggestedActionsNavigateAutomations: {
    id: 'insights.suggestedActionsNavigateAutomations',
    defaultMessage: '<link>{icon} Open Marketing Automations</link>',
  },
  suggestedActionsReviewDefaultCampaigns: {
    id: 'insights.suggestedActionsReviewDefaultCampaigns',
    defaultMessage: 'Review the default Automation Campaigns',
  },
  suggestedActionsEditEmail: {
    id: 'insights.suggestedActionsEditEmail',
    defaultMessage: 'If you would to edit the email before activating it, click {icon} > <strong>Edit</strong>',
  },
  suggestedActionsTurnOnAutomatedEmail: {
    id: 'insights.suggestedActionsTurnOnAutomatedEmail',
    defaultMessage: 'Click {icon} > <strong>Turn on Automated Email</strong>',
  },
  suggestedActionsRepeatCampaigns: {
    id: 'insights.suggestedActionsRepeatCampaigns',
    defaultMessage: 'Repeat for other desired campaigns',
  },
  overallocatedDurationsAdjustDurationStep: {
    id: 'insights.overallocatedDurationsAdjustDurationStep',
    defaultMessage: 'Adjust the duration for <strong>{guestCount} guests</strong> to <strong>{duration}</strong>',
  },
  pacingCoverageTableHeaderShiftName: {
    id: 'insights.pacingCoverageTableHeaderShiftName',
    defaultMessage: 'Shift Name',
  },
  pacingCoverageTableHeaderDate: {
    id: 'insights.pacingCoverageTableHeaderDate',
    defaultMessage: 'Date',
  },
  pacingCoverageTableHeaderDayOfWeek: {
    id: 'insights.pacingCoverageTableHeaderDayOfWeek',
    defaultMessage: 'Day of Week',
  },
  pacingCoverageTableHeaderMaxCover: {
    id: 'insights.pacingCoverageTableHeaderMaxCover',
    defaultMessage: 'Max Cover Capacity',
  },
  pacingCoverageTableHeaderTotalPacing: {
    id: 'insights.pacingCoverageTableHeaderTotalPacing',
    defaultMessage: 'Total Shift Pacing (for recurring Shift)',
  },
  pacingCoverageTableHeaderPacingCoverage: {
    id: 'insights.pacingCoverageTableHeaderPacingCoverage',
    defaultMessage: 'Pacing Coverage',
  },
  pacingCoverageTableHeaderCompletedCovers: {
    id: 'insights.pacingCoverageTableHeaderCompletedCovers',
    defaultMessage: 'Completed Covers',
  },
  pacingCoverageTableHeaderPacingFill: {
    id: 'insights.pacingCoverageTableHeaderPacingFill',
    defaultMessage: 'Pacing Fill',
  },
  pacingCoverageTableHeaderSearchesNoAvailability: {
    id: 'insights.pacingCoverageTableHeaderSearchesNoAvailability',
    defaultMessage: 'Searches without nearby Availability',
  },
  weekdayAllocationOptimizationText: {
    id: 'insights.weekdayAllocationOptimizationText',
    defaultMessage:
      'Optimizing table durations for weekdays can have some unique challenges compared to weekends, as customer behavior and demand patterns may be different',
  },
  insightText: {
    id: 'insights.insightText',
    defaultMessage: 'insight',
  },
  insightsText: {
    id: 'insights.insightsText',
    defaultMessage: 'insights',
  },
  newInsightHeaderText: {
    id: 'insights.newInsightHeaderText',
    defaultMessage: 'New Insight',
  },
  newInsightsHeaderText: {
    id: 'insights.newInsightsHeaderText',
    defaultMessage: 'New Insights',
  },
  errorFetchingInsight: {
    id: 'insights.errorFetchingInsight',
    defaultMessage: 'There was an error loading this insight.',
  },
  errorFetchingInsights: {
    id: 'insights.errorFetchingInsight',
    defaultMessage: 'There was an error loading your insights.',
  },
  trackingText: {
    id: 'insights.trackingText',
    defaultMessage:
      'Now that you have implemented the suggested action, be sure to monitor any operational impact associated with your change. We will continue to monitor these underlying metrics on a monthly basis and will provide you with additional insights if we identify further opportunity. {link}',
  },
  declineReasonExplainText: {
    id: 'insights.declineReasonExplainText',
    defaultMessage: 'Can you explain your decision to deny the proposed suggestion?',
  },
  declineReasonFeedbackText: {
    id: 'insights.declineReasonFeedbackText',
    defaultMessage: 'Your feedback will help us deliver higher quality insights',
  },
  changeRequestMessageText: {
    id: 'insights.changeRequestMessageText',
    defaultMessage: 'Do you have a message you’d like to attach to your request?',
  },
  changeRequestMessageSecondaryText: {
    id: 'insights.changeRequestExplainSecondaryText',
    defaultMessage: 'Scheduled changes will be made by SevenRooms support within 24 hours.',
  },
  changeRequestMessagePlaceholderText: {
    id: 'insights.changeRequestMessagePlaceholderText',
    defaultMessage: 'Modifications, special requests.',
  },
  declinedText: {
    id: 'insights.declinedText',
    defaultMessage:
      'You declined the suggestion action made for this shift. We will continue to monitor data for this shift but won’t alert you until we have another suggestion. {link}',
  },
  declinedYourFeedback: {
    id: 'insights.declinedYourFeedback',
    defaultMessage: 'Your feedback',
  },
  declinedByText: {
    id: 'insights.declinedByText',
    defaultMessage: 'Declined by {user} on {date}',
  },
  revertStatusLinkText: {
    id: 'insights.revertStatusLinkText',
    defaultMessage: 'Revert status',
  },
  overallocatedDurationsTableHeaderPartySize: {
    id: 'insights.overallocatedDurationsTableHeaderPartySize',
    defaultMessage: 'Party Size',
  },
  overallocatedDurationsTableHeaderResCompleted: {
    id: 'insights.overallocatedDurationsTableHeaderResCompleted',
    defaultMessage: '# Reservations Completed',
  },
  overallocatedDurationsTableHeaderCurrentDuration: {
    id: 'insights.overallocatedDurationsTableHeaderCurrentDuration',
    defaultMessage: 'Current Shift Duration',
  },
  overallocatedDurationsTableHeaderAvgDuration: {
    id: 'insights.overallocatedDurationsTableHeaderAvgDuration',
    defaultMessage: 'Actual Average Duration',
  },
  overallocatedDurationsTableHeaderSuggestedDuration: {
    id: 'insights.overallocatedDurationsTableHeaderSuggestedDuration',
    defaultMessage: 'Suggested Shift Duration',
  },
  overallocatedDurationsProofLabel: {
    id: 'insights.overallocatedDurationsProofLabel',
    defaultMessage: 'Duration Data',
  },
  addCancellationFeeTableHeaderBookedCovers: {
    id: 'insights.addCancellationFeeTableHeaderBookedCovers',
    defaultMessage: 'Party Size',
  },
  addCancellationFeeTableHeaderCoversCancelled: {
    id: 'insights.addCancellationFeeTableHeaderCoversCancelled',
    defaultMessage: 'Cancellations <24h',
  },
  addCancellationFeeTableHeaderCancelNoShowAmount: {
    id: 'insights.addCancellationFeeTableHeaderCancelNoShowAmount',
    defaultMessage: 'Actual Cancellation $',
  },
  addCancellationFeeTableHeaderPotentialFee: {
    id: 'insights.addCancellationFeeTableHeaderPotentialFee',
    defaultMessage: 'Potential Cancellation $ ($25pp)',
  },
  addCancellationFeeTableHeaderIncrementalFee: {
    id: 'insights.addCancellationFeeTableHeaderIncrementalFee',
    defaultMessage: 'Incremental Opportunity $',
  },
  addCancellationFeeTableHeaderSearchesWithoutSlot: {
    id: 'insights.addCancellationFeeTableHeaderSearchesWithoutSlot',
    defaultMessage: '# Unavailable Searches',
  },
  addCancellationFeeProofLabel: {
    id: 'insights.addCancellationFeeProofLabel',
    defaultMessage: 'Cancellation and Demand Data',
  },
  reducePaidChannelProofLabel: {
    id: 'insights.reducePaidChannelProofLabel',
    defaultMessage: '{shiftDetail} Supporting Data (30 Days)',
  },
  nextInsightButtonText: {
    id: 'insights.nextInsightButtonText',
    defaultMessage: 'Next Insight',
  },
  previousInsightButtonText: {
    id: 'insights.previousInsightButtonText',
    defaultMessage: 'Previous Insight',
  },
  supportingDataLabel: {
    id: 'insights.supportingDataLabel',
    defaultMessage: 'Supporting Data',
  },
  activityLabel: {
    id: 'insights.activityLabel',
    defaultMessage: 'Activity',
  },
  makeChangeYesButtonLabel: {
    id: 'insights.makeChangeYesButtonLabel',
    defaultMessage: '{icon} Yes, mark as done',
  },
  makeChangeAutomatedButtonLabel: {
    id: 'insights.makeChangeAutomatedButtonLabel',
    defaultMessage: '{icon} Enable, mark as done',
  },
  makeChangeNoButtonLabel: {
    id: 'insights.makeChangeNoButtonLabel',
    defaultMessage: '{icon} No, decline suggestion',
  },
  makeChangeAssignButtonLabel: {
    id: 'insights.makeChangeAssignButtonLabel',
    defaultMessage: '{icon} Assign to Sevenrooms Support',
  },
  changeRequestedText: {
    id: 'insights.changeRequestedText',
    defaultMessage:
      'You requested to have the suggested action implemented for you. We have contacted the CSM on your account to make the change. Continue to check back until the insight status is Tracking. If you have any additional questions please contact us. {link}',
  },
  changeRequestedLinkText: {
    id: 'insights.changeRequestedLinkText',
    defaultMessage: 'Contact support',
  },
  changeRequestedInstructionsAriaLabel: {
    id: 'insights.changeRequestedInstructionsAriaLabel',
    defaultMessage: 'Change request instructions',
  },
  changeRequestedMarkCompleteButtonLabel: {
    id: 'insights.changeRequestedMarkCompletedButtonLabel',
    defaultMessage: 'Mark as complete',
  },
  changeRequestedSuggestedActionDescription: {
    id: 'insights.changeRequestedSuggestedActionDescription',
    defaultMessage: 'Initial suggestion:',
  },
  emptyDeclinedReasonErrorMessage: {
    id: 'insights.emptyDeclinedReasonErrorMessage',
    defaultMessage: 'Please provide a message',
  },
  sidebarHeader: {
    id: 'insights.sidebarHeader',
    defaultMessage: 'Helpful Tips',
  },
  sidebarDescription: {
    id: 'insights.sidebarDescription',
    defaultMessage: 'Here are a few recommendations on how to get the most out of the product:',
  },
  sidebarSuggestionsBullet1: {
    id: 'insights.sidebarSuggestionsBullet1',
    defaultMessage: 'Review each insight, and supporting data and respond by accepting or declining the recommendation',
  },
  sidebarSuggestionsBullet2: {
    id: 'insights.sidebarSuggestionsBullet2',
    defaultMessage: 'Try to complete or decline every insight early in the month to allow ample learning time for next months insights',
  },
  sidebarSuggestionsBullet3: {
    id: 'insights.sidebarSuggestionsBullet3',
    defaultMessage: 'Provide an explanation if you decline a suggestion to help us improve',
  },
  sidebarInsightStatuses: {
    id: 'insights.sidebarInsightStatuses',
    defaultMessage: 'INSIGHT STATUSES',
  },
  sidebarNewDescription: {
    id: 'insights.sidebarNewDescription',
    defaultMessage:
      '<strong>New insights</strong> are the latest updates for you based on your data -- delivered at the beginning of every month.',
  },
  sidebarTrackingDescription: {
    id: 'insights.sidebarTrackingDescription',
    defaultMessage: 'Insights move to <strong>Done</strong> once you respond Yes or Assign to SevenRooms Support.\n',
  },
  sidebarDeclinedDescription: {
    id: 'insights.sidebarDeclinedDescription',
    defaultMessage: 'Any insights you <strong>Decline</strong> will help us to improve insight relevancy in the future.\n',
  },
  sidebarFeedbackRequestHeader: {
    id: 'insights.sidebarFeedbackRequestHeader',
    defaultMessage: 'FEEDBACK',
  },
  sidebarFeedbackRequestText: {
    id: 'insights.sidebarFeedbackRequestText',
    defaultMessage:
      'Your feedback is essential for us to understand how to improve this experience. Please take the time to provide suggestions and let us know what you think.',
  },
  sidebarFeedbackRequestButtonLabel: {
    id: 'insights.sidebarFeedbackRequestButtonLabel',
    defaultMessage: 'Submit Feedback',
  },
  featureEnablementProofDateLastChecked: {
    id: 'insights.featureEnablementProofDateLastChecked',
    defaultMessage: 'Setting last checked on {date}',
  },
  lowUsersSlideoutDescription: {
    id: 'insights.lowUsersSlideoutDescription',
    defaultMessage: `<p>Inviting additional team members is a great way to ensure that everyone is enabled to create the best possible
      guest experience. Having individual user accounts is also important for viewing the Activity Log to determine who made each change
      in SevenRooms.</p>
      <p>SevenRooms offers fine-tuned control over user permissions, allowing you to provide the right level of access to each team member.
      For more information about our available permissions, visit this <link>Help Center article</link>.</p>`,
  },
  suggestedActionReducePaidReview: {
    id: 'insights.suggestedActionReducePaidReview',
    defaultMessage: 'Review the supporting data below showing that you have an opportunity to reduce reliance on 3rd parties',
  },
  suggestedActionReducePaidEliminate: {
    id: 'insights.suggestedActionReducePaidEliminate',
    defaultMessage:
      'In your 3rd party systems, reduce or eliminate availability for <strong>{shiftDetail}</strong> where you have enough direct demand that you are turning away that could replace the paid inventory',
  },
  suggestedActionReducePaidAdjustAccess: {
    id: 'insights.suggestedActionReducePaidAdjustAccess',
    defaultMessage:
      'In SevenRooms, ensure you make corresponding adjustments to any pacing restrictions or Access Rule holds that may be blocking direct bookings during those times',
  },
  suggestedActionNavigatetoUserAccounts: {
    id: 'insights.suggestedActionNavigatetoUserAccounts',
    defaultMessage: '<link>{icon} Open User Accounts</link>',
  },
  lowUsersProofLabel: {
    id: 'insights.lowUsersProofLabel',
    defaultMessage: 'Current Account User Information',
  },
  lowUsersUserCount: {
    id: 'insights.lowUsersUserCount',
    defaultMessage: 'Number of active users',
  },
  suggestedActionAddNew: {
    id: 'insights.suggestedActionsAddNew',
    defaultMessage: 'Click <strong>Add New</strong>',
  },
  suggestedActionInputUserInfo: {
    id: 'insights.suggestedActionInputUserInfo',
    defaultMessage: 'Input user information and select desired Access Level and permissions',
  },
  suggestedActionSaveRepeat: {
    id: 'insights.suggestedActionSaveRepeat',
    defaultMessage: 'Save and repeat',
  },
  posEnabledReviewPOSSystemsStep: {
    id: 'insights.posEnabledReviewPOSSystemsStep',
    defaultMessage: 'Review the <link>{icon} POS systems</link> SevenRooms integrates with',
  },
  posEnabledScheduleConfigStep: {
    id: 'insights.posEnabledScheduleConfigStep',
    defaultMessage:
      'If your POS is supported and you are not already scheduled for configuration, <link>{icon} Contact our Support team</link>',
  },
  posEnabledDeclineStep: {
    id: 'insights.posEnabledDeclineStep',
    defaultMessage: "If your POS system is not supported, respond No below and add the system's name when prompted for an explanation",
  },
  posEnabledProofLabel: {
    id: 'insights.posEnabledProofLabel',
    defaultMessage: 'POS Configuration',
  },
  posEnabledProofTitle: {
    id: 'insights.posEnabledProofTitle',
    defaultMessage: 'POS Integration Enabled',
  },
  posEnabledSlideoutDescription: {
    id: 'insights.posEnabledSlideoutDescription',
    defaultMessage:
      'Integrating SevenRooms with your point of sale (POS) allows you to track Live Spend per Table, spend history per Client and a detailed order history for each Client.',
  },
  paymentEnabledReviewSystemsStep: {
    id: 'insights.paymentEnabledReviewSystemsStep',
    defaultMessage: 'Review the list of <link>{icon} Supported Payment Systems</link>',
  },
  paymentEnabledConfigStep: {
    id: 'insights.paymentEnabledConfigStep',
    defaultMessage: 'If your payment system is supported, follow the steps listed for that provider',
  },
  paymentEnabledSignupStep: {
    id: 'insights.paymentEnabledSignupStep',
    defaultMessage: 'If you do not have a payment system, consider signing up with Stripe or evaluating another supported system',
  },
  paymentEnabledDeclineStep: {
    id: 'insights.paymentEnabledDeclineStep',
    defaultMessage:
      "If you have a payment system that is not supported, respond No below and add the system's name when prompted for an explanation",
  },
  paymentEnabledProofLabel: {
    id: 'insights.paymentEnabledProofLabel',
    defaultMessage: 'Payment System Configuration',
  },
  paymentEnabledProofTitle: {
    id: 'insights.paymentEnabledProofTitle',
    defaultMessage: 'Payment System Enabled',
  },
  paymentEnabledSlideoutDescription: {
    id: 'insights.paymentEnabledSlideoutDescription',
    defaultMessage:
      'Integrating SevenRooms with a payment provider supercharges your ability to maximize revenue through Upgrades, cancellation fees and ticketed events.',
  },
  googleReserveEnabledReviewStep: {
    id: 'insights.googleReserveEnabledReviewStep',
    defaultMessage: 'Review the recommendation and determine if you should make reservations available on Google Reserve',
  },
  googleReserveEnabledConfigStep: {
    id: 'insights.googleReserveEnabledConfigStep',
    defaultMessage: 'If yes, choose <strong>Enable, mark as done</strong>',
  },
  googleReserveEnabledHelpCenterStep: {
    id: 'insights.googleReserveEnabledHelpCenterStep',
    defaultMessage: 'Review this <link>{icon} Help Center video</link> to ensure your Access Rules are targeting Google Reserve',
  },
  googleReserveEnabledProofLabel: {
    id: 'insights.googleReserveEnabledProofLabel',
    defaultMessage: 'Google Reserve Status',
  },
  googleReserveEnabledProofTitle: {
    id: 'insights.googleReserveEnabledProofTitle',
    defaultMessage: 'Google Reserve Integration',
  },
  googleReserveEnabledSlideoutDescription: {
    id: 'insights.googleReserveEnabledSlideoutDescription',
    defaultMessage: `<p>Listing reservations on Google Reserve is one of the most impactful ways to drive direct bookings and capture
    organic demand for your restaurant.</p>
    <p>As one of the largest global booking channels, Google Reserve can significantly increase covers, so ensuring you’re set up correctly
    is pivotal.</p>`,
  },
  requestsEnabledReviewStep: {
    id: 'insights.requestsEnabledReviewStep',
    defaultMessage: 'Open <link>{icon} Reservation Widget Settings</link>',
  },
  requestsEnabledConfigStep: {
    id: 'insights.requestsEnabledConfigStep',
    defaultMessage: 'Scroll to <strong>Additional Search Configurations</strong> and toggle <strong>Enable Requests</strong> to on',
  },
  requestsEnabledSaveStep: {
    id: 'insights.requestsEnabledSaveStep',
    defaultMessage: 'Scroll to the bottom and <strong>Save</strong> changes once satisfied',
  },
  requestsEnabledProofLabel: {
    id: 'insights.requestsEnabledProofLabel',
    defaultMessage: 'Reservation Widget Requests Status',
  },
  requestsEnabledProofTitle: {
    id: 'insights.requestsEnabledProofTitle',
    defaultMessage: 'Enable Requests',
  },
  requestsEnabledSlideoutDescription: {
    id: 'insights.requestsEnabledSlideoutDescription',
    defaultMessage: `<p>When a guest is unable to book a reservation for their desired date, time and party size,
    you can capture their interest using Requests.</p>
    <p>Requests also help capture booking interest from large groups that are not able to automatically book
    through your Reservation Widget.</p>`,
  },
  resSummaryEmailEnabledToggleStep: {
    id: 'insights.resSummaryEmailEnabledToggleStep',
    defaultMessage: 'Toggle on <strong>Reservation Summary Email - Internal</strong>',
  },
  resSummaryEmailEnabledSelectShiftsStep: {
    id: 'insights.resSummaryEmailEnabledSelectShiftsStep',
    defaultMessage: 'Check the box next to the Shift Category you want to send an email for',
  },
  resSummaryEmailEnabledSelectUsersStep: {
    id: 'insights.resSummaryEmailEnabledSelectUsersStep',
    defaultMessage:
      'Under <link>{icon} User accounts</link>, ensure that each user you would like to receive the email has Automated Reservation Summary Email Subscription checked',
  },
  resSummaryEmailEnabledProofLabel: {
    id: 'insights.resSummaryEmailEnabledProofLabel',
    defaultMessage: 'Reservation Summary Status',
  },
  resSummaryEmailEnabledProofTitle: {
    id: 'insights.resSummaryEmailEnabledProofTitle',
    defaultMessage: 'Reservation Summary Email',
  },
  resSummaryEmailEnabledSlideoutDescription: {
    id: 'insights.resSummaryEmailEnabledSlideoutDescription',
    defaultMessage: `<p>The Reservation Summary email is a powerful tool for informing your team about an upcoming service. The email can
    be sent before or after a Shift and breaks out booking metrics like covers, reservations and walk-ins while also showing you Client
    and Reservation details for all bookings, highlighting VIPs at the top.</p>
    <p>These emails can be scheduled on a daily basis by Shift, and user Access Levels can be used to determine recipients. For more
    information review this <link>Help Center article.</link></p>`,
  },
  feedbackSummaryEmailEnabledToggleStep: {
    id: 'insights.feedbackSummaryEmailEnabledToggleStep',
    defaultMessage: 'Toggle on <strong>Feedback Summary Email - Internal</strong>',
  },
  feedbackSummaryEmailEnabledSelectTimeStep: {
    id: 'insights.feedbackSummaryEmailEnabledSelectTimeStep',
    defaultMessage: 'Select your desired send time',
  },
  feedbackSummaryEmailEnabledSelectUsersStep: {
    id: 'insights.feedbackSummaryEmailEnabledSelectUsersStep',
    defaultMessage:
      'Under <link>{icon} User accounts</link>, ensure that each user you would like to receive the email has Automated Feedback Summary Email Subscription checked',
  },
  feedbackSummaryEmailEnabledProofLabel: {
    id: 'insights.feedbackSummaryEmailEnabledProofLabel',
    defaultMessage: 'Feedback Summary Status',
  },
  feedbackSummaryEmailEnabledProofTitle: {
    id: 'insights.feedbackSummaryEmailEnabledProofTitle',
    defaultMessage: 'Feedback Summary Email',
  },
  feedbackSummaryEmailEnabledSlideoutDescription: {
    id: 'insights.feedbackSummaryEmailEnabledSlideoutDescription',
    defaultMessage: `<p>The Feedback Summary email helps you vigilantly address guest Feedback responses and Reviews. Online reputation
    is incredibly important for restaurants, and actively responding to feedback is a good way to manage this. </p>
    <p>These emails can be scheduled on a daily basis by Shift, and user Access Levels can be used to determine recipients. For more
    information review this <link>Help Center article.</link></p>`,
  },
  summaryEmailEnabledNavigateStep: {
    id: 'insights.summaryEmailEnabledNavigateStep',
    defaultMessage: '<link>{icon} Open Internal Team Emails</link>',
  },
  summaryEmailEnabledSuggestedStepsNote: {
    id: 'insights.summaryEmailEnabledSuggestedStepsNote',
    defaultMessage:
      'Note: If you select <strong>Assign to Sevenrooms Support</strong>, please specify which users or Access Levels should receive this email',
  },
  suggestedActionsNoSeatingAreaNavigateStep: {
    id: 'insights.suggestedActionsNoSeatingAreaNavigateStep',
    defaultMessage: '<link>{icon} Open Table Settings</link>',
  },
  suggestedActionsNoSeatingAreaAssignStep: {
    id: 'insights.suggestedActionsNoSeatingAreaAssignStep',
    defaultMessage: 'Assign each unassigned table to a Seating Area',
  },
  suggestedActionsSaveChangesStep: {
    id: 'insights.suggestedActionsSaveChangesStep',
    defaultMessage: 'Click <strong>Save Changes</strong>',
  },
  suggestedActionsNoSeatingAreaProofLabel: {
    id: 'insights.suggestedActionsNoSeatingAreaProofLabel',
    defaultMessage: 'Tables without Seating Areas',
  },
  suggestedActionsNoSeatingAreaSlideoutDescription: {
    id: 'insights.suggestedActionsNoSeatingAreaSlideoutDescription',
    defaultMessage: `<p>Shifts control which Seating Areas are bookable. When Tables are not assigned to a Seating Area, they are
    <strong>excluded</strong> which can lead to “No tables available” showing even when you have Tables open.</p>
    <p>Unless you are purposely excluding a Table from Availability, all Tables should be mapped to Seating Areas in order to maximize your
    bookable Tables.</p>`,
  },
  reducePaidChannelTableHeaderDate: {
    id: 'insights.reducePaidChannelTableHeaderDate',
    defaultMessage: 'Date',
  },
  reducePaidChannelTableHeaderTotalRes: {
    id: 'insights.reducePaidChannelTableHeaderTotalRes',
    defaultMessage: 'Total Reservations Completed',
  },
  reducePaidChannelTableHeaderPaidRes: {
    id: 'insights.reducePaidChannelTableHeaderPaidRes',
    defaultMessage: 'Paid Reservations Completed',
  },
  reducePaidChannelTableHeaderNoAvaila: {
    id: 'insights.reducePaidChannelTableHeaderNoAvaila',
    defaultMessage: 'Searches without nearby Availability',
  },
  reducePaidChannelTableHeaderPotential25: {
    id: 'insights.reducePaidChannelTableHeaderPotential25',
    defaultMessage: 'Potential Direct Replacement Rate (25% of searches)',
  },
  reducePaidChannelTableHeaderPotentialCapped: {
    id: 'insights.reducePaidChannelTableHeaderPotentialCapped',
    defaultMessage: 'Potential Direct Replacement Reservations (capped)',
  },
  reducePaidChannelTableHeaderInfo: {
    id: 'insights.reducePaidChannelTableHeaderInfo',
    defaultMessage: 'Additional Info',
  },
  noSeatingAreaTableHeaderTableNum: {
    id: 'insights.noSeatingAreaTableHeaderTableNum',
    defaultMessage: 'Table #',
  },
  noSeatingAreaTableHeaderImpactedFloorplans: {
    id: 'insights.noSeatingAreaTableHeaderImpactedFloorplans',
    defaultMessage: 'Impacted Floorplans',
  },
  suggestedActionsAddEventsNavigateStep: {
    id: 'insights.suggestedActionsAddEventsNavigateStep',
    defaultMessage: '{icon} Open Events Settings',
  },
  suggestedActionsFollowInstructionsStep: {
    id: 'insights.suggestedActionsFollowInstructionsStep',
    defaultMessage: 'Follow the instructions in this <link>{icon} Help Center Article</link>:',
  },
  suggestedActionsAddEventsCreateEventStep: {
    id: 'insights.suggestedActionsAddEventsCreateEventStep',
    defaultMessage: 'Create an Event',
  },
  suggestedActionsAddEventsAddInventoryStep: {
    id: 'insights.suggestedActionsAddEventsAddInventoryStep',
    defaultMessage: 'Add Inventory',
  },
  suggestedActionsAddEventsReviewStep: {
    id: 'insights.suggestedActionsAddEventsReviewStep',
    defaultMessage: 'Ensure everything looks correct on your <link>{icon} Events Widget</link>',
  },
  addEventsProofLabel: {
    id: 'insights.addEventsProofLabel',
    defaultMessage: 'Events Status',
  },
  addEventsProofTitle: {
    id: 'insights.addEventsProofTitle',
    defaultMessage: 'Events (Next 31 Days)',
  },
  addEventsSlideoutDescription: {
    id: 'insights.addEventsSlideoutDescription',
    defaultMessage: `<p>Hosting events is a great way to connect and engage with your customers in new ways. Events also provide a new
    revenue stream for your business.</p>
    <p>Events are flexible and can be configured as one-time or recurring.</p>
    <p>For more information on how to configure Events and how to add the Events Widget to your website, view this
    <link>Help Center Article.</link></p>`,
  },
  suggestedActionsNoTableCombinationsProofLabel: {
    id: 'insights.suggestedActionsNoTableCombinationsProofLabel',
    defaultMessage: '# of Combinations per Layout',
  },
  suggestedActionsNoTableCombinationsSlideoutDescription: {
    id: 'insights.suggestedActionsNoTableCombinationsSlideoutDescription',
    defaultMessage: `<p>Typically restaurants have tables that can be combined to accommodate larger parties as needed. Configuring these
    in SevenRooms unlocks more bookings by taking potential combinations into account.</p>
    <p>Our Auto-assign algorithm will always try to use stand-alone Tables first before recommending a combination.</p>`,
  },
  suggestedActionsTableCombinationNavigateStep: {
    id: 'insights.suggestedActionsTableCombinationNavigateStep',
    defaultMessage: '<link>{icon} Open Table Combinations</link>',
  },
  suggestedActionsAddCombinationStep: {
    id: 'insights.suggestedActionsAddCombinationStep',
    defaultMessage: 'Tap the Tables that can be combined and hit <strong>Add Combination</strong>',
  },
  suggestedActionsFillAndAddStep: {
    id: 'insights.suggestedActionsFillAndAddStep',
    defaultMessage: 'Fill out the requested information and click <strong>Add</strong>',
  },
  suggestedActionsRepeatStep: {
    id: 'insights.suggestedActionsRepeatStep',
    defaultMessage: 'Repeat until all Combinations have been added',
  },
  suggestedActionsAddUpgradesNavigateStep: {
    id: 'insights.suggestedActionsAddUpgradesNavigateStep',
    defaultMessage: '<link>{icon} Open Upgrades</link>',
  },
  suggestedActionsAddUpgradesAddUpgradeCategoryStep: {
    id: 'insights.suggestedActionsAddUpgradesAddUpgradeCategoryStep',
    defaultMessage: 'Add one or more Upgrade Category',
  },
  suggestedActionsAddUpgradesAddUpgradeOptionStep: {
    id: 'insights.suggestedActionsAddUpgradesAddUpgradeOptionStep',
    defaultMessage: 'Add one or more Upgrade options',
  },
  suggestedActionsAddUpgradesAvailabilityStep: {
    id: 'insights.suggestedActionsAddUpgradesAvailabilityStep',
    defaultMessage: 'Make your Upgrades available through Shifts or Access Rules',
  },
  suggestedActionsAddUpgradesAutoStep: {
    id: 'insights.suggestedActionsAddUpgradesAutoStep',
    defaultMessage: 'To set your Upgrade(s) to be automatically included in Access Rules for that Shift:',
  },
  suggestedActionsAddUpgradesAutoEditShiftStep: {
    id: 'insights.suggestedActionsAddUpgradesAutoEditShiftStep',
    defaultMessage: 'Select a Shift and click <strong>Edit</strong> then <strong>Edit all days</strong>',
  },
  suggestedActionsAddUpgradesToShiftStep: {
    id: 'insights.suggestedActionsAddUpgradesToShiftStep',
    defaultMessage:
      'Under <strong>PERKS & UPGRADES</strong>, set <strong>Add Selectable Upgrades</strong> to <strong>Yes</strong>, select the desired Upgrade Categories and <strong>Save</strong>',
  },
  suggestedActionsAddUpgradesToAccessRuleStep: {
    id: 'insights.suggestedActionsAddUpgradesToAccessRuleStep',
    defaultMessage:
      'To make your Upgrade(s) optionally available through certain Access Rules, follow the instructions in this <link>{icon} Help Center article</link>',
  },
  suggestedActionsAddUpgradesBundledUpgradesStep: {
    id: 'insights.suggestedActionsAddUpgradesBundledUpgradesStep',
    defaultMessage:
      'Alternatively you can automatically include Upgrades by adding them under Bundled Upgrades within the <link>{icon} Payment & Policy</link> configuration of an Access Rule',
  },
  addUpgradesProofLabel: {
    id: 'insights.addUpgradesProofLabel',
    defaultMessage: 'Upgrades Status',
  },
  addUpgradesProofTitle: {
    id: 'insights.addUpgradesProofTitle',
    defaultMessage: 'Upgrades',
  },
  addUpgradesSlideoutDescription: {
    id: 'insights.addUpgradesSlideoutDescription',
    defaultMessage: `<p>Leveraging Upgrades is one of the easiest ways to unlock revenue before guests.</p>
    <p>Upgrades allow guests to customize their reservation with add-ons that enhance their dining experience and boost spend.
    Upgrades can be used to offer wine pairings, prix-fixe menus, kitchen tours, merchandise and more.</p>
    <p>For more information on how to configure Upgrades, view this <link>Help Center Article.</link></p>`,
  },
  suggestedActionsOpenGuestEmailSmsStep: {
    id: 'insights.suggestedActionsOpenGuestEmailSmsStep',
    defaultMessage: '{icon} Open Guest Email / SMS Settings',
  },
  suggestedActionsFeedbackSurveysToggleStep: {
    id: 'insights.suggestedActionsFeedbackSurveysToggleStep',
    defaultMessage: 'Toggle on <strong>Guest Feedback Emails</strong>',
  },
  suggestedActionsFeedbackSurveysSelectTimeStep: {
    id: 'insights.suggestedActionsFeedbackSurveysSelectTimeStep',
    defaultMessage: 'Check the box next to the Shift Category you want to send a survey for',
  },
  suggestedActionsFeedbackSurveysEnableSummaryEmailStep: {
    id: 'insights.suggestedActionsFeedbackSurveysEnableSummaryEmailStep',
    defaultMessage:
      'Under <link>{icon} Internal Team Emails</link>, ensure <strong>Feedback Summary Email - Internal</strong> is toggled on so your team receives a summary of feedback on a regular basis',
  },
  suggestedActionsFeedbackSurveysEnableUserSummaryEmailStep: {
    id: 'insights.suggestedActionsFeedbackSurveysEnableUserSummaryEmailStep',
    defaultMessage:
      'Under <link>{icon} User accounts</link>, ensure that each user you would like to receive the email has Automated Feedback Summary Email Subscription checked',
  },
  feedbackSurveysProofLabel: {
    id: 'insights.feedbackSurveysProofLabel',
    defaultMessage: 'Feedback Survey Status',
  },
  feedbackSurveysProofTitle: {
    id: 'insights.feedbackSurveysProofTitle',
    defaultMessage: 'Feedback Surveys',
  },
  feedbackSurveysSlideoutDescription: {
    id: 'insights.suggestedActionsFeedbackSurveysSlideoutDescription',
    defaultMessage: `<p>Be the first to know how it went. SevenRooms’ Guest Feedback Survey sends an automated email to each guest after
    their visit, allowing you to collect feedback instantly and at scale, while also gaining valuable insights to improve operations and
    avoiding negative feedback on public sites.</p>
    <p>For more information on how to configure Feedback Surveys, view this <link>Help Center Article.</link></p>`,
  },
  noTableCombinationsHeaderLayoutName: {
    id: 'insights.noTableCombinationsHeaderLayoutName',
    defaultMessage: 'Layout Name',
  },
  noTableCombinationsHeaderNumCombo: {
    id: 'insights.noTableCombinationsHeaderNumCombo',
    defaultMessage: '# of Combinations',
  },
  suggestedActionsLinkChecksHostTrainingStep: {
    id: 'insights.suggestedActionsLinkChecksHostTrainingStep',
    defaultMessage:
      'Host a training with your host team about the importance of linking checks to reservations, keeping in mind some tips and tricks:',
  },
  suggestedActionsLinkChecksIconDisplayTip: {
    id: 'insights.suggestedActionsLinkChecksIconDisplayTip',
    defaultMessage: 'Once a check is linked to a reservation a {icon} icon will appear on the reservation cell on the mobile app',
  },
  suggestedActionsLinkChecksMissedLinkTip: {
    id: 'insights.suggestedActionsLinkChecksMissedLinkTip',
    defaultMessage:
      'If you mark a reservation as left without linking a check, the reservation will be shaded red indicating a missed opportunity to link a check',
  },
  lowLinkedCheckPercentageLabel: {
    id: 'insights.linkChecksProofLabel',
    defaultMessage: 'POS Check Link % (Last 30 Days)',
  },
  lowLinkedCheckPercentageNotFound: {
    id: 'insights.lowLinkedCheckPercentageNotFound',
    defaultMessage: 'POS Check Link percentage not found',
  },
  linkChecksSlideoutDescription: {
    id: 'insights.suggestedActionsLinkChecksSlideoutDescription',
    defaultMessage: `<p>Unlock the full potential of your guest experience with by fully utilizing your POS Integration and linking the
    majority of your checks to reservations. By linking these two crucial datapoints, you gain valuable insights such as tracking overall
    spend, spend per guest, and determining favorite POS items by guest. This data-driven approach allows you to make informed decisions,
    personalize experiences, and maximize guest satisfaction, revolutionizing the way you deliver hospitality.</p>
    <p>Many of our POS integrations automatically link checks on behalf of your team. If you think there may an error causing your low
    linkage rate, please <link>Contact Support</link></p>`,
  },
  suggestedActionNavigatetoAccessRulesText: {
    id: 'insights.suggestedActionNavigatetoAccessRulesText',
    defaultMessage: '{icon} Open Access Rules',
  },
  suggestedActionGoogleReserveAvailabilityClickRuleStep: {
    id: 'insights.suggestedActionGoogleReserveAvailabilityClickRuleStep',
    defaultMessage: 'Click on the “{accessRuleName}” Access Rule',
  },
  suggestedActionGoogleReserveAvailabilityEditDaysStep: {
    id: 'insights.suggestedActionGoogleReserveAvailabilityEditDaysStep',
    defaultMessage: 'Select <strong>Edit</strong>, then <strong>Edit All Days</strong>',
  },
  suggestedActionGoogleReserveAvailabilityAddAudienceStep: {
    id: 'insights.suggestedActionGoogleReserveAvailabilityAddAudienceStep',
    defaultMessage: 'Add the Google Reserve Audience:',
  },
  suggestedActionNavigateToAudienceStep: {
    id: 'insights.suggestedActionNavigateToAudienceStep',
    defaultMessage: 'Navigate to <strong>Booking Channels and Audiences</strong>',
  },
  suggestedActionGoogleReserveAvailabilityUpdateAudienceStep: {
    id: 'insights.suggestedActionGoogleReserveAvailabilityUpdateAudienceStep',
    defaultMessage:
      'Update the Audience to <strong>Direct Booking Channels</strong> or add <strong>Google Reserve</strong>, click <strong>Done</strong>',
  },
  suggestedActionUpdateGuestFacingDisplayStep: {
    id: 'insights.suggestedActionUpdateGuestFacingDisplayStep',
    defaultMessage: 'Update Guest-Facing Display:',
  },
  suggestedActionNavigateToGuestFacingDisplayStep: {
    id: 'insights.suggestedActionNavigateToGuestFacingDisplayStep',
    defaultMessage: 'Navigate to <strong>Guest-Facing Display</strong>',
  },
  suggestedActionAllowGuestFacingDisplayStep: {
    id: 'insights.suggestedActionAllowGuestFacingDisplayStep',
    defaultMessage:
      'Check <strong>Allow this rule to be booked on channels that do not support any Guest-Facing Display fields</strong>, click <strong>Done</strong>',
  },
  suggestedActionNavigateToSeatingAreasStep: {
    id: 'insights.suggestedActionNavigateToSeatingAreasStep',
    defaultMessage: 'If the Time Slot Description includes a Seating Area, navigate to <strong>Seating Areas and Tables</strong>',
  },
  suggestedActionGoogleReserveAvailabilitySelectSeatingAreOptionStep: {
    id: 'insights.suggestedActionGoogleReserveAvailabilitySelectSeatingAreOptionStep',
    defaultMessage: 'Select an option under <strong>Google Reserve Seating Area</strong>, click <strong>Done</strong>',
  },
  googleReserveAvailabilityDescription: {
    id: 'insights.googleReserveAvailabilityDescription',
    defaultMessage: `<p>Google Reserve can significantly increase direct bookings. To display reservations on Google, an Access Rule
    must:</p>
    <p>
      <ul>
        <li>Include Google Reserve in the Audience</li>
        <li>Not require prepayment</li>
        <li>Exclude Guest-Facing Display or be configured to allow bookings on channels that do not support these fields</li>
      </ul>
    </p>
    <p>If you have intentionally excluded this Access Rule from Google Reserve because it offers an Experience, is intended for a select
    Audience or some other reason, decline this Insight. Otherwise, follow the steps below to share reservations with Google Reserve.</p>`,
  },
  googleReserveAvailabilityTableHeaderCurrentAudiences: {
    id: 'insights.googleReserveAvailabilityTableHeaderCurrentAudiences',
    defaultMessage: 'Current Audiences',
  },
  googleReserveAvailabilityTableHeaderGoogleIncluded: {
    id: 'insights.googleReserveAvailabilityTableHeaderGoogleIncluded',
    defaultMessage: 'Google Included',
  },
  googleReserveAvailabilityTableHeaderSuggestedStep: {
    id: 'insights.googleReserveAvailabilityTableHeaderAudienceSuggestedStep',
    defaultMessage: 'Suggested Step',
  },
  googleReserveAvailabilityTableHeaderPublicDescription: {
    id: 'insights.googleReserveAvailabilityTableHeaderPublicDescription',
    defaultMessage: 'Public Description',
  },
  googleReserveAvailabilityTableHeaderAllowDisplay: {
    id: 'insights.googleReserveAvailabilityTableHeaderAllowDisplay',
    defaultMessage: 'Allow Display on Google without Public Description shown',
  },
  googleReserveAvailabilityTableHeaderSeatingArea: {
    id: 'insights.googleReserveAvailabilityTableHeaderSeatingArea',
    defaultMessage: 'Google Reserve Seating Area',
  },
  googleReserveAvailabilityAudienceProofLabel: {
    id: 'insights.googleReserveAvailabilityAudienceProofLabel',
    defaultMessage: 'Audience Compatibility',
  },
  googleReserveAvailabilityPublicDescriptionProofLabel: {
    id: 'insights.googleReserveAvailabilityPublicDescriptionProofLabel',
    defaultMessage: 'Public Description Compatibility',
  },
  suggestedActionUpgradeCoverageNavToShiftsStep: {
    id: 'insights.suggestedActionUpgradeCoverageNavToShiftsStep',
    defaultMessage: 'Some Access Rules are following the Upgrades configured on the Shift. {link}',
  },
  suggestedActionUpgradeCoverageAddShiftUpgradesStep: {
    id: 'insights.suggestedActionUpgradeCoverageAddShiftUpgradesStep',
    defaultMessage:
      'For Each Shift below, click the Shift and click <strong>Edit All Days</strong>, navigate to <strong>Perks & Upgrades</strong> and add the appropriate default upgrades and save all changes:',
  },
  suggestedActionUpgradeCoverageShiftNameStep: {
    id: 'insights.suggestedActionUpgradeCoverageShiftNameStep',
    defaultMessage: '<strong>{shiftName}</strong> Shift',
  },
  suggestedActionUpgradeCoverageNavToAccessRulesStep: {
    id: 'insights.suggestedActionUpgradeCoverageNavToAccessRulesStep',
    defaultMessage: 'Some Access Rules are not following the Shift and need to be manually updated. {link}',
  },
  suggestedActionUpgradeCoverageAddAccessRuleUpgradesStep: {
    id: 'insights.suggestedActionUpgradeCoverageAddAccessRuleUpgradesStep',
    defaultMessage:
      'For Each Access Rule below, click the rule and click <strong>Edit All Days</strong> and navigate to <strong>Selectable Upgrades</strong> and add the appropriate upgrades and save all changes:',
  },
  suggestedActionUpgradeCoverageAccessRuleNameStep: {
    id: 'insights.suggestedActionUpgradeCoverageAccessRuleNameStep',
    defaultMessage: '<strong>{accessRuleName}</strong> Access Rule',
  },
  upgradeCoverageTableHeaderShiftName: {
    id: 'insights.upgradeCoverageTableHeaderShiftName',
    defaultMessage: 'Shift Name',
  },
  upgradeCoverageTableHeaderNumAccessRules: {
    id: 'insights.upgradeCoverageTableHeaderNumAccessRules',
    defaultMessage: 'Number of Access Rules following Shift',
  },
  upgradeCoverageTableHeaderAccessRules: {
    id: 'insights.upgradeCoverageTableHeaderAccessRules',
    defaultMessage: 'Access Rules following Shift',
  },
  upgradeCoverageTableHeaderSummary: {
    id: 'insights.upgradeCoverageTableHeaderSummary',
    defaultMessage: 'Summary',
  },
  upgradeCoverageTableHeaderAccessRuleName: {
    id: 'insights.upgradeCoverageTableHeaderAccessRuleName',
    defaultMessage: 'Access Rule Name',
  },
  upgradeCoverageTableHeaderFollowingShift: {
    id: 'insights.upgradeCoverageTableHeaderFollowingShift',
    defaultMessage: 'Following Shift Upgrades',
  },
  upgradeCoverageShiftProofLabel: {
    id: 'insights.upgradeCoverageShiftProofLabel',
    defaultMessage: 'Shifts without Upgrades Configured',
  },
  upgradeCoverageAccessRuleProofLabel: {
    id: 'insights.upgradeCoverageAccessRuleProofLabel',
    defaultMessage: 'Access Rules without Upgrades Configured',
  },
  upgradeCoverageAvailabilityDescription: {
    id: 'insights.upgradeCoverageAvailabilityDescription',
    defaultMessage: `<p>Leveraging Upgrades is one of the easiest ways to unlock revenue before guests arrive.</p>
    <p>Upgrades allow guests to customize their reservation with add-ons that enhance their dining experience and boost spend.
    Upgrades can be used to offer wine pairings, prix-fixe menus, kitchen tours, merchandise and more.</p>
    <p>For more information on how to configure Upgrades, view this <link>Help Center Article.</link></p>
    <p>You currently have Upgrades configured, but some Access Rules that are not configured to display those upgrade options to guests.
    Set yourself up for success by properly configuring all of your external availability to offer Upgrades.</p>`,
  },
  pacingCoverageDescription: {
    id: 'insights.pacingCoverageDescription',
    defaultMessage: `<p>Pacing is a valuable lever in ensuring your FOH and BOH teams aren’t overwhelmed with too many guests, but may
    prevent guests from booking reservations when there is capacity.</p>
    <p>To calculate your venue’s Max Cover Capacity, we evaluate a scenario where every one of the active tables in your Shift  is sat
    at the time of your first seating and turns as many times as possible, keeping in mind the Durations you have configured for that
    table size. While this number may not be operationally attainable, it helps create a Pacing baseline.</p>
    <p>We then calculate the Total Shift Pacing configured for your Shift and ask: given your Pacing configuration, would you  come
    close to reaching your maximum capacity?</p>
    <p>Overly restrictive Pacing is even more impactful if you are turning away diners on your Reservation Widget.</p>
    <p>This Shift requires a Pacing increase. Review the data below and consider adding more Pacing across this Shift.</p>`,
  },
  assignedUserLabel: {
    id: 'insights.assignedUserLabel',
    defaultMessage: 'Assigned to',
  },
  assignedUserDescription: {
    id: 'insights.assignedUserDescription',
    defaultMessage: 'Responsible for making the suggested action',
  },
  followersLabel: {
    id: 'insights.followersLabel',
    defaultMessage: 'Followers',
  },
  followersDescription: {
    id: 'insights.followersDescription',
    defaultMessage: 'Receive updates on the status of this insight',
  },
  activityCreated: {
    id: 'insights.activityCreated',
    defaultMessage: 'Created New Insight',
  },
  activityChangedStatus: {
    id: 'insights.activityChangedStatus',
    defaultMessage:
      'Updated Insight status from {oldStatus} to {newStatus} {hasNote, select, true {with additional note of: {note}} other {}}',
  },
  activityAssigned: {
    id: 'insights.activityAssigned',
    defaultMessage: 'Assigned Insight to {assignee}',
  },
  activityArchived: {
    id: 'insights.activityArchived',
    defaultMessage: 'Archived Insight',
  },
  addComment: {
    id: 'insights.addComment',
    defaultMessage: 'Add comment',
  },
  insightSaveSuccessMessage: {
    id: 'insights.insightSaveSuccessMessage',
    defaultMessage: 'Insight successfully updated',
  },
  insightSaveErrorMessage: {
    id: 'insights.insightSaveErrorMessage',
    defaultMessage: 'Unable to save changes to insight',
  },
  insightCommentErrorMessage: {
    id: 'insights.insightCommentErrorMessage',
    defaultMessage: 'Unable to add comment',
  },
  insightWaiting: {
    id: 'insights.insightsWaiting',
    defaultMessage: 'Revenue-Driving Insights Waiting',
  },
  newInsightWaiting: {
    id: 'insights.newInsightsWaiting',
    defaultMessage: 'New Revenue-Driving Insights Waiting',
  },
  insightUpsell: {
    id: 'insights.insightUpsell',
    defaultMessage:
      'Your package only includes basic Insights. The Insights in the Growth and Premium package help grow covers and revenue using advanced revenue management techniques. These Insights help optimize Pacing, Durations and more. Contact your Customer Success Manager or Support to upgrade.',
  },
  highlightHeadlineGoogleReserve: {
    id: 'insights.highlightHeadlineGoogleReserve',
    defaultMessage: 'Maximize Free Covers: Activate Google Reserve',
  },
  highlightHeadlineAllocation: {
    id: 'insights.highlightHeadlineAllocation',
    defaultMessage: 'Boost Turnover: Optimize Duration Settings',
  },
  highlightHeadlineFeaturePayments: {
    id: 'insights.highlightHeadlineFeaturePayments',
    defaultMessage: 'Secure Revenue: Activate a Payment Processor',
  },
  highlightHeadlinePacing: {
    id: 'insights.highlightHeadlinePacing',
    defaultMessage: 'Unleash Cover Potential: Amp Up Pacing',
  },
  highlightHeadlineCancellationFee: {
    id: 'insights.highlightHeadlineCancellationFee',
    defaultMessage: 'Combat No-Shows: Fine-Tune Cancellation Policies',
  },
  highlightHeadlineFeaturePriorityAlerts: {
    id: 'insights.highlightHeadlineFeaturePriorityAlerts',
    defaultMessage: 'Seize Opportunities: Activate Priority Alerts',
  },
  highlightHeadlineFeaturePosEnabled: {
    id: 'insights.highlightHeadlineFeaturePosEnabled',
    defaultMessage: 'Personalize & Profit: Integrate POS for Enhanced Experiences',
  },
  highlightHeadlineFeaturePosLinkage: {
    id: 'insights.highlightHeadlineFeaturePosLinkage',
    defaultMessage: 'Unlock Insights: Maximize POS Linking',
  },
  highlightHeadlineFeatureResSummary: {
    id: 'insights.highlightHeadlineFeatureResSummary',
    defaultMessage: 'Streamline Prep: Activate Reservation Summaries',
  },
  highlightHeadlineFeatureLogo: {
    id: 'insights.highlightHeadlineFeatureLogo',
    defaultMessage: 'Elevate Brand Presence: Add a Logo for Impact',
  },
  highlightHeadlineFeatureUpcomingEvent: {
    id: 'insights.highlightHeadlineFeatureUpcomingEvent',
    defaultMessage: 'Unlock Event Revenue: Harness the Events Feature',
  },
  highlightHeadlineTablesWithoutArea: {
    id: 'insights.highlightHeadlineTablesWithoutArea',
    defaultMessage: 'Maximize Availability: Allocate Tables for Seamless Reservations',
  },
  highlightHeadlineRulesWithoutUpgrades: {
    id: 'insights.highlightHeadlineRulesWithoutUpgrades',
    defaultMessage: 'Increase Revenue: Add Upgrades',
  },
  highlightHeadlineFeatureFeedbackSummary: {
    id: 'insights.highlightHeadlineFeatureFeedbackSummary',
    defaultMessage: 'Elevate Service: Enable Feedback Summary',
  },
  highlightHeadlineFeatureUpgrades: {
    id: 'insights.highlightHeadlineFeatureUpgrades',
    defaultMessage: 'Increase Revenue: Activate Upgrades',
  },
  highlightHeadlineFeatureRequests: {
    id: 'insights.highlightHeadlineFeatureRequests',
    defaultMessage: 'Fill Tables: Activate Requests',
  },
  highlightHeadlineNoCombination: {
    id: 'insights.highlightHeadlineNoCombination',
    defaultMessage: 'Maximize Flexibility: Activate Table Combinations',
  },
  highlightHeadlineFeatureUsersCreated: {
    id: 'insights.highlightHeadlineFeatureUsersCreated',
    defaultMessage: 'Unlock Full Potential: Bring More of Your Team Into SevenRooms',
  },
  highlightHeadlineFeatureUsageSurveys: {
    id: 'insights.highlightHeadlineFeatureUsageSurveys',
    defaultMessage: 'Enhance Service: Activate Feedback Surveys',
  },
  viewMoreInsights: {
    id: 'insights.viewMoreInsights',
    defaultMessage: 'View More Insights',
  },
  priorityInsights: {
    id: 'insights.priorityInsights',
    defaultMessage: 'Priority Insights',
  },
  priorityDescription: {
    id: 'insights.priorityDescription',
    defaultMessage: 'These insights will have the highest impact on your business this month',
  },
  insightsWithCounter: {
    id: 'insights.insightsWithCounter',
    defaultMessage: '{counter} {counter, plural, one {Insight} other {Insights}} Resolved',
  },
  getStarted: {
    id: 'insights.getStarted',
    defaultMessage: '<p>Get started with</p><p>your priority insights</p>',
  },
  goodStart: {
    id: 'insights.goodStart',
    defaultMessage: 'Good start!',
  },
  keepItUp: {
    id: 'insights.keepItUp',
    defaultMessage: 'Keep it up!',
  },
  niceJob: {
    id: 'insights.niceJob',
    defaultMessage: 'Nice job!',
  },
  topInsights: {
    id: 'insights.topInsights',
    defaultMessage: 'Top Insights',
  },
} as const)
