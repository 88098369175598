import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { css } from 'styled-components'
import {
  selectClientTagsOptionsList,
  selectReservationTagsOptionsList,
  selectValueObjectOptionsMap,
  selectVenueServerOptions,
  selectSeatingAreaOptions,
  selectVenueNameOptions,
  selectStatusOptions,
  selectBookedByUsers,
} from 'mgr/pages/single-venue/marketing/selectors/Autotags'
import CurrencyInput from 'svr/component-lib/Generic/TextInputs/CurrencyInput'
import PositiveIntInput from 'svr/component-lib/Generic/TextInputs/PositiveIntInput'
import CustomAutotagBooleanDropDown from './CustomAutotagBooleanDropDown'
import CustomAutotagDateDelta from './CustomAutotagDateDelta'
import CustomAutotagDateRangePicker from './CustomAutotagDateRangePicker'
import CustomAutotagDuration from './CustomAutotagDuration'
import { CustomAutotagTimesNumberComponent, CustomAutotagPercentageNumberComponent } from './CustomAutotagNumberComponents'
import CustomAutotagSearchDropdownMulti from './CustomAutotagSearchDropdownMulti'
import CustomAutotagSearchDropdownMultiForWaitlistStatus from './CustomAutotagSearchDropdownMultiForWaitlistStatus'
import { CustomAutotagSearchDropdownSingle } from './CustomAutotagSearchDropdownSingle'
import CustomAutotagSearchDropdownTags from './CustomAutotagSearchDropdownTags'
import CustomAutotagStringComponent from './CustomAutotagStringComponent'
import CustomAutotagTimeRangePicker from './CustomAutotagTimeRangePicker'

const VALUE_TYPES = {
  ANY_DATE: 'ANY_DATE',
  IS_ANYTHING: 'IS_ANYTHING',
  CURRENCY: 'CURRENCY',
  DATE_DELTA: 'DATE_DELTA',
  DATE_RANGE: 'DATE_RANGE',
  DURATION: 'DURATION',
  ENUM_BOOKED_BY: 'ENUM_BOOKED_BY',
  ENUM_CLIENT_TAGS: 'ENUM_CLIENT_TAGS',
  ENUM_GENDER: 'ENUM_GENDER',
  ENUM_MONTH: 'ENUM_MONTH',
  ENUM_ORDER_METHOD: 'ENUM_ORDER_METHOD',
  ENUM_ORDER_STATUS: 'ENUM_ORDER_STATUS',
  ENUM_ORDER_SOURCE_SYSTEM: 'ENUM_ORDER_SOURCE_SYSTEM',
  ENUM_RESERVATION_STATUS: 'ENUM_RESERVATION_STATUS',
  ENUM_RESERVATION_TAGS: 'ENUM_RESERVATION_TAGS',
  ENUM_SEATING_AREA: 'ENUM_SEATING_AREA',
  ENUM_SHIFT_CATEGORY: 'ENUM_SHIFT_CATEGORY',
  ENUM_VENUE_NAME: 'ENUM_VENUE_NAME',
  ENUM_VENUE_SERVER: 'ENUM_VENUE_SERVER',
  ENUM_FEEDBACK_RATING: 'ENUM_FEEDBACK_RATING',
  BOOLEAN: 'BOOLEAN',
  NTIMES: 'NTIMES',
  NUMBER: 'NUMBER',
  PERCENTAGE: 'PERCENTAGE',
  STRING: 'STRING',
  TIME: 'TIME',
  ENUM_REVIEW_RATING: 'ENUM_REVIEW_RATING',
  ENUM_REVIEW_PLATFORM: 'ENUM_REVIEW_PLATFORM',
  ENUM_WAITLIST_STATUS: 'ENUM_WAITLIST_STATUS',
}

const CustomAutotagConfigValueSwitch = ({
  id,
  viewID,
  locale,
  value,
  onChange,
  fieldType,
  isGlobal,
  isConfigStateValid,
  invalidMessage,
  ...props
}) => {
  const field = 'value'
  let valueComponent
  const commonDropdownMultiProps = {
    id,
    viewID,
    selectedValue: value,
    field,
    onChange,
    isSearchable: true,
    isValid: isConfigStateValid,
    invalidMessage,
    customWrapper: css`
      width: 495px;
    `,
  }
  switch (fieldType) {
    case VALUE_TYPES.ANY_DATE:
      valueComponent = null
      break
    case VALUE_TYPES.IS_ANYTHING:
      valueComponent = null
      break
    case VALUE_TYPES.CURRENCY:
      valueComponent = (
        <CurrencyInput
          inputId={id}
          field={field}
          value={value}
          currencySymbol={isGlobal ? '$' : props.currencySymbol}
          onChange={onChange}
          customInputWrapper={css`
            height: 35px;
            width: 110px;
          `}
          customInput={css`
            height: 35px;
            border-width: 1px;
            border-color: #dbdcde;
            outline: none;
            font-size: 14px;
          `}
          customCurrencySymbol={css`
            width: auto;
            padding: 0 4px;
          `}
        />
      )
      break
    case VALUE_TYPES.DATE_DELTA:
      valueComponent = (
        <CustomAutotagDateDelta id={id} value={value} onChange={onChange} isValid={_.isNumber(value)} invalidMessage="Value Required" />
      )
      break
    case VALUE_TYPES.DATE_RANGE:
      valueComponent = <CustomAutotagDateRangePicker id={id} value={value} onChange={onChange} locale={locale} />
      break
    case VALUE_TYPES.DURATION:
      valueComponent = <CustomAutotagDuration id={id} value={value} onChange={onChange} />
      break
    case VALUE_TYPES.ENUM_BOOKED_BY:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.bookedByUserOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a booked by user..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_CLIENT_TAGS:
      valueComponent = (
        <CustomAutotagSearchDropdownTags
          id={id}
          viewID={viewID}
          selectedValue={value}
          options={props.clientTagsOptionsList}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Search tags"
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
        />
      )
      break
    case VALUE_TYPES.ENUM_GENDER:
      valueComponent = (
        <CustomAutotagSearchDropdownSingle
          id={id}
          viewID={viewID}
          isSearchable={false}
          options={props.genderOptions}
          selectedValue={value}
          onChange={onChange}
        />
      )
      break
    case VALUE_TYPES.ENUM_MONTH:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.monthOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a month..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_ORDER_METHOD:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.orderMethodOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select an order type..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_ORDER_STATUS:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.orderStatusOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select an order status..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_ORDER_SOURCE_SYSTEM:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.orderSystemOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select an order delivery channel..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_RESERVATION_STATUS:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.statusOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a reservation status..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_RESERVATION_TAGS:
      valueComponent = (
        <CustomAutotagSearchDropdownTags
          id={id}
          viewID={viewID}
          selectedValue={value}
          options={props.reservationTagsOptionsList}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Search tags"
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
        />
      )
      break
    case VALUE_TYPES.ENUM_SEATING_AREA:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.seatingAreaOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a seating area..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_SHIFT_CATEGORY:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.shiftCategoryOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a shift category..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_VENUE_NAME:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.venueNameOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a venue..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_VENUE_SERVER:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.venueServerOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a server..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_REVIEW_RATING:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti options={props.reviewRateOptions} placeholder="Select a rate..." {...commonDropdownMultiProps} />
      )
      break
    case VALUE_TYPES.ENUM_WAITLIST_STATUS:
      valueComponent = (
        <CustomAutotagSearchDropdownMultiForWaitlistStatus
          options={props.waitlistStatusOptions}
          placeholder="Select a status..."
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_REVIEW_PLATFORM:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          options={props.reviewPlatformOptions}
          placeholder="Select a platform..."
          {...commonDropdownMultiProps}
        />
      )
      break
    case VALUE_TYPES.ENUM_FEEDBACK_RATING:
      valueComponent = (
        <CustomAutotagSearchDropdownMulti
          id={id}
          viewID={viewID}
          selectedValue={value}
          options={props.feedbackRateOptions}
          field={field}
          onChange={onChange}
          isSearchable
          placeholder="Select a rating..."
          isValid={isConfigStateValid}
          invalidMessage={invalidMessage}
          customWrapper={css`
            width: 495px;
          `}
        />
      )
      break
    case VALUE_TYPES.BOOLEAN:
      valueComponent = (
        <CustomAutotagBooleanDropDown id={id} viewID={viewID} isSearchable={false} selectedValue={value} onChange={onChange} />
      )
      break
    case VALUE_TYPES.NTIMES:
      valueComponent = (
        <CustomAutotagTimesNumberComponent
          id={id}
          value={value}
          onChange={onChange}
          isValid={_.isNumber(value)}
          invalidMessage="Value Required"
        />
      )
      break
    case VALUE_TYPES.NUMBER:
      valueComponent = (
        <PositiveIntInput
          inputId={id}
          value={value}
          onChange={onChange}
          customInputWrapper={css`
            height: 35px;
            width: 59px;
          `}
          customInput={css`
            height: 35px;
            box-sizing: border-box;
            border-width: 1px;
            border-color: #dbdcde;
            outline: none;
            font-size: 14px;
          `}
        />
      )
      break
    case VALUE_TYPES.PERCENTAGE:
      valueComponent = (
        <CustomAutotagPercentageNumberComponent
          id={id}
          value={value}
          onChange={onChange}
          isValid={_.isNumber(value)}
          invalidMessage="Value Required"
        />
      )
      break
    case VALUE_TYPES.STRING:
      valueComponent = (
        <CustomAutotagStringComponent
          id={id}
          value={value}
          field={field}
          onChange={onChange}
          isConfigStateValid={isConfigStateValid}
          invalidMessage="Value required"
        />
      )
      break
    case VALUE_TYPES.TIME:
      valueComponent = (
        <CustomAutotagTimeRangePicker
          id={id}
          value={value}
          onChange={onChange}
          locale={locale}
          isConfigStateValid={isConfigStateValid}
          invalidMessage="Times should not be the same"
        />
      )
      break
    default:
      // eslint-disable-next-line no-console
      console.error(`CustomAutotagConfigValueSwitch: Missing value type "${fieldType}"`)
      valueComponent = null
  }
  return valueComponent
}

const mapStateToProps = state => {
  const valueOptionsMap = selectValueObjectOptionsMap(state)
  return {
    clientTagsOptionsList: selectClientTagsOptionsList(state),
    reservationTagsOptionsList: selectReservationTagsOptionsList(state),
    venueServerOptions: selectVenueServerOptions(state),
    seatingAreaOptions: selectSeatingAreaOptions(state),
    venueNameOptions: selectVenueNameOptions(state),
    statusOptions: selectStatusOptions(state),
    bookedByUserOptions: selectBookedByUsers(state),
    orderMethodOptions: valueOptionsMap[VALUE_TYPES.ENUM_ORDER_METHOD],
    orderStatusOptions: valueOptionsMap[VALUE_TYPES.ENUM_ORDER_STATUS],
    orderSystemOptions: valueOptionsMap[VALUE_TYPES.ENUM_ORDER_SOURCE_SYSTEM],
    genderOptions: valueOptionsMap[VALUE_TYPES.ENUM_GENDER],
    monthOptions: valueOptionsMap[VALUE_TYPES.ENUM_MONTH],
    shiftCategoryOptions: valueOptionsMap[VALUE_TYPES.ENUM_SHIFT_CATEGORY],
    currencySymbol: state.appState.venue.currencySymbol,
    reviewRateOptions: valueOptionsMap[VALUE_TYPES.ENUM_REVIEW_RATING],
    reviewPlatformOptions: valueOptionsMap[VALUE_TYPES.ENUM_REVIEW_PLATFORM],
    feedbackRateOptions: valueOptionsMap[VALUE_TYPES.ENUM_FEEDBACK_RATING],
    feedbackRecommendOptions: valueOptionsMap[VALUE_TYPES.BOOLEAN],
    waitlistStatusOptions: valueOptionsMap[VALUE_TYPES.ENUM_WAITLIST_STATUS],
  }
}

const mapDispatchToProps = () => ({})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomAutotagConfigValueSwitch)
