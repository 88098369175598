import moment from 'moment-timezone'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { openSearch, openReview, setReviewReply } from 'mgr/pages/single-venue/marketing/actions/Reviews'
import { SingleStarRow, Thumb } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'
import { DisplayArea, SlideoutContent, ActionArea, LinkButton } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { SourceBar, RatingBar, RatingTitle, NumRating, StarSlide, ReviewInfo, ReviewContent } from '../components/Review'
import { setClipboard } from 'mgr/lib/utils/UXUtils'
import { BackwriterMessageLoader } from 'mgr/lib/components/BackwriterMessageLoader'
import ReviewSlideout from './ReviewSlideout'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { GoogleReviewSlideout } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/GoogleReviewSlideout'
import { BRAND_MAPPER, BRAND_MAPPER_NAMES } from 'svr/common/BrandIcon'
import { BackwriterPromptType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useReviewBackwriter } from '@sevenrooms/mgr-core/hooks/useBackwriter'
import { QuickButton } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { reviewsMessages } from '../locales'

const ShowReview = ({ isLinking, review, venue, openSearch, openReview, setReviewReply, copyAndNotify, showError }) => {
  const { formatMessage } = useLocales()
  const isLinked = review.actual_id || review.order_id
  const title = isLinked ? '' : 'Unlinked review'
  const { author, rating, review_site: reviewSite, notes: reviewText } = review
  const date = moment(review.date).format('ddd, MMM DD')
  const ignoreLinkButton = isLinked || review.review_site === 'grubhub'
  const siteName = BRAND_MAPPER_NAMES[reviewSite] || BRAND_MAPPER[reviewSite]

  const { isEnabled, isLoading, generatedText, errorMessage, setGeneratedText, generateResponse } = useReviewBackwriter()

  // Feels hacky; suspect a result
  // of these mixed state and component
  // patterns. May not be necessary
  // once useBackwriter has been
  // fleshed out.
  const [lastReview, setLastReview] = useState(null)
  if (isEnabled && lastReview !== review.id) {
    setLastReview(review.id)
    setGeneratedText('')
  }

  const copyLink = text => {
    copyAndNotify(text, formatMessage(reviewsMessages.responseCopied), formatMessage(reviewsMessages.responseCopyError))
  }

  const generateReviewResponse = () => {
    generateResponse({ venueId: venue.id, reviewId: review.id, promptType: BackwriterPromptType.RespondToReview })
  }

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage)
    }
  }, [showError, errorMessage])

  if (review.source === 'GOOGLEREVIEWS') {
    return (
      <GoogleReviewSlideout
        isLinking={isLinking}
        onClose={() => openReview(null)}
        review={review}
        setReview={setReviewReply}
        showError={showError}
      />
    )
  }

  return (
    <ReviewSlideout title={title}>
      <SlideoutContent>
        <DisplayArea>
          <SourceBar reviewSite={reviewSite} openSearch={openSearch} ignoreLinkButton={ignoreLinkButton} />
          <RatingBar>
            <RatingTitle>Rating</RatingTitle>
            <StarSlide>
              {reviewSite === 'facebook' ? (
                <Thumb starsChecked={rating} />
              ) : (
                <>
                  <NumRating>{rating} of 5</NumRating>
                  <SingleStarRow numStars={5} starsChecked={rating} />
                </>
              )}
            </StarSlide>
          </RatingBar>
          <ReviewInfo
            dangerouslySetInnerHTML={{
              __html: `Review for ${venue.name} submitted on ${date} by ${author}`,
            }}
          />
          <ReviewContent dangerouslySetInnerHTML={{ __html: reviewText }} />
          {isEnabled ? (
            <Box ml="m" mr="m" mb="m">
              {isLoading ? (
                <Box borderRadius="s" p="m" borderColor="borders" overflow="hidden">
                  <BackwriterMessageLoader width="auto" />
                </Box>
              ) : null}
              {generatedText && !isLoading ? (
                <Box>
                  <Box p="s" mb="s" borderRadius="s" borderColor="borders">
                    <Header mb="s" type="h4">
                      Suggested Review Response
                    </Header>
                    <Text type="p" whiteSpace="pre-wrap">
                      {generatedText}
                    </Text>
                  </Box>
                  <Box mb="s" ml="xs">
                    <SecondaryText fontSize="s">Copy this response to {siteName} to respond</SecondaryText>
                  </Box>
                </Box>
              ) : null}

              {!isLoading ? (
                <HStack spacing="s">
                  {generatedText ? (
                    <QuickButton data-test="review-copy-generated-content" icon="VMSWeb-copy" onClick={() => copyLink(generatedText)}>
                      Copy response
                    </QuickButton>
                  ) : null}
                  <QuickButton data-test="review-generate-response" icon="VMSWeb-action-stars" onClick={generateReviewResponse}>
                    {!generatedText ? formatMessage(reviewsMessages.generateResponse) : formatMessage(reviewsMessages.regenerateResponse)}
                  </QuickButton>
                </HStack>
              ) : null}
            </Box>
          ) : null}
        </DisplayArea>
        {!isLinking && !venue.isNightlifeClass && !ignoreLinkButton ? (
          <ActionArea>
            <LinkButton onClick={() => openSearch(review)}>Search for Matches</LinkButton>
          </ActionArea>
        ) : null}
      </SlideoutContent>
    </ReviewSlideout>
  )
}

const copyAndNotify = (text, successMessage, errorMessage) => dispatch => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      dispatch(GlobalActions.showSuccessMessage(successMessage))
    })
    .catch(() => {
      dispatch(GlobalActions.showErrorMessage(errorMessage))
    })
}

const showError = errorMessage => dispatch => {
  dispatch(GlobalActions.showErrorMessage(errorMessage))
}

const mapStateToProps = state => ({
  isLinking: state.reviews.data.isLinking,
  review: state.reviews.data.selected || {},
  venue: state.appState.venue,
})

const mapDispatchToProps = {
  openSearch,
  openReview,
  setReviewReply,
  copyAndNotify,
  showError,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowReview)
