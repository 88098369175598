import { useMemo } from 'react'
import type { POSIConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type AutomaticPrepaymentRedemptionCardFormData = ZodSchema<typeof useAutomaticPrepaymentRedemptionCardForm>
export type AutomaticPrepaymentRedemptionCardFormField = Field<AutomaticPrepaymentRedemptionCardFormData>

export const AutomaticPrepaymentRedemptionCardFormSchema = z.object({
  isAutomaticPrepaymentRedemptionEnabled: z.boolean(),
})

export function useAutomaticPrepaymentRedemptionCardForm() {
  return useMemo(() => AutomaticPrepaymentRedemptionCardFormSchema, [])
}

export const getAutomaticPrepaymentRedemptionCardFormDefaultValues = ({
  isAutomaticPrepaymentRedemptionEnabled,
}: {
  isAutomaticPrepaymentRedemptionEnabled: boolean
}): AutomaticPrepaymentRedemptionCardFormData => ({
  isAutomaticPrepaymentRedemptionEnabled,
})

export const convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig = (
  data: AutomaticPrepaymentRedemptionCardFormData
): Pick<POSIConfig, 'depositsEnabled'> => {
  const { isAutomaticPrepaymentRedemptionEnabled: depositsEnabled } = data
  return {
    depositsEnabled,
  }
}
