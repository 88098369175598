import _ from 'lodash'
import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { TagElement } from 'mgr/lib/components/Tags'
import { getStarColor } from 'mgr/lib/utils/Styles'
import { SingleStarRow, Thumb } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'
import { BrandVmsIconS, BrandMap, BRAND_MAPPER, BRAND_MAPPER_NAMES } from 'svr/common/BrandIcon'
import { dateToFormat } from 'svr/common/DateTime'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import Tooltip from 'svr/component-lib/Generic/Tooltip'

export const ratingsCell = cellInfo => {
  const { original } = cellInfo
  const { rating } = original
  if (original.review_site === 'facebook') {
    return <Thumb starsChecked={rating} />
  }

  return <SingleStarRow numStars={5} starsChecked={rating} color={getStarColor(rating)} />
}

const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`

const BrandName = styled.span`
  padding-left: ${props => props.theme.gutter.standard};
`

export const platformCell = cellInfo => {
  const { original } = cellInfo
  const { review_site, review_site_png } = original
  const mappedBrand = BRAND_MAPPER[review_site]
  const mapperBrandName = BRAND_MAPPER_NAMES[review_site] || mappedBrand

  return (
    <PlatformContainer>
      <BrandVmsIconS company={BrandMap[mappedBrand]} pngSrc={review_site_png} />
      <BrandName>{mapperBrandName}</BrandName>
    </PlatformContainer>
  )
}

export const percentageCell = cellInfo => `${cellInfo.value}%`

export const withNullHandler = cell => props => props.value === null || Number.isNaN(props.value) ? '( - )' : cell(props)

const DeltaNumber = styled.div`
  color: ${props => props.color};
  text-align: right;
`

export const numberCell =
  (settings = {}) =>
  props => {
    const { toDigits = null } = settings
    let val = parseFloat(props.value)

    if (typeof toDigits === 'number') {
      val = val.toFixed(toDigits)
    }

    return <div>{val.toLocaleString()}</div>
  }

export const currencyCell = (currencyCode, locale, toDigits = 2, props) => (
  <div>
    {new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: toDigits,
      maximumFractionDigits: toDigits,
    }).format(parseFloat(props.value))}
  </div>
)

export const dateCell = (locale, showTime = true, initialDtFormat = null, props) => {
  const isMilitary = locale !== 'en_US'
  let timeFormat
  if (showTime) {
    timeFormat = isMilitary ? ', HH:mm' : ', h:mm a'
  } else {
    timeFormat = ''
  }
  const format = isMilitary ? `MMM Do YYYY${timeFormat}` : `MMM Do YYYY${timeFormat}`

  return <div>{props && props.value ? dateToFormat(props.value, format, initialDtFormat) : ''}</div>
}

export const deltaCell = props => {
  let value = parseFloat(props.value)
  let color

  if (value > 0) {
    color = Theme.color.positive
    value = `+${value}`
  } else if (value < 0) {
    color = Theme.color.negative
  } else {
    color = Theme.color.neutral
  }

  return <DeltaNumber color={color}>{value}</DeltaNumber>
}

const TagContainer = styled.div`
  display: flex;
  max-width: 170px;
  flex: 1;
`

const Icon = styled(StyledVmsIconXS)`
  padding-right: ${props => props.theme.gutter.half};
`

export const tagTypeCell = props => {
  const isGlobal = props.row._original.autotag_config_is_global
  const type = props.row._original.autotag_type_label
  const icon = isGlobal ? <Icon>{VmsIcons.SafariLine}</Icon> : <Icon>{VmsIcons.LocationLine}</Icon>

  return (
    <TagElement
      tagObj={{
        tag_color: 'transparent',
        tag_name: type,
        text_color: Theme.color.black,
        icon,
      }}
    />
  )
}

export const tagNameCell = props => (
  <TagContainer>
    <TagElement
      tagObj={{
        tag_color: props.row._original.tag_color_hex,
        tag_name: props.value,
        text_color: props.row._original.is_active ? Theme.color.white : Theme.color.black,
        icon: <Icon>{VmsIcons.Refresh}</Icon>,
      }}
    />
  </TagContainer>
)

export const tooltipCell = (cellInfo /* :Object */) => (
  <div>
    <Tooltip content={cellInfo.value}>
      <LinesEllipsis text={cellInfo.value || ''} maxLine={1} ellipsis="..." trimRight basedOn="letters" />
    </Tooltip>
  </div>
)

export const TextCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TextCellWrapper = styled.div`
  ${props => props.cellStyles};
`

const TopSplitTextCell = styled.div`
  font-size: 15px;
`

const BottomSplitTextCell = styled.div`
  font-size: 13px;
  color: #565c63;
`

export const textCell = (cellInfo /* :Object */) => (
  <TextCell data-test={`${cellInfo.original.dataTestId}-${cellInfo.column.id}`}>{cellInfo.value || ''}</TextCell>
)

export const verticalSplitTextCell = (cellStyles, cellInfo) => (
  <TextCellWrapper cellStyles={cellStyles}>
    <TopSplitTextCell>{cellInfo.value.topValue || ''}</TopSplitTextCell>
    <BottomSplitTextCell>{cellInfo.value.bottomValue || ''}</BottomSplitTextCell>
  </TextCellWrapper>
)

export const customStyleTextCell = (cellStyles, cellInfo) => (
  <TextCellWrapper cellStyles={cellStyles}>
    <TextCell>{cellInfo.value || ''}</TextCell>
  </TextCellWrapper>
)

const StatusCell = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.theme.gutter.standard};
  border-radius: 15px;
  text-transform: capitalize;
`

export const statusCell = (colorMapping, cellInfo) => {
  const mapped = colorMapping && colorMapping[cellInfo.value.toLowerCase()]
  const color = mapped ? mapped.color : Theme.color.greySecondary
  const backgroundColor = mapped ? mapped.backgroundColor : Theme.color.lightGrey

  return (
    <StatusCell color={color} backgroundColor={backgroundColor}>
      {_.capitalize(cellInfo.value)}
    </StatusCell>
  )
}

const CopyCell = styled.div`
  border: 1px solid ${props => props.theme.color.greySecondary};
  color: ${props => props.theme.color.greySecondary};
  border-radius: 25px;
  width: 25px;
  height: 25px;

  &:hover {
    cursor: pointer;
    border-color: ${props => props.theme.color.teal};
    color: ${props => props.theme.color.teal};
    background-color: #d3f1e9;
  }
`
/* eslint-disable */
export const copyCell = (loadCampaign, updateStep, is_superuser, props) => {
  if (!is_superuser && props.original.campaign_type === 'ONE_TIME') {
    return null
  }
  const onClick = e => {
    e.stopPropagation()
    loadCampaign(props, true)
    updateStep(0)
  }
  return (
    <div onClick={onClick}>
      <Tooltip key={`tooltip-edit-cell-${props.row._original.id}`} minWidth="100px" content={[<div>Clone</div>]}>
        <CopyCell>
          <i className="svr-icon- svr-icon--copy" style={{ margin: 4, fontSize: '14px' }} />
        </CopyCell>
      </Tooltip>
    </div>
  )
}
/* eslint-enable */
const Bubble = styled.div`
  position: relative;
  border-radius: 100%;
  border: 1px solid ${props => props.theme.color.greyMuted};
  color: ${props => props.theme.color.black};
  background: ${props => props.theme.color.white};
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background 0.5s;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.color.greyMuted};
  }
`

const Dots = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(-15%);
  font-weight: bold;
`

const triangleWidth = 16
const triangleHeight = 10
const halfTriangleWidth = triangleWidth / 2

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: ${props => props.theme.color.white};
  box-shadow: ${props => props.theme.shadow.two};
  transform: translate(-${halfTriangleWidth}px, -110%);
  border-radius: 4px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: ${halfTriangleWidth + 2}px;
    bottom: -${triangleHeight - 2}px;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 1;
    border-width: ${triangleHeight}px ${halfTriangleWidth}px 0 ${halfTriangleWidth}px;
    border-color: ${props => props.theme.color.white} transparent transparent transparent;
  }
`

export const bubbleClickOutsideConfig = {
  handleClickOutside(instance) {
    return () => instance.onClickOutside()
  },
}

export class OverflowMenuBase extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.onBubbleClick = this.onBubbleClick.bind(this)
    this.onClickOutside = this.onClickOutside.bind(this)
  }

  onBubbleClick(e) {
    e.stopPropagation()

    this.props.onBubbleClick(e)
    this.setState({ open: !this.state.open })
  }

  onClickOutside(e) {
    this.props.onClickOutside(e)
    this.setState({ open: false })
  }

  render() {
    return (
      <Bubble onClick={this.onBubbleClick}>
        <Dots>&hellip;</Dots>
        {this.state.open && <Content>{this.props.children}</Content>}
      </Bubble>
    )
  }
}

OverflowMenuBase.defaultProps = {
  onClickOutside: () => {},
  onBubbleClick: () => {},
}

export const OverflowMenu = onClickOutside(OverflowMenuBase, bubbleClickOutsideConfig)

const greyTheme = {
  color: Theme.color.greySecondary,
  backgroundColor: Theme.color.lightGrey,
}

const greenTheme = {
  color: Theme.color.teal,
  backgroundColor: '#D3F1E8',
}

const darkGreyTheme = {
  color: '#1E252F',
  backgroundColor: '#DEDEDE',
}

export const STATUS_MAP = {
  draft: greyTheme,
  scheduled: greyTheme,
  active: greenTheme,
  sent: greenTheme,
  pending: darkGreyTheme,
}

export const HighlightedText = styled.div`
  font-size: ${props => props.theme.fontSize.subheader};
  color: ${props => props.theme.color.greySecondary};
  font-weight: bold;
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.color.black};
    `};
`
