import type { Pacing } from '@sevenrooms/core/domain'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { ReportLine } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { customPacingKeysSort } from '../../ViewMode/utils'

export interface CustomPacingProps {
  pacing: Pacing
}

export function CustomPacing(props: CustomPacingProps) {
  const { venueStartOfDayHour } = useAppContext()
  const customPacingKeysSorted = customPacingKeysSort(props.pacing, venueStartOfDayHour)

  return (
    <>
      {customPacingKeysSorted.map(
        minutes =>
          minutes !== undefined && (
            <ReportLine key={minutes} name={TimeOnly.from({ minutes }).formatSTime()}>
              {props.pacing[minutes]}
            </ReportLine>
          )
      )}
    </>
  )
}
