import { useLocales } from '@sevenrooms/core/locales'
import { Select, Input } from '@sevenrooms/core/ui-kit/core'
import { ErrorMessage, Label, MultiSelectTree } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useUserContext } from '@sevenrooms/mgr-core'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { usePromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'

export function GeneralSection() {
  const user = useUserContext()
  const { formatMessage } = useLocales()
  const { codeTypeChoices } = usePromoCodeResourcesContext()

  const {
    name,
    setName,
    isNameValid,
    description,
    setDescription,
    code,
    setCode,
    isCodeValid,
    setWidgets,
    widgets,
    isWidgetsValid,
    codeType,
    setCodeType,
  } = usePromoCodeContext()

  return (
    <BaseSection title={formatMessage(promoCodeMessages.promoCodeFormGeneralSection)}>
      <VStack spacing="lm" p="lm">
        <HStack spacing="lm">
          <Label
            primary={formatMessage(promoCodeMessages.promoCodeFormNameLabel)}
            secondary={formatMessage(promoCodeMessages.promoCodeFormNameDescription)}
            width="448px"
          >
            <Input
              value={name}
              data-test="promo-code-form-name-field"
              onChange={event => setName(event.target.value)}
              placeholder={formatMessage(promoCodeMessages.promoCodeFormNamePlaceholder)}
              invalid={!isNameValid}
              hideErrorMessage={isNameValid}
              errorMessage={formatMessage(promoCodeMessages.promoCodeFormNameErrorMessage)}
            />
          </Label>
          <Label
            primary={formatMessage(promoCodeMessages.promoCodeFormCodeLabel)}
            secondary={formatMessage(promoCodeMessages.promoCodeFormCodeDescription)}
            width="448px"
          >
            <Input
              value={(code || '').toUpperCase()}
              onChange={event => setCode(event.target.value)}
              data-test="promo-code-form-code-field"
              placeholder={formatMessage(promoCodeMessages.promoCodeFormCodePlaceholder)}
              invalid={!isCodeValid}
              hideErrorMessage={isCodeValid}
              errorMessage={formatMessage(promoCodeMessages.promoCodeFormCodeErrorMessage)}
            />
          </Label>
        </HStack>
        <HStack spacing="lm">
          <Label primary={formatMessage(promoCodeMessages.promoCodeFormDescriptionLabel)} width="448px">
            <Input
              value={description || ''}
              onChange={event => setDescription(event.target.value)}
              data-test="promo-code-form-description-field"
              placeholder={formatMessage(promoCodeMessages.promoCodeFormDescriptionPlaceholder)}
            />
          </Label>
          <Label primary={formatMessage(promoCodeMessages.promoCodeFormPlatformApplyTypeLabel)} width="448px">
            <MultiSelectTree
              data-test="promo-code-form-select-platform-type-field"
              placeholder={formatMessage(promoCodeMessages.promoCodeFormPlatformApplyTypePlaceholder)}
              showMoreLabel=""
              treeData={widgets}
              onChange={(_option, selectedNodes) => {
                setWidgets(selectedNodes)
              }}
              invalid={!isWidgetsValid}
            />
            {!isWidgetsValid && <ErrorMessage>{formatMessage(promoCodeMessages.promoCodeFormPlatformApplyTypeErrorMessage)}</ErrorMessage>}
          </Label>
        </HStack>
        {user?.is_superhero && (
          <HStack spacing="lm">
            <Label primary={formatMessage(promoCodeMessages.promoCodeFormCodeTypeLabel)} width="448px">
              <Select
                data-test="promo-code-form-code-type-field"
                options={codeTypeChoices}
                value={codeType}
                searchable={false}
                onChange={value => setCodeType(value)}
              />
            </Label>
          </HStack>
        )}
      </VStack>
    </BaseSection>
  )
}
