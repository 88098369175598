export const GuestDurationPickerTestId = {
  section: 'guest-duration-picker',
  specifyDurationCheckbox: 'specify-duration-checkbox',
  specifyDurationTooltip: 'specify-duration-tooltip',
  durationMin: 'duration-min',
  durationMax: 'duration-max',
  defaultsTable: 'guest-duration-picker-defaults',
  errors: 'guest-duration-picker-errors',
  report: 'guest-duration-picker-report',
} as const
