import { useState, useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleSlideoutSection, AccessRuleTooltip, useAccessRuleContext } from '../shared'
import { CollapsedSeatingAreas, DefaultSeatingAreas, SeatingAreasFields } from './components'
import { ConflictModal } from './components/ConflictModal'
import { SeatingAreasLocales } from './SeatingAreas.locales'
import { SeatingAreasTestId } from './SeatingAreas.testIds'
import { type SeatingAreasForm, getShiftSeatingAreas } from './SeatingAreas.zod'

export interface SeatingAreasProps {
  field: Field<SeatingAreasForm>
  disabled?: boolean
}

export function SeatingAreas({ field, disabled = false }: SeatingAreasProps) {
  const { seatingAreaToTables, shifts, accessRule } = useAccessRuleContext()
  const [conflictIsActive, setConflictIsActive] = useState(false)
  const { formatMessage } = useLocales()
  const disableEdit = !!accessRule?.exclusiveAccessPerkId || disabled

  const shiftSeatingAreas = useMemo(() => getShiftSeatingAreas(shifts, seatingAreaToTables), [shifts, seatingAreaToTables])
  const conflict = (
    <AccessRuleTooltip data-test={SeatingAreasTestId.conflictTooltip} variant="warning">
      <Text color="lightFont">{formatMessage(SeatingAreasLocales.conflictTooltip)}</Text>
      <Button data-test="see-details-button" onClick={() => setConflictIsActive(true)}>
        {formatMessage(commonMessages.seeDetails)}
      </Button>
    </AccessRuleTooltip>
  )

  return (
    <>
      <AccessRuleSlideoutSection
        disableEdit={disableEdit}
        data-test={SeatingAreasTestId.section}
        title={formatMessage(SeatingAreasLocales.title)}
        description={formatMessage(SeatingAreasLocales.description)}
        subCaption={formatMessage(SeatingAreasLocales.subCaption)}
        contentWhenCollapsed={<CollapsedSeatingAreas field={field} conflict={conflict} />}
        defaultSettings={<DefaultSeatingAreas shiftSeatingAreas={shiftSeatingAreas} />}
        sectionControllerName="seatingAreas"
        field={field}
      >
        <SeatingAreasFields disableEdit={disableEdit} field={field} shiftSeatingAreas={shiftSeatingAreas} conflict={conflict} />
      </AccessRuleSlideoutSection>
      <Window active={conflictIsActive}>
        <ConflictModal onClose={() => setConflictIsActive(false)} field={field.prop('selection')} shiftSeatingAreas={shiftSeatingAreas} />
      </Window>
    </>
  )
}
