import { type Field, useFieldArray } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, Button, ImageUploader } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack, Grid, Box } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, FormEditor } from '@sevenrooms/core/ui-kit/optional'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ExperienceFormEditorWrapper } from '../../ExperienceFormEditorWrapper'
import { ExperiencesLocales } from '../../Experiences.locales'
import type { CreateEditExperienceFormData } from '../../CreateEditExperienceForm.zod'

interface DescriptionProps {
  field: Field<CreateEditExperienceFormData>
}
export function Description({ field }: DescriptionProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()

  const { fields: images, append: appendImage, remove: removeImage } = useFieldArray(field, 'imageList')

  return (
    <BaseSection title={formatMessage(ExperiencesLocales.pdrSpaceDescription)}>
      <VStack spacing="lm" p="lm">
        <VStack>
          <Label primary={formatMessage(ExperiencesLocales.descriptionBody)} />
          <ExperienceFormEditorWrapper>
            <FormEditor
              field={field.prop(`descriptionBody`)}
              config={{
                ...defaultConfig,
                heightMin: 100,
                toolbarButtons: [
                  'textColor',
                  'bold',
                  'italic',
                  'underline',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'formatUL',
                  'formatOL',
                  'insertLink',
                ],
                toolbarBottom: false,
                useClasses: false,
              }}
              data-test="experiences-description-body"
              isLoyaltyAndPerksEnabled={venueSettings?.is_loyalty_and_perks_enabled}
              referralProgramEnabled={venueSettings?.referral_program_enabled}
            />
          </ExperienceFormEditorWrapper>
        </VStack>
        <VStack>
          <Label
            primary={formatMessage(ExperiencesLocales.headerImage)}
            secondary={formatMessage(ExperiencesLocales.pdrHeaderImageSizeDescription)}
          />
          <ImageUploader field={field.prop('heroImage')} aspect={2} maxSize={2000000} unit="%" />
        </VStack>
        <VStack>
          <Label primary={formatMessage(ExperiencesLocales.pdrAddAlternativeImages)} />
          {images.length > 0 && (
            <Grid gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))" gap="lm" gridAutoRows="min-content" alignItems="center">
              {images.map((image, idx) => (
                <ImageUploader
                  editText=""
                  deleteText=""
                  uploadText=""
                  variant="small"
                  onChange={img => !img && removeImage(idx)}
                  field={field.prop(`imageList.${idx}`)}
                  key={image.id}
                  alwaysShowDeleteButton
                  aspect={1.5}
                  unit="%"
                />
              ))}
            </Grid>
          )}
          <Box pt="lm">
            <Button
              data-test="experiences-add-image-button"
              variant="secondary"
              size="s"
              icon="VMSWeb-add"
              onClick={() => appendImage(null)}
              disabled={images.length === 8}
            >
              {formatMessage(ExperiencesLocales.addImage)}
            </Button>
          </Box>
        </VStack>
      </VStack>
    </BaseSection>
  )
}
