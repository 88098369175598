import React, { createContext, type PropsWithChildren, useContext, useState } from 'react'
import type { AccessRule, ShiftCategory } from '@sevenrooms/core/domain'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { AccessRuleView } from '../enums/enums'
import { useAccessRulesUrlParams } from '../hooks/useAccessRulesUrlParams'

interface ShowAccessRuleSlideoutProps {
  mode?: 'new-item'
  startDate?: Date
  accessRule?: AccessRule
  shiftCategories?: ShiftCategory[]
}

interface SlideoutContextType {
  showAccessRuleSlideout: (props?: ShowAccessRuleSlideoutProps) => void
  hideAccessRuleSlideout: () => void
}

const SlideoutContext = createContext<SlideoutContextType | null>(null)

export function AccessRuleSlideoutProvider({ children }: PropsWithChildren<unknown>) {
  const [slideoutProps, setSlideoutProps] = useState<ShowAccessRuleSlideoutProps | null>(null)
  const { data: accessRulesSlideoutData } = useAccessRulesSlideoutData()
  const [{ view, date }] = useAccessRulesUrlParams()
  const { venue } = useVenueContext()

  if (!venue) {
    return <>{children}</>
  }

  const showAccessRuleSlideout = (props?: ShowAccessRuleSlideoutProps) => {
    setSlideoutProps({
      mode: 'new-item',
      shiftCategories: [],
      ...props,
    })
  }

  const hideAccessRuleSlideout = () => {
    setSlideoutProps(null)
  }

  const onRuleUpdate = () => {
    if (view === AccessRuleView.CALENDAR) {
      // Refresh Calendar view
    } else {
      // Refresh List view
    }
    hideAccessRuleSlideout()
  }

  return (
    <SlideoutContext.Provider value={{ showAccessRuleSlideout, hideAccessRuleSlideout }}>
      {children}
      {slideoutProps && accessRulesSlideoutData && (
        <AccessRulesSlideout
          mode={slideoutProps.mode}
          accessRule={slideoutProps.accessRule}
          selectedDay={date.toIso()}
          onClose={hideAccessRuleSlideout}
          onRuleSave={onRuleUpdate}
          onRuleDelete={onRuleUpdate}
          startDate={slideoutProps.startDate ?? date.toJsDate()}
          shiftCategories={slideoutProps.shiftCategories || []}
          accessRulesSlideoutData={accessRulesSlideoutData}
        />
      )}
    </SlideoutContext.Provider>
  )
}

export const useAccessRuleSlideout = () => {
  const context = useContext(SlideoutContext)
  if (!context) {
    throw new Error('useAccessRuleSlideout must be used within an AccessRuleSlideoutProvider')
  }
  return context.showAccessRuleSlideout
}
