import { useCallback, useState } from 'react'
import { useUpdateVenueProfileMutation } from '@sevenrooms/core/api'
import type { VenueProfile, VenueUserProfile } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack, HStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { venueProfileMessages } from '../../locales/venueProfileMessages'
import { GuestFacingContactForm, type GuestFacingContactFormFields } from '../GuestFacingContactForm'
import { ReviewSitesForm, type ReviewSitesFormFields } from '../ReviewSitesForm'
import { SocialMediaForm, type SocialMediaFormFields } from '../SocialMediaForm'
import { VenueInformationForm, type VenueInformationFormFields } from '../VenueInformationForm'
import { getVenueProfileDefaultValues, useVenueProfileForm } from './VenueProfileForm.zod'

export interface VenueProfileFormProps {
  venueProfile: VenueProfile
  venueUsers: VenueUserProfile[]
}

export function VenueProfileForm({ venueProfile, venueUsers }: VenueProfileFormProps) {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const [updateVenueProfileMutation] = useUpdateVenueProfileMutation()
  const venueProfileFormSchema = useVenueProfileForm()
  const [defaultValues, setDefaultValues] = useState(getVenueProfileDefaultValues(venueProfile))
  const form = useForm(venueProfileFormSchema, { defaultValues, mode: 'onChange' })
  const {
    field,
    formState: { isSubmitting },
    getValues,
    reset,
  } = form

  const handleOnCancel = useCallback(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const handleOnSubmit = useCallback(async () => {
    const formData = getValues()
    try {
      const result = await updateVenueProfileMutation({ venueProfile: { ...venueProfile, ...formData } as VenueProfile, venueId })
      if ('error' in result) {
        notify({
          content: result.error,
          type: 'error',
        })
      } else {
        notify({
          content: formatMessage(venueProfileMessages.saveSuccessMessage),
          type: 'success',
        })
        setDefaultValues(formData)
        reset(formData)
      }
    } catch {
      notify({
        content: formatMessage(venueProfileMessages.senderRoleLabel),
        type: 'error',
      })
    }
  }, [getValues, formatMessage, venueProfile, updateVenueProfileMutation, venueId, reset, setDefaultValues])

  const handleOnInvalid = () => ({})

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(venueProfileMessages.venueProfileTitle)} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          title={formatMessage(venueProfileMessages.venueProfileTitle)}
          actions={
            <HStack spacing="s">
              <Button data-test="sr-venue-profile-cancel-btn" variant="tertiary" disabled={isSubmitting} onClick={handleOnCancel}>
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button data-test="sr-venue-profile-save-btn" type="submit" variant="primary" disabled={isSubmitting}>
                {formatMessage(commonMessages.saveChanges)}
              </Button>
            </HStack>
          }
        >
          <VStack spacing="lm" pb="lm" pl="lm" pr="lm">
            <BaseSection title={formatMessage(venueProfileMessages.venueInformation)}>
              <VStack p="lm">
                <VenueInformationForm fields={field as unknown as VenueInformationFormFields} />
              </VStack>
            </BaseSection>
            <BaseSection title={formatMessage(venueProfileMessages.guestFacingContact)}>
              <VStack p="lm">
                <GuestFacingContactForm
                  venueUsers={venueUsers}
                  fields={field as unknown as GuestFacingContactFormFields}
                  venueProfile={venueProfile}
                />
              </VStack>
            </BaseSection>
            <BaseSection title={formatMessage(venueProfileMessages.reviewSites)}>
              <VStack p="lm">
                <ReviewSitesForm fields={field as unknown as ReviewSitesFormFields} />
              </VStack>
            </BaseSection>
            <BaseSection title={formatMessage(venueProfileMessages.socialMediaLinks)}>
              <VStack p="lm">
                <SocialMediaForm fields={field as unknown as SocialMediaFormFields} />
              </VStack>
            </BaseSection>
          </VStack>
        </SettingsPageContent>
      </Form>
    </>
  )
}
