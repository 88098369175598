import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { TierAttributes, AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TighterTierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const monthChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class FeedbackTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getLanguage() {
    switch (this.props.tier.tier_type) {
      case AutotagTierType.POSITIVE_REVIEW:
        return 'a positive review'
      case AutotagTierType.NEGATIVE_REVIEW:
        return 'a negative review'
      case AutotagTierType.POSITIVE_FEEDBACK:
        return 'positive feedback'
      case AutotagTierType.NEGATIVE_FEEDBACK:
        return 'negative feedback'
      case AutotagTierType.GROUP_REVIEW:
        return "a review at one of your group's other locations"
      case AutotagTierType.GROUP_FEEDBACK:
        return "feedback at one of your group's other locations"
      // This should never default, but won't break if it does
      default:
        return 'feedback'
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier
    const language = this.getLanguage()

    const { index, editable, isActive, id } = this.props

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TighterTierEditor>
              Tag clients when they have left {language} within the last
              <DropdownArrowsPicker
                {...dropProps}
                choices={monthChoices}
                value={attributes.PAST_MONTHS_MAX}
                isDropUp={index > 3}
                style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MAX, value)}
              />
              month(s).
            </TighterTierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag clients when they have left {language} within the last {attributes.PAST_MONTHS_MAX} months(s).
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default FeedbackTier
