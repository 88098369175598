import { defineMessages } from '@sevenrooms/core/locales'

export const GuestDurationPickerLocales = defineMessages({
  title: {
    id: 'ar.guestDurationPicker.title',
    defaultMessage: 'Guest Duration Picker (Advanced)',
  },
  description: {
    id: 'ar.guestDurationPicker.description',
    defaultMessage: 'Ex: Ideal for experiences where guests need to choose a length of time, such as a poolside cabana or bowling lane.',
  },
  subCaption: {
    id: 'ar.guestDurationPicker.subCaption',
    defaultMessage: 'Require guests to specify the duration of their reservation.',
  },
  specifyDurationLabel: {
    id: 'ar.guestDurationPicker.specifyDurationLabel',
    defaultMessage: 'Guest must specify duration',
  },
  specifyDurationSubLabel: {
    id: 'ar.guestDurationPicker.specifyDurationSubLabel',
    defaultMessage: 'Guest will be required to choose duration and shift durations per party size will be ignored.',
  },
  specifyDurationLabelCollapsed: {
    id: 'ar.guestDurationPicker.specifyDurationLabelCollapsed',
    defaultMessage: 'Guest must specify duration:',
  },
  specifyDurationTooltip: {
    id: 'ar.guestDurationPicker.specifyDurationTooltip',
    defaultMessage:
      'Requires guests to select a custom duration when booking a reservation online. Utilizing this setting will cause this access rule to ignore durations set per party size on the shift. To utilize this setting, please make sure you point guests to the <a>duration picker version of the widget</a>.',
  },
  durationTimeFormat: {
    id: 'ar.guestDurationPicker.durationTimeFormat',
    defaultMessage: '{hours} hr {minutes} mins',
  },
  durationMin: {
    id: 'ar.guestDurationPicker.durationMin',
    defaultMessage: 'Duration min',
  },
  durationMax: {
    id: 'ar.guestDurationPicker.durationMax',
    defaultMessage: 'Duration max',
  },
  durationMinCollapsed: {
    id: 'ar.guestDurationPicker.durationMinCollapsed',
    defaultMessage: 'Duration min:',
  },
  durationMaxCollapsed: {
    id: 'ar.guestDurationPicker.durationMaxCollapsed',
    defaultMessage: 'Duration max:',
  },
  durationMinRequired: {
    id: 'ar.guestDurationPicker.durationMinRequired',
    defaultMessage: 'Duration min is required',
  },
  durationMaxRequired: {
    id: 'ar.guestDurationPicker.durationMaxRequired',
    defaultMessage: 'Duration max is required',
  },
  durationMaxAfterMin: {
    id: 'ar.guestDurationPicker.durationMaxAfterMin',
    defaultMessage: 'Duration max must be greater than or equal to Duration min',
  },
  durationEnabledWarning: {
    id: 'ar.guestDurationPicker.durationMaxAfterMin',
    defaultMessage:
      "Access Rules with custom Durations cannot also use the Guest Duration Picker. Restore this Access Rule's Durations to their default settings in order to use the Guest Duration Picker.",
  },
} as const)
