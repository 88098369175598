import { ExperienceTemplateTypeEnum } from '@sevenrooms/core/domain'
import { useController, type Field } from '@sevenrooms/core/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Clickable, Flex, Image } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'

export function ExperienceThemeSelector({ field }: { field: Field<string> }) {
  const themes = [
    {
      id: ExperienceTemplateTypeEnum.DEFAULT,
      name: 'Theme 1',
      image: 'theme_1.png',
    },
    {
      id: ExperienceTemplateTypeEnum.TWENTYTWENTY,
      name: 'Theme 2',
      image: 'theme_2.png',
    },
  ]
  const { mediaUrl } = useAppContext()
  const controller = useController(field)
  return (
    <Flex>
      {themes.map(theme => (
        <Clickable
          key={theme.id}
          width={controller.field.value === theme.id ? 'calc(30% + 6px)' : '30%'}
          mt={controller.field.value !== theme.id ? 'xs' : undefined}
          onClick={() => controller.field.onChange(theme.id)}
          p={controller.field.value === theme.id ? 'xs' : undefined}
          borderColor="primaryButton"
          borderWidth={controller.field.value === theme.id ? 'm' : 'none'}
          borderRadius="s"
          mr="m"
          position="relative"
        >
          <div style={{ opacity: controller.field.value === theme.id ? 0.6 : 1 }}>
            <Image src={`${mediaUrl}images/experiences/${theme.image}`} width="100%" alt={theme.name} />
          </div>
          {controller.field.value === theme.id && (
            <Flex position="absolute" top="none" left="none" right="none" bottom="none" justifyContent="center" alignItems="center">
              <Icon name="VMSWeb-checkbox-marked-circle" color="primaryButton" size="3x" />
            </Flex>
          )}
        </Clickable>
      ))}
    </Flex>
  )
}
