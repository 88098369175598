import _ from 'lodash'
import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'

const VenueGroupDropDown = ({
  venueGroupId,
  venueGroupIds,
  onChange,
  venueGroups,
  width,
  height,
  style,
  isLightTheme,
  isMultiSelect,
  name,
  noHeader,
}) => {
  const choices = _.isEmpty(venueGroups) ? [] : venueGroups.map(v => ({ name: v.name, value: v.id }))
  const value = venueGroupId
  const values = [...(venueGroupIds || [])]
  let displayOverride
  const onChangeHandler = values => {
    onChange(_.find(venueGroups, { id: values }))
  }
  return (
    <DropdownArrowsPicker
      key="VENUE GROUP"
      name={name}
      noHeader={noHeader}
      style={{
        display: 'inline-block',
        textAlign: 'left',
        width,
        ...style,
      }}
      {...{
        value,
        values,
        choices,
        displayOverride,
        onChangeHandler,
        isLightTheme,
        isMultiSelect,
        height,
      }}
    />
  )
}

VenueGroupDropDown.defaultProps = {
  width: 154,
  height: 42,
  onChange: () => {},
  isLightTheme: false,
  isMultiSelect: false,
  noHeader: false,
  name: 'VENUE GROUPS',
}

VenueGroupDropDown.propTypes = {
  venueGroupId: React.PropTypes.string, // Single-select
  venueGroupIds: React.PropTypes.array, // Multi-select
  venueGroups: React.PropTypes.array.isRequired,
  onChange: React.PropTypes.func.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  style: React.PropTypes.object,
  noHeader: React.PropTypes.bool.isRequired,
  isLightTheme: React.PropTypes.bool.isRequired,
  isMultiSelect: React.PropTypes.bool.isRequired,
}

export default VenueGroupDropDown
