import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack, Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { checkTimeBeforeWarning } from '../../shared'
import { CutoffTimeWarningTooltip } from '../../shared/CutoffTimeWarningTooltip'
import { FormattedTimeBefore } from '../../shared/FormattedTimeBefore'
import { BookingWindowLocales } from '../BookingWindow.locales'
import { BookingWindowTestId } from '../BookingWindow.testIds'
import type { BookingChannelsForm } from '../../BookingChannels/BookingChannels.zod'
import type { BookingWindowForm } from '../BookingWindow.zod'

export interface CollapsedBookingWindowProps {
  field: Field<BookingWindowForm>
  bookingChannelsField: Field<BookingChannelsForm>
}

export function CollapsedBookingWindow({ field, bookingChannelsField }: CollapsedBookingWindowProps) {
  const { formatMessage } = useLocales()
  const bookingWindow = useWatch(field)

  const startTime = useWatch(field.prop('startTime'))
  const cutoffTime = useWatch(field.prop('cutoffTime'))
  const bookingChannels = useWatch(bookingChannelsField)

  const hasBookingWindowWarning = bookingChannels.length === 1 && checkTimeBeforeWarning(startTime, cutoffTime)
  const hasBookingChannelsWarning =
    bookingChannels.length > 1 && bookingChannels.some(bookingChannel => checkTimeBeforeWarning(bookingChannel.startTime, cutoffTime))

  return (
    <Text fontSize="m">
      <VStack spacing="s">
        <Pair
          left={formatMessage(BookingWindowLocales.startTimeLabelCollapsed)}
          right={
            bookingChannelsField.size() > 1 ? (
              <Text fontStyle="italic">{formatMessage(BookingWindowLocales.setInBookingChannels)}</Text>
            ) : (
              <FormattedTimeBefore {...bookingWindow?.startTime} />
            )
          }
          data-test={BookingWindowTestId.startTimeCollapsedLabel}
        />
        <Pair
          left={formatMessage(BookingWindowLocales.cutoffTimeLabelCollapsed)}
          right={
            <HStack spacing="xs">
              <FormattedTimeBefore {...bookingWindow?.cutoffTime} />
              {(hasBookingWindowWarning || hasBookingChannelsWarning) && (
                <CutoffTimeWarningTooltip
                  hasBookingWindowWarning={hasBookingWindowWarning}
                  hasBookingChannelsWarning={hasBookingChannelsWarning}
                />
              )}
            </HStack>
          }
          data-test={BookingWindowTestId.cutoffTimeCollapsedLabel}
        />
      </VStack>
    </Text>
  )
}
