import type { AccessRule } from '@sevenrooms/core/domain'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import type { TreeNode } from '@sevenrooms/core/ui-kit/form'
import type { UpgradesValue } from './components/UpgradesProps'
import type { Upsells } from '../../AccessRule.types'

export function getMultiSelectOptions(upsells: Upsells, currencyCode: string, accessRule?: AccessRule): TreeNode<UpgradesValue>[] {
  const selectedUpsells = accessRule?.upsellCategories ?? []
  return Object.entries(upsells.categories).map(([id, { label, minQuantityType }]) => {
    const items = upsells.inventory.filter(({ categoryId }) => categoryId === id)
    return {
      id,
      label,
      value: {
        isRequired: minQuantityType !== 'NONE_REQUIRED',
        isPaid: items.some(({ price }) => price > 0),
      },
      checked: selectedUpsells.includes(id),
      expanded: true,
      children: items.map(item => ({
        id: item.id,
        label: `${item.name} - ${Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: currencyCode }).format(
          item.price
        )}`,
        value: {},
        disabled: true,
      })),
    }
  })
}
