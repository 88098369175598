import { useMemo } from 'react'
import {
  BookingNotificationsEnum,
  SendTimeOptionsEnum,
  EmailCategoriesEnum,
  VenueClassEnum,
  type VenueSettingsOptions,
  FeedbackOptionsEnum,
  ReminderDeliveryMethodEnum,
  BookingDeliveryMethodEnum,
} from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, shiftMessages, useLocales } from '@sevenrooms/core/locales'
import { Label, FormSelect, CheckboxGroup, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { Loader, HStack, VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ToggleToggler, CheckboxToggler, SectionBox, ExcludedAudience, type ExcludedAudienceFormField } from '../components'
import { useGetEmailTemplateQuery, useSelectDaysOptions } from '../hooks'
import { EmailTemplateEditor } from './EmailTemplateEditor'
import { guestEmailSMSMessages } from './GuestEmailSMS.locales'
import type { GuestEmailSMSForm } from './GuestEmailSMS.zod'

export interface GuestEmailSMSProps {
  field: Field<GuestEmailSMSForm>
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
}

export function GuestEmailSMS({ field, options, venueClass }: GuestEmailSMSProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { venueSettings } = useAppContext()
  const { data: emailTemplate, isLoading } = useGetEmailTemplateQuery({ venueId })
  const daysBeforeOptions = useSelectDaysOptions(7, 'before')
  const daysAfterOptions = useSelectDaysOptions(2, 'after')

  const { bookingNotifications, feedbackOptions, reminderDeliveryMethod, bookingDeliveryMethod } = useMemo(
    () => ({
      bookingNotifications: [
        {
          value: BookingNotificationsEnum.reservationEndTime,
          label: formatMessage(guestEmailSMSMessages.reservationEndTime),
        },
        {
          value: BookingNotificationsEnum.seatingArea,
          label: formatMessage(guestEmailSMSMessages.seatingArea),
        },
        {
          value: BookingNotificationsEnum.specialAttentionMessage,
          label: formatMessage(guestEmailSMSMessages.specialAttentionMessage),
        },
        {
          value: BookingNotificationsEnum.salutation,
          label: formatMessage(guestEmailSMSMessages.salutation),
        },
      ],
      feedbackOptions: [
        {
          value: FeedbackOptionsEnum.overall,
          label: formatMessage(guestEmailSMSMessages.feedbackOptionsOverall),
          disabled: true,
        },
        {
          value: FeedbackOptionsEnum.food,
          label: formatMessage(guestEmailSMSMessages.feedbackOptionsFood),
        },
        {
          value: FeedbackOptionsEnum.drinks,
          label: formatMessage(guestEmailSMSMessages.feedbackOptionsDrinks),
        },
        {
          value: FeedbackOptionsEnum.services,
          label: formatMessage(guestEmailSMSMessages.feedbackOptionsServices),
          disabled: true,
        },
        {
          value: FeedbackOptionsEnum.ambience,
          label: formatMessage(guestEmailSMSMessages.feedbackOptionsAmbience),
          disabled: true,
        },
      ],
      reminderDeliveryMethod: [
        { value: ReminderDeliveryMethodEnum.EMAIL, label: formatMessage(guestEmailSMSMessages.emailOnly) },
        { value: ReminderDeliveryMethodEnum.SMS, label: formatMessage(guestEmailSMSMessages.smsOnly) },
        // { value: ReminderDeliveryMethodEnum.EMAIL_AND_SMS, label: formatMessage(guestEmailSMSMessages.emailAndSMS) },
      ],
      bookingDeliveryMethod: [
        { value: BookingDeliveryMethodEnum.EMAIL, label: formatMessage(guestEmailSMSMessages.emailOnly) },
        { value: BookingDeliveryMethodEnum.SMS, label: formatMessage(guestEmailSMSMessages.smsOnly) },
        { value: BookingDeliveryMethodEnum.EMAIL_AND_SMS, label: formatMessage(guestEmailSMSMessages.emailAndSMS) },
      ],
    }),
    [formatMessage]
  )

  const feedbackEmailsSendTime = useMemo(
    () => [
      ...options.time,
      {
        id: SendTimeOptionsEnum.immediately,
        label: formatMessage(commonMessages.immediately),
      },
      ...options.immediateAfterHour,
    ],
    [formatMessage, options.time, options.immediateAfterHour]
  )
  const getAfterReservationOptions = (shift: EmailCategoriesEnum) => [
    {
      id: SendTimeOptionsEnum.after,
      label:
        shift === EmailCategoriesEnum.orders
          ? formatMessage(guestEmailSMSMessages.afterOrders)
          : formatMessage(guestEmailSMSMessages.afterReservation),
    },
  ]
  const sendReservationReminderTimeNightlifeValue = field.prop('sendReservationReminderTimeNightlife').watch()

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox halfsize title={formatMessage(guestEmailSMSMessages.sectionGuestBookingNotification)}>
        <ToggleToggler
          title={formatMessage(guestEmailSMSMessages.sendBookingNotification)}
          description={formatMessage(guestEmailSMSMessages.sendByDefaultDescription)}
          field={field.prop('sendEmailConfirmations')}
          data-test="sendEmailConfirmations"
        >
          <VStack spacing="l" pt="m">
            {venueSettings.smsBookingNotificationEnabled && (
              <Box>
                <Label primary={formatMessage(guestEmailSMSMessages.bookingDeliveryMethodTitle)} />
                <RadioGroup
                  name="bookingDeliveryMethod"
                  choices={bookingDeliveryMethod}
                  field={field.prop('bookingDeliveryMethod')}
                  data-test="bookingDeliveryMethod"
                />
              </Box>
            )}
          </VStack>
          <VStack spacing="s" pt="l">
            <Label
              primary={formatMessage(guestEmailSMSMessages.optionalFieldsTitle)}
              secondary={formatMessage(
                venueSettings.smsBookingNotificationEnabled
                  ? guestEmailSMSMessages.optionalFieldsDescription
                  : guestEmailSMSMessages.optionalFieldsDescriptionEmailOnly
              )}
            />
            <CheckboxGroup field={field.prop('bookingNotifications')} data-test="bookingNotifications" choices={bookingNotifications} />
          </VStack>
        </ToggleToggler>
      </SectionBox>
      {venueSettings.guestlistEnabled && (
        <SectionBox halfsize title={formatMessage(guestEmailSMSMessages.sectionGuestListNotificationEmails)}>
          <ToggleToggler
            title={formatMessage(guestEmailSMSMessages.sectionGuestListNotificationEmailsQRCodeTitle)}
            description={formatMessage(guestEmailSMSMessages.sectionGuestListNotificationEmailsQRCodeDescription)}
            field={field.prop('guestListQRCodeEnabled')}
            data-test="guestListNotificationEmailsQrCodeEnabled"
          />
        </SectionBox>
      )}
      <SectionBox halfsize title={formatMessage(guestEmailSMSMessages.sectionGuestReservationReminder)}>
        {venueClass === VenueClassEnum.DINING && (
          <ToggleToggler
            title={formatMessage(guestEmailSMSMessages.sendReservationReminderTitle)}
            description={formatMessage(guestEmailSMSMessages.sendReservationReminderDescription)}
            field={field.prop('sendReservationReminderShow')}
            data-test="sendReservationReminderShow"
          >
            <VStack spacing="l">
              <VStack spacing="s">
                {field.array('sendReservationReminder').map((field, value) => (
                  <CheckboxToggler
                    data-test={`sendReservationReminder-${value.shift}`}
                    key={value.shift}
                    title={formatMessage(shiftMessages[value.shift])}
                    checked={field.array('dayTime').size() > 0}
                    onShow={() => {
                      field.array('dayTime').append({
                        sendTime: options.time[0]?.id as string,
                        sendDays: daysBeforeOptions[0]?.id as string,
                      })
                    }}
                    onHide={() => field.array('dayTime').empty()}
                  >
                    {field.array('dayTime').map((field, value) => (
                      <HStack spacing="s" key={value.id}>
                        <FormSelect
                          options={options.time}
                          searchable={false}
                          hideErrorMessage
                          field={field.prop('sendTime')}
                          data-test="sendTime"
                        />
                        <FormSelect
                          options={daysBeforeOptions}
                          searchable={false}
                          hideErrorMessage
                          field={field.prop('sendDays')}
                          data-test="sendDays"
                        />
                      </HStack>
                    ))}
                  </CheckboxToggler>
                ))}
              </VStack>
              {options.remindersSmsEnabled && (
                <Box>
                  <Label primary={formatMessage(guestEmailSMSMessages.bookingDeliveryMethodTitle)} />
                  <RadioGroup
                    name="reminderDeliveryMethod"
                    choices={reminderDeliveryMethod}
                    field={field.prop('reminderDeliveryMethod')}
                    data-test="reminderDeliveryMethod"
                  />
                </Box>
              )}
            </VStack>
          </ToggleToggler>
        )}
        {venueClass === VenueClassEnum.NIGHTLIFE && (
          <ToggleToggler
            title={formatMessage(guestEmailSMSMessages.sendReservationReminderTitle)}
            description={formatMessage(guestEmailSMSMessages.sendReservationReminderDescription)}
            field={field.prop('sendReservationReminderNightlifeShow')}
            data-test="sendReservationReminderNightlifeShow"
            onShow={() => {
              if (sendReservationReminderTimeNightlifeValue == null) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                field.prop('sendReservationReminderTimeNightlife').set(options.time[0]!.id)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                field.prop('sendReservationReminderDayNightlife').set(daysBeforeOptions[0]!.id)
              }
            }}
          >
            <VStack spacing="l">
              <HStack spacing="s">
                <FormSelect
                  options={options.time}
                  searchable={false}
                  hideErrorMessage
                  withEmpty
                  hideEmptyOption
                  field={field.prop('sendReservationReminderTimeNightlife')}
                  data-test="sendReservationReminderTimeNightlife"
                />
                <FormSelect
                  options={daysBeforeOptions}
                  searchable={false}
                  hideErrorMessage
                  withEmpty
                  hideEmptyOption
                  field={field.prop('sendReservationReminderDayNightlife')}
                  data-test="sendReservationReminderDayNightlife"
                />
              </HStack>
            </VStack>
          </ToggleToggler>
        )}
      </SectionBox>
      {venueClass === VenueClassEnum.DINING && options.reservationsSmsEnabled && (
        <SectionBox halfsize title={formatMessage(guestEmailSMSMessages.sectionGuestReservationArrival)}>
          <ToggleToggler
            title={formatMessage(guestEmailSMSMessages.sendArrivalSmsTitle)}
            description={formatMessage(guestEmailSMSMessages.guestArrivalSmsDescription)}
            field={field.prop('isArrivalSms')}
            data-test="isArrivalSms"
          >
            <HStack spacing="s" alignItems="center">
              <FormSelect
                options={options.arrivalSmsTime}
                searchable={false}
                hideErrorMessage
                field={field.prop('arrivalSms')}
                data-test="arrivalSms"
              />
              <Text>{formatMessage(guestEmailSMSMessages.beforeReservation)}</Text>
            </HStack>
          </ToggleToggler>
        </SectionBox>
      )}
      {options.useSupafly && (
        <SectionBox halfsize title={formatMessage(guestEmailSMSMessages.sectionGuestFeedback)}>
          <ToggleToggler
            title={formatMessage(guestEmailSMSMessages.guestFeedbackTitle)}
            description={formatMessage(guestEmailSMSMessages.guestFeedbackDescription)}
            field={field.prop('feedbackEmailsShow')}
            data-test="feedbackEmailsShow"
          >
            <VStack spacing="s">
              {field.array('feedbackEmails').map((field, shiftValue) => (
                <CheckboxToggler
                  data-test={`feedbackEmails-${shiftValue.shift}`}
                  key={shiftValue.id}
                  title={formatMessage(shiftMessages[shiftValue.shift])}
                  checked={field.array('dayTime').size() > 0}
                  onShow={() => {
                    if (field.array('dayTime').size() === 0) {
                      field.array('dayTime').append({
                        sendTime: options.time[0]?.id as string,
                        sendDays: daysAfterOptions[0]?.id as string,
                      })
                    }
                  }}
                  onHide={() => field.array('dayTime').empty()}
                >
                  {field.array('dayTime').map((field, value) => {
                    const sendTimeValue = field.prop('sendTime').watch()
                    const sendDaysValue = field.prop('sendDays').watch()
                    return (
                      <HStack spacing="s" key={value.id}>
                        <FormSelect
                          options={feedbackEmailsSendTime}
                          searchable={false}
                          hideErrorMessage
                          field={field.prop('sendTime')}
                          data-test="sendTime"
                          onChange={value => {
                            if (value && isAfterReservation(value)) {
                              if (sendDaysValue !== SendTimeOptionsEnum.after) {
                                field.prop('sendDays').set(SendTimeOptionsEnum.after)
                              }
                            } else if (sendDaysValue === SendTimeOptionsEnum.after) {
                              field.prop('sendDays').set(daysAfterOptions[0]?.id as string)
                            }
                          }}
                        />
                        <FormSelect
                          options={isAfterReservation(sendTimeValue) ? getAfterReservationOptions(shiftValue.shift) : daysAfterOptions}
                          searchable={false}
                          hideErrorMessage
                          field={field.prop('sendDays')}
                          data-test="sendDays"
                          disabled={isAfterReservation(sendTimeValue)}
                        />
                      </HStack>
                    )
                  })}
                </CheckboxToggler>
              ))}
            </VStack>
          </ToggleToggler>
          <VStack spacing="s" pt="l">
            <Label
              primary={formatMessage(guestEmailSMSMessages.feedbackOptionsTitle)}
              secondary={formatMessage(guestEmailSMSMessages.feedbackOptionsDescription)}
            />
            <CheckboxGroup field={field.prop('feedbackOptions')} data-test="feedbackOptions" choices={feedbackOptions} />
          </VStack>
          <VStack pt="lm">
            <ExcludedAudience field={field as unknown as ExcludedAudienceFormField} />
          </VStack>
        </SectionBox>
      )}
      <SectionBox
        title={formatMessage(guestEmailSMSMessages.sectionEmailDesign)}
        description={formatMessage(guestEmailSMSMessages.sectionEmailDesignDescription)}
      >
        {emailTemplate && <EmailTemplateEditor field={field} emailTemplate={emailTemplate} />}
        {isLoading && <Loader />}
      </SectionBox>
    </VStack>
  )
}

const isAfterReservation = (sendTimeValue: string) => sendTimeValue === SendTimeOptionsEnum.immediately || sendTimeValue.includes('hour')
