import type { AccessRule, ExperiencesData } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { DateTime } from '@sevenrooms/core/timepiece'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, Image, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import ImagePlaceholder from '@sevenrooms/mgr-offers/experiences/images/ImagePlaceholder.png'
import { routes } from '@sevenrooms/routes'
import { spacesMessages } from '../spaces.locales'

interface SpaceCardProps {
  space: ExperiencesData['results'][number]
  accessRules: AccessRule[]
}

export function SpaceCard({ space, accessRules }: SpaceCardProps) {
  const { formatMessage } = useLocales()
  const { venueKey } = useVenueContext()
  const createdOn = new DateTime(new Date(space.created)).formatNYearSMonthNDaySTime()
  const nav = useNavigation()

  return (
    <HStack
      width="100%"
      height="92px"
      borderColor="borders"
      backgroundColor="primaryBackground"
      borderRadius="s"
      justifyContent="space-between"
      p="s lm s m"
    >
      <Flex justifyContent="space-around" alignItems="center" borderRadius="s" width="128px">
        {space?.heroImage?.photoDict?.small ? (
          <Image
            src={`/.h/download/${space?.heroImage?.photoDict?.small}`}
            alt=""
            data-test="space-card-image"
            borderRadius="s"
            height="auto"
            width="128px"
          />
        ) : (
          <Image src={ImagePlaceholder} alt="" width="128px" data-test="space-card-placeholder" />
        )}
      </Flex>

      <VStack width="100%" justifyContent="center" m="none m" spacing="xs">
        <Text fontSize="m" fontWeight={400} color="secondaryFont" textStyle="body2" width="100%" lineHeight="l">
          {formatMessage(spacesMessages.createdBy, { userName: space.createdByUserName, createdOn })}
        </Text>
        <Text fontSize="l" fontWeight={700} width="100%" lineHeight="l">
          {space.name}
        </Text>
        {accessRules.length > 0 ? (
          <Box width="max-content">
            <Tooltip content={<>{accessRules.map(rule => rule.name).join(', ')}</>} displayAction="hover">
              <Text cursor="default">{formatMessage(spacesMessages.attachedAccessRules, { count: accessRules.length })}</Text>
            </Tooltip>
          </Box>
        ) : (
          <HStack alignItems="center" spacing="xs">
            <Icon name="VMSWeb-warning" color="warning" size="lg" />
            <Text>{formatMessage(spacesMessages.attachedAccessRules, { count: 0 })}</Text>
          </HStack>
        )}
      </VStack>

      <Flex width="60px" alignSelf="center" alignItems="self-end">
        <IconButton
          data-test={`space-edit-link-${space.id}`}
          icon="VMSWeb-edit"
          size="m"
          borderType="none-round"
          title={formatMessage(commonMessages.edit)}
          onClick={() => nav.push(routes.manager2.marketing.groupBookings.editSpace, { params: { venueKey, spaceId: space.id } })}
        />
      </Flex>
    </HStack>
  )
}
