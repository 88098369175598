import { defineMessages } from '@sevenrooms/core/locales'

export const BookingWindowLocales = defineMessages({
  title: {
    id: 'ar.bookingWindow.title',
    defaultMessage: 'Booking Window',
  },
  description: {
    id: 'ar.bookingWindow.description',
    defaultMessage: 'Ex: Allow guests to book online reservations 30 days in advance up until 2 hours before their reservation.',
  },
  subCaption: {
    id: 'ar.bookingWindow.subCaption',
    defaultMessage: 'Set how far in advance guests can book online and when they should be cut off from booking.',
  },
  startTimeLabel: {
    id: 'ar.bookingWindow.startTimeLabel',
    defaultMessage: 'Guest Booking Start Time',
  },
  startTimeSubLabel: {
    id: 'ar.bookingWindow.startTimeSubLabel',
    defaultMessage: 'Allow guests to book starting from',
  },
  cutoffTimeLabel: {
    id: 'ar.bookingWindow.cutoffTimeLabel',
    defaultMessage: 'Guest Booking Cutoff Time',
  },
  cutoffTimeSubLabel: {
    id: 'ar.bookingWindow.cutoffTimeSubLabel',
    defaultMessage: 'Allow guests to book up until',
  },
  startTimeLabelCollapsed: {
    id: 'ar.bookingWindow.startTimeLabelCollapsed',
    defaultMessage: 'Guest Booking Start Time:',
  },
  cutoffTimeLabelCollapsed: {
    id: 'ar.bookingWindow.cutoffTimeLabelCollapsed',
    defaultMessage: 'Guest Booking Cutoff Time:',
  },
  timeBefore: {
    id: 'ar.bookingWindow.timeBefore',
    defaultMessage: `{before, select,
      0 {
        {unit, select,
          HOURS {
            {count, plural,
              one {# hour before reservation time}
              other {# hours before reservation time}
            }
          }
          DAYS {
            {count, plural,
              one {# day before reservation time}
              other {# days before reservation time}
            }
          }
          WEEKS {
            {count, plural,
              one {# week before reservation time}
              other {# weeks before reservation time}
            }
          }
          MONTHS {
            {count, plural,
              one {# month before reservation time}
              other {# months before reservation time}
            }
          }
          other {
            {count, plural,
              one {# minute before reservation time}
              other {# minutes before reservation time}
            }
          }
        }
      }
      other {
        {unit, select,
          HOURS {
            {count, plural,
              one {# hour before {before}}
              other {# hours before {before}}
            }
          }
          DAYS {
            {count, plural,
              one {# day before {before}}
              other {# days before {before}}
            }
          }
          WEEKS {
            {count, plural,
              one {# week before {before}}
              other {# weeks before {before}}
            }
          }
          MONTHS {
            {count, plural,
              one {# month before {before}}
              other {# months before {before}}
            }
          }
          other {
            {count, plural,
              one {# minute before {before}}
              other {# minutes before {before}}
            }
          }
        }
      }
    }`,
  },
  upToReservationTime: {
    id: 'ar.bookingWindow.upToReservationTime',
    defaultMessage: 'Up to reservation time',
  },
  setInBookingChannels: {
    id: 'ar.bookingWindow.setInBookingChannels',
    defaultMessage: 'Set in Booking Channels and Audiences',
  },
  disabledLabel: {
    id: 'ar.bookingWindow.disabledLabel',
    defaultMessage: 'Guest booking start time has been configured for individual audiences in the Booking Channels and Audiences section.',
  },
  startTimeRequired: {
    id: 'ar.bookingWindow.startTimeRequired',
    defaultMessage: 'Guest Booking Start Time is required',
  },
  cutoffTimeBasicWarning: {
    id: 'ar.bookingWindow.cutoffTimeWarning',
    defaultMessage:
      'Please note: We detected that your booking window is very narrow or nonexistent. Check your guest booking start and cutoff times to ensure guests have enough time to book.',
  },
  cutoffTimeChannelsWarning: {
    id: 'ar.bookingWindow.cutoffTimeWarningBookingChannels',
    defaultMessage:
      'Please note: We detected that your booking window is very narrow or nonexistent for at least one tier. Check your guest booking start and cutoff times to ensure guests have enough time to book.',
  },
  cutoffTimeBasicError: {
    id: 'ar.bookingWindow.cutoffTimeBasicError',
    defaultMessage: 'Booking cutoff time cannot exceed booking start time.',
  },
  cutoffTimeChannelsError: {
    id: 'ar.bookingWindow.cutoffTimeChannelsError',
    defaultMessage: 'Booking cutoff time cannot exceed booking start time set in Booking Channels and Audiences.',
  },
  accessTimeExceedsBoundaryError: {
    id: 'ar.bookingWindow.accessTimeExceedsBoundaryError',
    defaultMessage: `Value must be less than or equal to {unit, select,
          HOURS {
            {count, plural,
              one {# hour}
              other {# hours}
            }
          }
          DAYS {
            {count, plural,
              one {# day}
              other {# days}
            }
          }
          WEEKS {
            {count, plural,
              one {# week}
              other {# weeks}
            }
          }
          MONTHS {
            {count, plural,
              one {# month}
              other {# months}
            }
          }
          other {
            {count, plural,
              one {# minute}
              other {# minutes}
            }
          }
        }`,
  },
} as const)
