import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Radio } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { TimeBefore } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { perksMessages } from '../../locales'
import type { PerkCreateAccessRuleModalFormField } from './PerkCreateAccessRuleModalForm.zod'

interface PerkCreateAccessRuleModalReleaseTablesProps {
  field: PerkCreateAccessRuleModalFormField
}

export function PerkCreateAccessRuleModalReleaseTables({ field }: PerkCreateAccessRuleModalReleaseTablesProps) {
  const { formatMessage } = useLocales()
  const enableReleaseTables = useWatch(field.prop('enableReleaseTables'))

  return (
    <VStack spacing="s" mt="s" height="320px">
      <Radio field={field.prop('enableReleaseTables')} value={1} checked={!!enableReleaseTables}>
        {formatMessage(perksMessages.accessRuleModalReleaseReservedTables)}
      </Radio>
      {!!enableReleaseTables && (
        <VStack pl="lm">
          <TimeBefore field={field.prop('releaseTableTimeData')} data-test="time-before" />
        </VStack>
      )}
      <VStack mt="m">
        <Radio field={field.prop('enableReleaseTables')} value={0} checked={!enableReleaseTables}>
          {formatMessage(perksMessages.accessRuleModalDoNotReleaseReservedTables)}
        </Radio>
      </VStack>
    </VStack>
  )
}
