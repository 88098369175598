import * as React from 'react'
import { FormProvider } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useParams } from '@sevenrooms/core/navigation'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { type PaymentIntegration, usePaymentIntegration } from '../../Integrations'
import { PaymentSettingsButtons } from '../PaymentSettingsButtons'
import { messages } from './EditPaymentIntegrationPage.locales'

export function EditPaymentIntegrationPage() {
  const { provider } = useParams<{ provider?: string }>()
  const { isFetching, integration } = usePaymentIntegration(provider)
  if (isFetching || !integration) {
    return null
  }

  return <EditPaymentIntegrationView integration={integration} />
}

function EditPaymentIntegrationView({ integration }: { integration: PaymentIntegration }) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { ...formMethods } = integration.useFormHook && integration.useFormHook(integration.formDefault)
  if (!venue || !formMethods) {
    return null
  }
  const { SettingsForm } = integration

  return (
    <>
      <SettingsPageMeta title={formatMessage(messages.editIntegration)} venue={venue.name} />
      <FormProvider {...formMethods}>
        <SettingsPageContent
          title={formatMessage(messages.editIntegration)}
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          actions={<PaymentSettingsButtons />}
        >
          {SettingsForm && <SettingsForm />}
        </SettingsPageContent>
      </FormProvider>
    </>
  )
}
