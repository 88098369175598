import React, { type PropsWithChildren } from 'react'
import type { Field, UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, ImageUploader } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, FormEditor } from '@sevenrooms/core/ui-kit/optional'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import TimeSlotPreviewImage from '@sevenrooms/mgr-marketing-templates-gallery/assets/stepper-flow/images/time-slot-preview.svg'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/offerTemplates.locales'
import { RecommendationTip } from '../RecommendationTip'
import type { CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

export function OfferDetails({
  form,
  setShowNextButton,
}: {
  form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>>
  setShowNextButton: (show: boolean) => void
}) {
  setShowNextButton(true)
  const { formatMessage } = useLocales()
  const { field } = form
  const heroImageAspectRatio = 2.25

  return (
    <VStack pt="lm" pb="lm" spacing="lm" width="496px">
      <VStack spacing="xs" alignItems="center">
        <Text textStyle="h2">{formatMessage(messages.offerDetailsStepTitle)}</Text>
        <Text textStyle="body1" color="secondaryFont">
          {formatMessage(messages.offerDetailsStepDescription)}
        </Text>
      </VStack>
      <VStack>
        <OfferDetailsFormInput label={formatMessage(messages.offerTitle)} field={field.prop('name')} placeholder="" />
        <RecommendationTip
          header={formatMessage(messages.recommendationTipBestPractices)}
          description={formatMessage(messages.offerTitleRecommendation)}
          headerIcon="bestPractices"
        />
      </VStack>
      <OfferDetailsFormEditor label={formatMessage(messages.offerDescription)} field={field.prop('description')} />
      <VStack>
        <OfferDetailsFormInput
          label={formatMessage(messages.offerPreviewDescription)}
          field={field.prop('previewDescription')}
          placeholder={formatMessage(messages.offerPreviewDescriptionPlaceholder)}
        />
        <RecommendationTip
          header={formatMessage(messages.recommendationTipBestPractices)}
          description={formatMessage(messages.offerPreviewDescriptionRecommendation)}
          headerIcon="bestPractices"
          imageSrc={TimeSlotPreviewImage}
        />
      </VStack>
      <OfferDetailsFormInput label={formatMessage(messages.offerPrice)} field={field.prop('price')} placeholder="" />
      <OfferDetailsFieldWrapper label={formatMessage(messages.offerImage)}>
        <ImageUploader field={field.prop('heroImage')} aspect={heroImageAspectRatio} unit="%" data-test="offer-details-image-uploader" />
      </OfferDetailsFieldWrapper>
    </VStack>
  )
}

interface OfferDetailsFieldWrapperProps {
  label: string
}
interface OfferDetailsFormEditorProps extends OfferDetailsFieldWrapperProps {
  field: Field<string>
}
interface OfferDetailsFormInputProps extends OfferDetailsFormEditorProps {
  placeholder: string
}

function OfferDetailsFieldWrapper({ label, children }: PropsWithChildren<OfferDetailsFieldWrapperProps>) {
  return (
    <VStack spacing="xs" borderWidth="s" borderRadius="s" borderColor="dividerLines" p="m">
      <Text textStyle="body1Bold">{label}</Text>
      {children}
    </VStack>
  )
}

function OfferDetailsFormInput({ label, field, placeholder }: OfferDetailsFormInputProps) {
  return (
    <OfferDetailsFieldWrapper label={label}>
      <FormInput field={field} placeholder={placeholder} />
    </OfferDetailsFieldWrapper>
  )
}

function OfferDetailsFormEditor({ label, field }: OfferDetailsFormEditorProps) {
  return (
    <OfferDetailsFieldWrapper label={label}>
      <FormEditor
        field={field}
        config={{
          ...defaultConfig,
          heightMin: 100,
          toolbarButtons: [
            'textColor',
            'bold',
            'italic',
            'underline',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'formatUL',
            'formatOL',
            'insertLink',
          ],
          toolbarBottom: false,
          useClasses: false,
        }}
        data-test="offer-details-freola-input"
      />
    </OfferDetailsFieldWrapper>
  )
}
