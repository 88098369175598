import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  stripe: {
    id: 'paymentsSettings.RecommendedIntegration.stripe',
    defaultMessage: 'Stripe',
  },
  stripeDescription: {
    id: 'paymentsSettings.RecommendedIntegration.stripeDescription',
    defaultMessage: 'Supports Apple Pay, Google Pay, 3DS, and Online Ordering. Global availability.',
  },
  learnMore: {
    id: 'paymentsSettings.RecommendedIntegration.learnMore',
    defaultMessage: 'Learn more',
  },
  recommendedIntegration: {
    id: 'paymentsSettings.RecommendedPaymentIntegration.statusLabelText',
    defaultMessage: 'Recommended',
  },
  setup: {
    id: 'paymentsSettings.RecommendedPaymentIntegration.setup',
    defaultMessage: 'Setup',
  },
  setupIncompleteLabel: {
    id: 'paymentSettings.RecommendedPaymentIntegration.setupIncompleteLabel',
    defaultMessage: 'Pending',
  },
  finishSetup: {
    id: 'paymentsSettings.RecommendedPaymentIntegration.finishSetup',
    defaultMessage: 'Finish setup',
  },
  cancelSetup: {
    id: 'paymentsSettings.RecommendedPaymentIntegration.cancelSetup',
    defaultMessage: 'Cancel setup',
  },
} as const)
