import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { PropsWithChildren } from 'react'

export interface SettingsBlockProps {
  header: string
}

export function SettingsBlock({ children, header }: PropsWithChildren<SettingsBlockProps>) {
  return (
    <VStack spacing="l" flexWrap="wrap" p="m" m="m" backgroundColor="primaryBackground" maxWidth="968px">
      <Text textStyle="h2">{header}</Text>
      <HStack spacing="l">{children}</HStack>
    </VStack>
  )
}

export function SettingsBlockInputs({ children }: PropsWithChildren<{}>) {
  return (
    <VStack flex={1} spacing="m">
      {children}
    </VStack>
  )
}
