import { defineMessages } from '@sevenrooms/core/locales'

export const oneTimeSMSCenterMessages = defineMessages({
  oneTimeSMSCenterTitle: {
    id: 'oneTimeSMSCenter.title',
    defaultMessage: 'Text Marketing',
  },
  oneTimeSMSCenterDescription: {
    id: 'oneTimeSMSCenter.description',
    defaultMessage: "Send Text Marketing campaigns to guests to share events, last-minute availability, or anything you'd like to promote.",
  },
  oneTimeSMSCenterDescriptionWithPhoneNumber: {
    id: 'oneTimeSMSCenter.descriptionWithPhoneNumber',
    defaultMessage:
      "Send Text Marketing campaigns to guests to share events, last-minute availability, or anything you'd like to promote. Your Text Marketing phone number is {phone_number}.",
  },
  oneTimeSMSCenterNewSMS: {
    id: 'oneTimeSMSCenter.newSMS',
    defaultMessage: 'Create New Text',
  },
  campaignName: {
    id: 'oneTimeSMSs.campaignName',
    defaultMessage: 'Campaigns',
  },
  campaignRecipientsTagLeadIn: {
    id: 'oneTimeSMSs.campaignRecipientsTagLeadIn',
    defaultMessage: 'Guests in',
  },
  campaignRecipientsNoTags: {
    id: 'oneTimeSMSs.campaignRecipientsNoTags',
    defaultMessage: 'All Text Subscribers',
  },
  draft: {
    id: 'oneTimeSMSs.draft',
    defaultMessage: 'Draft',
  },
  scheduled: {
    id: 'oneTimeSMSs.scheduled',
    defaultMessage: 'Scheduled',
  },
  processing: {
    id: 'oneTimeSMSs.processing',
    defaultMessage: 'Processing',
  },
  sent: {
    id: 'oneTimeSMSs.sent',
    defaultMessage: 'Sent',
  },
  complete: {
    id: 'oneTimeSMSs.complete',
    defaultMessage: 'Sent',
  },
  confirmModalTitle: {
    id: 'oneTimeSMSs.confirmModalTitle',
    defaultMessage: 'Are you sure you want to edit a scheduled campaign?',
  },
  confirmModalInformation: {
    id: 'oneTimeSMSs.confirmModalInformation',
    defaultMessage: 'Editing a scheduled campaign will force the campaign to be converted into "Draft" state.',
  },
  confirmModalDelete: {
    id: 'oneTimeSMSs.confirmDelete',
    defaultMessage: 'Yes, I want to edit',
  },
  campaignSaveSuccess: {
    id: 'oneTimeSMSs.campaignSaveSuccess',
    defaultMessage: 'Text campaign archived successfully',
  },
  emptyTableTitleNoData: {
    id: 'oneTimeSMSs.emptyTableTitle.noData',
    defaultMessage: 'No text campaigns have been created yet',
  },
  emptyTableDescriptionNoData: {
    id: 'oneTimeSMSs.emptyTableDescription.noData',
    defaultMessage: 'Get started by',
  },
  emptyTableDescriptionLinkNoData: {
    id: 'oneTimeSMSs.emptyTableDescription.noData',
    defaultMessage: 'creating a text',
  },
  emptyTableDescriptionSearch: {
    id: 'oneTimeSMSs.emptyTableDescription.search',
    defaultMessage: 'No text campaign with a name containing "{searchValue}"',
  },
  emptyTableDescriptionFilters: {
    id: 'oneTimeSMSs.emptyTableDescription.filters',
    defaultMessage: 'No text campaigns match the selected filters',
  },
  confirmSendSMSTitle: {
    id: 'oneTimeSMSs.confirmSendSMSTitle',
    defaultMessage: 'Did you send yourself a test?',
  },
  confirmSendSMSText: {
    id: 'oneTimeSMSs.confirmSendSMSText',
    defaultMessage: 'Send a test to ensure short links, shortcodes, copy, and media look as expected.',
  },
  confirmSMSCancelButtonText: {
    id: 'oneTimeSMSs.confirmSMSCancelButtonText',
    defaultMessage: 'Go back to test',
  },
  confirmScheduleButtonText: {
    id: 'oneTimeSMSs.confirmScheduleButtonText',
    defaultMessage: 'Yes, schedule Text Campaign',
  },
  confirmSendButtonText: {
    id: 'oneTimeSMSs.confirmSendButtonText',
    defaultMessage: 'Yes, send Text Campaign',
  },
  confirmSendSMSNoTagsRecipients: {
    id: 'oneTimeSMSs.confirmSendSMSNoTagsRecipients',
    defaultMessage: 'Text Opt-Ins',
  },
  confirmArchiveSMSModalTitle: {
    id: 'confirmArchiveSMSModalTitle',
    defaultMessage: 'Are you sure you wish to archive this campaign?',
  },
  confirmArchiveSMSModalInformation: {
    id: 'confirmArchiveSMSModalInformation',
    defaultMessage: 'Archiving this campaign will remove it from the active Text Marketing center.',
  },
  confirmArchiveSMSModalHistoryInfo: {
    id: 'confirmArchiveSMSModalHistoryInfo',
    defaultMessage: "We will keep all of the campaign's historical performance data.",
  },
  cancelArchiveSMSModal: {
    id: 'cancelArchiveSMSModal',
    defaultMessage: 'Cancel',
  },
  confirmArchiveSMSModal: {
    id: 'confirmArchiveSMSModal',
    defaultMessage: 'Confirm Archive',
  },
  smsTemplatesTitle: {
    id: 'smsCenter.smsTemplates.title',
    defaultMessage: 'Text Marketing Examples',
  },
  previewTemplateTitle: {
    id: 'smsCenter.smsTemplates.templateView.title',
    defaultMessage: 'Preview Example',
  },
  useTemplate: {
    id: 'smsCenter.smsTemplates.templateView.cancelButton',
    defaultMessage: 'Use This Example',
  },
  smsTemplateEmptyCardTitle: {
    id: 'smsCenter.smsTemplates.emptyCardTitle',
    defaultMessage: 'Create from scratch',
  },
  smsTemplateEmptyCardText: {
    id: 'smsCenter.smsTemplates.emptyCardText',
    defaultMessage: 'Start from a blank design',
  },
  smsCampaignTemplatesLinkMessage: {
    id: 'smsCenter.oneTimeSMSCampaignTemplates.smsCampaignTemplatesLinkMessage',
    defaultMessage: 'Text Marketing Dashboard',
  },
  smsCampaignTemplatesBreadcrumbText: {
    id: 'smsCenter.oneTimeSMSCampaignTemplates.smsCampaignTemplatesBreadcrumbText',
    defaultMessage: 'Text Marketing Examples',
  },
  recipientsColumnHeading: {
    id: 'oneTimeCampaigns.recipientsColumnHeading',
    defaultMessage: 'Messages Sent',
  },
  sendTestModalHeading: {
    id: 'oneTimeCampaigns.sendTestModalHeading',
    defaultMessage: 'Send a text message',
  },
  sendTestModalSubheading: {
    id: 'oneTimeCampaigns.sendTestModalSubheading',
    defaultMessage: 'Immediately send a text message for review.',
  },
  sendTestModalNumberInputLabel: {
    id: 'oneTimeCampaigns.sendTestModalNumberInputLabel',
    defaultMessage: 'Phone numbers',
  },
  sendTestModalWarning: {
    id: 'oneTimeCampaigns.sendTestModalWarning',
    defaultMessage:
      'All phone numbers entered here will be opted in for Text Marketing if they are not already opted in. Please ensure the recipient has given their consent.',
  },
  sendTestModalAddButtonLabel: {
    id: 'oneTimeCampaigns.sendTestModalAddButtonLabel',
    defaultMessage: 'Add another phone number',
  },
  sendTestModalConfirmButtonLabel: {
    id: 'oneTimeCampaigns.sendTestModalConfirmButtonLabel',
    defaultMessage: 'Send Test Text',
  },
  sendTestModalErrorInvalidNumber: {
    id: 'oneTimeCampaign.sendTestModalErrorInvalidNumber',
    defaultMessage: 'Please enter a valid phone number',
  },
} as const)
