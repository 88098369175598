import React from 'react'
import { HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'

export function LoadingSettings() {
  return (
    <>
      <VStack
        width="1016px"
        height="450px"
        m="m"
        backgroundColor="primaryBackground"
        maxWidth="1016px"
        alignItems="center"
        justifyContent="center"
      >
        <HStack flexGrow="1" alignItems="center" justifyContent="center">
          <Loader />
        </HStack>
      </VStack>
    </>
  )
}
