import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import { ConflictModal } from './ConflictModal'
import type { PacingForm } from '../Pacing.zod'

export interface PacingHeldWarningProps {
  customPacing: PacingForm['customPacing']
  maxCoversPerSeatingInterval: number | null
}

export function PacingHeldWarning({ customPacing, maxCoversPerSeatingInterval }: PacingHeldWarningProps) {
  const { formatMessage } = useLocales()
  const [conflictIsActive, setConflictIsActive] = useState(false)

  return (
    <>
      <Box>
        <Text color="secondaryFont" data-test={PacingTestId.viewConflicts}>
          {formatMessage(PacingLocales.isPacingHeldNote, {
            a: (chunks: string[]) => (
              <Button variant="tertiary" onClick={() => setConflictIsActive(true)} noPadding data-test={PacingTestId.viewConflicts}>
                {chunks}
              </Button>
            ),
          })}
        </Text>
      </Box>
      <Window active={conflictIsActive}>
        <ConflictModal
          onClose={() => setConflictIsActive(false)}
          customPacing={customPacing}
          maxCoversPerSeatingInterval={maxCoversPerSeatingInterval}
          data-test={PacingTestId.conflictModal}
        />
      </Window>
    </>
  )
}
