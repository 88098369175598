import { useCallback, useMemo, useState } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { LanguageStrings, ReservationWidgetSettingsLanguageString, VenueLanguage } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { DescriptionColumn, OtherLanguagesTable, type OtherLanguagesTableColumn } from '../../components'
import { checkoutLanguagesTableMessages } from './CheckoutLanguagesTable.locales'

export interface CheckoutLanguagesTableProps {
  languageStrings: LanguageStrings
  onChange: (value: LanguageStrings) => void
  languageOptions: SelectOption<LanguageCode>[]
  defaultLanguage: VenueLanguage
}

export function CheckoutLanguagesTable({ languageStrings, onChange, languageOptions, defaultLanguage }: CheckoutLanguagesTableProps) {
  const { formatMessage } = useLocales()
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]?.id)

  const onLanguageStringChange = useCallback(
    (value: string, language: LanguageCode, stringToChange: ReservationWidgetSettingsLanguageString) => {
      const updatedLanguageStrings = {
        ...languageStrings,
        [language]: {
          ...languageStrings[language],
          [stringToChange]: value,
        },
      }
      onChange(updatedLanguageStrings)
    },
    [languageStrings, onChange]
  )

  const tableData = useMemo(
    () =>
      [
        {
          id: 'resConfirmPageOtherInfoLabel',
          description: (
            <DescriptionColumn primary={formatMessage(checkoutLanguagesTableMessages.otherLanguagesTableCustomQuestionColumnLabel)} />
          ),
          defaultLanguage: languageStrings[defaultLanguage?.value].resConfirmPageOtherInfoLabel,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resConfirmPageOtherInfoLabel : '',
        },
      ] as OtherLanguagesTableColumn[],
    [formatMessage, defaultLanguage, languageStrings, selectedLanguage]
  )

  return (
    <OtherLanguagesTable
      data={tableData}
      onChange={onLanguageStringChange}
      onSelectedLanguageChange={setSelectedLanguage}
      defaultLanguage={defaultLanguage}
      languageOptions={languageOptions}
      selectedLanguage={selectedLanguage as LanguageCode}
    />
  )
}
