import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { spacesMessages } from '../../spaces.locales'

enum EventTypes {
  HAPPY_HOUR = 'Happy Hour',
  COCKTAIL_RECEPTION = 'Cocktail Reception',
  PRIVATE_DINING = 'Private Dining',
  LARGE_GROUP_BOOKING = 'Large Group Booking',
}

export function useEventTypesOptions() {
  const { formatMessage } = useLocales()

  return useMemo<Array<SelectOption<EventTypes>>>(
    () => [
      {
        id: EventTypes.HAPPY_HOUR,
        label: formatMessage(spacesMessages.happyHour),
      },
      {
        id: EventTypes.COCKTAIL_RECEPTION,
        label: formatMessage(spacesMessages.cocktailReception),
      },
      {
        id: EventTypes.PRIVATE_DINING,
        label: formatMessage(spacesMessages.privateDining),
      },
      {
        id: EventTypes.LARGE_GROUP_BOOKING,
        label: formatMessage(spacesMessages.largeGroupBooking),
      },
    ],
    [formatMessage]
  )
}
