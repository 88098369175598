import _ from 'lodash'
import moment from 'moment-timezone'

export function getDateTimeOptions() {
  // Generates 15 minutes interval between 12:00 AM and 11:45 PM in the US locale
  const refDate = new Date()
  refDate.setHours(0, 0, 0, 0)
  return _.fromPairs(
    _.times(24 * 4, () => {
      refDate.setMinutes(refDate.getMinutes() + 15)
      return [
        refDate.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
        }),
        refDate.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        }),
      ]
    })
  )
}

export const daysOfTheWeekShort = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

/**
 * @param {string | moment.Moment} stringTime
 * @param {string | moment.Moment} format
 * @returns {string} string to defined format, by default AM/PM
 */
export const timeHHmmssToFormat = (stringTime, format = 'LT') => moment(stringTime, ['HH:mm:ss']).format(format)

export const dateToFormat = (date, format, initialDtFormat = null) => moment(date, initialDtFormat).format(format)

export const UTCdateToTimezoneFormat = (date, timezone, format) => moment.utc(date).tz(timezone).format(format)
