import { defineMessages } from '@sevenrooms/core/locales'

export const promoCodeManagementMessages = defineMessages({
  promoCodeTitle: {
    id: 'promoCode.title',
    defaultMessage: 'Promo Code',
  },
  promoCodesTitle: {
    id: 'promoCode.list.title',
    defaultMessage: 'Promo Code Management',
  },
  promoCodesDescription: {
    id: 'promoCode.list.description',
    defaultMessage: 'These promo codes are used at checkout on the widgets.',
  },
  promoCodesCreateBtn: {
    id: 'promoCode.list.createBtn',
    defaultMessage: 'Create Promo Code',
  },
  promoCodesSectionTitle: {
    id: 'promoCode.list.section.title',
    defaultMessage: 'Promo Codes',
  },
  promoCodesDeleteSuccessMessage: {
    id: 'promoCode.list.delete.message.success',
    defaultMessage: 'Promo code has been successfully deleted.',
  },
  promoCodesDeleteErrorMessage: {
    id: 'promoCode.list.delete.message.error',
    defaultMessage: 'There was an error when deleting the promo code. Please try again.',
  },
  promoCodesFilterByStatusLabel: {
    id: 'promoCode.list.section.filterBy.status.label',
    defaultMessage: 'Status',
  },
  promoCodesFilterByStatusPlaceholder: {
    id: 'promoCode.list.section.filterBy.status.placeholder',
    defaultMessage: 'Filter by',
  },
  promoCodesFilterByDiscountLabel: {
    id: 'promoCode.list.section.filterBy.discount.label',
    defaultMessage: 'Discount Type',
  },
  promoCodesFilterByDiscountPlaceholder: {
    id: 'promoCode.list.section.filterBy.discount.placeholder',
    defaultMessage: 'Filter by',
  },
  promoCodesFilterByWidgetTypeLabel: {
    id: 'promoCode.list.section.filterBy.widgetType.label',
    defaultMessage: 'Widget Type',
  },
  promoCodesFilterByWidgetTypePlaceholder: {
    id: 'promoCode.list.section.filterBy.widgetType.placeholder',
    defaultMessage: 'Filter by',
  },
  promoCodesFilterBySearchStringPlaceholder: {
    id: 'promoCode.list.section.filterBy.searchString.placeholder',
    defaultMessage: 'Search by name or code',
  },
  promoCodesTableColumnNameName: {
    id: 'promoCode.table.columnName.name',
    defaultMessage: 'Name',
  },
  promoCodesTableColumnNameCode: {
    id: 'promoCode.table.columnName.code',
    defaultMessage: 'Code',
  },
  promoCodesTableColumnNameDiscountAmount: {
    id: 'promoCode.table.columnName.discountAmount',
    defaultMessage: 'Discount Amount',
  },
  promoCodesTableColumnValuePercentageDiscountAmount: {
    id: 'promoCode.table.columnValue.percentageDiscountAmount',
    defaultMessage: '{value}% off',
  },
  promoCodesTableColumnNameDateRange: {
    id: 'promoCode.table.columnName.dateRange',
    defaultMessage: 'Date Range',
  },
  promoCodesTableDateRangeColumnValueUnknown: {
    id: 'promoCode.table.columnValue.dateRange.unknown',
    defaultMessage: 'Unknown',
  },
  promoCodesTableMinSubtotalValue: {
    id: 'promoCode.table.columnValue.minSubtotal',
    defaultMessage: 'Min {value}',
  },
  promoCodesTablePromoValueCap: {
    id: 'promoCode.table.columnValue.promoValueCap',
    defaultMessage: 'Max {value}',
  },
  promoCodesTableUsageValueUnlimited: {
    id: 'promoCode.table.columnValue.usage.unlimited',
    defaultMessage: 'unlimited',
  },
  promoCodesTableUsageValue: {
    id: 'promoCode.table.columnValue.usage',
    defaultMessage: '{count}/{total}',
  },
  promoCodesTableDateRangeColumnValueIndefinite: {
    id: 'promoCode.table.columnValue.dateRange.indefinite',
    defaultMessage: 'Indefinite',
  },
  promoCodesTableColumnNameDateRangeDefault: {
    id: 'promoCode.table.columnName.dateRange.default',
    defaultMessage: 'All Dates',
  },
  promoCodesTableColumnNameUsedTotal: {
    id: 'promoCode.table.columnName.usedTotal',
    defaultMessage: 'Used/Total',
  },
  promoCodesTableColumnNameLocationUsed: {
    id: 'promoCode.table.columnName.locationUsed',
    defaultMessage: 'Location Used',
  },
  promoCodesTableColumnNameStatus: {
    id: 'promoCode.table.columnName.used',
    defaultMessage: 'Status',
  },
  promoCodesTableColumnNameAction: {
    id: 'promoCode.table.columnName.action',
    defaultMessage: ' ',
  },
  promoCodesTableMenuItemEdit: {
    id: 'promoCode.table.menuItem.edit',
    defaultMessage: 'Edit',
  },
  promoCodesTableMenuItemDelete: {
    id: 'promoCode.table.menuItem.delete',
    defaultMessage: 'Delete',
  },
  promoCodesTablePromoRangeColumnValueReservationDate: {
    id: 'promoCode.table.columnValue.promoRange.reservationDate',
    defaultMessage: 'Encourage Advance Booking',
  },
  promoCodesTablePromoRangeColumnDescriptionReservationDate: {
    id: 'promoCode.table.columnDescription.promoRange.reservationDate',
    defaultMessage:
      'Based on the date the guest books/orders for. ' +
      'Used to encourage booking ahead/ordering in advance for a specific set up days/days of the week.',
  },
  promoCodesTablePromoRangeColumnDescriptionExampleReservationDate: {
    id: 'promoCode.table.columnDescriptionExample.promoRange.reservationDate',
    defaultMessage: 'Example: “Book now for Mondays-Wednesdays in May and get 10% off at checkout with GET10.”',
  },
  promoCodesTablePromoRangeColumnValueBookingDate: {
    id: 'promoCode.table.columnValue.promoRange.bookingDate',
    defaultMessage: 'Limited Time Special',
  },
  promoCodesTablePromoRangeColumnDescriptionBookingDate: {
    id: 'promoCode.table.columnDescription.promoRange.bookingDate',
    defaultMessage: 'Based on the date the guest uses the promo at checkout.',
  },
  promoCodesTablePromoRangeColumnDescriptionExampleBookingDate: {
    id: 'promoCode.table.columnDescriptionExample.promoRange.bookingDate',
    defaultMessage: 'Example: “Get 10% off if you use promo GET10 at checkout between 5/1-5/10.”',
  },
  promoCodesTablePromoRangeColumnValueNoExpiration: {
    id: 'promoCode.table.columnValue.promoRange.noExpiration',
    defaultMessage: 'All Dates',
  },
  promoCodesTablePromoRangeColumnDescriptionNoExpiration: {
    id: 'promoCode.table.columnDescription.promoRange.noExpiration',
    defaultMessage: ' ',
  },
  promoCodesTableUsageColumnValueVenue: {
    id: 'promoCode.table.columnValue.usage.venue',
    defaultMessage: 'This venue only',
  },
  promoCodesTableUsageColumnValueVenueGroup: {
    id: 'promoCode.table.columnValue.usage.venueGroup',
    defaultMessage: 'All venues in group',
  },

  promoCodesTablePlatformApplyTypeColumnValueOrdering: {
    id: 'promoCode.table.columnValue.platformApplyType.ordering',
    defaultMessage: 'Ordering Widget',
  },
  promoCodesTablePlatformApplyTypeColumnValueReservation: {
    id: 'promoCode.table.columnValue.platformApplyType.reservation',
    defaultMessage: 'Reservation Widget',
  },
  promoCodesTablePlatformApplyTypeColumnValueEvent: {
    id: 'promoCode.table.columnValue.platformApplyType.event',
    defaultMessage: 'Event Widget',
  },
  promoCodesTableStatusColumnValueActive: {
    id: 'promoCode.table.columnValue.status.active',
    defaultMessage: 'Active',
  },
  promoCodesTableStatusColumnValueInactive: {
    id: 'promoCode.table.columnValue.status.inactive',
    defaultMessage: 'Inactive',
  },
  promoCodesTableStatusColumnValueUpcoming: {
    id: 'promoCode.table.columnValue.status.upcoming',
    defaultMessage: 'Upcoming',
  },
  promoCodesTableDiscountTypeColumnValueFixed: {
    id: 'promoCode.table.columnValue.discountType.fixed',
    defaultMessage: 'Fixed amount',
  },
  promoCodesTableDiscountTypeColumnValuePercent: {
    id: 'promoCode.table.columnValue.discountType.percent',
    defaultMessage: 'Percentage off',
  },
  promoCodesDeleteModalTitle: {
    id: 'promoCode.modal.delete.title',
    defaultMessage: 'Are you sure that you want to delete this promo code?',
  },
  promoCodesDeleteModalContent: {
    id: 'promoCode.modal.delete.content',
    defaultMessage: 'This action cannot be undone.',
  },
  promoCodesBannerPrimaryText: {
    id: 'promoCode.banner.text.primary',
    defaultMessage: 'No Promo Codes have been made',
  },
  promoCodesBannerSecondaryText: {
    id: 'promoCode.banner.text.secondary',
    defaultMessage: 'If you want to create new promo codes just press the blue button on top right corner!',
  },
} as const)
