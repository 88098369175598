export const PaymentPolicyTestId = {
  section: 'payment-policy',
  addAnUpgrade: 'add-an-upgrade',
  allowOnUnsupportedChannels: 'allow-on-unsupported-channels',
  charges: 'access-rule-payment-charges',
  bookingCharge: 'booking-charge',
  bookingPolicy: 'booking-policy',
  cancelChargeRadios: 'cancel-charge-radios',
  cancelCutoff: 'cancel-cutoff',
  cancelPolicy: 'cancel-policy',
  cancelRadios: 'cancel-radios',
  chargeCutoff: 'charge-cutoff',
  minPartySize: 'min-party-size',
  noShowCharge: 'no-show-charge',
  partialRefund: 'partial-refund',
  partySizeFilter: 'party-size-filter',
  paymentRuleRadios: 'payment-rule-radios',
  refundRadios: 'refund-radios',
  refundTooltip: 'refund-tooltip',
  setChargeCutoff: 'set-charge-cutoff',
  totalAdvancedCharges: 'total-advanced-charges',
  upgradesGroup: 'upgrades-group',
  report: 'payment-policy-report',
  followShiftSettingsForPayment: 'follow-shift-settings-for-payment',
} as const
