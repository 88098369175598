import { useTestConfigurationMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateTime } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack, Loader, Grid, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text, Anchor, ProgressStatusLabel, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { AccountTypes } from '@sevenrooms/payments'
import {
  GeneralDocumentationLink,
  IntegrationsMapping,
  type PaymentIntegration,
  type PaymentIntegrationMeta,
  type usePaymentIntegrationReturn,
} from '../../Integrations'
import { ViewSettingsBlock, ProcessorCircleLogoGeneric, ProcessorCircleLogoStripe } from '../../Integrations/ViewSettingsBlock'
import { PaymentsValueProp } from '../../PaymentsValueProp'
import { EditIntegrationButton } from '../EditIntegrationButton'
import { StripeDisconnectButton } from '../StripeDisconnectButton'
import { messages } from './ViewPaymentIntegration.locales'

interface ViewPaymentIntegrationProps {
  paymentIntegration: usePaymentIntegrationReturn
}

export function ViewPaymentIntegration({ paymentIntegration }: ViewPaymentIntegrationProps) {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { integration } = paymentIntegration
  const [testConfiguration] = useTestConfigurationMutation()

  if (paymentIntegration.isFetching) {
    return <Loader />
  }
  if (!integration) {
    return null
  }

  const { integrationName, ShowCurrentSettings, currentConfiguration } = integration
  const isStripe = integrationName === AccountTypes.STRIPE

  const integrationData = IntegrationsMapping[integrationName]
  if (!integrationData) {
    return null
  }
  const { logo, title, errorResolveLink } = integrationData

  const testIntegration = async () => {
    const result = await testConfiguration({ venueId: venue.id }).unwrap()

    if (result) {
      notify({ content: formatMessage(messages.success), type: 'success' })
    } else {
      notify({ content: formatMessage(messages.error), type: 'error' })
    }
  }

  const { lastSync, lastSyncResult } = (integration as PaymentIntegration)?.meta as PaymentIntegrationMeta

  return (
    <>
      <SettingsPageMeta title={formatMessage(messages.paymentIntegration)} venue={venue?.name} />

      <SettingsPageContent
        title={formatMessage(messages.paymentIntegration)}
        description={formatMessage(messages.readMore, {
          a: (chunks: string[]) => <Anchor href={GeneralDocumentationLink}>{chunks}</Anchor>,
        })}
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="968px"
      >
        <VStack m="m" backgroundColor="primaryBackground" maxWidth="968px">
          <HStack spacing="m" ml="m" mr="m" pt="m" pb="m" pl="m" pr="m" flexGrow="1" alignItems="center" justifyContent="space-between">
            <HStack spacing="s" justifyContent="center" alignItems="center">
              {isStripe ? (
                <ProcessorCircleLogoStripe processorLogo={logo} processorTitle={title} />
              ) : (
                <ProcessorCircleLogoGeneric processorLogo={logo} processorTitle={title} />
              )}
              <HStack spacing="s" pl="m" justifyContent="center" alignItems="end">
                <Text textStyle="h2">{title}</Text>
                {lastSync && !lastSyncResult && (
                  <ProgressStatusLabel variant="error">{formatMessage(messages.offlineLabel)}</ProgressStatusLabel>
                )}
                {lastSync && lastSyncResult && (
                  <ProgressStatusLabel variant="success">{formatMessage(messages.onlineLabel)}</ProgressStatusLabel>
                )}
              </HStack>
            </HStack>
            <Box>{isStripe ? <StripeDisconnectButton /> : <EditIntegrationButton />}</Box>
          </HStack>

          <Grid gridTemplateColumns="1fr 3fr">
            <ViewSettingsBlock>
              <VStack spacing="xxs">
                <Text textStyle="h3">{formatMessage(messages.integrationDetails)}</Text>
              </VStack>
              <VStack flexWrap="wrap" spacing="s">
                <Text color="secondaryFont">
                  {formatMessage(messages.lastSynced)}: {(lastSync as DateTime)?.formatNYearSMonthNDaySWeekSTime() || '-'}
                </Text>
                <Box mb="s" onClick={testIntegration}>
                  <Button variant="secondary" data-test="test-integration">
                    {formatMessage(messages.testIntegration)}
                  </Button>
                </Box>
                {lastSync && !lastSyncResult && <OfflineNotification processorErrorResolveLink={errorResolveLink} />}
              </VStack>
            </ViewSettingsBlock>
            <ShowCurrentSettings currentConfiguration={currentConfiguration} />
          </Grid>
        </VStack>
        <PaymentsValueProp />
      </SettingsPageContent>
    </>
  )
}

export function OfflineNotification({ processorErrorResolveLink }: { processorErrorResolveLink: string }) {
  const { formatMessage } = useLocales()
  const integrationOfflineError = formatMessage(messages.integrationOfflineError, {
    a: (str: string) => (
      <Anchor target="_blank" href={processorErrorResolveLink}>
        <Text textStyle="body1" textDecoration="underline" color="secondaryFont">
          {str}
        </Text>
      </Anchor>
    ),
  })

  return (
    <VStack
      mt="m"
      p="m"
      spacing="xs"
      alignItems="baseline"
      justifyContent="center"
      flexWrap="wrap"
      backgroundColor="errorBackground"
      borderRadius="s"
    >
      <Text fontWeight="bold">{formatMessage(messages.integrationOffline)}</Text>
      <SecondaryText>{integrationOfflineError}</SecondaryText>
    </VStack>
  )
}
