import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  tabTitle: {
    id: 'posiSettings.SimphonyAuthenticatePage.tabTitle',
    defaultMessage: 'Point of Sale',
  },
  title: {
    id: 'posiSettings.SimphonyAuthenticatePage.title',
    defaultMessage: 'Configure Point of Sale Integration',
  },
  description: {
    id: 'posiSettings.SimphonyAuthenticatePage.description',
    defaultMessage: 'Authenticate your integration before completing any configuration. <a>Learn more</a>',
  },
  authenticationInformationLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.authenticationInformationLabel',
    defaultMessage: 'Authentication',
  },
  authenticationInformationDescription: {
    id: 'posiSettings.SimphonyAuthenticatePage.authenticationInformationDescription',
    defaultMessage: '<a>View Docs</a>',
  },
  usernameLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.usernameLabel',
    defaultMessage: 'API Account Username',
  },
  passwordLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.passwordLabel',
    defaultMessage: 'API Account Password',
  },
  orgShortNameLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.orgShortNameLabel',
    defaultMessage: 'Simphony Org Short Name',
  },
  openidUrlLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.openidUrlLabel',
    defaultMessage: 'OpenID Connect Provider URL',
  },
  simphonyBaseUrlLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.simphonyBaseUrlLabel',
    defaultMessage: 'Simphony Transaction Services (Gen2) URL',
  },
  openidClientIdLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.openidClientIdLabel',
    defaultMessage: 'Client ID',
  },
  submitButtonLabel: {
    id: 'posiSettings.SimphonyAuthenticatePage.submitButtonLabel',
    defaultMessage: 'Authenticate',
  },
  authenticateSuccess: {
    id: 'posiSettings.SimphonyAuthenticatePage.authenticateSuccess',
    defaultMessage: 'Successfully authenticated with Simphony.',
  },
  authenticateError: {
    id: 'posiSettings.SimphonyAuthenticatePage.authenticateError',
    defaultMessage: 'Error authenticating with Simphony.',
  },
} as const)
