import _ from 'lodash'
import { useEffect, useRef } from 'react'
import { useFormContext, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Box, DividerLine, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { referralsMessages } from '../../locales'
import { ReferralClientForm } from '../ReferralClientForm'
import { ReferredClientEmailConfigurationsForm } from '../ReferredClientEmailConfigurationsForm'
import type { ReferredClientFormFields } from './ReferredClientForm.zod'

export interface ReferredClientFormProps {
  disabled: boolean
  fields: ReferredClientFormFields
  globalPerks: SelectOption[]
  localPerks: SelectOption[]
}

export function ReferredClientForm({ fields, disabled, ...props }: ReferredClientFormProps) {
  const shouldResetNewClientEmailConfigurations = useRef(false)
  const shouldResetExistingClientEmailConfigurations = useRef(false)
  const newClientPrevBookingConfirmationEmail = useRef(false)
  const newClientPrevBookingReminderEmail = useRef(false)
  const existingClientPrevBookingConfirmationEmail = useRef(false)
  const existingClientPrevBookingReminderEmail = useRef(false)
  const { formatMessage } = useLocales()
  const { setValue, resetField } = useFormContext()
  const referredClientReservationTags = fields.prop('referredClientReservationTags')
  const referredClientReservationTagsData = useWatch(referredClientReservationTags)
  const newClientBookingConfirmationEmail = useWatch(fields.prop('referredNewClientEmailConfigurations.bookingConfirmationEmail'))
  const newClientBookingReminderEmail = useWatch(fields.prop('referredNewClientEmailConfigurations.bookingReminderEmail'))
  const existingClientBookingConfirmationEmail = useWatch(fields.prop('referredExistingClientEmailConfigurations.bookingConfirmationEmail'))
  const existingClientBookingReminderEmail = useWatch(fields.prop('referredExistingClientEmailConfigurations.bookingReminderEmail'))
  const newClient = referredClientReservationTags.item(0)
  const existingClient = referredClientReservationTags.item(1)
  const referredPerkText = useWatch(fields.prop('referredPerkText'))
  const newClientEmptyPerks =
    _.isEmpty(referredClientReservationTagsData[0]?.globalPerks) && _.isEmpty(referredClientReservationTagsData[0]?.localPerks)
  const existingClientEmptyPerks =
    _.isEmpty(referredClientReservationTagsData[1]?.globalPerks) && _.isEmpty(referredClientReservationTagsData[1]?.localPerks)

  useEffect(() => {
    if (newClientEmptyPerks && !shouldResetNewClientEmailConfigurations.current) {
      newClientPrevBookingConfirmationEmail.current = !!newClientBookingConfirmationEmail
      newClientPrevBookingReminderEmail.current = !!newClientBookingReminderEmail
      setValue('referredNewClientEmailConfigurations.bookingConfirmationEmail', false)
      setValue('referredNewClientEmailConfigurations.bookingReminderEmail', false)
      shouldResetNewClientEmailConfigurations.current = true
    } else if (shouldResetNewClientEmailConfigurations.current && !newClientEmptyPerks) {
      setValue('referredNewClientEmailConfigurations.bookingConfirmationEmail', newClientPrevBookingConfirmationEmail.current)
      setValue('referredNewClientEmailConfigurations.bookingReminderEmail', newClientPrevBookingReminderEmail.current)
      shouldResetNewClientEmailConfigurations.current = false
    }
    if (existingClientEmptyPerks && !shouldResetExistingClientEmailConfigurations.current) {
      existingClientPrevBookingConfirmationEmail.current = !!existingClientBookingConfirmationEmail
      existingClientPrevBookingReminderEmail.current = !!existingClientBookingReminderEmail
      setValue('referredExistingClientEmailConfigurations.bookingConfirmationEmail', false)
      setValue('referredExistingClientEmailConfigurations.bookingReminderEmail', false)
      shouldResetExistingClientEmailConfigurations.current = true
    } else if (shouldResetExistingClientEmailConfigurations.current && !existingClientEmptyPerks) {
      setValue('referredExistingClientEmailConfigurations.bookingConfirmationEmail', existingClientPrevBookingConfirmationEmail.current)
      setValue('referredExistingClientEmailConfigurations.bookingReminderEmail', existingClientPrevBookingReminderEmail.current)
      shouldResetExistingClientEmailConfigurations.current = false
    }
  }, [
    newClientEmptyPerks,
    existingClientEmptyPerks,
    setValue,
    resetField,
    newClientBookingConfirmationEmail,
    newClientBookingReminderEmail,
    existingClientBookingConfirmationEmail,
    existingClientBookingReminderEmail,
  ])

  return (
    <VStack spacing="lm">
      <VStack spacing="m">
        <Box>
          <Box mb="s">
            <Header type="h4">{formatMessage(referralsMessages.referralNewClient)}</Header>
          </Box>
          <SecondaryText>{formatMessage(referralsMessages.referralNewClientDescription)}</SecondaryText>
        </Box>
        <ReferralClientForm fields={newClient} disabled={disabled} type="new" {...props} />

        <Header type="h3">{formatMessage(referralsMessages.communicationConfigurations)}</Header>
        <ReferredClientEmailConfigurationsForm
          fields={fields.prop('referredNewClientEmailConfigurations')}
          disabled={disabled || newClientEmptyPerks}
          referredPerkText={referredPerkText ?? ''}
          isNewClient
        />
      </VStack>
      <DividerLine margin="none" />

      <VStack spacing="m">
        <Box>
          <Box mb="s">
            <Header type="h4">{formatMessage(referralsMessages.referralExistingClient)}</Header>
          </Box>
          <SecondaryText>{formatMessage(referralsMessages.referralExistingClientDescription)}</SecondaryText>
        </Box>
        <ReferralClientForm fields={existingClient} disabled={disabled} type="existing" {...props} />
        <Header type="h3">{formatMessage(referralsMessages.communicationConfigurations)}</Header>
        <ReferredClientEmailConfigurationsForm
          fields={fields.prop('referredExistingClientEmailConfigurations')}
          disabled={disabled || existingClientEmptyPerks}
          referredPerkText={referredPerkText ?? ''}
          isNewClient={false}
        />
      </VStack>
    </VStack>
  )
}
