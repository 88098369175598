import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { TierAttributes, AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TighterTierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const dayChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class FirstTimerTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getEditor(attributes) {
    const type = this.props.tier.tier_type
    const { index } = this.props
    switch (type) {
      case AutotagTierType.FIRST_TIMER:
        return <div>Tag clients when they are visiting your venue for the first time.</div>
      case AutotagTierType.FIRST_TIMER_COMPLETED:
        return (
          <div>
            Tag clients who have been to the venue for the first time in the past
            <DropdownArrowsPicker
              {...dropProps}
              choices={dayChoices}
              value={attributes.REMOVE_AFTER_DAYS}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.REMOVE_AFTER_DAYS, value)}
            />
            day(s).
          </div>
        )
      case AutotagTierType.FIRST_TIMER_GLOBAL:
        return <div>Tag clients when they are visiting your group for the first time.</div>
      case AutotagTierType.FIRST_TIMER_COMPLETED_GLOBAL:
        return (
          <div>
            Tag clients who have visited your group for the first time in the past
            <DropdownArrowsPicker
              {...dropProps}
              choices={dayChoices}
              value={attributes.REMOVE_AFTER_DAYS}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.REMOVE_AFTER_DAYS, value)}
            />
            day(s).
          </div>
        )
      default:
        return null
    }
  }

  getContent(attributes) {
    const type = this.props.tier.tier_type
    switch (type) {
      case AutotagTierType.FIRST_TIMER:
        return <div>Tag clients when they are visiting your venue for the first time.</div>
      case AutotagTierType.FIRST_TIMER_COMPLETED:
        return (
          <div>
            Tag clients who have been to the venue for the first time in the past <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
          </div>
        )
      case AutotagTierType.FIRST_TIMER_GLOBAL:
        return <div>Tag clients when they are visiting your group for the first time.</div>
      case AutotagTierType.FIRST_TIMER_COMPLETED_GLOBAL:
        return (
          <div>
            Tag clients who have visited your group for the first time in the past <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
          </div>
        )
      default:
        return null
    }
  }

  render() {
    const name = this.props.tier.name_display
    const type = this.props.tier.tier_type
    const { attributes } = this.props.tier

    const { index, editable, isActive, id } = this.props

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TighterTierEditor>{this.getEditor(attributes)}</TighterTierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>{this.getContent(attributes)}</TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default FirstTimerTier
