import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { perksMessages } from '../../locales'
import type { PerkNameFormField } from './PerkNameForm.zod'

export interface PerkNameFormProps {
  disabled: boolean
  internalNameField: PerkNameFormField
  primaryText?: string
  secondaryText?: string
}

export function PerkNameForm({ disabled, internalNameField, primaryText, secondaryText }: PerkNameFormProps) {
  const { formatMessage } = useLocales()
  const { perkNameInternalPrimary, perkNameInternalSecondary, perkNameInternalPlaceholder } = perksMessages

  return (
    <Label
      primary={primaryText ?? formatMessage(perkNameInternalPrimary)}
      secondary={secondaryText ?? formatMessage(perkNameInternalSecondary)}
    >
      <FormInput
        autoFocus
        data-test="sr-perk-internal-name"
        disabled={disabled}
        placeholder={formatMessage(perkNameInternalPlaceholder)}
        field={internalNameField}
      />
    </Label>
  )
}
