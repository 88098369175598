import type { PromoRangeApplyType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import {
  DateRangePicker,
  DatePickerUtils,
  DayOfWeekPicker,
  type DayOfWeekPickerChoiceProps,
  RadioGroup,
} from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Delimiter } from '../components/Delimiter'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { usePromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'

export function LifespanSection() {
  const { formatMessage } = useLocales()
  const { lifespanChoices } = usePromoCodeResourcesContext()
  const {
    selectedLifespan,
    setSelectedLifespan,
    startDateTime,
    setStartDateTime,
    isStartDateValid,
    endDateTime,
    setEndDateTime,
    isEndDateValid,
    selectedDates,
    setSelectedDates,
  } = usePromoCodeContext()

  return (
    <BaseSection title={formatMessage(promoCodeMessages.promoCodeFormLifespanSection)}>
      <Box p="lm" width="496px">
        <RadioGroup
          name="lifespanGroup"
          data-test="promo-code-form-lifespan-group-field"
          choices={lifespanChoices}
          selected={selectedLifespan}
          onChange={selected => setSelectedLifespan(selected.value as PromoRangeApplyType)}
        />
        {selectedLifespan !== 'NO_EXPIRATION' && (
          <VStack>
            <Delimiter />
            <DateRangePicker
              id="dateRangePicker"
              data-test="promo-code-form-date-range-picker-field"
              startDate={startDateTime}
              endDate={endDateTime}
              onStartDateChange={setStartDateTime}
              onEndDateChange={setEndDateTime}
              startDateProps={{
                isOutsideRange: date =>
                  DatePickerUtils.isBeforeCurrentFn()(date) || (endDateTime !== 'infinite' && DatePickerUtils.isAfterFn(endDateTime)(date)),
                appendToBody: false,
                openDirection: 'up',
                invalid: !isStartDateValid,
              }}
              endDateProps={{
                isOutsideRange: DatePickerUtils.isBeforeFn(startDateTime),
                appendToBody: false,
                openDirection: 'up',
                invalid: !isEndDateValid,
              }}
              infinite
            />
            <Box mt="lm">
              <DayOfWeekPicker
                name="dayOfWeekPicker"
                data-test="promo-code-form-day-of-week-picker-field"
                selected={selectedDates}
                onChange={(choice: DayOfWeekPickerChoiceProps, checked: boolean) => setSelectedDates(choice, checked)}
              />
            </Box>
          </VStack>
        )}
      </Box>
    </BaseSection>
  )
}
