import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  // Check Triggers Error Banner
  checkTriggersErrorBannerTitle: {
    id: 'posiSettings.EditPage.checkTriggersErrorBannerTitle',
    defaultMessage: 'Error loading menu data',
  },
  checkTriggersErrorBannerDescription: {
    id: 'posiSettings.EditPage.checkTriggersErrorBannerDescription',
    defaultMessage: `Encountered the following error when attempting to load menu data: "{error}"`,
  },

  // Check Triggers Warning Banner
  checkTriggersWarningBannerTitle: {
    id: 'posiSettings.EditPage.checkTriggersWarningBannerTitle',
    defaultMessage: 'No Menu Data',
  },
  checkTriggersWarningBannerDescription: {
    id: 'posiSettings.EditPage.checkTriggersWarningBannerDescription',
    defaultMessage: 'Configure menu data in your POS system to enable Self-Updating Table Statuses. <a>Learn more</a>',
  },

  // In-Service Statuses Error Banner
  inServiceStatusesErrorBannerTitle: {
    id: 'posiSettings.EditPage.inServiceStatusesErrorBannerTitle',
    defaultMessage: 'Error loading in-service statuses',
  },
  inServiceStatusesErrorBannerDescription: {
    id: 'posiSettings.EditPage.inServiceStatusesErrorBannerDescription',
    defaultMessage: `Encountered the following error when attempting to load in-service statuses: "{error}"`,
  },
} as const)
