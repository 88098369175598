import { defineMessages } from '@sevenrooms/core/locales'

export const activityLogMessages = defineMessages({
  notFound: {
    id: 'activityLog.notFound',
    defaultMessage: 'No activity found',
  },
  selectUser: {
    id: 'activityLog.select.user',
    defaultMessage: 'Select user',
  },
  selectCategory: {
    id: 'activityLog.select.category',
    defaultMessage: 'Select category',
  },
  title: {
    id: 'activityLog.title',
    defaultMessage: '{title} Activity Log',
  },
  noChanges: {
    id: 'activityLog.noChanges',
    defaultMessage: 'No changes',
  },
} as const)
