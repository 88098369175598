import styled from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'

export const ValueWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 200px;
  ${props => props.css};
`

export const ValueBox = styled.div`
  font-size: 14px;
  line-height: 19px;
  background-color: white;
  border: 1px solid ${props => (props.isValid ? '#DBDCDE' : '#D01A20')};
  border-radius: 5px;
  box-sizing: inherit;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  ${props => props.css};
`

export const SearchIcon = styled(StyledVmsIconS)`
  font-size: 12px;
  width: 34px;
  height: 100%;
  color: #888c92;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.css};
`

export const DropdownTextInput = styled.input`
  width: 100%;
  height: 100%;
  border: transparent;
  outline: none;
  padding: 0;
  font-size: 14px;
  ${props => props.css};
`

export const DropdownSelectText = styled.span`
  width: 242px;
  display: block;
  padding: 7px 10px;
  color: ${props => (props.selectedValue ? 'black' : '#DBDCDE')};
  ${props => props.css};
`

export const DropdownList = styled.ul`
  margin: 0;
  padding: 6px 0;
  text-align: left;
  display: flex;
  position: absolute;
  flex-direction: column;
  font-size: 14px;
  color: #151618;
  border: 1px solid #dbdcde;
  border-radius: 5px;
  width: 100%;
  box-sizing: inherit;
  -webkit-box-shadow: 0px 0px 19px -7px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 0px 19px -7px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 19px -7px rgba(0, 0, 0, 0.59);
  cursor: pointer;
  opacity: 1;
  background-color: white;
  z-index: 999;
  overflow: scroll;
  max-height: ${props => props.maxHeight || '210'}px;
  bottom: ${props => (props.renderBelow ? 'auto' : '100%')};
  ${props => props.css};
`

export const DropdownListItem = styled.li`
  list-style-type: none;
  padding: 7px 10px;
  line-height: 20px;
  :hover {
    background-color: #f7f7f7;
  }
  ${props => props.css};
`

export const DropdownCaret = styled(StyledVmsIconS)`
  transform: rotate(${props => (props.isActive ? '270' : '90')}deg);
  font-weight: 100;
  font-size: 15px;
  margin: 0;
  text-align: center;
  width: 30px;
  position: absolute;
  color: #636970;
  pointer-events: none;
  right: 0;
  top: 8px;
  ${props => props.css};
`

export const DropdownListItemIcon = styled(StyledVmsIconS)`
  width: 20px;
  text-align: center;
  font-size: 15px;
  margin-right: 10px;
  ${props => props.css};
`

export const SelectedValuePill = styled.div`
  height: 25px;
  margin: 3px 5px 3px 0px;
  border-radius: 20px;
  padding: 5px 10px;
  line-height: 15px;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  white-space: nowrap;
  ${props => props.css};
`
export const NullValuePill = styled.div`
  height: 35px;
`

export const SelectedValueIcon = styled(StyledVmsIconS)`
  height: 15px;
  font-size: 11px;
  min-height: 15px;
  display: inline-block;
  text-align: center;
  line-height: 15px;
  font-size: 15px;
  margin-left: 5px;
  ${props => props.css};
`

export const RemoveSelectionIcon = styled(StyledVmsIconS)`
  line-height: 15px;
  font-size: 15px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
  :hover {
    font-weight: 800;
  }
`

export const InlineText = styled.div`
  font-size: 14px;
  ${props => props.css};
`

export const InvalidMessage = styled.div`
  font-size: 12px;
  color: #d01a20;
  margin-top: 4px;
`
