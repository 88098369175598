import { AccessRuleTimeUnitEnum, type AccessRuleTimeUnitType } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export type TimeBeforeForm = ZodSchema<typeof timeBeforeForm>

export const timeBeforeForm = z.object({
  count: z.number().nullable(),
  unit: z.custom<AccessRuleTimeUnitType>(),
  beforeTime: z.string(),
  isEarlyAccess: z.boolean().optional(),
})

export function timeBeforeToHours({ count, unit }: TimeBeforeForm) {
  if (count === null) {
    return null
  }

  switch (unit) {
    case AccessRuleTimeUnitEnum.MONTHS:
      return count * 31 * 24
    case AccessRuleTimeUnitEnum.WEEKS:
      return count * 7 * 24
    case AccessRuleTimeUnitEnum.DAYS:
      return count * 24
    case AccessRuleTimeUnitEnum.MINUTES:
      return count / 60
    default:
      return count
  }
}
