import { type FormEvent, useCallback } from 'react'
import { useDeletePromoCodeMutation, useGetPromoCodeListQuery } from '@sevenrooms/core/api'
import type { PromoCode } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { MultiSelectTree, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { usePromoCodeManagementContext } from '../hooks/usePromoCodeManagementContext'
import { promoCodeManagementMessages } from '../locales'
import { PromoCodeBanner } from './PromoCodeBanner'
import { PromoCodeTable } from './PromoCodeTable'

export function PromoCodeList() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()

  const {
    data: promoCodeList = [],
    isFetching,
    error,
    refetch: reloadPromoCodeList,
  } = useGetPromoCodeListQuery({
    venueId: venue.id,
  })

  const [deletePromoCode] = useDeletePromoCodeMutation()

  const onPromoCodeDelete = useCallback(
    async (promoCode: PromoCode) => {
      try {
        await deletePromoCode({ venueId: venue.id, promoCodeKey: promoCode.key })
        notify({ content: formatMessage(promoCodeManagementMessages.promoCodesDeleteSuccessMessage), type: 'success' })
      } catch {
        notify({ content: formatMessage(promoCodeManagementMessages.promoCodesDeleteErrorMessage), type: 'error' })
      }
      reloadPromoCodeList()
    },
    [deletePromoCode, formatMessage, reloadPromoCodeList, venue.id]
  )

  const {
    filterSearchValue,
    setFilterSearchValue,
    statusChoices,
    setStatusChoices,
    discountChoices,
    setDiscountChoices,
    widgetChoices,
    setWidgetChoices,
    sortedColumns,
    setSortedColumns,
    filteredList,
  } = usePromoCodeManagementContext(promoCodeList)

  if (isFetching) {
    return <Loader />
  }

  if (error || promoCodeList.length === 0) {
    return (
      <PromoCodeBanner
        primary={formatMessage(promoCodeManagementMessages.promoCodesBannerPrimaryText)}
        secondary={formatMessage(promoCodeManagementMessages.promoCodesBannerSecondaryText)}
      />
    )
  }

  return (
    <>
      <HStack justifyContent="space-between" pb="lm">
        <HStack spacing="s">
          <Box width="220px">
            <Label primary={formatMessage(promoCodeManagementMessages.promoCodesFilterByStatusLabel)}>
              <MultiSelectTree
                placeholder={formatMessage(promoCodeManagementMessages.promoCodesFilterByStatusPlaceholder)}
                data-test="promo-code-select-status"
                showMoreLabel=""
                showRemoveAllButton
                displayAsTextWhenCollapsed
                treeData={statusChoices}
                onChange={(_option, selectedNodes) => {
                  setStatusChoices(selectedNodes)
                }}
                scrollLock
              />
            </Label>
          </Box>
          <Box width="220px">
            <Label primary={formatMessage(promoCodeManagementMessages.promoCodesFilterByDiscountLabel)}>
              <MultiSelectTree
                data-test="promo-code-select-discount-type"
                placeholder={formatMessage(promoCodeManagementMessages.promoCodesFilterByDiscountPlaceholder)}
                showMoreLabel=""
                showRemoveAllButton
                displayAsTextWhenCollapsed
                treeData={discountChoices}
                onChange={(_option, nodes) => {
                  setDiscountChoices(nodes)
                }}
                scrollLock
              />
            </Label>
          </Box>
          <Box width="220px">
            <Label primary={formatMessage(promoCodeManagementMessages.promoCodesFilterByWidgetTypeLabel)}>
              <MultiSelectTree
                data-test="promo-code-select-widget-type"
                placeholder={formatMessage(promoCodeManagementMessages.promoCodesFilterByWidgetTypePlaceholder)}
                treeData={widgetChoices}
                showMoreLabel=""
                showRemoveAllButton
                displayAsTextWhenCollapsed
                onChange={(_option, nodes) => {
                  setWidgetChoices(nodes)
                }}
                scrollLock
              />
            </Label>
          </Box>
        </HStack>
        <Box width="220px">
          <Label primary="&nbsp;">
            <SelectTarget data-test="promo-code-search-bar">
              <SelectFilter
                placeholder={formatMessage(promoCodeManagementMessages.promoCodesFilterBySearchStringPlaceholder)}
                selectedValue={filterSearchValue || null}
                searchable
                onChange={(event: FormEvent<HTMLInputElement>) => {
                  setFilterSearchValue(event.currentTarget.value)
                }}
              />
            </SelectTarget>
          </Label>
        </Box>
      </HStack>
      <PromoCodeTable
        venue={venue}
        promoCodeList={filteredList}
        sorted={sortedColumns}
        onSortedChange={setSortedColumns}
        onPromoCodeDelete={onPromoCodeDelete}
      />
    </>
  )
}
