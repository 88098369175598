import { defineMessages } from '@sevenrooms/core/locales'

export const reservationWidgetMessages = defineMessages({
  commonForText: {
    id: 'commonForText',
    defaultMessage: 'common for text old value',
  },
  commonPaymentGeneralSelectATipLabel: {
    id: 'commonPaymentGeneralSelectATipLabel',
    defaultMessage: 'Select a tip',
  },
  commonAndText: {
    id: 'commonAndText',
    defaultMessage: 'and',
  },
  commonGetDirectionsLink: {
    id: 'commonGetDirectionsLink',
    defaultMessage: 'Get Directions',
  },
  commonLinkToReceiptText: {
    id: 'commonLinkToReceiptText',
    defaultMessage: 'Link to receipt',
  },
  commonPaymentDetailsText: {
    id: 'commonPaymentDetailsText',
    defaultMessage: 'Payment Details',
  },
  commonReservedForText: {
    id: 'commonReservedForText',
    defaultMessage: 'Reserved for',
  },
  commonTotalText: {
    id: 'commonTotalText',
    defaultMessage: 'Total',
  },
  commonUpgradesLabel: {
    id: 'commonUpgradesLabel',
    defaultMessage: 'Upgrades',
  },
  commonHistoricalTransactionsText: {
    id: 'commonHistoricalTransactionsText',
    defaultMessage: 'Transaction History',
  },
  commonChargedText: {
    id: 'commonChargedText',
    defaultMessage: 'Charged',
  },
  commonRefundedText: {
    id: 'commonRefundedText',
    defaultMessage: '{amount} refunded',
  },
  commonPaymentGeneralSelectLabel: {
    id: 'commonPaymentGeneralSelectLabel',
    defaultMessage: 'select',
  },
  commonPaymentErrorPaymentRejectedError: {
    id: 'commonPaymentErrorPaymentRejectedError',
    defaultMessage: 'Payment attempt was rejected',
  },
  resCancelPageCallVenueSubheader: {
    id: 'resCancelPageCallVenueSubheader',
    defaultMessage: 'Please call restaurant at {venue_phone}',
  },
  resCancelPageCallVenueSubheaderNoPhone: {
    id: 'resCancelPageCallVenueSubheaderNoPhone',
    defaultMessage: 'Please call the restaurant directly',
  },
  resCancelPageCancelButtonLabel: {
    id: 'resCancelPageCancelButtonLabel',
    defaultMessage: 'Cancel Reservation',
  },
  resCancelPageCancellationFeeText: {
    id: 'resCancelPageCancellationFeeText',
    defaultMessage: 'Cancelling right now may trigger a cancellation fee of {amount} charged on card {card_details}.',
  },
  resCancelPageCancelMessage: {
    id: 'resCancelPageCancelMessage',
    defaultMessage: "While you won't be charged if you need to cancel, we ask that you do so at least 24 hours in advance.",
  },
  resCancelPageHeader: {
    id: 'resCancelPageHeader',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  resCancelPageKeepResButton: {
    id: 'resCancelPageKeepResButton',
    defaultMessage: 'Keep My Reservation',
  },
  resCancelPageModifyButtonLabel: {
    id: 'resCancelPageModifyButtonLabel',
    defaultMessage: 'Modify Reservation',
  },
  resCancelPagePageHeader: {
    id: 'resCancelPagePageHeader',
    defaultMessage: 'Reservation Cancellation',
  },
  resCancelPagePartySize: {
    id: 'resCancelPagePartySize',
    defaultMessage: 'Party of',
  },
  resCancelPageRefundText: {
    id: 'resCancelPageRefundText',
    defaultMessage: '{amount} will be refunded back to your card {card_details}',
  },
  resCancelPageResDetailsLabel: {
    id: 'resCancelPageResDetailsLabel',
    defaultMessage: 'Reservation Details',
  },
  resCancelPageResPassed: {
    id: 'resCancelPageResPassed',
    defaultMessage: 'The reservation date has passed',
  },
  resCancelPageSuccessBookButton: {
    id: 'resCancelPageSuccessBookButton',
    defaultMessage: 'Book',
  },
  resCancelPageSuccessSubheader: {
    id: 'resCancelPageSuccessSubheader',
    defaultMessage: 'Click below to book another reservation with us.',
  },
  resCancelPageTableMinText: {
    id: 'resCancelPageTableMinText',
    defaultMessage: 'Table minimum',
  },
  resCancelPageUnableModifyResHeader: {
    id: 'resCancelPageUnableModifyResHeader',
    defaultMessage: 'The reservation cannot be modified online',
  },
  resCancelPageUnableCancelModifyResHeader: {
    id: 'resCancelPageUnableCancelModifyResHeader',
    defaultMessage: 'The reservation cannot be canceled and modified online',
  },
  resConfirmPageError: {
    id: 'resConfirmPageError',
    defaultMessage: 'Something went wrong.',
  },
  resConfirmPageAdditionalUpgradesUnavailable: {
    id: 'resConfirmPageAdditionalUpgradesUnavailable',
    defaultMessage: 'Sorry, additional upgrades cannot be purchased at this time.',
  },
  resConfirmPageErrorCallVenueSubheader: {
    id: 'resConfirmPageErrorCallVenueSubheader',
    defaultMessage: 'If this error persists, please call the restaurant at {venue_phone} directly',
  },
  resConfirmPageErrorCallVenueSubheaderNoPhone: {
    id: 'resConfirmPageErrorCallVenueSubheaderNoPhone',
    defaultMessage: 'If this error persists, please call the restaurant directly"',
  },
  resConfEmailHeader: {
    id: 'resConfEmailHeader',
    defaultMessage: 'A note from us',
  },
  resConfirmPageDesc: {
    id: 'resConfirmPageDesc',
    defaultMessage: "If you'd like, tell us more about yourself and your preferences so we can provide a better experience.",
  },
  resConfirmPageDietaryRestrictGroupQuestion: {
    id: 'resConfirmPageDietaryRestrictGroupQuestion',
    defaultMessage: 'Does your party have any dietary restrictions?',
  },
  resConfirmPageDietaryRestrictQuestion: {
    id: 'resConfirmPageDietaryRestrictQuestion',
    defaultMessage: 'Do you have any dietary restrictions?',
  },
  resConfirmPageHeader: {
    id: 'resConfirmPageHeader',
    defaultMessage: 'Thank you for confirming with us.',
  },
  resConfirmPageLiquorPref: {
    id: 'resConfirmPageLiquorPref',
    defaultMessage: 'Liquor Preference',
  },
  resConfirmPageNextButton: {
    id: 'resConfirmPageNextButton',
    defaultMessage: 'Next',
  },
  resConfirmPageOtherInfoLabel: {
    id: 'resConfirmPageOtherInfoLabel',
    defaultMessage: 'Anything else we should know?',
  },
  resConfirmPageProfilePhotoDesc: {
    id: 'resConfirmPageProfilePhotoDesc',
    defaultMessage: 'Upload your profile photo so we can greet you upon your arrival',
  },
  resConfirmPageRemovePhotoButton: {
    id: 'resConfirmPageRemovePhotoButton',
    defaultMessage: 'Remove photo',
  },
  resConfirmPageSearchChampagnePrefLabel: {
    id: 'resConfirmPageSearchChampagnePrefLabel',
    defaultMessage: 'Champagne Preference',
  },
  resConfirmPageSpecialOccLabel: {
    id: 'resConfirmPageSpecialOccLabel',
    defaultMessage: 'Is this a Special Occasion?',
  },
  resConfirmPageSubHeader: {
    id: 'resConfirmPageSubHeader',
    defaultMessage: 'We look forward to welcoming you.',
  },
  resConfirmPageUploadPhotoButton: {
    id: 'resConfirmPageUploadPhotoButton',
    defaultMessage: 'Upload',
  },
  resWidgetUpdateBody: {
    id: 'resWidgetUpdateBody',
    defaultMessage: 'You’ve successfully updated your reservation.',
  },
  resWidgetPoweredByLabel: {
    id: 'resWidgetPoweredByLabel',
    defaultMessage: 'Powered by',
  },
  resWidgetSpecialAttentionInfoBody: {
    id: 'resWidgetSpecialAttentionInfoBody',
    defaultMessage:
      'Nothing is more important to us than the safety of our guests. In addition to meeting all health standards, we are taking extra precaution by ensuring that tables are spaced apart, and all surfaces are thoroughly disinfected between seatings.',
  },
  resWidgetSpecialAttentionLabel: {
    id: 'resWidgetSpecialAttentionLabel',
    defaultMessage: 'Our commitment to your safety during COVID-19',
  },
  resWidgetUpgradePageHeader: {
    id: 'resWidgetUpgradePageHeader',
    defaultMessage: 'Upgrade your reservation',
  },
  resConfirmPageAvailableUpgradesSubheader: {
    id: 'resConfirmPageAvailableUpgradesSubheader',
    defaultMessage: 'Enhance your experience with one of our signature items',
  },
  resUpgradesPageEnoughSelected: {
    id: 'resUpgradesPageEnoughSelected',
    defaultMessage: 'You have already selected the maximum number of upgrade.',
  },
  resUpgradesPageNotEnoughSelected: {
    id: 'resUpgradesPageNotEnoughSelected',
    defaultMessage: 'You have not selected the minimum required number of upgrades.',
  },
  resConfirmPageAvailableUpgradesButton: {
    id: 'resConfirmPageAvailableUpgradesButton',
    defaultMessage: 'Add Upgrades',
  },
  resConfirmPageNoThanks: {
    id: 'resConfirmPageNoThanks',
    defaultMessage: 'No Thanks',
  },
  resConfirmPageViewMore: {
    id: 'resConfirmPageViewMore',
    defaultMessage: 'View More',
  },
  resConfirmPageViewLess: {
    id: 'resConfirmPageViewLess',
    defaultMessage: 'View Less',
  },
  resConfirmPageViewAllUpgrades: {
    id: 'resConfirmPageViewAllUpgrades',
    defaultMessage: 'View All Upgrades',
  },
  resCancelPageCancelPriorityAlertsSuccessHeader: {
    id: 'resCancelPageCancelPriorityAlertsSuccessHeader',
    defaultMessage: 'Your Priority Alert has been successfully turned off',
  },
  resCancelPageCancelPriorityAlertsButton: {
    id: 'resCancelPageCancelPriorityAlertsButton',
    defaultMessage: 'Turn Off Alert',
  },
  resCancelPageCancelPriorityAlertsHeader: {
    id: 'resCancelPageCancelPriorityAlertsHeader',
    defaultMessage: 'Are you sure you want to turn off your Priority Alert?',
  },
  resCancelPageCancelPriorityAlertsSubheader: {
    id: 'resCancelPageCancelPriorityAlertsSubheader',
    defaultMessage: 'You have a Priority Alert for:',
  },
  resWidgetPriorityAlertsDetailsHeader: {
    id: 'resWidgetPriorityAlertsDetailsHeader',
    defaultMessage: 'Priority Alert Details',
  },
  resWidgetPriorityAlertsSetAlertTitle: {
    id: 'resWidgetPriorityAlertsSetAlertTitle',
    defaultMessage: 'Set Alert Me',
  },
  resWidgetPriorityAlertsManageAlertButton: {
    id: 'resWidgetPriorityAlertsManageAlertButton',
    defaultMessage: 'Manage Alert',
  },
  resWidgetPriorityAlertsHeader: {
    id: 'resWidgetPriorityAlertsHeader',
    defaultMessage: 'Alert me when a reservation becomes available on this date.',
  },
  resWidgetPriorityAlertsReservationTimeBetween: {
    id: 'resWidgetPriorityAlertsReservationTimeBetween',
    defaultMessage: 'Reservation Time Between:',
  },
  resWidgetPriorityAlertsSetAlertButton: {
    id: 'resWidgetPriorityAlertsSetAlertButton',
    defaultMessage: 'Alert Me',
  },
  resWidgetRequestErrorMessageInvalid: {
    id: 'resWidgetRequestErrorMessageInvalid',
    defaultMessage: 'Your request was not found.',
  },
  resWidgetRequestErrorMessageInactive: {
    id: 'resWidgetRequestErrorMessageInactive',
    defaultMessage: 'Your request is no longer active.',
  },
  resWidgetTitle: {
    id: 'resWidgetTitle',
    defaultMessage: '',
  },
  resWidgetNoAvailability: {
    id: 'resWidgetNoAvailability',
    defaultMessage: 'Unfortunately there is no availability at the selected time.',
  },
  resWidgetNoAvailabilityCriteria: {
    id: 'resWidgetNoAvailabilityCriteria',
    defaultMessage: 'There is no availability that meets your search criteria.',
  },
  resWidgetOtherDatesLabel: {
    id: 'resWidgetOtherDatesLabel',
    defaultMessage: 'Other dates with availability at {venue}',
  },
  resWidgetOtherLocationsLabel: {
    id: 'resWidgetOtherLocationsLabel',
    defaultMessage: 'Availability at our other locations',
  },
  resWidgetOtherLocationsNoAvail: {
    id: 'resWidgetOtherLocationsNoAvail',
    defaultMessage: 'There is no additional availability at our other venues.',
  },
  resWidgetNoAdditionalAvail: {
    id: 'resWidgetNoAdditionalAvail',
    defaultMessage: 'There is no additional availability at this time.',
  },
  resWidgetCrossSellVenueLongDescription: {
    id: 'resWidgetCrossSellVenueLongDescription',
    defaultMessage: '',
  },
  resWidgetCrossSellVenueShortDescription: {
    id: 'resWidgetCrossSellVenueShortDescription',
    defaultMessage: '',
  },
  resWidgetConfirmEmailSent: {
    id: 'resWidgetConfirmEmailSent',
    defaultMessage: 'Your confirmation and details have been sent to {booking_conf_destination}',
  },
  resWidgetGuestCheckoutButton: {
    id: 'resWidgetGuestCheckoutButton',
    defaultMessage: 'Continue as a Guest',
  },
  resWidgetHoldExpiredLabel: {
    id: 'resWidgetHoldExpiredLabel',
    defaultMessage: 'Your table is no longer being held, you can still try to book it.',
  },
  resWidgetHoldingTableLabel: {
    id: 'resWidgetHoldingTableLabel',
    defaultMessage: 'We’re holding this table for',
  },
  resWidgetPerPersonLabel: {
    id: 'resWidgetPerPersonLabel',
    defaultMessage: 'person',
  },
  resWidgetPerReservationLabel: {
    id: 'resWidgetPerReservationLabel',
    defaultMessage: 'reservation',
  },
  resWidgetFirstNameLabel: {
    id: 'resWidgetFirstNameLabel',
    defaultMessage: 'First Name',
  },
  billingAddressLabel: {
    id: 'billingAddressLabel',
    defaultMessage: 'Billing Address',
  },
  billingAddressEnterYourAddressLabel: {
    id: 'billingAddressEnterYourAddressLabel',
    defaultMessage: 'Enter your billing address',
  },
  resWidgetLastNameLabel: {
    id: 'resWidgetLastNameLabel',
    defaultMessage: 'Last Name',
  },
  resWidgetEmailLabel: {
    id: 'resWidgetEmailLabel',
    defaultMessage: 'Email Address',
  },
  resWidgetPostalCodeLabel: {
    id: 'resWidgetPostalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  resWidgetPreferredLanguageLabel: {
    id: 'resWidgetPreferredLanguageLabel',
    defaultMessage: 'Preferred communication language',
  },
  resWidgetBirthdayLabel: {
    id: 'resWidgetBirthdayLabel',
    defaultMessage: 'Birthday',
  },
  resWidgetPhoneNumberLabel: {
    id: 'resWidgetPhoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  commonGuestLabel: {
    id: 'commonGuestLabel',
    defaultMessage: 'guest',
  },
  commonGuestsLabel: {
    id: 'commonGuestsLabel',
    defaultMessage: 'guests',
  },
  resWidgetUpgradePageSelectLabel: {
    id: 'resWidgetUpgradePageSelectLabel',
    defaultMessage: 'selected',
  },
  resWidgetGuestCheckoutDivider: {
    id: 'resWidgetGuestCheckoutDivider',
    defaultMessage: 'Or continue as a guest',
  },
  resWidgetGuestInformationHeader: {
    id: 'resWidgetGuestInformationHeader',
    defaultMessage: 'Your Information',
  },
  resWidgetLogInButton: {
    id: 'resWidgetLogInButton',
    defaultMessage: 'Log in',
  },
  resWidgetLoginOrLabel: {
    id: 'resWidgetLoginOrLabel',
    defaultMessage: 'Or',
  },
  resWidgetLoginPageHeader: {
    id: 'resWidgetLoginPageHeader',
    defaultMessage: 'Log in for faster checkout:',
  },
  resWidgetCheckoutSubmitButtonLabel: {
    id: 'resWidgetCheckoutSubmitButtonLabel',
    defaultMessage: 'Submit',
  },
  resWidgetCheckoutModifyButtonLabel: {
    id: 'resWidgetCheckoutModifyButtonLabel',
    defaultMessage: 'Complete Modified Booking',
  },
  proceedToPaymentLabel: {
    id: 'proceedToPaymentLabel',
    defaultMessage: 'Proceed to Payment',
  },
  resWidgetCheckoutVenueGroupOptIn: {
    id: 'resWidgetCheckoutVenueGroupOptIn',
    defaultMessage: 'Receive news and offers for all our locations',
  },
  resWidgetCheckoutVenueOptIn: {
    id: 'resWidgetCheckoutVenueOptIn',
    defaultMessage: 'Receive news and offers via Email for {venue}',
  },
  resWidgetCheckoutVenueSmsOptIn: {
    id: 'resWidgetCheckoutVenueSmsOptIn',
    defaultMessage: 'Receive news and offers via text for {venue}',
  },
  resWidgetMarketingPolicyHeader: {
    id: 'resWidgetMarketingPolicyHeader',
    defaultMessage: 'Marketing Policy',
  },
  resWidgetCancellationPolicyHeader: {
    id: 'resWidgetCancellationPolicyHeader',
    defaultMessage: 'Cancellation Policy',
  },
  resWidgetCancelPolicyOptInLabel: {
    id: 'resWidgetCancelPolicyOptInLabel',
    defaultMessage: 'I agree to the Cancellation Policy',
  },
  resWidgetCustomPolicyOptInLabel: {
    id: 'resWidgetCustomPolicyOptInLabel',
    defaultMessage: "I agree to the venue's required policy",
  },
  resWidgetGdprOptInLabel: {
    id: 'resWidgetGdprOptInLabel',
    defaultMessage:
      'You agree to allow us to retain this sensitive personal information (ie food allergies, medical conditions, etc.) in order to provide you with a more tailored service.',
  },
  resWidgetCustomPolicyHeader: {
    id: 'resWidgetCustomPolicyHeader',
    defaultMessage: 'Required checkout policy',
  },
  resWidgetCustomGdprPolicyLinkLabel: {
    id: 'resWidgetCustomGdprPolicyLinkLabel',
    defaultMessage: 'GDPR Policy',
  },
  resWidgetCustomPrivacyPolicyLinkLabel: {
    id: 'resWidgetCustomPrivacyPolicyLinkLabel',
    defaultMessage: 'Privacy Policy',
  },
  resWidgetPolicyDisclaimerText: {
    id: 'resWidgetPolicyDisclaimerText',
    defaultMessage: 'By clicking "submit" you agree to',
  },
  resWidgetTermsOfServiceLinkLabel: {
    id: 'resWidgetTermsOfServiceLinkLabel',
    defaultMessage: 'Terms of Service',
  },
  policyUsTwilioSmsOptInSubfooter: {
    id: 'policyUsTwilioSmsOptInSubfooter',
    defaultMessage:
      '<i>For SMS, reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Message & data rates may apply.</i>',
  },
  resWidgetSummaryLabel: {
    id: 'resWidgetSummaryLabel',
    defaultMessage: 'Summary',
  },
  resWidgetReservationLabel: {
    id: 'resWidgetReservationLabel',
    defaultMessage: 'Reservation',
  },
  resWidgetSubtotalLabel: {
    id: 'resWidgetSubtotalLabel',
    defaultMessage: 'Subtotal',
  },
  resWidgetSalutationLabel: {
    id: 'resWidgetSalutation',
    defaultMessage: 'Title',
  },
  resWidgetTaxLabel: {
    id: 'resWidgetTaxLabel',
    defaultMessage: 'Tax',
  },
  resWidgetTipLabel: {
    id: 'resWidgetTipLabel',
    defaultMessage: 'Tip',
  },
  resWidgetTotalLabel: {
    id: 'resWidgetTotalLabel',
    defaultMessage: 'Total',
  },
  resWidgetAmountDueNowLabel: {
    id: 'resWidgetAmountDueNowLabel',
    defaultMessage: 'Amount due now',
  },
  resWidgetServiceChargeLabel: {
    id: 'resWidgetServiceChargeLabel',
    defaultMessage: 'Service charge',
  },
  resWidgetProfileLink: {
    id: 'resWidgetProfileLink',
    defaultMessage: 'Profile',
  },
  resWidgetPromoCodeDiscountLabel: {
    id: 'resWidgetPromoCodeDiscountLabel',
    defaultMessage: 'Promo Applied',
  },
  resWidgetGiftCardDiscountLabel: {
    id: 'resWidgetGiftCardDiscountLabel',
    defaultMessage: 'Gift card',
  },
  resWidgetTotalIncludesTaxLabel: {
    id: 'resWidgetTotalIncludesTaxLabel',
    defaultMessage: 'Total includes {taxLabel} {formattedCurrency}',
  },
  resWidgetPromoButtonApplyLabel: {
    id: 'resWidgetPromoButtonApplyLabel',
    defaultMessage: 'Apply',
  },
  resWidgetPromoButtonRemoveLabel: {
    id: 'resWidgetPromoButtonRemoveLabel',
    defaultMessage: 'remove',
  },
  resWidgetPromoLabel: {
    id: 'resWidgetPromoLabel',
    defaultMessage: 'Promo Code',
  },
  resWidgetPromoPlaceholder: {
    id: 'resWidgetPromoPlaceholder',
    defaultMessage: 'Enter promo code here',
  },
  resWidgetPromoAppliedStatusLabel: {
    id: 'resWidgetPromoAppliedStatusLabel',
    defaultMessage: '{value} is applied',
  },
  resWidgetPromoErrorInvalid: {
    id: 'resWidgetPromoErrorInvalid',
    defaultMessage: 'Invalid promo code.',
  },
  resWidgetPromoErrorExhausted: {
    id: 'resWidgetPromoErrorExhausted',
    defaultMessage: 'That promo code has already been used.',
  },
  policyCancellationDefault: {
    id: 'policyCancellationDefault',
    defaultMessage:
      'We ask that any cancellation to your reservation be made no less than 24 hours prior. For no shows or late cancellations, you will be subject to a fee that will be applied to the credit card on file.',
  },
  policyCustomCheckout: {
    id: 'policyCustomCheckout',
    defaultMessage: '',
  },
  policyVenueGroupMarketing: {
    id: 'policyVenueGroupMarketing',
    defaultMessage:
      'From time to time, we’ll contact you via email to keep you in the loop about what’s happening at all our locations. By clicking this, you will be resubscribed to any of our locations from which you previously unsubscribed.',
  },
  resWidgetPolicyVenueSpecificMarketingLabel: {
    id: 'resWidgetPolicyVenueSpecificMarketingLabel',
    defaultMessage: 'Receive news and offers for {venue}',
  },
  policyVenueSpecificMarketing: {
    id: 'policyVenueSpecificMarketing',
    defaultMessage: 'From time to time, we’ll contact you via email to keep you in the loop about what’s happening at our venue.',
  },
  policyVenueSpecificSmsMarketing: {
    id: 'policyVenueSpecificSmsMarketing',
    defaultMessage: "From time to time, we'll contact you via text to keep you in the loop about what's happening at our venue.",
  },
  resWidgetAgeConsentLabel: {
    id: 'resWidgetAgeConsentLabel',
    defaultMessage: 'I certify I am above the age of',
  },
  policyUsTwilioSmsOptIn: {
    id: 'policyUsTwilioSmsOptIn',
    defaultMessage: 'I agree to receive automated text messages, such as reservation reminders, at the phone number provided.',
  },
  resWidgetRequestsSubmitRequestTitle: {
    id: 'resWidgetRequestsSubmitRequestTitle',
    defaultMessage: 'Submit Request',
  },
  resWidgetRequestDetailsLabel: {
    id: 'resWidgetRequestDetailsLabel',
    defaultMessage: 'Request Details',
  },
  resWidgetRequestsManageRequestButton: {
    id: 'resWidgetRequestsManageRequestButton',
    defaultMessage: 'Manage Request',
  },
  resWidgetSubmitRequestButton: {
    id: 'resWidgetSubmitRequestButton',
    defaultMessage: 'Submit a request',
  },
  resWidgetReservationNotesHeader: {
    id: 'resWidgetReservationNotesHeader',
    defaultMessage: 'Reservation Notes',
  },
  resWidgetPriorityAlertsConfirmationHeader: {
    id: 'resWidgetPriorityAlertsConfirmationHeader',
    defaultMessage: 'Your Priority Alert Has Been Set at {venue_name}',
  },
  resWidgetRequestReceivedLabel: {
    id: 'resWidgetRequestReceivedLabel',
    defaultMessage: 'We have received your request.',
  },
  policySmsOptIn: {
    id: 'policySmsOptIn',
    defaultMessage: 'By opting in, you agree to receive SMS updates and reminders about your reservations.',
  },
  textTailoredCommunicationOptInLabel: {
    id: 'tailoredCommunicationOptInLabel',
    defaultMessage: 'I agree to receive communications tailored based on my interests, preferences and interactions with the restaurant',
  },
  textTailoredCommunicationOptInHeader: {
    id: 'tailoredCommunicationOptInHeader',
    defaultMessage: 'Marketing Policy',
  },
  textTailoredCommunicationOptInBody: {
    id: 'tailoredCommunicationOptInBody',
    defaultMessage:
      'By checking this box, you agree to receive communications tailored based on the data the restaurant has on you, such as menu items ordered or the number of times you have visited.',
  },
  resWidgetPriorityAlertsConfirmationMessage: {
    id: 'resWidgetPriorityAlertsConfirmationMessage',
    defaultMessage: 'We will alert you if a reservation becomes available for:',
  },
  resWidgetPriorityAlertsAlreadyExistsError: {
    id: 'resWidgetPriorityAlertsAlreadyExistsError',
    defaultMessage:
      'An alert has already been set up during this time period. If you wish to set up another alert, please modify the date or the party size.',
  },
  resWidgetRequestsAlreadyExistsError: {
    id: 'resWidgetRequestsAlreadyExistsError',
    defaultMessage:
      'A request has already been set up during this time period. If you wish to set up another request, please modify the date or the party size.',
  },
  resWidgetDatePickerLabel: {
    id: 'resWidgetDatePickerLabel',
    defaultMessage: 'Date',
  },
  resWidgetTimePickerLabel: {
    id: 'resWidgetTimePickerLabel',
    defaultMessage: 'Time',
  },
  resWidgetGuestsLabel: {
    id: 'resWidgetGuestsLabel',
    defaultMessage: 'Guests',
  },
  resWidgetAllTimesLabel: {
    id: 'resWidgetAllTimesLabel',
    defaultMessage: 'All Times',
  },
  resWidgetShowXMoreTimes: {
    id: 'resWidgetShowXMoreTimes',
    defaultMessage: 'Show {number_of_times} more times on {date}',
  },
  resWidgetShowAllTimes: {
    id: 'resWidgetShowAllTimes',
    defaultMessage: 'Show all times on {date}.',
  },
  resWidgetSearchAllTimes: {
    id: 'resWidgetSearchAllTimes',
    defaultMessage: 'Search All Times',
  },
  resWidgetSearchHaloTimeRange: {
    id: 'resWidgetSearchHaloTimeRange',
    defaultMessage: 'Search {halo_start_time} to {halo_end_time}',
  },
  resWidgetHaloPickerLabel: {
    id: 'resWidgetHaloPickerLabel',
    defaultMessage: 'Halo',
  },
  resWidgetErrorsFieldRequired: {
    id: 'resWidgetErrorsFieldRequired',
    defaultMessage: 'Required',
  },
  resWidgetErrorsFieldInvalid: {
    id: 'resWidgetErrorsFieldInvalid',
    defaultMessage: 'Invalid',
  },
  resWidgetReservationDetails: {
    id: 'resWidgetReservationDetails',
    defaultMessage: 'Reservation Details',
  },
  resWidgetBlackoutDate: {
    id: 'resWidgetBlackoutDate',
    defaultMessage: 'There is no online availability for this day. Please call the restaurant.',
  },
  resWidgetNoShiftsOnDate: {
    id: 'resWidgetNoShiftsOnDate',
    defaultMessage: 'We are closed on {date}. Book one of these upcoming dates.',
  },
  resWidgetOtherVenueNavigationModalHeader: {
    id: 'resWidgetOtherVenueNavigationModalHeader',
    defaultMessage: "You're booking at {venue}",
  },
  resWidgetOtherVenueNavigationModalBody: {
    id: 'resWidgetOtherVenueNavigationModalBody',
    defaultMessage: "You're leaving {venue_from} to make a reservation at {venue_to}.",
  },
  resWidgetCancelButtonLabel: {
    id: 'res_widget_cancel_button_label',
    defaultMessage: 'Cancel',
  },
  resWidgetContinueButtonLabel: {
    id: 'resWidgetContinueButtonLabel',
    defaultMessage: 'Continue',
  },
  // Will be replaced in GS-152
  userGreeting: {
    id: 'usercontact.usergreeting',
    defaultMessage: 'Hello, {user_first_name} {user_last_name}',
  },
  notLoggedInUser: {
    id: 'usercontact.notloggedinuser',
    defaultMessage: 'Not You?',
  },
  resWidgetCurrentReservationDetailsLabel: {
    id: 'resWidgetCurrentReservationDetailsLabel',
    defaultMessage: 'Current:',
  },
  resWidgetNewReservationDetailsLabel: {
    id: 'resWidgetNewReservationDetailsLabel',
    defaultMessage: 'New:',
  },
  resWidgetModifyReservationHeader: {
    id: 'resWidgetModifyReservationHeader',
    defaultMessage: 'Modify your reservation at {venue}',
  },
  resWidgetPaymentLabel: {
    id: 'resWidgetPaymentLabel',
    defaultMessage: 'Payment',
  },
  resPaymentFailedModalHeader: {
    id: 'resPaymentFailedModalHeader',
    defaultMessage: 'Payment Failed',
  },
  resPaymentFailedModalBody: {
    id: 'resPaymentFailedModalBody',
    defaultMessage: 'There has been an issue with the payment and the checkout has not completed. Please try again.',
  },
  resErrorModalTryAgainButtonLabel: {
    id: 'resErrorModalTryAgainButtonLabel',
    defaultMessage: 'Try Again',
  },
  resWidgetIncludesFees: {
    id: 'resWidgetIncludesFees',
    defaultMessage: `(Includes {fees} in fees)`,
  },
  resWidgetGiftCardLabel: {
    id: 'resWidgetGiftCardLabel',
    defaultMessage: 'Redeem a Gift Card',
  },
  resWidgetGiftCardNumberLabel: {
    id: 'resWidgetGiftCardNumberLabel',
    defaultMessage: 'Gift Card Number',
  },
  resWidgetGiftCardPINLabel: {
    id: 'resWidgetGiftCardPINLabel',
    defaultMessage: 'PIN',
  },
  resWidgetGiftCardRedeemAmountLabel: {
    id: 'resWidgetGiftCardRedeemAmountLabel',
    defaultMessage: 'Amount to Redeem',
  },
  resWidgetGiftCardBalanceLabel: {
    id: 'resWidgetGiftCardBalanceLabel',
    defaultMessage: 'Gift Card Balance',
  },
  resWidgetGiftCardApplyLabel: {
    id: 'resWidgetGiftCardApplyLabel',
    defaultMessage: 'Apply',
  },
  resWidgetGiftCardChangeAmountLabel: {
    id: 'resWidgetGiftCardChangeAmountLabel',
    defaultMessage: 'Change Amount',
  },
  resWidgetGiftCardUpdateAmountLabel: {
    id: 'resWidgetGiftCardUpdateAmountLabel',
    defaultMessage: 'Update Amount',
  },
  resWidgetGiftCardCancelLabel: {
    id: 'resWidgetGiftCardCancelLabel',
    defaultMessage: 'Cancel Gift Card',
  },
  resWidgetGiftCardAmountAppliedLabel: {
    id: 'resWidgetGiftCardApplyLabel',
    defaultMessage: '{amount} applied. {remaining} balance remaining on gift card.',
  },
  resWidgetRedemptionServiceErrorSetup: {
    id: 'resWidgetRedemptionServiceErrorSetup',
    defaultMessage: 'Invalid Gift Card Setup, please contact venue or try again.',
  },
  resWidgetRedemptionServiceErrorCardNotActive: {
    id: 'resWidgetRedemptionServiceErrorCardNotActive',
    defaultMessage: 'Card not active, please try a different card.',
  },
  resWidgetRedemptionServiceErrorNotSufficientFunds: {
    id: 'resWidgetRedemptionServiceErrorNotSufficientFunds',
    defaultMessage: 'This gift card has insufficient funds.',
  },
  resWidgetRedemptionServiceErrorCardNotFound: {
    id: 'resWidgetRedemptionServiceErrorCardNotFound',
    defaultMessage: 'Gift card not found, please update and try again.',
  },
  resWidgetRedemptionServiceErrorInvalidAmount: {
    id: 'resWidgetRedemptionServiceErrorInvalidAmount',
    defaultMessage: 'Invalid amount. Please update and try again.',
  },
  resWidgetRedemptionServiceErrorSession: {
    id: 'resWidgetRedemptionServiceErrorSession',
    defaultMessage: 'Session has expired. Please refresh page and try again.',
  },
  resWidgetRedemptionServiceError: {
    id: 'resWidgetRedemptionServiceError',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  resWidgetRedeemAmountExceedBalanceError: {
    id: 'resWidgetRedeemAmountExceedBalanceError',
    defaultMessage: 'Redeemed amount cannot exceed the gift card balance.',
  },
  resWidgetRedeemAmountExceedTotalError: {
    id: 'resWidgetRedeemAmountExceedTotalError',
    defaultMessage: 'Redeemed amount cannot exceed the payment total.',
  },
  resWidgetUpgradeViewLessButton: {
    id: 'resWidgetUpgradeViewLessButton',
    defaultMessage: 'View less',
  },
  resWidgetUpgradeViewMoreButton: {
    id: 'resWidgetUpgradeViewMoreButton',
    defaultMessage: 'View more',
  },
  resWidgetSelectButtonLabel: {
    id: 'resWidgetSelectButtonLabel',
    defaultMessage: 'Select',
  },
  commonCancellationPolicyHeader: {
    id: 'commonCancellationPolicyHeader',
    defaultMessage: 'Cancellation Policy',
  },
  resWidgetGroupBookingsBannerLabel: {
    id: 'resWidgetPrivateEventsBannerLabel',
    defaultMessage: 'We offer spaces for group bookings',
  },
  resWidgetGroupBookingsBannerDescription: {
    id: 'resWidgetPrivateEventsBannerDescription',
    defaultMessage: 'Booking for a large party? We’d love to host you.',
  },
  resWidgetGroupBookingsBannerExploreButtonLabel: {
    id: 'resWidgetPrivateEventsBannerExploreButtonLabel',
    defaultMessage: 'Explore Group Booking Spaces',
  },
  resWidgetReservationsTabLabel: {
    id: 'resWidgetReservationsTabLabel',
    defaultMessage: 'Reservations',
  },
  resWidgetGroupBookingsTabLabel: {
    id: 'resWidgetGroupBookingsTabLabel',
    defaultMessage: 'Group Bookings',
  },
  resWidgetGroupBookingsTabHeader: {
    id: 'resWidgetGroupBookingsTabHeader',
    defaultMessage: 'Search for Group Booking Availability',
  },
  resWidgetGroupBookingsDatePlaceholder: {
    id: 'resWidgetGroupBookingsDatePlaceholder',
    defaultMessage: 'Select Date',
  },
  resWidgetGroupBookingsPartySizePlaceholder: {
    id: 'resWidgetGroupBookingsPartySizePlaceholder',
    defaultMessage: 'Select Party Size',
  },
  resWidgetGroupBookingsRoomMaxCapacityText: {
    id: 'resWidgetGroupBookingsRoomMaxCapacityText',
    defaultMessage: 'Up to {count} Guests',
  },
  resWidgetGroupBookingsRoomMoreDetailsLabel: {
    id: 'resWidgetGroupBookingsRoomMoreDetailsLabel',
    defaultMessage: 'More Details',
  },
  resWidgetGroupBookingsRoomSpecifySearchCriteriaText: {
    id: 'resWidgetGroupBookingsRoomSpecifySearchCriteriaText',
    defaultMessage: 'Select a date and party size to view availability',
  },
  resWidgetGroupBookingsRoomNoAvailabilityAtSelectedDayText: {
    id: 'resWidgetGroupBookingsRoomNoAvailabilityAtSelectedDayText',
    defaultMessage: 'No availability on this date',
  },
  resWidgetGroupBookingsRoomSelectHigherPartySizeText: {
    id: 'resWidgetGroupBookingsRoomSelectHigherPartySizeText',
    defaultMessage: 'Select a party size of {value} or higher to view availability',
  },
  resWidgetGroupBookingsRoomSelectLowerPartySizeText: {
    id: 'resWidgetGroupBookingsRoomSelectLowerPartySizeText',
    defaultMessage: 'Select a party size of {value} or lower to view availability',
  },
  resWidgetGroupBookingsRoomContactUsLabel: {
    id: 'resWidgetGroupBookingsRoomContactUsLabel',
    defaultMessage: 'Contact Us',
  },
  resWidgetGroupBookingsMoreDetailsBackLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsBackLabel',
    defaultMessage: 'Back',
  },
  resWidgetGroupBookingsMoreDetailsCapacityLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsCapacityLabel',
    defaultMessage: 'Capacity',
  },
  resWidgetGroupBookingsMoreDetailsCapacitySeatingLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsCapacitySeatingLabel',
    defaultMessage: 'Seating: {count}',
  },
  resWidgetGroupBookingsMoreDetailsCapacityStandingLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsCapacityStandingLabel',
    defaultMessage: 'Standing: {count}',
  },
  resWidgetGroupBookingsMoreDetailsPaymentsLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsPaymentsLabel',
    defaultMessage: 'Pricing',
  },
  resWidgetGroupBookingsMoreDetailsMinimumSpendLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsMinimumSpendLabel',
    defaultMessage: 'Minimum spend {amount}',
  },
  resWidgetGroupBookingsMoreDetailsMinimumSpendInfoLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsMinimumSpendInfoLabel',
    defaultMessage: 'Required payment due at booking will be available at checkout',
  },
  resWidgetGroupBookingsMoreDetailsOtherFeesLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsOtherFeesLabel',
    defaultMessage: 'Other Fees',
  },
  resWidgetGroupBookingsMoreDetailsMenuLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsMenuLabel',
    defaultMessage: 'Menu Options',
  },
  resWidgetGroupBookingsMoreDetailsSpaceAmenitiesLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsSpaceAmenitiesLabel',
    defaultMessage: 'Space Amenities',
  },
  resWidgetGroupBookingsMoreDetailsSquareFootageLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsSquareFootageLabel',
    defaultMessage: 'Size of Space',
  },
  resWidgetGroupBookingsMoreDetailsDescriptionLabel: {
    id: 'resWidgetGroupBookingsMoreDetailsDescriptionLabel',
    defaultMessage: 'About',
  },
  resWidgetUpgradeToGroupBookingLabel: {
    id: 'resWidgetUpgradeToGroupBookingLabel',
    defaultMessage: 'Upgrade to a Group Booking',
  },
  resWidgetUpgradeToGroupBookingEmptyResultsLabel: {
    id: 'resWidgetUpgradeToGroupBookingEmptyResultsLabel',
    defaultMessage: 'For your party size, we offer group booking spaces, which can be booked below.',
  },
  resWidgetGroupBookingSummaryLabel: {
    id: 'resWidgetGroupBookingSummaryLabel',
    defaultMessage: 'Group Booking Policy',
  },
  resWidgetGroupBookingSummarySubLabel: {
    id: 'resWidgetGroupBookingSummarySubLabel',
    defaultMessage: 'Total Costs',
  },
  resWidgetGroupBookingSummaryMinSpendLabel: {
    id: 'resWidgetGroupBookingSummaryMinSpendLabel',
    defaultMessage: 'Min Spend',
  },
  resWidgetGroupBookingPolicyBody: {
    id: 'policyPrivateEventsBody',
    defaultMessage: '',
  },
  resWidgetGroupBookingPolicyLabel: {
    id: 'policyPrivateEventsLabel',
    defaultMessage: 'Group Booking Policy',
  },
  resWidgetGroupBookingOptInLabel: {
    id: 'resWidgetGroupBookingOptInLabel',
    defaultMessage: 'I agree to the Group Booking Policy',
  },
  resWidgetReservationHoldLoadingMessage: {
    id: 'resWidgetReservationHoldLoadingMessage',
    defaultMessage: 'Securing your reservation',
  },
  resWidgetGdprPolicyOptInLabel: {
    id: 'resWidgetGdprPolicyOptInLabel',
    defaultMessage: 'I agree to the GDPR policy',
  },
} as const)
