import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  cardLabel: {
    id: 'posiSettings.AuthenticationDataCard.cardLabel',
    defaultMessage: 'Authentication',
  },
  orgShortNameLabel: {
    id: 'posiSettings.AuthenticationDataCard.orgShortName',
    defaultMessage: 'Simphony Org Short Name',
  },
  openidUrlLabel: {
    id: 'posiSettings.AuthenticationDataCard.openidUrl',
    defaultMessage: 'OpenID Connect Provider URL',
  },
  simphonyBaseUrlLabel: {
    id: 'posiSettings.AuthenticationDataCard.simphonyBaseUrl',
    defaultMessage: 'Simphony Transaction Services (Gen2) URL',
  },
  openidClientIdLabel: {
    id: 'posiSettings.AuthenticationDataCard.openidClientId',
    defaultMessage: 'Simphony OAuth Client ID',
  },
} as const)
