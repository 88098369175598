import { useMemo } from 'react'
import { PerkAccessTypeEnum, type PerkAccessType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, Tooltip, RowDivider } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../../locales'

interface PerkTableHeaderProps {
  perkType?: PerkAccessType
}

export function PerkTableHeader({ perkType = PerkAccessTypeEnum.DEFAULT }: PerkTableHeaderProps) {
  const { formatMessage } = useLocales()

  const { perkText, perkInfo } = useMemo(() => {
    switch (perkType) {
      case PerkAccessTypeEnum.EARLY_ACCESS:
        return {
          perkText: formatMessage(perksMessages.perkEarlyAccess),
          perkInfo: formatMessage(perksMessages.perkEarlyAccessInfo),
        }
      case PerkAccessTypeEnum.EXCLUSIVE_ACCESS:
        return {
          perkText: formatMessage(perksMessages.perkExclusiveAccess),
          perkInfo: formatMessage(perksMessages.perkExclusiveAccessInfo),
        }
      default:
        return {
          perkText: formatMessage(perksMessages.perkDefaultAccess),
          perkInfo: '',
        }
    }
  }, [perkType, formatMessage])

  return (
    <RowDivider width="100%">
      <HStack>
        {perkText}&nbsp;
        {perkType !== PerkAccessTypeEnum.DEFAULT && (
          <Tooltip displayAction="hover" content={<>{perkInfo}</>} alignment="top">
            <Icon name="VMSWeb-info" />
          </Tooltip>
        )}
      </HStack>
    </RowDivider>
  )
}
