import { useMemo } from 'react'
import { CostOptionsEnum, CreditCardCollectionOptionsEnum } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { getNumberOptions } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, type RadioChoice, RadioGroup, type CheckboxChoice, CheckboxGroup, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, RelatedSettings, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { SectionBox } from '../components/SectionBox'
import { chargesMessages } from './Charges.locales'
import type { ChargesDiningForm, CreditCardCollectionOptionsValues } from './ChargesDining.zod'
import type { CostOptionsValues } from './ChargesNightlife.zod'

export interface ChargesProps {
  field: Field<ChargesDiningForm>
}

const percentOptions = getNumberOptions({ max: 39, suffix: '%' })

// Do not auto-cancel, 30 minutes, 1 hour, 2 hours, 6 hours, 12 hours, 24 hours, 48 hours, 3 days, 4, 5, 6, 7, 10, 14, 21 days
const CC_AUTO_CANCEL_MINUTES = [-1, 30, 60, 120, 360, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080, 14400, 20160, 30240]

export function ChargesDining({ field }: ChargesProps) {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { paylinkOnly } = useAppContext().venueSettings

  const creditCardCollection = useWatch(field.prop('creditCardCollection'))
  const autoCancelMinutesLong = useWatch(field.prop('incompletePaylinkAutoCancelMinutesLong'))
  const autoCancelMinutesShort = useWatch(field.prop('incompletePaylinkAutoCancelMinutesShort'))

  const costOptionsChoices = useMemo(
    (): Array<CheckboxChoice<CostOptionsValues>> => [
      { value: CostOptionsEnum.MINIMUM_DOLLARS, label: formatMessage(chargesMessages.costOptionsMinDollars) },
      { value: CostOptionsEnum.MINIMUM_BOTTLES, label: formatMessage(chargesMessages.costOptionsMinBottles) },
      { value: CostOptionsEnum.NO_MINIMUM, label: formatMessage(chargesMessages.costOptionsNoMin) },
      { value: CostOptionsEnum.COMPED, label: formatMessage(chargesMessages.costOptionsComped) },
      { value: CostOptionsEnum.TOTAL_DOLLARS, label: formatMessage(chargesMessages.costOptionsTotalDollars) },
    ],
    [formatMessage]
  )

  const ccCollectionOptions = useMemo(
    (): Array<RadioChoice<CreditCardCollectionOptionsValues>> => [
      {
        value: CreditCardCollectionOptionsEnum.BOTH_DEFAULT_MANUAL,
        label: formatMessage(chargesMessages.ccCollectionOptionsBothDefaultManual),
      },
      {
        value: CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK,
        label: formatMessage(chargesMessages.ccCollectionOptionsBothDefaultPaylink),
      },
      {
        value: CreditCardCollectionOptionsEnum.ONLY_MANUAL,
        label: formatMessage(chargesMessages.ccCollectionOptionsManualOnly),
      },
      {
        value: CreditCardCollectionOptionsEnum.ONLY_PAYLINK,
        label: formatMessage(chargesMessages.ccCollectionOptionsPaylinkOnly),
      },
    ],
    [formatMessage]
  )

  const ccAutoCancelOptionsLong = useMemo(
    () =>
      CC_AUTO_CANCEL_MINUTES.map(minutes => ({
        id: minutes,
        label: formatMessage(chargesMessages.ccCollectionOptionsTimeLabel, { minutes }),
      })),
    [formatMessage]
  )
  const ccAutoCancelOptionsShort = useMemo(
    () => ccAutoCancelOptionsLong.filter(option => option.id < autoCancelMinutesLong),
    [ccAutoCancelOptionsLong, autoCancelMinutesLong]
  )

  const handleAutoCancelTimeLongChange = (newAutoCancelMinutesLong: number) => {
    if (newAutoCancelMinutesLong !== -1 && autoCancelMinutesShort >= newAutoCancelMinutesLong) {
      field
        .prop('incompletePaylinkAutoCancelMinutesShort')
        .set(Math.max(...CC_AUTO_CANCEL_MINUTES.filter(minutes => minutes < newAutoCancelMinutesLong)))
    }
  }

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox title={formatMessage(chargesMessages.sectionReservationCharges)}>
        <HStack spacing="lm" alignItems="flex-start">
          <VStack spacing="l">
            <VStack spacing="lm">
              {!paylinkOnly && (
                <Label
                  primary={formatMessage(chargesMessages.ccCollectionOptionsTitle)}
                  secondary={formatMessage(chargesMessages.ccCollectionOptionsDescription)}
                >
                  <RadioGroup field={field.prop('creditCardCollection')} choices={ccCollectionOptions} data-test="cc-collection" />
                </Label>
              )}
              {creditCardCollection !== CreditCardCollectionOptionsEnum.ONLY_MANUAL && (
                <VStack spacing="sm">
                  <Label primary={formatMessage(chargesMessages.ccCollectionOptionsAutoCancelLongTime)}>
                    <HStack>
                      <FormSelect
                        field={field.prop('incompletePaylinkAutoCancelMinutesLong')}
                        options={ccAutoCancelOptionsLong}
                        searchable={false}
                        data-test="auto-cancel-time-long"
                        onChange={handleAutoCancelTimeLongChange}
                      />
                    </HStack>
                  </Label>
                  {autoCancelMinutesLong !== -1 && (
                    <Box ml="sm">
                      <Label primary={formatMessage(chargesMessages.ccCollectionOptionsAutoCancelShortTime)}>
                        <HStack>
                          <FormSelect
                            field={field.prop('incompletePaylinkAutoCancelMinutesShort')}
                            options={ccAutoCancelOptionsShort}
                            searchable={false}
                            data-test="auto-cancel-time-short"
                          />
                        </HStack>
                      </Label>
                    </Box>
                  )}
                </VStack>
              )}
            </VStack>
            <Label
              primary={formatMessage(chargesMessages.serviceChargeTitle)}
              secondary={formatMessage(chargesMessages.serviceChargeDescription)}
            >
              <HStack>
                <FormSelect
                  options={percentOptions}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('serviceCharge')}
                  data-test="serviceCharge"
                />
              </HStack>
            </Label>
            <Label primary={formatMessage(chargesMessages.gratuityTitle)} secondary={formatMessage(chargesMessages.gratuityDescription)}>
              <HStack>
                <FormSelect
                  options={percentOptions}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('gratuity')}
                  data-test="gratuity"
                />
              </HStack>
            </Label>
            <VStack spacing="s" mb="l">
              <Label
                primary={formatMessage(chargesMessages.costOptionsTitle)}
                secondary={formatMessage(chargesMessages.costOptionsDiningDescription)}
              />
              <CheckboxGroup field={field.prop('costOptions')} data-test="costOptions" choices={costOptionsChoices} />
            </VStack>
          </VStack>
          <RelatedSettings
            size="s"
            primary={formatMessage(chargesMessages.taxRatesTitle)}
            secondary={formatMessage(chargesMessages.taxRatesDescription)}
            label={formatMessage(chargesMessages.taxRatesButton)}
            onClick={() => {
              if (venue) {
                window.location.assign(nav.href(routes.manager.manage.taxRates, { params: { venueKey: venue.urlKey } }))
              }
            }}
          />
        </HStack>
      </SectionBox>
    </VStack>
  )
}
