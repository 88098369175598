import * as React from 'react'
import { useGenerateStripeConnectUrlMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { messages } from './StripeConnectButton.locales'

export function StripeConnectButton() {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const [generateStripeConnectUrl] = useGenerateStripeConnectUrlMutation()

  const handleConnectClick = async () => {
    const { data: connectUrl } = (await generateStripeConnectUrl({ venueId })) as { data: string }
    window.location.href = connectUrl
  }

  return (
    <Button variant="primary" onClick={handleConnectClick} isExternal target="_self" data-test="payment-settings-stripe-connect-btn">
      {formatMessage(messages.stripeConnect)}
    </Button>
  )
}
