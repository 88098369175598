import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { getAccessRuleDefaults } from '../../../AccessRule.zod'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { BookingChannelsLocales } from '../BookingChannels.locales'

export function DefaultBookingChannels() {
  const { formatMessage } = useLocales()
  const context = useAccessRuleContext()
  const AccessRuleDefaults = getAccessRuleDefaults(context)

  return (
    <DefaultSettingsReportSection data-test="booking-channels-defaults">
      <Report>
        <ReportPart>
          <ReportLine name={formatMessage(BookingChannelsLocales.title)}>
            {AccessRuleDefaults.bookingChannels.map(tier => tier.selected.map(selected => selected.label))}
          </ReportLine>
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
