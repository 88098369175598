import { defineMessages } from '@sevenrooms/core/locales'

export const venueProfileMessages = defineMessages({
  venueProfileTitle: {
    id: 'venueProfile.title',
    defaultMessage: 'Venue Profile',
  },
  fetchError: {
    id: 'venueProfile.fetchError',
    defaultMessage: 'An error occurred. Try refreshing the page',
  },
  venueInformation: {
    id: 'venueProfile.venueInformation',
    defaultMessage: 'Venue Information',
  },
  guestFacingContact: {
    id: 'venueProfile.guestFacingContact',
    defaultMessage: 'Guest-Facing Contact',
  },
  reviewSites: {
    id: 'venueProfile.reviewSites',
    defaultMessage: 'Review Sites',
  },
  socialMediaLinks: {
    id: 'venueProfile.socialMediaLinks',
    defaultMessage: 'Social Media Links',
  },
  addressLabel: {
    id: 'venueProfile.addressLabel',
    defaultMessage: 'Address',
  },
  crossStreetLabel: {
    id: 'venueProfile.crossStreetLabel',
    defaultMessage: 'Cross Street',
  },
  cityLabel: {
    id: 'venueProfile.cityLabel',
    defaultMessage: 'City',
  },
  stateProvinceLabel: {
    id: 'venueProfile.stateProvinceLabel',
    defaultMessage: 'State / Province',
  },
  postalCodeLabel: {
    id: 'venueProfile.postalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  countryLabel: {
    id: 'venueProfile.countryLabel',
    defaultMessage: 'Country',
  },
  phoneLabel: {
    id: 'venueProfile.phoneLabel',
    defaultMessage: 'Phone',
  },
  primaryContactLabel: {
    id: 'venueProfile.primaryContactLabel',
    defaultMessage: 'Primary Contact',
  },
  websiteLabel: {
    id: 'venueProfile.websiteLabel',
    defaultMessage: 'Website',
  },
  menuLinkLabel: {
    id: 'venueProfile.menuLinkLabel',
    defaultMessage: 'Menu Link',
  },
  googleMapsLinkLabel: {
    id: 'venueProfile.googleMapsLinkLabel',
    defaultMessage: 'Google Maps Link',
  },
  bookingLinkLabel: {
    id: 'venueProfile.bookingLinkLabel',
    defaultMessage: 'Booking Link',
  },
  avgSpendCoverLabel: {
    id: 'venueProfile.avgSpendCoverLabel',
    defaultMessage: 'Avg Spend / Cover',
  },
  guestFacingContactLabel: {
    id: 'venueProfile.guestFacingContactLabel',
    defaultMessage: 'Guest-Facing Contact',
  },
  sendFromUserLabel: {
    id: 'venueProfile.sendFromUserLabel',
    defaultMessage: 'Send from User',
  },
  custom: {
    id: 'venueProfile.custom',
    defaultMessage: 'Custom',
  },
  senderNameLabel: {
    id: 'venueProfile.senderNameLabel',
    defaultMessage: 'Sender Name',
  },
  senderRoleLabel: {
    id: 'venueProfile.senderRoleLabel',
    defaultMessage: 'Sender Role',
  },
  emailAddressLabel: {
    id: 'venueProfile.emailAddressLabel',
    defaultMessage: 'Email Address',
  },
  reviewSitesLabel: {
    id: 'venueProfile.reviewSitesLabel',
    defaultMessage: 'Review Sites',
  },
  googleReviewLabel: {
    id: 'venueProfile.googleReviewLabel',
    defaultMessage: 'Google',
  },
  tripAdvisorReviewLabel: {
    id: 'venueProfile.tripAdvisorReviewLabel',
    defaultMessage: 'TripAdvisor',
  },
  yelpReviewLabel: {
    id: 'venueProfile.yelpReviewLabel',
    defaultMessage: 'Yelp',
  },
  openTableReviewLabel: {
    id: 'venueProfile.openTableReviewLabel',
    defaultMessage: 'OpenTable',
  },
  openTableUsaReviewLabel: {
    id: 'venueProfile.openTableUsaReviewLabel',
    defaultMessage: 'OpenTable USA',
  },
  openRiceReviewLabel: {
    id: 'venueProfile.openRiceReviewLabel',
    defaultMessage: 'OpenRice',
  },
  grubhubReviewLabel: {
    id: 'venueProfile.grubhubReviewLabel',
    defaultMessage: 'Grubhub',
  },
  theForkReviewLabel: {
    id: 'venueProfile.theForkReviewLabel',
    defaultMessage: 'The Fork',
  },
  resyReviewLabel: {
    id: 'venueProfile.resyReviewLabel',
    defaultMessage: 'Resy',
  },
  socialMediaLinksLabel: {
    id: 'venueProfile.socialMediaLinksLabel',
    defaultMessage: 'Social Media Links',
  },
  instagramSocialLabel: {
    id: 'venueProfile.instagramSocialLabel',
    defaultMessage: 'Instagram',
  },
  snapchatSocialLabel: {
    id: 'venueProfile.snapchatSocialLabel',
    defaultMessage: 'Snapchat',
  },
  twitterSocialLabel: {
    id: 'venueProfile.twitterSocialLabel',
    defaultMessage: 'Twitter',
  },
  tiktokSocialLabel: {
    id: 'venueProfile.tiktokSocialLabel',
    defaultMessage: 'TikTok',
  },
  protocolPlaceholder: {
    id: 'venueProfile.protocolPlaceholder',
    defaultMessage: 'http://',
  },
  directChannelEnabledPlaceholder: {
    id: 'directChannelEnabledPlaceholder',
    defaultMessage: 'Direct Channel Enabled',
  },
  saveSuccessMessage: {
    id: 'venueProfile.saveSuccessMessage',
    defaultMessage: 'Successfully saved changes.',
  },
} as const)
