// /* @flow */
import * as React from 'react'
import TextInput from '../TextInput'

/* type Props = {
  onChange: (value: number, field: string) => mixed,
  value: number,
  field: string,
  inputId?: string,
  label?: string,
  charLimit?: number,
  dataTest?: string,
  customInput: Array<string | (() => mixed)>,
  customInputLabel: Array<string | (() => mixed)>,
  customInputWrapper: Array<string | (() => mixed)>,
  allowEmpty?: boolean,
} */

const PositiveIntInput = ({ inputId, value, field, ...etc } /* : Props */) => {
  const inputIdInt = inputId || `sr-positiveintinput-${field}`

  return (
    <TextInput
      {...etc}
      field={field}
      value={`${value}`}
      inputType={TextInput.inputTypes.POSITIVE_INT}
      inputId={inputIdInt}
      isValid={!(value === '')}
    />
  )
}

PositiveIntInput.defaultProps = {
  customInput: [],
  customInputLabel: [],
  customInputWrapper: [],
  allowEmpty: false,
}

export default PositiveIntInput
