import _ from 'lodash'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { v4 } from 'uuid'
import { type LanguageCode, useGetCustomSmsQuery, useSaveCustomSmsMutation, useDeleteCustomSmsMutation } from '@sevenrooms/core/api'
import type { CustomSms } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { customSmsMessages } from '../locales'

export function useCustomSmsContext(
  venueId: string,
  selectedLanguageCode: LanguageCode,
  defaultLanguageCode: LanguageCode,
  isLanguageListFetching: boolean
) {
  const { formatMessage } = useLocales()
  const [updatedCustomSmsIds, setUpdatedCustomSmsIds] = useState<Set<string>>(new Set())
  const [customSmsList, setCustomSmsList] = useState<CustomSms[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [customSmsToDeleteId, setCustomSmsToDeleteId] = useState('')

  const {
    data: customSmsListData,
    isFetching: isSmsListFetching,
    refetch: reloadCustomSms,
  } = useGetCustomSmsQuery(
    {
      venueId,
      selectedLanguageCode,
      defaultLanguageCode,
    },
    {
      skip: !venueId || !defaultLanguageCode || !selectedLanguageCode,
      refetchOnMountOrArgChange: true,
    }
  )

  const getNewEmptyRow = useCallback(
    (): CustomSms => ({
      name: '',
      [defaultLanguageCode]: '',
      [selectedLanguageCode]: '',
      id: v4(),
      isNew: true,
    }),
    [defaultLanguageCode, selectedLanguageCode]
  )

  useEffect(() => {
    const smsList = customSmsListData?.customSmsList
    if (customSmsListData) {
      setCustomSmsList(smsList?.length ? smsList : [getNewEmptyRow()])
    }
  }, [customSmsListData, getNewEmptyRow])

  const addCustomSms = useCallback(() => {
    const newRow = getNewEmptyRow()
    setCustomSmsList([...customSmsList, newRow])
    const updatedIds = new Set(updatedCustomSmsIds)
    updatedIds.add(newRow.id)
    setUpdatedCustomSmsIds(updatedIds)
  }, [customSmsList, getNewEmptyRow, updatedCustomSmsIds])

  const updateCustomSms = useCallback(
    (updatedCustomSmsId: string, updatedCell: { cell: string; value: string }) => {
      const { cell: updatedLanguage, value: updatedValue } = updatedCell
      const updatedCustomSmsList = [...customSmsList]
      const updatedIndex = _.findIndex(updatedCustomSmsList, customSms => customSms.id === updatedCustomSmsId)
      const updatedSms = updatedCustomSmsList[updatedIndex]
      if (updatedSms) {
        updatedCustomSmsList[updatedIndex] = { ...updatedSms, [updatedLanguage]: updatedValue }
        setCustomSmsList(updatedCustomSmsList)

        const updatedIds = new Set(updatedCustomSmsIds)
        updatedIds.add(updatedCustomSmsId)
        setUpdatedCustomSmsIds(updatedIds)
      }
    },
    [customSmsList, updatedCustomSmsIds]
  )

  const [saveCustomSms, saveStatus] = useSaveCustomSmsMutation()
  const onSave = useCallback(
    (
      venueId: string,
      customSmsList: CustomSms[],
      updatedCustomSmsIds: Set<string>,
      selectedLanguageCode: LanguageCode,
      defaultLanguageCode: LanguageCode
    ) => {
      if (
        customSmsList.find(
          customSms =>
            updatedCustomSmsIds.has(customSms.id) &&
            (!customSms[selectedLanguageCode] || !customSms[defaultLanguageCode] || !customSms.name)
        )
      ) {
        notify({ content: formatMessage(customSmsMessages.customSmsSaveEmptyValueErrorMessage), type: 'error' })
        return
      }
      const uniqueNames = new Set(customSmsList.map(customSms => customSms.name))
      if (uniqueNames.size < customSmsList.length) {
        notify({ content: formatMessage(customSmsMessages.customSmsSaveNonUniqueNameErrorMessage), type: 'error' })
        return
      }
      saveCustomSms({ venueId, customSmsList, updatedCustomSmsIds, selectedLanguageCode, defaultLanguageCode })
        .unwrap()
        .then(() => {
          if (customSmsList.find(customSms => customSms.isNew)) {
            reloadCustomSms()
          }
          notify({ content: formatMessage(customSmsMessages.customSmsSaveSuccessMessage), type: 'success' })
        })
        .catch(() => {
          notify({ content: formatMessage(customSmsMessages.customSmsSaveErrorMessage), type: 'error' })
        })
    },
    [saveCustomSms, formatMessage, reloadCustomSms]
  )

  const updateSmsListForDelete = useCallback(
    (smsToDeleteId: string) => {
      const updatedCustomSmsList = customSmsList.filter(customSms => customSms.id !== smsToDeleteId)
      if (!updatedCustomSmsList.length) {
        setCustomSmsList([])
        setCustomSmsList([getNewEmptyRow()])
      } else {
        setCustomSmsList(updatedCustomSmsList)
      }
    },
    [customSmsList, setCustomSmsList, getNewEmptyRow]
  )

  const onDelete = useCallback(
    (smsToDeleteId: string, isNew: boolean) => {
      if (isNew) {
        updateSmsListForDelete(smsToDeleteId)
      } else {
        setCustomSmsToDeleteId(smsToDeleteId)
        setShowDeleteModal(true)
      }
    },
    [updateSmsListForDelete]
  )

  const [deleteCustomSms, deleteStatus] = useDeleteCustomSmsMutation()

  const onDeleteConfirm = useCallback(
    (venueId: string, smsToDeleteId: string) => {
      deleteCustomSms({ venueId, customSmsId: smsToDeleteId })
        .unwrap()
        .then(() => {
          updateSmsListForDelete(smsToDeleteId)
          notify({ content: formatMessage(customSmsMessages.customSmsDeleteSuccessMessage), type: 'success' })
        })
        .catch(() => {
          notify({ content: formatMessage(customSmsMessages.customSmsDeleteErrorMessage), type: 'error' })
        })
      setCustomSmsToDeleteId('')
      setShowDeleteModal(false)
    },
    [deleteCustomSms, updateSmsListForDelete, formatMessage]
  )

  const isLoading = useMemo(
    () => isLanguageListFetching || isSmsListFetching || saveStatus.isLoading || deleteStatus.isLoading,
    [isLanguageListFetching, isSmsListFetching, saveStatus.isLoading, deleteStatus.isLoading]
  )

  return {
    customSmsList,
    updatedCustomSmsIds,
    updateCustomSms,
    addCustomSms,
    onSave,
    customSmsToDeleteId,
    onDelete,
    showDeleteModal,
    onDeleteConfirm,
    setShowDeleteModal,
    isLoading,
  }
}
