export const ScheduleTestId = {
  section: 'schedule',
  dateRangePicker: 'date-range-picker',
  dayOfWeekPicker: 'day-of-week-picker',
  timeSlotFilterType: 'time-slot-filter-type',
  shiftCategoryMultiSelect: 'shift-category-multi-select',
  specificTimesMultiSelect: 'specific-times-multi-select',
  startTimeSelect: 'start-time-select',
  endTimeSelect: 'end-time-select',
  restrictToShiftsCheckBox: 'restrict-to-shifts-check-box',
  noOverlapsWarning: 'no-overlaps-warning',
  report: 'schedule-report',

  // startDate: 'ui-kit-date-range-picker-start-date'
  // endDate: 'ui-kit-date-range-picker-end-date'
  // isInfinite: 'ui-kit-date-range-picker-is-infinite'
  // dayOfWeekChoice: ['Mon',...,'Fri'],
  // toggleAllDays: 'ui-kit-date-of-week-picker-toggle-all-days',
} as const
