import { defineMessages } from '@sevenrooms/core/locales'

export const guestEmailSMSMessages = defineMessages({
  reservationEndTime: {
    id: 'settings.general.reservationEndTime',
    defaultMessage: 'Reservation end time',
  },
  seatingArea: {
    id: 'settings.general.seatingArea',
    defaultMessage: 'Seating area',
  },
  specialAttentionMessage: {
    id: 'settings.general.specialAttentionMessage',
    defaultMessage: 'Special attention message',
  },
  salutation: {
    id: 'settings.general.salutation',
    defaultMessage: 'Salutation',
  },
  qrCode: {
    id: 'settings.general.qrCode',
    defaultMessage: 'QR code',
  },
  emailOnly: {
    id: 'settings.general.emailOnly',
    defaultMessage: 'Email',
  },
  smsOnly: {
    id: 'settings.general.smsOnly',
    defaultMessage: 'SMS',
  },
  emailAndSMS: {
    id: 'settings.general.emailAndSms',
    defaultMessage: 'Email and SMS',
  },
  sectionGuestBookingNotification: {
    id: 'settings.general.section.guestBookingNotification',
    defaultMessage: 'Guest Booking Notification',
  },
  sendBookingNotification: {
    id: 'settings.general.sendByDefault.title',
    defaultMessage: 'Send a Booking Notification when reservation is made internally (via the Reservation Slideout)',
  },
  sendByDefaultDescription: {
    id: 'settings.general.sendByDefault.description',
    defaultMessage: 'Please Note: For reservations made via widget, the Booking Notification will always automatically send to guests.',
  },
  optionalFieldsTitle: {
    id: 'settings.general.optionalFields.title',
    defaultMessage: 'Optional Fields to Display',
  },
  optionalFieldsDescription: {
    id: 'settings.general.optionalFields.description',
    defaultMessage:
      'In addition to reservation date, time and party size, display the following optional fields in guest emails (fields will not display in SMS messages)',
  },
  optionalFieldsDescriptionEmailOnly: {
    id: 'settings.general.optionalFields.descriptionEmailOnly',
    defaultMessage: 'In addition to reservation date, time and party size, display the following optional fields',
  },
  sectionGuestListNotificationEmails: {
    id: 'settings.general.section.guestListNotificationEmails',
    defaultMessage: 'Guest List Notification Emails',
  },
  sectionGuestListNotificationEmailsQRCodeTitle: {
    id: 'settings.general.section.guestListNotificationEmails.QRCode.title',
    defaultMessage: 'Show QR Code',
  },
  sectionGuestListNotificationEmailsQRCodeDescription: {
    id: 'settings.general.section.guestListNotificationEmails.QRCode.description',
    defaultMessage: 'Include QR Code in the Guestlist notification email',
  },
  sectionGuestReservationReminder: {
    id: 'settings.general.section.guestReservationReminder',
    defaultMessage: 'Guest Reservation Reminders',
  },
  sendReservationReminderTitle: {
    id: 'settings.general.sendReservationReminder.title',
    defaultMessage: 'Send Reservation Reminders',
  },
  sendReservationReminderDescription: {
    id: 'settings.general.sendReservationReminder.description',
    defaultMessage: 'Automatically send guests a Reservation Reminder prompting them to confirm their reservation',
  },
  bookingDeliveryMethodTitle: {
    id: 'settings.general.bookingDeliveryMethod.title',
    defaultMessage: 'Default Delivery Method',
  },
  includeCustomQuestionTitle: {
    id: 'settings.general.includeCustomQuestion.title',
    defaultMessage: 'Include a custom question to be answered by the guest?',
  },
  anythingElsePlaceholder: {
    id: 'settings.general.anythingElse.placeholder',
    defaultMessage: 'Anything else we should know?',
  },
  sectionGuestReservationArrival: {
    id: 'settings.general.section.guestReservationArrival',
    defaultMessage: 'Guest Virtual Arrival SMS',
  },
  sendArrivalSmsTitle: {
    id: 'settings.general.guestArrivalSms.title',
    defaultMessage: 'Send Virtual Arrival SMS',
  },
  guestArrivalSmsDescription: {
    id: 'settings.general.guestArrivalSms.description',
    defaultMessage: 'Automatically send guests a SMS message prompting them to mark themselves as arrived upon arrival',
  },
  beforeReservation: {
    id: 'settings.general.beforeReservation',
    defaultMessage: 'before reservation',
  },
  sectionGuestFeedback: {
    id: 'settings.general.section.guestFeedback',
    defaultMessage: 'Guest Feedback Emails',
  },
  guestFeedbackTitle: {
    id: 'settings.general.guestFeedback.title',
    defaultMessage: 'Send Guest Feedback Emails',
  },
  guestFeedbackDescription: {
    id: 'settings.general.guestFeedback.description',
    defaultMessage: 'Automatically send guests a Feedback Email prompting them to provide feedback on their experience',
  },
  sectionEmailDesign: {
    id: 'settings.general.section.emailDesign',
    defaultMessage: 'Transactional Email Design',
  },
  sectionEmailDesignDescription: {
    id: 'settings.general.section.emailDesign.description',
    defaultMessage:
      'Manage the design of transactional guest-facing emails: Booking Notification, Reservation Reminder and Feedback Emails',
  },
  uploadLogoTitle: {
    id: 'settings.general.emailDesign.title',
    defaultMessage: 'Upload Logo',
  },
  emailPreviewTitle: {
    id: 'settings.general.emailPreview.title',
    defaultMessage: 'Email Preview',
  },
  emailLogoHeightTitle: {
    id: 'settings.general.emailLogoHeight.title',
    defaultMessage: 'Header Height',
  },
  venueColorTitle: {
    id: 'settings.general.venueColor.title',
    defaultMessage: 'Email Header Background Color',
  },
  venueColorDefault: {
    id: 'settings.general.venueColor.default',
    defaultMessage: 'None (white)',
  },
  altColorTitle: {
    id: 'settings.general.altColor.title',
    defaultMessage: 'Buttons and Links Color',
  },
  altColorDefault: {
    id: 'settings.general.altColor.default',
    defaultMessage: 'Same as background color',
  },
  customColor: {
    id: 'settings.general.customColor',
    defaultMessage: 'Custom',
  },
  afterReservation: {
    id: 'settings.general.section.afterReservation',
    defaultMessage: 'after reservation',
  },
  afterOrders: {
    id: 'settings.general.section.afterOrders',
    defaultMessage: 'after order complete',
  },
  feedbackOptionsTitle: {
    id: 'settings.general.feedbackOptions.title',
    defaultMessage: 'Feedback Categories',
  },
  feedbackOptionsDescription: {
    id: 'settings.general.feedbackOptions.description',
    defaultMessage: 'Customize the categories you would like your guests to rate',
  },
  feedbackOptionsOverall: {
    id: 'settings.general.feedbackOptionsOverall',
    defaultMessage: 'Overall',
  },
  feedbackOptionsFood: {
    id: 'settings.general.feedbackOptionsFood',
    defaultMessage: 'Food',
  },
  feedbackOptionsDrinks: {
    id: 'settings.general.feedbackOptionsDrinks',
    defaultMessage: 'Drinks',
  },
  feedbackOptionsServices: {
    id: 'settings.general.feedbackOptionsServices',
    defaultMessage: 'Services',
  },
  feedbackOptionsAmbience: {
    id: 'settings.general.feedbackOptionsAmbience',
    defaultMessage: 'Ambience',
  },
  guestFeedbackExcludeDescription: {
    id: 'settings.general.guestFeedback.excludeDescription',
    defaultMessage: 'Is there anyone you would like to exclude from receiving the feedback survey email? (optional)',
  },
})
