import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Grid } from '@sevenrooms/core/ui-kit/layout'
import { venueProfileMessages } from '../../locales'
import type { ReviewSiteFieldKey, ReviewSitesFormFields } from './ReviewSitesForm.zod'

export interface ReviewSitesFormProps {
  fields: ReviewSitesFormFields
}

const reviewSitesConfig = [
  { fieldKey: 'reviewSiteGoogle', labelMessage: venueProfileMessages.googleReviewLabel, directEnabledKey: 'reviewSiteGoogleDirectEnabled' },
  { fieldKey: 'reviewSiteGrubhub', labelMessage: venueProfileMessages.grubhubReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteOpenTable', labelMessage: venueProfileMessages.openTableReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteOpenTableUsa', labelMessage: venueProfileMessages.openTableUsaReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteOpenRice', labelMessage: venueProfileMessages.openRiceReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteTheFork', labelMessage: venueProfileMessages.theForkReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteTripadvisor', labelMessage: venueProfileMessages.tripAdvisorReviewLabel, directEnabledKey: '' },
  { fieldKey: 'reviewSiteYelp', labelMessage: venueProfileMessages.yelpReviewLabel, directEnabledKey: '' },
]

export function ReviewSitesForm({ fields }: ReviewSitesFormProps) {
  const { formatMessage } = useLocales()
  const reviewSiteGoogleDirectEnabled = useWatch(fields.prop('reviewSiteGoogleDirectEnabled'))

  return (
    <Grid gridTemplateColumns="1fr 1fr" columnGap="lm" rowGap="m">
      {reviewSitesConfig.map(({ fieldKey, labelMessage, directEnabledKey }) => {
        const reviewSiteField = fields.prop(fieldKey as ReviewSiteFieldKey)
        const isDirectEnabled = directEnabledKey === 'reviewSiteGoogleDirectEnabled' ? reviewSiteGoogleDirectEnabled : false
        const placeholder = formatMessage(
          !isDirectEnabled ? venueProfileMessages.protocolPlaceholder : venueProfileMessages.directChannelEnabledPlaceholder
        )

        return (
          <Label key={fieldKey} primary={formatMessage(labelMessage)}>
            <FormInput field={reviewSiteField} placeholder={placeholder} disabled={isDirectEnabled} />
          </Label>
        )
      })}
    </Grid>
  )
}
