import { defineMessages } from '@sevenrooms/core/locales'

export const referralsMessages = defineMessages({
  referralProgram: {
    id: 'referrals.referralProgram',
    defaultMessage: 'Referral Program',
  },
  referralsDescription: {
    id: 'referrals.description',
    defaultMessage: 'Manage the details of your Referral Program',
  },
  referrers: {
    id: 'referrals.referrers',
    defaultMessage: 'Referrers',
  },
  referrersSectionDescription: {
    id: 'referrals.referrers',
    defaultMessage: 'Referrers are clients who refer other clients to dine at your group for the first time.',
  },
  referralsUnsavedDataWarning: {
    id: 'referrals.referralsUnsavedDataWarning',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  referralAutoTag: {
    id: 'referrals.referralAutoTag',
    defaultMessage: 'Auto-tag',
  },
  referralGlobalPerks: {
    id: 'referrals.globalPerks',
    defaultMessage: 'Perks Given at All Venues',
  },
  referralLocalPerks: {
    id: 'referrals.localPerks',
    defaultMessage: 'Perks Given Only at This Venue',
  },
  referralAdditionalNotes: {
    id: 'referrals.additionalNotes',
    defaultMessage: 'Additional Notes',
  },
  referralLevel: {
    id: 'referrals.level',
    defaultMessage: 'Referrer Level',
  },
  referralConditionStart: {
    id: 'referrals.conditionStart',
    defaultMessage: 'Tag clients who have referred at least',
  },
  referralConditionEnd: {
    id: 'referrals.conditionEnd',
    defaultMessage: 'new clients via their unique referral link that have completed reservations.',
  },
  referralNoGlobalPerks: {
    id: 'referrals.noGlobalPerks',
    defaultMessage: 'There are no global Perks yet. To add Perks, go to Marketing > Perks.',
  },
  referralSearchPerks: {
    id: 'referrals.searchPerks',
    defaultMessage: 'Search Perks',
  },
  referralNoLocalPerks: {
    id: 'referrals.noLocalPerks',
    defaultMessage: 'There are no local Perks yet. To add Perks, go to Marketing > Perks.',
  },
  referralAutomatedEmail: {
    id: 'referrals.automatedEmail',
    defaultMessage: 'Automated Email',
  },
  referralViewEmailDetails: {
    id: 'referrals.viewEmailDetails',
    defaultMessage: 'View Email Details',
  },
  communicationConfigurations: {
    id: 'referrals.communicationConfigurations',
    defaultMessage: 'Communication Configurations',
  },
  bookingConfirmationEmail: {
    id: 'referrals.bookingConfirmationEmail',
    defaultMessage: 'Booking Confirmation email',
  },
  feedbackEmail: {
    id: 'referrals.feedbackEmail',
    defaultMessage: 'Feedback email',
  },
  feedbackSuccessPage: {
    id: 'referrals.feedbackSuccessPage',
    defaultMessage: 'Feedback success page',
  },
  reservationTag: {
    id: 'referrals.reservationTag',
    defaultMessage: 'Reservation Tag',
  },
  referredClient: {
    id: 'referrals.referredClient',
    defaultMessage: 'Referred Client',
  },
  referredClientDescription: {
    id: 'referrals.reservationTag',
    defaultMessage: 'Referred clients are clients who book a reservation at your group via a link sent by a Referrer.',
  },
  referralNewClient: {
    id: 'referrals.referralNewClient',
    defaultMessage: 'Referral: New',
  },
  referralNewClientDescription: {
    id: 'referrals.referralNewClientDescription',
    defaultMessage: 'A client who is dining with your group for the first time.',
  },
  referralExistingClient: {
    id: 'referrals.referralExistingClient',
    defaultMessage: 'Referral: Existing',
  },
  referralExistingClientDescription: {
    id: 'referrals.referralNewClientDescription',
    defaultMessage: 'A client who had dined at your group before but booked via referral link.',
  },
  bookingReminderEmail: {
    id: 'referrals.bookingReminderEmail',
    defaultMessage: 'Booking Reminder email',
  },
  customizeText: {
    id: 'referrals.customizeText',
    defaultMessage: 'Customize Text',
  },
  conditionThresholdError: {
    id: 'referrals.conditionThresholdError',
    defaultMessage: 'The threshold needs to greater than that of Tier {tier}.',
  },
  fetchError: {
    id: 'referrals.fetchError',
    defaultMessage: 'An error occurred. Try refreshing the page',
  },
  additionalNotesInfo: {
    id: 'referrals.additionalNotesInfo',
    defaultMessage:
      'Front of house team can access this information on the reservation slideout on both web and mobile and the daily/program notes section.',
  },
  referrersEmailConfigurationsDescription: {
    id: 'referrals.referrersEmailConfigurationsDescription',
    defaultMessage: 'You must add at least one global Perk to the Referrer Auto-tag in order for the unique link card to be displayed.',
  },
  referrersEmailConfigurationsTitle: {
    id: 'referrals.referrersEmailConfigurationsTitle',
    defaultMessage: 'Select which guest-facing communications will include a client’s unique referral link.',
  },
  referredNewClientEmailConfigurationsTitle: {
    id: 'referrals.referredNewClientEmailConfigurationsTitle',
    defaultMessage: 'Select emails to show Perks to Referral: New',
  },
  referredExistingClientEmailConfigurationsTitle: {
    id: 'referrals.referredExistingClientEmailConfigurationsTitle',
    defaultMessage: 'Select emails to show Perks to Referral: Existing',
  },
  referredNewClientEmailConfigurationsDescription: {
    id: 'referrals.referredNewClientEmailConfigurationsDescription',
    defaultMessage: 'You must add at least one Perk to the Referral: New tag in order for the unique referral Perk card to be displayed.',
  },
  referredExistingClientEmailConfigurationsDescription: {
    id: 'referrals.referredExistingClientEmailConfigurationsDescription',
    defaultMessage:
      'You must add at least one Perk to the Referral: Existing tag in order for the unique referral Perk card to be displayed.',
  },
  referredNewClientPreviewText: {
    id: 'referrals.referredNewClientPreviewText',
    defaultMessage: 'Referral: New Email Block Preview',
  },
  referredExistingClientPreviewText: {
    id: 'referrals.referredExistingClientPreviewText',
    defaultMessage: 'Referral: Existing Email Block Preview',
  },
  referralProgramActivateTitle: {
    id: 'referrals.referralProgramActivateTitle',
    defaultMessage: 'Are you sure you want to activate this Referral Program?',
  },
  referralProgramDeactivateTitle: {
    id: 'referrals.referralProgramDeactivateTitle',
    defaultMessage: 'Are you sure you want to deactivate this Referral Program?',
  },
  referralProgramActivateSubTitle: {
    id: 'referrals.referralProgramActivateSubTitle',
    defaultMessage: 'Activating this Program will activate it on all venues.',
  },
  referralProgramDeactivateSubTitle: {
    id: 'referrals.referralProgramDeactivateSubTitle',
    defaultMessage: 'Deactivating this Program will remove it from all venues.',
  },
  referralProgramSaveSuccess: {
    id: 'referrals.referralProgramSaveSucess',
    defaultMessage: 'Referral Program updated successfully',
  },
  referralDeactivateProgram: {
    id: 'referrals.referralDeactivateProgram',
    defaultMessage: 'Deactivate Program',
  },
  referralActivateProgram: {
    id: 'referrals.referralActivateProgram',
    defaultMessage: 'Activate Program',
  },
  referralProgramSaveModalTitle: {
    id: 'referrals.referralProgramSaveModalTitle',
    defaultMessage: 'Are you sure you want to make the changes globally?',
  },
  referralProgramSaveModalSubTitle: {
    id: 'referrals.referralProgramSaveModalSubTitle',
    defaultMessage: 'This change will be reflected on all venues in your group.',
  },
  referralProgramDisabledCommunications: {
    id: 'referrals.referralProgramDisabledCommunications',
    defaultMessage: 'Add a perk to enable guest communications.',
  },
} as const)
