import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { referralsMessages } from '../../locales'

export interface ReferralProgramEnableModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onUpdate: (referralProgramEnabled: boolean) => void
}

export function ReferralProgramEnableModal({ closeHref, onUpdate }: ReferralProgramEnableModalProps) {
  const { formatMessage } = useLocales()
  const { params } = useDestination(routes.manager2.marketing.referrals.referralProgramEnableModal)
  const referralProgramEnabled = params?.referralProgramEnabled

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle
            title={
              referralProgramEnabled
                ? formatMessage(referralsMessages.referralProgramActivateTitle)
                : formatMessage(referralsMessages.referralProgramDeactivateTitle)
            }
          />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>
          {referralProgramEnabled
            ? formatMessage(referralsMessages.referralProgramActivateSubTitle)
            : formatMessage(referralsMessages.referralProgramDeactivateSubTitle)}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button data-test="sr-referrals-modal-cancel-btn" href={closeHref} variant="secondary">
            {formatMessage(commonMessages.cancel)}
          </Button>
          {referralProgramEnabled ? (
            <Button
              data-test="sr-referrals-modal-activate-btn"
              variant={referralProgramEnabled ? 'primary' : 'primary-warning'}
              onClick={() => {
                onUpdate(referralProgramEnabled)
              }}
            >
              {formatMessage(commonMessages.activate)}
            </Button>
          ) : (
            <Button
              data-test="sr-referrals-modal-deactivate-btn"
              variant={referralProgramEnabled ? 'primary' : 'primary-warning'}
              onClick={() => {
                onUpdate(referralProgramEnabled)
              }}
            >
              {formatMessage(commonMessages.deactivate)}
            </Button>
          )}
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
