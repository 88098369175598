import React from 'react'
import styled from 'styled-components'
import history from 'mgr/pages/shared/utils/History'
import LocationBar from 'svr/component-lib/Manager/Layout/LocationBar'
import TitleBar from 'svr/component-lib/Manager/Layout/TitleBar'
import Spinner from 'svr/lib/Modal/Spinner'

const LayoutContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.color.white};
  overflow: auto;
  max-width: calc(100vw - 80px - 180px);
  min-width: 500px;
`

const HeadersContainer = styled.div``

const MainContentContainer = styled.div`
  flex: 1;
`

const SpinnerContainer = styled.div`
  margin-top: 15%;
  margin-right: 6%;
`

const ContentLoader = ({ showSpinner, children, spinnerImgUrl }) => {
  if (showSpinner) {
    return (
      <SpinnerContainer>
        <Spinner mediaUrl={spinnerImgUrl} />
      </SpinnerContainer>
    )
  }

  return children
}

class ContentLayout extends React.PureComponent {
  render() {
    const {
      title,
      venue,
      venues,
      onChangeVenue,
      venueGroup,
      userDomain,
      onChangeVenueGroup,
      Actions,
      AdditionalActions,
      routes = [],
      contentHistory = history,
      hideTitleBar = false,
      overrideChangeVenue = false,
      overrideRefreshOnVenueChange = false,
      showSpinner = false,
      spinnerImgUrl = '',
      children,
    } = this.props

    return (
      <LayoutContainer>
        <HeadersContainer>
          <LocationBar
            venue={venue}
            venues={venues}
            onChangeVenue={onChangeVenue}
            venueGroup={venueGroup}
            userDomain={userDomain}
            onChangeVenueGroup={onChangeVenueGroup}
            routes={routes}
            history={contentHistory}
            overrideChangeVenue={overrideChangeVenue}
            overrideRefreshOnVenueChange={overrideRefreshOnVenueChange}
          />
          {!hideTitleBar && <TitleBar id="content-layout-title" title={title} Actions={Actions} AdditionalActions={AdditionalActions} />}
        </HeadersContainer>

        <ContentLoader showSpinner={showSpinner} spinnerImgUrl={spinnerImgUrl}>
          <MainContentContainer>{children}</MainContentContainer>
        </ContentLoader>
      </LayoutContainer>
    )
  }
}

export default ContentLayout
