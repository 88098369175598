import React, { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { MultiSelectTree, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { HStack, BaseSection, Loader, Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { TableMappingLocales } from './TableMapping.locales'
import type { TableMappingProps } from './TableMapping.types'

export function TableMapping({ tablesData, spaceData, integrationName, onChange, isLoading, isDisabled }: TableMappingProps) {
  const { formatMessage } = useLocales()

  const spaceTree = useMemo(
    () =>
      spaceData.map(space => ({
        ...space,
        treeData:
          tablesData?.map((tableSection: TreeNode) => ({
            id: `${space.id}-${tableSection.id}`,
            label: tableSection.label,
            value: tableSection.id,
            checked: space.tables?.includes(tableSection.id),
            children: tableSection.children?.map(table => ({
              id: `${space.id}-${table.id}`,
              label: table.label,
              value: table.value,
              spaceId: space.id,
              checked: space.tables?.includes(table.value) || space.tables?.includes(tableSection.id),
            })),
          })) || [],
      })),
    [tablesData, spaceData]
  )

  return (
    <BaseSection>
      {isLoading && (
        <Flex minHeight="274px" justifyContent="center" alignItems="center" data-test="loading-box">
          <Loader>
            <Text textStyle="body1">{formatMessage(TableMappingLocales.loadingTitle, { name: integrationName })}</Text>
          </Loader>
        </Flex>
      )}

      {!isLoading && (
        <Box p="m" data-test="main-box">
          <HStack spacing="m">
            <Flex width="100px">
              <Text textStyle="body1Bold">{formatMessage(TableMappingLocales.spaceId, { name: integrationName })}</Text>
            </Flex>
            <Flex flex={2}>
              <Text textStyle="body1Bold">{formatMessage(TableMappingLocales.spaceName, { name: integrationName })}</Text>
            </Flex>
            <Flex flex={3}>
              <Text textStyle="body1Bold">{formatMessage(TableMappingLocales.sevenRoomsTablesTitle)}</Text>
            </Flex>
          </HStack>

          {spaceTree.map(space => (
            <HStack pb="s" pt="s" key={space.id} spacing="m" data-test={`row-${space.id}`}>
              <Flex width="100px">
                <Input value={space.id} disabled />
              </Flex>
              <Flex flex={2}>
                <Input value={space.name} disabled />
              </Flex>
              <Flex flex={3}>
                <MultiSelectTree
                  treeData={space.treeData}
                  placeholder={formatMessage(TableMappingLocales.sevenRoomsTablesPlaceholder)}
                  displayAsTextWhenCollapsed
                  containerWidth="100%"
                  onChange={(currentNode, selectedNodes) => {
                    onChange(currentNode, selectedNodes, space.id)
                  }}
                  disabled={isLoading || isDisabled}
                />
              </Flex>
            </HStack>
          ))}
        </Box>
      )}
    </BaseSection>
  )
}
