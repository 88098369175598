import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { oneTimeEmailCenterMessages } from '../../locales'

export interface ConfirmArchiveOneTimeEmailModalProps {
  onConfirm: (campaignId: string) => void
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function ConfirmArchiveOneTimeEmailModal({ closeHref, onConfirm }: ConfirmArchiveOneTimeEmailModalProps) {
  const { formatMessage } = useLocales()
  const { params } = useDestination(routes.manager2.marketing.oneTimeEmailCenter.confirmArchiveModal)
  const campaignId = params?.campaignId
  const { confirmArchiveEmailModalTitle, confirmArchiveEmailModalInformation, confirmArchiveEmailModalHistoryInfo } =
    oneTimeEmailCenterMessages

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle title={formatMessage(confirmArchiveEmailModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(confirmArchiveEmailModalInformation)} </Text>
        <Text>{formatMessage(confirmArchiveEmailModalHistoryInfo)} </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm(campaignId)
            }}
            data-test="confirm-button"
          >
            {formatMessage(oneTimeEmailCenterMessages.confirmArchiveEmailModal)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
