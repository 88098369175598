import { useMemo } from 'react'
import { useGetExperiencesQuery, useGetAccessRulesListQuery } from '@sevenrooms/core/api'
import { OfferTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Loader, Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { SpacesList } from './components'
import { spacesMessages } from './spaces.locales'

export function GroupBookings() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey, venueId } = useVenueContext()

  const { data: experiencesData, isLoading: isExperiencesLoading } = useGetExperiencesQuery({ venueId })
  const { data: accessRuleData, isLoading: isAccessRuleLoading } = useGetAccessRulesListQuery({ venueId })
  const isLoading = isExperiencesLoading || isAccessRuleLoading

  const experiences = useMemo(
    () => experiencesData?.results.filter(({ offerType }) => offerType === OfferTypeEnum.PDR) ?? [],
    [experiencesData]
  )

  return (
    <>
      <SettingsPageMeta title={formatMessage(spacesMessages.pageTitle)} />
      <SettingsPageContent
        title={formatMessage(spacesMessages.pageTitle)}
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="968px"
        actions={
          <Flex>
            <Button
              variant="primary"
              icon="VMSWeb-add"
              onClick={() => nav.push(routes.manager2.marketing.groupBookings.createSpace, { params: { venueKey } })}
              data-test="create-new-space"
            >
              {formatMessage(spacesMessages.createNewSpace)}
            </Button>
          </Flex>
        }
      >
        <Box p="none lm" maxWidth="968px">
          {isLoading ? <Loader /> : <SpacesList experiences={experiences} accessRules={accessRuleData ?? []} />}
        </Box>
      </SettingsPageContent>
    </>
  )
}
