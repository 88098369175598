import _ from 'lodash'
import moment from 'moment-timezone'
import { determineFrontEndAutotagType } from 'mgr/pages/single-venue/marketing/utils/custom-autotags'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeAutotagsActivityReportParams = state => ({
  activity: state.autotags.data.histogram,
})

const computeAutotagsActivityReportValues = ({ activity = [] }) =>
  activity.map(row => [moment(row.date).format('MMM D'), row.num_tags_added, row.num_tags_removed])

export const selectAutotagsActivityReportValues = createShallowEqualSelector(
  computeAutotagsActivityReportParams,
  computeAutotagsActivityReportValues
)

const computeAutotagsListParams = state => ({
  list: state.autotags.data.list,
  activity: state.autotags.data.tagActivity,
  user: state.appState.user,
  userDomain: state.appState.userDomain,
  venueSettings: state.appState.venueSettings,
})

const computeAutotagsListValues = ({ list = [], activity = {}, user, userDomain, venueSettings }) => {
  let fullList = list.map(el => {
    if (el.is_config_type_custom) {
      // eslint-disable-next-line no-param-reassign
      el.typeOverride = determineFrontEndAutotagType(el.custom_config_tree_client)
    }
    return {
      ...el,
      autotag_type_label: el.typeOverride || (el.autotag_config_is_global ? 'Global' : 'Local'),
      num_tags_removed: -_.get(activity, `[${el.tag_id}].num_tags_removed`, null),
      num_tags_added: _.get(activity, `[${el.tag_id}].num_tags_added`, null),
    }
  })
  if (!venueSettings.is_custom_autotags_enabled) {
    fullList = _.filter(fullList, el => !el.is_config_type_custom)
  }
  const partitionedByActiveList = _.partition(fullList, el => el.is_active)

  return {
    activeTags: partitionedByActiveList[0],
    inactiveTags: partitionedByActiveList[1],
  }
}

export const selectAutotagsListValues = createShallowEqualSelector(computeAutotagsListParams, computeAutotagsListValues)

const getCustomAutotagObjects = state => ({
  customAutotagObjects: _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_objects']),
  venueSettings: state.appState.venueSettings,
})

const computeCustomAutotagObjects = ({ customAutotagObjects = {}, venueSettings }) => {
  const returnObj = {
    conditionsList: [],
    conditionsMap: {},
    valueObjectsList: [],
    valueObjectsMap: {},
    operatorsList: [],
    operatorsMap: {},
  }
  if (_.isEmpty(customAutotagObjects)) {
    return returnObj
  }
  const customAutotagObjectsFiltered = customAutotagObjects
  const tagsToHide = []
  if (!venueSettings.is_custom_autotags_enhanced_condition_enabled) {
    tagsToHide.push('RESERVATION_COMPLETED_VISIT_ENHANCED')
    tagsToHide.push('RESERVATION_BOOKED_ENHANCED')
  }
  if (tagsToHide) {
    customAutotagObjectsFiltered.conditions = customAutotagObjectsFiltered.conditions.filter(
      obj => !Object.keys(obj).some(key => tagsToHide.includes(key))
    )
  }

  const convertToMap = obj =>
    _.reduce(
      obj,
      (accum, item) => {
        const itemKey = _.keys(item)[0]
        // eslint-disable-next-line no-param-reassign
        accum[itemKey] = item[itemKey]
        return accum
      },
      {}
    )

  returnObj.conditionsList = customAutotagObjectsFiltered.conditions
  returnObj.conditionsMap = convertToMap(customAutotagObjectsFiltered.conditions)
  returnObj.valueObjectsList = customAutotagObjectsFiltered.value_objects
  returnObj.valueObjectsMap = convertToMap(customAutotagObjectsFiltered.value_objects)
  returnObj.operatorsList = customAutotagObjectsFiltered.operators
  returnObj.operatorsMap = convertToMap(customAutotagObjectsFiltered.operators)
  return returnObj
}

export const selectCustomAutotagObjects = createShallowEqualSelector(getCustomAutotagObjects, computeCustomAutotagObjects)

const computeValueObjectOptionsMap = customAutotagObjects =>
  _.mapValues(customAutotagObjects.valueObjectsMap, valueObj =>
    _.reduce(valueObj.options, (accum, obj) => _.concat(accum, [{ key: _.keys(obj)[0], displayText: _.values(obj)[0] }]), [])
  )

export const selectValueObjectOptionsMap = createShallowEqualSelector(selectCustomAutotagObjects, computeValueObjectOptionsMap)

const getClientTags = state => ({
  clientTags: _.get(state, ['autotags', 'data', 'clientTagsList']),
})

const computeClientTagsOptionsList = ({ clientTags = {} }) =>
  _.reduce(
    clientTags,
    (accum, tagGroup) => {
      _.each(tagGroup.tags, tag => {
        const tagKey = [tagGroup.privacy, tagGroup.id, tagGroup.name, tag].join('##')
        accum.push({
          key: tagKey,
          colorHex: tagGroup.color_hex,
          displayText: tagGroup.tag_name_displays[tag] || tag,
          tagGroupID: tagGroup.id,
          tagGroupName: tagGroup.name_display || tagGroup.name || '',
        })
      })
      return accum
    },
    []
  )

export const selectClientTagsOptionsList = createShallowEqualSelector(getClientTags, computeClientTagsOptionsList)

const getReservationTags = state => ({
  reservationTagGroups: _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_options', 'reservation_tag_groups']),
})

const computeReservationTagsOptionsList = ({ reservationTagGroups = {} }) =>
  _.reduce(
    reservationTagGroups,
    (accum, tagGroup) => {
      _.each(tagGroup.tags, tag => {
        const tagKey = [tagGroup.privacy, tagGroup.id, tagGroup.name, tag].join('##')
        accum.push({
          key: tagKey,
          colorHex: tagGroup.color_hex,
          displayText: tagGroup.tag_name_displays[tag] || tag,
          tagGroupID: tagGroup.id,
          tagGroupName: tagGroup.name_display || tagGroup.name || '',
        })
      })
      return accum
    },
    []
  )

export const selectReservationTagsOptionsList = createShallowEqualSelector(getReservationTags, computeReservationTagsOptionsList)

const getValueObjectData = state => ({
  locale: _.get(state, ['appState', 'venue', 'locale']),
  dateToday: _.get(state, ['appState', 'dateToday']),
})

export const computeDefaultValueMap = ({ locale, dateToday }) => {
  const timeMoment = moment().locale(locale)
  timeMoment.set('minute', timeMoment.minute() - (timeMoment.minute() % 15))
  return {
    ANY_DATE: 'NULL',
    IS_ANYTHING: 'NULL',
    CURRENCY: 0,
    DATE_DELTA: 1,
    DATE_RANGE: [moment(), moment()],
    ENUM_GENDER: 'FEMALE',
    NTIMES: 1,
    NUMBER: 0,
    PERCENTAGE: 1,
    STRING: '',
    TIME: [timeMoment.clone(), timeMoment.clone().add(15, 'minutes')],
    BOOLEAN: true,
  }
}

export const selectCustomAutotagDefaultValueMap = createShallowEqualSelector(getValueObjectData, computeDefaultValueMap)

const getAutotagsEditorState = state => state.autotags.editor

const computeCustomAutotagEditState = autotagsEditorState => {
  const retval = { tierConfig: {}, autotagConfig: {} }
  if (!_.isNumber(autotagsEditorState.customTier) || _.isEmpty(autotagsEditorState.tag)) {
    return retval
  }
  retval.tierConfig = autotagsEditorState.tag.tier_configs[autotagsEditorState.customTier]
  retval.autotagConfig = autotagsEditorState.tag
  retval.perksByTagName = autotagsEditorState.perksByTagName
  retval.notesByTagName = autotagsEditorState.notesByTagName
  return retval
}

export const selectCustomAutotagEditState = createShallowEqualSelector(getAutotagsEditorState, computeCustomAutotagEditState)

const getVenueServers = state => _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_options', 'venue_servers'], [])

const computeVenueServerOptions = venueServers => {
  const venueServerOptions = _.reduce(
    venueServers,
    (accum, venueServer) => _.concat(accum, [{ key: venueServer.id, displayText: venueServer.name }]),
    []
  )
  return _.sortBy(venueServerOptions, vs => _.values(vs)[0])
}

export const selectVenueServerOptions = createShallowEqualSelector(getVenueServers, computeVenueServerOptions)

const getSeatingAreas = state => _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_options', 'seating_areas'], [])

const computeSeatingAreaOptions = seatingAreas => {
  const seatingAreaOptions = _.reduce(
    seatingAreas,
    (accum, seatingArea) => _.concat(accum, [{ key: seatingArea.id, displayText: seatingArea.name }]),
    []
  )
  return _.sortBy(seatingAreaOptions, vs => _.values(vs)[0])
}

export const selectSeatingAreaOptions = createShallowEqualSelector(getSeatingAreas, computeSeatingAreaOptions)

const getVenueNames = state => _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_options', 'venues'], [])

const computeVenueNameOptions = venues => {
  const venueNameOptions = _.reduce(venues, (accum, venue) => _.concat(accum, [{ key: venue.venue_id, displayText: venue.venue_name }]), [])
  return _.sortBy(venueNameOptions, vs => _.values(vs)[0])
}

export const selectVenueNameOptions = createShallowEqualSelector(getVenueNames, computeVenueNameOptions)

const getStatuses = state => _.get(state, ['appState', 'venueSettings', 'service_statuses', 'pre-service'], [])

const computeStatusOptions = statuses => {
  const statusOptions = _.reduce(
    statuses,
    (accum, status) => _.concat(accum, [{ key: status.db_name, displayText: _.startCase(status.display) }]),
    []
  )
  return _.sortBy(statusOptions, vs => _.values(vs)[0])
}

export const selectStatusOptions = createShallowEqualSelector(getStatuses, computeStatusOptions)

export const selectBookedByOptions = createShallowEqualSelector(getStatuses, computeVenueNameOptions)

const getBookedByUsers = state => ({
  bookedByUsers: _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_options', 'booked_by_users']),
})

const computeBookedByUsersOptions = ({ bookedByUsers }) =>
  _.reduce(
    bookedByUsers,
    (accum, bookedByUser) => _.concat(accum, [{ key: bookedByUser.id, displayText: _.startCase(bookedByUser.name) }]),
    []
  )

export const selectBookedByUsers = createShallowEqualSelector(getBookedByUsers, computeBookedByUsersOptions)
