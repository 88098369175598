import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ListIcon from '@mui/icons-material/List'
import type { ToggleButtonProps } from '@sevenrooms/react-components'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ToggleButton } from '@sevenrooms/react-components/components/ToggleButton'
import { ToggleButtonGroup } from '@sevenrooms/react-components/components/ToggleButtonGroup'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { AccessDatePicker } from './AccessDatePicker'

interface FilledToggleButtonProps extends ToggleButtonProps {
  'data-test'?: string
}

function FilledToggleButton({ value, children, ...props }: FilledToggleButtonProps) {
  return (
    <ToggleButton
      value={value}
      data-test={props['data-test']}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
        '&.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            cursor: 'default',
          },
        },
        '&.MuiToggleButtonGroup-lastButton': {
          m: 0,
        },
      }}
    >
      {children}
    </ToggleButton>
  )
}

export function ActionsBar() {
  const [{ view, range }, setParams] = useAccessRulesUrlParams()

  const handleViewToggle = (event: React.MouseEvent<HTMLElement>, newView: AccessRuleView) => {
    if (newView !== null) {
      setParams({ view: newView })
    }
  }

  const handleRangeToggle = (event: React.MouseEvent<HTMLElement>, newRange: AccessRuleRange) => {
    if (newRange !== null) {
      setParams({ range: newRange })
    }
  }

  return (
    <Stack
      id="access-rules-actions-bar"
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" spacing={8}>
        <AccessDatePicker />
        <ToggleButtonGroup
          value={range}
          id="day-week-switch"
          data-test="day-week-switch"
          aria-label="Day/Week Switch"
          exclusive
          color="primary"
          onChange={handleRangeToggle}
        >
          <FilledToggleButton value={AccessRuleRange.OVERVIEW} data-test="btn-overview">
            Overview
          </FilledToggleButton>
          <FilledToggleButton value={AccessRuleRange.DAY} data-test="btn-day">
            Day
          </FilledToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <ToggleButtonGroup
        value={view}
        id="cal-list-switch"
        data-test="cal-list-switch"
        aria-label="Calendar/List Switch"
        exclusive
        color="primary"
        onChange={handleViewToggle}
      >
        <FilledToggleButton value={AccessRuleView.CALENDAR} data-test="btn-cal">
          <CalendarMonthIcon />
        </FilledToggleButton>
        <FilledToggleButton value={AccessRuleView.LIST} data-test="btn-list">
          <ListIcon />
        </FilledToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
