import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelectTag, type TagOption, Label } from '@sevenrooms/core/ui-kit/form'
import { useAccessRuleContext } from '../../shared'
import { ReservationTagsLocales } from '../ReservationTags.locales'
import { ReservationTagsTestId } from '../ReservationTags.testIds'
import { generateTagHash } from '../ReservationTags.zod'
import type { ReservationTagsEditProps } from './ReservationTagsEditProps'
import type { TagGroup } from '../../../AccessRule.types'

export function ReservationTagsEdit({ field, categories }: ReservationTagsEditProps) {
  const { accessRule, tagGroups } = useAccessRuleContext()
  const { formatMessage } = useLocales()
  const formattedTagOptions = useMemo(() => getFormattedTagOptions(tagGroups), [tagGroups])

  const initiallySelected = useMemo(() => {
    const selectedTags = accessRule?.reservationTags ?? []
    return formattedTagOptions.filter(({ id }) => selectedTags.includes(id))
  }, [formattedTagOptions, accessRule?.reservationTags])

  return (
    <>
      <Label primary={formatMessage(ReservationTagsLocales.title)} />
      <MultiSelectTag
        data-test={ReservationTagsTestId.reservationTagsMultiSelect}
        field={field}
        categories={categories}
        options={formattedTagOptions}
        searchable
        placeholder={formatMessage(ReservationTagsLocales.searchPlaceholder)}
        initialSelectedItems={initiallySelected}
      />
    </>
  )
}

function getFormattedTagOptions(tagGroups: Map<string, TagGroup>): TagOption[] {
  const options: TagOption[] = []
  tagGroups.forEach((tagGroup, categoryId) =>
    tagGroup.tags.forEach(tag =>
      options.push({
        id: generateTagHash(tagGroup.privacy, categoryId, tagGroup.name, tag),
        label: tagGroup.tagNameDisplays[tag] ?? tag,
        categoryId,
      })
    )
  )
  return options
}
