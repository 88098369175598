import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'
import type { PerkSelectAccessRuleModalFormField } from './PerkSelectAccessRuleModalForm.zod'

interface PerkSelectAccessRuleModalAccessRulesProps {
  field: PerkSelectAccessRuleModalFormField
  accessRulesOptions: SelectOption[]
}

export function PerkSelectAccessRuleModalAccessRules({ field, accessRulesOptions }: PerkSelectAccessRuleModalAccessRulesProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="s" mt="s" height="320px">
      <FormMultiSelect
        data-test="perk-select-access-rule-modal-booking"
        searchable
        useCheckboxItems
        options={accessRulesOptions}
        field={field.prop('accessRuleToCloneIds')}
        placeholder={formatMessage(perksMessages.accessRuleModalSelectAccessRule)}
      />
    </VStack>
  )
}
