import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, ReportSection, type ReportSectionProps } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text, Header } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../AccessRules.locales'
import { AccessRuleTooltip } from './AccessRuleTooltip'
import { SharedTestId } from './shared.testIds'
import type { PropsWithChildren } from 'react'

export function DefaultSettingsReportSection(props: PropsWithChildren<Partial<ReportSectionProps>>) {
  const { formatMessage } = useLocales()

  const title = (
    <HStack spacing="xs">
      <Box>
        <Header type="h4">{formatMessage(accessRulesMessages.defaultSettingsReportTitle)}</Header>
      </Box>

      <AccessRuleTooltip data-test={SharedTestId.defaultSettingsIcon}>
        <Text color="lightFont" fontWeight="bold">
          {formatMessage(accessRulesMessages.defaultSettingsReportTitle)}
        </Text>
        <Text color="lightFont">{formatMessage(accessRulesMessages.defaultSettingsTooltip)}</Text>
        <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/7091422400155-Access-Rule-Default-Settings">
          {formatMessage(accessRulesMessages.defaultSettingsLink)}
        </Anchor>
      </AccessRuleTooltip>
    </HStack>
  )
  return (
    <Box maxHeight="480px" overflow="auto">
      <ReportSection title={title} {...props} />
    </Box>
  )
}
