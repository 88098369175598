import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { CollapsedPacing } from './components/CollapsedPacing'
import { DefaultPacingSettings } from './components/DefaultPacingSettings'
import { PacingEdit } from './components/PacingEdit'
import { PacingLocales } from './Pacing.locales'
import { PacingTestId } from './Pacing.testIds'
import type { PacingProps } from './components/PacingProps'

export function Pacing({ field }: PacingProps) {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      data-test={PacingTestId.section}
      title={formatMessage(PacingLocales.title)}
      description={formatMessage(PacingLocales.description)}
      subCaption={formatMessage(PacingLocales.subCaption)}
      contentWhenCollapsed={<CollapsedPacing field={field} />}
      defaultSettings={<DefaultPacingSettings />}
      sectionControllerName="pacing"
      field={field}
    >
      <PacingEdit field={field} />
    </AccessRuleSlideoutSection>
  )
}
