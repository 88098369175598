import React from 'react'
import { Flex, Image } from '@sevenrooms/core/ui-kit/layout'

export function POSICircleLogo({ logo, title }: { logo: string; title: string }) {
  return (
    <Flex
      borderRadius="l"
      boxShadow="primary"
      width="72px"
      height="72px"
      borderColor="iconHover"
      borderWidth="s"
      textAlign="center"
      justifyContent="center"
      display="inherit"
    >
      <Image src={logo} alt={title} width="80%" objectFit="contain" />
    </Flex>
  )
}
