import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { Jobs } from '../Jobs'
import { jobsDashboardMessages } from '../locales'

interface jobsDashboardProps {
  venueId: string
}

export function JobsDashboard({ venueId }: jobsDashboardProps) {
  const { formatMessage } = useLocales()
  return (
    <SettingsPage>
      <SettingsPageMeta title={formatMessage(jobsDashboardMessages.jobsDashboardTitle)} />
      <SettingsPageContent
        title={formatMessage(jobsDashboardMessages.jobsDashboardTitle)}
        description={formatMessage(jobsDashboardMessages.jobsDashboardDescription)}
        secondHeaderMaxWidth="100%"
      >
        <Box p="lm" width="100%">
          <Jobs venueId={venueId} />
        </Box>
      </SettingsPageContent>
    </SettingsPage>
  )
}
