import { useCallback, useMemo, useState } from 'react'
import type { VenueProfile, VenueUserProfile } from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Grid } from '@sevenrooms/core/ui-kit/layout'
import { venueProfileMessages } from '../../locales'
import type { GuestFacingContactFormFields } from './GuestFacingContactForm.zod'

export interface GuestFacingContactFormProps {
  fields: GuestFacingContactFormFields
  venueUsers: VenueUserProfile[]
  venueProfile: VenueProfile
}

const CUSTOM = 'Custom'

export function GuestFacingContactForm({ fields, venueUsers, venueProfile }: GuestFacingContactFormProps) {
  const { formatMessage } = useLocales()
  const { setValue } = useFormContext()

  const [selecteduser, setSelectedUser] = useState(() => {
    const initialUser = venueUsers.find(user => user.fullName === venueProfile.marketingSenderName)

    return initialUser?.fullName ?? CUSTOM
  })

  const usersSelectOptions: SelectOption[] = useMemo(() => {
    const customOption: SelectOption = {
      label: formatMessage(venueProfileMessages.custom),
      id: CUSTOM,
    }
    return [customOption, ...venueUsers.map(user => ({ id: user.fullName, label: user.fullName }))]
  }, [venueUsers, formatMessage])

  const handleSelectChange = useCallback(
    (value: string) => {
      setSelectedUser(value)

      const customInitialUser: VenueUserProfile = {
        fullName: venueProfile.marketingSenderName,
        email: venueProfile.marketingEmail,
        role: venueProfile.marketingSenderRole,
      }

      const selected = venueUsers.find(user => user.fullName === value) ?? customInitialUser

      setValue('marketingSenderName', selected.fullName)
      setValue('marketingSenderRole', selected.role)
      setValue('marketingEmail', selected.email)
    },
    [venueProfile, setValue, venueUsers]
  )

  return (
    <>
      <Grid gridTemplateColumns="1fr 1fr" mb="m" pr="lm">
        <Label primary={formatMessage(venueProfileMessages.sendFromUserLabel)}>
          <Select options={usersSelectOptions} value={selecteduser} onChange={handleSelectChange} searchable={false} />
        </Label>
      </Grid>
      <Grid gridTemplateColumns="1fr 1fr" columnGap="lm" rowGap="m">
        <Label primary={formatMessage(venueProfileMessages.senderNameLabel)}>
          <FormInput field={fields.prop('marketingSenderName')} />
        </Label>
        <Label primary={formatMessage(venueProfileMessages.senderRoleLabel)}>
          <FormInput field={fields.prop('marketingSenderRole')} />
        </Label>
        <Label primary={formatMessage(venueProfileMessages.emailAddressLabel)}>
          <FormInput field={fields.prop('marketingEmail')} />
        </Label>
      </Grid>
    </>
  )
}
