import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'

export const AutotagPermissions = ({ permission, children }) => {
  const permissions = useNavigationPermissionsContext()

  if (permissions[permission]) {
    return children
  }

  return null
}
