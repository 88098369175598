import { useMemo } from 'react'
import snakecaseKeys from 'snakecase-keys'
import type { CreateEditExperiencePDRApi, MinimumSpendMatrixApi } from '@sevenrooms/core/api'
import type { PDRAmenitiesEnumOption, PDRRoomTypeEnumOption, PDRTimeSlots } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export const usePDRDetailsForm = () => {
  const MinimumSpendMatrixForm = useMinimumSpendMatrixForm()
  return useMemo(
    () =>
      z
        .object({
          pdrMaxSeatedCapacity: z.number().nullable().default(null),
          pdrMinSeatedCapacity: z.number().nullable().default(null),
          pdrMaxStandingCapacity: z.number().nullable().default(null),
          pdrMinStandingCapacity: z.number().nullable().default(null),
          pdrAmenityList: z.custom<PDRAmenitiesEnumOption[]>(),
          pdrRoomType: z.custom<PDRRoomTypeEnumOption>().nullable().default(null),
          pdrTables: z.string().nullable().default(null),
          pdrSquareFootage: z.number().nullable().default(null),
          pdrUnitOfMeasurement: z.string().nullable().default(null),
          pdrMinSpend: z.number().nullable().default(null),
          pdrAdminFee: z.number().nullable().default(null),
          pdrTaxRateId: z.string().nullable().default(null),
          pdrMinimumSpendMatrix: z.array(MinimumSpendMatrixForm),
          pdrTripleSeatRoomId: z.string().nullable().default(null),
          pdrFees: z.array(z.string()).default([]),
          pdrDepositFee: z.number().nullable().default(null),
        })
        .nullable(),
    [MinimumSpendMatrixForm]
  )
}

export type PDRDetailsFormData = ZodSchema<typeof usePDRDetailsForm>

export const useMinimumSpendMatrixForm = () =>
  useMemo(
    () =>
      z.object({
        startDay: z.string().nullable().default(null),
        endDay: z.string().nullable().default(null),
        startTime: z.custom<PDRTimeSlots>().nullable().default(null),
        endTime: z.custom<PDRTimeSlots>().nullable().default(null),
        minimumSpend: z.number().nullable().default(null),
      }),
    []
  )

export type MinimumSpendMatrixFormData = ZodSchema<typeof useMinimumSpendMatrixForm>

export const formToPDRDetails = (pdrDetails: PDRDetailsFormData): CreateEditExperiencePDRApi => ({
  pdr_standing_capacity: pdrDetails?.pdrMaxStandingCapacity || null,
  pdr_standing_min_capacity: pdrDetails?.pdrMinStandingCapacity || null,
  pdr_room_type: pdrDetails?.pdrRoomType || null,
  pdr_amenity_list: pdrDetails?.pdrAmenityList || null,
  pdr_square_footage: pdrDetails?.pdrSquareFootage || null,
  pdr_tables: pdrDetails?.pdrTables || null,
  pdr_max_seated_capacity: pdrDetails?.pdrMaxSeatedCapacity || null,
  pdr_min_seated_capacity: pdrDetails?.pdrMinSeatedCapacity || null,
  pdr_unit_of_measurement: pdrDetails?.pdrUnitOfMeasurement || null,
  pdr_min_spend: pdrDetails?.pdrMinSpend || null,
  pdr_minimum_spend_matrix: (snakecaseKeys(pdrDetails?.pdrMinimumSpendMatrix || [], { deep: true }) as MinimumSpendMatrixApi[]) || [],
  pdr_admin_fee: pdrDetails?.pdrAdminFee || null,
  pdr_tax_rate_id: pdrDetails?.pdrTaxRateId || null,
  pdr_triple_seat_room_id: pdrDetails?.pdrTripleSeatRoomId || null,
  pdr_fees: pdrDetails?.pdrFees || null,
  pdr_deposit_fee: pdrDetails?.pdrDepositFee || null,
})
