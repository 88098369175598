import { useLocales } from '@sevenrooms/core/locales'
import { DateRangePicker } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'
import { PerkAvailabilityForm } from '../PerkAvailabilityForm'
import { PerkDayOfWeek } from '../PerkDayOfWeek'
import { PerkRestrictionTypeForm } from '../PerkRestrictionTypeForm'
import { PerkTimeRangesForm } from '../PerkTimeRanges'
import type { PerkAvailabilityRestrictionsFormField } from './PerkAvailabilityRestrictionsForm.zod'

interface PerkAvailabilityRestrictionsFormProps {
  disabled: boolean
  field: PerkAvailabilityRestrictionsFormField
}

export function PerkAvailabilityRestrictionsForm({ disabled, field }: PerkAvailabilityRestrictionsFormProps) {
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(perksMessages.perkAvailability)}>
      <Box p="lm" width="100%">
        <VStack spacing="m">
          <PerkAvailabilityForm disabled={disabled} perkAvailabilityTypeField={field.prop('perkAvailabilityType')} />
          <PerkRestrictionTypeForm
            perkAvailabilityTypeField={field.prop('perkAvailabilityType')}
            dateRange={<DateRangePicker disabled={disabled} field={field.prop('dateRange')} infinite />}
            dayOfWeek={<PerkDayOfWeek disabled={disabled} dowOfferedField={field.prop('dowOffered')} />}
            timeRanges={
              <PerkTimeRangesForm
                dowOfferedField={field.prop('dowOffered')}
                disabled={false}
                perkTimeRangeFields={field.prop('timeRanges')}
              />
            }
            isRestrictedByDateRangeField={field.prop('isRestrictedByDateRange')}
            isRestrictedByWeekOfDayAndTimeRangeField={field.prop('isRestrictedByWeekOfDayAndTimeRange')}
            disabled={disabled}
          />
        </VStack>
      </Box>
    </BaseSection>
  )
}
