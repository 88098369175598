import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  // Page
  tabTitle: {
    id: 'posiSettings.SelectPage.tabTitle',
    defaultMessage: 'Point of Sale',
  },
  title: {
    id: 'posiSettings.SelectPage.title',
    defaultMessage: 'Connect Your Point of Sale',
  },
  description: {
    id: 'posiSettings.SelectPage.description',
    defaultMessage:
      'See guest spend details directly in SevenRooms. Provide exceptional service informed by data. <a>More available integrations.</a>',
  },

  // Authentication
  userDeniedAuthenticationError: {
    id: 'posiSettings.SelectPage.userDeniedAuthenticationError',
    defaultMessage: 'User denied access to {posName} integration.',
  },
  defaultAuthenticationError: {
    id: 'posiSettings.SelectPage.defaultAuthenticationError',
    defaultMessage: 'Failed to authenticate with {posName} integration.',
  },

  // Info Banner
  infoBannerTitle: {
    id: 'posiSettings.SelectPage.infoBannerTitle',
    defaultMessage: 'Don’t see your Point of Sale system?',
  },
  infoBannerDescription: {
    id: 'posiSettings.SelectPage.infoBannerDescription',
    defaultMessage:
      'SevenRooms has dozens of integrations available with many of the top POS systems across the globe. See our full list and contact Support to get started.',
  },
  infoBannerButton: {
    id: 'posiSettings.SelectPage.infoBannerButton',
    defaultMessage: 'Explore More Integrations',
  },
} as const)
