import { Workbook, type WorkbookInstance } from '@fortune-sheet/react'
import React from 'react'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { Sheet } from '@fortune-sheet/core/dist/types'
import '@fortune-sheet/react/dist/index.css'

interface ArSpreadsheetContainerProps {
  data: Sheet[]
  spreadsheetRef: React.Ref<WorkbookInstance>
}

function ArSpreadsheetContainer({ spreadsheetRef, data }: ArSpreadsheetContainerProps) {
  return (
    <Box height="80vh" width="100%" data-test="ar-spreadsheet">
      <Workbook ref={spreadsheetRef} data={data} showToolbar={false} showFormulaBar={false} showSheetTabs={false} cellContextMenu={[]} />
    </Box>
  )
}

export { ArSpreadsheetContainer }
