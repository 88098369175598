// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  onChange: (value: string, field: string) => mixed,
  value: string,
  field: string,
  inputId?: string,
  currencySymbol?: string,
  placeholder?: string,
  dataTest?: string,
  charLimit?: number,
  label?: string,
  isValid?: boolean,
  validator?: string => boolean,
  customInput: Array<string | (() => mixed)>,
  customInputLabel: Array<string | (() => mixed)>,
  customInputContainer: Array<string | (() => mixed)>,
  customCurrencySymbol: Array<string | (() => mixed)>,
  customInputWrapper: Array<string | (() => mixed)>,
  disabled: boolean,
} */

class CurrencyInput extends React.Component /* <Props> */ {
  static defaultProps = {
    placeholder: '',
    isValid: true,
    currencySymbol: '$',
    customInput: [],
    customInputLabel: [],
    customInputContainer: [],
    customCurrencySymbol: [],
    customInputWrapper: [],
    disabled: false,
  }

  inputId = this.props.inputId || `sr-currencyinput-${this.props.field}`

  currencyRegex = /\d*[.,]\d{0,2}|\d+/

  handleInputChange = (event /* : SyntheticEvent<HTMLInputElement> */) => {
    const inputVal = this.limitInput(event.currentTarget.value)
    if (inputVal !== this.props.value) {
      this.props.onChange(inputVal, event.currentTarget.name)
    }
  }

  limitInput = (inputVal /* : string */) => {
    if (inputVal === '') {
      return ''
    }
    const match = inputVal.match(this.currencyRegex)
    let newVal = match ? match[0] : ''
    if (this.props.charLimit) {
      newVal = newVal.substring(0, this.props.charLimit)
    }
    return newVal
  }

  validate() {
    if (!this.props.validator) {
      throw new TypeError('React component is missing props: [validator]')
    }
    return this.props.validator(this.props.value)
  }

  render() {
    return (
      <InputWrapper customInputWrapper={this.props.customInputWrapper} data-test={this.props.dataTest}>
        {this.props.label && (
          <InputLabel isValid={this.props.isValid} htmlFor={this.inputId} customInputLabel={this.props.customInputLabel}>
            {this.props.label}
          </InputLabel>
        )}
        <InputContainer>
          <CurrencySymbol className="currency-symbol" customCurrencySymbol={this.props.customCurrencySymbol}>
            {this.props.currencySymbol}
          </CurrencySymbol>
          <Input
            id={this.inputId}
            value={this.props.value}
            name={this.props.field}
            onChange={this.handleInputChange}
            placeholder={this.props.placeholder}
            isValid={this.props.isValid}
            customInput={this.props.customInput}
            disabled={this.props.disabled}
          />
        </InputContainer>
      </InputWrapper>
    )
  }
}

/* colors
   grey: rgba(236, 236, 236, 1.00),
   black: rgba(40, 44, 52, 1.00),
*/

const InputWrapper = styled.div`
  width: 150px;
  box-sizing: border-box;
  ${props => props.customInputWrapper};
`

const InputLabel = styled.label`
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  box-sizing: inherit;
  color: #878787;
  ${props => props.customInputLabel} ${props => !props.isValid && 'color: red;'};
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: inherit;
  ${props => props.customInputContainer};
`

const CurrencySymbol = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  box-sizing: inherit;
  ${props => props.customCurrencySymbol};
`

const Input = styled.input`
  height: 36px;
  width: 100%;
  padding: 0 4px 0 26px;
  border-radius: 5px;
  border-width: 2px;
  font-size: 18px;
  border-style: solid;
  border-color: rgba(236, 236, 236, 1);
  background-color: rgba(255, 255, 255, 1);
  color: #666666;
  text-align: right;
  box-sizing: inherit;
  ${props => props.customInput} ${props => !props.isValid && 'border-color: red;'};
`

export default CurrencyInput
