import {
  PDRAmenitiesEnum,
  type PDRAmenitiesEnumOption,
  PDRRoomTypeEnum,
  type PDRRoomTypeEnumOption,
  PDRUnitOfMeasurementEnum,
  type PDRUnitOfMeasurementEnumOption,
  PDRDeadlineTimeUnitEnum,
  type PDRDeadlineTimeUnitEnumOption,
  PDRDeadlineTimeFromEnum,
  type PDRDeadlineTimeFromEnumOption,
} from '@sevenrooms/core/domain'

export const roomTypeOptions = Object.entries(PDRRoomTypeEnum).map(([roomId, label]) => ({
  id: roomId as PDRRoomTypeEnumOption,
  label,
}))

export const unitsOfMeasurementOptions = Object.entries(PDRUnitOfMeasurementEnum).map(([unitId, label]) => ({
  id: unitId as PDRUnitOfMeasurementEnumOption,
  label,
}))

export const amenitiesOptions = Object.entries(PDRAmenitiesEnum)
  .sort(([a], [b]) => a.localeCompare(b))
  .map(([amenitiesId, label]) => ({
    id: amenitiesId as PDRAmenitiesEnumOption,
    label,
  }))

export const pdrDeadlineTimeUnitOptions = Object.entries(PDRDeadlineTimeUnitEnum).map(([optionValue, label]) => ({
  id: optionValue as PDRDeadlineTimeUnitEnumOption,
  label,
}))

export const pdrDeadlineTimeFromOptions = Object.entries(PDRDeadlineTimeFromEnum).map(([optionValue, label]) => ({
  id: optionValue as PDRDeadlineTimeFromEnumOption,
  label,
}))

enum DepositCalcFrom {
  FB_MIN_SPEND = 'F&B Min Spend',
  ESTIMATED_TOTAL = 'Estimated Total Spend',
}

export type DepositCalcFromOption = keyof typeof DepositCalcFrom

export const depositCalcFromOptions = Object.entries(DepositCalcFrom).map(([optionValue, label]) => ({
  id: optionValue as DepositCalcFromOption,
  label,
}))
