import { AccessRuleTimeUnitEnum } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { checkTimeBeforeWarning, TimeBefore } from '../../shared'
import { CutoffTimeWarningTooltip } from '../../shared/CutoffTimeWarningTooltip'
import { BookingWindowLocales } from '../BookingWindow.locales'
import { BookingWindowTestId } from '../BookingWindow.testIds'
import type { BookingChannelsForm } from '../../BookingChannels/BookingChannels.zod'
import type { BookingWindowForm } from '../BookingWindow.zod'

export interface BookingWindowFieldsProps {
  field: Field<BookingWindowForm>
  bookingChannelsField: Field<BookingChannelsForm>
}

export function BookingWindowFields({ field, bookingChannelsField }: BookingWindowFieldsProps) {
  const { formatMessage } = useLocales()

  const startTimeUnits = Object.values(AccessRuleTimeUnitEnum).filter(item => item !== AccessRuleTimeUnitEnum.MINUTES)
  const bookingChannelsInUse = bookingChannelsField.size() > 1

  const startTime = useWatch(field.prop('startTime'))
  const cutoffTime = useWatch(field.prop('cutoffTime'))
  const bookingChannels = useWatch(bookingChannelsField)

  const hasBookingWindowWarning = bookingChannels.length === 1 && checkTimeBeforeWarning(startTime, cutoffTime)
  const hasBookingChannelsWarning =
    bookingChannels.length > 1 && bookingChannels.some(bookingChannel => checkTimeBeforeWarning(bookingChannel.startTime, cutoffTime))

  return (
    <VStack spacing="m">
      <VStack spacing="s">
        <Label
          primary={formatMessage(BookingWindowLocales.startTimeLabel)}
          secondary={formatMessage(BookingWindowLocales.startTimeSubLabel)}
        >
          <TimeBefore
            field={field.prop('startTime')}
            units={startTimeUnits}
            disabled={bookingChannelsInUse}
            data-test={BookingWindowTestId.startTime}
          />
        </Label>
        {bookingChannelsInUse && (
          <Text color="secondaryFont" fontStyle="italic" data-test={BookingWindowTestId.startTimeDisabledLabel}>
            {formatMessage(BookingWindowLocales.disabledLabel)}
          </Text>
        )}
      </VStack>
      <Label
        primary={formatMessage(BookingWindowLocales.cutoffTimeLabel)}
        secondary={formatMessage(BookingWindowLocales.cutoffTimeSubLabel)}
      >
        <TimeBefore
          field={field.prop('cutoffTime')}
          data-test={BookingWindowTestId.cutoffTime}
          extra={
            (hasBookingWindowWarning || hasBookingChannelsWarning) && (
              <CutoffTimeWarningTooltip
                hasBookingWindowWarning={hasBookingWindowWarning}
                hasBookingChannelsWarning={hasBookingChannelsWarning}
              />
            )
          }
        />
      </Label>
    </VStack>
  )
}
