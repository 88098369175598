import { useMemo } from 'react'
import type { LightspeedKConfig, POSILocation } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig,
  getAutomaticPrepaymentRedemptionCardFormDefaultValues,
  useAutomaticPrepaymentRedemptionCardForm,
} from '../../../AutomaticPrepaymentRedemptionCard/AutomaticPrepaymentRedemptionCardForm.zod'
import {
  getCourseStatusMappingCardFormDefaultValues,
  convertCourseStatusMappingCardFormDataToPOSIConfig,
  useCourseStatusMappingCardForm,
} from '../../../CourseStatusMappingCard/CourseStatusMappingCardForm.zod'
import {
  getLocationSelectionCardFormDefaultValues,
  convertLocationSelectionCardFormDataToPOSIConfig,
  useLocationSelectionCardForm,
} from '../../../LocationSelectionCard/LocationSelectionCardForm.zod'

export type LightspeedKEditPageFormData = ZodSchema<typeof useLightspeedKEditPageForm>
export type LightspeedKEditPageFormField = Field<LightspeedKEditPageFormData>

export function useLightspeedKEditPageForm() {
  const locationCard = useLocationSelectionCardForm()
  const automaticPrepaymentRedemptionCard = useAutomaticPrepaymentRedemptionCardForm()
  const courseStatusMappingCard = useCourseStatusMappingCardForm()

  return useMemo(
    () =>
      z.object({
        locationCard,
        automaticPrepaymentRedemptionCard,
        courseStatusMappingCard,
      }),
    [automaticPrepaymentRedemptionCard, courseStatusMappingCard, locationCard]
  )
}

export const getLightspeedKEditPageFormDefaultValues = (config: LightspeedKConfig): LightspeedKEditPageFormData => ({
  locationCard: getLocationSelectionCardFormDefaultValues(config.location?.id),
  automaticPrepaymentRedemptionCard: getAutomaticPrepaymentRedemptionCardFormDefaultValues({
    isAutomaticPrepaymentRedemptionEnabled: config.depositsEnabled,
  }),
  courseStatusMappingCard: getCourseStatusMappingCardFormDefaultValues(config),
})

export const convertLightspeedKEditPageFormDataToLightspeedKConfig = ({
  config,
  locations,
  data,
}: {
  config: LightspeedKConfig
  locations: POSILocation[]
  data: LightspeedKEditPageFormData
}): LightspeedKConfig => ({
  lastSaved: config.lastSaved,
  checkCreationEnabled: false,
  ...convertLocationSelectionCardFormDataToPOSIConfig({ locations, data: data.locationCard }),
  ...convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig({
    isAutomaticPrepaymentRedemptionEnabled: data.automaticPrepaymentRedemptionCard.isAutomaticPrepaymentRedemptionEnabled,
  }),
  ...convertCourseStatusMappingCardFormDataToPOSIConfig(data.courseStatusMappingCard),
})
