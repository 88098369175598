import { useMemo } from 'react'
import type { AccessRule, AccessRuleInventoryType } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReservationCoverLimitLocales } from './ReservationCoverLimit.locales'

export type ReservationCoverLimitForm = ZodSchema<typeof useReservationCoverLimitForm>

export function useReservationCoverLimitForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z
        .object({
          count: z.number().nullable(),
          type: z.custom<AccessRuleInventoryType>(),
          guaranteeBookings: z.boolean(),
        })
        .superRefine(({ count, type, guaranteeBookings }, ctx) => {
          if (type === 'UNLIMITED' && count != null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['type'],
            })
          }
          if (type !== 'UNLIMITED' && count == null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(ReservationCoverLimitLocales.limitRequired),
              path: ['count'],
            })
          }
          if (guaranteeBookings && (type === 'UNLIMITED' || count == null)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['type', 'count'],
            })
          }
        }),
    [formatMessage]
  )
}

export function getInitialReservationCoverLimit(accessRule?: AccessRule): ReservationCoverLimitForm {
  return {
    count: accessRule?.inventoryCount ?? null,
    type: accessRule?.inventoryType ?? 'UNLIMITED',
    guaranteeBookings: accessRule?.guaranteeBookings ?? false,
  }
}
