import { skipToken } from '@reduxjs/toolkit/query'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TransitionBlockerWithOldRouterSupport } from 'mgr/lib/components/TransitionBlockerWithOldRouterSupport'
import { useMemo, useState } from 'react'
import { type LanguageListData, type LanguageCode, useGetTestConfigurationQuery, useGetVenueLanguagesQuery } from '@sevenrooms/core/api'
import type { ReservationWidgetSettingsV2 } from '@sevenrooms/core/domain'
import { type Dirtied, useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { generatePath, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Banner, BaseSection, Box, HStack, UnsavedChangesModal, notify, Tab, TabList, TabPanel, Tabs } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, useVenueContext } from '@sevenrooms/mgr-core'
import { AccountTypes } from '@sevenrooms/payments'
import { routes } from '@sevenrooms/routes'
import { CheckoutConfirmation } from './CheckoutConfirmation/CheckoutConfirmation'
import { General } from './General/General'
import { type ReservationSettingsForm, useReservationSettingsForm } from './ReservationSettings.zod'
import { reservationWidgetSettingsMessages } from './ReservationWidgetSettings.locales'
import { SearchAvailability } from './SearchAvailability/SeachAvailability'

const LEFT_MARGIN_OFFSET = 'calc(100% - 274px)'

export interface MainContentProps {
  data: ReservationWidgetSettingsV2
  onSave: (formData: ReservationSettingsForm, dirtyFields: Dirtied<ReservationSettingsForm>) => void
  isSaving: boolean
}

export function MainContent({ data, onSave, isSaving }: MainContentProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { data: languageListData } = useGetVenueLanguagesQuery({
    venueId: venue.id,
  })
  const { defaultLanguage, enabledLanguages } = languageListData as LanguageListData

  const languageOptions = useMemo(
    () =>
      enabledLanguages.map(language => ({
        id: language.value as LanguageCode,
        label: language.name,
      })),
    [enabledLanguages]
  )

  const tabs: { key: string; title: string }[] = [
    {
      key: 'general',
      title: formatMessage(reservationWidgetSettingsMessages.tabGeneral),
    },
    {
      key: 'searchAvailability',
      title: formatMessage(reservationWidgetSettingsMessages.tabSearchAvailability),
    },
    {
      key: 'checkoutConfirmation',
      title: formatMessage(reservationWidgetSettingsMessages.tabCheckoutConfirmation),
    },
  ]
  const nav = useNavigation()
  const { query } = useDestination(routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2)
  const activeTabIndex = tabs.findIndex(tab => tab.key === query.activeTab)
  const [selectedIndex, setSelectedIndex] = useState(activeTabIndex !== -1 ? activeTabIndex : 0)
  const [hasCopied, setHasCopied] = useState(false)

  const newResWidgetPath = generatePath(routes.explore.reservations.create.search.path, { venueKey: venue.urlKey })
  const newResWidgetUrl = `https://${window.location.host}${newResWidgetPath}`
  const schema = useReservationSettingsForm(defaultLanguage)

  const {
    reset,
    field,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm(schema, { defaultValues: data })

  const onSubmit = handleSubmit(
    formData => {
      onSave(formData, dirtyFields)
      reset({}, { keepValues: true, keepDirty: false })
    },
    errors => {
      notify({ content: formatMessage(reservationWidgetSettingsMessages.error), type: 'error' })
      // eslint-disable-next-line no-console
      console.error('errors', errors)
    }
  )

  const onTabChange = (index: number) => {
    setSelectedIndex(index)
    const tab = tabs[index]
    if (tab && venue) {
      nav.push(routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2, {
        params: { venueKey: venue.urlKey },
        query: { activeTab: tab.key },
      })
    }
  }

  const { data: isPaymentConfigured = false, isFetching: isGetTestConfigurationFetching } = useGetTestConfigurationQuery(
    venue.paymentType !== AccountTypes.PLACEHOLDER ? { venueId: venue.id } : skipToken
  )
  const paymentIntegrationPath = generatePath(routes.manager2.settings.paymentIntegration.view.path, { venueKey: venue.urlKey })
  const banner = (
    <Banner
      canDismiss={false}
      type="attention"
      title={formatMessage(reservationWidgetSettingsMessages.paymentBannerTitle)}
      description={formatMessage(reservationWidgetSettingsMessages.paymentBannerDescription)}
      icon={<Icon name="VMSWeb-creditcard" size="2x" />}
      action="Connect"
      onAction={() => window.open(paymentIntegrationPath, '_blank')}
    />
  )
  const bannerContent = !isPaymentConfigured && !isGetTestConfigurationFetching ? banner : null
  const containsDirtyChanges = !!Object.keys(dirtyFields).length

  return (
    <SettingsPageContent
      title={formatMessage(reservationWidgetSettingsMessages.title)}
      headerWidth={LEFT_MARGIN_OFFSET}
      actions={
        <HStack spacing="s">
          <Button
            variant="tertiary"
            onClick={() => {
              navigator.clipboard.writeText(newResWidgetUrl)
              setHasCopied(true)
              setTimeout(() => {
                setHasCopied(false)
              }, 4000)
            }}
            data-test="button-copy-widget-link"
          >
            {hasCopied
              ? formatMessage(reservationWidgetSettingsMessages.linkCopied)
              : formatMessage(reservationWidgetSettingsMessages.copyWidgetLink)}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              window.open(newResWidgetUrl)
            }}
            data-test="button-view-widget"
          >
            {formatMessage(reservationWidgetSettingsMessages.viewLiveWidget)}
          </Button>
          <Box width="142px">
            <Button
              fullWidth
              variant="primary"
              disabled={!containsDirtyChanges || isSaving}
              onClick={onSubmit}
              data-test="button-save-changes"
            >
              {isSaving
                ? formatMessage(reservationWidgetSettingsMessages.savingActionLabel)
                : formatMessage(reservationWidgetSettingsMessages.saveAndPublish)}
            </Button>
          </Box>
        </HStack>
      }
      tab={tabs[selectedIndex]?.title}
    >
      <Box pl="lm" pb="lm" pr="lm" width="100%">
        <Tabs onSelect={onTabChange} selectedIndex={selectedIndex}>
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.key} data-test={`tab-${tab.key}`}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <Box pt="m">
            <BaseSection>{bannerContent}</BaseSection>
          </Box>
          <TabPanel>
            <General field={field.prop('general')} data={data} languageOptions={languageOptions} defaultLanguage={defaultLanguage} />
          </TabPanel>
          <TabPanel>
            <SearchAvailability field={field.prop('searchAvailability')} data={data} />
          </TabPanel>
          <TabPanel>
            <CheckoutConfirmation
              field={field.prop('checkoutConfirmation')}
              languageStringsField={field.prop('general').prop('languageStrings')}
              data={data}
              languageOptions={languageOptions}
              defaultLanguage={defaultLanguage}
            />
          </TabPanel>
        </Tabs>
      </Box>
      <TransitionBlockerWithOldRouterSupport modal={<UnsavedChangesModal />} skipQuery isBlocked={containsDirtyChanges} />
    </SettingsPageContent>
  )
}
