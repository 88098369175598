import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  pageTitle: {
    id: 'paymentSettings.IntegrationNoSelfServe.pageTitle',
    defaultMessage: 'Payment Processor',
  },
  infoHeader: {
    id: 'paymentSettings.IntegrationNoSelfServe.infoHeader',
    defaultMessage: 'Your payment integration is managed by administrators at SevenRooms.',
  },
  infoBody: {
    id: 'paymentSettings.IntegrationNoSelfServe.infoBody',
    defaultMessage: 'Please <a>contact support</a> for assistance.',
  },
} as const)
