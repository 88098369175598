import { useMemo, useCallback } from 'react'
import type { Event } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelect } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { EventSelectorLocales } from './EventSelector.locales'

interface EventSelectorProps {
  events: Event[]
  selectedEventIds: string[]
  setSelectedEventIds: (selectedIds: string[]) => void
}
export function EventSelector({ events, setSelectedEventIds, selectedEventIds }: EventSelectorProps) {
  const { formatMessage } = useLocales()
  const EventOptions = useMemo(() => {
    if (events) {
      return events.map(event => ({
        id: event.id,
        label: event.name,
        description: (
          <Box pl="l" width="100%">
            <HStack>
              <VStack flex="1">
                <Text fontSize="m" color="secondaryFont">
                  {formatMessage(EventSelectorLocales.eventSelectDays)}
                </Text>
              </VStack>
              <VStack flex="3">
                <Text fontSize="m" color="secondaryFont">
                  {event.schedule}
                </Text>
              </VStack>
            </HStack>
          </Box>
        ),
      }))
    }
    return []
  }, [events, formatMessage])

  const onEventIdsChange = useCallback(
    (selectedIds: string[]) => {
      setSelectedEventIds(selectedIds)
    },
    [setSelectedEventIds]
  )

  return (
    <MultiSelect
      options={EventOptions}
      onChange={onEventIdsChange}
      initialSelectedIds={selectedEventIds}
      searchable
      useCheckboxItems
      wrapText
    />
  )
}
