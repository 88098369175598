import { useMemo } from 'react'
import {
  useGetDefaultPolicyDataQuery,
  useGetExperiencesQuery,
  useGetAudienceHierarchyQuery,
  useGetPoliciesQuery,
  useGetReservationWidgetSettingsQuery,
  useGetSeatingAreasTablesQueryNew,
  useGetShiftsQuery,
  useGetTaxRatesQuery,
  useGetUpsellsQuery,
  useGetReservationTagGroupsQuery,
  useGetClientTagGroupsQuery,
} from '@sevenrooms/core/api'
import {
  type Policy,
  type ReservationWidget,
  type Shift,
  type TaxRates,
  type Experience,
  type Upsells,
  type ExperiencesData,
  type DefaultPolicyData,
  type SeatingAreasTablesNew,
  type AudienceHierarchy,
  type GenericTagGroup,
  OfferTypeEnum,
} from '@sevenrooms/core/domain'
import { notNullish } from '@sevenrooms/core/ui-kit/form'
import { useVenueContext } from '@sevenrooms/mgr-core'
import type {
  Upsells as AccessRulesUpsells,
  DefaultPolicies as AccessRulesDefaultPolicies,
  SeatingArea as AccessRuleSeatingArea,
} from './AccessRule.types'

export interface AccessRulesSlideoutData {
  upsells: AccessRulesUpsells
  allShifts: Shift[]
  policies: Policy[]
  defaultPolicies: AccessRulesDefaultPolicies
  reservationWidgetSettings: ReservationWidget.Settings
  taxRates: TaxRates
  experiences: Record<string, Experience>
  seatingAreaData: AccessRuleSeatingArea
  audienceHierarchy: AudienceHierarchy[]
  reservationTagGroups: GenericTagGroup[]
  clientTagGroups: GenericTagGroup[]
}

export interface AccessRulesSlideoutDataFetchState {
  isSuccess: boolean
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isUninitialized: boolean
  errors: unknown[]
  data?: AccessRulesSlideoutData
}

const _convertUpsellsToAccessRulesUpsells = (upsells: Upsells): AccessRulesUpsells => {
  /**
   * The Upsells type defined in AccessRule.types.ts has a label: string prop. This prop was set
   * in manager.accessrulesshared.jsx and is based off of the upsells.is_required prop.
   * However, upsells.is_required does not exist, so label will always just be category.name
   */
  const accessRulesUpsells: AccessRulesUpsells = { categories: {}, inventory: upsells.inventory }
  Object.entries(upsells.categories).forEach(([id, category]) => {
    accessRulesUpsells.categories[id] = { ...category, label: category.name }
  })
  return accessRulesUpsells
}

const _convertExperiencesDataToAccessRulesExperiences = (experiencesData: ExperiencesData): Record<string, Experience> =>
  experiencesData.results.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current.id]: current,
    }),
    {}
  )

const _convertDefaultPolicyDataToAccessRulesDefaultPolicies = (defaultPolicyData: DefaultPolicyData): AccessRulesDefaultPolicies => ({
  bookingPolicy: defaultPolicyData.bookingPolicy,
  cancelPolicy: defaultPolicyData.cancellationPolicy,
  bookingPolicyId: defaultPolicyData.bookingPolicyId,
  cancelPolicyId: defaultPolicyData.cancellationPolicyId,
})

const _convertSeatingAreasToAccessRulesSeatingAreas = (seatingAreas: SeatingAreasTablesNew): AccessRuleSeatingArea => ({
  allTableInventoryItems: seatingAreas.allTableInventoryItems,
  venueSeatingAreas: seatingAreas.venueSeatingAreas,
  newSeatingAreaCodesToTables: seatingAreas.newSeatingAreaCodesToTables,
  seatingAreaCodesToTables: seatingAreas.seatingAreaCodesToTables,
})

export function useAccessRulesSlideoutData(): AccessRulesSlideoutDataFetchState {
  const { venueId } = useVenueContext()

  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })
  const policies = useGetPoliciesQuery({ venueId, policyCategories: ['BOOKING', 'PAYMENT'] })
  const defaultPolicies = useGetDefaultPolicyDataQuery({ venueId })
  const reservationWidgetSettings = useGetReservationWidgetSettingsQuery({ venueId })
  const upsells = useGetUpsellsQuery({ venueId })
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId })
  const shifts = useGetShiftsQuery({ venueId })
  const taxRates = useGetTaxRatesQuery({ venueId })
  const experiences = useGetExperiencesQuery({ venueId, excludedOfferTypes: [OfferTypeEnum.EVENT] })
  const reservationTagGroups = useGetReservationTagGroupsQuery({ venueKey: venueId, includeRebuildState: false })
  const clientTagGroups = useGetClientTagGroupsQuery({ venueKey: venueId })
  return useMemo(() => {
    const results = [
      policies,
      defaultPolicies,
      reservationWidgetSettings,
      shifts,
      taxRates,
      experiences,
      seatingAreasTables,
      audienceHierarchy,
      reservationTagGroups,
      clientTagGroups,
    ]
    const isSuccess = results.every(r => r.isSuccess)
    return {
      isSuccess,
      isError: results.some(r => r.isError),
      isLoading: results.some(r => r.isLoading),
      isFetching: results.some(r => r.isFetching),
      isUninitialized: results.some(r => r.isUninitialized),
      errors: results.map(r => r.error).filter(notNullish),
      data:
        isSuccess &&
        upsells.data &&
        shifts.data &&
        reservationWidgetSettings.data?.widgetSettings &&
        taxRates.data &&
        policies.data &&
        defaultPolicies.data &&
        experiences.data &&
        seatingAreasTables.data &&
        audienceHierarchy.data &&
        reservationTagGroups.data &&
        clientTagGroups.data
          ? {
              upsells: _convertUpsellsToAccessRulesUpsells(upsells.data),
              allShifts: shifts.data,
              reservationWidgetSettings: reservationWidgetSettings.data?.widgetSettings,
              taxRates: taxRates.data,
              policies: policies.data,
              defaultPolicies: _convertDefaultPolicyDataToAccessRulesDefaultPolicies(defaultPolicies.data),
              experiences: _convertExperiencesDataToAccessRulesExperiences(experiences.data),
              seatingAreaData: _convertSeatingAreasToAccessRulesSeatingAreas(seatingAreasTables.data),
              audienceHierarchy: audienceHierarchy.data,
              reservationTagGroups: reservationTagGroups.data,
              clientTagGroups: clientTagGroups.data,
            }
          : undefined,
    }
  }, [
    audienceHierarchy,
    clientTagGroups,
    defaultPolicies,
    experiences,
    policies,
    reservationTagGroups,
    reservationWidgetSettings,
    seatingAreasTables,
    shifts,
    taxRates,
    upsells.data,
  ])
}
