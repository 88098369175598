import React, { useCallback, useMemo, useState } from 'react'
import { useCreatePerkMutation, useSaveAccessRuleMutation, useUpdatePerkMutation, type AccessRuleInput } from '@sevenrooms/core/api'
import {
  PerkAccessTypeEnum,
  AccessRuleTagRestrictionEnum,
  type AccessRule,
  type AccessRuleAudienceTier,
  type AccessRuleStartType,
  type AccessRules,
  type GenericTagGroup,
  type Perk,
  type PerkAssociatedAccessRulesData,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form, IconButton, Label, TextArea } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, DividerLine, Grid, HStack, Tooltip, VStack, Window, notify, Box, Breadcrumbs } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { isSeatingAreaToTables } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.types'
import { getAccessRuleDefaults, getInitialState } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.zod'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { FormattedTimeBefore } from '@sevenrooms/mgr-access-rules-slideout/components/shared/FormattedTimeBefore'
import { toAccessRuleInput } from '@sevenrooms/mgr-access-rules-slideout/toAccessRuleInput'
import type { AccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import {
  formToPerk,
  getCreateEditPerkDefaultValues,
  getTagsGroupsToRemovePerkFrom,
  useCreateEditPerkForm,
  type CreateEditPerkFormData,
} from '@sevenrooms/mgr-perks/views/CreateEditPerk/CreateEditPerkForm.zod'
import { routes } from '@sevenrooms/routes'
import { PerkActionButtons } from '../../components/PerkActionButtons'
import { PerkAssociatedTagsForm } from '../../components/PerkAssociatedTagsForm'
import { PerkEditAudienceTierModal } from '../../components/PerkEditAudienceTierModal'
import { PerkNameForm } from '../../components/PerkNameForm'
import { PerkSelectAccessRuleModal } from '../../components/PerkSelectAccessRuleModal'
import { perksMessages } from '../../locales'
import type { PerkEditAudienceTierModalFormData } from '../../components/PerkEditAudienceTierModal/PerkEditAudienceTierModalForm.zod'
import type { PerkSelectAccessRuleModalFormData } from '../../components/PerkSelectAccessRuleModal/PerkSelectAccessRuleModalForm.zod'

export interface CreateEditEarlyAccessPerkFormProps {
  perk?: Perk
  clientTagGroups: GenericTagGroup[]
  accessRulesSlideoutData: AccessRulesSlideoutData
  accessRulesByDay: AccessRules
  earlyAccessAudienceId: string
}

export function CreateEditEarlyAccessPerkForm({
  perk,
  clientTagGroups,
  accessRulesSlideoutData,
  accessRulesByDay,
  earlyAccessAudienceId: exclusiveAudienceId,
}: CreateEditEarlyAccessPerkFormProps) {
  const { formatMessage } = useLocales()
  const perkId = perk?.id
  const { venue, venueKey } = useVenueContext()
  const { venueSettings, venueCurrencyCode } = useAppContext()
  const nav = useNavigation()

  const [createPerk, { isLoading: isCreatingPerk }] = useCreatePerkMutation()
  const [updatePerk, { isLoading: isUpdatingPerk }] = useUpdatePerkMutation()

  const [saveAccessRule, { isLoading: isSavingAccessRule }] = useSaveAccessRuleMutation()

  const isMutating = isCreatingPerk || isUpdatingPerk || isSavingAccessRule

  const [accessRuleUpdatesById, setAccessRuleUpdatesById] = useState<{ [key: string]: AccessRule }>({})
  const [audienceTierToEdit, setAudienceTierToEdit] = useState<{ audienceTier: AccessRuleAudienceTier; accessRule: AccessRule }>()
  const [unlinkedAccessRuleIds, setUnlinkedAccessRuleIds] = useState<{ tierId: string | undefined; persistentId: string }[]>([])

  // Counter for temporary Ids given to not yet created Audience Tiers
  const [temporaryId, setTemporaryId] = useState<number>(1)

  const seatingAreaToTables = useMemo(
    () => accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables.filter(isSeatingAreaToTables) ?? [],
    [accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables]
  )
  const tagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.reservationTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.reservationTagGroups]
  )

  const getPageTitle = () =>
    perkId
      ? formatMessage(perksMessages.editPerk, { perkName: perk?.internalName ?? 'Perk' })
      : formatMessage(perksMessages.createNewEarlyAccessPerk)

  const handleOnCancel = useCallback(() => {
    nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
  }, [nav, venue])

  const defaultValues = useMemo(() => getCreateEditPerkDefaultValues({ perk, conciergeAccess: false }), [perk])
  const form = useForm(useCreateEditPerkForm(), {
    defaultValues,
  })
  const { field, watch, reset } = form

  const perkInternalName = watch('internalName')

  const { initialAccessRulesOptions, existingAssociatedAccessRulesById, accessRulesById } = useMemo(() => {
    const initialAccessRulesOptions: { id: string; label: string; accessRule: AccessRule }[] = []
    const existingAssociatedAccessRulesById: { [key: string]: AccessRule } = {}
    const accessRulesById: { [key: string]: AccessRule } = {}

    Object.values(accessRulesByDay ?? {}).forEach(accessRules => {
      accessRules.forEach(accessRule => {
        // Show one of each Access Rule and exclude overrides
        if (!accessRulesById[accessRule.persistentId] && !accessRule.isOverride) {
          accessRulesById[accessRule.persistentId] = accessRule

          if (perk && perk.associatedAccessRulesData[accessRule.persistentId]) {
            existingAssociatedAccessRulesById[accessRule.persistentId] = accessRule
          }

          const hasEarlyAccess = accessRule.audienceTiers.some(tier => tier.isEarlyAccess)
          if (accessRule.audienceTiers.length < 3 && !hasEarlyAccess) {
            initialAccessRulesOptions.push({
              id: accessRule.persistentId,
              label: accessRule.name,
              accessRule,
            })
          }
        }
      })
    })

    return { initialAccessRulesOptions, existingAssociatedAccessRulesById, accessRulesById }
  }, [accessRulesByDay, perk])

  const [associatedAccessRulesById, setAssociatedAccessRulesById] =
    useState<{ [key: string]: AccessRule }>(existingAssociatedAccessRulesById)
  const [accessRulesOptions, setAccessRulesOptions] =
    useState<{ id: string; label: string; accessRule: AccessRule }[]>(initialAccessRulesOptions)
  const getAccessRuleInput = useCallback(
    (accessRule: AccessRule): AccessRuleInput => {
      const clientTagGroupsMap = new Map(accessRulesSlideoutData.clientTagGroups.map(tagGroup => [tagGroup.id, tagGroup]))
      const accessRuleFormData = getInitialState({
        accessRule,
        allShifts: accessRulesSlideoutData.allShifts,
        audienceHierarchy: accessRulesSlideoutData.audienceHierarchy,
        currencyCode: venueCurrencyCode,
        editPhoto: formatMessage(accessRulesMessages.editPhoto),
        name: accessRule.name,
        startDate: new Date(),
        shiftCategories: [],
        policies: accessRulesSlideoutData.policies,
        seatingAreaToTables,
        startOfDayTime: venueSettings.startOfDayTime,
        tagGroups,
        clientTagGroups: clientTagGroupsMap,
        upsells: accessRulesSlideoutData.upsells,
        accessRuleDefaults: getAccessRuleDefaults({
          defaultBookingPolicyId: accessRulesSlideoutData.defaultPolicies.bookingPolicyId ?? 'default',
          defaultCancelPolicyId: accessRulesSlideoutData.defaultPolicies.cancelPolicyId ?? 'default',
          policies: accessRulesSlideoutData.policies,
          clientTagGroups: clientTagGroupsMap,
        }),
      })

      const prepData = toAccessRuleInput(
        new Date().toISOString(),
        venue.id,
        venueSettings.isGoogleBookingEnabled,
        venueSettings.isTheforkIntegrationEnabled,
        'all'
      )
      const accessRuleInput = prepData(accessRuleFormData)

      return accessRuleInput
    },
    [accessRulesSlideoutData, formatMessage, seatingAreaToTables, tagGroups, venue.id, venueCurrencyCode, venueSettings]
  )

  const updateAccessRuleAsync = useCallback(
    async ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }) => {
      const { name } = accessRuleInput
      try {
        const updatedAccessRule = await saveAccessRule({
          args: { id, venueId: venue.id },
          data: accessRuleInput,
        }).unwrap()

        return { updatedAccessRule }
      } catch {
        return { errorMessage: formatMessage(accessRulesMessages.saveFailed, { name }) }
      }
    },
    [saveAccessRule, venue.id, formatMessage]
  )

  const handleEditAudienceTierOnSubmit = useCallback(
    (formData: PerkEditAudienceTierModalFormData) => {
      if (audienceTierToEdit) {
        const { accessRule, audienceTier } = audienceTierToEdit

        const updatedAudienceTier = accessRule.audienceTiers.map(tier =>
          tier.audienceTierId === audienceTier.audienceTierId
            ? {
                ...tier,
                startNum: formData.count ?? undefined,
                startType: formData.unit as AccessRuleStartType,
                startHour: formData.beforeTime,
                startHourDisplay: formData.beforeTime,
              }
            : tier
        )

        const updatedAccessRule = {
          [accessRule.persistentId]: { ...accessRule, audienceTiers: updatedAudienceTier },
        }

        // Update the the list of associated access rules
        setAssociatedAccessRulesById(prev => ({ ...prev, ...updatedAccessRule }))

        // Add to updates list
        setAccessRuleUpdatesById(prev => ({ ...prev, ...updatedAccessRule }))

        setAudienceTierToEdit(undefined)
      }
    },
    [audienceTierToEdit, setAssociatedAccessRulesById, setAccessRuleUpdatesById]
  )

  const handleOnSubmit = useCallback(
    async (createEditPerkFormData: CreateEditPerkFormData) => {
      const associatedAccessRulesData = perk ? { ...perk.associatedAccessRulesData } : {}

      // Remove unlinked tiers
      unlinkedAccessRuleIds.forEach(({ persistentId, tierId }) => {
        if (associatedAccessRulesData[persistentId]) {
          const audienceTierIds = associatedAccessRulesData[persistentId]?.audienceTierIds.filter(tier => tier !== tierId) ?? []
          if (audienceTierIds.length > 0) {
            associatedAccessRulesData[persistentId] = { audienceTierIds }
          } else {
            delete associatedAccessRulesData[persistentId]
          }
        }
      })

      const newAssociatedAccessRulesData: PerkAssociatedAccessRulesData = {}
      // Update Access Rule
      for (const accessRule of Object.values(accessRuleUpdatesById)) {
        // If the tier has an number id, it's a temporary id, so it hasn't been created yet
        // We need to set undefined
        const accessRuleForUpdate = {
          ...accessRule,
          audienceTiers: accessRule.audienceTiers.map(tier => ({
            ...tier,
            ...(tier.audienceTierId && { audienceTierId: Number(tier.audienceTierId) ? undefined : tier.audienceTierId }),
          })),
        }
        const accessRuleInput = getAccessRuleInput(accessRuleForUpdate)
        const { updatedAccessRule, errorMessage } = await updateAccessRuleAsync({
          id: accessRule.id,
          accessRuleInput,
        })

        if (errorMessage) {
          notify({
            content: formatMessage(perksMessages.perkCreateUpdateError),
            type: 'error',
          })
          // Using return here to avoid further operations
          return false
        } else if (updatedAccessRule) {
          // Set updated tierIds in perk associatedAccessRulesData
          const audienceTierIds = updatedAccessRule.audienceTiers.filter(tier => tier.isEarlyAccess).map(tier => tier.audienceTierId ?? '')
          newAssociatedAccessRulesData[updatedAccessRule.persistentId] = { audienceTierIds }
        }
      }

      // Remove empty associations
      const filteredAssociatedAccessRulesData = Object.entries({ ...associatedAccessRulesData, ...newAssociatedAccessRulesData }).reduce(
        (acc, [key, value]) => {
          if (value.audienceTierIds.filter(id => id).length) {
            return {
              ...acc,
              [key]: value,
            }
          }
          return acc
        },
        {}
      )

      const updatedPerk = formToPerk({
        createEditPerkFormData,
        associatedAccessRulesData: filteredAssociatedAccessRulesData,
      })
      updatedPerk.perkType = PerkAccessTypeEnum.EARLY_ACCESS

      const createUpdatePromise =
        perk && perkId
          ? updatePerk({
              venueId: venue.id,
              perkId,
              perk: updatedPerk,
              tagGroupsToRemovePerkFrom: getTagsGroupsToRemovePerkFrom(perk, createEditPerkFormData),
            })
          : createPerk({ venueId: venue.id, perk: updatedPerk })

      try {
        await createUpdatePromise.unwrap()

        notify({
          content: formatMessage(perk && perkId ? perksMessages.perkUpdateSuccess : perksMessages.perkCreateSuccess, {
            perkInternalName: perkInternalName || 'Perk',
          }),
          type: 'success',
        })
        nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
      } catch (e) {
        notify({
          content: formatMessage(perksMessages.perkCreateUpdateError),
          type: 'error',
        })
      }
      return true
    },
    [
      createPerk,
      formatMessage,
      accessRuleUpdatesById,
      unlinkedAccessRuleIds,
      nav,
      perk,
      perkId,
      updatePerk,
      venue.id,
      venue.urlKey,
      perkInternalName,
      updateAccessRuleAsync,
      getAccessRuleInput,
    ]
  )

  const handlePerkSelectAccessRuleModalOnSubmit = (values: PerkSelectAccessRuleModalFormData) => {
    const { accessRuleToCloneIds, releaseTableTimeData } = values
    const startNum = releaseTableTimeData.count ?? undefined
    const startType = releaseTableTimeData.unit as AccessRuleStartType
    const startHour = releaseTableTimeData.beforeTime

    const selectedAccessRulesAndBooking: { [key: string]: AccessRule } = {}

    accessRuleToCloneIds.forEach(accessRuleToCloneId => {
      const existingAccessRule = associatedAccessRulesById[accessRuleToCloneId] ?? (accessRulesById[accessRuleToCloneId] as AccessRule)
      const updatedAudienceTiers = [
        ...existingAccessRule.audienceTiers,
        {
          audienceTierId: `${temporaryId}`,
          channels: [],
          concierges: [],
          thirdParties: [exclusiveAudienceId],
          startHour,
          startHourDisplay: startHour,
          startNum,
          startType,
          isEarlyAccess: true,
          tagRestriction: AccessRuleTagRestrictionEnum.NONE,
          clientTags: [],
        },
      ]

      selectedAccessRulesAndBooking[accessRuleToCloneId] = {
        ...existingAccessRule,
        audienceTiers: updatedAudienceTiers,
      }

      // Remove AccessRule from options
      const updatedOptions = accessRulesOptions.filter(({ accessRule }) => accessRule.persistentId !== accessRuleToCloneId)

      setAccessRulesOptions(updatedOptions)
    })

    setTemporaryId(prev => prev + 1)
    setAccessRuleUpdatesById(prev => ({ ...prev, ...selectedAccessRulesAndBooking }))
    setAssociatedAccessRulesById(prev => ({ ...prev, ...selectedAccessRulesAndBooking }))
  }

  const unlinkAccessRule = useCallback(
    (id: string, tierId: string | undefined) => {
      setUnlinkedAccessRuleIds(prev => [...prev, { persistentId: id, tierId }])

      const accessRule = associatedAccessRulesById[id] as AccessRule
      const audienceTiers = accessRule.audienceTiers.filter(tier => tier.audienceTierId !== tierId)

      // Add AccessRule in options
      setAccessRulesOptions(prev => [
        ...prev,
        {
          id: accessRule.persistentId,
          label: accessRule.name,
          accessRule,
        },
      ])

      // Remove it from the list of associated access rules
      setAssociatedAccessRulesById(prev => ({ ...prev, [id]: { ...accessRule, audienceTiers } }))

      // Add to updates list
      setAccessRuleUpdatesById(prev => ({ ...prev, [id]: { ...accessRule, audienceTiers } }))
    },
    [associatedAccessRulesById, setAssociatedAccessRulesById, setAccessRuleUpdatesById]
  )

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={getPageTitle()} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={() => {}}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          title={getPageTitle()}
          actions={<PerkActionButtons disabled={isMutating || !perkInternalName} onCancel={handleOnCancel} perkId={perkId} />}
          breadcrumbs={
            <Breadcrumbs>
              <Link to={nav.href(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })}>
                {formatMessage(perksMessages.perksManagement)}
              </Link>
              <Text>{formatMessage(perkId ? perksMessages.editPerkBreadcrumb : perksMessages.createNewEarlyAccessPerkBreadcrumb)}</Text>
            </Breadcrumbs>
          }
        >
          <Box pt="lm" pl="lm" pr="lm" width="100%">
            <BaseSection title={formatMessage(perksMessages.perkGeneral)}>
              <Box p="lm" width="100%">
                <VStack spacing="lm">
                  <PerkNameForm
                    disabled={isMutating}
                    internalNameField={field.prop('internalName')}
                    primaryText={formatMessage(perksMessages.internalPerksName)}
                    secondaryText={formatMessage(perksMessages.exclusivePerkNameInternalSecondary)}
                  />
                  <Box>
                    <DividerLine margin="none" />
                  </Box>
                  <VStack spacing="xs">
                    <Header>{formatMessage(perksMessages.reservationWidetLinkPrimary)}</Header>
                    <Text>{formatMessage(perksMessages.earlyAccessReservationWidetLinkSecondary)}</Text>
                  </VStack>
                  {perk?.associatedAccessRulesData ? (
                    <>
                      <Label
                        primary={formatMessage(perksMessages.reservationLinkContentPrimary)}
                        secondary={formatMessage(perksMessages.reservationLinkContentSecondary)}
                      >
                        {Object.keys(perk.associatedAccessRulesData).length > 0 && (
                          <HStack>
                            <Box width="50%">
                              <TextArea disabled defaultValue={perk.earlyAccessLink} resize="none" />
                            </Box>
                            <Tooltip
                              content={<div>{formatMessage(commonMessages.copyToClipboard)}</div>}
                              displayAction="hover"
                              alignment="top"
                            >
                              <IconButton
                                borderType="square"
                                icon="VMSWeb-copy"
                                height="100%"
                                onClick={() => {
                                  if (perk.earlyAccessLink) {
                                    navigator.clipboard.writeText(perk.earlyAccessLink)
                                  }
                                }}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Label>
                    </>
                  ) : (
                    <Text color="secondaryFont" fontStyle="italic">
                      {formatMessage(perksMessages.reservationLinkNotCreated)}
                    </Text>
                  )}
                  <Box>
                    <DividerLine margin="none" />
                  </Box>
                  <Box p="lm" width="100%">
                    <PerkAssociatedTagsForm
                      field={field.prop('associatedTags')}
                      clientTagGroups={clientTagGroups ?? []}
                      disabled={isMutating}
                      disableReservationTags
                    />
                  </Box>
                </VStack>
              </Box>
            </BaseSection>
          </Box>
          <Box p="lm" width="100%">
            <BaseSection hasError>
              <Box p="lm" width="100%">
                <VStack spacing="xs">
                  <Header type="h2">{formatMessage(perksMessages.accessRulesSectionTitle)}</Header>
                  <Text color="secondaryFont">{formatMessage(perksMessages.accessRulesSelectSectionDescription)}</Text>
                </VStack>
                <Grid gridTemplateColumns="repeat(2, minmax(0, 1fr))" pt="sm" pb="sm" mt="lm" gap="s">
                  <Text>{formatMessage(perksMessages.accessRulesTableAccessRuleName)}</Text>
                  <Text>{formatMessage(perksMessages.accessRulesTableDaysInAdvanceToBook)}</Text>
                </Grid>
                <DividerLine margin="none" />
                {Object.values(associatedAccessRulesById).map(accessRule =>
                  accessRule.audienceTiers
                    .filter(tier => tier.isEarlyAccess)
                    .map(tier => (
                      <React.Fragment key={accessRule.id + tier.audienceTierId}>
                        <Grid gridTemplateColumns="repeat(2, minmax(0, 1fr))" cursor="pointer" alignItems="center" pt="s" pb="s" gap="s">
                          <Box pl="s" pt="xxs" pb="xxs">
                            <Text>{accessRule.name}</Text>
                          </Box>
                          <HStack pr="s" pt="xxs" pb="xxs" alignItems="center" justifyContent="space-between">
                            <Text>
                              <FormattedTimeBefore
                                count={tier.startNum ?? null}
                                unit={tier.startType ?? null}
                                beforeTime={tier.startHour ?? null}
                              />
                            </Text>
                            <HStack>
                              <IconButton
                                size="s"
                                iconSize="sm"
                                icon="VMSWeb-edit"
                                data-test="edit-access-rule-button"
                                onClick={() => {
                                  nav.push(routes.manager2.marketing.perks2.editAudienceTier, {
                                    params: { venueKey },
                                  })
                                  setAudienceTierToEdit({ audienceTier: tier, accessRule })
                                }}
                                borderType="none-round"
                              />
                              <IconButton
                                size="s"
                                iconSize="sm"
                                icon="VMSWeb-unlink"
                                data-test="unlink-access-rule-button"
                                onClick={() => unlinkAccessRule(accessRule.persistentId, tier.audienceTierId)}
                                borderType="none-round"
                              />
                            </HStack>
                          </HStack>
                        </Grid>
                        <DividerLine margin="none" />
                      </React.Fragment>
                    ))
                )}
                <Box pt="m" pb="m" pl="xs">
                  <Button
                    data-test="early-access-select-access-rule"
                    variant="tertiary"
                    onClick={() => {
                      nav.push(routes.manager2.marketing.perks2.selectAccessRule, {
                        params: { venueKey },
                      })
                    }}
                    noPadding
                  >
                    {formatMessage(perksMessages.selectAccessRule)}
                  </Button>
                </Box>
              </Box>
            </BaseSection>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.perks2.selectAccessRule}>
        <Window>
          <PerkSelectAccessRuleModal
            accessRulesOptions={accessRulesOptions}
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.perks2.selectAccessRule, { params: { venueKey } })}
            onSubmit={handlePerkSelectAccessRuleModalOnSubmit}
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.perks2.editAudienceTier}>
        <Window>
          {audienceTierToEdit && (
            <PerkEditAudienceTierModal
              audienceTier={audienceTierToEdit.audienceTier}
              ruleName={audienceTierToEdit.accessRule.name}
              closeHref={nav.closeSurfaceHref(routes.manager2.marketing.perks2.editAudienceTier, { params: { venueKey } })}
              onSubmit={handleEditAudienceTierOnSubmit}
              onClose={() => reset(defaultValues)}
            />
          )}
        </Window>
      </Surface>
    </>
  )
}
