import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useCallback, useState } from 'react'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { DatePicker } from '@sevenrooms/react-components/components/DatePicker'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'

export function AccessDatePicker() {
  const [{ date, view, range }, setParams] = useAccessRulesUrlParams()
  const [initialDate] = useState(date.toJsDate())
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  const onChange = useCallback(
    async (d: Date | null) => {
      if (d == null) {
        return
      }
      const newDate = DateOnly.fromDate(d)
      setParams({ date: newDate })
    },
    [setParams]
  )

  const goToPreviousDay = () => {
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() - 1)
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  const goToNextDay = () => {
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() + 1)
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton data-test="prev-day-arrow" onClick={goToPreviousDay} aria-label="Previous day">
        <ArrowBackIosNewIcon />
      </IconButton>
      <DatePicker
        open={datePickerOpen}
        onOpen={() => setDatePickerOpen(true)}
        onClose={() => setDatePickerOpen(false)}
        defaultValue={initialDate}
        value={date.toJsDate()}
        onChange={onChange}
        aria-label="Access Rules date picker"
        data-test="access-rules-date-picker"
        disabled={view === AccessRuleView.LIST && range === AccessRuleRange.OVERVIEW}
        slotProps={{
          textField: {
            id: 'access-rules-date-picker',
            fullWidth: true,
            inputProps: {
              readOnly: true,
              onClick: () => setDatePickerOpen(!datePickerOpen),
            },
          },
        }}
        sx={{
          '& .MuiInputBase-root': { my: 0 },
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.12)' },
        }}
      />
      <IconButton data-test="next-day-arrow" onClick={goToNextDay} aria-label="Next day">
        <ArrowForwardIosIcon />
      </IconButton>
    </Stack>
  )
}
