import { useSaveConfigurationMutation } from '@sevenrooms/core/api'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation, useParams } from '@sevenrooms/core/navigation'
import { Button, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { HStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { usePaymentIntegration } from '../../Integrations'
import { messages } from './PaymentSettingsButtons.locales'

export function PaymentSettingsButtons() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()
  const paymentIntegration = usePaymentIntegration()
  const currentIntegration = paymentIntegration?.integration?.integrationName

  const { handleSubmit } = useFormContext()
  const [saveConfiguration, { isLoading }] = useSaveConfigurationMutation()

  const { provider } = useParams<{ provider?: string }>()
  const gateway = provider || currentIntegration
  if (!gateway) {
    return null
  }

  const notifyNotSavedEditChanges = () => notify({ content: formatMessage(messages.testFailedOnSaveMessage), type: 'error' })

  const onSubmit = handleSubmit(async configuration => {
    await saveConfiguration({ gateway, venueKey, configuration }).then(savingResult => {
      if ('data' in savingResult && savingResult?.data?.result === null) {
        notifyNotSavedEditChanges()
      } else {
        nav.push(routes.manager2.settings.paymentIntegration.view, {
          params: { venueKey },
        })
      }
    })
  })

  const onDiscardChanges = () => {
    nav.push(routes.manager2.settings.paymentIntegration.view, {
      params: { venueKey },
    })
  }

  return (
    <HStack spacing="s" p="s">
      <Button variant="tertiary" onClick={onDiscardChanges} data-test="payment-settings-discard-changes-btn">
        {formatMessage(messages.discardChanges)}
      </Button>
      <LoaderButton loading={isLoading} onClick={onSubmit} data-test="payment-settings-save-changes-btn">
        {formatMessage(messages.saveChanges)}
      </LoaderButton>
    </HStack>
  )
}
