import { useMemo } from 'react'
import { EmailCategoriesEnum } from '@sevenrooms/core/domain'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'

const useShiftOptionsValues = createZodSchema(z =>
  z.union([
    z.literal(EmailCategoriesEnum.DAY),
    z.literal(EmailCategoriesEnum.DINNER),
    z.literal(EmailCategoriesEnum.BREAKFAST),
    z.literal(EmailCategoriesEnum.BRUNCH),
    z.literal(EmailCategoriesEnum.LUNCH),
    z.literal(EmailCategoriesEnum.LEGACY),
    z.literal(EmailCategoriesEnum.orders),
  ])
)

export type ShiftOptionsForm = InferZodSchema<typeof useShiftOptionsValues>

export type ShiftTimeForm = InferZodSchema<typeof useShiftTimeForm>

export const useShiftTimeForm = createZodSchema(z => {
  const shiftOptionsValues = useShiftOptionsValues()
  const sendDayTimeValues = useSendDayTimeForm()

  return useMemo(
    () =>
      z.object({
        shift: shiftOptionsValues,
        dayTime: z.array(sendDayTimeValues),
      }),
    [z, sendDayTimeValues, shiftOptionsValues]
  )
})

export type SendDayTimeForm = InferZodSchema<typeof useSendDayTimeForm>

export const useSendDayTimeForm = createZodSchema(z =>
  useMemo(
    () =>
      z.object({
        sendTime: z.string(),
        sendDays: z.string(),
      }),
    [z]
  )
)

export type SendTimeForm = InferZodSchema<typeof useSendTimeForm>

export const useSendTimeForm = createZodSchema(z =>
  useMemo(
    () =>
      z.object({
        time: z.string(),
      }),
    [z]
  )
)
