import React from 'react'
import styled from 'styled-components'
import { AutotagNotes } from './AutotagNotes'
import { AutotagPerksSelect } from './AutotagPerksSelect'
import { AutotagPermissions } from './AutotagPermissions'

const Hr = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 16px;
`
const List = styled.ul`
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
`
export function AutotagTierAdditionalOptions({ autotagPerks, updatePerksByTag, updateNotesByTag, tier, perks, notes }) {
  return (
    <>
      <Hr />
      <List>
        <AutotagPermissions permission="isLoyaltyAndPerksEnabled">
          <li>
            <AutotagPerksSelect
              errorMessage=""
              selectedPerkIds={perks || []}
              autotagPerks={autotagPerks.perks}
              onChange={value => updatePerksByTag(tier.tier_tag_name, value)}
              disabled={false}
            />
          </li>
          <li>
            <AutotagNotes
              errorMessage=""
              notes={notes && notes.length > 0 ? notes[0] ?? '' : ''}
              onChange={value => updateNotesByTag(tier.tier_tag_name, value)}
              disabled={false}
            />
          </li>
        </AutotagPermissions>
      </List>
    </>
  )
}
