import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import type { PerkVisibilityFormField } from './PerkVisibilityForm.zod'

interface PerkVisibilityFormProps {
  field: PerkVisibilityFormField
}

export function PerkVisibilityForm({ field }: PerkVisibilityFormProps) {
  const { formatMessage } = useLocales()

  const {
    perkVisibility,
    displayedExternallyLabel,
    perkNameExternalSecondary,
    perkNameExternalPrimary,
    perkNameExternalPlaceholder,
    perkExternalMatchesInternalLabel,
  } = perksMessages

  const usingInternalName = useWatch(field.prop('usingInternalName'))
  const displayedExternally = useWatch(field.prop('displayedExternally'))

  return (
    <Label primary={<Text fontWeight="bold">{formatMessage(perkVisibility)}</Text>}>
      <Checkbox field={field.prop('displayedExternally')} data-test="sr-perk-displayed-externally">
        {formatMessage(displayedExternallyLabel)}
      </Checkbox>
      {displayedExternally && (
        <VStack spacing="m" ml="l">
          <Checkbox field={field.prop('usingInternalName')} data-test="sr-perk-is-using-internal-name">
            {formatMessage(perkExternalMatchesInternalLabel)}
          </Checkbox>
          {!usingInternalName && (
            <VStack spacing="m" ml="l">
              <Label primary={formatMessage(perkNameExternalPrimary)} secondary={formatMessage(perkNameExternalSecondary)}>
                <FormInput
                  data-test="sr-perk-external-name"
                  field={field.prop('externalName')}
                  placeholder={formatMessage(perkNameExternalPlaceholder)}
                />
              </Label>
            </VStack>
          )}
        </VStack>
      )}
    </Label>
  )
}
