import { useCallback } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../AccessRules.locales'

interface AccessRuleSlideoutFooterProps {
  primaryAction: () => void
  secondaryAction?: () => void
  deleteAction?: () => void
  isEditOpen: boolean
  isFormDirty: boolean
}

export function AccessRuleSlideoutFooter({
  primaryAction,
  secondaryAction,
  deleteAction,
  isEditOpen,
  isFormDirty,
}: AccessRuleSlideoutFooterProps) {
  const { formatMessage } = useLocales()

  const getPrimaryButtonLabel = useCallback(() => {
    if (isEditOpen) {
      return formatMessage(accessRulesMessages.reviewChanges)
    }
    return formatMessage(commonMessages.edit)
  }, [formatMessage, isEditOpen])

  return (
    <HStack width="100%">
      {isEditOpen ? null : (
        <Button data-test="ui-kit-slideout-delete" onClick={deleteAction} variant="tertiary-warning">
          {formatMessage(commonMessages.delete)}
        </Button>
      )}
      <HStack spacing="s" ml="auto">
        {secondaryAction ? (
          <Button data-test="ui-kit-slideout-secondary-button" onClick={secondaryAction} variant="secondary">
            {isEditOpen ? formatMessage(commonMessages.cancel) : formatMessage(accessRulesMessages.clone)}
          </Button>
        ) : null}
        <Button data-test="ui-kit-slideout-primary-button" onClick={primaryAction} disabled={isEditOpen && !isFormDirty} variant="primary">
          {getPrimaryButtonLabel()}
        </Button>
      </HStack>
    </HStack>
  )
}
