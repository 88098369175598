import type { InsightDetail } from '@sevenrooms/core/domain'
import { insightsMessages } from '../locales'
import { LEARN_MORE_URL } from './insightLinkUrls'

export const getTypeGroupInfo = (insightTypeGroup: string) => {
  switch (insightTypeGroup) {
    case 'misallocated_durations':
      return {
        title: insightsMessages.misallocationTitle,
        description: insightsMessages.misallocationDescription,
        learnUrl: LEARN_MORE_URL,
      }
    case 'reduce_cancellations':
      return {
        title: insightsMessages.reduceCancellationsTitle,
        description: insightsMessages.reduceCancellationsDescription,
        learnUrl: LEARN_MORE_URL,
      }
    case 'floorplan_optimization':
      return {
        title: insightsMessages.floorplanOptimizationTitle,
        description: insightsMessages.floorplanOptimizationDescription,
        learnUrl: LEARN_MORE_URL,
      }
    case 'availability_optimization':
      return {
        title: insightsMessages.availabilityOptimizationTitle,
        description: insightsMessages.availabilityOptimizationDescription,
        learnUrl: LEARN_MORE_URL,
      }
    case 'feature_usage':
      return {
        title: insightsMessages.featureUsageTitle,
        description: insightsMessages.featureUsageDescription,
        learnUrl: LEARN_MORE_URL,
      }
    case 'channel_optimization':
      return {
        title: insightsMessages.channelOptimizationTitle,
        description: insightsMessages.channelOptimizationDescription,
        learnUrl: LEARN_MORE_URL,
      }
    default:
      return { title: null, description: null, learnUrl: null }
  }
}

export const getHighlightHeadline = (insightType: string) =>
  ({
    feature_usage_google_reserve_enabled: insightsMessages.highlightHeadlineGoogleReserve,
    over_allocated_durations: insightsMessages.highlightHeadlineAllocation,
    feature_usage_payment_enabled: insightsMessages.highlightHeadlineFeaturePayments,
    pacing_coverage: insightsMessages.highlightHeadlinePacing,
    add_cancellation_fee: insightsMessages.highlightHeadlineCancellationFee,
    feature_usage_priority_alerts: insightsMessages.highlightHeadlineFeaturePriorityAlerts,
    feature_usage_pos_enabled: insightsMessages.highlightHeadlineFeaturePosEnabled,
    feature_usage_low_pos_linkage: insightsMessages.highlightHeadlineFeaturePosLinkage,
    feature_usage_daily_reservation_summary_enabled: insightsMessages.highlightHeadlineFeatureResSummary,
    feature_usage_logo_enabled: insightsMessages.highlightHeadlineFeatureLogo,
    feature_usage_upcoming_event: insightsMessages.highlightHeadlineFeatureUpcomingEvent,
    tables_without_seating_area: insightsMessages.highlightHeadlineTablesWithoutArea,
    access_rules_without_upgrades: insightsMessages.highlightHeadlineRulesWithoutUpgrades,
    feature_usage_feedback_alert_summary_enabled: insightsMessages.highlightHeadlineFeatureFeedbackSummary,
    feature_usage_upgrades_created: insightsMessages.highlightHeadlineFeatureUpgrades,
    feature_usage_requests_enabled: insightsMessages.highlightHeadlineFeatureRequests,
    no_table_combination: insightsMessages.highlightHeadlineNoCombination,
    feature_usage_users_created: insightsMessages.highlightHeadlineFeatureUsersCreated,
    feature_usage_feedback_surveys: insightsMessages.highlightHeadlineFeatureUsageSurveys,
  }[insightType] || { id: '', defaultMessage: '' })

export interface DetailProps {
  data: InsightDetail
}
