import { defineMessages } from '@sevenrooms/core/locales'

export const userAccountsLocales = defineMessages({
  title: {
    id: 'userAccountsSettings.title',
    defaultMessage: 'User Accounts',
  },
  description: {
    id: 'userAccountsSettings.description',
    defaultMessage: 'Click here to see all the <link>permission level sets</link>.',
  },
  buttonAdminSettings: {
    id: 'userAccountsSettings.button.adminSettings',
    defaultMessage: 'Admin Settings',
  },
  buttonCreateUser: {
    id: 'userAccountsSettings.button.createUser',
    defaultMessage: 'Create User',
  },
  buttonSaveUser: {
    id: 'userAccountsSettings.button.saveUser',
    defaultMessage: 'Save User',
  },
  buttonSuspendAccess: {
    id: 'userAccountsSettings.button.suspendAccess',
    defaultMessage: 'Suspend Access',
  },
  buttonUnsuspendAccess: {
    id: 'userAccountsSettings.button.unsuspendAccess',
    defaultMessage: 'Unsuspend Access',
  },
  buttonRestoreDefault: {
    id: 'userAccountsSettings.button.buttonRestoreDefault',
    defaultMessage: 'Restore Default',
  },
  tableViewName: {
    id: 'userAccountsSettings.tableView.name',
    defaultMessage: 'Name',
  },
  tableViewJobTile: {
    id: 'userAccountsSettings.tableView.jobTitle',
    defaultMessage: 'Job Title',
  },
  tableViewUserLevel: {
    id: 'userAccountsSettings.tableView.userLevel',
    defaultMessage: 'User Level',
  },
  tableViewEmailNotifications: {
    id: 'userAccountsSettings.tableView.emailNotifications',
    defaultMessage: 'Email Notifications',
  },
  emailNotificationsFeedbackSummary: {
    id: 'userAccountsSettings.emailNotifications.receiveFeedbackSummaryEmail',
    defaultMessage: 'Feedback Summary',
  },
  detailedUserAccountInformationTitle: {
    id: 'userAccountsSettings.detailedUserAccountInformation.title',
    defaultMessage: 'User Account Information',
  },
  detailedUserAccountInformationFirstName: {
    id: 'userAccountsSettings.detailedUserAccountInformation.firstName',
    defaultMessage: 'First Name',
  },
  detailedUserAccountInformationLastName: {
    id: 'userAccountsSettings.detailedUserAccountInformation.lastName',
    defaultMessage: 'Last Name',
  },
  detailedUserAccountInformationEmail: {
    id: 'userAccountsSettings.detailedUserAccountInformation.email',
    defaultMessage: 'Email',
  },
  detailedUserAccountInformationMFA: {
    id: 'userAccountsSettings.detailedUserAccountInformation.mfa',
    defaultMessage: 'Multifactor Authentication',
  },
  detailedEmailConfigurationsTitle: {
    id: 'userAccountsSettings.detailedEmailConfigurations.title',
    defaultMessage: 'Email Configurations',
  },
  detailedEmailConfigurationsFeedbackSummary: {
    id: 'userAccountsSettings.detailedEmailConfigurations.feedbackSummary',
    defaultMessage: 'Automated Feedback Summary',
  },
  detailedEmailConfigurationsFeedbackSummaryDescription: {
    id: 'userAccountsSettings.detailedEmailConfigurations.feedbackSummary.description',
    defaultMessage: 'Summary of past 24 hours feedback sent out automatically at scheduled times',
  },
  detailedPermissionSetTitle: {
    id: 'userAccountsSettings.permissionSet.title',
    defaultMessage: 'Permission Set',
  },
  detailedPermissionSetDescription: {
    id: 'userAccountsSettings.permissionSet.description',
    defaultMessage: 'Assign an access level on this user',
  },
  notificationSaveSuccess: {
    id: 'userAccountsSettings.notificationSave.success',
    defaultMessage: 'Venue user changes have been saved.',
  },
  notificationSaveError: {
    id: 'userAccountsSettings.notificationSave.error',
    defaultMessage: 'Error: {message}',
  },
} as const)

export const userAccountPermissionsLocales = defineMessages({
  admin: {
    id: 'userAccountPermissions.admin',
    defaultMessage: 'Admin',
  },
  manager: {
    id: 'userAccountPermissions.manager',
    defaultMessage: 'Manager',
  },
  viewer: {
    id: 'userAccountPermissions.viewer',
    defaultMessage: 'Viewer',
  },
  fullAccess: {
    id: 'userAccountPermissions.fullAccess',
    defaultMessage: 'Full Access',
  },
  restrictedAccess: {
    id: 'userAccountPermissions.restrictedAccess',
    defaultMessage: 'Restricted Access',
  },
  noAccess: {
    id: 'userAccountPermissions.noAccess',
    defaultMessage: 'No Access',
  },
  viewOnly: {
    id: 'userAccountPermissions.viewOnly',
    defaultMessage: 'View Only',
  },
  generalAccessSettings: {
    id: 'userAccountPermissions.generalAccessSettings',
    defaultMessage: 'General Access Settings',
  },
  clientDataAccessSettings: {
    id: 'userAccountPermissions.clientDataAccessSettings',
    defaultMessage: 'Client Data Access Settings',
  },
  featurePermission: {
    id: 'userAccountPermissions.featurePermission',
    defaultMessage: 'Feature Permission',
  },
  accessClientTab: {
    id: 'userAccountPermissions.accessClientTab',
    defaultMessage: 'Access Client Tab',
  },
  accessMarketingTools: {
    id: 'userAccountPermissions.accessMarketingTools',
    defaultMessage: 'Access Marketing Tools',
  },
  marketingToolsViewerMode: {
    id: 'userAccountPermissions.marketingToolsViewerMode',
    defaultMessage: 'Marketing Tools Viewer Mode',
  },
  accessPrivateLine: {
    id: 'userAccountPermissions.accessClientTab',
    defaultMessage: 'Access Private Line',
  },
  addRemoveClientTagsClientProfiles: {
    id: 'userAccountPermissions.addRemoveClientTagsClientProfiles',
    defaultMessage: 'Add/remove client tags from client profiles',
  },
  administrateGeneralSettings: {
    id: 'userAccountPermissions.administrateGeneralSettings',
    defaultMessage: 'Administrate General Settings',
  },
  generalSettingsMarketingMode: {
    id: 'userAccountPermissions.generalSettingsMarketingMode',
    defaultMessage: 'General Settings Marketing Mode',
  },
  automatedFeedbackSummary: {
    id: 'userAccountPermissions.automatedFeedbackSummary',
    defaultMessage: 'Automated Feedback Summary',
  },
  CreateEditEmailCampaignsGlobalTags: {
    id: 'userAccountPermissions.CreateEditEmailCampaignsGlobalTags',
    defaultMessage: 'Create/Edit Email Campaigns with Global Tags',
  },
  exportClientData: {
    id: 'userAccountPermissions.exportClientData',
    defaultMessage: 'Export Client Data',
  },
  manageClientsTagsLists: {
    id: 'userAccountPermissions.manageClientsTagsLists',
    defaultMessage: "Manage client's tags lists",
  },
  manageReservationTagsLists: {
    id: 'userAccountPermissions.manageReservationTagsLists',
    defaultMessage: 'Manage reservation tags lists',
  },
  manageGlobalAutoTags: {
    id: 'userAccountPermissions.manageGlobalAutoTags',
    defaultMessage: 'Manage global auto-tags',
  },
  viewAllClientsContactInfo: {
    id: 'userAccountPermissions.viewAllClientsContactInfo',
    defaultMessage: "View all client's contact info",
  },
  viewClientSpendAggregatesData: {
    id: 'userAccountPermissions.viewClientSpendAggregatesData',
    defaultMessage: 'View Client Spend Aggregates Data',
  },
  viewClientsAssociatedGroup: {
    id: 'userAccountPermissions.viewClientsAssociatedGroup',
    defaultMessage: 'View clients associated with all venues in the Group',
  },
  viewPOSCheckData: {
    id: 'userAccountPermissions.viewPOSCheckData',
    defaultMessage: 'View POS Check Data',
  },
  viewReportingFull: {
    id: 'userAccountPermissions.viewReportingFull',
    defaultMessage: 'View Reporting',
  },
  writeMessagesInPrivateLine: {
    id: 'userAccountPermissions.writeMessagesInPrivateLine',
    defaultMessage: 'Write messages in Private Line',
  },
  administrateUserAccess: {
    id: 'userAccountPermissions.administrateUserAccess',
    defaultMessage: 'Administrate User Access',
  },
  administrateWidgetSettings: {
    id: 'userAccountPermissions.administrateWidgetSettings',
    defaultMessage: 'Administrate Widget Settings',
  },
} as const)
