import { defineMessages } from '@sevenrooms/core/locales'

export const ExperiencesLocales = defineMessages({
  experiencesTitle: {
    id: 'experiences.title',
    defaultMessage: 'Offers',
  },
  offersFeesTitle: {
    id: 'experiences.offersFeesTitle',
    defaultMessage: 'Private Event Fees',
  },
  offersFeesDescription: {
    id: 'experiences.offersFeesDescription',
    defaultMessage: 'Fees applied to this space',
  },
  offersDirectory: {
    id: 'experiences.offersDirectory',
    defaultMessage: 'Offers Directory',
  },
  createNew: {
    id: 'experiences.createNew',
    defaultMessage: 'Create New Offer',
  },
  featuredOffer: {
    id: 'experiences.featuredOffer',
    defaultMessage: 'Featured Offer',
  },
  markAsFeatured: {
    id: 'experiences.markAsFeatured',
    defaultMessage: 'Mark as Featured',
  },
  removeAsFeatured: {
    id: 'experiences.removeAsFeatured',
    defaultMessage: 'Remove as Featured',
  },
  createLink: {
    id: 'experiences.createLink',
    defaultMessage: 'Create Link',
  },
  activeDescription: {
    id: 'experiences.activeDescription',
    defaultMessage: 'Offers will be displayed in this order on the guest-facing Offers Directory.',
  },
  wouldYouLikeToCopyLinkForThisOffer: {
    id: 'experiences.wouldYouLikeToCopyLinkForThisOffer',
    defaultMessage: 'Would you like to copy a link for this offer?',
  },
  whatTypeOfLinkDoYouWantToCreate: {
    id: 'experiences.whatTypeOfLinkDoYouWantToCreate',
    defaultMessage: 'What type of link do you want to create?',
  },
  openAccess: {
    id: 'experiences.openAccess',
    defaultMessage: 'Open Access',
  },
  createdBy: {
    id: 'experiences.createdBy',
    defaultMessage: '{type} • Created by: {userName} on {createdOn}',
  },
  exclusiveAccess: {
    id: 'experiences.exclusiveAccess',
    defaultMessage: 'Exclusive Access',
  },
  haveNotSetUpEvent: {
    id: 'experiences.haveNotSetUpEvent',
    defaultMessage: "Haven't set up your Event for this Offer yet? You can do that",
  },
  haveNotSetUpAccessRule: {
    id: 'experiences.haveNotSetUpAccessRule',
    defaultMessage: "Haven't set up your Access Rules for {offerType} yet? You can do that",
  },
  thisOffer: {
    id: 'experiences.thisOffer',
    defaultMessage: 'this Offer',
  },
  yourOffers: {
    id: 'experiences.yourOffers',
    defaultMessage: 'your Offers',
  },
  openAccessDescription: {
    id: 'experiences.openAccessDescription',
    defaultMessage:
      "Perfect to use in social media posts, email blasts and web pages on your website. This landing page will show availability based on Access Rules with {offerType} and the 'Widget' audience selected.",
  },
  exclusiveAccessDescription: {
    id: 'experiences.exclusiveAccessDescription',
    defaultMessage:
      "Perfect to use for campaigns that target a specific group of guests, such as your Regulars or VIPs. This landing page will show availability based on Access Rules with {offerType} and the '7X Exclusive' audience selected.",
  },
  anyActiveOffer: {
    id: 'experiences.anyActiveOffer',
    defaultMessage: 'any active offer',
  },
  activeExperienceName: {
    id: 'experiences.activeExperienceName',
    defaultMessage: '{name} experience',
  },
  copyLink: {
    id: 'experiences.copyLink',
    defaultMessage: 'Copy Link',
  },
  draft: {
    id: 'experiences.draft',
    defaultMessage: 'Draft',
  },
  createOffer: {
    id: 'experiences.createOffer',
    defaultMessage: 'Create Offer',
  },
  offerDetail: {
    id: 'experiences.offerDetail',
    defaultMessage: 'Offer Details',
  },
  offerOverview: {
    id: 'experiences.offerOverview',
    defaultMessage: 'Offer Overview',
  },
  images: {
    id: 'experiences.images',
    defaultMessage: 'Images',
  },
  headerImage: {
    id: 'experiences.headerImage',
    defaultMessage: 'Header Image',
  },
  headerImageSize: {
    id: 'experience.headerImageSize',
    defaultMessage: 'Size should be less than 2MB',
  },
  addAlternativeImages: {
    id: 'experience.addAlternativeImages',
    defaultMessage: 'Add Alternative Images (Up to 8)',
  },
  addImage: {
    id: 'experience.addImage',
    defaultMessage: 'Add Image',
  },
  choseType: {
    id: 'experience.choseType',
    defaultMessage: 'What type of Offer do you want to create?',
  },
  experienceTypeDescription: {
    id: 'experience.experienceTypeDescription',
    defaultMessage: 'Promote unique reasons to dine with you — like a chef’s table dinner or weekly jazz brunch',
  },
  experience: {
    id: 'experience.experience',
    defaultMessage: 'Experience',
  },
  eventTypeDescription: {
    id: 'experience.eventTypeDescription',
    defaultMessage: 'Leverage the power of the SevenRooms Event Widget for guestlist and ticketed events.',
  },
  event: {
    id: 'experience.event',
    defaultMessage: 'Event',
  },
  theFork: {
    id: 'experience.theFork',
    defaultMessage: 'TheFork Restaurant Bookable Preset Menu',
  },
  theForkName: {
    id: 'experience.theForkName',
    defaultMessage: 'Name',
  },
  theForkDescription: {
    id: 'experience.theForkDescription',
    defaultMessage: 'Allow diners booking on theFork to select a specific preset menu.',
  },
  theForkDetails: {
    id: 'experience.theForkDetails',
    defaultMessage: 'TheFork Details',
  },
  pdr: {
    id: 'experience.pdr',
    defaultMessage: 'Group Bookings',
  },
  pdrDescription: {
    id: 'experience.pdrDescription',
    defaultMessage: 'Allow guests to book large parties and private events directly online.',
  },
  pdrDetails: {
    id: 'experience.pdrDetails',
    defaultMessage: 'Space Details',
  },
  pdrSpaceDescription: {
    id: 'experiences.pdrSpaceDescription',
    defaultMessage: 'Space Description',
  },
  pdrHeaderImageSizeDescription: {
    id: 'experience.pdrHeaderImageSizeDescription',
    defaultMessage: 'Primary space image. Size should be 1500px x 750px and under 2MB.',
  },
  pdrAddAlternativeImages: {
    id: 'experience.pdrAddAlternativeImages',
    defaultMessage: 'Add Alternative Images (Up to 6)',
  },
  pdrTables: {
    id: 'experience.pdrTables',
    defaultMessage: 'Table',
  },
  pdrMinimumSpendMatrixAddSlot: {
    id: 'experience.pdrTables',
    defaultMessage: 'Add Another Slot',
  },
  pdrMinimumSpendMatrixBaseBoxTitle: {
    id: 'experience.pdrMinimumSpendMatrixBaseBoxTitle',
    defaultMessage: 'Minimum Spend',
  },
  pdrTripleSeatIntegrationBoxTitle: {
    id: 'experience.pdrTripleSeatIntegrationBoxTitle',
    defaultMessage: 'TripleSeat Integration',
  },
  pdrDepositFeeBoxTitle: {
    id: 'experience.pdrDepositFeeBoxTitle',
    defaultMessage: 'Deposits',
  },
  pdrFeesBoxTitle: {
    id: 'experience.pdrFeesBoxTitle',
    defaultMessage: 'Group Booking Fees',
  },
  events: {
    id: 'experience.events',
    defaultMessage: 'Events',
  },
  offerName: {
    id: 'experience.offerName',
    defaultMessage: 'Offer Name',
  },
  privateDiningName: {
    id: 'experience.privateDiningName',
    defaultMessage: 'Space Name',
  },
  privateDiningNameDescription: {
    id: 'experience.privateDiningNameDescription',
    defaultMessage: "This is how you'll refer to this group bookings space internally and externally.",
  },
  theForkDescriptionTitle: {
    id: 'experience.theForkDescriptionTitle',
    defaultMessage: 'Description Title',
  },
  pdrName: {
    id: 'experience.pdrName',
    defaultMessage: 'Name',
  },
  pdrSquareFootage: {
    id: 'experience.pdrSquareFootage',
    defaultMessage: 'Size of Space',
  },
  pdrSquareFootagePlaceholder: {
    id: 'experience.pdrSquareFootagePlaceholder',
    defaultMessage: 'ex. 350',
  },
  unitOfMeasurement: {
    id: 'experience.unitOfMeasurement',
    defaultMessage: 'Unit of Measurement',
  },
  pdrUnitOfMeasurement: {
    id: 'experience.pdrUnitOfMeasurement',
    defaultMessage: 'Unit of Measure for Size of Space',
  },
  pdrMinStandingCapacity: {
    id: 'experience.pdrMinStandingCapacity',
    defaultMessage: 'Min Standing Capacity',
  },
  pdrMaxStandingCapacity: {
    id: 'experience.pdrMaxStandingCapacity',
    defaultMessage: 'Max Standing Capacity',
  },
  pdrMinSeatedCapacity: {
    id: 'experience.pdrMinSeatedCapacity',
    defaultMessage: 'Min Seated Capacity',
  },
  pdrMaxSeatedCapacity: {
    id: 'experience.pdrMaxSeatedCapacity',
    defaultMessage: 'Max Seated Capacity',
  },
  pdrRoomType: {
    id: 'experience.pdrRoomType',
    defaultMessage: 'Room Type',
  },
  pdrAmenities: {
    id: 'experience.pdrAmenities',
    defaultMessage: 'Amenities',
  },
  pdrCode: {
    id: 'experience.pdrCode',
    defaultMessage: 'Code',
  },
  pdrAdminFee: {
    id: 'experience.pdrAdminFee',
    defaultMessage: 'Admin fee',
  },
  pdrTripleSeatRoomId: {
    id: 'experience.pdrTripleSeatRoomId',
    defaultMessage: 'Triple Seat Room Id',
  },
  pdrDepositFee: {
    id: 'experience.pdrDepositFee',
    defaultMessage: 'Deposit Fee (%)',
  },
  pdrTaxRateId: {
    id: 'experience.pdrTaxRateId',
    defaultMessage: 'Apply Tax',
  },
  pdrStartDay: {
    id: 'experience.pdrStartDay',
    defaultMessage: 'Start Day',
  },
  pdrEndDay: {
    id: 'experience.pdrEndDay',
    defaultMessage: 'End Day',
  },
  pdrStartTime: {
    id: 'experience.pdrStartTime',
    defaultMessage: 'Start Time',
  },
  pdrEndTime: {
    id: 'experience.pdrEndTime',
    defaultMessage: 'End Time',
  },
  pdrDefaultMinSpend: {
    id: 'experience.pdrDefaultMinSpend',
    defaultMessage: 'Default Minimum Spend',
  },
  pdrDefaultMinSpendHelper: {
    id: 'experience.pdrDefaultMinSpendHelper',
    defaultMessage: 'This value will be used if there is no time slot match.',
  },
  pdrMinSpend: {
    id: 'experience.pdrMinSpend',
    defaultMessage: 'Min Spend Value',
  },
  pdrMinimumSpendMatrixLabel: {
    id: 'experience.pdrMinimumSpendMatrixLabel',
    defaultMessage: 'Minimum Spend Matrix',
  },
  offerNameDescription: {
    id: 'experience.offerNameDescription',
    defaultMessage: "This is how you'll refer to this offer internally and externally",
  },
  enterName: {
    id: 'experience.enterName',
    defaultMessage: 'Enter Name',
  },
  menu: {
    id: 'experience.menu',
    defaultMessage: 'Menu',
  },
  menuSupportedFiles: {
    id: 'experience.menuSupportedFiles',
    defaultMessage: 'Supported file types: JPG, PNG, doc, PDF, CSV',
  },
  menuDescription: {
    id: 'experience.menuDescription',
    defaultMessage:
      'The file name will be visible to guests, so use a name that represents what’s inside the file. Supported file types: JPG, PNG, doc, PDF, CSV',
  },
  defaultPartySize: {
    id: 'experience.defaultPartySize',
    defaultMessage: 'Default Party Size',
  },
  hidePrice: {
    id: 'experience.hidePrice',
    defaultMessage: "Don't show price",
  },
  price: {
    id: 'experience.price',
    defaultMessage: 'Price',
  },
  descriptionTitle: {
    id: 'experience.descriptionTitle',
    defaultMessage: 'Description Title',
  },
  descriptionBody: {
    id: 'experience.descriptionBody',
    defaultMessage: 'Description Body',
  },
  displayOffer: {
    id: 'experience.displayOffer',
    defaultMessage: 'Display Offer in Offers Directory?',
  },
  themeLabel: {
    id: 'experience.themeLabel',
    defaultMessage: 'What landing page theme would you like to use?',
  },
  publishOffer: {
    id: 'experience.publishOffer',
    defaultMessage: 'Publish Offer',
  },
  publishSpace: {
    id: 'experience.publishOffer',
    defaultMessage: 'Publish Space',
  },
  experienceAPIError: {
    id: 'experience.experienceUpdateError',
    defaultMessage: 'Something went wrong, please try again or contact the support',
  },
  experienceUpdated: {
    id: 'experience.experienceUpdated',
    defaultMessage: '{name} is successfully saved.',
  },
  experienceCreated: {
    id: 'experience.experienceCreated',
    defaultMessage: '{name} has been successfully created.',
  },
  loadingOffers: {
    id: 'experience.loadingOffers',
    defaultMessage: 'Loading Offers',
  },
  associatedAccessRules: {
    id: 'experience.associatedAccessRules',
    defaultMessage: 'Associated Access Rules',
  },
  accessRuleWarning: {
    id: 'experience.accessRuleWarning',
    defaultMessage: 'You can only link Access Rules to an active Offer',
  },
  accessRuleName: {
    id: 'experience.accessRuleName',
    defaultMessage: 'Access Rules Name',
  },
  accessRuleSchedule: {
    id: 'experience.accessRuleSchedule',
    defaultMessage: 'Schedule',
  },
  accessRulePartySize: {
    id: 'experience.accessRulePartySize',
    defaultMessage: 'Party Size',
  },
  linkAccessRule: {
    id: 'experience.linkAccessRule',
    defaultMessage: 'Link Access Rule',
  },
  rememberToLinkAccessRule: {
    id: 'experience.rememberToLinkAccessRule',
    defaultMessage: 'The Offer will not be available until you link an Access Rule',
  },
  link: {
    id: 'experience.accessRule.link',
    defaultMessage: 'link',
  },
  linkOfferText: {
    id: 'experience.accessRule.linkOfferText',
    defaultMessage: 'Link this Offer to an existing Access Rule to make it bookable online',
  },
  linkOfferToEventText: {
    id: 'experience.accessRule.linkOfferText',
    defaultMessage: 'Link this Offer to existing Events',
  },
  saveChangesToComplete: {
    id: 'experience.accessRule.saveChangesToComplete',
    defaultMessage: 'Save Changes to complete the link to this Access Rule.',
  },
  attachedAccessRulesCount: {
    id: 'experience.attachedAccessRules',
    defaultMessage: '{count} Access Rules attached',
  },
  attachedOneAccessRule: {
    id: 'experience.attachedOneAccessRule',
    defaultMessage: '1 Access Rule attached',
  },
  noAttachedAccessRulesAttached: {
    id: 'experience.noAttachedAccessRulesAttached',
    defaultMessage: 'No Access Rules attached',
  },
  attachedEventsCount: {
    id: 'experience.attachedEventsCount',
    defaultMessage: '{count} Events attached',
  },
  attachedOneEvent: {
    id: 'experience.attachedOneEvent',
    defaultMessage: '1 Event attached',
  },
  noAttachedEventsAttached: {
    id: 'experience.noAttachedEventsAttached',
    defaultMessage: 'No Event attached',
  },
  fieldLengthError: {
    id: 'experience.fieldLengthError',
    defaultMessage: 'Should be at least {minSize} character',
  },
  fieldValueMinError: {
    id: 'experience.fieldValueMinError',
    defaultMessage: 'Should be at least {min}',
  },
  noOffersTitle: {
    id: 'experience.noOffersTitle',
    defaultMessage: 'No Offers have been created yet',
  },
  emptyOffersNoData: {
    id: 'experience.emptyOffersNoData',
    defaultMessage: 'Get started by',
  },
  emptyOffersLinkNoData: {
    id: 'experience.emptyOffersLinkNoData',
    defaultMessage: 'creating an offer',
  },
  associatedEvents: {
    id: 'experience.associatedEvents',
    defaultMessage: 'Associated Events',
  },
  eventWarning: {
    id: 'experience.eventWarning',
    defaultMessage: 'You can only link Events to an active Offer',
  },
  rememberToLinkEvent: {
    id: 'experience.rememberToLinkEvent',
    defaultMessage: 'Remember to link Event',
  },
  linkEvent: {
    id: 'experience.linkEvent',
    defaultMessage: 'Link Event',
  },
  eventName: {
    id: 'experience.eventName',
    defaultMessage: 'Event Name',
  },
  eventSchedule: {
    id: 'experience.eventSchedule',
    defaultMessage: 'Schedule',
  },
  saveChangesToCompleteEvent: {
    id: 'experience.event.saveChangesToComplete',
    defaultMessage: 'Save Changes to complete the link to this Event.',
  },
  bookStatus: {
    id: 'experience.theFork.bookStatus',
    defaultMessage: 'Booking Status',
  },
  notBookable: {
    id: 'experience.theFork.bookStatus',
    defaultMessage: 'Not Bookable',
  },
  allowEarlyPreviewInfo: {
    id: 'experience.theFork.allowEarlyPreviewInfo',
    defaultMessage: 'Allow guests to preview this menu on The Fork before the booking start date.',
  },
  allowEarlyPreview: {
    id: 'experience.theFork.allowEarlyPreview',
    defaultMessage: 'Allow early menu preview',
  },
  previewStartDate: {
    id: 'experience.theFork.previewStartDate',
    defaultMessage: 'Preview Start Date*',
  },
  addMenuCourse: {
    id: 'experience.theFork.addMenuCourse',
    defaultMessage: '+ Add Menu Course',
  },
  customMenuCourseNameLabel: {
    id: 'experience.theFork.customMenuCourseName',
    defaultMessage: 'Custom Menu Course Name',
  },
  customMenuCourseNamePlaceHolder: {
    id: 'experience.theFork.customMenuCourseNamePlaceHolder',
    defaultMessage: 'Enter name',
  },
  menuCourseLabel: {
    id: 'experience.theFork.menuCourseLabel',
    defaultMessage: 'Menu Course ',
  },
  menuItemsLabel: {
    id: 'experience.theFork.menuItemLabel',
    defaultMessage: 'Menu Item(s)*',
  },
  addMenuItem: {
    id: 'experience.theFork.addMenuItem',
    defaultMessage: '+ Add Menu Item',
  },
  cancelEditWarning: {
    id: 'experience.cancelEditWarning',
    defaultMessage: 'Are you sure you want to leave without saving changes?',
  },
  backToEditing: {
    id: 'campaignBuilder.backToEditing',
    defaultMessage: 'Back to Editing',
  },
  exitWithoutSaving: {
    id: 'campaignBuilder.exitWithoutSaving',
    defaultMessage: 'Exit Without Saving',
  },
  createNewAR: {
    id: 'experience.createNewAR',
    defaultMessage: 'Create New Access Rule',
  },
} as const)
