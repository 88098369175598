import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { PacingLocales } from '../Pacing.locales'
import { CustomPacing } from './CustomPacing'

export function DefaultPacingSettings() {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()

  return (
    <DefaultSettingsReportSection>
      <Report>
        {shifts.map((shift: Shift) => (
          <ReportPart key={shift.id} caption={[shift.name, formatMessage(PacingLocales.maxCoversPerSeatingInterval)]}>
            <ReportLine name={formatMessage(PacingLocales.defaultPacingLabel)}>{shift.defaultPacing}</ReportLine>
            {shift.customPacing && <CustomPacing pacing={shift.customPacing} />}
          </ReportPart>
        ))}
      </Report>
    </DefaultSettingsReportSection>
  )
}
