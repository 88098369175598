import type { POSILocation } from '@sevenrooms/core/domain'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import type { LocationSelectionCardFormField } from './LocationSelectionCardForm.zod'

export interface LocationSelectionCardProps {
  field: LocationSelectionCardFormField
  locations: POSILocation[]
  disabled: boolean
}

export const locationSelectOption = (location: POSILocation): SelectOption => ({
  id: location.id,
  label: location.name,
})
