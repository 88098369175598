import React from 'react'
import { type Perk, PerkAccessTypeEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'

interface PerkDeleteModalProps {
  closeHref: string
  onSubmit: () => void
  onClose: () => void
  isLoading?: boolean
  tagOptions?: TagOption[]
  perk: Perk
}

export function PerkDeleteModal({ onClose, onSubmit, isLoading = false, tagOptions = [], perk, closeHref }: PerkDeleteModalProps) {
  const { formatMessage } = useLocales()
  const {
    perkArchiveTitle,
    perkArchiveRemoveFromTagsDescription,
    perkArchiveArchiveAssociatedAccessRulesDescription,
    perkArchiveUpdateAssociatedAccessRulesDescription,
    confirmArchive,
  } = perksMessages
  const autoTagNames = tagOptions.map(tagOption => tagOption.label).join(', ')
  const isAssociatedAccessRules = perk?.associatedAccessRulesData && Object.keys(perk.associatedAccessRulesData).length > 0

  return (
    <Modal ariaLabel={formatMessage(perkArchiveTitle)}>
      <ModalHeader closeHref={closeHref} onClick={onClose}>
        <VStack spacing="s">
          <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(perkArchiveTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        {tagOptions.length > 0 && <Text>{formatMessage(perkArchiveRemoveFromTagsDescription, { autoTagNames })}</Text>}{' '}
        {isAssociatedAccessRules && perk.perkType === PerkAccessTypeEnum.EXCLUSIVE_ACCESS && (
          <Text>{formatMessage(perkArchiveArchiveAssociatedAccessRulesDescription)}</Text>
        )}
        {isAssociatedAccessRules && perk.perkType === PerkAccessTypeEnum.EARLY_ACCESS && (
          <Text>{formatMessage(perkArchiveUpdateAssociatedAccessRulesDescription)}</Text>
        )}
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button data-test="perk-delete-cancel-button" variant="tertiary" href={closeHref} onClick={onClose}>
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button data-test="perk-delete-confirm-button" variant="primary" onClick={onSubmit} disabled={isLoading}>
            {formatMessage(confirmArchive)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
