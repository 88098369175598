import { useMemo } from 'react'
import { ContactInfoEnum, TallyEnum, SeatingAreaEnum, type VenueSettingsOptions, WaitlistEnabledOptionEnum } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Checkbox, type CheckboxChoice, CheckboxGroup, Label, type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, SettingsSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { SectionBox } from '../components/SectionBox'
import { venueSettingsMessages } from '../VenueSettings.locales'
import { reservationsMessages } from './Reservations.locales'
import type {
  ReservationsNightlifeForm,
  ContactInfoNightlifeValues,
  GlistTallyValues,
  ChargeTallyValues,
} from './ReservationsNightlife.zod'

export interface ReservationsNightlifeProps {
  field: Field<ReservationsNightlifeForm>
  options: VenueSettingsOptions
}

export function ReservationsNightlife({ field, options }: ReservationsNightlifeProps) {
  const { formatMessage } = useLocales()

  const contactInfo = useMemo(
    (): Array<CheckboxChoice<ContactInfoNightlifeValues>> => [
      { value: ContactInfoEnum.phone, label: formatMessage(reservationsMessages.phone) },
      { value: ContactInfoEnum.email, label: formatMessage(reservationsMessages.email) },
      { value: ContactInfoEnum.phoneoremail, label: formatMessage(reservationsMessages.phoneOrEmail) },
      { value: ContactInfoEnum.lastname, label: formatMessage(reservationsMessages.lastName) },
      { value: ContactInfoEnum.arrivalTime, label: formatMessage(reservationsMessages.arrivalTime) },
      { value: ContactInfoEnum.seatingArea, label: formatMessage(reservationsMessages.seatingArea) },
      { value: ContactInfoEnum.salutation, label: formatMessage(reservationsMessages.salutation) },
    ],
    [formatMessage]
  )

  const glistTallyChoices = useMemo(
    (): Array<RadioChoice<GlistTallyValues>> => [
      { value: TallyEnum.genderCover, label: formatMessage(reservationsMessages.byGenderCover) },
      { value: TallyEnum.cover, label: formatMessage(reservationsMessages.byCover) },
      { value: TallyEnum.no, label: formatMessage(reservationsMessages.no) },
    ],
    [formatMessage]
  )

  const chargeTallyChoices = useMemo(
    (): Array<RadioChoice<ChargeTallyValues>> => [
      { value: TallyEnum.genderCover, label: formatMessage(reservationsMessages.byGenderCover) },
      { value: TallyEnum.gender, label: formatMessage(reservationsMessages.byGender) },
    ],
    [formatMessage]
  )

  const waitlistIntervalOptions = useMemo(
    (): SelectOption[] =>
      options.waitlistWaitInterval.map(option => ({
        id: String(option),
        label: formatMessage(commonMessages.minutes, { count: option }),
      })),
    [formatMessage, options.waitlistWaitInterval]
  )

  const seatingAreaChoices = useMemo(
    (): Array<RadioChoice<string>> => [
      {
        value: SeatingAreaEnum.ALL,
        label: formatMessage(reservationsMessages.seatingAreaAll),
      },
      ...options.seatingAreas,
    ],
    [formatMessage, options.seatingAreas]
  )

  const etaFromValue = field.prop('etaFrom').watch()
  const startDayValue = field.prop('startDay').watch()

  const optionsTimeByStartDay = useMemo(() => {
    const index = options.time.findIndex(option => option.id === startDayValue)
    return index > -1 ? options.time.slice(index) : options.time
  }, [options.time, startDayValue])

  if (optionsTimeByStartDay[0] && optionsTimeByStartDay.findIndex(option => option.id === etaFromValue) < 0) {
    field.prop('etaFrom').set(optionsTimeByStartDay[0].id)
  }

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationBooking)}>
        <VStack spacing="s" mb="l">
          <Label
            primary={formatMessage(reservationsMessages.contactInfoTitle)}
            secondary={formatMessage(reservationsMessages.contactInfoDescription)}
          />
          <CheckboxGroup field={field.prop('contactInfo')} data-test="contactInfo" choices={contactInfo} />
        </VStack>
        <Box mb="l">
          <Label
            primary={formatMessage(reservationsMessages.reservationsEtaTitle)}
            secondary={formatMessage(reservationsMessages.reservationsEtaDescription)}
          />
          <HStack spacing="s" alignItems="flex-end">
            <Label primary={formatMessage(reservationsMessages.reservationsEtaFrom)}>
              <FormSelect
                options={optionsTimeByStartDay}
                searchable={false}
                hideErrorMessage
                field={field.prop('etaFrom')}
                data-test="etaFrom"
              />
            </Label>
            <Label primary={formatMessage(reservationsMessages.reservationsEtaTo)}>
              <FormSelect options={options.time} searchable={false} hideErrorMessage field={field.prop('etaTo')} data-test="etaTo" />
            </Label>
            <Label primary={formatMessage(reservationsMessages.reservationsEtaInterval)}>
              <FormSelect
                options={options.allowableEta}
                searchable={false}
                hideErrorMessage
                field={field.prop('etaInterval')}
                data-test="etaInterval"
              />
            </Label>
          </HStack>
        </Box>
        <Box mb="sm">
          <SettingsSection
            collapsible
            defaultCollapsed
            size="s"
            memoCollapseState="generalSettingsReservationsNightlife"
            data-test="reservationsNightlifeSection"
            title={formatMessage(venueSettingsMessages.additionalConfig)}
          >
            <Box pt="m">
              <Checkbox
                field={field.prop('multiReservation')}
                data-test="multiReservation"
                description={formatMessage(reservationsMessages.multiReservationDescription)}
              >
                {formatMessage(reservationsMessages.multiReservationTitle)}
              </Checkbox>
            </Box>
          </SettingsSection>
        </Box>
      </SectionBox>
      <SectionBox halfsize title={formatMessage(reservationsMessages.sectionInternalConfigurations)}>
        <Label
          primary={formatMessage(reservationsMessages.startDayTitle)}
          secondary={formatMessage(reservationsMessages.startDayDescription)}
        >
          <HStack>
            <FormSelect
              options={options.timeHour}
              searchable={false}
              hideErrorMessage
              field={field.prop('startDay')}
              data-test="startDay"
            />
          </HStack>
        </Label>
        <Box pt="l">
          <Label
            primary={formatMessage(reservationsMessages.seatingAreaTitle)}
            secondary={formatMessage(reservationsMessages.seatingAreaDescription)}
          />
          <RadioGroup choices={seatingAreaChoices} field={field.prop('defaultSeatingArea')} data-test="defaultSeatingArea" />
        </Box>
        <Box pt="l">
          <Checkbox
            field={field.prop('showCovers')}
            data-test="showCovers"
            description={formatMessage(reservationsMessages.showCoversDescription)}
          >
            {formatMessage(reservationsMessages.showCoversTitle)}
          </Checkbox>
        </Box>
        <Box pt="l">
          <Label
            primary={formatMessage(reservationsMessages.glistTallyTitle)}
            secondary={formatMessage(reservationsMessages.glistTallyDescription, formatItalic)}
          />
          <RadioGroup choices={glistTallyChoices} field={field.prop('glistTally')} data-test="glistTally" />
        </Box>
        <Box pt="l">
          <Label
            primary={formatMessage(reservationsMessages.chargeTallyTitle)}
            secondary={formatMessage(reservationsMessages.chargeTallyDescription, formatItalic)}
          />
          <RadioGroup choices={chargeTallyChoices} field={field.prop('chargeTally')} data-test="chargeTally" />
        </Box>
      </SectionBox>
      {options.waitlistEnabled !== WaitlistEnabledOptionEnum.DISABLED && (
        <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationWaitTime)}>
          <Label
            primary={formatMessage(reservationsMessages.waitTimeOptsTitle)}
            secondary={formatMessage(reservationsMessages.waitTimeOptsDescription)}
          />
          <HStack spacing="s">
            <Label primary={formatMessage(reservationsMessages.maxWaitTimeTitle)}>
              <FormSelect
                options={options.waitlistWaitTime}
                searchable={false}
                hideErrorMessage
                field={field.prop('maxWaitTime')}
                data-test="maxWaitTime"
              />
            </Label>
            <Label
              primary={formatMessage(reservationsMessages.withIntervalTitle)}
              info={
                <VStack spacing="s">
                  <Box>
                    <Text color="lightFont" fontWeight="bold">
                      {formatMessage(reservationsMessages.withIntervalInfoTitle)}
                    </Text>
                  </Box>
                  <Box>{formatMessage(reservationsMessages.withIntervalInfo)}</Box>
                </VStack>
              }
              data-test="withIntervalTitle"
            >
              <FormSelect
                options={waitlistIntervalOptions}
                searchable={false}
                hideErrorMessage
                field={field.prop('withInterval')}
                data-test="withInterval"
              />
            </Label>
          </HStack>
          <Box pt="l">
            <Label
              primary={formatMessage(reservationsMessages.autoRemoveTimingTitle)}
              secondary={formatMessage(reservationsMessages.autoRemoveTimingDescription)}
            >
              <HStack>
                <FormSelect
                  options={options.waitlistAutoRemoveTime}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('autoRemoveTiming')}
                  data-test="autoRemoveTiming"
                />
              </HStack>
            </Label>
          </Box>
        </SectionBox>
      )}
    </VStack>
  )
}

const formatItalic = {
  i: (chunks: never) => (
    <SecondaryText fontStyle="italic">
      <br />
      {chunks}
    </SecondaryText>
  ),
}
