import type { AccessRule, AccessRules, ShiftsByDate } from '@sevenrooms/core/domain'
import { AccessRulesDataGrid } from '../../components/AccessRulesDataGrid/AccessRulesDataGrid'
import { columns, rows } from './demoData'

interface GroupedAccessListProps {
  shifts: ShiftsByDate | undefined
  accessRules: AccessRules | AccessRule[] | undefined
}

function AccessRulesList({ shifts, accessRules }: GroupedAccessListProps) {
  // TOD0: Implement logic to manipulate and structure accessrules and shift data to create our columns and rows
  return <AccessRulesDataGrid columns={columns} rows={rows} groupBy="shiftCategory" shifts={shifts} accessRules={accessRules} />
}

export { AccessRulesList }
