import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { generateTimeSlots } from '../../shared'
import { ScheduleLocales } from '../Schedule.locales'
import { ScheduleTestId } from '../Schedule.testIds'
import { ShiftRestrictable } from './ShiftRestrictable'
import type { ScheduleProps } from '../ScheduleProps'

export function SpecificTimeSlotsSelector(props: ScheduleProps) {
  const { field } = props
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useAppContext().venueSettings

  const timeSlots = useMemo(() => generateTimeSlots(startOfDayTime), [startOfDayTime])

  return (
    <ShiftRestrictable {...props}>
      <FormMultiSelect
        data-test={ScheduleTestId.specificTimesMultiSelect}
        field={field.prop('specificTimes')}
        options={timeSlots}
        useCheckboxItems
        searchable
        placeholder={formatMessage(ScheduleLocales.searchTimeSlots)}
      />
    </ShiftRestrictable>
  )
}
