import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { MultiSelectTagOptionsForm, Category } from '@sevenrooms/core/ui-kit/form'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { AccessRuleSlideoutSection, DefaultSettingsReportSection, useAccessRuleContext } from '../shared'
import { CollapsedReservationTags, ReservationTagsEdit } from './components'
import { ReservationTagsLocales } from './ReservationTags.locales'
import { ReservationTagsTestId } from './ReservationTags.testIds'
import type { TagGroup } from '../../AccessRule.types'

export interface ReservationTagsProps {
  field: Field<MultiSelectTagOptionsForm>
}

export function ReservationTags({ field }: ReservationTagsProps) {
  const { tagGroups } = useAccessRuleContext()
  const { formatMessage } = useLocales()
  const formattedCategories = useMemo(() => getFormattedCategories(tagGroups), [tagGroups])

  const defaultSettings = (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart>
          <ReportLine name={formatMessage(ReservationTagsLocales.label)}>{formatMessage(commonMessages.none)}</ReportLine>
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )

  return (
    <AccessRuleSlideoutSection
      data-test={ReservationTagsTestId.section}
      title={formatMessage(ReservationTagsLocales.title)}
      description={formatMessage(ReservationTagsLocales.description)}
      subCaption={formatMessage(ReservationTagsLocales.subCaption)}
      contentWhenCollapsed={<CollapsedReservationTags field={field} categories={formattedCategories} />}
      defaultSettings={defaultSettings}
      sectionControllerName="reservationTags"
      field={field}
    >
      <ReservationTagsEdit field={field} categories={formattedCategories} />
    </AccessRuleSlideoutSection>
  )
}

function getFormattedCategories(tagGroups: Map<string, TagGroup>): Category[] {
  const categories: Category[] = []
  tagGroups.forEach(tagGroup =>
    categories.push({
      id: tagGroup.id,
      name: tagGroup.nameDisplay || tagGroup.name,
      color: tagGroup.colorHex,
    })
  )
  return categories
}
