import React from 'react'
import { ConciergeEnabledEnum, type ConciergeEnabled, type Disabled } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { RadioGroup, Label } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'

interface PerkIsConciergePortalFormProps {
  conciergeEnabledField: Field<ConciergeEnabled>
  disabled: Disabled
}

export function PerkIsConciergePortalForm({ disabled, conciergeEnabledField }: PerkIsConciergePortalFormProps) {
  const { formatMessage } = useLocales()

  const {
    perkAvailableInConciergePortalLabel,
    perkAvailableInConciergePortalNoLabel,
    perkAvailableInConciergePortalYesDescription,
    perkAvailableInConciergePortalYesLabel,
  } = perksMessages

  return (
    <VStack spacing="m">
      <Label primary={formatMessage(perkAvailableInConciergePortalLabel)} />
      <RadioGroup
        data-test="sr-concierge-portal-choice"
        choices={[
          {
            disabled,
            label: formatMessage(perkAvailableInConciergePortalNoLabel),
            value: ConciergeEnabledEnum.NO,
          },
          {
            disabled,
            description: formatMessage(perkAvailableInConciergePortalYesDescription),
            label: formatMessage(perkAvailableInConciergePortalYesLabel),
            value: ConciergeEnabledEnum.YES,
          },
        ]}
        disabled={disabled}
        field={conciergeEnabledField}
      />
    </VStack>
  )
}
