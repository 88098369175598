import { useLocales } from '@sevenrooms/core/locales'
import { Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useGetVenueSettingsQuery, useSaveVenueSettingsMutation } from './hooks'
import { MainContent } from './MainContent'
import { venueSettingsMessages } from './VenueSettings.locales'
import type { ReactNode } from 'react'

export function VenueSettings() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { data, isFetching, error } = useGetVenueSettingsQuery({ venueId })
  const [saveVenueSettings] = useSaveVenueSettingsMutation()

  const onSuccess = () => {
    notify({ content: formatMessage(venueSettingsMessages.success), type: 'success' })
  }

  const onError = () => {
    notify({ content: formatMessage(venueSettingsMessages.error), type: 'error' })
  }

  const renderContent = (content: ReactNode) => (
    <>
      <SettingsPageMeta title={formatMessage(venueSettingsMessages.title)} />
      {content}
    </>
  )

  if (isFetching && !error) {
    return renderContent(<Loader />)
  }

  if (data && !error) {
    return renderContent(
      <MainContent
        fields={data.fields}
        options={data.options}
        venueClass={data.class}
        onSave={(formData, dirtyFields) =>
          saveVenueSettings({ venueId, formData, dirtyFields }).then(response => {
            if ('error' in response) {
              onError()
            } else {
              onSuccess()
            }
          }, onError)
        }
      />
    )
  }

  return renderContent(formatMessage(venueSettingsMessages.error))
}
