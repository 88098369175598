import { useMemo } from 'react'
import { useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, MultiSelectTag, type Category, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { Box, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { guestEmailSMSMessages } from '../../GuestEmailSMS/GuestEmailSMS.locales'
import type { ExcludedAudienceFormField } from './ExcludedAudience.zod'

interface ExcludedAudienceProps {
  field: ExcludedAudienceFormField
}

export function ExcludedAudience({ field }: ExcludedAudienceProps) {
  const { formatMessage } = useLocales()
  const { venueKey } = useVenueContext()
  const { venueSettings } = useAppContext()
  const { data: clientAutotags, isFetching } = useGetClientTagGroupsQuery({ venueKey })

  const { excludedTagCategories, excludedTagOptions, excludedTagsInitialSelection } = useMemo(
    () => generateTagsData(clientAutotags, venueSettings.feedbackExcludedTags),
    [clientAutotags, venueSettings]
  )

  return (
    <Box>
      {isFetching ? (
        <Loader />
      ) : (
        <VStack spacing="lm">
          <Label primary={formatMessage(guestEmailSMSMessages.guestFeedbackExcludeDescription)}>
            <MultiSelectTag
              searchable
              field={field.prop('excludedAutotags')}
              categories={excludedTagCategories}
              options={excludedTagOptions}
              initialSelectedItems={excludedTagsInitialSelection}
              id="excluded-tags"
            />
          </Label>
        </VStack>
      )}
    </Box>
  )
}

const generateTagsData = (clientTagGroups?: GenericTagGroup[], excludedTags?: string[]) => {
  const excludedTagCategories: Category[] = []
  const excludedTagOptions: TagOption[] = []
  const excludedTagsInitialSelection: TagOption[] = []

  if (clientTagGroups) {
    const filteredClientTagGroups = clientTagGroups.filter(clientTagGroup => !clientTagGroup.deleted && clientTagGroup.tags.length > 0)
    const excludedTagsSet = new Set(excludedTags)
    filteredClientTagGroups.forEach(clientTagGroup => {
      clientTagGroup.tags.forEach((tag: string) => {
        const tagOption: TagOption = {
          id: [clientTagGroup.privacy, clientTagGroup.id, clientTagGroup.name, tag].join('##'),
          label: clientTagGroup.tagNameDisplays[tag] ?? tag,
          categoryId: clientTagGroup.id,
        }

        if (excludedTagsSet.has(tagOption.id)) {
          excludedTagsInitialSelection.push(tagOption)
        }
        excludedTagOptions.push(tagOption)
      })
      const tagCategory: Category = {
        id: clientTagGroup.id,
        name: clientTagGroup.nameDisplay ?? clientTagGroup.name,
        color: clientTagGroup.colorHex,
      }
      excludedTagCategories.push(tagCategory)
    })
  }
  return {
    excludedTagCategories,
    excludedTagOptions,
    excludedTagsInitialSelection,
  }
}
