import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'paymentSettings.StripeDisconnectDialog.title',
    defaultMessage: 'Are you sure you want to disconnect this integration?',
  },
  body: {
    id: 'paymentSettings.StripeDisconnectDialog.body',
    defaultMessage: 'You will lose all previously saved credentials',
  },
  disconnect: {
    id: 'paymentSettings.StripeDisconnectDialog.disconnect',
    defaultMessage: 'Disconnect Stripe',
  },
} as const)
