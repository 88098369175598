import { useState } from 'react'
import type { Event } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, NotificationBanner } from '@sevenrooms/core/ui-kit/layout'
import { ExperiencesLocales } from '../../Experiences.locales'
import { EventSelector } from './EventSelector'
import { EventSelectorLocales } from './EventSelector.locales'

interface EventSelectorModalProps {
  close: () => void
  events: Event[]
  selectedEventIds: string[]
  setSelectedEventIds: (selectedIds: string[]) => void
}
export function EventSelectorModal({ close, events, selectedEventIds, setSelectedEventIds }: EventSelectorModalProps) {
  const { formatMessage } = useLocales()
  const [eventIds, setEventsIds] = useState(selectedEventIds)
  const link = () => {
    setSelectedEventIds(eventIds)
    close()
  }
  return (
    <Modal ariaLabel="Modal" data-test="events-modal">
      <ModalHeader onClose={() => close()}>
        <ModalTitle title={formatMessage(EventSelectorLocales.modalTitle)} />
      </ModalHeader>
      <ModalBody>
        <EventSelector events={events} selectedEventIds={eventIds} setSelectedEventIds={setEventsIds} />
        {events.length === 0 && (
          <NotificationBanner
            mt="s"
            type="warning"
            iconName="VMSWeb-warning"
            showIcon
            content={formatMessage(EventSelectorLocales.allEventsAreAlreadyLinked)}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <Flex flexDirection="row-reverse" pt="s">
          <Button data-test="sr-event-link-button" variant="primary" onClick={link}>
            {formatMessage(ExperiencesLocales.link)}
          </Button>
          <Button data-test="sr-event-cancel-button" variant="tertiary" onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}
