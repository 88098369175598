import { defineMessages } from '@sevenrooms/core/locales'

export const posiCommonMessages = defineMessages({
  error: {
    id: 'posi.error',
    defaultMessage: 'Error',
  },
  errorBannerDescription: {
    id: 'posi.errorBannerDescription',
    defaultMessage: `Failed to load POS data. Please try again. If the error persists, please contact support. Error: "{error}"`,
  },
  tryAgain: {
    id: 'posi.tryAgain',
    defaultMessage: 'Try Again',
  },
} as const)
