import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack, Grid, SectionHeader } from '@sevenrooms/core/ui-kit/layout'
import { messages } from './AdditionalPaymentIntegrations.locales'

export interface PaymentIntegrationProps {}

export function AdditionalPaymentIntegrations({ children }: React.PropsWithChildren<PaymentIntegrationProps>) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="s" minWidth="-webkit-fill-available" width="100%">
      <SectionHeader
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        data-test="payment-settings-additional-integrations"
      />
      <Grid columnGap="m" gridTemplateColumns="repeat( auto-fill, minmax(358px, 1fr) )">
        {children}
      </Grid>
    </VStack>
  )
}
