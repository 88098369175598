import { isDollarAmountType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { PercentageInput, CurrencyInput } from '@sevenrooms/core/ui-kit/core'
import { Checkbox, Label, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Delimiter } from '../components/Delimiter'
import { Tooltip } from '../components/Tooltip'
import { usePromoCodeContext } from '../hooks/usePromoCodeContext'
import { usePromoCodeResourcesContext } from '../hooks/usePromoCodeResourcesContext'
import { promoCodeMessages } from '../locales'

export function PromoTypesSection() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { promoTypeChoices } = usePromoCodeResourcesContext()
  const {
    widgets,
    promoType,
    setPromoType,
    promoValue,
    setPromoValue,
    isPromoValueValid,
    requireMinimumSubtotal,
    setRequireMinimumSubtotal,
    minimumSubtotalAmount,
    setMinimumSubtotalAmount,
    isMinimumSubtotalAmountValid,
    upToFixedAmount,
    setUpToFixedAmount,
    isFixedAmountCapValid,
    fixedAmountCap,
    setFixedAmountCap,
  } = usePromoCodeContext()
  const selectedWidgets = widgets.filter(selectedWidget => selectedWidget.checked)
  const isOnlyOrderingWidgetSelected = selectedWidgets.length === 1 && selectedWidgets[0]?.value === 'ORDERING'

  return (
    <BaseSection title={formatMessage(promoCodeMessages.promoCodeFormPromoTypesSection)}>
      <Box p="lm">
        <VStack>
          <RadioGroup
            name="promoType"
            data-test="promo-code-form-promo-type-field"
            choices={promoTypeChoices}
            selected={!promoType || isDollarAmountType(promoType) ? promoType : 'PERCENT_DISCOUNT'}
            onChange={option => setPromoType(option.value)}
          />
          {promoType && (
            <>
              <Delimiter />
              <VStack spacing="s">
                {isDollarAmountType(promoType) ? (
                  <Label primary={formatMessage(promoCodeMessages.promoCodeFormDiscountedValueLabel)}>
                    <CurrencyInput
                      data-test="promo-code-form-promo-value-field"
                      currencySymbol={venue?.currencySymbol}
                      invalid={!isPromoValueValid}
                      hideErrorMessage={isPromoValueValid}
                      errorMessage={formatMessage(promoCodeMessages.promoCodeFormDiscountedValueFixedAmountErrorMessage)}
                      value={promoValue}
                      onChange={(value: number | null) => {
                        setPromoValue(value ?? undefined)
                      }}
                    />
                  </Label>
                ) : (
                  <>
                    <Label primary={formatMessage(promoCodeMessages.promoCodeFormDiscountedValueLabel)}>
                      <PercentageInput
                        data-test="promo-code-form-promo-value-field"
                        value={promoValue}
                        decimalScale={0}
                        hideErrorMessage={isPromoValueValid}
                        invalid={!isPromoValueValid}
                        errorMessage={formatMessage(promoCodeMessages.promoCodeFormDiscountedValuePercentageErrorMessage)}
                        onChange={(value: number | null) => {
                          setPromoValue(value ?? undefined)
                        }}
                      />
                    </Label>
                    <Checkbox
                      data-test="promo-code-form-up-fixed-amount-field"
                      onChange={e => setUpToFixedAmount(e.target.checked)}
                      checked={upToFixedAmount}
                      name="upToFixedAmount"
                      info={
                        <Tooltip
                          title={formatMessage(promoCodeMessages.promoCodeFormUpFixedAmountTooltipTitle)}
                          content={formatMessage(promoCodeMessages.promoCodeFormUpFixedAmountTooltipContent)}
                        />
                      }
                    >
                      {formatMessage(promoCodeMessages.promoCodeFormFixedAmountLabel)}
                    </Checkbox>
                    {upToFixedAmount && (
                      <Box pl="lm">
                        <CurrencyInput
                          data-test="promo-code-form-fixed-amount-field"
                          value={fixedAmountCap || 0}
                          currencySymbol={venue?.currencySymbol}
                          invalid={!isFixedAmountCapValid}
                          hideErrorMessage={isFixedAmountCapValid}
                          errorMessage={formatMessage(promoCodeMessages.promoCodeFormDiscountedValueFixedAmountErrorMessage)}
                          onChange={(value: number | null) => setFixedAmountCap(value ?? undefined)}
                        />
                      </Box>
                    )}
                  </>
                )}
                {isOnlyOrderingWidgetSelected && (
                  <>
                    <Checkbox
                      data-test="promo-code-form-require-minimum-subtotal-field"
                      onChange={e => setRequireMinimumSubtotal(e.target.checked)}
                      checked={requireMinimumSubtotal}
                      name="requireMinimumSubtotal"
                    >
                      {formatMessage(promoCodeMessages.promoCodeFormRequireMinimumSubtotalLabel)}
                    </Checkbox>
                    {requireMinimumSubtotal && (
                      <Box pl="lm">
                        <CurrencyInput
                          data-test="promo-code-form-min-subtotal-field"
                          value={minimumSubtotalAmount || 0}
                          currencySymbol={venue?.currencySymbol}
                          invalid={!isMinimumSubtotalAmountValid}
                          hideErrorMessage={isMinimumSubtotalAmountValid}
                          errorMessage={formatMessage(promoCodeMessages.promoCodeFormDiscountedValueFixedAmountErrorMessage)}
                          onChange={(value: number | null) => setMinimumSubtotalAmount(value ?? undefined)}
                        />
                      </Box>
                    )}
                  </>
                )}
              </VStack>
            </>
          )}
        </VStack>
      </Box>
    </BaseSection>
  )
}
