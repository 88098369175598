import { parsePhoneNumber } from 'libphonenumber-js'
import { useSendTestSMSCampaignMutation } from '@sevenrooms/core/api'
import { type SubmitHandler, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Form, Label, Button } from '@sevenrooms/core/ui-kit/form'
import {
  notify,
  VStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { smsBuilderMessages } from '@sevenrooms/marketing'
import { oneTimeSMSCenterMessages } from '@sevenrooms/mgr-marketing-one-time-sms-center/locales'
import { PhoneNumberInputList } from './PhoneNumberInputList'
import { SendTestSMSDefaultValues, type SendTestSMSSchema, useSendTestSMSForm } from './TestSMSModalForm.zod'

export interface SendTestSMSModalProps {
  venueId: string
  messageBody?: string
  messageMedia?: string[] | null
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}
export function SendTestSMSModal({ closeHref, venueId, messageBody, messageMedia }: SendTestSMSModalProps) {
  const { formatMessage } = useLocales()

  const nav = useNavigation()
  const schema = useSendTestSMSForm()
  const form = useForm(schema, { defaultValues: SendTestSMSDefaultValues })
  const {
    field,
    formState: { isDirty, isValid },
  } = form
  const [sendTestSMSCampaignQuery, { isLoading: isSendingTest }] = useSendTestSMSCampaignMutation()

  const onSubmitForm: SubmitHandler<SendTestSMSSchema> = ({ phoneNumbers }) => {
    sendTestSMSCampaignQuery({
      venueId,
      messageContent: {
        destinationPhoneNumbers: phoneNumbers.map(n => n.number),
        messageMedia: messageMedia || null,
        messageBody: messageBody || null,
      },
    })
      .then(result => {
        if (!('data' in result) || !result.data) {
          throw Error()
        }
        notify({
          content: () => (
            <VStack>
              {formatMessage(smsBuilderMessages.smsSendTestSuccess, { ignoreTag: false })}
              {phoneNumbers.map(phoneNumber => (
                <Text key={phoneNumber.number} color="lightFont">
                  {parsePhoneNumber(phoneNumber.number).format('NATIONAL')}
                </Text>
              ))}
            </VStack>
          ),
          type: 'success',
        })
        nav.closeSurface(closeHref)
      })
      .catch(() => {
        notify({ content: formatMessage(smsBuilderMessages.smsSendTestError), type: 'error' })
      })
  }

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(oneTimeSMSCenterMessages.sendTestModalHeading)}
          subTitle={<Text color="secondaryFont">{formatMessage(oneTimeSMSCenterMessages.sendTestModalSubheading)}</Text>}
        />
      </ModalHeader>
      <Form {...form} onSubmit={onSubmitForm} onInvalid={() => {}}>
        <ModalBody>
          <Label primary={formatMessage(oneTimeSMSCenterMessages.sendTestModalNumberInputLabel)} />
          <PhoneNumberInputList maxEntries={6} field={field} fieldArrayName="phoneNumbers" />
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button href={closeHref} variant="secondary" data-test="send-test-sms-modal-cancel-button" disabled={isSendingTest}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button data-test="send-test-sms-modal-confirm-button" type="submit" disabled={isSendingTest || !(isDirty && isValid)}>
              {formatMessage(oneTimeSMSCenterMessages.sendTestModalConfirmButtonLabel)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
