import { defineMessages } from '@sevenrooms/core/locales'

export const chargesMessages = defineMessages({
  sectionReservationCharges: {
    id: 'settings.general.section.reservationCharges',
    defaultMessage: 'Reservation Charges',
  },
  serviceChargeTitle: {
    id: 'settings.general.serviceCharge.title',
    defaultMessage: 'Default Service Charge',
  },
  serviceChargeDescription: {
    id: 'settings.general.serviceCharge.description',
    defaultMessage: 'Service charge on any payments collected where ‘default service charge’ is selected',
  },
  gratuityTitle: {
    id: 'settings.general.gratuity.title',
    defaultMessage: 'Default Gratuity',
  },
  gratuityDescription: {
    id: 'settings.general.gratuity.description',
    defaultMessage: 'Gratuity on any payments collected where ‘default gratuity’ is selected',
  },
  taxRatesTitle: {
    id: 'settings.general.taxRates.title',
    defaultMessage: 'Related Settings - Tax Rates',
  },
  taxRatesDescription: {
    id: 'settings.general.taxRates.description',
    defaultMessage: 'Don’t forget to set your Tax Rates! Head on over to the tax rates settings page to do so.',
  },
  taxRatesButton: {
    id: 'settings.general.taxRates.button',
    defaultMessage: 'Go to Tax Rates',
  },
  trackCompsTitle: {
    id: 'settings.general.trackComps.title',
    defaultMessage: 'Track Comps',
  },
  trackCompsDescription: {
    id: 'settings.general.trackComps.description',
    defaultMessage: 'Include additional Comps field on booking form to track dollars/bottles comped on each reservation',
  },
  costOptionsTitle: {
    id: 'settings.general.costOptions.title',
    defaultMessage: 'Cost Options',
  },
  costOptionsDiningDescription: {
    id: 'settings.general.costOptionsDining.description',
    defaultMessage: 'Choose the cost or item options you would like displayed on your reservation slideout',
  },
  costOptionsDescription: {
    id: 'settings.general.costOptions.description',
    defaultMessage:
      'Choose the cost or item options you would like displayed on your reservation slideout; this is associated with nightlife perks',
  },
  costOptionsMinDollars: {
    id: 'settings.general.costOptionsMinDollars',
    defaultMessage: 'Minimum $',
  },
  costOptionsMinBottles: {
    id: 'settings.general.costOptionsMinBottles',
    defaultMessage: 'Minimum bottles',
  },
  costOptionsNoMin: {
    id: 'settings.general.costOptionsNoMin',
    defaultMessage: 'No minimum',
  },
  costOptionsComped: {
    id: 'settings.general.costOptionsComped',
    defaultMessage: 'Comped',
  },
  costOptionsTotalDollars: {
    id: 'settings.general.costOptionsTotalDollars',
    defaultMessage: 'Total Fee',
  },
  printChitCommitmentMsgTitle: {
    id: 'settings.general.printChitCommitmentMsg.title',
    defaultMessage: 'Custom text for printer chit commitment message.',
  },
  printChitCommitmentMsgDescription: {
    id: 'settings.general.printChitCommitmentMsg.description',
    defaultMessage: 'Commitment text',
  },
  printChitCommitmentMsgPlaceholder: {
    id: 'settings.general.printChitCommitmentMsg.placeholder',
    defaultMessage: 'I, the customer, agree to meet the above fee for this night. This fee does not include tax or gratuity charge.',
  },
  printChitEnabledTitle: {
    id: 'settings.general.printChitEnabled.title',
    defaultMessage: 'Printer Chit Gratuity Settings',
  },
  printChitEnabledDescription: {
    id: 'settings.general.printChitEnabled.description',
    defaultMessage: 'Enable printing gratuity options on commitment chits?',
  },
  printGratuityCommitmentMsgTitle: {
    id: 'settings.general.printGratuityCommitmentMsg.title',
    defaultMessage: 'Gratuity Commitment Message',
  },
  printGratuityCommitmentMsgPlaceholder: {
    id: 'settings.general.printGratuityCommitmentMsg.placeholder',
    defaultMessage: 'I would also like to add a gratuity of: ',
  },
  printGratuityFirstOptTitle: {
    id: 'settings.general.printGratuityFirstOpt.title',
    defaultMessage: 'First Gratuity Option ',
  },
  printGratuitySecondOptTitle: {
    id: 'settings.general.printGratuitySecondOpt.title',
    defaultMessage: 'Second Gratuity Option ',
  },
  printGratuityChangeMsgTitle: {
    id: 'settings.general.printGratuityChangeMsg.title',
    defaultMessage: 'Gratuity Change Agreement',
  },
  printGratuityChangeMsgPlaceholder: {
    id: 'settings.general.printGratuityChangeMsg.placeholder',
    defaultMessage:
      'I reserve the right to change this gratuity at any time during my visit by informing the server or manager of such change. ',
  },
  avgBottlePriceTitle: {
    id: 'settings.general.avgBottlePrice.title',
    defaultMessage: 'Reporting Average Bottle Price',
  },
  avgBottlePriceDescription: {
    id: 'settings.general.avgBottlePrice.description',
    defaultMessage:
      'For reporting purposes, this price will be used to estimate money booked for minimums specified as quantity of bottles (e.g. $500)',
  },
  ccCollectionOptionsTitle: {
    id: 'settings.general.ccCollectionOptions.title',
    defaultMessage: 'Credit Card Collection Options',
  },
  ccCollectionOptionsDescription: {
    id: 'settings.general.ccCollectionOptions.description',
    defaultMessage: 'Controls how a user is able to collect a credit card from a guest on a reservation',
  },
  ccCollectionOptionsBothDefaultManual: {
    id: 'settings.general.ccCollectionOptions.bothDefaultManual',
    defaultMessage: 'Both Options - Default to Manually Enter by User',
  },
  ccCollectionOptionsBothDefaultPaylink: {
    id: 'settings.general.ccCollectionOptions.bothDefaultPaylink',
    defaultMessage: 'Both Options - Default to Collect via Paylink (recommended)',
  },
  ccCollectionOptionsManualOnly: {
    id: 'settings.general.ccCollectionOptions.manualOnly',
    defaultMessage: 'Manually Enter by User ONLY',
  },
  ccCollectionOptionsPaylinkOnly: {
    id: 'settings.general.ccCollectionOptions.paylinkOnly',
    defaultMessage: 'Collect via Paylink ONLY',
  },
  ccCollectionOptionsAutoCancelLongTime: {
    id: 'settings.general.ccCollectionOptions.autoCancelLongTime',
    defaultMessage: 'Auto-cancel reservation if paylink not completed within:',
  },
  ccCollectionOptionsAutoCancelShortTime: {
    id: 'settings.general.ccCollectionOptions.autoCancelShortTime',
    defaultMessage: 'If under time set above between now and reservation date/time:',
  },
  ccCollectionOptionsTimeLabel: {
    id: 'settings.general.ccCollectionOptions.timeLabel',
    defaultMessage: `{minutes, select,
      30 { 30 minutes }
      60 { 1 hour }
      120 { 2 hours }
      360 { 6 hours }
      720 { 12 hours }
      1440 { 24 hours }
      2880 { 48 hours }
      4320 { 3 days }
      5760 { 4 days }
      7200 { 5 days }
      8640 { 6 days }
      10080 { 7 days }
      14400 { 10 days }
      20160 { 14 days }
      30240 { 21 days }
      other { Do not auto-cancel }
    }`,
  },
})
