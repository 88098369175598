import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationCoverLimitLocales } from '../ReservationCoverLimit.locales'
import { ReservationCoverLimitTestId } from '../ReservationCoverLimit.testIds'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function ReservationCoverLimitReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()

  const { count, type, guaranteeBookings } = accessRule.reservationCoverLimit

  return (
    <ReportPart caption={formatMessage(ReservationCoverLimitLocales.title)} data-test={ReservationCoverLimitTestId.report}>
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.maxLimitPerDay)}>
        <Text>
          {count != null && type
            ? formatMessage(ReservationCoverLimitLocales.selectionSummary, { type, count })
            : formatMessage(ReservationCoverLimitLocales.defaultsValue)}
        </Text>
      </ReportLine>
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.guaranteeBookingsReportKey)}>
        <Text>{formatMessage(guaranteeBookings ? commonMessages.yes : commonMessages.no)}</Text>
      </ReportLine>
    </ReportPart>
  )
}
