import React from 'react'
import styled from 'styled-components'

const TitleBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple} ${props => props.theme.gutter.medium}
    ${props => props.theme.gutter.double};
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
`
const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.largeSubheader};
  margin: 0px;
  padding-top: ${props => props.theme.gutter.standard};
  padding-bottom: ${props => props.theme.gutter.standard};
`

const ActionWrapper = styled.div`
  display: flex;
`

const AdtlActionsContainer = styled.div`
  display: inline-block;
  padding-right: ${props => props.theme.padding.medium};
`

const ActionsContainer = styled.div`
  display: inline-block;
`

class TitleBar extends React.PureComponent {
  render() {
    const { id, title, Actions, AdditionalActions } = this.props

    return (
      <TitleBarContainer>
        <Title id={id}>{title}</Title>
        <ActionWrapper>
          {AdditionalActions && <AdtlActionsContainer>{AdditionalActions}</AdtlActionsContainer>}
          {Actions && <ActionsContainer>{Actions}</ActionsContainer>}
        </ActionWrapper>
      </TitleBarContainer>
    )
  }
}

export default TitleBar
