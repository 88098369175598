import { useMemo } from 'react'
import type { Policy } from '@sevenrooms/core/domain'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Divider, useAccessRuleContext } from '../../shared'
import { MissingPayments } from '../../shared/MissingPayments'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { partitionPolicies } from '../PaymentPolicy.zod'
import { AllowUnsupported } from './AllowUnsupported'
import { BundledUpgrades } from './BundledUpgrades'
import { FollowShift } from './FollowShift'
import { PolicyPicker } from './PolicyPicker'
import { PaymentRuleRadios, RefundRadios, CancelChargeRadios, CancelRadios } from './radio'
import { Totals } from './Totals'
import type { PaymentPolicySectionProps, PaymentPolicyProps } from './PaymentPolicyProps'

export function PaymentPolicyFields({ field, allowChannelsWithoutCCHolds }: PaymentPolicySectionProps) {
  const { formatMessage } = useLocales()
  const useShiftPaymentAndPolicy = useWatch(field.prop('useShiftPaymentAndPolicy'))
  const { paymentsEnabled } = useAppContext().venueSettings
  const { policies } = useAccessRuleContext()
  const [bookingPolicies, cancelPolicies] = useMemo(() => partitionPolicies(policies, 'BOOKING'), [policies])

  return (
    <>
      {!paymentsEnabled && <MissingPayments />}
      <VStack spacing="lm">
        <Divider />
        <FollowShift field={field.prop('useShiftPaymentAndPolicy')} />
        {useShiftPaymentAndPolicy ? (
          <>
            <AllowUnsupported field={allowChannelsWithoutCCHolds} />
            <Divider />
            <BundledUpgrades field={field} />
          </>
        ) : (
          <>
            {paymentsEnabled && (
              <>
                <Divider />
                <PaymentRuleRadios field={field} allowChannelsWithoutCCHolds={allowChannelsWithoutCCHolds} />
                <Divider />
                <BundledUpgrades field={field} />
                <Totals field={field} />
                <Divider />
                <NoShowOrRefund field={field} />
              </>
            )}
            <CancelRadios field={field} />
            <Divider />
            <PolicyPicker
              data-test={PaymentPolicyTestId.bookingPolicy}
              choice={field.prop('bookingPolicy')}
              freeform={field.prop('customBookingPolicy')}
              policies={bookingPolicies}
              label={formatMessage(PaymentPolicyLocales.bookingPolicy)}
            />
            <CancelPolicy field={field} policies={cancelPolicies} />
          </>
        )}
      </VStack>
    </>
  )
}

function NoShowOrRefund({ field }: PaymentPolicyProps) {
  const paymentRule = useWatch(field.prop('paymentRule'))
  return paymentRule === 'save_for_later' ? <CancelChargeRadios field={field} /> : <RefundRadios field={field} />
}

type CancelPolicyProps = PaymentPolicyProps & { policies: Policy[] }
function CancelPolicy({ field, policies }: CancelPolicyProps) {
  const { formatMessage } = useLocales()
  const paymentRule = useWatch(field.prop('paymentRule'))

  return paymentRule !== 'none' ? (
    <PolicyPicker
      data-test={PaymentPolicyTestId.cancelPolicy}
      choice={field.prop('cancelPolicy')}
      freeform={field.prop('customCancelPolicy')}
      policies={policies}
      label={formatMessage(PaymentPolicyLocales.cancellationPolicy)}
    />
  ) : null
}
