import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  discardChanges: {
    id: 'paymentSettings.PaymentSettingsButtons.discardChanges',
    defaultMessage: 'Discard changes',
  },
  saveChanges: {
    id: 'paymentSettings.PaymentSettingsButtons.saveChanges',
    defaultMessage: 'Submit',
  },
  testFailedOnSaveMessage: {
    id: 'paymentSettings.PaymentSettingsButtons.testFailedOnSaveMessage',
    defaultMessage: 'Changes not saved. Double check your credentials and try again.',
  },
} as const)
