import * as React from 'react'
import { Flex, Image, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import type { PropsWithChildren } from 'react'

export function ViewSettingsBlock({ children }: PropsWithChildren<{}>) {
  return (
    <div
      style={{
        borderStyle: 'solid',
        borderColor: theme.colors.secondaryBackground,
        padding: '24px',
        borderWidth: '2px 1px',
        flexGrow: '1',
        flexBasis: '0',
      }}
    >
      <VStack spacing="m">{children}</VStack>
    </div>
  )
}

export function ProcessorCircleLogoGeneric({ processorLogo, processorTitle }: { processorLogo: string; processorTitle: string }) {
  return (
    <Flex
      borderRadius="l"
      boxShadow="primary"
      width="72px"
      height="72px"
      borderColor="iconHover"
      borderWidth="s"
      textAlign="center"
      justifyContent="center"
      display="inherit"
    >
      <Image src={processorLogo} alt={processorTitle} width="80%" data-test="payment-settings-integration-logo" />
    </Flex>
  )
}

export function ProcessorCircleLogoStripe({ processorLogo, processorTitle }: { processorLogo: string; processorTitle: string }) {
  return (
    <Flex>
      <Image
        src={processorLogo}
        alt={processorTitle}
        width="53px"
        height="53px"
        borderRadius="l"
        centered
        data-test="payment-settings-stripe-logo"
      />
    </Flex>
  )
}
