import { Tier, TierTitle, TierContent } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier from './BaseTier'

export class VisitsMarketingSegmentationTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier

    const { isActive, id } = this.props
    return (
      <div id={id}>
        {this.props.editing ? (
          <Tier isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag clients who have exactly <strong>{attributes.MIN_VISITS}</strong> completed reservations. The tag will automatically be
              removed after <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
            </TierContent>
            {this.fetchAdditionalOptions()}
          </Tier>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag clients who have exactly <strong>{attributes.MIN_VISITS}</strong> completed reservations. The tag will automatically be
              removed after <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default VisitsMarketingSegmentationTier
