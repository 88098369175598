import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Beforeunload } from 'react-beforeunload'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useLocation, useHistory, Prompt } from '@sevenrooms/core/navigation'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import type * as History from 'history'

interface TransitionBlockerProps {
  modal: React.ReactElement
  isBlocked: boolean
  blockBeforeUnload?: boolean
  onConfirmTransition?: (path: string) => void
  renderCustomBlocker?: (render: (location: History.Location) => boolean) => React.ReactElement
  skipQuery?: boolean
}

export function TransitionBlocker({
  modal,
  isBlocked,
  blockBeforeUnload = true,
  onConfirmTransition,
  renderCustomBlocker,
  skipQuery,
}: TransitionBlockerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { formatMessage } = useLocales()
  const [lastLocation, setLastLocation] = useState<History.Location>(location)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onCancelHandler = () => {
    closeModal()
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = (nextLocation: History.Location) => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = (nextLocation: History.Location) => {
    if (skipQuery && location.pathname === nextLocation.pathname) {
      return true
    }
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const onDiscardHandler = () => {
    closeModal()
    setConfirmedNavigation(true)
  }

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      history.push(lastLocation.pathname)
      onConfirmTransition?.(lastLocation.pathname)
    }
  }, [confirmedNavigation, history, lastLocation, onConfirmTransition])

  const renderModal = () =>
    React.cloneElement(modal, {
      onDiscard: onDiscardHandler,
      onCancel: onCancelHandler,
    })

  const onBeforeunload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isBlocked) {
        event.preventDefault()
        // eslint-disable-next-line no-param-reassign
        event.returnValue = formatMessage(commonMessages.unSavedChangesWarning)
      }
      return null
    },
    [formatMessage, isBlocked]
  )

  return (
    <>
      {isBlocked && blockBeforeUnload && <Beforeunload onBeforeunload={onBeforeunload} />}
      <Prompt when message={handleBlockedRoute} />
      {renderCustomBlocker?.(handleBlockedRoute)}
      <Window active={isModalOpen}>{renderModal()}</Window>
    </>
  )
}
