import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  convertSimphonyAuthenticationCardFormDataToSimphonyConfig,
  getSimphonyAuthenticationCardFormDefaultValues,
  useSimphonyAuthenticationCardForm,
} from '../../../AuthenticationCard/SimphonyAuthenticationCard/SimphonyAuthenticationCardForm.zod'
import {
  convertSimphonyAutomaticCheckCreationCardFormDataToSimphonyConfig,
  getSimphonyAutomaticCheckCreationCardFormDefaultValues,
  useSimphonyAutomaticCheckCreationCardForm,
} from '../../../AutomaticCheckCreationCard/SimphonyAutomaticCheckCreationCard/SimphonyAutomaticCheckCreationCardForm.zod'
import {
  convertSimphonyAutomaticPrepaymentRedemptionCardFormDataToSimphonyConfig,
  getSimphonyAutomaticPrepaymentRedemptionCardFormDefaultValues,
  useSimphonyAutomaticPrepaymentRedemptionCardForm,
} from '../../../AutomaticPrepaymentRedemptionCard/SimphonyAutomaticPrepaymentRedemptionCard/SimphonyAutomaticPrepaymentRedemptionCardForm.zod'
import {
  convertCourseStatusMappingCardFormDataToPOSIConfig,
  getCourseStatusMappingCardFormDefaultValues,
  useCourseStatusMappingCardForm,
} from '../../../CourseStatusMappingCard/CourseStatusMappingCardForm.zod'
import {
  convertSimphonyLocationSelectionCardFormDataToSimphonyConfig,
  getSimphonyLocationSelectionCardFormDefaultValues,
  useSimphonyLocationSelectionCardForm,
} from '../../../LocationSelectionCard/SimphonyLocationSelectionCard/SimphonyLocationSelectionCardForm.zod'

export type SimphonyEditPageFormData = ZodSchema<typeof useSimphonyEditPageForm>
export type SimphonyEditPageFormField = Field<SimphonyEditPageFormData>

export function useSimphonyEditPageForm() {
  const authenticationCard = useSimphonyAuthenticationCardForm()
  const automaticCheckCreationCard = useSimphonyAutomaticCheckCreationCardForm()
  const automaticPrepaymentRedemptionCard = useSimphonyAutomaticPrepaymentRedemptionCardForm()
  const locationCard = useSimphonyLocationSelectionCardForm()
  const courseStatusMappingCard = useCourseStatusMappingCardForm()

  return useMemo(
    () =>
      z.object({
        authenticationCard,
        automaticCheckCreationCard,
        automaticPrepaymentRedemptionCard,
        locationCard,
        courseStatusMappingCard,
      }),
    [authenticationCard, automaticCheckCreationCard, automaticPrepaymentRedemptionCard, courseStatusMappingCard, locationCard]
  )
}

export const getSimphonyEditPageFormDefaultValues = (config: SimphonyConfig): SimphonyEditPageFormData => ({
  authenticationCard: getSimphonyAuthenticationCardFormDefaultValues(config),
  automaticCheckCreationCard: getSimphonyAutomaticCheckCreationCardFormDefaultValues({
    ...config,
    isAutomaticCheckCreationEnabled: config.checkCreationEnabled,
  }),
  automaticPrepaymentRedemptionCard: getSimphonyAutomaticPrepaymentRedemptionCardFormDefaultValues({
    ...config,
    isAutomaticPrepaymentRedemptionEnabled: config.depositsEnabled,
  }),
  locationCard: getSimphonyLocationSelectionCardFormDefaultValues({ ...config }),
  courseStatusMappingCard: getCourseStatusMappingCardFormDefaultValues(config),
})

export const convertSimphonyEditPageFormDataToSimphonyConfig = ({
  config,
  data,
}: {
  config: SimphonyConfig
  data: SimphonyEditPageFormData
}): SimphonyConfig => ({
  lastSaved: config.lastSaved,
  pollFrequency: config.pollFrequency,
  ...convertSimphonyAuthenticationCardFormDataToSimphonyConfig(data.authenticationCard),
  ...convertSimphonyAutomaticCheckCreationCardFormDataToSimphonyConfig(data.automaticCheckCreationCard),
  ...convertSimphonyAutomaticPrepaymentRedemptionCardFormDataToSimphonyConfig(data.automaticPrepaymentRedemptionCard),
  ...convertSimphonyLocationSelectionCardFormDataToSimphonyConfig(data.locationCard),
  ...convertCourseStatusMappingCardFormDataToPOSIConfig(data.courseStatusMappingCard),
})
