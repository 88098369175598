import { useMemo, useState } from 'react'
import { type EmailCampaignTemplate, useGetOneTimeEmailCampaignTemplatesQuery } from '@sevenrooms/core/api'
import { EmailCampaignTemplateCategoryEnum } from '@sevenrooms/core/domain'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { FormMultiSelect, BasicButton, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, Grid, HStack, Loader, VStack, BorderedBox, Breadcrumbs } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text, StatusLabel, Link } from '@sevenrooms/core/ui-kit/typography'
import { useOneTimeEmailCampaignTemplateCategories, campaignCenterMessages } from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { EmailTemplatePreview } from '../../components'
import { oneTimeEmailCenterMessages } from '../../locales'

const EMAIL_CAMPAIGN_TEMPLATE_CARD_HEIGHT = '354px'
const CAMPAIGN_TEMPLATE_CARD_WIDTH = '266px'
const EMAIL_CAMPAIGN_CATEGORY_FILTER_WIDTH = '216px'

export function EmailCampaignTemplates() {
  const [isCreateCardHover, setCreateCardHover] = useState(false)
  const { venue, venueId } = useVenueContext()
  const nav = useNavigation()
  const { data, isFetching } = useGetOneTimeEmailCampaignTemplatesQuery({ venueId })
  const { field, watch } = useForm<typeof filtersSchema>(filtersSchema)
  const categories = useOneTimeEmailCampaignTemplateCategories()
  const [selectedCategories] = watch(['selectedCategories'])
  const { formatMessage } = useLocales()
  const filterTemplates = (data: EmailCampaignTemplate[] | undefined, selectedCategories: EmailCampaignTemplateCategoryEnum[]) => {
    if (!data) {
      return null
    }
    return data.filter(emailTemplate =>
      selectedCategories && selectedCategories.length > 0
        ? selectedCategories.find(selectedCategory => emailTemplate.templateCategories?.includes(selectedCategory))
        : true
    )
  }
  const templates = useMemo(() => filterTemplates(data?.templates, selectedCategories), [selectedCategories, data])
  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(oneTimeEmailCenterMessages.emailTemplatesTitle)} />
      <SettingsPageContent
        title={formatMessage(oneTimeEmailCenterMessages.emailTemplatesTitle)}
        breadcrumbs={
          <Breadcrumbs>
            <Link
              to={nav.href(routes.manager2.marketing.oneTimeEmailCenter, {
                params: { venueKey: venue.urlKey },
                queryMode: 'clear',
              })}
            >
              {formatMessage(oneTimeEmailCenterMessages.emailCampaignTemplatesLinkMessage)}
            </Link>
            <Text>{formatMessage(oneTimeEmailCenterMessages.emailCampaignTemplatesBreadCrumText)}</Text>
          </Breadcrumbs>
        }
      >
        <Box pb="m" pl="m" pr="m" width="100%">
          <Flex mb="m">
            <Box minWidth={EMAIL_CAMPAIGN_CATEGORY_FILTER_WIDTH}>
              <FormMultiSelect
                placeholder={formatMessage(campaignCenterMessages.categoryFilterPlaceholder)}
                field={field.prop('selectedCategories')}
                displayAsTextWhenCollapsed
                options={categories}
                showMoreLabel=""
                useCheckboxItems
                usePills={false}
                scrollLock
              />
            </Box>
          </Flex>

          {isFetching ? (
            <Loader />
          ) : (
            <Grid
              gridTemplateColumns={`repeat(auto-fill, minmax(${CAMPAIGN_TEMPLATE_CARD_WIDTH}, ${CAMPAIGN_TEMPLATE_CARD_WIDTH}))`}
              gap="m"
              alignItems="start"
            >
              <BasicButton
                onClick={() => {
                  nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
                    params: { venueKey: venue.urlKey },
                    queryMode: 'clear',
                  })
                }}
              >
                <BorderedBox>
                  <Flex
                    backgroundColor="primaryBackground"
                    maxHeight={EMAIL_CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    maxWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    height={EMAIL_CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    width={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    flexGrow="1"
                    flex="none"
                    pl="sm"
                    pr="sm"
                    pt="m"
                    pb="m"
                    onMouseOver={() => setCreateCardHover(true)}
                    onFocus={() => setCreateCardHover(true)}
                    onMouseOut={() => setCreateCardHover(false)}
                    onBlur={() => setCreateCardHover(false)}
                  >
                    <VStack alignItems="center" justifyContent="center" spacing="m">
                      <IconButton
                        borderType="round"
                        icon="VMSWeb-add"
                        iconBackgroundColor={isCreateCardHover ? 'primaryButton' : undefined}
                        iconColor={isCreateCardHover ? 'lightFont' : undefined}
                        hoverIconBackgroundColor={isCreateCardHover ? 'primaryHover' : undefined}
                        iconBorderColor={isCreateCardHover ? 'lightFont' : undefined}
                      />
                      <Header>{formatMessage(oneTimeEmailCenterMessages.emailTemplateEmptyCardTitle)}</Header>
                      <Text>{formatMessage(oneTimeEmailCenterMessages.emailTemplateEmptyCardText)}</Text>
                    </VStack>
                  </Flex>
                </BorderedBox>
              </BasicButton>
              {templates?.map((template: EmailCampaignTemplate) => (
                <VStack key={template.id}>
                  <EmailTemplatePreview
                    cardHeight={EMAIL_CAMPAIGN_TEMPLATE_CARD_HEIGHT}
                    cardWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH}
                    cardBackgroudColor="primaryBackground"
                    template={template}
                    scrollable={false}
                  />
                  <HStack mt="sm" spacing="xs" maxWidth={CAMPAIGN_TEMPLATE_CARD_WIDTH} flexWrap="wrap" rowGap="xs">
                    {template.templateCategories?.map((category: string) => (
                      <StatusLabel variant="inactive" key={category}>
                        {categories.find(({ id }) => id === category)?.label}
                      </StatusLabel>
                    ))}
                  </HStack>
                </VStack>
              ))}
            </Grid>
          )}
        </Box>
      </SettingsPageContent>
    </>
  )
}

const filtersSchema = z.object({
  selectedCategories: z.array(z.nativeEnum(EmailCampaignTemplateCategoryEnum)),
})
