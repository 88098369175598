import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface TooltipProps {
  title?: string
  content: string
}
export function Tooltip({ title, content }: TooltipProps) {
  return (
    <VStack>
      {!!title && (
        <Text color="lightFont" fontWeight="bold">
          {title}
        </Text>
      )}
      <Box mt="s">
        <Text color="lightFont">{content}</Text>
      </Box>
    </VStack>
  )
}
