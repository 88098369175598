import EditIcon from '@mui/icons-material/Edit'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { Button } from '@sevenrooms/react-components/components/Button'
import { useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { accessRulesSettingsMessages } from '../../locales'

export function CreateAccessRuleButton() {
  const { formatMessage } = useLocales()
  const permissions = useNavigationPermissionsContext()
  const showAccessRuleSlideout = useAccessRuleSlideout()

  const handleClick = () => {
    showAccessRuleSlideout()
  }

  return permissions?.canAdministrateShiftsAccessRules ? (
    <Button
      variant="contained"
      onClick={handleClick}
      startIcon={<EditIcon />}
      id="btn-create-access-rule"
      data-test="btn-create-access-rule"
    >
      {formatMessage(accessRulesSettingsMessages.createAccessRule)}
    </Button>
  ) : null
}
