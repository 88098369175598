// /* @flow */
import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { StyledIcons } from 'svr/common/VmsIcons'

const CheckboxLineWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const CheckboxLabel = styled.label`
  font-size: 15px;
  font-family: inherit;
  box-sizing: inherit;
  padding: 0 0 0 ${props => props.theme.gutter.standard};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  ${props => props.labelStyles};
`

const CheckboxIconWrapper = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  ${props => props.iconStyles};
`

const CheckboxIcon = styled(StyledIcons.S)`
  color: ${props => (props.isDisabled ? '#CCCCCC' : props.isSelected ? (props.checkedColor ? props.checkedColor : '#347baf') : '#888C92')};
`

const TooltipIcon = styled(StyledIcons.XS)`
  color: ${props => (props.tooltipColor ? props.tooltipColor : '#888C92')};
  padding: 0 0 0 ${props => props.theme.gutter.standard};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
`

const ReactTooltipStyled = styled(ReactTooltip)`
  width: ${props => props.width};
  text-align: center;
`

/* type Props = {
  field: string,
  onClick: (field: string) => mixed,
  label: string,
  checkedColor: string,
  tooltipColor: string,
  tooltipText: string,
  tooltipWidth: string,
  tooltipPlacement: string,
  dataTest: string,
  defaultCheckboxColor: string,
  isSelected: boolean,
  isDisabled: boolean,
  selectedIcon: mixed,
  unselectedIcon: mixed,
  tooltipIcon: mixed,
  iconStyles: Array<string | (() => mixed)>,
  labelStyles: Array<string | (() => mixed)>,
} */

class VmsCheckbox extends React.Component /* <Props> */ {
  static defaultProps = {
    isSelected: false,
    isDisabled: false,
    defaultCheckboxColor: '#888C92',
    label: '',
    tooltipText: '',
    dataTest: '',
    tooltipPlacement: 'top',
    tooltipWidth: '230px',
  }

  checkboxId = `sr-checkbox-${this.props.field}`

  clickHandler = () => {
    if (!this.props.isDisabled) {
      this.props.onClick(this.props.field)
    }
  }

  render() {
    return (
      <CheckboxLineWrapper onClick={this.clickHandler} data-test={this.props.dataTest}>
        <CheckboxIconWrapper id={`${this.checkboxId}-wrapper`} isDisabled={this.props.isDisabled} iconStyles={this.props.iconStyles}>
          <CheckboxIcon
            id={this.checkboxId}
            checkedColor={this.props.checkedColor}
            isSelected={this.props.isSelected}
            isDisabled={this.props.isDisabled}
          >
            {this.props.isSelected
              ? this.props.overrideIcon
                ? this.props.overrideIcon
                : this.props.selectedIcon
              : this.props.unselectedIcon}
          </CheckboxIcon>
        </CheckboxIconWrapper>
        {this.props.label && this.props.label.length !== 0 && (
          <CheckboxLabel labelStyles={this.props.labelStyles} htmlFor={this.checkboxId} isDisabled={this.props.isDisabled}>
            {this.props.label}
          </CheckboxLabel>
        )}
        {this.props.tooltipIcon && this.props.tooltipText && this.props.tooltipText.length !== 0 && (
          <TooltipIcon
            data-for={`${this.checkboxId}-tooltip`}
            data-tip={this.props.tooltipText}
            data-place={this.props.tooltipPlacement}
            tooltipColor={this.props.tooltipColor}
            isDisabled={this.props.isDisabled}
          >
            {this.props.tooltipIcon}
          </TooltipIcon>
        )}
        <ReactTooltipStyled id={`${this.checkboxId}-tooltip`} width={this.props.tooltipWidth} />
      </CheckboxLineWrapper>
    )
  }
}

export default VmsCheckbox
