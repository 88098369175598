import { type OneTimeEmailCampaignType, useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { formatAndFilterTags } from '@sevenrooms/mgr-marketing/utils'
import { oneTimeEmailCenterMessages } from '../../../locales'

export function CampaignDetails({ emailCampaign }: { emailCampaign: OneTimeEmailCampaignType }) {
  const { formatMessage } = useLocales()
  const { venueKey } = useVenueContext()

  const { data: autoTags } = useGetClientTagGroupsQuery({ venueKey })
  const formattedRecipientClientTags = formatAndFilterTags(emailCampaign?.recipientClientTags, autoTags)
  const formattedRecipientClientTagsExclude = formatAndFilterTags(emailCampaign?.recipientClientTagsExclude, autoTags)
  const recipients = emailCampaign.oneTimeSendTimeConfig?.estimatedNumClients ?? 0

  const details = [
    {
      field: formatMessage(oneTimeEmailCenterMessages.campaignName),
      content: emailCampaign.campaignName,
    },
    {
      field: formatMessage(oneTimeEmailCenterMessages.sendTime),
      content: emailCampaign.sentDateTimeLocal?.formatFMonthNYearSTime(),
    },
    {
      field: formatMessage(emailBuilderMessages.audienceDescription),
      content: (
        <VStack>
          <Box>
            <Text fontWeight={700}>{formattedRecipientClientTags.join(', ')}</Text>{' '}
            <Text>{formatMessage(oneTimeEmailCenterMessages.oneTimeEmailDetailsRecipients, { recipients })}</Text>
          </Box>
          {formattedRecipientClientTagsExclude.length > 0 && (
            <Box>
              <Text>{formatMessage(oneTimeEmailCenterMessages.oneTimeEmailDetailsExclude)}</Text>{' '}
              <Text fontWeight={700}>{formattedRecipientClientTagsExclude.join(', ')}</Text>
            </Box>
          )}
        </VStack>
      ),
    },
    {
      field: formatMessage(emailBuilderMessages.emailSubjectLineTitle),
      content: emailCampaign.emailSubject,
    },
  ]

  return (
    <BaseSection title={formatMessage(emailBuilderMessages.emailDetailsTitle)} padding="lm">
      <VStack pr="lm" pl="lm" pt="m" pb="m">
        {details.map((detail, index) => (
          <HStack borderTopColor={index > 0 ? 'margin' : undefined} pt="s" pb="s" key={detail.field}>
            <Text width="200px">{detail.field}</Text>
            <Text width="300px">{detail.content}</Text>
          </HStack>
        ))}
      </VStack>
    </BaseSection>
  )
}
