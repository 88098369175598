import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationCoverLimitTestId } from '../../ReservationCoverLimit/ReservationCoverLimit.testIds'
import { getTagDisplayName, useAccessRuleContext } from '../../shared'
import { ReservationTagsLocales } from '../ReservationTags.locales'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function ReservationTagsReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { tagGroups } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const { reservationTags } = accessRule

  return (
    <ReportPart caption={formatMessage(ReservationTagsLocales.title)} data-test={ReservationCoverLimitTestId.report}>
      <ReportLine name={formatMessage(ReservationTagsLocales.title)}>
        <Text>
          {reservationTags.length > 0
            ? reservationTags.map(tag => getTagDisplayName(tag, tagGroups)).join(', ')
            : formatMessage(commonMessages.none)}
        </Text>
      </ReportLine>
    </ReportPart>
  )
}
