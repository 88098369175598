import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { tagNameCell, tagTypeCell, withNullHandler, numberCell, currencyCell, deltaCell } from 'svr/component-lib/Generic/Tables/Cells'
import Tooltip from 'svr/component-lib/Generic/Tooltip'

const AutoTagsListContainer = styled.div`
  flex: 1;
`

const customTableContainerStyles = css`
  padding: 0;
  background-color: ${props => props.theme.color.white};
`

const TagContents = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const getToolTip = props => (
  <Tooltip minWidth="100px" content={props.value}>
    <TagContents>{tagNameCell(props)}</TagContents>
  </Tooltip>
)

const activityColumns = deltaCell => [
  {
    Header: 'ADDED CLIENTS',
    accessor: 'num_tags_added',
    Cell: withNullHandler(deltaCell),
    className: 'align-right highlighted',
    headerClassName: 'align-right highlighted',
  },
  {
    Header: 'REMOVED CLIENTS',
    accessor: 'num_tags_removed',
    Cell: withNullHandler(deltaCell),
    className: 'align-right highlighted',
    headerClassName: 'align-right highlighted',
  },
]

const firstHeaderGroupWithColumn = defaultFirstHeaderGroup =>
  defaultFirstHeaderGroup
    ? {
        Header: 'INACTIVE',
        columns: [
          {
            Header: 'TAG NAME',
            accessor: 'tag_name_display',
            Cell: props => getToolTip(props),
            width: 200,
          },
        ],
      }
    : {
        Header: 'TAG NAME',
        accessor: 'tag_name_display',
        Cell: props => getToolTip(props),
        width: 200,
      }

const lastHeaderGroupWithColumn = (dateRange, deltaCell, defaultLastHeaderGroup) =>
  defaultLastHeaderGroup
    ? {
        Header: '',
        columns: activityColumns(deltaCell),
      }
    : {
        Header: `Activity ${dateRange}`,
        headerClassName: 'highlighted',
        columns: activityColumns(deltaCell),
      }

const autoTagColumns = (dateRange, currencyCode, locale, defaultFirstHeaderGroup = false, defaultLastHeaderGroup = false) => [
  firstHeaderGroupWithColumn(defaultFirstHeaderGroup),
  {
    Header: 'CATEGORY',
    accessor: 'category',
  },
  {
    Header: 'TYPE',
    accessor: 'autotag_type_label',
    Cell: tagTypeCell,
  },
  {
    Header: 'TOTAL CLIENTS',
    accessor: 'total_clients',
    Cell: withNullHandler(numberCell()),
    className: 'align-right',
    headerClassName: 'align-right',
  },
  {
    Header: 'AVG VISITS',
    accessor: 'average_visits',
    Cell: withNullHandler(numberCell({ toDigits: 1 })),
    className: 'align-right',
    headerClassName: 'align-right',
  },
  {
    Header: 'AVG SPEND/COVER',
    accessor: 'average_spend_per_cover',
    Cell: withNullHandler(locale ? currencyCell.bind(null, currencyCode, locale.replace('_', '-'), 0) : numberCell({ toDigits: 0 })),
    className: 'align-right',
    headerClassName: 'align-right',
  },
  lastHeaderGroupWithColumn(dateRange, deltaCell, defaultLastHeaderGroup),
]

const AutoTagsList = ({ activeTags, inactiveTags, isLoading, dateRange, rowClick, currencyCode, locale, firstThClick, defaultSort }) => {
  const activeColumns = autoTagColumns(dateRange, currencyCode, locale)
  const inactiveColums = autoTagColumns(dateRange, currencyCode, locale, true, true)

  const getStyle = (state, value) => {
    if (value != null) {
      const { original } = value
      return {
        style: {
          opacity: original.is_active && original.is_edited ? 0.5 : 1,
        },
      }
    }
    return {
      style: { opacity: 1 },
    }
  }

  return (
    <AutoTagsListContainer>
      {inactiveTags.length || activeTags.length ? (
        <div>
          <Table
            data={activeTags}
            customTableContainerStyles={customTableContainerStyles}
            showPagination={false}
            pageSize={activeTags.length}
            isLoading={isLoading}
            columns={activeColumns}
            className="active_table"
            noDataText={isLoading ? 'Loading...' : 'No active tags found.'}
            rowClick={rowClick}
            thClick={firstThClick}
            customTrProps={getStyle}
            defaultSorted={[defaultSort]}
          />
          <Table
            data={inactiveTags}
            customTableContainerStyles={customTableContainerStyles}
            showPagination={false}
            isLoading={isLoading}
            pageSize={inactiveTags.length}
            columns={inactiveColums}
            className="inactive_table hide-header-only inactive-group-header disabled"
            noDataText={isLoading ? 'Loading...' : 'No inactive tags found.'}
            rowClick={rowClick}
            defaultSorted={[defaultSort]}
          />
        </div>
      ) : null}
    </AutoTagsListContainer>
  )
}

AutoTagsList.propTypes = {
  activeTags: PropTypes.array,
  inactiveTags: PropTypes.array,
  isLoading: PropTypes.bool,
  dateRange: PropTypes.string,
  rowClick: PropTypes.func,
  currencyCode: PropTypes.string,
  locale: PropTypes.string,
  firstThClick: PropTypes.func,
  defaultSort: PropTypes.object,
}

export default AutoTagsList
