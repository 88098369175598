import React from 'react'
import { HStack, type HStackProps } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface JoinProps extends HStackProps {
  delimiter: string
}

export function Join({ children, delimiter, ...props }: React.PropsWithChildren<JoinProps>) {
  const last = React.Children.count(children) - 1
  return (
    <HStack spacing="s" {...props}>
      {React.Children.map(children, (node, idx) => (
        <HStack>
          {node}
          {idx < last && <Text>{delimiter}</Text>}
        </HStack>
      ))}
    </HStack>
  )
}
