import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'paymentsSettings.AdditionalPaymentIntegrations.title',
    defaultMessage: 'Additional Payment Processors',
  },
  description: {
    id: 'paymentsSettings.AdditionalPaymentIntegrations.description',
    defaultMessage: 'These processors may take longer to set up and have technical requirements for their integrations.',
  },
} as const)
