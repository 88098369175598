import { useMemo } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { PacingLocales } from './Pacing.locales'

export type PacingForm = ZodSchema<typeof usePacingForm>

export function usePacingForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z
        .object({
          maxCoversPerSeatingInterval: z.number({ required_error: formatMessage(PacingLocales.pacingDefaultValueRequired) }).nullable(),
          customPacing: z.record(z.number().nullable().optional()),
          isPacingHeld: z.boolean(),
          setCustomPacing: z.boolean(),
          excludeFromShiftPacing: z.boolean(),
        })
        .superRefine(({ maxCoversPerSeatingInterval, setCustomPacing, isPacingHeld }, ctx) => {
          if (maxCoversPerSeatingInterval === null && (setCustomPacing || isPacingHeld)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PacingLocales.limitRequired),
              path: ['maxCoversPerSeatingInterval'],
            })
          }
        }),
    [formatMessage]
  )
}

export function getInitialPacing(accessRule?: AccessRule): PacingForm {
  return {
    maxCoversPerSeatingInterval: accessRule?.defaultPacing ?? null,
    customPacing: accessRule?.customPacing ?? {},
    isPacingHeld: accessRule?.isPacingHeld ?? false,
    setCustomPacing: Object.keys(accessRule?.customPacing ?? {}).length > 0,
    excludeFromShiftPacing: accessRule?.excludeFromShiftPacing ?? false,
  }
}
