import { defineMessages } from '@sevenrooms/core/locales'

export const reviewsMessages = defineMessages({
  responseCopied: {
    id: 'reviews.responseCopied',
    defaultMessage: 'Response copied!',
  },
  responseCopyError: {
    id: 'reviews.responseCopyError',
    defaultMessage: 'Error copying response, please try again later.',
  },
  generateResponse: {
    id: 'reviews.generateResponse',
    defaultMessage: 'Generate a response to review',
  },
  regenerateResponse: {
    id: 'reviews.regenerateResponse',
    defaultMessage: 'Regenerate response',
  },
  aiSummaryHeader: {
    id: 'reviews.aiSummaryHeader',
    defaultMessage: '{icon} AI Weekly Summary - {start} to {end}',
  },
  aiSummarySlideoutMessage: {
    id: 'reviews.aiSummarySlideoutMessage',
    defaultMessage:
      'This summary has been generated via Generative AI based on the feedback and reviews you received during this week. We suggest using this in conjunction with reviewing individual results as some elements may be lacking significant support.',
  },
  aiSummarySlideoutLoadingMessage: {
    id: 'reviews.aiSummarySlideoutLoadingMessage',
    defaultMessage: 'Loading Guest Highlights, Lowlights and Improvements...',
  },
  aiGuestHighlights: {
    id: 'reviews.aiGuestHighlights',
    defaultMessage: '{icon} Guest Highlights',
  },
  aiGuestHighlightsNotAvailable: {
    id: 'reviews.aiGuestHighlightsNotAvailable',
    defaultMessage: 'No highlights available for this week',
  },
  aiGuestLowlights: {
    id: 'reviews.aiGuestLowlights',
    defaultMessage: '{icon} Guest Lowlights',
  },
  aiGuestLowlightsNotAvailable: {
    id: 'reviews.aiGuestLowlightsNotAvailable',
    defaultMessage: 'No lowlights available for this week',
  },
  aiAreasForImprovement: {
    id: 'reviews.aiAreasForImprovement',
    defaultMessage: '{icon} Areas for Improvement',
  },
  aiAreasForImprovementNotAvailable: {
    id: 'reviews.aiAreasForImprovementNotAvailable',
    defaultMessage: 'No areas for improvement available for this week',
  },
  aiSummaryNextWeek: {
    id: 'reviews.aiSummaryNextWeek',
    defaultMessage: 'Next Week',
  },
  aiSummaryLastWeek: {
    id: 'reviews.aiSummaryLastWeek',
    defaultMessage: 'Last Week',
  },
  aiSummarySeeDetails: {
    id: 'reviews.aiSummarySeeDetails',
    defaultMessage: 'See Details >',
  },
})
