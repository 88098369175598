import { useMemo } from 'react'
import { AccessRuleTimeUnitEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTreeForm } from '@sevenrooms/core/ui-kit/form'
import { timeBeforeForm } from '@sevenrooms/mgr-access-rules-slideout/components/shared'

export type PerkCreateAccessRuleModalFormData = ZodSchema<typeof usePerkCreateAccessRuleModalForm>
export type PerkCreateAccessRuleModalFormField = Field<PerkCreateAccessRuleModalFormData>

export interface TreeItemValue {
  isTable: boolean
  isBookable?: boolean
}

export function usePerkCreateAccessRuleModalForm() {
  const seatingAreasTables = useMultiSelectTreeForm<TreeItemValue>()
  return useMemo(
    () =>
      z.object({
        seatingAreasTables,
        enableReleaseTables: z.number(),
        releaseTableTimeData: timeBeforeForm,
        cloneExistingAccessRule: z.number(),
        accessRuleToCloneId: z.string(),
      }),
    [seatingAreasTables]
  )
}

export const getPerkCreateAccessRuleModalDefaultValues = (isExistingAccessRules: boolean): PerkCreateAccessRuleModalFormData => ({
  seatingAreasTables: [],
  enableReleaseTables: 1,
  releaseTableTimeData: {
    count: 5,
    unit: AccessRuleTimeUnitEnum.HOURS,
    beforeTime: '0',
  },
  cloneExistingAccessRule: isExistingAccessRules ? 1 : 0,
  accessRuleToCloneId: '',
})
