import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  // Locations Error Banner
  locationsErrorBannerTitle: {
    id: 'posiSettings.IntegrationStatusPage.locationsErrorBannerTitle',
    defaultMessage: 'Error',
  },
  locationsErrorBannerDescription: {
    id: 'posiSettings.IntegrationStatusPage.locationsErrorBannerDescription',
    defaultMessage: `Encountered the following error: "{error}"`,
  },

  tabTitle: {
    id: 'posiSettings.IntegrationStatusPage.tabTitle',
    defaultMessage: 'Point of Sale',
  },
  title: {
    id: 'posiSettings.IntegrationStatusPage.title',
    defaultMessage: 'Point of Sale Integrations',
  },
  description: {
    id: 'posiSettings.IntegrationStatusPage.description',
    defaultMessage: 'Manage and configure your Point of Sale Integrations. <a>Read more</a>',
  },
} as const)
