import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
  ModalBody,
  type ModalHeaderProps,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './TableMappingWizardModal.locales'

export interface TableMappingWizardModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function TableMappingWizardModal({ closeHref }: TableMappingWizardModalProps) {
  const { formatMessage } = useLocales()
  return (
    <Modal ariaLabel="Modal">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <ModalTitle title={formatMessage(messages.title)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>This is where the wizard will go </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={() => {}} data-test="save-button">
            {formatMessage(commonMessages.save)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
