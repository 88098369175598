import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import LazyImage from 'mgr/lib/components/LazyImage'
import { TagElement } from 'mgr/lib/components/Tags'
import { ShiftCategories, DayRanges } from 'mgr/lib/utils/Constants'
import { getContrastingColor } from 'svr/common/Color'

const ModalTopBar = ({ onCloseClick }) => (
  <div className="row top-bar">
    <div className="col-xs-1 col-xs-offset-11 search-close-bar">
      <a onClick={onCloseClick} style={{ cursor: 'pointer' }}>
        <i
          className="svr-icon- svr-icon--close close-icon"
          style={{
            color: '#FFFFFF',
            position: 'absolute',
            fontSize: '2.5rem',
            fontWeight: 100,
            top: '.5rem',
            right: '.5rem',
          }}
        />
      </a>
    </div>
  </div>
)

const SearchContainer = styled.div`
  margin: 1rem 4rem 0 4rem;
`

export const SearchModal = ({ isModalOpen, onCloseClick, children, onKeyDown }) => {
  window.document.body.addEventListener('keydown', onKeyDown)

  return (
    <div className="enable-bootstrap">
      <div className={`linking-search ${isModalOpen ? 'open' : 'closed'}`} style={{ width: 'auto', right: '398px', overflow: 'scroll' }}>
        <SearchContainer className="container-fluid search-container">
          <ModalTopBar onCloseClick={onCloseClick} />
          {children}
        </SearchContainer>
      </div>
    </div>
  )
}

const ReservationAndRequestLinkBar = ({ searchQuery, venueUrlKey }) => {
  const baseLink = `/manager/${venueUrlKey}`
  const encodedSearchParam = window.encodeURIComponent(searchQuery)
  const resLink = `${baseLink}/reservations/day/?q=${encodedSearchParam}`
  const reqLink = `${baseLink}/requests/all?q=${searchQuery}`

  return (
    <div className="row">
      <a href={resLink} className="col-xs-5 link" target="_blank" rel="noreferrer">
        Show all matching reservations
      </a>
      <span className="col-xs-2 link">|</span>
      <a href={reqLink} className="col-xs-5 link" target="_blank" rel="noreferrer">
        Show all matching requests
      </a>
    </div>
  )
}

export const TabRow = ({ tabs, activeTab, onTabChange }) => {
  const colSize = Math.floor(12 / tabs.length)
  const colSizeClass = `col-xs-${colSize} tab`

  return (
    <div className="row  tab-bar visible-xs-block">
      {tabs.map(elem => {
        const tabChangeAdapter = () => {
          onTabChange(elem.action)
        }
        return (
          <div key={elem.action} className={colSizeClass}>
            <a className={`tab ${elem.action === activeTab ? 'active' : ''}`} onClick={tabChangeAdapter}>
              {elem.text}
            </a>
          </div>
        )
      })}

      <div className="row">
        <hr className="col-xs-12 spacer" style={{ marginTop: 0 }} />
      </div>
    </div>
  )
}

export const ResultRow = ({
  singleResult,
  onRequestClick,
  onReservationClick,
  isRequestSlideoutAvailable,
  isReservationSlideoutAvailable,
  isSingleVenue,
  venueUrlKey,
  isSupaflyEnabled,
  reviewId,
}) => {
  let resultType
  const isActual = singleResult.type === 'ACTUAL'
  const isRequest = singleResult.type === 'REQUEST'
  const isSameVenue = singleResult.venue_key === venueUrlKey

  if (isActual) {
    resultType = <i className="svr-icon- svr-icon--book" />
  } else if (isRequest) {
    resultType = <i className="svr-icon- svr-icon--request-line" />
  } else {
    resultType = <i className="svr-icon- svr-icon--help" />
  }

  let vipFlagContents

  if (singleResult.is_client_vip) {
    vipFlagContents = <span className="vip-flag" />
  }
  const showFor = singleResult.arrival_time_display && singleResult.max_guests_short_formatted ? 'for' : ''
  const guestDisplay = ` ${showFor} ${singleResult.max_guests_short_formatted}`
  const isRedStatus =
    singleResult.status_formatted === 'Cancelled' ||
    singleResult.status_formatted === 'No Show' ||
    singleResult.status_formatted === 'Canceled'
  const resultStyle = { cursor: 'pointer' }
  let href
  let target
  let onClick
  let callback
  if (isRequest && isRequestSlideoutAvailable && isSameVenue) {
    callback = () => {
      onRequestClick(singleResult.id)
    }

    onClick = callback
  } else if (isActual && isReservationSlideoutAvailable && (isSameVenue || isSupaflyEnabled)) {
    // Todo: supafly can support other venues now, but first check user setting if supafly is enabled for this venue
    callback = () => {
      onReservationClick(singleResult.id, singleResult.venue_id, reviewId)
    }
    onClick = callback
  } else {
    href = singleResult.detail_link
    target = '_blank'
  }

  const canceledStyle = isRedStatus ? { color: '#F75A6D' } : {}

  const noteStyle = {
    fontStyle: 'oblique',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  }

  let venueName = null

  if (!isSingleVenue) {
    venueName = <div className="col-xs-12 col-sm-5 result-text venue-name">{singleResult.venue_name}</div>
  }
  return (
    <li className="row result res">
      <a style={resultStyle} target={target} href={href} onClick={onClick}>
        {vipFlagContents}
        <div className="col-xs-1 result-text result-type  result-icon">{resultType}</div>
        <div className="col-xs-5  col-lg-5">
          <div className="row">
            <div className={`col-xs-12 ${isSingleVenue ? 'col-sm-12' : 'col-sm-7'} client-name result-text name`}>
              {singleResult.client_display_name}
            </div>
            {venueName}
          </div>
        </div>

        <div className="col-xs-4">
          <div className="row">
            <div className="col-xs-12 col-sm-6 result-text display-date">{singleResult.date_formatted_short_abbrev_display_year}</div>
            <div className="col-xs-12 col-sm-6 result-text arrival-time">
              {singleResult.arrival_time_display}
              {guestDisplay}
            </div>
          </div>
        </div>
        <div className="col-xs-2 col-lg-2 result-text status" style={canceledStyle}>
          {singleResult.status_formatted}
        </div>
        {singleResult.note ? (
          <div className="col-xs-11 col-xs-offset-1 result-text status" style={noteStyle}>
            {singleResult.note}
          </div>
        ) : null}
      </a>
    </li>
  )
}

export const CombinedBookingSearchResultsSection = ({
  pastBookings,
  futureBookings,
  activeTab,
  isRequestSlideoutAvailable,
  isReservationSlideoutAvailable,
  searchQuery,
  venueUrlKey,
  onReservationClick,
  onRequestClick,
  hasMoreResults,
  isSingleVenue,
  isSupaflyEnabled,
  reviewId,
}) => {
  const bookingsToElements = bookings =>
    bookings.map((element, index) => (
      <ResultRow
        key={element.id}
        singleResult={element}
        venueUrlKey={venueUrlKey}
        isRequestSlideoutAvailable={isRequestSlideoutAvailable}
        isReservationSlideoutAvailable={isReservationSlideoutAvailable}
        isSupaflyEnabled={isSupaflyEnabled}
        onReservationClick={onReservationClick}
        onRequestClick={onRequestClick}
        isSingleVenue={isSingleVenue}
        reviewId={reviewId}
      />
    ))

  const doHide = activeTabAction => (activeTab === activeTabAction ? 'col-xs-12' : 'hidden-xs')

  let pastBookingElements

  if (pastBookings.length > 0) {
    pastBookingElements = <ul>{bookingsToElements(pastBookings)}</ul>
  } else {
    pastBookingElements = (
      <div className="row">
        <h3 className="col-xs-12  no-results" style={{ marginBottom: '20px' }}>
          <hr className="spacer hidden-xs" />
          No Results
        </h3>
      </div>
    )
  }

  const pastBookingElementsWithHeader = (
    <div className="row">
      <div className={`col-sm-12 ${doHide('past')}`}>
        <div className="row">
          <h3 className="col-sm-12 hidden-xs search-result-header">Past reservations</h3>
        </div>
        {pastBookingElements}
      </div>
    </div>
  )

  return <div className="col-sm-12 col-xs-12">{pastBookingElementsWithHeader}</div>
}

const SearchBox = styled.div`
  margin-bottom: 2rem;
  padding-left: 0;
  padding-right: 0;
  width: 90%;
`

const Filters = styled.div`
  flex: 1;
  margin: 0 15px;
  width: 90%;
`

const SearchArea = styled.div`
  position: relative;
  z-index: 1;
`

export class SearchQueryArea extends React.Component {
  constructor(props) {
    super(props)

    this.timeChoices = [
      { name: 'Last 3 days', value: DayRanges.L3DAY },
      { name: 'Last 7 days', value: DayRanges.L7DAY },
      { name: 'Last 30 days', value: DayRanges.L30DAY },
      { name: 'This Month', value: DayRanges.THISMONTH },
      { name: 'Last Month', value: DayRanges.LASTMONTH },
      { name: 'This Year', value: DayRanges.THISYEAR },
      { name: 'Last Year', value: DayRanges.LASTYEAR },
    ]

    this.shiftChoices = Object.keys(ShiftCategories).map(shift => ({
      name: ShiftCategories[shift],
      value: shift,
    }))

    this.shiftChoices.unshift({ name: 'All Shifts', value: 'ALL' })
  }

  componentDidUpdate() {
    if (this.props.hasFocus) {
      const node = ReactDOM.findDOMNode(this.textInput)
      window.setTimeout(() => {
        node.focus()
      }, 0)
    }
  }

  render() {
    const dropStyle = {
      display: 'inline-block',
    }

    return (
      <SearchArea className="row search-entry">
        <div className="col-sm-9 col-xs-12 search-box" style={{ width: '90%' }}>
          <i className="svr-icon- svr-icon--search search-icon text" />
          <input
            ref={input => {
              this.textInput = input
            }}
            value={this.props.searchQuery}
            type="text"
            name="common-query"
            placeholder="Search"
            id="linking-query"
            className="search-input text"
            onChange={this.props.onSearchChange}
            autoFocus
            style={{ width: '100%' }}
          />
          <p
            style={{
              textAlign: 'right',
              color: '#FFFFFF',
              fontSize: '1rem',
              marginTop: '1rem',
            }}
          >
            <span style={{ opacity: 0.8 }}>press</span> <strong style={{ fontWeight: '900', opacity: 1, color: '#FFFFFF' }}>ESC</strong>
            <span style={{ opacity: 0.8 }}> to close</span>
          </p>
        </div>
        <Filters>
          <DropdownArrowsPicker
            name="Date"
            useOutsideLabel
            choices={this.timeChoices}
            value={this.props.searchTime}
            style={dropStyle}
            onChangeHandler={this.props.onTimeSearchChange}
          />
          <DropdownArrowsPicker
            name="Shift"
            useOutsideLabel
            choices={this.shiftChoices}
            value={this.props.searchShift}
            style={dropStyle}
            onChangeHandler={this.props.onShiftSearchChange}
          />
        </Filters>
      </SearchArea>
    )
  }
}

const ClientResult = ({ singleResult }) => {
  let clientImageContents
  const imageSizes = ['small', 'medium', 'large']
  const imageSrcs = []
  let vipFlagContents

  if (singleResult.is_vip) {
    vipFlagContents = <span className="vip-flag" />
  }
  imageSizes.forEach(elem => {
    if (singleResult.photos && singleResult.photos[elem]) {
      imageSrcs.push(singleResult.photos[elem])
    }
  })

  if (singleResult.photos) {
    // clientImageContents = <img src={singleResult.photos.small} className="client-image"
    //                             alt={singleResult.name_first_first_display}/>
    clientImageContents = <LazyImage src={imageSrcs} className="client-image" alt={singleResult.name_first_first_display} />
  } else {
    clientImageContents = <div className="client-image">{singleResult.initials}</div>
  }

  const emailContents = singleResult.email && (
    <div className="col-xs-12  col-lg-7 result-text client-email fs-hide">{singleResult.email}</div>
  )
  const phoneContents = singleResult.phone_number_formatted && (
    <div className="col-xs-12  col-lg-5  result-text client-phone fs-hide">{singleResult.phone_number_formatted}</div>
  )

  let visitsLanguage = ''

  if (singleResult.total_visits === 1) {
    visitsLanguage = 'VISIT'
  } else if (singleResult.total_visits > 1) {
    visitsLanguage = 'VISITS'
  }
  return (
    <li className="row result">
      <a href={singleResult.detail_link} target="_blank" rel="noreferrer">
        {vipFlagContents}
        <div className="col-xs-1" style={{ paddingRight: 0, paddingLeft: 0 }}>
          {clientImageContents}
        </div>
        <div className="col-xs-8  result-text">
          <div className="row">
            <div className="col-xs-12  col-lg-5 result-text name">{singleResult.name_first_first_display}</div>
            <div className="hidden-xs hidden-sm col-lg-7 result-text">{singleResult.title}</div>
          </div>
          <div className="row fs-hide">
            {phoneContents}
            {emailContents}
          </div>

          <div className="row">
            <div className="col-xs-12 client-tags">
              {(singleResult.client_tags_display || []).map((elem, idx) => {
                const key = `${elem.tag_group_id}##${elem.tag_name}`
                return <TagElement key={key} tagObj={elem} />
              })}
            </div>
          </div>
        </div>
        <div className="col-xs-3  result-text visits">
          <strong className="visit-number">{singleResult.total_visits > 0 ? singleResult.total_visits : ''}</strong> {visitsLanguage}
        </div>
      </a>
    </li>
  )
}

export const ClientSearchResultsArea = ({ clients, activeTab }) => {
  let clientSearchResults

  if (clients.length === 0) {
    clientSearchResults = (
      <div className="row">
        <h3 className="col-xs-12 spacer no-results">
          <hr className="spacer hidden-xs" />
          No Results
        </h3>
      </div>
    )
  } else {
    clientSearchResults = (
      <ul>
        {' '}
        {clients.map((elem, index) => (
          <ClientResult key={elem.id} singleResult={elem} />
        ))}
      </ul>
    )
  }

  const tabClass = `col-sm-4  col-sm-offset-1 ${activeTab === 'clients' ? 'col-xs-12' : 'hidden-xs'}`

  return (
    <div className={tabClass}>
      <div className="row">
        <h3 className="col-sm-12 hidden-xs search-result-header">Clients</h3>
      </div>
      {clientSearchResults}
    </div>
  )
}
