import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { GuestDurationPickerFields, CollapsedGuestDurationPicker, DefaultGuestDurationPicker } from './components'
import { GuestDurationPickerLocales } from './GuestDurationPicker.locales'
import { GuestDurationPickerTestId } from './GuestDurationPicker.testIds'
import type { GuestDurationPickerForm } from './GuestDurationPicker.zod'
import type { DurationsForm } from '../Durations/Durations.zod'

export interface GuestDurationPickerProps {
  field: Field<GuestDurationPickerForm>
  durationsField: Field<DurationsForm>
}

export function GuestDurationPicker({ field, durationsField }: GuestDurationPickerProps) {
  const { formatMessage } = useLocales()
  const { useShiftDurations } = useWatch(durationsField)

  return (
    <AccessRuleSlideoutSection
      title={formatMessage(GuestDurationPickerLocales.title)}
      description={formatMessage(GuestDurationPickerLocales.description)}
      subCaption={formatMessage(GuestDurationPickerLocales.subCaption)}
      contentWhenCollapsed={<CollapsedGuestDurationPicker field={field} />}
      defaultSettings={<DefaultGuestDurationPicker showDurationsEnabledWarning={!useShiftDurations} />}
      sectionControllerName="guestDurationPicker"
      field={field}
      data-test={GuestDurationPickerTestId.section}
      disableEdit={!useShiftDurations}
    >
      <GuestDurationPickerFields field={field} />
    </AccessRuleSlideoutSection>
  )
}
