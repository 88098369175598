import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { DisplayIfSelected } from '../../../ChargeSettings'
import { PaymentPolicyLocales } from '../../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import { Cutoff, RadioSection, ChargeChoices } from './RadioComponents'
import type { PaymentPolicyForm } from '../../PaymentPolicy.zod'
import type { PaymentPolicyProps } from '../PaymentPolicyProps'

export function CancelChargeRadios({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()

  const choices: RadioChoice<PaymentPolicyForm['cancelCharge']>[] = useMemo(
    () => [
      {
        value: 'NO_CHARGE',
        label: formatMessage(PaymentPolicyLocales.penaltyChoiceNone),
      },
      {
        value: 'CANCELLATION_NO_SHOW_CHARGE',
        label: formatMessage(PaymentPolicyLocales.penaltyChoiceCharge),
        description: (
          <DisplayIfSelected field={field.prop('cancelCharge')} option="CANCELLATION_NO_SHOW_CHARGE">
            <NoShowCharge field={field} />
          </DisplayIfSelected>
        ),
      },
    ],
    [formatMessage, field]
  )
  return (
    <RadioSection
      data-test={PaymentPolicyTestId.cancelChargeRadios}
      choices={choices}
      field={field.prop('cancelCharge')}
      primaryLabel={formatMessage(PaymentPolicyLocales.cancelCharge)}
    />
  )
}

function NoShowCharge({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="s">
      <ChargeChoices data-test={PaymentPolicyTestId.noShowCharge} field={field.prop('autoCharge')} />
      <Cutoff
        field={field}
        label={formatMessage(PaymentPolicyLocales.setChargeCutoff)}
        description={formatMessage(PaymentPolicyLocales.setChargeCutoffDescription)}
      />
    </VStack>
  )
}
