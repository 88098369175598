import { useMemo } from 'react'
import type { AccessRule, ExperiencesData } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Box, Image, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import Empty from '@sevenrooms/mgr-offers/experiences/images/Empty.svg'
import { routes } from '@sevenrooms/routes'
import { spacesMessages } from '../spaces.locales'
import { SpaceCard } from './SpaceCard'

interface SpacesListProps {
  experiences: ExperiencesData['results']
  accessRules: AccessRule[]
}

export function SpacesList({ experiences, accessRules }: SpacesListProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()

  const spacesList = useMemo(
    () => ({
      draft: experiences.filter(experience => experience.isDraft),
      active: experiences.filter(experience => experience.isActive),
      inactive: experiences.filter(experience => experience.isInactive),
    }),
    [experiences]
  )

  return (
    <VStack data-test="experiences-list" spacing="lm" pb="lm">
      {!spacesList?.draft.length && !spacesList?.active.length && !spacesList?.inactive.length && (
        <VStack alignItems="center">
          <Image src={Empty} alt="" />
          <Box mt="l">
            <Text fontWeight="bold" fontSize="l">
              {formatMessage(spacesMessages.noSpacesTitle)}
            </Text>
          </Box>
          <Box mt="s">
            <Text color="secondaryFont">
              {formatMessage(spacesMessages.emptySpacesNoData, {
                a: (chunks: never) => (
                  <Link to={nav.href(routes.manager2.marketing.groupBookings.createSpace, { params: { venueKey } })}>{chunks}</Link>
                ),
              })}
            </Text>
          </Box>
        </VStack>
      )}
      {spacesList.draft.length > 0 && (
        <VStack spacing="sm">
          <Header type="h2" lineHeight="xl" width="100%">
            {formatMessage(spacesMessages.draftsTitle)}
          </Header>
          <VStack spacing="m">
            {spacesList.draft.map(space => (
              <SpaceCard key={space.id} space={space} accessRules={accessRules.filter(rule => rule.experienceID === space.id)} />
            ))}
          </VStack>
        </VStack>
      )}
      {spacesList.active.length > 0 && (
        <VStack spacing="sm">
          <VStack spacing="xs">
            <Header type="h2" lineHeight="xl" width="100%">
              {formatMessage(spacesMessages.activeTitle)}
            </Header>
            <Text fontSize="m" lineHeight="xl">
              {formatMessage(spacesMessages.activeDescription)}
            </Text>
          </VStack>
          <VStack spacing="m">
            {spacesList.active.map(space => (
              <SpaceCard key={space.id} space={space} accessRules={accessRules.filter(rule => rule.experienceID === space.id)} />
            ))}
          </VStack>
        </VStack>
      )}
      {spacesList.inactive.length > 0 && (
        <VStack spacing="sm">
          <Header type="h2" lineHeight="xl" width="100%">
            {formatMessage(spacesMessages.inactiveTitle)}
          </Header>
          <VStack spacing="m">
            {spacesList.inactive.map(space => (
              <SpaceCard key={space.id} space={space} accessRules={accessRules.filter(rule => rule.experienceID === space.id)} />
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  )
}
