import { useLocales } from '@sevenrooms/core/locales'
import { Banner, Box } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../AccessRules.locales'
import { SharedTestId } from './shared.testIds'

export function MissingPayments() {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  return (
    <Box pb="lm">
      <Banner
        data-test={SharedTestId.missingPayments}
        title={formatMessage(accessRulesMessages.paymentNotConfigured)}
        type="attention"
        description={formatMessage(accessRulesMessages.paymentNotConfiguredDescription)}
        action="Connect"
        onAction={() => window.open(`/manager2/${venueUrlKey}/settings/payment-integration/view`, '_blank')}
      />
    </Box>
  )
}
