import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'
import DataHeavyLayout from 'mgr/pages/shared/layouts/DataHeavy'
import {
  onDateFilterChange,
  onPlatformFilterChange,
  onRatingFilterChange,
  onFeedbackTypeFilterChange,
  onSearchFilterChange,
  onGuestSatisfactionPageChange,
  onGuestSatisfactionSort,
  onCustomDateRangeChange,
} from 'mgr/pages/single-venue/marketing/actions/Filters'
import {
  startLoading,
  tryLoadReviews,
  onChangeVenue,
  smartRowClick,
  openReview,
  tryTriggerGuestSatisfactionExport,
} from 'mgr/pages/single-venue/marketing/actions/Reviews'
import Filters from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/Filters'
import ReviewsList from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/ReviewsList'
import ReviewsSummary from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/ReviewsSummary'
import { ActionButton } from 'mgr/pages/single-venue/marketing/components/shared/ActionButton'
import ShowReview from 'mgr/pages/single-venue/marketing/containers/ShowReview'
import { selectReviewsFiltersValues } from 'mgr/pages/single-venue/marketing/selectors/Filters'
import { filterChannelsByConfiguredSites, selectReviewsStats } from 'mgr/pages/single-venue/marketing/selectors/Reviews'
import {
  ExpandableSearchModal,
  RenderedCombinedBookingSearchResultsSection,
  RenderedSearchQuery,
} from 'mgr/pages/single-venue/search/containers/containers'
import { BRAND_MAPPER, getBrandPNGSrcMap } from 'svr/common/BrandIcon'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import { AISummary } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/AISummary/AISummary'

// TODO change this to guest satisfaction
class Reviews extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onChangeVenue = this.onChangeVenue.bind(this)
    this.onGuestSatisfactionPageChange = this.onGuestSatisfactionPageChange.bind(this)
  }

  onChangeVenue(venue) {
    this.props.onChangeVenue(venue)
  }

  onGuestSatisfactionPageChange(page) {
    this.props.onGuestSatisfactionPageChange(page, this.props.filters.pageSize)
  }

  componentDidMount() {
    const { tryLoadReviews, venue } = this.props

    tryLoadReviews(venue)

    window.metric.track('GuestSatisfaction.ClickedTab')

    window.document.body.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        this.props.openReview(null)
      }
    })
  }

  render() {
    const {
      smartRowClick,
      results,
      total,
      overall,
      breakdown,
      channels,
      lastUpdated,
      onChangeVenue,
      isLoading,
      startLoading,
      history,
      selected,
      filters,
      venue,
      page,
      pages,
      pageSize,
      onDateFilterChange,
      onPlatformFilterChange,
      onRatingFilterChange,
      onFeedbackTypeFilterChange,
      onSearchFilterChange,
      onGuestSatisfactionSort,
      dateValue,
      searchValue,
      platformValues,
      ratingValues,
      feedbackTypeValues,
      tryTriggerGuestSatisfactionExport,
      isLoadingExport,
      isSearchBlank,
      onCustomDateRangeChange,
      tryLoadReviews,
      canExportReservations,
      locale,
      isFullFeatured,
      isUpsellDisabled,
      isFeedbackConfigured,
      configuredChannels,
      pngSrcMap,
      venues,
      isOrdersIntegrationEnabled,
      aiReviewSummaryEnabled,
    } = this.props
    return (
      <ContentLayout
        title="Guest Satisfaction"
        venue={venue}
        venues={venues}
        Actions={
          <ActionButton disabled={isLoadingExport} onClick={tryTriggerGuestSatisfactionExport}>
            Export
          </ActionButton>
        }
        onChangeVenue={onChangeVenue}
      >
        {aiReviewSummaryEnabled && <AISummary />}
        <DataHeavyLayout
          DataFilter={
            <Filters
              channels={channels}
              configuredChannels={configuredChannels}
              isFullFeatured={isFullFeatured}
              startLoading={startLoading}
              dateValue={dateValue}
              platformValues={platformValues}
              ratingValues={ratingValues}
              feedbackTypeValues={feedbackTypeValues}
              searchValue={searchValue}
              onDateFilterChange={onDateFilterChange}
              onPlatformFilterChange={onPlatformFilterChange}
              onRatingFilterChange={onRatingFilterChange}
              onFeedbackTypeFilterChange={onFeedbackTypeFilterChange}
              onSearchFilterChange={onSearchFilterChange}
              startDate={filters.startDate}
              endDate={filters.endDate}
              locale={locale}
              isOrdersIntegrationEnabled={isOrdersIntegrationEnabled}
              onCustomDateRangeChange={(type, val) => {
                onCustomDateRangeChange(type, val)
                tryLoadReviews(venue)
              }}
              productProvisionPackage={venue.productProvisionPackage}
            />
          }
          DataSummary={
            <ReviewsSummary
              isLoading={isLoading}
              channels={channels}
              breakdown={breakdown}
              overall={overall}
              lastUpdated={lastUpdated}
              isFeedbackConfigured={isFeedbackConfigured}
              isFullFeatured={isFullFeatured}
              isUpsellDisabled={isUpsellDisabled}
              pngSrcMap={pngSrcMap}
              onPlatformFilterChange={onPlatformFilterChange}
              productProvisionPackage={venue.productProvisionPackage}
            />
          }
          DataList={
            <ReviewsList
              isLoading={isLoading}
              reviews={results}
              total={total}
              smartRowClick={smartRowClick}
              page={page}
              pageSize={pageSize}
              pages={pages}
              onGuestSatisfactionSort={onGuestSatisfactionSort}
              locale={locale}
              onGuestSatisfactionPageChange={this.onGuestSatisfactionPageChange}
            />
          }
        />
        <ShowReview />
        <ExpandableSearchModal>
          <RenderedSearchQuery />
          {!isSearchBlank ? (
            <div key="result-area" className="row result-area">
              <RenderedCombinedBookingSearchResultsSection />
            </div>
          ) : null}
        </ExpandableSearchModal>
      </ContentLayout>
    )
  }
}

const platformToArray = platform => {
  const channels = Object.keys(BRAND_MAPPER)
  const platforms = platform.split(',')

  if (_.includes(platforms, 'all')) {
    return channels
  }
  return platforms.filter(e => _.includes(channels, e))
}

const mapStateToProps = state => {
  const { venueSettings } = state.appState
  const isFullFeatured =
    venueSettings.is_full_sizzle_package || venueSettings.is_sizzle_standalone || venueSettings.is_guest_satisfaction_standalone_enabled

  const selectedPlatforms = platformToArray(state.reviews.filters.platform)

  const [sitesForTotals, configuredSites, sitesforStarAggregates] = isFullFeatured
    ? [selectedPlatforms, state.appState.venueSettings.guest_satisfaction_configured_review_sites, selectedPlatforms]
    : [['sevenrooms', 'google', 'yelp', 'tripadvisor'], ['sevenrooms', 'google', 'yelp', 'tripadvisor'], ['sevenrooms']]

  const reviewsStats = selectReviewsStats(state, sitesforStarAggregates, sitesForTotals)
  const filtersValues = selectReviewsFiltersValues(state)
  const pngSrcMap = getBrandPNGSrcMap(state.appState.mediaUrl)
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]

  return {
    venue,
    venues,
    results: state.reviews.data.results,
    total: state.reviews.data.total,
    overall: reviewsStats.overall,
    breakdown: reviewsStats.breakdown,
    channels: filterChannelsByConfiguredSites(reviewsStats.breakdown.channels, configuredSites),
    lastUpdated: state.reviews.data.lastUpdated,
    selected: state.reviews.data.selected,
    isLoading: state.reviews.data.isLoading,
    isLoadingExport: state.reviews.data.isLoadingExport,
    isOrdersIntegrationEnabled: state.appState.venueSettings.is_orders_integration_enabled,
    filters: state.reviews.filters,
    dateValue: filtersValues.values.date,
    searchValue: filtersValues.values.search,
    platformValues: filtersValues.values.platform,
    ratingValues: filtersValues.values.rating,
    feedbackTypeValues: filtersValues.values.feedbackType,
    page: filtersValues.values.page,
    pages: filtersValues.values.pages,
    pageSize: filtersValues.values.pageSize,
    isSearchBlank: state.search.isBlank,
    isFullFeatured,
    canExportReservations: state.appState.venueSettings.features.can_export_reservations_data,
    locale: state.appState.venue.locale,
    isFeedbackConfigured: state.appState.venueSettings.is_feedback_configured,
    isUpsellDisabled: state.appState.venueSettings.is_guest_satisfaction_upsell_disabled,
    configuredChannels: state.appState.venueSettings.guest_satisfaction_configured_review_sites,
    aiReviewSummaryEnabled: state.appState.venueSettings.ff_ai_review_summary_enabled,
    pngSrcMap,
  }
}

const mapDispatchToProps = {
  tryLoadReviews,
  onChangeVenue,
  smartRowClick,
  openReview,
  onDateFilterChange,
  onPlatformFilterChange,
  onRatingFilterChange,
  onFeedbackTypeFilterChange,
  onSearchFilterChange,
  onGuestSatisfactionPageChange,
  onGuestSatisfactionSort,
  startLoading,
  tryTriggerGuestSatisfactionExport,
  onCustomDateRangeChange,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reviews))
