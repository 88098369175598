import { z } from 'zod'
import type { Gratuity, ServiceCharge } from '@sevenrooms/core/domain'
import type { ZodSchema } from '@sevenrooms/core/form'

export type ChargeSettingsForm = ZodSchema<typeof chargeSettingsForm>

export const chargeSettingsForm = z.object({
  applyServiceCharge: z.boolean(),
  serviceChargeType: z.custom<ServiceCharge>(),
  serviceChargePercent: z.number().nullable(),
  applyTax: z.boolean(),
  taxId: z.string().nullable(),
  applyGratuity: z.boolean(),
  gratuityType: z.custom<Gratuity>(),
  gratuityPercent: z.number().nullable(),
  requireGratuity: z.boolean(),
})
