import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  accountAndAuthentication: {
    id: 'paymentSettings.Stripe.accountAndAuthentication',
    defaultMessage: 'Account & Authentication',
  },
  accountId: {
    id: 'paymentSettings.Stripe.accountId',
    defaultMessage: 'Account ID',
  },
} as const)
