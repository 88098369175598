import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { MarketingFilter } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/Filters'
import DateRangePicker from 'mgr/pages/single-venue/marketing/components/shared/DateRangePicker'
import { dateFilters } from 'mgr/pages/single-venue/marketing/reducers/Filters'

const FiltersContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

class Filters extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onDateFilterChange = this.onDateFilterChange.bind(this)
  }

  onDateFilterChange(value) {
    this.props.onDateFilterChange(value)
  }

  render() {
    const { dateValue, startDate, endDate, onCustomDateRangeChange, locale, actionButton } = this.props

    return (
      <FiltersContainer>
        <MarketingFilter
          choices={dateFilters}
          value={dateValue}
          style={{ width: 'auto' }}
          onChangeHandler={this.onDateFilterChange}
          name="Date"
        />
        {dateValue !== 'all' && (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            minEndDate={startDate}
            onDateChange={onCustomDateRangeChange}
            locale={locale}
            hasMinDate={false}
            maxDate={moment().toDate()}
          />
        )}
        {actionButton}
      </FiltersContainer>
    )
  }
}

export default Filters
