import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  AutomaticPrepaymentRedemptionCardFormSchema,
  convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig,
} from '../AutomaticPrepaymentRedemptionCardForm.zod'

export type SimphonyAutomaticPrepaymentRedemptionCardFormData = ZodSchema<typeof useSimphonyAutomaticPrepaymentRedemptionCardForm>
export type SimphonyAutomaticPrepaymentRedemptionCardFormField = Field<SimphonyAutomaticPrepaymentRedemptionCardFormData>

export function useSimphonyAutomaticPrepaymentRedemptionCardForm() {
  return useMemo(
    () =>
      AutomaticPrepaymentRedemptionCardFormSchema.extend({
        depositMenuItemId: z.string(),
        depositDefinitionSequence: z.string(),
      }).superRefine(({ isAutomaticPrepaymentRedemptionEnabled, depositMenuItemId, depositDefinitionSequence }, ctx) => {
        // Only require these fields if the user enables Automated Prepayment Redemption
        if (isAutomaticPrepaymentRedemptionEnabled) {
          if (depositMenuItemId.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['depositMenuItemId'],
            })
          }
          if (depositDefinitionSequence.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['depositDefinitionSequence'],
            })
          }
        }
      }),
    []
  )
}

export const getSimphonyAutomaticPrepaymentRedemptionCardFormDefaultValues = ({
  isAutomaticPrepaymentRedemptionEnabled,
  depositMenuItemId,
  depositDefinitionSequence,
}: {
  isAutomaticPrepaymentRedemptionEnabled: boolean
  depositMenuItemId?: string
  depositDefinitionSequence?: string
}): SimphonyAutomaticPrepaymentRedemptionCardFormData => ({
  isAutomaticPrepaymentRedemptionEnabled,
  depositMenuItemId: depositMenuItemId ?? '',
  depositDefinitionSequence: depositDefinitionSequence ?? '',
})

export const convertSimphonyAutomaticPrepaymentRedemptionCardFormDataToSimphonyConfig = (
  data: SimphonyAutomaticPrepaymentRedemptionCardFormData
): Pick<SimphonyConfig, 'depositsEnabled' | 'depositMenuItemId' | 'depositDefinitionSequence'> => {
  const { depositMenuItemId, depositDefinitionSequence } = data
  return {
    ...convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig(data),
    depositMenuItemId: depositMenuItemId ?? undefined,
    depositDefinitionSequence: depositDefinitionSequence ?? undefined,
  }
}
