import { defineMessages } from '@sevenrooms/core/locales'

export const oneTimeEmailCenterMessages = defineMessages({
  oneTimeEmailCenterTitle: {
    id: 'oneTimeEmailCenter.title',
    defaultMessage: 'Email Marketing',
  },
  oneTimeEmailCenterDescription: {
    id: 'oneTimeEmailCenter.description',
    defaultMessage:
      'Send personalized and automatically optimized one-time marketing emails to your guests to provide a better guest experience and drive more engagement, reservations, and repeat visits.',
  },
  oneTimeEmailCenterNewEmail: {
    id: 'oneTimeEmailCenter.newEmail',
    defaultMessage: 'Create New Email',
  },
  campaignName: {
    id: 'oneTimeEmails.campaignName',
    defaultMessage: 'Campaign Name',
  },
  oneTimeEmailCenterCampaignPerformance: {
    id: 'oneTimeEmails.campaignPerformance',
    defaultMessage: 'Campaign Performance',
  },
  campaignNameToDescription: {
    id: 'oneTimeEmails.campaignNameToDescription',
    defaultMessage: 'to',
  },
  scheduled: {
    id: 'oneTimeEmails.scheduled',
    defaultMessage: 'Scheduled',
  },
  processing: {
    id: 'oneTimeEmails.processing',
    defaultMessage: 'Processing',
  },
  confirmModalTitle: {
    id: 'oneTimeEmails.confirmModalTitle',
    defaultMessage: 'Are you sure you want to edit a scheduled campaign?',
  },
  confirmModalInformation: {
    id: 'oneTimeEmails.confirmModalInformation',
    defaultMessage: 'Editing a scheduled campaign will force the campaign to be converted into "Draft" state.',
  },
  confirmModalDelete: {
    id: 'oneTimeEmails.confirmDelete',
    defaultMessage: 'Yes, I want to edit',
  },
  campaignSaveSuccess: {
    id: 'oneTimeEmails.campaignSaveSuccess',
    defaultMessage: 'Email Campaign archived successfully',
  },
  oneTimeEmailTableStatusFilterPlaceholder: {
    id: 'oneTimeEmailCenter.campaignTable.statusFilterPlaceholder',
    defaultMessage: 'Filter by status',
  },
  oneTimeEmailTableCampaignFilterPlaceholder: {
    id: 'oneTimeEmailCenter.campaignTable.campaignFilterPlaceholder',
    defaultMessage: 'Search by campaign name',
  },
  emptyTableTitleNoData: {
    id: 'oneTimeEmails.emptyTableTitle.noData',
    defaultMessage: 'No emails have been created yet',
  },
  emptyTableDescriptionNoData: {
    id: 'oneTimeEmails.emptyTableDescription.noData',
    defaultMessage: 'Get started by',
  },
  emptyTableDescriptionLinkNoData: {
    id: 'oneTimeEmails.emptyTableDescription.noData',
    defaultMessage: 'creating an email',
  },
  emptyTableDescriptionSearch: {
    id: 'oneTimeEmails.emptyTableDescription.search',
    defaultMessage: 'No emails with a campaign name containing "{searchValue}"',
  },
  emptyTableDescriptionFilters: {
    id: 'oneTimeEmails.emptyTableDescription.filters',
    defaultMessage: 'No emails match the selected filters',
  },
  confirmSendEmailTitle: {
    id: 'oneTimeEmails.confirmSendEmailTitle',
    defaultMessage: 'Ready to send?',
  },
  sendTime: {
    id: 'oneTimeEmails.sendTime',
    defaultMessage: 'Send Time',
  },
  oneTimeEmailDetailsRecipients: {
    id: 'oneTimeEmails.oneTimeEmailDetailsRecipients',
    defaultMessage: '({recipients} recipients)',
  },
  oneTimeEmailDetailsExclude: {
    id: 'oneTimeEmails.oneTimeEmailDetailsExclude',
    defaultMessage: 'Excluding',
  },
  confirmSendEmailText: {
    id: 'oneTimeEmails.confirmSendEmailText',
    defaultMessage: 'You are about to send a campaign to the {recipientTagNames} segment(s).',
  },
  confirmScheduleEmailText: {
    id: 'oneTimeEmails.confirmScheduleEmailText',
    defaultMessage: 'You are about to schedule a campaign to the {recipientTagNames} segment(s).',
  },
  confirmArchiveEmailModalTitle: {
    id: 'confirmArchiveEmailModalTitle',
    defaultMessage: 'Are you sure you wish to archive this campaign?',
  },
  confirmArchiveEmailModalInformation: {
    id: 'confirmArchiveEmailModalInformation',
    defaultMessage: 'Archiving this campaign will remove it from the active email center.',
  },
  confirmArchiveEmailModalHistoryInfo: {
    id: 'confirmArchiveEmailModalHistoryInfo',
    defaultMessage: "We will keep all of the campaign's historical performance data.",
  },
  confirmArchiveEmailModal: {
    id: 'confirmArchiveEmailModal',
    defaultMessage: 'Confirm Archive',
  },
  emailTemplatesTitle: {
    id: 'emailCenter.emailTemplates.title',
    defaultMessage: 'Email Marketing Templates',
  },
  previewTemplateTitle: {
    id: 'emialCenter.emailTemplates.templateView.title',
    defaultMessage: 'Preview Template',
  },
  useTemplate: {
    id: 'emailCenter.emailTemplates.templateView.cancelButton',
    defaultMessage: 'Use this Template',
  },
  emailTemplateEmptyCardTitle: {
    id: 'emailCenter.emailTemplates.emptyCardTitle',
    defaultMessage: 'Create from Scratch',
  },
  emailTemplateEmptyCardText: {
    id: 'emailCenter.emailTemplates.emptyCardText',
    defaultMessage: 'Start from a blank design',
  },
  emailCampaignTemplatesLinkMessage: {
    id: 'emailCenter.oneTimeEmailCampaignTemplates.emailCampaignTemplatesLinkMessage',
    defaultMessage: 'Email Marketing Dashboard',
  },
  emailCampaignTemplatesBreadCrumText: {
    id: 'emailCenter.oneTimeEmailCampaignTemplates.emailCampaignTemplatesBreadCrumText',
    defaultMessage: 'Email Marketing Templates',
  },
  active: {
    id: 'oneTimeEmailCenter.active',
    defaultMessage: 'Scheduled',
  },
  inactive: {
    id: 'oneTimeEmailCenter.inactive',
    defaultMessage: 'Inactive',
  },
  draft: {
    id: 'oneTimeEmailCenter.draft',
    defaultMessage: 'Draft',
  },
  archived: {
    id: 'oneTimeEmailCenter.archived',
    defaultMessage: 'Archived',
  },
  sending: {
    id: 'oneTimeEmailCenter.sending',
    defaultMessage: 'Sending',
  },
  sent: {
    id: 'oneTimeEmailCenter.sent',
    defaultMessage: 'Sent',
  },
  complete: {
    id: 'oneTimeEmailCenter.complete',
    defaultMessage: 'Completed',
  },
  completed: {
    id: 'oneTimeEmailCenter.completed',
    defaultMessage: 'Completed',
  },
  campaignPerformanceChartNoData: {
    id: 'oneTimeEmailCenter.emailCenter.campaignPerformanceChartNoData',
    defaultMessage: 'No email campaign data for that time range.',
  },
  campaignPerformanceChartError: {
    id: 'oneTimeEmailCenter.emailCenter.campaignPerformanceChartError',
    defaultMessage: 'Error during fetching email campaign data.',
  },
  campaignText: {
    id: 'campaignText',
    defaultMessage: 'Automated Emails',
  },
  overviewTab: {
    id: 'oneTimeEmails.overviewTab',
    defaultMessage: 'Overview',
  },
  opensTab: {
    id: 'oneTimeEmails.opensTab',
    defaultMessage: 'Opens',
  },
  clicksTab: {
    id: 'clicksTab.clicksTab',
    defaultMessage: 'Clicks',
  },
  reservationsTab: {
    id: 'oneTimeEmails.reservationsTab',
    defaultMessage: 'Reservations',
  },
  reservationsTableHeaderDate: {
    id: 'oneTimeEmails.reservationsTab.headerDate',
    defaultMessage: 'Date',
  },
  reservationsTableHeaderTime: {
    id: 'oneTimeEmails.reservationsTab.headerTime',
    defaultMessage: 'Time',
  },
  reservationsTableHeaderName: {
    id: 'oneTimeEmails.reservationsTab.headerName',
    defaultMessage: 'Name',
  },
  reservationsTableHeaderCreatedDate: {
    id: 'oneTimeEmails.reservationsTab.headerCreatedDate',
    defaultMessage: 'Created Date',
  },
  reservationsTableHeaderSpend: {
    id: 'oneTimeEmails.reservationsTab.headerSpend',
    defaultMessage: 'Spend',
  },
  reservationsTableHeaderCovers: {
    id: 'oneTimeEmails.reservationsTab.headerCovers',
    defaultMessage: 'Covers',
  },
  reservationsTableSearch: {
    id: 'oneTimeEmails.reservationsTab.search',
    defaultMessage: 'Search name or email',
  },
} as const)
