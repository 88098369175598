import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid ${props => props.theme.color.grey};
`
const ErrorLogoBanner = styled.div`
  height: 68px;
  background: ${props => props.theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorBody = styled.div`
  height: 68px;
  padding: ${props => props.theme.gutter.triple};
  background: ${props => props.theme.color.black};
  color: ${props => props.theme.color.white};
  font-size: 14px;
  flex: 1;
  text-align: center;
`
const ErrorButton = styled.a`
  ${props => props.theme.primaryButton} display: inline-block;
  padding: ${props => props.theme.gutter.standard};
  color: ${props => props.theme.color.white} !important;
  :hover {
    color: ${props => props.theme.color.white} !important;
  }
`

export const ErrorNotFound = () => (
  <ErrorContainer>
    <ErrorLogoBanner>
      <div>
        <img src={`${window.globalInit.mediaUrl}images/sevenrooms_logo.png`} />
      </div>
    </ErrorLogoBanner>
    <ErrorBody>
      <p>There's nothing here. Only the void.</p>
      <p>
        <ErrorButton href="/">Flee the void</ErrorButton>
      </p>
    </ErrorBody>
  </ErrorContainer>
)
