import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { getPaymentPolicyTable } from './PaymentPolicyInfo'

export function DefaultPaymentPolicy() {
  const { shifts, policies } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const shiftPaymentPolicies = useMemo(
    () =>
      shifts.map(s => ({
        id: s.id,
        name: s.name,
        table: getPaymentPolicyTable(
          {
            ...s,
            bookingPolicyId: s.policyType === 'custom' ? 'custom' : s.bookingPolicyName,
            cancellationPolicyId: s.cancellationPolicyType === 'custom' ? 'custom' : s.cancellationPolicyName,
          },
          policies,
          formatMessage
        ),
      })),
    [formatMessage, policies, shifts]
  )

  return (
    <DefaultSettingsReportSection>
      <Report>
        {shiftPaymentPolicies.map(s => (
          <ReportPart key={s.id} caption={[s.name, formatMessage(PaymentPolicyLocales.setting)]}>
            {s.table.map(([key, value]) => (
              <ReportLine key={key} name={key}>
                {value}
              </ReportLine>
            ))}
          </ReportPart>
        ))}
      </Report>
    </DefaultSettingsReportSection>
  )
}
