import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { type LocationSelectionCardFormData, LocationSelectionCardFormSchema } from '../LocationSelectionCardForm.zod'

export type SimphonyLocationSelectionCardFormData = LocationSelectionCardFormData & ZodSchema<typeof useSimphonyLocationSelectionCardForm>
export type SimphonyLocationSelectionCardFormField = Field<SimphonyLocationSelectionCardFormData>

export function useSimphonyLocationSelectionCardForm() {
  return useMemo(
    () =>
      LocationSelectionCardFormSchema.extend({
        checkPollingRvcs: z.array(z.string()).min(1),
      }),
    []
  )
}

export const getSimphonyLocationSelectionCardFormDefaultValues = ({
  locationId,
  checkPollingRvcs,
}: {
  locationId?: string
  checkPollingRvcs?: string[]
}): SimphonyLocationSelectionCardFormData => ({
  locationId: locationId ?? '',
  checkPollingRvcs: checkPollingRvcs ?? [],
})

export const convertSimphonyLocationSelectionCardFormDataToSimphonyConfig = (
  data: SimphonyLocationSelectionCardFormData
): Pick<SimphonyConfig, 'locationId' | 'checkPollingRvcs'> => {
  const { locationId, checkPollingRvcs } = data
  return {
    locationId,
    checkPollingRvcs,
  }
}
