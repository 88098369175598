import React, { useMemo, useState } from 'react'
import type { POSICourseStatus, POSICheckTriggers, POSIType } from '@sevenrooms/core/domain'
import { type Field, useFieldArray, useWatch, useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, FormMultiSelect, FormSelect, FormToggle, IconButton, Label } from '@sevenrooms/core/ui-kit/form'
import { Banner, DividerLine, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box'
import { posiMetaDataMap } from '../../ReferenceData'
import { messages } from './CourseStatusMappingCard.locales'
import type { CourseStatusMappingCardFormData, CourseStatusMappingCardFormField } from './CourseStatusMappingCardForm.zod'

const formatTitleCase = (s: string) =>
  s
    .split(/(\s)/)
    .map(w => w.charAt(0).toUpperCase() + w.substring(1))
    .join('')

export const IN_SERVICE_STATUS_SEPARATOR = '##'
export const buildInServiceStatusSelectOptionId = (inServiceStatus: POSICourseStatus): string =>
  [inServiceStatus.status, inServiceStatus.label].join(IN_SERVICE_STATUS_SEPARATOR)

export const getStatusAndLabelFromInServiceStatusSelectOptionId = (
  inServiceStatusSelectOptionId: string
): { status: string; label: string } => {
  const [status, label] = inServiceStatusSelectOptionId.split(IN_SERVICE_STATUS_SEPARATOR)
  if (!status || !label) {
    // This should never happen
    throw new Error('Invalid inServiceStatusSelectOptionId')
  }
  return { status, label }
}

export interface CourseStatusMappingCardProps {
  field: CourseStatusMappingCardFormField
  inServiceStatuses: POSICourseStatus[]
  checkTriggers?: POSICheckTriggers
  isFetching: boolean
  posiType: POSIType
  isLocation: boolean
  errorBanners?: React.ReactNode
  formatCheckTriggerLabel?: (label: string) => string
}

export function CourseStatusMappingCard({
  field,
  inServiceStatuses,
  checkTriggers,
  isFetching,
  posiType,
  isLocation,
  errorBanners,
  formatCheckTriggerLabel,
}: CourseStatusMappingCardProps) {
  const { formatMessage } = useLocales()
  const { setValue, watch } = useFormContext()

  const isCourseStatusMappingEnabled = watch('courseStatusMappingCard.isCourseStatusMappingEnabled')

  const [checkTriggerOptionsList, setCheckTriggerOptionsList] = useState<SelectOption[][]>([])

  const inServiceStatusSelectOptions = useMemo(
    () =>
      inServiceStatuses
        .map(inServiceStatus => ({
          id: buildInServiceStatusSelectOptionId(inServiceStatus),
          label: formatTitleCase(inServiceStatus.label),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [inServiceStatuses]
  )

  const { checkTriggerSelectOptionsByType, checkTriggerTypeSelectOptions } = useMemo(() => {
    const checkTriggerSelectOptionsByType: { [key: string]: SelectOption[] } = {}
    const checkTriggerTypeSelectOptions: SelectOption[] = []
    if (checkTriggers) {
      Object.entries(checkTriggers).forEach(([triggerType, labels]) => {
        const checkTriggerOptions: SelectOption[] = []
        labels.forEach((label: string) => {
          checkTriggerOptions.push({
            id: label,
            label: formatCheckTriggerLabel ? formatCheckTriggerLabel(label) : formatTitleCase(label),
          })
        })
        checkTriggerSelectOptionsByType[triggerType] = checkTriggerOptions
        checkTriggerTypeSelectOptions.push({
          id: triggerType,
          label: formatTitleCase(triggerType),
        })
      })
    }
    return { checkTriggerSelectOptionsByType, checkTriggerTypeSelectOptions }
  }, [checkTriggers, formatCheckTriggerLabel])

  const { fields: courseStatusMappingsFieldArray, remove, append } = useFieldArray(field, 'courseStatusMappings')

  const addRow = () => {
    const checkTriggerType = checkTriggerTypeSelectOptions.length > 0 ? checkTriggerTypeSelectOptions[0]?.id ?? '' : ''
    if (checkTriggers && checkTriggerType) {
      append({ checkTriggerType, checkTriggers: [], transitionToStatus: '' })
      setCheckTriggerOptionsList([...checkTriggerOptionsList, checkTriggerSelectOptionsByType[checkTriggerType] ?? []])
    }
  }

  function CourseStatusMapperCardRow({
    field,
    remove,
    resetCheckTriggers,
    disabled,
  }: {
    field: Field<CourseStatusMappingCardFormData['courseStatusMappings'][number]>
    remove: () => void
    resetCheckTriggers: () => void
    disabled: boolean
  }) {
    const checkTriggerType = useWatch(field.prop('checkTriggerType'))
    const checkTriggersDisabled = !checkTriggerType
    return (
      <HStack width="100%" justifyContent="space-between" alignItems="start">
        <Box width="150px">
          <FormSelect
            options={checkTriggerTypeSelectOptions ?? []}
            field={field.prop('checkTriggerType')}
            searchable={false}
            disabled={disabled || checkTriggerTypeSelectOptions.length <= 1}
            onChange={resetCheckTriggers}
            hideErrorMessage
          />
        </Box>
        <Box width="500px">
          <FormMultiSelect
            options={checkTriggersDisabled ? [] : checkTriggerSelectOptionsByType[checkTriggerType] ?? []}
            field={field.prop('checkTriggers')}
            disabled={disabled || checkTriggersDisabled}
            scrollLock
            hideErrorMessage
            searchable
          />
        </Box>
        <Box width="200px">
          <FormSelect
            field={field.prop('transitionToStatus')}
            options={inServiceStatusSelectOptions}
            searchable
            hideErrorMessage
            disabled={disabled}
          />
        </Box>
        <Box width="50px">
          <IconButton borderType="none-round" icon="VMSWeb-trash" onClick={remove} disabled={disabled} />
        </Box>
      </HStack>
    )
  }

  const banners = useMemo(() => {
    if (!isLocation) {
      return (
        <Box width="100%">
          <Banner
            type="info"
            title={formatMessage(messages.locationRequiredBannerTitle)}
            description={formatMessage(messages.locationRequiredBannerDescription)}
          />
        </Box>
      )
    }

    if (errorBanners) {
      return errorBanners
    }

    return undefined
  }, [errorBanners, formatMessage, isLocation])

  return isFetching ? (
    <Loader />
  ) : (
    <VStack backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
      <HStack spacing="m" pt="m" pb="l" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
        <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
          <HStack width="100%" justifyContent="space-between" alignItems="center">
            <VStack spacing="s">
              <Text textStyle="h2">{formatMessage(messages.configurationLabel)}</Text>{' '}
              <SecondaryText>
                {formatMessage(messages.configurationSubHeadingLabel, {
                  a: (chunks: string[]) => <Anchor href={posiMetaDataMap[posiType].docLink}>{chunks}</Anchor>,
                })}
              </SecondaryText>
            </VStack>
            <FormToggle field={field.prop('isCourseStatusMappingEnabled')} disabled={!!banners || !checkTriggers} />
          </HStack>
          <Box width="100%">
            <DividerLine mr="none" ml="none" />
          </Box>
          {banners}
          {courseStatusMappingsFieldArray.map(({ id }, idx) => (
            <Box width="100%" key={`${id}-box`}>
              {idx === 0 && (
                <HStack width="100%" justifyContent="space-between" alignItems="center">
                  <Label primary={formatMessage(messages.mappingTypeLabel)} width="150px" />
                  <Label primary={formatMessage(messages.mappingNameLabel)} width="500px" />
                  <Label primary={formatMessage(messages.mappingStatusLabel)} width="200px" />
                  <Box width="50px" />
                </HStack>
              )}
              <CourseStatusMapperCardRow
                key={id}
                field={field.prop(`courseStatusMappings.${idx}`)}
                remove={() => remove(idx)}
                resetCheckTriggers={() => {
                  setValue(`courseStatusMappingCard.courseStatusMappings.${idx}.checkTriggers`, [])
                }}
                disabled={!!errorBanners || !isCourseStatusMappingEnabled}
              />
            </Box>
          ))}
          {!banners && (
            <Box width="100%" display="flex" justifyContent="center">
              <Button
                onClick={addRow}
                disabled={!isCourseStatusMappingEnabled || courseStatusMappingsFieldArray.length >= 10}
                data-test="add-mapping"
              >
                {formatMessage(messages.addMappingLabel)}
              </Button>
            </Box>
          )}
        </VStack>
      </HStack>
    </VStack>
  )
}
