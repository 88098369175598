/* eslint-disable max-classes-per-file */
import moment from 'moment-timezone'
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import DatePicker from 'js/3p/react-datepicker-v1'
import { ValueWrapper, ValueBox, InlineText } from './CustomAutotagShared'

require('../../../../../../../../../css/react/react-datepicker-v1.css')

const DatePickerInnerWrapper = styled.div`
  position: absolute;
  top: 40px;
  z-index: 99;
  ${props => props.hasMinDate && `right: 0px;`};
`

const ValueBoxCSS = css`
  height: 35px;
  width: 237px;
  text-align: center;
  justify-content: center;
`

const FIELDS = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
}

class CustomAutotagDatePicker extends React.PureComponent {
  handleClickOutside = () => {
    this.props.closeCalendar()
  }

  render() {
    const { id, date, locale, onChange, hasMinDate, minDate } = this.props
    return (
      <DatePickerInnerWrapper id={id} hasMinDate={hasMinDate} className="svr-datepicker react-datepicker-v1">
        <DatePicker inline selected={date} locale={locale} onChange={onChange} hasMinDate={hasMinDate} minDate={minDate} />
      </DatePickerInnerWrapper>
    )
  }
}

const DatePickerWrapper = onClickOutside(CustomAutotagDatePicker)

class CustomAutotagDateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isActive: null }
  }

  startDateBoxClickHandler = () => this.setState({ isActive: FIELDS.START_DATE })

  endDateBoxClickHandler = () => this.setState({ isActive: FIELDS.END_DATE })

  closeCalendar = () => this.setState({ isActive: null })

  startDateChangeHandler = newStartDate => {
    const [startDate, endDate] = this.props.value
    let newEndDate = endDate
    newStartDate.set('milliseconds', 0)
    newStartDate.set('seconds', 0)
    if (newStartDate.isAfter(endDate)) {
      newEndDate = newStartDate
    }
    this.props.onChange([newStartDate, newEndDate])
  }

  endDateChangeHandler = newEndDate => {
    newEndDate.set('milliseconds', 0)
    newEndDate.set('seconds', 0)
    this.props.onChange([this.props.value[0], newEndDate])
  }

  getDate = momentDate => momentDate.format('YYYY-MM-DD')

  render() {
    const { value, locale } = this.props
    const [startDate, endDate] = [moment(value[0]), moment(value[1])]
    return (
      <ValueWrapper
        css={css`
          width: 532px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <ValueBox isValid onClick={this.startDateBoxClickHandler} css={ValueBoxCSS}>
          {this.getDate(startDate)}
        </ValueBox>
        {this.state.isActive === FIELDS.START_DATE && (
          <DatePickerWrapper date={startDate} locale={locale} onChange={this.startDateChangeHandler} closeCalendar={this.closeCalendar} />
        )}
        <InlineText>and</InlineText>
        <ValueBox isValid onClick={this.endDateBoxClickHandler} css={ValueBoxCSS}>
          {this.getDate(endDate)}
        </ValueBox>
        {this.state.isActive === FIELDS.END_DATE && (
          <DatePickerWrapper
            date={endDate}
            locale={locale}
            hasMinDate
            minDate={startDate}
            onChange={this.endDateChangeHandler}
            closeCalendar={this.closeCalendar}
          />
        )}
      </ValueWrapper>
    )
  }
}

export default CustomAutotagDateRangePicker
