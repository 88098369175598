import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FieldErrors, FormNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PartySizeLocales } from '../PartySize.locales'
import { PartySizeTestId } from '../PartySize.testIds'
import type { PartySizeForm } from '../PartySize.zod'

export interface PartySizeFieldsProps {
  field: Field<PartySizeForm>
  isPrivateEventAccessRule?: boolean
}

export function PartySizeFields(props: PartySizeFieldsProps) {
  const { formatMessage } = useLocales()
  const { isPrivateEventAccessRule } = props
  return (
    <VStack spacing="lm">
      <HStack spacing="lm">
        <Label primary={formatMessage(PartySizeLocales.minLabel)}>
          <FormNumberInput
            data-test={PartySizeTestId.min}
            placeholder={formatMessage(PartySizeLocales.minPlaceholder)}
            field={props.field.prop('min')}
            decimalScale={0}
            hideErrorMessage
          />
        </Label>

        <Label primary={formatMessage(PartySizeLocales.maxLabel)}>
          <FormNumberInput
            data-test={PartySizeTestId.max}
            placeholder={formatMessage(PartySizeLocales.maxPlaceholder)}
            field={props.field.prop('max')}
            decimalScale={0}
            hideErrorMessage
          />
        </Label>
      </HStack>
      {isPrivateEventAccessRule && (
        <InlineNotice label={formatMessage(PartySizeLocales.privateEventNotice)} data-test={PartySizeTestId.privateEventNotice} />
      )}
      <FieldErrors data-test={PartySizeTestId.errors} field={props.field} />
    </VStack>
  )
}

function InlineNotice({ label, 'data-test': testId }: { label: string; 'data-test': string }) {
  return (
    <Box data-test={testId} pt="s" pb="s" pl="m" pr="m" backgroundColor="infoBackground">
      <Text>{label}</Text>
    </Box>
  )
}
