import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { openReview } from 'mgr/pages/single-venue/marketing/actions/Reviews'

const mapStateToProps = state => ({
  isSlideoutOpen: !!state.reviews.data.selected,
  maxWidth: '398px',
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(openReview(null)),
})

const ReviewSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default ReviewSlideout
