import { useMemo } from 'react'
import type { CostOptions } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useAdditionalInfoValues } from '../AdditionalValues.zod'

export type ChargesNightlifeForm = ZodSchema<typeof useChargesNightlifeForm>
export type CostOptionsValues = ZodSchema<typeof useCostOptionsValues>

export function useCostOptionsValues() {
  return useMemo(() => z.custom<CostOptions>(), [])
}

export function useChargesNightlifeForm() {
  const costOptionsValues = useCostOptionsValues()
  const additionalInfoValues = useAdditionalInfoValues()

  return useMemo(
    () =>
      z.object({
        serviceCharge: z.string(),
        gratuity: z.string(),
        trackComps: z.boolean(),
        costOptions: z.array(costOptionsValues),
        printChitCommitmentMsg: z.string(),
        printGratuityEnabled: additionalInfoValues,
        printGratuityCommitmentMsg: z.string(),
        printGratuityFirstOpt: z.string(),
        printGratuitySecondOpt: z.string(),
        printGratuityChangeMsg: z.string(),
        avgBottlePrice: z.number(),
      }),
    [costOptionsValues, additionalInfoValues]
  )
}
