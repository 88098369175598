import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

interface GoogleReviewSlideoutFooterProps {
  primaryAction: () => void
  primaryActionLabel: string
  deleteAction?: () => void | undefined
  sendingReply: boolean
}

export function GoogleReviewSlideoutFooter({
  primaryAction,
  primaryActionLabel,
  deleteAction,
  sendingReply,
}: GoogleReviewSlideoutFooterProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack width="100%">
      {deleteAction ? (
        <Button data-test="ui-kit-slideout-delete" onClick={deleteAction} variant="tertiary-warning">
          {formatMessage(commonMessages.delete)}
        </Button>
      ) : null}
      <HStack spacing="s" ml="auto">
        <Button disabled={sendingReply} data-test="ui-kit-slideout-primary-button" onClick={primaryAction} variant="primary">
          {primaryActionLabel}
        </Button>
      </HStack>
    </HStack>
  )
}
