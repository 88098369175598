import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { BookingWindowLocales } from './BookingWindow.locales'
import { BookingWindowTestId } from './BookingWindow.testIds'
import { DefaultBookingWindow, BookingWindowFields, CollapsedBookingWindow } from './components'
import type { BookingWindowForm } from './BookingWindow.zod'
import type { BookingChannelsForm } from '../BookingChannels/BookingChannels.zod'

export interface BookingWindowProps {
  field: Field<BookingWindowForm>
  bookingChannelsField: Field<BookingChannelsForm>
}

export function BookingWindow({ field, bookingChannelsField }: BookingWindowProps) {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleSlideoutSection
      title={formatMessage(BookingWindowLocales.title)}
      description={formatMessage(BookingWindowLocales.description)}
      subCaption={formatMessage(BookingWindowLocales.subCaption)}
      contentWhenCollapsed={<CollapsedBookingWindow field={field} bookingChannelsField={bookingChannelsField} />}
      defaultSettings={<DefaultBookingWindow bookingChannelsField={bookingChannelsField} />}
      sectionControllerName="bookingWindow"
      field={field}
      data-test={BookingWindowTestId.section}
    >
      <BookingWindowFields field={field} bookingChannelsField={bookingChannelsField} />
    </AccessRuleSlideoutSection>
  )
}
