import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type GuestFacingContactFormData = ZodSchema<ReturnType<typeof useGuestFacingContactForm>>
export type GuestFacingContactFormFields = Field<GuestFacingContactFormData>

export const useGuestFacingContactForm = () =>
  useMemo(
    () =>
      z.object({
        marketingSenderName: z.string(),
        marketingSenderRole: z.string(),
        marketingEmail: z.string().email().nullable().or(z.literal('')),
      }),
    []
  )

export const getGuestFacingContactFormDefaultValues = (source: VenueProfile): GuestFacingContactFormData => ({
  marketingSenderName: source.marketingSenderName,
  marketingSenderRole: source.marketingSenderRole,
  marketingEmail: source.marketingEmail,
})
