import { useState, memo } from 'react'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { NumberInput } from '@sevenrooms/core/ui-kit/core'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import type { PacingForm } from '../Pacing.zod'

export interface CustomPacingRowProps {
  defaultPacing: Field<PacingForm['maxCoversPerSeatingInterval']>
  customPacing: Field<PacingForm['customPacing']>
  timeSlotLabel: string
  timeSlotId: string
}

export const CustomPacingRow = memo(CustomPacingRowComponent)

function CustomPacingRowComponent(props: CustomPacingRowProps) {
  const { formatMessage } = useLocales()
  const defaultPacing = useWatch(props.defaultPacing)
  const controller = useController(props.customPacing.prop(props.timeSlotId))
  const [value, setValue] = useState(controller.field.value ?? null)
  const [checked, setChecked] = useState(value != null)

  return (
    <HStack spacing="s" alignItems="center">
      <Box width="5.5em">
        <Checkbox
          data-test={`${PacingTestId.customPacingCheckbox}-${props.timeSlotId}`}
          checked={checked}
          onChange={({ target: { checked } }) => {
            setChecked(checked)
            controller.field.onChange(checked ? value : null)
          }}
        >
          {props.timeSlotLabel}
        </Checkbox>
      </Box>
      <Box width="10.3em">
        {checked ? (
          <NumberInput
            data-test={`${PacingTestId.customPacingInput}-${props.timeSlotId}`}
            value={value}
            onChange={setValue}
            onBlur={() => controller.field.onChange(value)}
            decimalScale={0}
          />
        ) : (
          <NumberInput value={defaultPacing} disabled />
        )}
      </Box>
      <Text>{formatMessage(PacingLocales.unit)}</Text>
    </HStack>
  )
}
