import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { AutomaticCheckCreationCardFormSchema } from '../AutomaticCheckCreationCardForm.zod'

export type SimphonyAutomaticCheckCreationCardFormData = ZodSchema<typeof useSimphonyAutomaticCheckCreationCardForm>
export type SimphonyAutomaticCheckCreationCardFormField = Field<SimphonyAutomaticCheckCreationCardFormData>

export function useSimphonyAutomaticCheckCreationCardForm() {
  return useMemo(
    () =>
      AutomaticCheckCreationCardFormSchema.extend({
        employeeRefId: z.number().nullable(),
        orderTypeRefId: z.string(),
        tenderId: z.string(),
        checkCreationRvc: z.string(),
      }).superRefine(({ isAutomaticCheckCreationEnabled, employeeRefId, orderTypeRefId, tenderId, checkCreationRvc }, ctx) => {
        // Only require these fields if the user enables Automated Check Creation
        if (isAutomaticCheckCreationEnabled) {
          if (!employeeRefId) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'number',
              received: 'null',
              path: ['employeeRefId'],
            })
          }
          if (orderTypeRefId.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['orderTypeRefId'],
            })
          }
          if (tenderId.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['tenderId'],
            })
          }
          if (checkCreationRvc.length < 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['checkCreationRvc'],
            })
          }
        }
      }),
    []
  )
}

export const getSimphonyAutomaticCheckCreationCardFormDefaultValues = ({
  isAutomaticCheckCreationEnabled,
  employeeRefId,
  orderTypeRefId,
  tenderId,
  checkCreationRvc,
}: {
  isAutomaticCheckCreationEnabled: boolean
  employeeRefId?: number
  orderTypeRefId?: string
  tenderId?: string
  checkCreationRvc?: string
}): SimphonyAutomaticCheckCreationCardFormData => ({
  isAutomaticCheckCreationEnabled,
  employeeRefId: employeeRefId ?? null,
  orderTypeRefId: orderTypeRefId ?? '',
  tenderId: tenderId ?? '',
  checkCreationRvc: checkCreationRvc ?? '',
})

export const convertSimphonyAutomaticCheckCreationCardFormDataToSimphonyConfig = (
  data: SimphonyAutomaticCheckCreationCardFormData
): Pick<SimphonyConfig, 'checkCreationEnabled' | 'employeeRefId' | 'orderTypeRefId' | 'tenderId' | 'checkCreationRvc'> => {
  const { isAutomaticCheckCreationEnabled: checkCreationEnabled, employeeRefId, orderTypeRefId, tenderId, checkCreationRvc } = data
  return {
    checkCreationEnabled,
    employeeRefId: employeeRefId ?? undefined,
    orderTypeRefId: orderTypeRefId ?? undefined,
    tenderId: tenderId ?? undefined,
    checkCreationRvc: checkCreationRvc ?? undefined,
  }
}
