import { POSITypeEnum, type POSIMetaData } from '@sevenrooms/core/domain'
import { routes } from '@sevenrooms/routes'
import InfoGenesisLogo from './images/infogenesis-logo.svg'
import InfrasysLogo from './images/infrasys-logo.svg'
import LightspeedKLogo from './images/lightspeed_k-logo.svg'
import PlaceholderLogo from './images/placeholder-logo.svg'
import SimphonyLogo from './images/simphony-logo.svg'
import SquareLogo from './images/square-logo.svg'
import ToastLogo from './images/toast-logo.svg'

// Used both in the footer info banner explore more link and the read me in the header
export const posiDocumentationLink = 'https://help.sevenrooms.com/hc/en-us/articles/360012572271-Supported-POS-Systems'
export const customerSupportLink =
  'https://help.sevenrooms.com/hc/en-us/requests/new?utm_source=product&utm_medium=link&utm_campaign=POS_integration_page_2024'

export const posiMetaDataMap: { [key in POSITypeEnum]: POSIMetaData } = {
  // Self-Service Functionality exists
  [POSITypeEnum.SQUARE]: {
    logo: SquareLogo,
    title: 'Square for Restaurants',
    docLink: 'https://help.sevenrooms.com/hc/en-us/articles/20415092348571-Enabling-Your-Square-for-Restaurants-POS-Integration',
    cardSummary: 'Version requirements apply',
    selectPageSortOrder: 0,
  },
  [POSITypeEnum.LIGHTSPEED_K]: {
    logo: LightspeedKLogo,
    title: 'Lightspeed K-Series',
    docLink:
      'https://help.sevenrooms.com/hc/en-us/articles/27461761224859-Enabling-Your-Lightspeed-K-Series-POS-Integration-enhanced-version',
    cardSummary: 'Version requirements apply',
    selectPageSortOrder: 1,
  },
  [POSITypeEnum.SIMPHONY]: {
    logo: SimphonyLogo,
    title: 'Oracle MICROS Simphony',
    docLink:
      'https://help.sevenrooms.com/hc/en-us/articles/19074948714011-Oracle-MICROS-Simphony-Cloud-Integration-v19-2-or-newer-All-Articles',
    cardSummary: 'Simphony Cloud v19.2 & higher',
    internalSetupLink: routes.manager2.settings.pointOfSaleIntegration.auth.simphony,
    selectPageSortOrder: 2,
  },
  // Self-Service Functionality does not exist
  [POSITypeEnum.TOAST]: {
    logo: ToastLogo,
    title: 'Toast',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 3,
  },
  [POSITypeEnum.LIGHTSPEED]: {
    logo: LightspeedKLogo,
    title: 'Lightspeed L-Series', // POSITypeEnum.LIGHTSPEED refers to the L-Series
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 4,
  },
  [POSITypeEnum.LIGHTSPEED_KOUNTA]: {
    logo: '',
    title: 'Lightspeed Kounta',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: -1, // Negative values used to skip cards
  },
  [POSITypeEnum.INFOGENESIS]: {
    logo: InfoGenesisLogo,
    title: 'Agilysys InfoGenesis',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 5,
  },
  [POSITypeEnum.ALOHA]: {
    logo: PlaceholderLogo,
    title: 'Aloha',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 6,
  },
  [POSITypeEnum.HL]: {
    logo: PlaceholderLogo,
    title: 'H&L',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 7,
  },
  [POSITypeEnum.INFRASYS]: {
    logo: InfrasysLogo,
    title: 'Infrasys',
    docLink: posiDocumentationLink,
    cardSummary: '',
    selectPageSortOrder: 8,
  },
} as const
