import { srDelete, srGet, srPost, throwFetchError } from '@sevenrooms/core/api'

export const fetchAutotagsList = venue =>
  srGet(
    `/api-yoa/autotag/config_tiers`,
    { venue: venue.id, include_client_stats: true },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the autotags list', e)
    return e
  })

export const fetchAutotagsActivityReport = (venue, params) =>
  srGet(
    '/api-yoa/autotag/activity',
    { ...params, venue: venue.id },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the autotags activity report', e)
    return e
  })

export const fetchAutotag = (venue, tagId) =>
  srGet(
    `/api-yoa/autotag/configs/${tagId}`,
    { venue: venue.id },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the autotag', e)
    return e
  })

export const saveAutotag = (venue, tagData, perksByTagName, notesByTagName) =>
  srPost(
    `/api-yoa/autotag/configs/${tagData.id}`,
    {
      autotag_config: JSON.stringify(tagData),
      venue: venue.id,
      perks_by_tag: JSON.stringify(perksByTagName),
      notes_by_tag: JSON.stringify(notesByTagName),
    },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).then(response => {
    if (response.error) {
      throw new Error(response.errorMsg)
    }
    return response
  })

export const deleteAutotag = (venue, tagID) =>
  srDelete(`/api-yoa/autotag/custom`, {
    venue: venue.id,
    tag_ids: tagID,
  }).then(throwFetchError)

export const fetchCustomAutotagsObjects = venue =>
  srGet(
    '/api-yoa/autotag/custom',
    {
      venue: venue.id,
    },
    {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  )
    .then(response => {
      if (!response || response.status !== 200) {
        throw new Error(response)
      }
      return response
    })
    .catch(e => {
      console.error('An unexpected error occurred fetching the autotag', e)
      return e
    })

export const fetchTagCampaignsAndPerks = (venue, tagID, tagConfigId) =>
  srGet('/api-yoa/autotag_email_campaigns_and_perks', {
    venue: venue.id,
    tag_id: tagID,
    autotag_config_id: tagConfigId,
  }).then(throwFetchError)
