import camelCase from 'camelcase'
import type { Field } from '@sevenrooms/core/form'
import { type FormatMessage, useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, BaseSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { userAccountPermissionsLocales, userAccountsLocales } from './UserAccountsLocales'
import type { userAccountsFormSchemaType } from './UserAccountsFormSchema.zod'

enum PrivilegeLevels {
  FullAccess,
  RestrictedAccess,
  NoAccess,
  ViewOnly,
}

enum Roles {
  Admin,
  Manager,
  ViewOnly,
}

interface permissionOptionInterface {
  id: number
  label: string
}

interface roleInterface {
  id: number
  isEditable: boolean
  default: number
}

interface permissionInterface {
  id: string
  title: string
  availablePrivilegeLevels: number[]
  availableWithDefaultRoles: roleInterface[]
}

interface permissionGroupInterface {
  id: string
  title: string
  permissions: permissionInterface[]
}

function getPermissionOption(formatMessage: FormatMessage, optionId: number): permissionOptionInterface {
  switch (optionId) {
    case PrivilegeLevels.FullAccess:
      return {
        id: PrivilegeLevels.FullAccess,
        label: formatMessage(userAccountPermissionsLocales.fullAccess),
      }
    case PrivilegeLevels.RestrictedAccess:
      return {
        id: PrivilegeLevels.RestrictedAccess,
        label: formatMessage(userAccountPermissionsLocales.restrictedAccess),
      }
    case PrivilegeLevels.NoAccess:
      return {
        id: PrivilegeLevels.NoAccess,
        label: formatMessage(userAccountPermissionsLocales.noAccess),
      }
    default:
      return {
        id: PrivilegeLevels.ViewOnly,
        label: formatMessage(userAccountPermissionsLocales.viewOnly),
      }
  }
}
function getPermissionSet(formatMessage: FormatMessage) {
  return [
    {
      id: 'general_access_settings',
      title: formatMessage(userAccountPermissionsLocales.generalAccessSettings),
      permissions: [
        {
          id: 'access_client_tab',
          title: formatMessage(userAccountPermissionsLocales.accessClientTab),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'access_marketing_tools',
          title: formatMessage(userAccountPermissionsLocales.accessMarketingTools),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'access_private_line',
          title: formatMessage(userAccountPermissionsLocales.accessPrivateLine),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: true, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: true, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: true, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'view_reporting',
          title: formatMessage(userAccountPermissionsLocales.viewReportingFull),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
      ],
    },
    {
      id: 'client_data_access_settings',
      title: formatMessage(userAccountPermissionsLocales.clientDataAccessSettings),
      permissions: [
        {
          id: 'add_remove_client_tags_from_client_profiles',
          title: formatMessage(userAccountPermissionsLocales.addRemoveClientTagsClientProfiles),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'manage_clients_tags_list',
          title: formatMessage(userAccountPermissionsLocales.manageClientsTagsLists),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'export_client_data',
          title: formatMessage(userAccountPermissionsLocales.exportClientData),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'manage_reservation_tags_lists',
          title: formatMessage(userAccountPermissionsLocales.manageReservationTagsLists),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'manage_global_auto_tags',
          title: formatMessage(userAccountPermissionsLocales.manageGlobalAutoTags),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'view_all_clients_contact_info',
          title: formatMessage(userAccountPermissionsLocales.viewAllClientsContactInfo),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'view_clients_spend_aggregates_data',
          title: formatMessage(userAccountPermissionsLocales.viewClientSpendAggregatesData),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'view_clients_associated_with_all_venues_in_the_group',
          title: formatMessage(userAccountPermissionsLocales.viewClientsAssociatedGroup),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
        {
          id: 'view_pos_check_data',
          title: formatMessage(userAccountPermissionsLocales.viewPOSCheckData),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.ViewOnly },
          ],
        },
      ],
    },
    {
      id: 'feature_permission',
      title: formatMessage(userAccountPermissionsLocales.featurePermission),
      permissions: [
        {
          id: 'create_edit_email_campaigns_with_global_tags',
          title: formatMessage(userAccountPermissionsLocales.CreateEditEmailCampaignsGlobalTags),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'write_message_in_private_line',
          title: formatMessage(userAccountPermissionsLocales.writeMessagesInPrivateLine),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: true, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: true, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: true, default: PrivilegeLevels.NoAccess },
          ],
        },
      ],
    },
    {
      id: 'feature_permission',
      title: formatMessage(userAccountPermissionsLocales.featurePermission),
      permissions: [
        {
          id: 'administrate_user_permissions',
          title: formatMessage(userAccountPermissionsLocales.administrateUserAccess),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.NoAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'administrate_general_settings',
          title: formatMessage(userAccountPermissionsLocales.administrateGeneralSettings),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
        {
          id: 'administrate_widget_settings',
          title: formatMessage(userAccountPermissionsLocales.administrateWidgetSettings),
          availablePrivilegeLevels: [PrivilegeLevels.FullAccess, PrivilegeLevels.NoAccess, PrivilegeLevels.ViewOnly],
          availableWithDefaultRoles: [
            { id: Roles.Admin, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.Manager, isEditable: false, default: PrivilegeLevels.FullAccess },
            { id: Roles.ViewOnly, isEditable: false, default: PrivilegeLevels.NoAccess },
          ],
        },
      ],
    },
  ]
}

function PermissionSection({
  permissionGroup,
  field,
}: {
  permissionGroup: permissionGroupInterface
  field: Field<userAccountsFormSchemaType>
}) {
  return (
    <VStack spacing="s">
      <Text fontSize="xl" fontWeight="bold">
        {permissionGroup.title}
      </Text>
      <VStack>
        {permissionGroup.permissions.map(permission => (
          <PermissionLine key={permission.id} permission={permission} field={field} />
        ))}
      </VStack>
    </VStack>
  )
}

function PermissionLine({ permission, field }: { permission: permissionInterface; field: Field<userAccountsFormSchemaType> }) {
  const { formatMessage } = useLocales()
  const options = permission.availablePrivilegeLevels.map(privilegeLevel => getPermissionOption(formatMessage, privilegeLevel))

  return (
    <HStack spacing="s">
      <Text fontSize="xl" fontWeight="bold" width="50%">
        {permission.title}
      </Text>
      <FormSelect field={field.prop(camelCase(permission.id) as 'accessClientTab')} options={options} hideEmptyOption searchable={false} />
    </HStack>
  )
}

export function PermissionSet({ isLoading, field }: { isLoading: boolean; field: Field<userAccountsFormSchemaType> }) {
  const { formatMessage } = useLocales()

  const permissionSet = getPermissionSet(formatMessage)

  return (
    <BaseSection
      title={formatMessage(userAccountsLocales.detailedPermissionSetTitle)}
      description={formatMessage(userAccountsLocales.detailedPermissionSetDescription)}
      actions={
        <HStack spacing="s">
          <Select
            options={
              [
                { id: 'admin', label: formatMessage(userAccountPermissionsLocales.admin) },
                { id: 'manager', label: formatMessage(userAccountPermissionsLocales.manager) },
                { id: 'viewer', label: formatMessage(userAccountPermissionsLocales.viewer) },
              ] as const
            }
            value="admin"
            hideEmptyOption
            searchable={false}
          />
          <Button variant="secondary" onClick={() => {}} data-test="button-restore-deault" disabled={isLoading}>
            {formatMessage(userAccountsLocales.buttonRestoreDefault)}
          </Button>
        </HStack>
      }
    >
      <VStack m="lm" spacing="lm">
        {permissionSet.map(permissionGroup => (
          <PermissionSection key={permissionGroup.id} permissionGroup={permissionGroup} field={field} />
        ))}
      </VStack>
    </BaseSection>
  )
}
