import { defineMessages } from '@sevenrooms/core/locales'

export const ScheduleLocales = defineMessages({
  datesLabel: {
    id: 'ar.schedule.datesLabel',
    defaultMessage: 'Start and End Dates',
  },
  daysOfWeekLabel: {
    id: 'ar.schedule.daysOfWeekLabel',
    defaultMessage: 'Days of week',
  },
  timeSlotsLabel: {
    id: 'ar.schedule.timeSlotsLabel',
    defaultMessage: 'Time(s)',
  },
  timeSlotsDescription: {
    id: 'ar.schedule.timeSlotsDescription',
    defaultMessage: 'During which Shifts or times should this Access Rule apply?',
  },
  firstReservationLabel: {
    id: 'ar.schedule.firstReservationLabel',
    defaultMessage: 'First Reservation',
  },
  lastReservationLabel: {
    id: 'ar.schedule.lastReservationLabel',
    defaultMessage: 'Last Reservation',
  },
  restrictToShiftsLabel: {
    id: 'ar.schedule.restrictToShiftsLabel',
    defaultMessage: 'Restrict to Shift Category',
  },
  noOverlapsWarning: {
    id: 'ar.schedule.noOverlapsWarning',
    defaultMessage: 'Warning: Times chosen do not overlap with any applicable Shifts',
  },
  searchTimeSlots: {
    id: 'ar.schedule.searchTimeSlots',
    defaultMessage: 'Search time slots(s)',
  },
  searchShifts: {
    id: 'ar.schedule.searchShifts',
    defaultMessage: 'Choose Shift Categories',
  },
  timeSlotAll: {
    id: 'ar.schedule.timeSlotAll',
    defaultMessage: 'All times during Shift Category',
  },
  timeSlotCustom: {
    id: 'ar.schedule.timeSlotCustom',
    defaultMessage: 'Custom time range',
  },
  timeSlotSpecific: {
    id: 'ar.schedule.timeSlotSpecific',
    defaultMessage: 'Specific time slots',
  },
  shiftSelectionRequired: {
    id: 'ar.schedule.shiftSelectionRequired',
    defaultMessage: 'Shift selection required',
  },
  startTimeRequired: {
    id: 'ar.schedule.startTimeRequired',
    defaultMessage: 'First Reservation time required',
  },
  endTimeRequired: {
    id: 'ar.schedule.endTimeRequired',
    defaultMessage: 'Last Reservation time required',
  },
  timeSlotRequired: {
    id: 'ar.schedule.timeSlotRequired',
    defaultMessage: 'Time slot selection required',
  },
  startDateToIndefinite: {
    id: 'ar.schedule.startDateToIndefinite',
    defaultMessage: '{date} - Indefinite',
  },
  shiftCategoryLabel: {
    id: 'ar.schedule.shiftCategoryLabel',
    defaultMessage: 'All {name} Shifts ({count})',
  },
} as const)
