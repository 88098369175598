import { type PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { BasicButton, Checkbox, type CheckboxProps } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, Tooltip } from '@sevenrooms/core/ui-kit/layout'

export interface CheckboxTogglerProps extends CheckboxProps<boolean> {
  infoText?: string
  onShow?: () => void
  onHide?: () => void
}

export function CheckboxToggler({
  title,
  description,
  infoText,
  checked,
  onShow,
  onHide,
  children,
  ...props
}: PropsWithChildren<CheckboxTogglerProps>) {
  const [open, setOpen] = useState(checked)

  useEffect(() => {
    if (checked !== open) {
      setOpen(checked)
    }
  }, [checked, open])

  const toggle = useCallback(() => {
    if (open) {
      onHide?.()
    } else {
      onShow?.()
    }
    setOpen(!open)
  }, [onHide, onShow, open])

  return (
    <>
      <Checkbox {...props} checked={open} onChange={toggle} description={description}>
        <HStack spacing="xs">
          {title}
          {infoText && (
            <Box ml="xs">
              <Tooltip content={<>{infoText}</>}>
                <BasicButton aria-label="Additional Information">
                  <Icon name="VMSWeb-info" />
                </BasicButton>
              </Tooltip>
            </Box>
          )}
        </HStack>
      </Checkbox>
      {open && <Box pl="lm">{children}</Box>}
    </>
  )
}
