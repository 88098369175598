import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  locationIdLabel: {
    id: 'posiSettings.SimphonyLocationSelectionCard.locationIdLabel',
    defaultMessage: 'Location',
  },
  checkPollingRvcsLabel: {
    id: 'posiSettings.SimphonyLocationSelectionCard.checkPollingRvcsLabel',
    defaultMessage: 'Revenue Center(s)',
  },
  checkPollingRvcsDisabledPlaceholder: {
    id: 'posiSettings.SimphonyLocationSelectionCard.checkPollingRvcsDisabledPlaceholder',
    defaultMessage: 'Choose a location',
  },
  checkPollingRvcsPlaceholder: {
    id: 'posiSettings.SimphonyLocationSelectionCard.checkPollingRvcsPlaceholder',
    defaultMessage: 'Choose an option',
  },
} as const)
