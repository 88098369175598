import * as React from 'react'
import { HStack, VStack, PreviewImage } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import BestPracticesIcon from './assets/BestPracticesIcon.svg'
import HelpfulTipIcon from './assets/HelpfulTipIcon.svg'

interface RecommendationTipProps {
  header: string
  description: string
  headerIcon?: 'bestPractices' | 'helpfulTip'
  imageSrc?: string
}

export function RecommendationTip({ header, description, headerIcon, imageSrc }: RecommendationTipProps) {
  return (
    <VStack
      backgroundColor="insetBackground"
      borderRadius="s"
      borderColor="dividerLines"
      spacing="s"
      pt="m"
      pl="m"
      pr="m"
      pb="m"
      maxWidth="100%"
    >
      <HStack spacing="s" flexWrap="wrap" alignItems="center">
        {headerIcon && (
          <PreviewImage
            src={headerIcon === 'helpfulTip' ? HelpfulTipIcon : BestPracticesIcon}
            alt="icon"
            data-test="recommendation-tip-icon"
            height="13px"
            width="12px"
          />
        )}
        <Text fontSize="xs" fontWeight="bold" color="primarySelected" letterSpacing="m">
          {header.toUpperCase()}
        </Text>
      </HStack>
      <HStack spacing="m" alignItems="center">
        <Text textStyle="body1" color="primaryFont">
          {description}
        </Text>
        {imageSrc && <PreviewImage src={imageSrc} alt="icon" data-test="recommendation-tip-description-icon" />}
      </HStack>
    </VStack>
  )
}
