import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  editIntegration: {
    id: 'paymentsSettings.EditPaymentIntegrationPage.editIntegration',
    defaultMessage: 'Edit payment processor',
  },
  account: {
    id: 'paymentSettings.EditPaymentIntegrationPage.account',
    defaultMessage: 'Account',
  },
  viewDocs: {
    id: 'paymentSettings.EditPaymentIntegrationPage.viewDocs',
    defaultMessage: 'View Docs',
  },
} as const)
