import React from 'react'
import { type PerkAvailabilityType, PerkAvailabilityTypeEnum } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { RadioGroup, Label } from '@sevenrooms/core/ui-kit/form'
import { perksMessages } from '../../locales'

interface PerkAvailabilityFormProps {
  disabled: boolean
  perkAvailabilityTypeField: Field<PerkAvailabilityType>
}

export function PerkAvailabilityForm({ disabled, perkAvailabilityTypeField }: PerkAvailabilityFormProps) {
  const { formatMessage } = useLocales()

  const { perkAvailabilityLabel, perkAvailabilityAnytime, perkAvailabilityRestrictions } = perksMessages

  return (
    <Label primary={formatMessage(perkAvailabilityLabel)}>
      <RadioGroup
        data-test="sr-perk-form-restrictions-change"
        disabled={disabled}
        choices={[
          {
            label: formatMessage(perkAvailabilityAnytime),
            value: PerkAvailabilityTypeEnum.ANY_TIME,
          },
          {
            label: formatMessage(perkAvailabilityRestrictions),
            value: PerkAvailabilityTypeEnum.AVAILABILITY_RESTRICTION,
          },
        ]}
        field={perkAvailabilityTypeField}
      />
    </Label>
  )
}
