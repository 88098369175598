import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './SimphonyAuthenticationCard.locales'
import type { SimphonyAuthenticationCardFormField } from './SimphonyAuthenticationCardForm.zod'

export interface SimphonyAuthenticationCardProps {
  field: SimphonyAuthenticationCardFormField
}

export function SimphonyAuthenticationCard({ field }: SimphonyAuthenticationCardProps) {
  const { formatMessage } = useLocales()
  return (
    <VStack backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
      <HStack spacing="m" pt="m" pb="l" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
        <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
          <Text textStyle="h2">{formatMessage(messages.cardLabel)}</Text>{' '}
          <Box width="100%">
            <DividerLine mr="none" ml="none" />
          </Box>
          <VStack width="100%" spacing="s" justifyContent="center" alignItems="start">
            <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
              <Label width="50%" primary={formatMessage(messages.orgShortNameLabel)}>
                <FormInput field={field.prop('orgShortName')} fullWidth disabled />
              </Label>
              <Label width="50%" primary={formatMessage(messages.simphonyBaseUrlLabel)}>
                <FormInput field={field.prop('simphonyBaseUrl')} fullWidth disabled />
              </Label>
            </HStack>
            <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
              <Label width="50%" primary={formatMessage(messages.openidUrlLabel)}>
                <FormInput field={field.prop('openidUrl')} fullWidth disabled />
              </Label>
              <Label width="50%" primary={formatMessage(messages.openidClientIdLabel)}>
                <FormInput field={field.prop('openidClientId')} fullWidth disabled />
              </Label>
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  )
}
