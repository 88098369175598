import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { marketingHomeMessages } from '../locales'

export function ImportPendingBanner() {
  const { formatMessage } = useLocales()

  return (
    <Banner
      canDismiss={false}
      type="attention"
      title={formatMessage(marketingHomeMessages.importBannerTitle)}
      description={formatMessage(marketingHomeMessages.importBannerDescription)}
      icon={<Icon name="VMSWeb-refresh" size="2x" />}
    />
  )
}
