import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { CustomerSupportLink } from '../../Integrations'
import { PaymentsValueProp } from '../../PaymentsValueProp'
import { messages } from './IntegrationNoSelfServePage.locales'

export function IntegrationNoSelfServePage() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  return (
    <>
      <SettingsPageMeta title={formatMessage(messages.pageTitle)} venue={venue?.name} />

      <SettingsPageContent title={formatMessage(messages.pageTitle)} secondHeaderMaxWidth="968px">
        <Box width="100%">
          <VStack m="m" p="m" flexGrow="1" backgroundColor="primaryBackground" alignItems="center" maxWidth="968px">
            <HStack mt="m" mb="s">
              <Text textStyle="h3">{formatMessage(messages.infoHeader)}</Text>
            </HStack>
            <HStack mb="m">
              <Text textStyle="body1" textAlign="center">
                {formatMessage(messages.infoBody, {
                  a: (chunks: string[]) => <Anchor href={CustomerSupportLink}>{chunks}</Anchor>,
                })}
              </Text>
            </HStack>
          </VStack>
          <PaymentsValueProp />
        </Box>
      </SettingsPageContent>
    </>
  )
}
