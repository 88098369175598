import * as React from 'react'
import { type AccessRuleInventoryType, AccessRuleInventoryTypeEnum } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Checkbox, FormSelect, FieldErrors, FormNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { VStack, Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleTooltip } from '../../shared'
import { ReservationCoverLimitLocales } from '../ReservationCoverLimit.locales'
import { ReservationCoverLimitTestId } from '../ReservationCoverLimit.testIds'
import type { ReservationCoverLimitForm } from '../ReservationCoverLimit.zod'

export interface ReservationCoverLimitEditProps {
  field: Field<ReservationCoverLimitForm>
}

export function ReservationCoverLimitEdit({ field }: ReservationCoverLimitEditProps) {
  const { formatMessage } = useLocales()

  const typeOptions = React.useMemo(
    () => [
      {
        id: AccessRuleInventoryTypeEnum.RESERVATIONS as AccessRuleInventoryType,
        label: formatMessage(ReservationCoverLimitLocales.reservations),
      },
      { id: AccessRuleInventoryTypeEnum.COVERS as AccessRuleInventoryType, label: formatMessage(ReservationCoverLimitLocales.covers) },
    ],
    [formatMessage]
  )

  const count = useWatch(field.prop('count'))

  const tooltip = (
    <VStack spacing="s">
      <Text color="lightFont">{formatMessage(ReservationCoverLimitLocales.guaranteeBookingsTooltip)}</Text>
    </VStack>
  )

  return (
    <>
      <Label primary={formatMessage(ReservationCoverLimitLocales.label)} />
      <VStack spacing="sm" alignItems="start">
        <HStack spacing="s" alignItems="start">
          <FormNumberInput
            data-test={ReservationCoverLimitTestId.count}
            placeholder={formatMessage(ReservationCoverLimitLocales.placeholder)}
            field={field.prop('count')}
            decimalScale={0}
            hideErrorMessage
          />
          <FormSelect data-test={ReservationCoverLimitTestId.type} field={field.prop('type')} options={typeOptions} searchable={false} />

          {count === 0 && (
            <Box mt="sm">
              <AccessRuleTooltip data-test={ReservationCoverLimitTestId.zeroCoversTooltip} variant="warning">
                <Text color="lightFont" fontWeight="bold">
                  {formatMessage(commonMessages.warning)}
                </Text>
                <Text color="lightFont">{formatMessage(ReservationCoverLimitLocales.tooltip)}</Text>
              </AccessRuleTooltip>
            </Box>
          )}
        </HStack>
        <FieldErrors data-test={ReservationCoverLimitTestId.errors} field={field.prop('count')} />
        <Checkbox data-test={ReservationCoverLimitTestId.guaranteeBookings} field={field.prop('guaranteeBookings')} info={tooltip}>
          {formatMessage(ReservationCoverLimitLocales.guaranteeBookings)}
        </Checkbox>
      </VStack>
    </>
  )
}
