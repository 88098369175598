import _ from 'lodash'
import moment from 'moment-timezone'
// eslint-disable-next-line no-restricted-imports
import styled, { type DefaultTheme, ThemeProvider } from 'styled-components' // hack to get non-ui-kit GX components to preview correctly
import { type Field, useWatch } from '@sevenrooms/core/form'
import type { TimeOnly } from '@sevenrooms/core/timepiece'
import TimeSlotUntyped from '../../../../../../application/site/static/app/widget/dining/src/SearchResults/components/TimeSlot'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import type { AccessRuleWidgetSettings } from '../../shared'
import type { GuestFacingForm } from '../GuestFacing.zod'

export interface TimeSlotProps {
  widgetSettings: AccessRuleWidgetSettings
  timeSlot: TimeOnly
  field: Field<GuestFacingForm>
  currencyCode: string
  locale?: string
}

export function TimeSlot({ timeSlot, widgetSettings, field, ...props }: TimeSlotProps) {
  const { image, timeslotDescription, title, description } = useWatch(field)

  return (
    <ThemeProvider theme={widgetSettings as unknown as DefaultTheme}>
      <ThemeHack data-test={GuestFacingTestId.timeSlotButton} font={widgetSettings.font}>
        <TimeSlotUntyped
          {...props}
          publicTimeSlotDescription={timeslotDescription}
          publicDescriptionTitle={title}
          publicLongFormDescription={description}
          publicPhoto={image?.rawUrl}
          timeSlotMoment={moment(timeSlot.toJsDate().toISOString())}
          onTimeSlotClick={_.noop}
          textPerPerson="person"
          textPerReservation="reservation"
          selectedAutomaticUpsells={{}}
          requirePayment={false}
          cost={null}
          chargeType={null}
          duration={60}
          foundNearbyResult
        />
      </ThemeHack>
    </ThemeProvider>
  )
}

const ThemeHack = styled.div<{ font: string }>`
  font-family: ${props => props.font};
  & i {
    width: 8px;
  }
  & button {
    width: 100%;
    margin: 0;
  }
`
