import _ from 'lodash'
import moment from 'moment-timezone'

const isChainValid = chain => {
  let isValid = true
  chain.forEach(obj => {
    if (obj.value === '') {
      isValid = false
    }
  })
  return isValid
}

export const validateCustomAutotagConfigState = customAutotagConfigState => {
  const flatConfigState = _.flatten(customAutotagConfigState)
  if (!_.filter(flatConfigState, obj => !!obj.condition).length) {
    return [false, 'Invalid configuration: must setup conditions']
  }
  let allValuesValid = _.reduce(
    flatConfigState,
    (isValid, obj) => {
      if (!isValid) {
        return isValid
      } else if (!obj.condition) {
        return true
      }
      if (_.isArray(obj.value)) {
        return !_.isEmpty(obj.value)
      } else if (_.isNumber(obj.value)) {
        return true
      }
      return !!obj.value
    },
    true
  )
  flatConfigState.forEach(obj => {
    if (!isChainValid(obj.chain)) {
      allValuesValid = false
    }
  })
  if (!allValuesValid) {
    return [false, 'Invalid configuration: all conditions must have values']
  }
  return [true, '']
}

const _transformConditionForServer = data => {
  const item = { ...data }
  switch (item.condition) {
    case 'CLIENT_CREATED_DATE':
    case 'RESERVATION_DATE':
    case 'RESERVATION_CREATED_DATE':
    case 'ORDER_OPEN_DATE':
    case 'ORDER_CLOSE_DATE':
    case 'WAITLIST_DATE_JOINED':
    case 'FEEDBACK_ORDER_DATE':
    case 'REVIEW_DATE':
    case 'FEEDBACK_VISIT_DATE':
      if (_.isArray(item.value)) {
        item.value = _.map(item.value, value => (moment.isMoment(value) ? value.format('YYYY-MM-DD[T]HH:mm:ss') : value))
      }
      break
    case 'RESERVATION_TIME':
    case 'ORDER_OPEN_TIME':
    case 'ORDER_CLOSE_TIME':
    case 'WAITLIST_START_TIME':
      if (_.isArray(item.value)) {
        item.value = _.map(item.value, value => value.format('YYYY-MM-DDTHH:mm:ss'))
      }
      break
    case 'VISIT_TOTAL_SPEND':
      // To support IS_ANYTHING
      if (item.value !== 'NULL') {
        item.value = Number(item.value)
      }
      break
    case 'VISIT_HISTORY_TOTAL_SPEND':
    case 'VISIT_HISTORY_TOTAL_SPEND_PER_VISIT':
    case 'VISIT_HISTORY_AVG_SPEND_PER_COVER':
    case 'VISIT_POS_ITEM_PRICE':
    case 'ORDER_ITEM_PRICE':
    case 'ORDER_TOTAL_SPEND':
    case 'AGGREGATE_ORDER_TOTAL_SPEND':
    case 'AGGREGATE_ORDER_AVG_SPEND_PER_ORDER':
    case 'WAITLIST_RECOMMEND_WAIT_TIME':
    case 'WAITLIST_QUOTED_WAIT_DURATION':
      item.value = Number(item.value)
      break
    case 'FEEDBACK_ORDER_RECOMMENDATION':
      item.value = String(item.value)
      item.value = item.value === 'true'
      break
    case 'FEEDBACK_VISIT_RECOMMENDATION':
      item.value = String(item.value)
      item.value = item.value === 'true'
      break
    case 'FEEDBACK_VISIT_OVERALL_RATING':
    case 'FEEDBACK_VISIT_FOOD_RATING':
    case 'FEEDBACK_VISIT_DRINKS_RATING':
    case 'FEEDBACK_VISIT_SERVICE_RATING':
    case 'FEEDBACK_VISIT_AMBIENCE_RATING':
    case 'FEEDBACK_ORDER_OVERALL_RATING':
    case 'FEEDBACK_ORDER_FOOD_RATING':
    case 'FEEDBACK_ORDER_SERVICE_RATING':
    case 'FEEDBACK_ORDER_AMBIENCE_RATING':
    case 'REVIEW_RATING':
      if (_.isArray(item.value)) {
        item.value = _.map(item.value, value => parseInt(value, 10))
      }
      break
    default:
  }
  return item
}

export const transformConfigStateForServer = customAutotagConfigState => {
  const outerState = _.reduce(
    customAutotagConfigState,
    (accumOuter, outerLevelItem) => {
      const innerState = _.reduce(
        outerLevelItem,
        (accumInner, innerLevelItem) => {
          const itemCopy = _transformConditionForServer(innerLevelItem)
          const chainCopy = [...itemCopy.chain]
          itemCopy.chain = _.map(chainCopy, _transformConditionForServer)
          return itemCopy.condition ? _.concat(accumInner, itemCopy) : accumInner
        },
        []
      )
      return innerState.length ? _.concat(accumOuter, [innerState]) : accumOuter
    },
    []
  )
  return outerState
}

const _transformConditionForClient = data => {
  const item = { ...data }
  switch (item.condition) {
    case 'CLIENT_CREATED_DATE':
    case 'RESERVATION_DATE':
    case 'RESERVATION_CREATED_DATE':
    case 'ORDER_OPEN_DATE':
    case 'ORDER_CLOSE_DATE':
    case 'REVIEW_DATE':
    case 'FEEDBACK_ORDER_DATE':
    case 'FEEDBACK_VISIT_DATE':
    case 'WAITLIST_DATE_JOINED':
      if (_.isArray(item.value)) {
        item.value = _.map(item.value, value => moment(value))
      }
      break
    case 'RESERVATION_TIME':
    case 'ORDER_OPEN_TIME':
    case 'ORDER_CLOSE_TIME':
    case 'WAITLIST_START_TIME':
      if (_.isArray(item.value)) {
        item.value = _.map(item.value, value => moment(value))
      }
      break
    case 'VISIT_TOTAL_SPEND':
      if (item.value !== 'NULL') {
        item.value = item.value.toFixed(2)
      }
      break
    case 'VISIT_HISTORY_TOTAL_SPEND':
    case 'VISIT_HISTORY_TOTAL_SPEND_PER_VISIT':
    case 'VISIT_HISTORY_AVG_SPEND_PER_COVER':
    case 'VISIT_POS_ITEM_PRICE':
    case 'ORDER_ITEM_PRICE':
    case 'ORDER_TOTAL_SPEND':
    case 'AGGREGATE_ORDER_TOTAL_SPEND':
    case 'AGGREGATE_ORDER_AVG_SPEND_PER_ORDER':
      item.value = item.value.toFixed(2)
      break
    default:
  }
  return item
}
export const transformConfigStateForClient = customAutotagConfigState =>
  _.map(customAutotagConfigState, outerLevelItem =>
    _.map(outerLevelItem, innerLevelItem => {
      const itemCopy = _transformConditionForClient(innerLevelItem)
      const chainCopy = [...itemCopy.chain]
      itemCopy.chain = _.map(chainCopy, _transformConditionForClient)
      return itemCopy
    })
  )

export const formatToOptionsList = (obj, itemKey, itemDisplayTextKey, sort = false) => {
  const retval = _.reduce(
    obj,
    (accum, item) => {
      const innerItem = _.values(item)[0]
      return _.concat(accum, [{ key: innerItem[itemKey], displayText: innerItem[itemDisplayTextKey] }])
    },
    []
  )
  return sort ? _.sortBy(retval, option => option.displayText) : retval
}

const FRONT_END_AUTOTAG_TYPES = {
  CLIENT: 'Client',
}

export const determineFrontEndAutotagType = clientSideConfig => {
  const flatConfig = _.flatten(clientSideConfig)
  if (_.every(flatConfig, item => !item.condition || _.startsWith(item.condition, 'CLIENT'))) {
    return FRONT_END_AUTOTAG_TYPES.CLIENT
  }
  return null
}
