import _ from 'lodash'
import { connect } from 'react-redux'
import { fetchBookingSearchResults } from '../actions/actions'
import {
  CLOSE_SEARCH_MODAL,
  DISPATCH_MULTI_SEARCH,
  INIT,
  CHOOSE_TAB,
  UPDATE_SEARCH_SHIFT,
  UPDATE_SEARCH_TIME,
} from '../actions/ActionTypes'
import App from '../components/app'
import {
  SearchModal,
  CombinedBookingSearchResultsSection,
  SearchQueryArea,
  ClientSearchResultsArea,
  TabRow,
} from '../components/components'

const mapStateToProps = state => ({
  isModalOpen: state.search.isModalOpen,
})

const mapDispatchToProps = dispatch => ({
  onCloseClick: () => {
    dispatch({ type: CLOSE_SEARCH_MODAL })
  },
  onKeyDown: event => {
    if (event.keyCode === 27) {
      dispatch({ type: CLOSE_SEARCH_MODAL })
    }
  },
})

export const ExpandableSearchModal = connect(mapStateToProps, mapDispatchToProps)(SearchModal)

const bookingStateToProps = rootState => {
  const state = rootState.search

  return {
    futureBookings: state.futureBookings,
    pastBookings: state.pastBookings,
    activeTab: state.activeTab,
    isReservationSlideoutAvailable: state.isReservationSlideoutAvailable,
    isRequestSlideoutAvailable: state.isRequestSlideoutAvailable,
    venueUrlKey: state.venueKey,
    searchQuery: state.searchQuery,
    isSingleVenue: state.isSingleVenue,
    hasMoreResults: state.numFound > 30,
    isSupaflyEnabled: !!globalInit.venueSettings.use_supafly,
    reviewId: state.reviewId,
  }
}

const bookingDispatchToProps = () => ({
  onReservationClick: (actualId, venueId, reviewId) => {
    // eslint-disable-next-line no-undef
    if (globalInit.venueSettings.use_supafly) {
      SvrManager.ActualSlideout.viewActual(actualId, venueId, () => {
        SvrManager.ActualSlideout.setToLinking(reviewId)
      })
    } else {
      ReservationSlideOut.showReservation(actualId)
    }
  },
})

const mobileUniversalSearchTabBarStateToProps = state => ({
  tabs: state.tabs,
  activeTab: state.activeTab,
})

const mobileUniversalTabBarDispatchToProps = dispatch => ({
  onTabChange: activeTab => {
    dispatch({ type: CHOOSE_TAB, activeTab })
  },
})

export const MobileSearchTabBar = connect(mobileUniversalSearchTabBarStateToProps, mobileUniversalTabBarDispatchToProps)(TabRow)

const searchQueryStateToProps = state => ({
  searchQuery: state.search.searchQuery,
  hasFocus: state.search.isModalOpen,
  searchShift: state.search.searchShift,
  searchTime: state.search.searchTime,
})

const massDispatch = (_dispatch, val) => {
  _dispatch(fetchBookingSearchResults(val))
}

const debouncedSearchQueryResultDispatch = _.debounce(massDispatch, 1000, {
  leading: true,
  trailing: true,
})

const searchQueryDispatchToProps = dispatch => ({
  onShiftSearchChange: shift => {
    dispatch({
      type: UPDATE_SEARCH_SHIFT,
      shift,
    })
    debouncedSearchQueryResultDispatch(dispatch, null)
  },
  onTimeSearchChange: time => {
    dispatch({
      type: UPDATE_SEARCH_TIME,
      time,
    })
    debouncedSearchQueryResultDispatch(dispatch, null)
  },
  onSearchChange: event => {
    const val = event.target.value
    const cleansedVal = val.trim()
    const isBlank = _.isEmpty(cleansedVal)
    dispatch({
      type: DISPATCH_MULTI_SEARCH,
      searchQuery: val,
      isBlank,
    })

    if (!isBlank) {
      debouncedSearchQueryResultDispatch(dispatch, val)
    }
  },
})

const appLevelStateToProps = state => ({
  isBlank: state.isBlank,
})

const appLevelDispatchToProps = dispatch => ({})

const clientResultsStateToProps = state => ({
  clients: state.clients,
  activeTab: state.activeTab,
})

export const RenderedSearchQuery = connect(searchQueryStateToProps, searchQueryDispatchToProps)(SearchQueryArea)

export const RenderedCombinedBookingSearchResultsSection = connect(
  bookingStateToProps,
  bookingDispatchToProps
)(CombinedBookingSearchResultsSection)

export const WrappedApp = connect(appLevelStateToProps, appLevelDispatchToProps)(App)

export const WrappedClientSearchResultArea = connect(clientResultsStateToProps)(ClientSearchResultsArea)
