import _ from 'lodash'
import { TransitionBlocker } from 'mgr/lib/components/TransitionBlocker'
import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { iVvyApi, seatingAreasTablesApi } from '@sevenrooms/core/api'
import type { iVvyGetFunctionSpacesApi, IVvySpaceMapping, SeatingAreaToTables } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, HStack, type BannersArray, BannersDrawer, Box } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { UnsavedChangesModal } from '@sevenrooms/mgr-marketing-promo-code/CreateEditPromoCode/UnsavedChangesModal'
import { TableMapping, type SpaceDataProps, TableMappingLocales } from '../shared/TableMapping'
import { iVvyTableMappingLocales } from './iVvyTableMapping.locales'
import { SaveAndImportModal } from './SaveAndImportModal'
import { SaveButton } from './SaveButton'

export function IVvyTableMapping() {
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()
  const {
    venueSettings: { is_ivvy_enabled: isIVvyEnabled },
  } = useVenueSettingsContext()
  const { venueId } = useVenueContext()
  const [isDisabled, setIsDisabled] = useState(true)
  const [banners, setBanners] = useState<BannersArray>([])
  const [isModalActive, setIsModalActive] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const { data: functionalSpacesData, isLoading: isFunctionalSpacesDataLoading } = iVvyApi.useGetFunctionalSpacesQuery({ venueId })
  const [isImportInProgress, setIsImportInProgress] = useState(false)
  const { data: seatingAreaTables, isLoading: isTablesDataLoading } = seatingAreasTablesApi.useGetSeatingAreasTablesQuery({ venueId })
  const [spaceMappingData, setSpaceMappingData] = useState<IVvySpaceMapping>({})

  const isLoading = useMemo(
    () => isFunctionalSpacesDataLoading || isTablesDataLoading,
    [isFunctionalSpacesDataLoading, isTablesDataLoading]
  )

  const addInProgressBanner = useCallback(() => {
    setBanners(banners => [
      ...banners,
      {
        key: 3,
        title: formatMessage(iVvyTableMappingLocales.notificationSyncInProgressTitle),
        description: formatMessage(iVvyTableMappingLocales.notificationSyncInProgressDescription),
        type: 'warning',
        action: formatMessage(TableMappingLocales.sevenRoomsTablesNoResultButton),
        canDismiss: false,
        icon: <Icon name="VMSWeb-refresh" size="2x" color="primaryIcons" animation="spin" />,
      },
    ])
  }, [formatMessage])

  const addNoTablesBanner = useCallback(() => {
    setBanners(banners => [
      ...banners,
      {
        key: 3,
        title: formatMessage(TableMappingLocales.sevenRoomsTablesNoResultButton),
        description: formatMessage(TableMappingLocales.sevenRoomsTablesNoResultDescription, {
          name: formatMessage(iVvyTableMappingLocales.name),
        }),
        type: 'warning',
        action: formatMessage(TableMappingLocales.sevenRoomsTablesNoResultButton),
        onAction: () => window.open('https://help.sevenrooms.com/hc/en-us/sections/11488530726555-Tables-Floorplans', '_blank'),
        canDismiss: false,
      },
    ])
  }, [formatMessage])

  const addSyncBanner = useCallback(
    (functionalSpacesData: iVvyGetFunctionSpacesApi, spaceMapping: IVvySpaceMapping) => {
      const spaceData =
        functionalSpacesData?.functionSpaces?.map(space => ({
          id: space.id,
          name: space.name,
          tables: spaceMapping[space.id]?.sort().slice(0) || [],
        })) || []

      const removedSpaceDataResult = Object.keys(functionalSpacesData.iVvySpaceMapping || {}).filter(
        space => !spaceData.find(i => i.id === +space)
      )

      const addedSpaceDataResult = spaceData
        .filter(space => !Object.keys(functionalSpacesData?.iVvySpaceMapping).includes(space.id.toString()))
        .map(space => space.name)
      const description = (
        <>
          {!!removedSpaceDataResult.length && (
            <>
              <Text>
                {formatMessage(iVvyTableMappingLocales.notificationSyncSuccessRemovedDescription, {
                  count: removedSpaceDataResult.length,
                  items: removedSpaceDataResult.join(', '),
                })}
              </Text>
              <br />
            </>
          )}
          {!!addedSpaceDataResult.length && (
            <Text>
              {formatMessage(iVvyTableMappingLocales.notificationSyncSuccessAddedDescription, {
                count: addedSpaceDataResult.length,
                items: addedSpaceDataResult.join(', '),
              })}
            </Text>
          )}
          {!removedSpaceDataResult.length && !addedSpaceDataResult.length && (
            <Text>{formatMessage(iVvyTableMappingLocales.notificationSyncSuccessDescription)}</Text>
          )}
        </>
      )

      setBanners(banners => [
        ...banners,
        {
          key: 4,
          title: formatMessage(iVvyTableMappingLocales.notificationSyncSuccessTitle),
          description,
          type: 'success',
        },
      ])
    },
    [formatMessage]
  )

  const addStatusBanner = useCallback(
    (status, lastSync?: string) => {
      const prefix =
        lastSync &&
        formatMessage(iVvyTableMappingLocales.notificationSyncFailedDescriptionPrefix, {
          time: lastSync,
        })
      switch (status) {
        case 403:
          setBanners(banners => [
            ...banners,
            {
              key: 1,
              title: formatMessage(iVvyTableMappingLocales.notificationSyncFailedCredentialsTitle),
              description: formatMessage(iVvyTableMappingLocales.notificationSyncFailedDescription, {
                prefix,
                a: (chunks: string[]) => <Anchor href="mailto:itsupport@sevenrooms.com">{chunks}</Anchor>,
              }),
              type: 'error',
              canDismiss: false,
            },
          ])
          break
        case 400:
        case 500:
          setBanners(banners => [
            ...banners,
            {
              key: 2,
              title: formatMessage(iVvyTableMappingLocales.notificationSyncFailedTitle),
              description: prefix,
              type: 'error',
              action: formatMessage(iVvyTableMappingLocales.tryAgain),
              onAction: () => {
                dispatch(iVvyApi.util.resetApiState())
                setBanners(banners.filter(banner => banner.key !== 2))
              },
              canDismiss: false,
            },
          ])
          break
        default:
      }
    },
    [dispatch, formatMessage]
  )

  const canImportAndSave = useMemo(() => _.isEmpty(functionalSpacesData?.iVvySpaceMapping), [functionalSpacesData?.iVvySpaceMapping])

  const spaceData: SpaceDataProps[] = useMemo(() => {
    if (!functionalSpacesData) {
      return []
    }

    const spaceMapping = _.cloneDeep(functionalSpacesData.iVvySpaceMapping || {})

    if (functionalSpacesData.status === 200 && !canImportAndSave && !functionalSpacesData.isImportInProgress) {
      addSyncBanner(functionalSpacesData, spaceMapping)
      setSpaceMappingData({
        ...functionalSpacesData?.iVvySpaceMapping,
      })
    } else if (functionalSpacesData.status !== 200) {
      addStatusBanner(functionalSpacesData.status, functionalSpacesData.lastSync)
      setIsImportInProgress(functionalSpacesData.isImportInProgress || false)

      return (
        Object.keys(functionalSpacesData.iVvySpaceMapping || {}).map(i => ({
          id: +i,
          tables: functionalSpacesData.iVvySpaceMapping[+i] || [],
        })) || []
      )
    }

    if (functionalSpacesData.isImportInProgress) {
      setIsImportInProgress(functionalSpacesData.isImportInProgress)
      addInProgressBanner()
    }

    return (
      functionalSpacesData.functionSpaces?.map(space => ({
        id: space.id,
        name: space.name,
        tables: spaceMapping[space.id]?.sort().slice(0) || [],
      })) || []
    )
  }, [addInProgressBanner, addStatusBanner, addSyncBanner, canImportAndSave, functionalSpacesData])

  const tablesData = useMemo(() => {
    const seatingAreaCodesToTables = seatingAreaTables?.seatingAreaCodesToTables
      .filter((areaCode: SeatingAreaToTables) => areaCode.tables.length && areaCode.name)
      .map((areaCode: SeatingAreaToTables) => ({
        id: areaCode.id,
        value: areaCode.name,
        label: areaCode.name,
        section: true,
        children: areaCode.tables.map(table => ({ id: table.id, value: table.itemCode, label: table.itemCode })),
      }))

    if (seatingAreaCodesToTables && seatingAreaCodesToTables.length === 0) {
      addNoTablesBanner()
    }
    return seatingAreaCodesToTables
  }, [addNoTablesBanner, seatingAreaTables?.seatingAreaCodesToTables])

  const onChange = (currentNode: TreeNode, selectedNodes: TreeNode[], spaceId: number) => {
    const tables: string[] = selectedNodes.map(i => i.value) || []
    spaceMappingData[spaceId] = tables.sort()
    const initialData = functionalSpacesData?.functionSpaces?.map(({ id }) => [id, []]) || []

    setSpaceMappingData({
      ...Object.fromEntries(initialData),
      ...functionalSpacesData?.iVvySpaceMapping,
      ...spaceMappingData,
    })

    setIsDisabled(
      !Object.entries(spaceMappingData).some(data => !_.isEqual(data[1], functionalSpacesData?.iVvySpaceMapping[+data[0]] || []))
    )
  }

  const onSubmitSync = useCallback(() => {
    setIsImport(false)
    setIsModalActive(true)
  }, [])

  return (
    <>
      <SettingsPageMeta title={formatMessage(iVvyTableMappingLocales.title)} />
      <SettingsPageContent
        title={formatMessage(iVvyTableMappingLocales.title)}
        description={formatMessage(iVvyTableMappingLocales.description, {
          a: (chunks: string[]) => <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/11463360889115">{chunks}</Anchor>,
        })}
        actions={
          <HStack spacing="s" ml="s">
            {!isLoading && (
              <>
                <Button variant="secondary" onClick={onSubmitSync} data-test="button-sync-changes" disabled={isImportInProgress}>
                  {formatMessage(iVvyTableMappingLocales.syncChanges)}
                </Button>
                <SaveButton
                  canImportAndSave={canImportAndSave}
                  isDisabled={isDisabled}
                  spaceMappingData={spaceMappingData}
                  setIsModalActive={setIsModalActive}
                  setIsImport={setIsImport}
                  isImport={isImport}
                  setIsDisabled={setIsDisabled}
                />
              </>
            )}
          </HStack>
        }
      >
        <SaveAndImportModal
          isActive={isModalActive}
          isImport={isImport}
          setIsActive={setIsModalActive}
          spaceMappingData={spaceMappingData}
          setIsDisabled={() => {
            if (functionalSpacesData) {
              setIsImportInProgress(true)
              setIsDisabled(true)
              addInProgressBanner()
            }
          }}
        />
        <Box p="m" data-test="iVvy-table-main-box">
          {banners?.length !== 0 && (
            <Box pb="m">
              <BaseSection>
                <BannersDrawer banners={banners} setBanners={setBanners} />
              </BaseSection>
            </Box>
          )}

          {isIVvyEnabled && (
            <TableMapping
              spaceData={spaceData}
              tablesData={tablesData}
              integrationName={formatMessage(iVvyTableMappingLocales.name)}
              onChange={onChange}
              isLoading={isLoading}
              isDisabled={isImportInProgress || false}
            />
          )}
        </Box>
      </SettingsPageContent>
      <TransitionBlocker modal={<UnsavedChangesModal />} isBlocked={!isDisabled} />
    </>
  )
}
