import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeBorderRadius } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'

const STAT_PADDING_X = 'm'
const FOOTNOTE_PLACEHOLDER = '<footnote_idx>'
const FOOTNOTE_SPLITTER = RegExp(`(?=${FOOTNOTE_PLACEHOLDER})|(?<=${FOOTNOTE_PLACEHOLDER})`, 'g')
export function NumericalStat({
  stat: { number, formatType, description, currency, footnotes, decimalPlaces },
  startIdx,
  borderRadius,
}: NumericalStatProps) {
  const { formatNumber } = useLocales()
  let footnoteIdx = 0

  let formattedNo
  switch (formatType) {
    case 'currency':
      formattedNo = formatNumber(number, {
        currencySign: 'standard',
        currencyDisplay: 'symbol',
        style: 'currency',
        currency,
        maximumFractionDigits: decimalPlaces ?? 2,
      })
      break
    case 'percent':
      formattedNo = formatNumber(number, { style: 'percent' })
      break
    case 'multiplier':
      formattedNo = `${formatNumber(number)}x`
      break
    default:
      formattedNo = formatNumber(number)
  }
  const descriptionParts = footnotes ? description.split(FOOTNOTE_SPLITTER, footnotes.length * 2 + 1) : [description]

  return (
    <VStack
      backgroundColor="secondaryBackground"
      borderRadius={borderRadius}
      key={formattedNo}
      pt={STAT_PADDING_X}
      pb={STAT_PADDING_X}
      flex={1}
      alignItems="center"
    >
      <Text textStyle="body1Bold">{formattedNo}</Text>
      <Text textStyle="body1Bold" textAlign="center">
        {descriptionParts.map(part => {
          if (part === FOOTNOTE_PLACEHOLDER) {
            footnoteIdx += 1
            return (
              <sup>
                <Text color="secondaryFont" fontSize="xs">
                  {footnoteIdx + startIdx}
                </Text>
              </sup>
            )
          }
          return <>{part}</>
        })}
      </Text>
    </VStack>
  )
}

export interface NumericalStatData {
  number: number
  formatType: 'percent' | 'ratio' | 'currency' | 'multiplier'
  currency?: string
  description: string
  footnotes?: string[]
  decimalPlaces?: number
}

interface NumericalStatProps {
  stat: NumericalStatData
  startIdx: number
  borderRadius: ThemeBorderRadius
}
