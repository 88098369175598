import React from 'react'
import { AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import { Tier, TierTitle, TierContent } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier from './BaseTier'

export class MarketingTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getContent() {
    const type = this.props.tier.tier_type
    switch (type) {
      case AutotagTierType.ALL_GUESTS:
        return <div>Tag guests who haven't unsubscribed from receiving venue marketing emails.</div>
      case AutotagTierType.SUBSCRIBED_GUESTS:
        return <div>Tag guests who have opted to receive venue marketing emails.</div>
      case AutotagTierType.ALL_GUESTS_GLOBAL:
        return <div>Tag guests who haven't unsubscribed from receiving group marketing emails.</div>
      case AutotagTierType.MARKETING_OPT_INS_GLOBAL:
        return <div>Tag guests who have opted to receive group marketing emails.</div>
      default:
        return null
    }
  }

  render() {
    let name = this.props.tier.name_display
    const { isActive, id } = this.props
    if (this.props.tier.tier_type === AutotagTierType.ALL_GUESTS) {
      name += ' (Recommended)'
    }

    return (
      <div id={id}>
        <Tier isActive={isActive}>
          <TierTitle>{name}</TierTitle>
          <TierContent>{this.getContent()}</TierContent>
          {this.fetchAdditionalOptions()}
        </Tier>
      </div>
    )
  }
}

export default MarketingTier
