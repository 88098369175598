import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { AccountTypes } from '@sevenrooms/payments'
import { IntegrationNoSelfServePage } from '../../IntegrationNoSelfServe/IntegrationNoSelfServePage'
import { type PaymentIntegration, usePaymentIntegration, SelfServePaymentProviders } from '../../Integrations'
import { ViewPaymentIntegration } from '../../PreviewIntegration/ViewPaymentIntegration'
import { PaymentIntegrations } from '../PaymentIntegrations'

function isNotConfigured(integration: PaymentIntegration) {
  const currentIntegrationName = integration?.integrationName
  return (
    !currentIntegrationName ||
    currentIntegrationName === AccountTypes.PLACEHOLDER ||
    (currentIntegrationName === AccountTypes.STRIPE && !integration?.currentConfiguration?.accountId)
  )
}

export function PaymentIntegrationsPage() {
  const { venueId } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const paymentIntegration = usePaymentIntegration()
  const currentIntegration = paymentIntegration?.integration

  const paymentSystem = venueSettings?.payment_system?.toUpperCase()

  if (paymentIntegration?.isFetching) {
    return <Loader />
  }

  if (paymentSystem && !SelfServePaymentProviders.includes(paymentSystem)) {
    return <IntegrationNoSelfServePage />
  }

  if (!currentIntegration || (currentIntegration && isNotConfigured(currentIntegration))) {
    return <PaymentIntegrations venueId={venueId} />
  }

  return <ViewPaymentIntegration paymentIntegration={paymentIntegration} />
}
