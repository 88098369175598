import { useMemo } from 'react'
import type { Perk } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type PerkNameFormData = ZodSchema<typeof usePerkNameForm>
export type PerkNameFormField = Field<PerkNameFormData>

export const usePerkNameForm = () => useMemo(() => z.string().min(1), [])

export const getPerkNameFormDefaultValue = (perk?: Perk): PerkNameFormData => perk?.internalName || ''
