import onClickOutside from 'react-onclickoutside'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { TierAttributes } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const referralChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class ReferrerTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  handleClickOutside(e) {
    if (!this.props.tier.name_display && this.originalName === '') {
      this.props.removeTier()
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier

    const { isActive, id } = this.props
    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TierEditor>
              Tag clients who have referred at least
              <DropdownArrowsPicker
                {...dropProps}
                choices={referralChoices}
                value={attributes.MIN_REFERRALS}
                style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_REFERRALS, value)}
              />
              completed reservations from their unique referral link.
            </TierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag clients who have referred at least <strong>{attributes.MIN_REFERRALS}</strong> completed reservations from their unique
              referral link.
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default onClickOutside(ReferrerTier)
