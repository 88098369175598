import { defineMessages } from '@sevenrooms/core/locales'

export const TableMappingLocales = defineMessages({
  loadingTitle: {
    id: 'TableMapping.loading.title',
    defaultMessage: 'Loading {name} Spaces',
  },
  sevenRoomsTablesTitle: {
    id: 'TableMapping.sevenRoomsTables.title',
    defaultMessage: 'SevenRooms Tables',
  },
  sevenRoomsTablesPlaceholder: {
    id: 'TableMapping.sevenRoomsTables.placeholder',
    defaultMessage: 'Unassigned',
  },
  spaceId: {
    id: 'TableMapping.space.id',
    defaultMessage: '{name} Space ID',
  },
  spaceName: {
    id: 'TableMapping.space.name',
    defaultMessage: '{name} Space Name',
  },
  notificationSaveSuccess: {
    id: 'TableMapping.notification.save.success',
    defaultMessage: 'Your changes have been saved.',
  },
  sevenRoomsTablesNoResultDescription: {
    id: 'TableMapping.sevenRoomsTables.noResultsDescription',
    defaultMessage: 'Your venue must have a floorplan and tables set up before you can map tables and seating areas to {name}.',
  },
  sevenRoomsTablesNoResultButton: {
    id: 'TableMapping.sevenRoomsTables.noResultsButton',
    defaultMessage: 'How to set up tables',
  },
} as const)
