// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in ORDER-819
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface PromoCodeBannerProps {
  primary: string
  secondary: string
}

export function PromoCodeBanner({ primary, secondary }: PromoCodeBannerProps) {
  return (
    <BannerContainer justifyContent="center" alignItems="center">
      <VStack alignItems="center">
        <Text color="primaryFont" fontWeight="bold">
          {primary}
        </Text>
        <Box mt="xs">
          <Text color="secondaryFont">{secondary}</Text>
        </Box>
      </VStack>
    </BannerContainer>
  )
}

const BannerContainer = styled(HStack)`
  height: 72px;
  border: 2px solid ${props => props.theme.mediumGrey};
  border-radius: 4px;
  background-color: ${props => props.theme.color.lightGrey};
`
