import React from 'react'
import styled, { css } from 'styled-components'
import { InvalidMessage } from 'mgr/pages/single-venue/marketing/components/auto-tags/custom-auto-tags/CustomAutotagShared'
import PositiveIntInput from 'svr/component-lib/Generic/TextInputs/PositiveIntInput'

const PercentageInputWrapper = styled.div``

export const CustomAutotagPercentageNumberComponent = ({ id, value, onChange, isValid, invalidMessage }) => (
  <PercentageInputWrapper>
    <div>
      <PositiveIntInput
        inputId={id}
        value={value}
        onChange={onChange}
        customInputWrapper={css`
          height: 35px;
          width: 49px;
          display: inline-block;
          margin-right: 6px;
        `}
        customInput={css`
          height: 35px;
          box-sizing: border-box;
          border-width: 1px;
          border-color: #dbdcde;
          outline: none;
          font-size: 14px;
        `}
        allowEmpty
      />
      %
    </div>

    {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
  </PercentageInputWrapper>
)

const TimesInputWrapper = styled.div`
  font-size: 14px;
`

export const CustomAutotagTimesNumberComponent = ({ id, value, onChange, isValid, invalidMessage }) => (
  <TimesInputWrapper>
    <div>
      <PositiveIntInput
        inputId={id}
        value={value}
        onChange={onChange}
        customInputWrapper={css`
          height: 35px;
          width: 59px;
          margin-right: 15px;
          display: inline-block;
        `}
        customInput={css`
          height: 35px;
          box-sizing: border-box;
          border-width: 1px;
          border-color: #dbdcde;
          outline: none;
          font-size: 14px;
        `}
        allowEmpty
      />
      time(s)
    </div>

    {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
  </TimesInputWrapper>
)
