import { parsePhoneNumber } from 'libphonenumber-js'
import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { oneTimeSMSCenterMessages } from '../../locales'

export function useSendTestSMSForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z.object({
        phoneNumbers: z
          .array(
            /* This is a nuisance, but ReactHookForm doesn't allow non-object arrays with useFieldArray */
            z.object({
              number: z.string().transform((smsPhoneNumber: string, ctx: z.RefinementCtx): string => {
                if (!smsPhoneNumber) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(oneTimeSMSCenterMessages.sendTestModalErrorInvalidNumber),
                  })
                  return smsPhoneNumber
                }
                const pn = parsePhoneNumber(smsPhoneNumber)
                if (!pn.isValid()) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: formatMessage(oneTimeSMSCenterMessages.sendTestModalErrorInvalidNumber),
                  })
                  return smsPhoneNumber
                }
                return pn.format('E.164')
              }),
            })
          )
          .min(1),
      }),
    [formatMessage]
  )
}

export type SendTestSMSSchema = ZodSchema<typeof useSendTestSMSForm>
export const SendTestSMSDefaultValues: SendTestSMSSchema = {
  phoneNumbers: [{ number: '' }],
}
