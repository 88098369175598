import { type PropsWithChildren, useCallback, useState } from 'react'
import { type LanguageListData, useGetVenueLanguagesQuery, type LanguageCode } from '@sevenrooms/core/api'
import type { ReservationWidgetSettingsV2, VenueLanguage } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Checkbox, RadioGroupToggle, RadioGroup, FormInput, FormNumberInput, TextArea, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack, BaseSection, Window } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { type NavigationPermissions, useNavigationPermissionsContext, useVenueContext } from '@sevenrooms/mgr-core'
import { OtherLanguagesModal } from '../components'
import { EditOtherLanguagesRow } from '../General/General'
import { checkoutConfirmationMessages } from './CheckoutConfirmation.locales'
import { CheckoutLanguagesTable } from './CheckoutLanguagesTable'
import { UpgradesBanner } from './UpgradesBanner'
import type { CheckoutConfirmationForm, GeneralForm } from '../ReservationSettings.zod'

export interface CheckoutConfirmationProps {
  field: Field<CheckoutConfirmationForm>
  languageStringsField: Field<GeneralForm['languageStrings']>
  data: ReservationWidgetSettingsV2
  languageOptions: SelectOption<LanguageCode>[]
  defaultLanguage: VenueLanguage
}

export function CheckoutConfirmation({ field, languageStringsField, data, languageOptions, defaultLanguage }: CheckoutConfirmationProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [otherLanguagesModalOpened, setOtherLanguagesModalOpened] = useState(false)
  const { data: languageListData } = useGetVenueLanguagesQuery({
    venueId,
  })
  const {
    defaultLanguage: { value: defaultLanguageValue },
  } = languageListData as LanguageListData
  const customCheckoutPolicyOn = useWatch(field.prop('customCheckoutPolicyOn'))
  const salutationTypeOn = useWatch(field.prop('salutationTypeOn'))
  const aboveAgeConsentOn = useWatch(field.prop('aboveAgeConsentOn'))
  const venueSmsMarketingOn = useWatch(field.prop('venueSmsMarketingOn'))
  const venueSpecificMarketingOn = useWatch(field.prop('venueSpecificMarketingOn'))
  const venueGroupMarketingOn = useWatch(field.prop('venueGroupMarketingOn'))
  const tailoredCommunicationOn = useWatch(field.prop('tailoredCommunicationOn'))
  const displayReservationSmsOptIn = useWatch(field.prop('displayReservationSmsOptIn'))
  const enableDietaryRestrictions = useWatch(field.prop('enableDietaryRestrictions'))
  const birthdayTypeOn = useWatch(field.prop('birthdayTypeOn'))
  const postalCodeTypeOn = useWatch(field.prop('postalCodeTypeOn'))
  const groupBookingsPolicyOn = useWatch(field.prop('groupBookingsPolicyOn'))
  const enableCustomQuestion = useWatch(field.prop('enableCustomQuestion'))
  const enableCustomQuestionConfirmation = useWatch(field.prop('enableCustomQuestionConfirmation'))
  const permissions: undefined | NavigationPermissions = useNavigationPermissionsContext()

  const { venue } = useVenueContext()
  const showGiftCardRedemption = data.checkoutConfirmation.redemptionSystemsEnabled?.includes('VALUTEC')
  const newResWidgetPrivateEventsEnabled = !!data.general.newResWidgetPrivateEventsEnabled

  const languageStrings = useWatch(languageStringsField)
  const [updatedLanguageStrings, setUpdatedLanguageStrings] = useState(languageStrings)

  const onLanguagesModalSaveClick = useCallback(() => {
    languageStringsField.set(updatedLanguageStrings)
    setOtherLanguagesModalOpened(false)
  }, [languageStringsField, updatedLanguageStrings])

  const onLanguagesModalCloseClick = useCallback(() => {
    setOtherLanguagesModalOpened(false)
  }, [])

  return (
    <>
      <VStack spacing="lm" mt="lm">
        <BaseSection title={formatMessage(checkoutConfirmationMessages.extrasLabel)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            <Checkbox
              field={field.prop('enablePromoCodes')}
              data-test="enablePromoCodes"
              description={formatMessage(checkoutConfirmationMessages.promoCodesDescription, {
                link: (
                  <Link isExternal to={`/manager2/${venue.urlKey}/marketing/promo-codes/`} target="_self">
                    {formatMessage(checkoutConfirmationMessages.promoCodesName)}
                    &nbsp;
                    <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Link>
                ),
              })}
            >
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.promoCodes)}</Text>
            </Checkbox>
            {showGiftCardRedemption && (
              <Checkbox field={field.prop('enableGiftCardRedemption')} data-test="enableGiftCardRedemption">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableGiftCardRedemption)}</Text>
              </Checkbox>
            )}
            <Checkbox field={field.prop('enableSocialMediaLogin')} data-test="enableSocialMediaLogin">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableSocialMediaLogin)}</Text>
            </Checkbox>
          </VStack>
        </BaseSection>
        {permissions?.canViewMarketing && (
          <BaseSection>
            <UpgradesBanner />
          </BaseSection>
        )}
        <BaseSection title={formatMessage(checkoutConfirmationMessages.optInsAndLegalese)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            {newResWidgetPrivateEventsEnabled && (
              <Box>
                <Checkbox field={field.prop('groupBookingsPolicyOn')} data-test="groupBookingsPolicyOn">
                  <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.groupBookingPolicyOn)}</Text>
                </Checkbox>
                {groupBookingsPolicyOn && (
                  <Box pt="s" pl="lm">
                    <RadioGroupToggle
                      field={field.prop('isGroupBookingsPolicyDefaultOn')}
                      data-test="isGroupBookingsPolicyDefaultOn"
                      choices={[
                        { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                        { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                      ]}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box>
              <Checkbox field={field.prop('customCheckoutPolicyOn')} data-test="customCheckoutPolicyOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.customCheckoutPolicyOn)}</Text>
              </Checkbox>
              {customCheckoutPolicyOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isDefaultCustomCheckoutPolicyOn')}
                    data-test="isDefaultCustomCheckoutPolicyOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Checkbox field={field.prop('aboveAgeConsentOn')} data-test="aboveAgeConsentOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.mandatedAgeConsent)}</Text>
              </Checkbox>
              {aboveAgeConsentOn && (
                <Box pt="s" pl="l" width="83px">
                  <FormNumberInput
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    field={field.prop('ageToConsent')}
                    greaterThanZero
                    required
                    data-test="ageToConsent"
                  />
                </Box>
              )}
            </Box>
            {data.checkoutConfirmation.isSmsMarketingEnabled && (
              <Box>
                <Checkbox field={field.prop('venueSmsMarketingOn')} data-test="venueSmsMarketingOn">
                  <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueSmsMarketingOn)}</Text>
                </Checkbox>
                {venueSmsMarketingOn && (
                  <Box pt="s" pl="lm">
                    <RadioGroupToggle
                      field={field.prop('isVenueSmsMarketingPolicyDefaultOn')}
                      data-test="isVenueSmsMarketingPolicyDefaultOn"
                      choices={[
                        { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                        { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                      ]}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box>
              <Checkbox field={field.prop('venueSpecificMarketingOn')} data-test="venueSpecificMarketingOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueSpecificMarketingOn)}</Text>
              </Checkbox>
              {venueSpecificMarketingOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isVenueSpecificMarketingPolicyDefaultOn')}
                    data-test="isVenueSmsMarketingPolicyDefaultOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>

            <Box>
              <Checkbox field={field.prop('venueGroupMarketingOn')} data-test="venueGroupMarketingOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueGroupMarketingOn)}</Text>
              </Checkbox>
              {venueGroupMarketingOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isVenueGroupMarketingPolicyDefaultOn')}
                    data-test="isVenueGroupMarketingPolicyDefaultOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>

            <Box>
              <Checkbox
                field={field.prop('tailoredCommunicationOn')}
                data-test="tailoredCommunicationOn"
                disabled={data.checkoutConfirmation.isTailoredCommunicationPolicyDisabled}
                onClick={event => {
                  if (event.currentTarget.checked) {
                    field.prop('isTailoredCommunicationPolicyDefaultOn').set(true)
                  }
                }}
              >
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.tailoredCommunicationOn)}</Text>
              </Checkbox>
              {tailoredCommunicationOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isTailoredCommunicationPolicyDefaultOn')}
                    data-test="isTailoredCommunicationPolicyDefaultOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>

            {(data.checkoutConfirmation.remindersSmsEnabled || data.checkoutConfirmation.smsBookingNotificationEnabled) &&
              data.checkoutConfirmation.showSmsOptInSettingsForTwilioUs && (
                <Box>
                  <Checkbox field={field.prop('displayReservationSmsOptIn')} data-test="displayReservationSmsOptIn" disabled>
                    <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.displayReservationSmsOptIn)}</Text>
                  </Checkbox>
                  {displayReservationSmsOptIn && (
                    <Box pt="s" pl="lm">
                      <RadioGroupToggle
                        field={field.prop('reservationSmsOptInOn')}
                        data-test="reservationSmsOptInOn"
                        choices={[
                          { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                          { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                        ]}
                      />
                    </Box>
                  )}
                </Box>
              )}

            <Checkbox field={field.prop('recaptchaOn')} data-test="recaptchaOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.recaptchaOn)}</Text>
            </Checkbox>

            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(checkoutConfirmationMessages.privacyPolicyNotification)}
              </Text>
              <SecondaryText>
                {formatMessage(checkoutConfirmationMessages.privacyPolicyNotificationDescr, {
                  link: (
                    <Link isExternal to={`/manager2/${venue.urlKey}/settings/widget_settings/`} target="_self">
                      {formatMessage(checkoutConfirmationMessages.privacyPolicyNotificationLink)}
                      &nbsp;
                      <Icon name="VMSWeb-open-in-new" size="sm" />
                    </Link>
                  ),
                })}
              </SecondaryText>
            </VStack>

            <VStack spacing="lm">
              <VStack spacing="xs">
                <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customPrivacyPolicyLink)}</Text>
                <FormInput field={field.prop('textCustomPrivacyPolicyLink')} type="text" fullWidth />
              </VStack>

              <VStack spacing="xs">
                <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customPrivacyPolicyHolderName)}</Text>
                <FormInput field={field.prop('textCustomPolicyHolderName')} type="text" fullWidth />
              </VStack>

              <VStack spacing="xs">
                <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customGDPRPolicyLink)}</Text>
                <FormInput field={field.prop('textCustomGdprPolicyLink')} type="text" fullWidth />
              </VStack>
            </VStack>
          </VStack>
        </BaseSection>
        <BaseSection title={formatMessage(checkoutConfirmationMessages.clientInformation)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            <Box>
              <Checkbox field={field.prop('salutationTypeOn')} data-test="salutationTypeOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.salutationType)}</Text>
              </Checkbox>
              {salutationTypeOn && (
                <Box pt="s" pl="lm">
                  <RadioGroup
                    field={field.prop('salutationType')}
                    data-test="salutationType"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                      { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                    ]}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Checkbox field={field.prop('birthdayTypeOn')} data-test="birthdayTypeOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.birthdayType)}</Text>
              </Checkbox>
              {birthdayTypeOn && (
                <Box pt="s" pl="lm">
                  <RadioGroup
                    field={field.prop('birthdayType')}
                    data-test="birthdayType"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                      { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                    ]}
                  />
                </Box>
              )}
            </Box>

            <Box>
              <Checkbox field={field.prop('postalCodeTypeOn')} data-test="birthdayTypeOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.postalCode)}</Text>
              </Checkbox>
              {postalCodeTypeOn && (
                <Box pt="s" pl="lm">
                  <RadioGroup
                    field={field.prop('postalCodeType')}
                    data-test="postalCodeType"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                      { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                    ]}
                  />
                </Box>
              )}
            </Box>
          </VStack>
        </BaseSection>
        <BaseSection title={formatMessage(checkoutConfirmationMessages.guestPreferences)} padding="xl">
          <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
            <AdditionalDetails
              title={formatMessage(checkoutConfirmationMessages.enableDietaryRestrictions)}
              description={formatMessage(checkoutConfirmationMessages.enableDietaryRestrictionsDescription, {
                clientTagsLink: (
                  <Link data-test="res-widget-client-tags-link" isExternal to={`/manager/${venue.urlKey}/manage/tags`}>
                    {formatMessage(checkoutConfirmationMessages.clientTags)}
                    &nbsp;
                    <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Link>
                ),
                resTagsLink: (
                  <Link data-test="res-widget-reservation-tags-link" isExternal to={`/manager/${venue.urlKey}/manage/reservationtags`}>
                    {formatMessage(checkoutConfirmationMessages.reservationTags)}
                    &nbsp;
                    <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Link>
                ),
              })}
            >
              <VStack>
                <Checkbox field={field.prop('enableDietaryRestrictions')} data-test="enableDietaryRestrictions">
                  {formatMessage(checkoutConfirmationMessages.showOnCheckoutLabel)}
                </Checkbox>
                {enableDietaryRestrictions && (
                  <Box pt="s" pl="lm">
                    <Checkbox field={field.prop('requireDietaryTagGdprOptIn')} data-test="requireDietaryTagGdprOptIn">
                      {formatMessage(checkoutConfirmationMessages.requireDietaryTagGdprOptIn)}
                    </Checkbox>
                  </Box>
                )}
              </VStack>
              <Checkbox field={field.prop('enableDietaryRestrictionsConfirmation')} data-test="enableDietaryRestrictionsConfirmation">
                {formatMessage(checkoutConfirmationMessages.showOnConfirmationLabel)}
              </Checkbox>
            </AdditionalDetails>
            <AdditionalDetails
              title={formatMessage(checkoutConfirmationMessages.enableSpecialOccasions)}
              description={formatMessage(checkoutConfirmationMessages.enableSpecialOccasionsDescription, {
                link: (
                  <Link data-test="res-widget-reservation-tags-link" isExternal to={`/manager/${venue.urlKey}/manage/reservationtags`}>
                    {formatMessage(checkoutConfirmationMessages.reservationTags)}
                    &nbsp;
                    <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Link>
                ),
              })}
            >
              <Checkbox field={field.prop('enableSpecialOccasions')} data-test="enableSpecialOccasions">
                {formatMessage(checkoutConfirmationMessages.showOnCheckoutLabel)}
              </Checkbox>
              <Checkbox field={field.prop('enableSpecialOccasionsConfirmation')} data-test="enableSpecialOccasionsConfirmation">
                {formatMessage(checkoutConfirmationMessages.showOnConfirmationLabel)}
              </Checkbox>
            </AdditionalDetails>
            <AdditionalDetails
              title={formatMessage(checkoutConfirmationMessages.enableCustomQuestionLabel)}
              description={formatMessage(checkoutConfirmationMessages.enableCustomQuestionDescription)}
            >
              <Checkbox field={field.prop('enableCustomQuestion')} data-test="enableCustomQuestionConfirmation">
                {formatMessage(checkoutConfirmationMessages.showOnCheckoutLabel)}
              </Checkbox>
              <Checkbox field={field.prop('enableCustomQuestionConfirmation')} data-test="enableCustomQuestionConfirmation">
                {formatMessage(checkoutConfirmationMessages.showOnConfirmationLabel)}
              </Checkbox>
              {(enableCustomQuestionConfirmation || enableCustomQuestion) && (
                <VStack spacing="xs" mt="sm">
                  <Label primary={<Text textStyle="body1">{formatMessage(checkoutConfirmationMessages.editCustomQuestionLabel)}</Text>}>
                    <TextArea
                      field={languageStringsField.prop(`${defaultLanguageValue}.resConfirmPageOtherInfoLabel`)}
                      data-test="sr-res-widget-custom-question-label"
                      fullWidth
                    />
                  </Label>
                  <EditOtherLanguagesRow
                    data-test="sr-res-widget-edit-other-languages"
                    field={languageStringsField.prop(`${defaultLanguageValue}.resConfirmPageOtherInfoLabel`)}
                    onClick={() => setOtherLanguagesModalOpened(true)}
                  />
                </VStack>
              )}
            </AdditionalDetails>
            <AdditionalDetails
              title={formatMessage(checkoutConfirmationMessages.enableProfilePhotoLabel)}
              description={formatMessage(checkoutConfirmationMessages.enableProfilePhotoDescription)}
            >
              <Checkbox field={field.prop('enableProfilePhotoConfirmation')} data-test="enableProfilePhotoConfirmation">
                {formatMessage(checkoutConfirmationMessages.showOnConfirmationLabel)}
              </Checkbox>
            </AdditionalDetails>
          </VStack>
        </BaseSection>
      </VStack>
      <Window active={otherLanguagesModalOpened}>
        <OtherLanguagesModal onClose={onLanguagesModalCloseClick} onSave={onLanguagesModalSaveClick}>
          <CheckoutLanguagesTable
            languageStrings={updatedLanguageStrings}
            onChange={setUpdatedLanguageStrings}
            languageOptions={languageOptions}
            defaultLanguage={defaultLanguage}
          />
        </OtherLanguagesModal>
      </Window>
    </>
  )
}

function AdditionalDetails({ title, description, children }: PropsWithChildren<{ title: string; description: string }>) {
  return (
    <VStack spacing="m">
      <VStack spacing="xs">
        <Text fontSize="m" fontWeight="bold">
          {title}
        </Text>
        <SecondaryText>{description}</SecondaryText>
      </VStack>
      <VStack spacing="xs">{children}</VStack>
    </VStack>
  )
}
