import { defineMessages } from '@sevenrooms/core/locales'

export const accessRulesMessages = defineMessages({
  // Edit mode text
  titlePlaceholder: {
    id: 'ar.edit.titlePlaceholder',
    defaultMessage: "e.g. Chef's Tasting Dinner",
  },
  nameLabel: {
    id: 'ar.edit.nameLabel',
    defaultMessage: 'Access Rule Name',
  },
  defaultSettingsButton: {
    id: 'ar.edit.defaultSettingsButton',
    defaultMessage: 'Restore Default Settings',
  },
  defaultSettingsModalTitle: {
    id: 'ar.edit.defaultSettingsModalTitle',
    defaultMessage: 'Are you sure you want to clear your edits and restore Default Settings?',
  },
  defaultSettingsModalSubtext: {
    id: 'ar.edit.defaultSettingsModalSubtext',
    defaultMessage:
      'Below are the settings for the Shifts that this Access Rule is applied to. If you choose to clear the edits you have made to this Access Rule, these are the settings that the Access Rule will follow.',
  },
  followShiftDefaultSettingsModalSubtext: {
    id: 'ar.edit.defaultSettingsModalSubtext',
    defaultMessage:
      'Below are the settings for the Shifts that this Access Rule is applied to. If you chose <strong>not to follow</strong> Shift Settings, these will be overriden by the Access Rule settings.',
  },
  defaultSettingsYes: {
    id: 'ar.edit.defaultSettingsYes',
    defaultMessage: 'Yes, restore default settings',
  },
  defaultSettingsRestored: {
    id: 'ar.edit.defaultSettingsRestored',
    defaultMessage: 'Default settings restored',
  },
  defaultSettingsReportTitle: {
    id: 'ar.edit.defaultSettingsReportTitle',
    defaultMessage: 'Default Settings',
  },
  defaultSettingsTooltip: {
    id: 'ar.edit.defaultSettingsTooltip',
    defaultMessage:
      'Below are the settings this access rule will follow if you do not make any changes to this section. In some cases, Default Settings are derived from one or more shifts the access rule is applied to.',
  },
  defaultSettingsLink: {
    id: 'ar.edit.defaultSettingsLink',
    defaultMessage: 'More about Default Settings',
  },
  scheduleHeader: {
    id: 'ar.edit.scheduleHeader',
    defaultMessage: 'Schedule',
  },
  scheduleDescription: {
    id: 'ar.edit.scheduleDescription',
    defaultMessage: 'Define which dates and times online reservations should be available through this Access Rule.',
  },
  basicSettingsHeader: {
    id: 'ar.edit.basicSettingsHeader',
    defaultMessage: 'Basic Settings',
  },
  basicSettingsDescription: {
    id: 'ar.edit.basicSettingsDescription',
    defaultMessage:
      'Use these settings to target or limit availability to online reservations for this Access Rule. Some settings will follow your Shift settings if you choose not to make edits.',
  },
  additionalSettingsHeader: {
    id: 'ar.edit.additionalSettingsHeader',
    defaultMessage: 'Additional Settings',
  },
  additionalSettingsDescription: {
    id: 'ar.edit.additionalSettingsDescription',
    defaultMessage:
      'Use these settings to target or limit availability to online reservations for this Access Rule. Some settings will follow your Shift settings if you choose not to make edits.',
  },
  paymentNotConfigured: {
    id: 'ar.edit.paymentNotConfigured',
    defaultMessage: 'Get Paid Through Your Reservation & Events Widget',
  },
  paymentNotConfiguredDescription: {
    id: 'ar.edit.paymentNotConfiguredDescription',
    defaultMessage: 'Connect a processor in minutes to enable online payments.',
  },
  removeSuccess: {
    id: 'ar.edit.removeSuccess',
    defaultMessage: '{name} was succesfully deleted',
  },
  removeFailed: {
    id: 'ar.edit.removeFailed',
    defaultMessage: 'Failed to delete {name}, please try again.',
  },
  saveSuccess: {
    id: 'ar.edit.saveSuccess',
    defaultMessage: '{name} was successfully saved',
  },
  saveFailed: {
    id: 'ar.edit.saveFailed',
    defaultMessage: 'Failed to save {name}, please try again.',
  },
  shift: {
    id: 'ar.edit.shift',
    defaultMessage: 'Shift',
  },
  clone: {
    id: 'ar.edit.clone',
    defaultMessage: 'Clone',
  },
  override: {
    id: 'ar.edit.override',
    defineMessage: 'Override',
  },
  cloneRuleName: {
    id: 'ar.edit.cloneRuleName',
    defaultMessage: 'Copy of {name}',
  },
  decisionModeTitle: {
    id: 'ar.decision.title',
    defaultMessage: `{isEdit, select,
      true {
        Which days would you like to edit {name}?
      }
      other {
        Which days would you like to delete {name}?
      }
    }`,
  },
  decisionModeAllLabel: {
    id: 'ar.decision.all.label',
    defaultMessage: `{isEdit, select,
      true {
        Edit all days
      }
      other {
        Delete all days
      }
    }`,
  },
  decisionModeAllDescription: {
    id: 'ar.decision.all.description',
    defaultMessage: `{isEdit, select,
      true {
        All present and future days in {name} will be edited. Single day overrides will remain the same.
      }
      other {
        All present and future days in {name} will be deleted. Single day overrides will remain the same.
      }
    }`,
  },
  decisionModeFollowingLabel: {
    id: 'ar.decision.following.label',
    defaultMessage: `{isEdit, select,
      true {
        Edit from a specific date moving forward
      }
      other {
        Delete from a specific date moving forward
      }
    }`,
  },
  decisionModeFollowingDescription: {
    id: 'ar.decision.following.description',
    defaultMessage: `{isEdit, select,
      true {
        The specific date and future days in {name} will be edited. Single day overrides will remain the same.
      }
      other {
        The specific date and future days in {name} will be deleted. Single day overrides will remain the same.
      }
    }`,
  },
  decisionModeOverrideLabel: {
    id: 'ar.decision.override.label',
    defaultMessage: `{isEdit, select,
      true {
        Override a specific date only
      }
      other {
        Delete a specific date only
      }
    }`,
  },
  decisionModeOverrideDescription: {
    id: 'ar.decision.override.description',
    defaultMessage: 'All other days in {name} will remain unchanged.',
  },
  deleteConfirmationTitle: {
    id: 'ar.delete.confirmation.title',
    defaultMessage: `{decision, select,
      all {
        Are you sure you want to delete {name} for all days?
      }
      following {
        Are you sure you want to delete {name} for {formattedDate} and all future days?
      }
      other {
        Are you sure you want to delete {name} for {formattedDate}?
      }
    }`,
  },
  deleteConfirmationBody: {
    id: 'ar.delete.confirmation.body',
    defaultMessage: 'Once you delete an Access Rule, it cannot be recovered.',
  },
  defaultBookingPolicy: {
    id: 'ar.edit.defaultBookingPolicy',
    defaultMessage: 'Default Booking Policy',
  },
  defaultCancelPolicy: {
    id: 'ar.edit.defaultCancelPolicy',
    defaultMessage: 'Default Cancellation Policy',
  },
  additionalSettings: {
    id: 'ar.edit.additionalSettings',
    defaultMessage: 'Additional settings',
  },
  additionalSettingsCollapsed: {
    id: 'ar.edit.additionalSettingsCollapsed',
    defaultMessage: 'Additional settings:',
  },
  shiftCategories: {
    id: 'ar.view.shiftCategories',
    defaultMessage: 'Shift Categories',
  },
  followShift: {
    id: 'ar.view.followShift',
    defaultMessage: 'Follow Shift(s)',
  },
  audience: {
    id: 'ar.view.audience',
    defaultMessage: 'Audience',
  },
  editPhoto: {
    id: 'ar.view.editPhoto',
    defaultMessage: 'Edit photo',
  },
  newAccessRule: {
    id: 'ar.view.newAccessRule',
    defaultMessage: 'New Access Rule',
  },
  ariaTooltip: {
    id: 'ar.view.ariaTooltip',
    defaultMessage: 'Tooltip',
  },
  switchSlideout: {
    id: 'ar.view.switchSlideout',
    defaultMessage: "\u2728 You're viewing the new and improved Access Rule Editor. <a>Switch to Classic Editor.</a>",
  },
  durationWidgetRequiredTitle: {
    id: 'ar.view.durationWidgetRequiredTitle',
    defaultMessage: 'Important: Duration Widget Required',
  },
  durationWidgetRequiredBody: {
    id: 'ar.view.durationWidgetRequiredBody',
    defaultMessage:
      'This Access Rule will only surface availability if your Reservation Widget is using the special duration picker configuration. This requires guests to select a custom duration when booking a reservation online, ignoring durations set per party size on the shift.',
  },
  learnMore: {
    id: 'ar.view.learnMore',
    defaultMessage: 'Learn More',
  },
  perkLinkTitle: {
    id: 'ar.view.perkLinkTitle',
    defaultMessage: 'Linked to Perk',
  },
  perkLinkDescription: {
    id: 'ar.view.perkLinkDescription',
    defaultMessage: `This Access Rule is linked to the Perk: {name}.`,
  },
  multiplePerkLinkDescription: {
    id: 'ar.view.multiplePerkLinkDescription',
    defaultMessage: 'This Access Rule is linked to multiple Perks.',
  },
  perkLinkAction: {
    id: 'ar.view.perkLinkAction',
    defaultMessage: 'Go to Perk',
  },
  multiplePerkLinkAction: {
    id: 'ar.view.multiplePerkLinkAction',
    defaultMessage: 'Manage Perks',
  },
  preSaveChangesIntroText: {
    id: 'ar.view.preSaveChangesIntroText',
    defaultMessage: 'You are editing:',
  },
  postSaveChangesIntroText: {
    id: 'ar.view.postSaveChangesIntroText',
    defaultMessage: 'You edited:',
  },
  overrideARNameHeader: {
    id: 'ar.view.overrideARNameHeader',
    defaultMessage: 'Access Rules Name',
  },
  overrideScheduleHeader: {
    id: 'ar.view.overrideScheduleHeader',
    defaultMessage: 'Schedule',
  },
  overrideShiftCategoryHeader: {
    id: 'ar.view.overrideShiftCategoryHeader',
    defaultMessage: 'Shift Categories',
  },
  overridePartySizeHeader: {
    id: 'ar.view.overridePartySizeHeader',
    defaultMessage: 'Party Size',
  },
  overrideEditSettingsHeader: {
    id: 'ar.view.overrideEditSettingsHeader',
    defaultMessage: 'Edit Settings',
  },
  overrideDateAtTimes: {
    id: 'ar.view.overrideDateAndTimes',
    defaultMessage: '{date} at {times}',
  },
  viewActivityLog: {
    id: 'ar.viewActivityLog',
    defaultMessage: 'View Activity Log',
  },
  activityLogNoChanges: {
    id: 'ar.activityLog.noChanges',
    defaultMessage: 'No changes',
  },
  reviewChanges: {
    id: 'ar.activityLog.reviewChanges',
    defaultMessage: 'Review Changes',
  },
  specificDate: {
    id: 'ar.edit.specificDate',
    defaultMessage: 'Specific Date',
  },
} as const)
