import { type PropsWithChildren, type ReactElement, useCallback, useMemo, useState } from 'react'
import { useCreateExperienceMutation } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Box, notify } from '@sevenrooms/core/ui-kit/layout'
import { getShiftSeatingAreas } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/SeatingAreas.zod'
import { useAccessRuleContext } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { Modal } from '@sevenrooms/react-components/components/Modal'
import { routes } from '@sevenrooms/routes'
import { StepperFlow, type Steps } from '../StepperFlow'
import { Availability } from './Availability'
import { type Availabilities, formToExperience, useCreateOfferFromTemplateForm, useDefaultValues } from './CreateOfferFromTemplateForm.zod'
import { FinalPage } from './FinalPage'
import { NewAccessRuleCustomization } from './NewAccessRuleCustomization'
import { NewAccessRulePayment } from './NewAccessRulePayment'
import { NewAccessRuleScheduling } from './NewAccessRuleScheduling'
import { OfferDetails } from './OfferDetails'
import type { OfferTemplateProps } from '../MarketingTemplatesGallery'

export function OffersStepperFlow({
  templateData,
  venueKey,
  venueName,
  venueId,
}: {
  templateData: OfferTemplateProps
  venueKey: string
  venueName: string
  venueId: string
}) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueCurrencyCode } = useAppContext()
  const { allShifts, policies, seatingAreaToTables, upsells, clientTagGroups } = useAccessRuleContext()

  const createOfferFromTemplateSchema = useCreateOfferFromTemplateForm()
  const defaultValues = useDefaultValues(
    templateData,
    venueName,
    venueCurrencyCode,
    policies,
    upsells,
    clientTagGroups,
    allShifts,
    seatingAreaToTables
  )
  const shiftSeatingAreas = useMemo(() => getShiftSeatingAreas(allShifts, seatingAreaToTables), [allShifts, seatingAreaToTables])

  const [showNextButton, setShowNextButton] = useState(true)
  const [experienceFlowCompleted, setExperienceFlowCompleted] = useState(false)
  const [createdExperience, setCreatedExperience] = useState(undefined)
  const [isCreateExperienceLoading, setIsCreateExperienceLoading] = useState(false)
  const [createExperience] = useCreateExperienceMutation()

  const [selectedAvailabilityOption, setSelectedAvailabilityOption] = useState<Availabilities | undefined>(undefined)
  const [isAccessRuleCustomizationInitialPart, setIsAccessRuleCustomizationInitialPart] = useState(true)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const form = useForm(createOfferFromTemplateSchema, { defaultValues, mode: 'onChange' })

  const handleOnSubmit = useCallback(async () => {
    const { getValues } = form
    const values = getValues()
    const createExperienceData = await formToExperience({
      formData: values,
      onError: () => {
        setIsCreateExperienceLoading(false)
        notify({
          content: formatMessage(messages.experienceAPIError),
          type: 'error',
        })
      },
    })
    return createExperience({
      experience: createExperienceData,
      venueId,
    })
  }, [form, createExperience, venueId, formatMessage])

  const onCancel = () => {
    nav.push(routes.manager2.marketing.offers, { params: { venueKey } })
  }

  const onFlowSubmit = () => {
    setIsCreateExperienceLoading(true)
    handleOnSubmit().then(result => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCreatedExperience(result?.data || undefined)
      setExperienceFlowCompleted(true)
    })
  }

  const steps: Steps = {
    offerDetails: {
      title: formatMessage(messages.stepOfferDetails),
      component: <OfferDetails form={form} setShowNextButton={setShowNextButton} />,
      isValid: () => form.trigger(['name', 'description']),
    },
    availability: {
      title: formatMessage(messages.stepAvailability),
      component: (
        <Availability
          form={form}
          venueId={venueId}
          setShowNextButton={setShowNextButton}
          setFlowCompleted={setExperienceFlowCompleted}
          selectedAvailabilityOption={selectedAvailabilityOption}
          setSelectedAvailabilityOption={setSelectedAvailabilityOption}
        />
      ),
      isValid: () => true,
      handleBack: selectedAvailabilityOption ? () => setSelectedAvailabilityOption(undefined) : undefined,
      replaceHandleBack: true,
    },
    scheduling: {
      title: formatMessage(messages.stepScheduling),
      component: <NewAccessRuleScheduling form={form} allShifts={allShifts} />,
      isValid: () => form.trigger(['schedule.dateRange', 'schedule.selectedDays', 'schedule.shiftCategories']),
      handleBack: () => setSelectedAvailabilityOption(undefined),
    },
    payment: {
      title: formatMessage(messages.stepPayment),
      component: <NewAccessRulePayment form={form} />,
      isValid: () =>
        form.trigger([
          'paymentPolicy.paymentRule',
          'paymentPolicy.bookingCharge.amount',
          'paymentPolicy.partySizeMin',
          'paymentPolicy.charges.gratuityPercent',
          'paymentPolicy.charges.serviceChargePercent',
          'paymentPolicy.charges.taxId',
        ]),
    },
    customization: {
      title: formatMessage(messages.stepCustomization),
      component: (
        <NewAccessRuleCustomization
          form={form}
          shiftSeatingAreas={shiftSeatingAreas}
          setFlowCompleted={setExperienceFlowCompleted}
          isAccessRuleCustomizationInitialPart={isAccessRuleCustomizationInitialPart}
        />
      ),
      isValid: () => (isAccessRuleCustomizationInitialPart ? form.trigger(['partySize.min', 'partySize.max']) : true),
      handleBack: !isAccessRuleCustomizationInitialPart ? () => setIsAccessRuleCustomizationInitialPart(true) : undefined,
      replaceHandleBack: true,
      handleNext: isAccessRuleCustomizationInitialPart ? () => setIsAccessRuleCustomizationInitialPart(false) : undefined,
      replaceHandleNext: true,
    },
  }

  return (
    <OffersStepperFlowWrapper key="offers-stepper-flow-window">
      {experienceFlowCompleted && createdExperience ? (
        <FinalPage venueUrlKey={venueKey} experience={createdExperience} form={form} />
      ) : (
        <StepperFlow
          form={form}
          steps={steps}
          onCancel={onCancel}
          onFlowSubmit={onFlowSubmit}
          isFlowSubmitLoading={isCreateExperienceLoading}
          isFlowCompleted={experienceFlowCompleted}
          setFlowCompleted={setExperienceFlowCompleted}
          showFooterMenu={showNextButton}
        />
      )}
    </OffersStepperFlowWrapper>
  )
}

function OffersStepperFlowWrapper({ children }: { children: PropsWithChildren<ReactElement> }) {
  return (
    <Modal open sx={{ overflow: 'auto', height: '100%', width: '100%' }} data-test="offers-stepper-flow-window">
      <Box width="100vw" height="100vh" pt="m" backgroundColor="inputBackground" overflow="auto" data-test="offers-stepper-flow-window">
        {children}
      </Box>
    </Modal>
  )
}
