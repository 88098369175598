import { Box } from '@sevenrooms/react-components/components/Box'

interface ScheduleBlockProps {
  columnStart: number
  columnEnd: number
  rowStart: number
  rowEnd: number
  zIndex: number
}

export function ScheduleBlock({ columnStart, columnEnd, rowStart, rowEnd, zIndex, children }: React.PropsWithChildren<ScheduleBlockProps>) {
  return (
    <Box
      sx={{
        gridColumn: `${columnStart} col-start / ${columnEnd} col-end`,
        // +1 for css grid 1 based indexing and +4 for to skip visual buffer row
        gridRow: `${rowStart + 5} row-start / ${rowEnd + 5} row-start`,
        zIndex,
      }}
    >
      {children}
    </Box>
  )
}
