import { useState } from 'react'
import type { AccessRuleToShow } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, NotificationBanner } from '@sevenrooms/core/ui-kit/layout'
import { ExperiencesLocales } from '../../Experiences.locales'
import { AccessRuleSelector } from './AccessRuleSelector'
import { AccessRuleSelectorLocales } from './AccessRuleSelector.locales'

interface AccessRuleSelectorModalProps {
  close: () => void
  accessRules: AccessRuleToShow[]
  selectedAccessRuleIds: string[]
  setSelectedAccessRuleIds: (selectedIds: string[]) => void
}
export function AccessRuleSelectorModal({
  close,
  accessRules,
  selectedAccessRuleIds,
  setSelectedAccessRuleIds,
}: AccessRuleSelectorModalProps) {
  const { formatMessage } = useLocales()
  const [accessRuleIds, setAccessRulesIds] = useState(selectedAccessRuleIds)
  const link = () => {
    setSelectedAccessRuleIds(accessRuleIds)
    close()
  }
  return (
    <Modal ariaLabel="Modal" data-test="access-rules-modal">
      <ModalHeader onClose={() => close()}>
        <ModalTitle title={formatMessage(AccessRuleSelectorLocales.modalTitle)} />
      </ModalHeader>
      <ModalBody>
        <AccessRuleSelector accessRules={accessRules} selectedAccessRuleIds={accessRuleIds} setSelectedAccessRuleIds={setAccessRulesIds} />
        {accessRules.length === 0 && (
          <NotificationBanner
            mt="s"
            type="warning"
            iconName="VMSWeb-warning"
            showIcon
            content={formatMessage(AccessRuleSelectorLocales.allAccessRulesAreAlreadyLinked)}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <Flex flexDirection="row-reverse">
          <Button data-test="link-button" variant="primary" onClick={link}>
            {formatMessage(ExperiencesLocales.link)}
          </Button>
          <Button data-test="cancel-button" variant="tertiary" onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}
