import { type Experience, ExperienceStatusEnum } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { spacesMessages } from '../../spaces.locales'
import type { SpaceFormType } from './Space.zod'

interface ExperiencesActionButtonsProps {
  field: Field<SpaceFormType>
  disabled: boolean
  experience?: Experience
  onCancel: () => void
  onSave: (isStay: boolean) => void
}

export function ExperiencesActionButtons({ field, disabled, experience, onCancel, onSave }: ExperiencesActionButtonsProps) {
  const { formatMessage } = useLocales()

  const onSaveAndPublish = (isStay: boolean) => {
    field.prop('status').set(ExperienceStatusEnum.ACTIVE)
    onSave(isStay)
  }
  const onDeactivate = (isStay: boolean) => {
    field.prop('status').set(ExperienceStatusEnum.INACTIVE)
    onSave(isStay)
  }
  const onSaveAsDraft = (isStay: boolean) => {
    field.prop('status').set(ExperienceStatusEnum.DRAFT)
    onSave(isStay)
  }

  return (
    <HStack spacing="s">
      <Button data-test="experience-form-cancel" disabled={disabled} variant="tertiary" onClick={onCancel}>
        {formatMessage(commonMessages.cancel)}
      </Button>
      {experience?.isActive ? (
        <>
          <Button data-test="btn-deactivate" disabled={disabled} variant="secondary-warning" onClick={() => onDeactivate(false)}>
            {formatMessage(commonMessages.deactivate)}
          </Button>
          <Button data-test="btn-save-and-publish" disabled={disabled} variant="primary" onClick={() => onSaveAndPublish(true)}>
            {formatMessage(commonMessages.saveChanges)}
          </Button>
        </>
      ) : (
        <>
          {experience?.isInactive ? (
            <Button data-test="btn-save" disabled={disabled} variant="secondary" onClick={() => onDeactivate(true)}>
              {formatMessage(commonMessages.saveChanges)}
            </Button>
          ) : (
            <Button data-test="btn-save" disabled={disabled} variant="secondary" onClick={() => onSaveAsDraft(!!experience?.id)}>
              {experience?.id ? formatMessage(commonMessages.saveChanges) : formatMessage(commonMessages.saveAsDraft)}
            </Button>
          )}
          <Button data-test="btn-save-and-publish" disabled={disabled} variant="primary" onClick={() => onSaveAndPublish(false)}>
            {experience?.id ? formatMessage(commonMessages.saveAndPublish) : formatMessage(spacesMessages.publishSpace)}
          </Button>
        </>
      )}
    </HStack>
  )
}
