import { useEffect, useMemo, useState } from 'react'
import type { Shift, ShiftsByDate } from '@sevenrooms/core/domain'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { AccessRuleRange } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { MultiDayScheduleGrid } from '../MultiDayScheduleGrid/MultiDayScheduleGrid'
import { ShiftBlock } from './ShiftBlock'

interface ShiftData {
  shift: Shift
  date: DateOnly
  isLabelSpace: boolean
  isLeft: boolean
  isRight: boolean
}

function getShiftsBlocks(shiftsByDate: ShiftsByDate) {
  const shiftData = Object.entries(shiftsByDate).map(([date, shifts]) => ({ date: DateOnly.from(date), shifts }))

  const result: ShiftData[] = []
  for (const [i, { date, shifts }] of shiftData.entries()) {
    const previousDay = i > 0 ? shiftData.at(i - 1) : undefined
    const nextDay = i < shiftData.length ? shiftData.at(i + 1) : undefined

    for (const [i, shift] of shifts.entries()) {
      if (!shift.startTimeSortOrder || !shift.endTimeSortOrder) {
        continue
      }

      const previousShift = i > 0 ? shifts.at(i - 1) : undefined
      const isLabelSpace = !previousShift || shift.startTimeSortOrder - (previousShift?.endTimeSortOrder ?? 0) >= 4
      const isLeft = previousDay?.shifts.find(x => x.id === shift.id) === undefined
      const isRight = nextDay?.shifts.find(x => x.id === shift.id) === undefined

      result.push({ shift, date, isLabelSpace, isLeft, isRight })
    }
  }

  return result
}

interface AccessRulesScheduleGridProps {
  dates: DateOnly[]
  shifts?: ShiftsByDate
  onClickDayHeader: (date: DateOnly) => void
}

export function AccessRulesScheduleGrid({ dates, shifts, onClickDayHeader }: AccessRulesScheduleGridProps) {
  const { venue } = useVenueContext()
  const [{ range, date }] = useAccessRulesUrlParams()
  const focusDate = useMemo(() => (range === AccessRuleRange.DAY ? date : undefined), [date, range])

  const [displayDates, setDisplayDates] = useState<DateOnly[]>(dates)
  useEffect(() => {
    if (focusDate) {
      setDisplayDates([date])
    } else {
      setDisplayDates(dates)
    }
  }, [date, dates, focusDate, range])

  const onClick = (date: DateOnly) => {
    setDisplayDates([date])
    onClickDayHeader(date)
  }

  const getColumnIndex = (date: DateOnly) => (focusDate ? 1 : dates.findIndex(x => date.isEqualTo(x)) + 1)

  const shiftsData = shifts ? getShiftsBlocks(shifts) : []

  const displayShifts = focusDate ? shiftsData?.filter(x => x.date.isEqualTo(focusDate)) : shiftsData

  const hourSpan = Math.ceil(Math.max(...(shiftsData?.map(x => x.shift.endTimeSortOrder ?? 0) ?? [])) / 4)

  return (
    <MultiDayScheduleGrid startHour={venue.startOfDayHour} hourSpan={hourSpan} dates={displayDates} onClickDayHeader={onClick}>
      {displayShifts?.map(data => (
        <ShiftBlock
          key={`shift-${data.date.toIso()}-${data.shift.name}`}
          shift={data.shift}
          column={getColumnIndex(data.date)}
          date={data.date}
          startSortOrder={data.shift.startTimeSortOrder ?? 0}
          endSortOrder={data.shift.endTimeSortOrder ?? 0}
          isLeft={focusDate ? true : data.isLeft}
          isRight={focusDate ? true : data.isRight}
          disableLabel={!data.isLabelSpace}
        />
      ))}
    </MultiDayScheduleGrid>
  )
}
