import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Pair } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PartySizeLocales } from '../PartySize.locales'
import { MissingDurationWarningTooltip } from './MissingDurationWarningTooltip'
import type { PartySizeProps } from './PartySizeProps'

interface CollapsedPartySizeProps extends PartySizeProps {
  durationsMinPartySize?: number
  durationsMaxPartySize?: number
}

export function CollapsedPartySize({ field, durationsMinPartySize, durationsMaxPartySize }: CollapsedPartySizeProps) {
  const { formatMessage } = useLocales()
  const { min, max } = useWatch(field)

  if (!min || !max) {
    return null
  }

  return (
    <Text fontSize="m">
      <Pair
        left={formatMessage(PartySizeLocales.partySizeLabel)}
        right={
          <>
            {min} <MissingDurationWarningTooltip minPartySize={min} durationsMinPartySize={durationsMinPartySize} /> - {max}
            <MissingDurationWarningTooltip maxPartySize={max} durationsMaxPartySize={durationsMaxPartySize} />
          </>
        }
      />
    </Text>
  )
}
