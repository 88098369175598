import type { AccessRuleAudienceTier } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { marketingCommonMessages } from '@sevenrooms/marketing'
import { TimeBefore, timeBeforeForm } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { perksMessages } from '../../locales'
import { type PerkEditAudienceTierModalFormData, getPerkEditAudienceTierModalDefaultValues } from './PerkEditAudienceTierModalForm.zod'

interface PerkEditAudienceTierModalProps {
  audienceTier: AccessRuleAudienceTier
  ruleName: string
  closeHref: string
  onSubmit: (formData: PerkEditAudienceTierModalFormData) => void
  onClose?: () => void
}

export function PerkEditAudienceTierModal({ closeHref, onClose, onSubmit, audienceTier, ruleName }: PerkEditAudienceTierModalProps) {
  const { formatMessage } = useLocales()

  const defaultValues = getPerkEditAudienceTierModalDefaultValues(audienceTier)
  const form = useForm(timeBeforeForm, {
    defaultValues,
  })

  const { field, watch, getValues } = form
  const currentFormValues = watch()

  return (
    <Modal ariaLabel={formatMessage(perksMessages.earlyAccessRuleModalEdit, { ruleName })} minWidth="560px">
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <ModalHeader closeHref={closeHref} onClick={onClose}>
          <VStack spacing="s" mt="s">
            <SecondaryText fontWeight={700}>{formatMessage(perksMessages.earlyAccessRuleModalEdit, { ruleName })}</SecondaryText>
            <ModalTitle title={formatMessage(perksMessages.earlyAccessRuleModalReservationTitle)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="s" mt="s" height="320px">
            <Text>{formatMessage(perksMessages.earlyAccessRuleModalReservationLabel)}</Text>
            <TimeBefore field={field} data-test="time-before" />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button data-test="perk-edit-audience-cancel-button" variant="tertiary" href={closeHref} onClick={onClose}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              data-test="perk-edit-audience-save-button"
              variant="primary"
              href={closeHref}
              onClick={() => {
                onSubmit(getValues())
              }}
              disabled={!currentFormValues.count}
            >
              {formatMessage(marketingCommonMessages.saveChanges)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
