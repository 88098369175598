import { useMemo } from 'react'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { FollowShiftMessage } from './FollowShift'
import { getBundledUpgradesInfo, getPaymentPolicySummary } from './PaymentPolicyInfo'
import type { PaymentPolicySectionProps } from './PaymentPolicyProps'

export function CollapsedPaymentPolicy({ field, allowChannelsWithoutCCHolds }: PaymentPolicySectionProps) {
  const { formatMessage } = useLocales()
  const { policies } = useAccessRuleContext()

  const allowUnsupported = useWatch(allowChannelsWithoutCCHolds)
  const paymentPolicy = useWatch(field)

  const ccHoldSummary = allowUnsupported
    ? formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)
    : formatMessage(PaymentPolicyLocales.doNotBookOnUnsupported, {
        strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
      })

  const summary = useMemo(
    () => getPaymentPolicySummary(paymentPolicy, policies, formatMessage, true),
    [paymentPolicy, policies, formatMessage]
  )

  if (paymentPolicy.useShiftPaymentAndPolicy) {
    return (
      <VStack spacing="s">
        <Pair left={formatMessage(PaymentPolicyLocales.title)} right={<FollowShiftMessage />} />
        <Pair
          left={formatMessage(PaymentPolicyLocales.bundledUpgrades)}
          right={getBundledUpgradesInfo(formatMessage, paymentPolicy.bundledUpgrades)}
        />
        <Pair left={formatMessage(accessRulesMessages.additionalSettings)} right={ccHoldSummary} />
      </VStack>
    )
  }

  return (
    <VStack spacing="s">
      {summary.map(([key, value]) => (
        <Pair key={key} left={key} right={value} />
      ))}
      {paymentPolicy.paymentRule === 'save_for_later' && (
        <Pair left={formatMessage(accessRulesMessages.additionalSettingsCollapsed)} right={ccHoldSummary} />
      )}
    </VStack>
  )
}
