import { push } from 'connected-react-router'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { tryLoadAutotagsData } from 'mgr/pages/single-venue/marketing/actions/Autotags'
import { tryGetEmailCampaignActivity, tryGetEmailCampaignsData } from 'mgr/pages/single-venue/marketing/actions/EmailCenter'
import { tryLoadReviews } from 'mgr/pages/single-venue/marketing/actions/Reviews'
import queryString from 'query-string'

// TODO batch dispatches here
// TODO generic params util maybe
const filterChange = update => (dispatch, getState) => {
  const state = getState()
  const { filters } = state.reviews

  dispatch(
    push({
      search: queryString.stringify({
        ...filters,
        startDate: filters.startDate.format('YYYY-MM-DD'),
        endDate: filters.endDate.format('YYYY-MM-DD'),
      }),
    })
  )
}

export const onDateFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState

  dispatch({
    type: ActionTypes.DATE_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onAutoTagsDateFilterChange = filter => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DATE_FILTER_CHANGE,
    value: filter,
  })

  dispatch({
    type: ActionTypes.LOAD_AUTOTAGS_START,
  })

  const state = getState()
  const filters = { date: state.autotags.filters.date }
  const { venue } = state.appState

  dispatch(
    push({
      search: queryString.stringify(filters),
    })
  )

  return dispatch(tryLoadAutotagsData(venue))
}

export const onEmailCenterDateFilterChange = filter => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.DATE_FILTER_CHANGE,
    value: filter,
  })

  dispatch({
    type: ActionTypes.LOAD_EMAIL_CENTER_START,
  })

  const state = getState()
  const filters = { date: state.emails.filters.date }
  const { venue } = state.appState

  dispatch(
    push({
      search: queryString.stringify(filters),
    })
  )

  dispatch(tryGetEmailCampaignsData(venue))
}

export const onPlatformFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.PLATFORM_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onRatingFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.RATING_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onFeedbackTypeFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.FEEDBACK_TYPE_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onSearchFilterChange = filter => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.SEARCH_FILTER_CHANGE,
    value: filter,
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

const clearPagination = () => (dispatch, getState) => {
  const { pageSize } = getState().reviews.filters
  dispatch({
    type: ActionTypes.REVIEWS_PAGINATION_CHANGE,
    page: 0,
    pageSize,
  })
}

export const onGuestSatisfactionPageChange = (page, pageSize) => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  dispatch({
    type: ActionTypes.REVIEWS_PAGINATION_CHANGE,
    page,
    pageSize,
  })

  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onGuestSatisfactionSort = sorts => (dispatch, getState) => {
  const state = getState()
  const { venue } = state.appState
  const sort = sorts && sorts[0]

  dispatch({
    type: ActionTypes.REVIEWS_SORT_CHANGE,
    sortKey: sort.id === 'actual_name' ? 'actual_name_first_last' : sort.id,
    sortDirection: sort.desc ? 'desc' : 'asc',
  })

  dispatch(clearPagination())
  dispatch(filterChange())
  dispatch(tryLoadReviews(venue))
}

export const onCampaignIdsFilterChange =
  (filter, silent = false) =>
  (dispatch, getState) => {
    dispatch({
      type: ActionTypes.CAMPAIGN_IDS_FILTER_CHANGE,
      value: filter,
    })

    dispatch({
      type: ActionTypes.LOAD_EMAIL_CENTER_START,
    })

    const state = getState()
    const filters = { campaignIds: state.emails.filters.campaignIds }

    if (!silent) {
      dispatch(
        push({
          search: queryString.stringify(filters),
        })
      )

      dispatch(tryGetEmailCampaignActivity(state.appState.venue))
    }
  }

export const onCustomDateRangeChange = (dateType, dateVal) => dispatch => {
  dispatch({
    type: ActionTypes.CUSTOM_RANGE_CHANGE,
    dateType,
    dateVal,
  })

  dispatch(filterChange())
}
