export const PacingTestId = {
  customPacingCheckbox: 'custom-pacing-check',
  customPacingErrors: 'custom-pacing-errors',
  customPacingInput: 'custom-pacing-input',
  customPacingTooltip: 'custom-pacing-tooltip',
  defaultPacingErrors: 'default-pacing-errors',
  defaultPacingInput: 'default-pacing-input',
  holdPacingCheckbox: 'hold-pacing-checkbox',
  section: 'pacing',
  setCustomPacingCheckbox: 'set-custom-pacing-checkbox',
  conflictModal: 'pacing-conflict-modal',
  viewConflicts: 'pacing-view-conflicts',
  reservedTooltip: 'pacing-reserved-tooltip',
  reservedButton: 'pacing-reserved-button',
  report: 'pacing-report',
  excludeFromShiftPacingCheckbox: 'exclude-from-shift-pacing-checkbox',
} as const
