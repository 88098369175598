import { AccessRuleTagRestrictionEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Tag, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAccessRuleContext } from '../../shared'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import type { BookingChannelsForm } from '../BookingChannels.zod'

export function BookingChannelAudience({ tier }: { tier: BookingChannelsForm[number] }) {
  const { formatMessage } = useLocales()
  const { clientTagGroups } = useAccessRuleContext()
  const { bookClientTags, viewClientTags } = tier

  return (
    <VStack>
      <Text>
        {tier.tagRestriction === AccessRuleTagRestrictionEnum.NONE && formatMessage(BookingChannelsLocales.tierAndAudiencesNoneLabel)}
        {tier.tagRestriction === AccessRuleTagRestrictionEnum.BOOK && formatMessage(BookingChannelsLocales.tierAndAudiencesBookLabel)}
        {tier.tagRestriction === AccessRuleTagRestrictionEnum.VIEW && formatMessage(BookingChannelsLocales.tierAndAudiencesViewLabel)}
      </Text>
      {tier.tagRestriction === AccessRuleTagRestrictionEnum.BOOK && (
        <Box mt="xs" mb="xs">
          {bookClientTags.tags.map(tag => (
            <Tag key={tag.id} backgroundColor={clientTagGroups.get(tag.categoryId)?.colorHex ?? ''}>
              {tag.label}
            </Tag>
          ))}
          {bookClientTags.tags.length === 0 && bookClientTags.hasDeletedTags && (
            <>
              <Text color="warning">{formatMessage(BookingChannelsLocales.tierAndAudiencesClientTagsDeletedWarningLabel)}</Text>
              &nbsp;
              <Icon name="VMSWeb-warning" size="lg" color="warning" />
            </>
          )}
        </Box>
      )}
      {tier.tagRestriction === AccessRuleTagRestrictionEnum.VIEW && (
        <Box mt="xs" mb="xs">
          {viewClientTags.tags.map(tag => (
            <Tag key={tag.id} backgroundColor={clientTagGroups.get(tag.categoryId)?.colorHex ?? ''}>
              {tag.label}
            </Tag>
          ))}
          {viewClientTags.tags.length === 0 && viewClientTags.hasDeletedTags && (
            <>
              <Text color="warning">{formatMessage(BookingChannelsLocales.tierAndAudiencesClientTagsDeletedWarningLabel)}</Text>
              &nbsp;
              <Icon name="VMSWeb-warning" size="lg" color="warning" />
            </>
          )}
        </Box>
      )}
    </VStack>
  )
}
