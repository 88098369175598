import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import { withCustomAutotagSearchDropdownContainer } from './CustomAutotagSearchDropdownSingle'
import {
  ValueWrapper,
  ValueBox,
  SearchIcon,
  DropdownTextInput,
  DropdownSelectText,
  DropdownList,
  DropdownListItem,
  DropdownListItemIcon,
  DropdownCaret,
  SelectedValuePill,
  SelectedValueIcon,
  InvalidMessage,
} from './CustomAutotagShared'

class CustomAutotagSearchDropdownMultiForWaitlistStatus extends React.PureComponent {
  onChangeHandler = optionKey => {
    const { selectedValue, onChangeHandler, getDisplayText } = this.props
    let newValue
    const currentValues = selectedValue || []
    if (currentValues.includes(getDisplayText(optionKey))) {
      newValue = _.filter(currentValues, value => value !== getDisplayText(optionKey))
    } else {
      newValue = _.concat(currentValues, [getDisplayText(optionKey)])
    }
    onChangeHandler(newValue, true)
  }

  removeSelectionHandler = optionDisplay => {
    const { selectedValue, onChangeHandler } = this.props
    const newValue = _.filter([...selectedValue], value => value !== optionDisplay)
    onChangeHandler(newValue)
  }

  render() {
    const {
      id,
      dropdownHeight,
      renderBelow,
      isSearchable,
      selectedValue,
      placeholder,
      isActive,
      listOptions,
      searchValue,
      customWrapper,
      textInputID,
      onClickHandler,
      onSearchTextChange,
      getDisplayText,
      optionsMap,
      customSelectedItemsRender,
      customDropdownRender,
      invalidMessage,
    } = this.props
    const selectedValues = selectedValue || []
    const selectedValuesSet = new Set(selectedValues)
    const hasSelectedValues = !!selectedValues.length
    const displaySearchComponents = isSearchable && isActive
    const displayDropdown = isActive && !!listOptions.length
    const displayPlaceholder = !hasSelectedValues && (!isSearchable || !isActive)
    const isValid = this.props.isValid || hasSelectedValues
    return (
      <ValueWrapper css={customWrapper} id={id}>
        <ValueBox
          onClick={onClickHandler}
          isValid={isValid}
          css={css`
            padding-top: 0px;
            padding-right: 30px;
            padding-bottom: 0px;
            ${hasSelectedValues && 'padding-left: 10px;'} ${displaySearchComponents && 'padding-left: 30px;'};
          `}
        >
          {displaySearchComponents && (
            <SearchIcon
              css={css`
                height: 31px;
                position: absolute;
                left: 0;
                top: 0;
              `}
            >
              {VmsIcons.Search}
            </SearchIcon>
          )}
          {hasSelectedValues &&
            (customSelectedItemsRender
              ? customSelectedItemsRender(optionsMap, selectedValues, this.removeSelectionHandler)
              : _.map(selectedValues, optionDisplay => {
                  const displayText = optionDisplay
                  const removeHandler = this.removeSelectionHandler.bind(this, optionDisplay)
                  return (
                    <SelectedValuePill
                      key={optionDisplay}
                      onClick={removeHandler}
                      css={css`
                        border: 1px solid black;
                      `}
                    >
                      {displayText}
                      <SelectedValueIcon>{VmsIcons.ClearLine}</SelectedValueIcon>
                    </SelectedValuePill>
                  )
                }))}
          {displayPlaceholder && (
            <DropdownSelectText
              css={css`
                padding: 6px 10px;
              `}
            >
              {placeholder}
            </DropdownSelectText>
          )}
          {displaySearchComponents && (
            <DropdownTextInput
              id={textInputID}
              placeholder={hasSelectedValues ? '' : 'Search...'}
              value={searchValue}
              onChange={onSearchTextChange}
              autoComplete="off"
              css={css`
                width: 160px;
                height: 31px;
              `}
            />
          )}
          <DropdownCaret
            isActive={isActive}
            css={css`
              height: auto;
              line-height: 35px;
              margin-left: auto;
              right: 3px;
              top: 0;
            `}
          >
            {VmsIcons.Chevron}
          </DropdownCaret>
        </ValueBox>
        {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
        {displayDropdown &&
          (customDropdownRender ? (
            customDropdownRender(listOptions, selectedValuesSet, dropdownHeight, renderBelow, this.onChangeHandler)
          ) : (
            <DropdownList id={`${id}-dropdown`} maxHeight={dropdownHeight} renderBelow={renderBelow}>
              {_.map(listOptions, option => {
                const isSelected = selectedValuesSet.has(getDisplayText(option.key))
                return (
                  <DropdownListItem id={option.key} key={option.key} onClick={() => this.onChangeHandler(option.key)}>
                    <DropdownListItemIcon
                      isSelected={isSelected}
                      css={css`
                        :after {
                          content: '${isSelected ? VmsIcons.Checked : VmsIcons.OvalLine}';
                        }
                      `}
                    />
                    {option.displayText}
                  </DropdownListItem>
                )
              })}
            </DropdownList>
          ))}
      </ValueWrapper>
    )
  }
}

export default withCustomAutotagSearchDropdownContainer(CustomAutotagSearchDropdownMultiForWaitlistStatus)
