import { ProductProvisionPackageTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BorderedBox, Box, Grid, VStack } from '@sevenrooms/core/ui-kit/layout'
import { List, SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { CustomerSupportLink } from '../Integrations'
import { messages } from './PaymentsValueProp.locales'
import type { PropsWithChildren } from 'react'

interface ReusableBoxProps {
  amountText: string
  description: string
}

function ReusableBoxWithBackgroundColor(props: PropsWithChildren<ReusableBoxProps>) {
  const { amountText, description } = props
  return (
    <BorderedBox backgroundColor="secondaryInfoBackground" mb="m" p="s" textAlign="center">
      <Text textStyle="h3">{amountText}</Text>
      <SecondaryText type="p">{description}</SecondaryText>
    </BorderedBox>
  )
}

function ValuePropGrid({ children }: PropsWithChildren<unknown>) {
  return (
    <Grid gridTemplateRows="7% 25% 28% 31% auto" p="m" height="100%">
      {children}
    </Grid>
  )
}

export function PaymentsValueProp() {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useAppContext()
  const { formatMessage } = useLocales()

  const isOffersEnabledForVenue =
    venue.productProvisionPackage !== ProductProvisionPackageTypeEnum.LEGACY ||
    (venue.productProvisionPackage === ProductProvisionPackageTypeEnum.LEGACY && venueSettings.isFullSizzlePackage)
  const offersButtonDestination = isOffersEnabledForVenue
    ? nav.href(routes.manager2.marketing.offers, { params: { venueKey: venue.urlKey } })
    : CustomerSupportLink
  const offersButtonText = isOffersEnabledForVenue ? formatMessage(messages.valueProp1Button) : formatMessage(messages.valuePropLearnHow)

  const isEventsEnabledForVenue = venueSettings.eventWidgetEnabled
  const eventsButtonDestination = isEventsEnabledForVenue
    ? nav.href(routes.manager.events, { params: { venueKey: venue.urlKey } })
    : CustomerSupportLink
  const eventsButtonText = isEventsEnabledForVenue ? formatMessage(messages.valueProp3Button) : formatMessage(messages.valuePropLearnHow)

  return (
    <VStack m="m" p="m" backgroundColor="primaryBackground" width="968px">
      <Box mb="xs">
        <Text textStyle="h2">{formatMessage(messages.valuePropHeader)}</Text>
      </Box>
      <SecondaryText>{formatMessage(messages.valuePropDescription)}</SecondaryText>

      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="sm" mt="lm" mb="lm">
        <BorderedBox pb="m">
          <ValuePropGrid>
            <Text textStyle="h3">
              {formatMessage(messages.valueProp1Header)}
              &nbsp;<sup>*</sup>
            </Text>
            <SecondaryText type="p">{formatMessage(messages.valueProp1Description)}</SecondaryText>
            <List>
              <SecondaryText type="li">{formatMessage(messages.valueProp1Item1)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp1Item2)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp1Item3)}</SecondaryText>
            </List>
            <ReusableBoxWithBackgroundColor
              amountText={formatMessage(messages.valueProp1Amount)}
              description={formatMessage(messages.valueProp1AmountDescription)}
            />
            <Button
              data-test="create-an-experience-button"
              variant="secondary"
              href={offersButtonDestination}
              isExternal
              target={isOffersEnabledForVenue ? '_self' : '_blank'}
            >
              {offersButtonText}
            </Button>
          </ValuePropGrid>
        </BorderedBox>
        <BorderedBox pb="m">
          <ValuePropGrid>
            <Text textStyle="h3">{formatMessage(messages.valueProp2Header)}</Text>
            <SecondaryText type="p">{formatMessage(messages.valueProp2Description)}</SecondaryText>
            <List>
              <SecondaryText type="li">{formatMessage(messages.valueProp2Item1)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp2Item2)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp2Item3)}</SecondaryText>
            </List>
            <ReusableBoxWithBackgroundColor
              amountText={formatMessage(messages.valueProp2Amount)}
              description={formatMessage(messages.valueProp2AmountDescription)}
            />
            <Button
              data-test="create-an-upgrade-button"
              variant="secondary"
              href={nav.href(routes.manager.upgrades, { params: { venueKey: venue.urlKey } })}
              isExternal
              target="_self"
            >
              {formatMessage(messages.valueProp2Button)}
            </Button>
          </ValuePropGrid>
        </BorderedBox>
        <BorderedBox pb="m">
          <ValuePropGrid>
            <Text textStyle="h3">
              {formatMessage(messages.valueProp3Header)}
              &nbsp;<sup>*</sup>
            </Text>
            <SecondaryText type="p">{formatMessage(messages.valueProp3Description)}</SecondaryText>
            <List>
              <SecondaryText type="li">{formatMessage(messages.valueProp3Item1)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp3Item2)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp3Item3)}</SecondaryText>
            </List>
            <ReusableBoxWithBackgroundColor
              amountText={formatMessage(messages.valueProp3Amount)}
              description={formatMessage(messages.valueProp3AmountDescription)}
            />
            <Button
              data-test="create-an-event-button"
              variant="secondary"
              href={eventsButtonDestination}
              isExternal
              target={isEventsEnabledForVenue ? '_self' : '_blank'}
            >
              {eventsButtonText}
            </Button>
          </ValuePropGrid>
        </BorderedBox>
        <BorderedBox pb="m">
          <ValuePropGrid>
            <Text textStyle="h3">{formatMessage(messages.valueProp4Header)}</Text>
            <SecondaryText type="p">{formatMessage(messages.valueProp4Description)}</SecondaryText>
            <List>
              <SecondaryText type="li">{formatMessage(messages.valueProp4Item1)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp4Item2)}</SecondaryText>
              <SecondaryText type="li">{formatMessage(messages.valueProp4Item3)}</SecondaryText>
            </List>
            <ReusableBoxWithBackgroundColor
              amountText={formatMessage(messages.valueProp4Amount)}
              description={formatMessage(messages.valueProp4AmountDescription)}
            />
            <Button
              variant="secondary"
              href={nav.href(routes.manager.manage.shifts, { params: { venueKey: venue.urlKey } })}
              isExternal
              target="_self"
              data-test="create-a-policy-setting-button"
            >
              {formatMessage(messages.valueProp4Button)}
            </Button>
          </ValuePropGrid>
        </BorderedBox>
      </Grid>

      <SecondaryText>
        <sup>*</sup>&nbsp;
        {formatMessage(messages.valuePropRequestSupport)}
      </SecondaryText>
    </VStack>
  )
}
