import { BookingChannelsTestId } from './components/BookingChannels/BookingChannels.testIds'
import { GuestFacingTestId } from './components/GuestFacing/GuestFacing.testIds'
import { PacingTestId } from './components/Pacing/Pacing.testIds'
import { PartySizeTestId } from './components/PartySize/PartySize.testIds'
import { PaymentPolicyTestId } from './components/PaymentPolicy/PaymentPolicy.testIds'
import { RecurringDecisionTestId } from './components/RecurringDecision/RecurringDecision.testIds'
import { ReservationCoverLimitTestId } from './components/ReservationCoverLimit/ReservationCoverLimit.testIds'
import { ReservationTagsTestId } from './components/ReservationTags/ReservationTags.testIds'
import { ScheduleTestId } from './components/Schedule/Schedule.testIds'
import { SeatingAreasTestId } from './components/SeatingAreas/SeatingAreas.testIds'
import { SharedTestId } from './components/shared/shared.testIds'
import { UpgradesTestId } from './components/Upgrades/Upgrades.testIds'

export const AccessRuleTestId = {
  slideout: 'access-rules-slideout',
  switchSlideout: 'switch-slideout',

  basicSettings: 'basic-settings',
  additionalSettings: 'additional-settings',
  changesBanner: 'ar-changes-banner',

  name: 'name',
  bookingChannels: BookingChannelsTestId,
  guestFacing: GuestFacingTestId,
  pacing: PacingTestId,
  partySize: PartySizeTestId,
  paymentPolicy: PaymentPolicyTestId,
  reservationCoverLimit: ReservationCoverLimitTestId,
  reservationTags: ReservationTagsTestId,
  schedule: ScheduleTestId,
  seatingAreas: SeatingAreasTestId,
  upgrades: UpgradesTestId,
  recurringDecisionModal: RecurringDecisionTestId,
  shared: SharedTestId,
  // closeSlideout: 'ui-kit-slideout-close',
  // saveOrEdit: 'ui-kit-slideout-primary-button',
  // cancelOrClone: 'ui-kit-slideout-secondary-button',
  // delete: 'ui-kit-slideout-delete',
} as const
