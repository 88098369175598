import { type SquareLocation, SquareLocationStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Banner, Box } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseLocationSelectionCard } from '../BaseLocationSelectionCard'
import { locationSelectOption, type LocationSelectionCardProps } from '../LocationSelectionCard'
import { messages as locationCardMessages } from '../LocationSelectionCard.locales'
import { messages } from './SquareLocationSelectionCard.locales'

export interface SquareLocationSelectionCardProps extends LocationSelectionCardProps {
  locations: SquareLocation[]
  isEligibleLocation: boolean
}

export function SquareLocationSelectionCard({ field, locations, disabled, isEligibleLocation }: SquareLocationSelectionCardProps) {
  const { formatMessage } = useLocales()
  const selectOptions: SelectOption[] = locations
    .filter(location => location.status === SquareLocationStatusEnum.ACTIVE)
    .map(location => locationSelectOption(location))

  const ineligibleLocationBannerLinkUrl = 'https://squareup.com/dashboard/restaurants/learn-more'

  return (
    <BaseLocationSelectionCard docLink={posiMetaDataMap.SQUARE.docLink}>
      <Label width="50%" primary={formatMessage(locationCardMessages.locationLabel)}>
        <FormSelect field={field.prop('locationId')} options={selectOptions} disabled={disabled} />
      </Label>
      {!isEligibleLocation && (
        <Box width="100%">
          <Banner
            type="warning"
            title={formatMessage(messages.ineligibleLocationBannerTitle)}
            description={formatMessage(messages.ineligibleLocationBannerDescription, {
              a: (chunks: string[]) => <Anchor href={ineligibleLocationBannerLinkUrl}>{chunks}</Anchor>,
            })}
          />
        </Box>
      )}
    </BaseLocationSelectionCard>
  )
}
