import { useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleTooltip } from '../../shared'
import { SharedTestId } from '../../shared/shared.testIds'
import { DurationsLocales } from '../Durations.locales'

export function MissingDurationErrorTooltip() {
  const { formatMessage } = useLocales()

  return (
    <AccessRuleTooltip data-test={SharedTestId.cutoffTimeWarning} variant="error">
      <Text color="lightFont">{formatMessage(DurationsLocales.missingDurationError)}</Text>
    </AccessRuleTooltip>
  )
}
