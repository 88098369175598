import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { promoCodeMessages } from '../locales'

interface UnsavedChangesModalProps {
  onDiscard?: () => void
  onCancel?: () => void
}
export function UnsavedChangesModal({ onCancel, onDiscard }: UnsavedChangesModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Modal" minWidth={528}>
      <ModalHeader pb="none" onClose={() => onCancel?.()}>
        <VStack spacing="s">
          <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(promoCodeMessages.promoCodeUnsavedChangesModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={() => onCancel?.()} data-test="sr-promo-code-back-editing">
            {formatMessage(commonMessages.backToEditing)}
          </Button>
          <Button variant="primary-warning" onClick={() => onDiscard?.()} data-test="sr-promo-code-discard-changes">
            {formatMessage(commonMessages.discardChanges)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
