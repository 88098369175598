import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { ChargeSettings } from '../../../ChargeSettings'
import { useAccessRuleContext } from '../../../shared'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import { ChargeChoices, MinPartySize } from './RadioComponents'
import type { PaymentPolicyProps } from '../PaymentPolicyProps'

export function ChargeAtBooking({ field }: PaymentPolicyProps) {
  const { taxRates } = useAccessRuleContext()
  return (
    <VStack spacing="s">
      <MinPartySize field={field} />
      <ChargeChoices data-test={PaymentPolicyTestId.bookingCharge} field={field.prop('bookingCharge')} />
      <ChargeSettings data-test={PaymentPolicyTestId.charges} field={field.prop('charges')} taxRates={taxRates} />
    </VStack>
  )
}
