import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { partySizeRangePickerMessages } from './PartySizeRangePicker.messages'

export type PartySizeRangePickerForm = ZodSchema<typeof usePartySizeRangePickerForm>

export function usePartySizeRangePickerForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z
        .object({
          min: z.number(),
          max: z.number(),
        })
        .superRefine(({ min, max }, ctx) => {
          if (min > max) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(partySizeRangePickerMessages.partySizeMinInvalid),
            })
          }
        }),
    [formatMessage]
  )
}
