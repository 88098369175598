import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, MultiSelectTag, type MultiSelectTagOptionsForm, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import { generateTagHash, getFormattedCategories } from '../BookingChannels.zod'
import type { TagGroup } from '../../../AccessRule.types'

interface CreateTagSelectorProps {
  field?: Field<MultiSelectTagOptionsForm>
  clientTagGroups: Map<string, TagGroup>
  whoCanBook: string
}
export function CreateTagSelector({ field, clientTagGroups, whoCanBook }: CreateTagSelectorProps) {
  const { formatMessage } = useLocales()
  const formattedTagOptions = useMemo(() => getFormattedTagOptions(clientTagGroups), [clientTagGroups])
  return (
    <VStack backgroundColor="secondaryBackground" p="sm" mt="s" spacing="sm">
      <Text>
        {formatMessage(BookingChannelsLocales.inventoryShowTo)} {whoCanBook}
      </Text>
      <VStack spacing="s">
        <Label>{formatMessage(BookingChannelsLocales.clientTagCanBook)}</Label>
        <MultiSelectTag
          field={field}
          categories={getFormattedCategories(clientTagGroups)}
          options={formattedTagOptions}
          searchable
          placeholder={formatMessage(BookingChannelsLocales.searchTagPlaceholder)}
        />
      </VStack>
    </VStack>
  )
}

function getFormattedTagOptions(tagGroups: Map<string, TagGroup>): TagOption[] {
  const options: TagOption[] = []
  tagGroups.forEach((tagGroup, categoryId) =>
    tagGroup.tags.forEach(tag =>
      options.push({
        id: generateTagHash(tagGroup.privacy, categoryId, tagGroup.name, tag),
        label: tagGroup.tagNameDisplays[tag] ?? tag,
        categoryId,
      })
    )
  )
  return options
}
