import { type Dispatch, type SetStateAction, useCallback } from 'react'
import { useDisconnectMutation } from '@sevenrooms/core/api'
import type { POSIType } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Dialog } from '@sevenrooms/react-components/components/Dialog'
import { messages } from './ConfirmDisconnectIntegrationDialog.locales'

export interface ConfirmDisconnectIntegrationDialogProps {
  isOpen: boolean
  onClose: () => void
  setIsDisconnecting: Dispatch<SetStateAction<boolean>>
}

export function ConfirmDisconnectIntegrationDialog({ isOpen, onClose, setIsDisconnecting }: ConfirmDisconnectIntegrationDialogProps) {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const posiType = venue.posIntegrationType as POSIType

  const [disconnect, { isLoading }] = useDisconnectMutation()

  const tryDisconnectIntegration = useCallback(async () => {
    try {
      await disconnect({ posiType, venueId }).unwrap()
      notify({
        content: formatMessage(messages.disconnectIntegrationSuccess, { posiType }),
        type: 'success',
      })
      setIsDisconnecting(true)
      onClose()
      window.location.reload()
    } catch {
      notify({
        content: formatMessage(messages.disconnectIntegrationError, { posiType }),
        type: 'error',
      })
    }
  }, [disconnect, venueId, formatMessage, posiType, setIsDisconnecting, onClose])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="disconnect-modal-dialog-title"
      aria-describedby="disconnect-modal-dialog-description"
      fullWidth
    >
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(messages.title)} />
        </VStack>
      </ModalHeader>

      <ModalBody>
        <Text>{formatMessage(messages.content)}</Text>
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Box width="120px">
            <Button variant="secondary" onClick={onClose} data-test="deactivate-modal-cancel" fullWidth>
              {formatMessage(commonMessages.cancel)}
            </Button>
          </Box>
          <Box width="120px">
            <Button
              data-test="deactivate-modal-confirm"
              variant="primary-warning"
              onClick={tryDisconnectIntegration}
              disabled={isLoading}
              fullWidth
            >
              {formatMessage(messages.disconnect)}
            </Button>
          </Box>
        </ModalActions>
      </ModalFooter>
      {/* </Modal> */}
    </Dialog>
  )
}
