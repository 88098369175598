import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { type OneTimeSMSCampaignType, useDeleteSMSCampaignByIdMutation, useUpdateSMSCampaignsMutation } from '@sevenrooms/core/api'
import { type GenericTagGroup, OneTimeSMSCampaignStatusEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales, Locale } from '@sevenrooms/core/locales'
import { Surface, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { useScreenWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  VStack,
  Box,
  Loader,
  notify,
  SECONDARY_HEADER_ID,
  BasicCell,
  Cell,
  EmptyState,
  RowDivider,
  Spreadsheet,
  Window,
  Tooltip,
  type DataTableColumn,
  type Row,
  type EmptyStateProps,
} from '@sevenrooms/core/ui-kit/layout'
import { TruncatingText, Link, StatusLabel, type StatusLabelVariant, Text } from '@sevenrooms/core/ui-kit/typography'
import {
  campaignCenterMessages,
  marketingCommonMessages,
  type OneTimeSMSCampaignWithActivity,
  useSMSCampaignPerformanceContext,
} from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import mobilePhone from '../../assets/images/mobile_phone.png'
import { oneTimeSMSCenterMessages } from '../../locales'
import { ConfirmArchiveOneTimeSMSModal } from '../ConfirmArchiveOneTimeSMSModal'
import { ConfirmEditOneTimeSMSModal } from '../ConfirmEditOneTimeSMSModal'

export function OneTimeSMSCampaigns() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const canManageSMSsWithGlobalTags = useAppContext().userDomain?.canManageEmailsWithGlobalTags
  const [updateSMSCampaignsQuery] = useUpdateSMSCampaignsMutation()
  const [deleteSMSCampaignMutation] = useDeleteSMSCampaignByIdMutation()

  const { breakpoint } = useScreenWidthBreakpoint()
  const isLargeScreen = _.includes(['l', 'xl'], breakpoint)
  const locale = Locale.getLocale(venue?.locale)
  const currencySymbol = venue?.currencySymbol
  const { params } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.archiveSMS)
  const campaignId = params?.campaignId
  const closeArchiveSMSCampaignHref = nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.confirmArchiveModal, {
    params: { venueKey: venue.urlKey, campaignId },
  })

  const { campaigns, filteredCampaigns, campaignStatus, searchValue, isError, isFetching, isPOSActivated, averageSpendPerCover } =
    useSMSCampaignPerformanceContext()

  const statusFilters: SelectOption<OneTimeSMSCampaignStatusEnum>[] = useMemo(
    () => [
      { id: OneTimeSMSCampaignStatusEnum.COMPLETE, label: formatMessage(marketingCommonMessages.complete) },
      { id: OneTimeSMSCampaignStatusEnum.ACTIVE, label: formatMessage(marketingCommonMessages.scheduled) },
      { id: OneTimeSMSCampaignStatusEnum.DRAFT, label: formatMessage(marketingCommonMessages.draft) },
    ],
    [formatMessage]
  )

  const onCampaignEditConfirm = useCallback(
    async (campaignId: string) => {
      try {
        const response = await updateSMSCampaignsQuery({
          smsCampaignIds: [campaignId],
          smsCampaignUpdate: { status: OneTimeSMSCampaignStatusEnum.INACTIVE },
          venueId: venue.id || '',
        })
        if ('error' in response) {
          notify({
            content: formatMessage(campaignCenterMessages.campaignSaveError),
            type: 'error',
          })
          nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.confirmEditModal, {
            params: { venueKey: venue?.urlKey, campaignId },
          })
          return
        }
        nav.push(routes.manager2.marketing.oneTimeSMSCenter.editOneTimeSMS, {
          params: { venueKey: venue.urlKey, campaignId },
          queryMode: 'clear',
        })
      } catch {
        notify({
          content: formatMessage(campaignCenterMessages.campaignSaveError),
          type: 'error',
        })
      }
    },
    [formatMessage, nav, updateSMSCampaignsQuery, venue]
  )

  const onCampaignEditClick = useCallback(
    (campaign: OneTimeSMSCampaignWithActivity) => {
      if (campaign.status === OneTimeSMSCampaignStatusEnum.DRAFT) {
        nav.push(routes.manager2.marketing.oneTimeSMSCenter.editOneTimeSMS, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
          queryMode: 'clear',
        })
      } else {
        nav.push(routes.manager2.marketing.oneTimeSMSCenter.confirmEditModal, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
        })
      }
    },
    [nav, venue]
  )

  const onCampaignArchiveConfirm = useCallback(
    async (campaignId: string) => {
      try {
        await deleteSMSCampaignMutation({ campaignId, venueId: venue.id || '' }).unwrap()

        notify({
          content: formatMessage(oneTimeSMSCenterMessages.campaignSaveSuccess),
          type: 'success',
        })

        nav.closeSurface(closeArchiveSMSCampaignHref)
      } catch {
        notify({
          content: formatMessage(campaignCenterMessages.campaignSaveError),
          type: 'error',
        })
      }
    },
    [formatMessage, nav, deleteSMSCampaignMutation, venue, closeArchiveSMSCampaignHref]
  )

  const onCampaignArchiveClick = useCallback(
    (campaign: OneTimeSMSCampaignWithActivity) => {
      nav.push(routes.manager2.marketing.oneTimeSMSCenter.confirmArchiveModal, {
        params: { venueKey: venue.urlKey, campaignId: campaign.id },
      })
    },
    [nav, venue]
  )

  const onCampaignViewClick = useCallback(
    (campaign: OneTimeSMSCampaignWithActivity) => {
      nav.push(routes.manager2.marketing.oneTimeSMSCenter.viewOneTimeSMS, {
        params: { venueKey: venue.urlKey, campaignId: campaign.id },
      })
    },
    [nav, venue]
  )

  const onCampaignDuplicateClick = useCallback(
    (campaignId: string) => {
      nav.push(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS, {
        params: { venueKey: venue.urlKey },
        query: { campaignToDuplicateId: campaignId, parentTemplateId: '' },
      })
    },
    [nav, venue]
  )

  const oneTimeSMSStatusLabelConfig: { [status in OneTimeSMSCampaignStatusEnum]?: { text: string; variant: StatusLabelVariant } } = useMemo(
    () => ({
      [OneTimeSMSCampaignStatusEnum.DRAFT]: { text: formatMessage(oneTimeSMSCenterMessages.draft), variant: 'inactive' },
      [OneTimeSMSCampaignStatusEnum.ACTIVE]: { text: formatMessage(oneTimeSMSCenterMessages.scheduled), variant: 'status' },
      [OneTimeSMSCampaignStatusEnum.COMPLETE]: { text: formatMessage(oneTimeSMSCenterMessages.complete), variant: 'success' },
    }),
    [formatMessage]
  )

  const getCampaignStatusDate = (campaign: OneTimeSMSCampaignWithActivity) => {
    if (campaign.status === OneTimeSMSCampaignStatusEnum.COMPLETE) {
      return campaign.sentDateTimeLocal
    } else if (campaign.status === OneTimeSMSCampaignStatusEnum.ACTIVE) {
      return campaign.oneTimeSendTimeConfig?.scheduledDate
    }

    return campaign.updated
  }

  const sortCampaigns = (campaigns: OneTimeSMSCampaignWithActivity[]) => {
    const precendence = [OneTimeSMSCampaignStatusEnum.DRAFT, OneTimeSMSCampaignStatusEnum.ACTIVE, OneTimeSMSCampaignStatusEnum.COMPLETE]

    const campaignGroups = _.groupBy(campaigns, campaign => getCampaignStatusDate(campaign)?.toDateOnly()?.toJsDate()?.setDate(0))

    const campaignGroupsOrderedByDate = Object.keys(campaignGroups)
      .sort()
      .reverse()
      .reduce<typeof campaignGroups>(
        (obj, key) =>
          ({
            ...obj,
            [key]: campaignGroups[key],
          } as typeof campaignGroups),
        {}
      )

    return _.flatten(
      _.values(
        _.mapValues(campaignGroupsOrderedByDate, campaigns =>
          _.orderBy(
            campaigns,
            [
              campaign => _.findIndex(precendence, status => campaign.status === status),
              campaign => getCampaignStatusDate(campaign)?.toJsDate(),
            ],
            ['asc', 'desc']
          )
        )
      )
    )
  }

  const renderPreComponent = ({
    row,
    index,
    rows,
    containerWidth,
  }: {
    row: Row<OneTimeSMSCampaignWithActivity>
    index: number
    rows: Row<OneTimeSMSCampaignWithActivity>[]
    containerWidth: React.CSSProperties['width']
  }) => {
    const previousCampaign = rows[index - 1]?.original
    const previousCampaignDate = previousCampaign && getCampaignStatusDate(previousCampaign)?.toJsDate()
    const currentCampaignDate = getCampaignStatusDate(row?.original)
    const currentCampaignJsDate = currentCampaignDate?.toJsDate()

    if (!previousCampaign || previousCampaignDate?.getMonth() !== currentCampaignJsDate?.getMonth()) {
      const count = _.size(
        rows.filter(value => {
          const campaignDate = getCampaignStatusDate(value?.original)?.toJsDate()

          return (
            campaignDate?.getFullYear() === currentCampaignJsDate?.getFullYear() &&
            campaignDate?.getMonth() === currentCampaignJsDate?.getMonth()
          )
        })
      )

      return (
        <RowDivider width={containerWidth}>
          {currentCampaignDate?.formatFMonthNYear()}&nbsp;({count})
        </RowDivider>
      )
    }

    return null
  }

  const getCampaignAudienceDescription = useCallback(
    (campaign: OneTimeSMSCampaignWithActivity) => {
      const formattedRecipientClientTags = campaign?.formattedRecipientClientTags
      return formattedRecipientClientTags?.length
        ? `${formatMessage(oneTimeSMSCenterMessages.campaignRecipientsTagLeadIn)} ${formattedRecipientClientTags
            .map((formattedRecipientClientTag: string | GenericTagGroup) =>
              typeof formattedRecipientClientTag === 'string'
                ? formattedRecipientClientTag
                : (formattedRecipientClientTag as GenericTagGroup).nameDisplay
            )
            .join(', ')}`
        : formatMessage(oneTimeSMSCenterMessages.campaignRecipientsNoTags)
    },
    [formatMessage]
  )

  const getCampaignStatusDescription = useCallback(
    (campaign: OneTimeSMSCampaignWithActivity) => {
      const date = getCampaignStatusDate(campaign)?.formatNYearFMonthNDaySWeekSTime() || ''

      if (campaign.status === OneTimeSMSCampaignStatusEnum.COMPLETE) {
        return (
          <>
            {formatMessage(campaignCenterMessages.campaignNameSentDescription, {
              date,
            })}
          </>
        )
      } else if (campaign.status === OneTimeSMSCampaignStatusEnum.ACTIVE) {
        return (
          <>
            {formatMessage(campaignCenterMessages.campaignNameScheduledDescription, {
              date,
            })}
          </>
        )
      }

      return formatMessage(campaignCenterMessages.campaignNameDraftDescription, {
        date,
      })
    },
    [formatMessage]
  )

  const getActionMenu = (value: OneTimeSMSCampaignWithActivity) => (
    <Menu
      scrollLock
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      disabled={value.usesGlobalTag && !canManageSMSsWithGlobalTags}
    >
      {_.includes([OneTimeSMSCampaignStatusEnum.DRAFT, OneTimeSMSCampaignStatusEnum.ACTIVE], value.status) && (
        <MenuItem onClick={() => onCampaignEditClick(value)}>
          <Icon name="VMSWeb-edit" />
          {formatMessage(commonMessages.edit)}
        </MenuItem>
      )}

      {value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && (
        <MenuItem onClick={() => onCampaignViewClick(value)}>
          <Icon name="VMSWeb-eye" />
          {formatMessage(commonMessages.view)}
        </MenuItem>
      )}

      <MenuItem data-test="sr-button-duplicate-sms-campaign" onClick={() => onCampaignDuplicateClick(value.id)}>
        <Icon name="VMSWeb-copy" />
        {formatMessage(commonMessages.duplicate)}
      </MenuItem>

      {_.includes([OneTimeSMSCampaignStatusEnum.DRAFT], value.status) && (
        <MenuItem data-test="sr-button-archive-sms-campaign" onClick={() => onCampaignArchiveClick(value)}>
          <Icon name="VMSWeb-trash" />
          {formatMessage(commonMessages.archive)}
        </MenuItem>
      )}
    </Menu>
  )

  const columns: DataTableColumn<OneTimeSMSCampaignWithActivity>[] = [
    {
      key: 'campaignName',
      header: formatMessage(oneTimeSMSCenterMessages.campaignName),
      accessor: 'campaignName',
      render: (value, index) => (
        <Box minHeight={36} overflow="hidden" boxSizing="content-box" data-test={`one-time-sms-${index}-campaign-name`}>
          <Box mb="xs">
            <Text textOverflow="twoLinesEllipsis">{value.campaignName}</Text>
          </Box>
        </Box>
      ),
      attach: 'left',
      minWidth: 270,
      maxWidth: isLargeScreen ? undefined : 270,
      width: isLargeScreen ? undefined : 270,
      alignItems: 'start',
    },
    {
      key: 'status',
      header: formatMessage(campaignCenterMessages.status),
      accessor: 'status',
      render: (value, index) => (
        <Box overflow="hidden" boxSizing="content-box" data-test={`one-time-sms-${index}-status`}>
          {value?.status && (
            <Box mb="xs">
              <StatusLabel variant={oneTimeSMSStatusLabelConfig[value.status]?.variant || 'inactive'}>
                {oneTimeSMSStatusLabelConfig[value.status]?.text}
              </StatusLabel>
            </Box>
          )}
          <Text color="secondaryFont">{getCampaignStatusDescription(value)}</Text>
        </Box>
      ),
      minWidth: 220,
      maxWidth: 270,
      alignItems: 'start',
    },
    {
      key: 'recipients',
      header: formatMessage(oneTimeSMSCenterMessages.recipientsColumnHeading),
      textDisplay: 'flex',
      headerAlign: 'start',
      cellAlign: 'start',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.recipients,
      render: (value: OneTimeSMSCampaignWithActivity, index) => (
        <Cell data-test={`one-time-sms-${index}-recipients`}>
          <VStack spacing="s">
            <TruncatingText
              textDisplay="block"
              message={getCampaignAudienceDescription(value)}
              textOverflow={1}
              viewMoreButtonText={formatMessage(commonMessages.viewMore)}
              viewLessButtonText={formatMessage(commonMessages.viewLess)}
              whiteSpace="normal"
              spacing="xs"
            />
            {value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && (
              <Text color="secondaryFont">
                {formatMessage(campaignCenterMessages.campaignNameGuestsDescription, {
                  guests: value.campaignActivity
                    ? value.campaignActivity.recipients
                    : value?.oneTimeSendTimeConfig?.estimatedNumClients || 0,
                })}
              </Text>
            )}
          </VStack>
        </Cell>
      ),
      minWidth: 400,
      maxWidth: 400,
      alignItems: 'start',
    },
    {
      key: 'clicks',
      header: formatMessage(campaignCenterMessages.clicks),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.clicks,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.clicks.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.clicks / value.campaignActivity.recipients) * 100) || 0}%`}
            data-test={`one-time-sms-${index}-clicks`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'totalRevenue',
      header: formatMessage(campaignCenterMessages.revenue),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.totalRevenue,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell
            primary={`${currencySymbol ?? ''}${Math.round(
              parseFloat(
                `${
                  !isPOSActivated && averageSpendPerCover
                    ? value.campaignActivity.covers * averageSpendPerCover
                    : value.campaignActivity.totalRevenue
                }`
              )
            ).toLocaleString(locale)}`}
            data-test={`one-time-sms-${index}-revenue`}
          />
        ) : (
          '-'
        ),
      minWidth: 87,
      alignItems: 'start',
    },
    {
      key: 'reservations',
      header: formatMessage(campaignCenterMessages.reservations),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.reservations,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.reservations.toLocaleString(locale)}
            data-test={`one-time-sms-${index}-reservations`}
          />
        ) : (
          '-'
        ),
      minWidth: 108,
      alignItems: 'start',
    },
    {
      key: 'covers',
      header: formatMessage(campaignCenterMessages.covers),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.covers,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell primary={value.campaignActivity.covers.toLocaleString(locale)} data-test={`one-time-sms-${index}-covers`} />
        ) : (
          '-'
        ),
      minWidth: 76,
      alignItems: 'start',
    },
    {
      key: 'orders',
      header: formatMessage(campaignCenterMessages.orders),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.orders,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell primary={value.campaignActivity.orders.toLocaleString(locale)} data-test={`one-time-sms-${index}-orders`} />
        ) : (
          '-'
        ),
      minWidth: 76,
      alignItems: 'start',
    },
    {
      key: 'unsubscribed',
      header: formatMessage(campaignCenterMessages.unsubscribed),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.unsubscribed,
      render: (value, index) =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.unsubscribed.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.unsubscribed / value.campaignActivity.recipients) * 100) || 0}%`}
            data-test={`one-time-sms-${index}-unsubscribed`}
          />
        ) : (
          '-'
        ),
      minWidth: 119,
      alignItems: 'start',
    },
    {
      key: 'bounces',
      header: formatMessage(campaignCenterMessages.bounce),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeSMSCampaignWithActivity) => value?.campaignActivity?.bounces,
      render: value =>
        value.status === OneTimeSMSCampaignStatusEnum.COMPLETE && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.bounces.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.bounces / value.campaignActivity.recipients) * 100) || 0}%`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'action',
      header: '',
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      render: value => {
        if (value.usesGlobalTag && !canManageSMSsWithGlobalTags) {
          return (
            <Tooltip
              content={<span>{formatMessage(campaignCenterMessages.campaignTableEditPermissionTooltip)}</span>}
              displayAction="hover"
              alignment="top"
            >
              <Box textAlign="right">{getActionMenu(value)}</Box>
            </Tooltip>
          )
        }
        return getActionMenu(value)
      },
      attach: 'right',
      expandable: true,
      minWidth: 64,
      maxWidth: 64,
      alignItems: 'start',
    },
  ]

  const getEmptyState = (): React.ReactNode | undefined => {
    const emptyStateProps: EmptyStateProps = {}
    if (_.isEmpty(campaigns)) {
      emptyStateProps.image = mobilePhone
      emptyStateProps.title = formatMessage(oneTimeSMSCenterMessages.emptyTableTitleNoData)
      emptyStateProps.description = (
        <>
          {formatMessage(oneTimeSMSCenterMessages.emptyTableDescriptionNoData)}&nbsp;
          <Link
            to={nav.href(routes.manager2.marketing.oneTimeSMSCenter.smsCampaignTemplates, {
              params: { venueKey: venue.urlKey },
            })}
          >
            {formatMessage(oneTimeSMSCenterMessages.emptyTableDescriptionLinkNoData)}
          </Link>
        </>
      )
      emptyStateProps.alt = formatMessage(oneTimeSMSCenterMessages.emptyTableTitleNoData)
    } else if (_.isEmpty(filteredCampaigns)) {
      const descriptionText = formatMessage(
        [0, statusFilters.length].includes(campaignStatus.length)
          ? oneTimeSMSCenterMessages.emptyTableDescriptionSearch
          : oneTimeSMSCenterMessages.emptyTableDescriptionFilters,
        { searchValue }
      )
      emptyStateProps.description = <Text color="secondaryFont">{descriptionText}</Text>
      emptyStateProps.alt = descriptionText
    }
    return emptyStateProps.description ? (
      <EmptyState
        title={emptyStateProps.title}
        image={emptyStateProps.image}
        description={emptyStateProps.description}
        alt={emptyStateProps.alt}
      />
    ) : undefined
  }

  if (!venue || isError) {
    notify({ content: formatMessage(campaignCenterMessages.fetchErrorMessage), type: 'error' })
    return null
  }

  if (isFetching) {
    return (
      <Box pb="m">
        <Loader />
      </Box>
    )
  }

  const data = sortCampaigns((filteredCampaigns as OneTimeSMSCampaignType[]) || [])

  return (
    <>
      <Spreadsheet
        data={data}
        columns={columns}
        autoResetExpanded={false}
        isVerticalScrollBlocked={isMenuOpen}
        noPagination
        renderPreComponent={renderPreComponent}
        placeholder={getEmptyState()}
        noBorder
        pin={document.getElementById(SECONDARY_HEADER_ID)}
      />

      <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.confirmEditModal}>
        <Window>
          <ConfirmEditOneTimeSMSModal onConfirm={onCampaignEditConfirm} />
        </Window>
      </Surface>

      <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.confirmArchiveModal}>
        <Window>
          <ConfirmArchiveOneTimeSMSModal onConfirm={onCampaignArchiveConfirm} closeHref={closeArchiveSMSCampaignHref} />
        </Window>
      </Surface>
    </>
  )
}
