import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { AccessRuleSlideoutSection, DefaultSettingsReportSection } from '../shared'
import { CollapsedReservationCoverLimit, ReservationCoverLimitEdit, type ReservationCoverLimitProps } from './components'
import { ReservationCoverLimitLocales } from './ReservationCoverLimit.locales'
import { ReservationCoverLimitTestId } from './ReservationCoverLimit.testIds'

export function ReservationCoverLimit({ field }: ReservationCoverLimitProps) {
  const { formatMessage } = useLocales()

  const defaultSettings = (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart>
          <ReportLine name={formatMessage(ReservationCoverLimitLocales.defaultsKey)}>
            {formatMessage(ReservationCoverLimitLocales.defaultsValue)}
          </ReportLine>
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )

  return (
    <AccessRuleSlideoutSection
      data-test={ReservationCoverLimitTestId.section}
      title={formatMessage(ReservationCoverLimitLocales.title)}
      description={formatMessage(ReservationCoverLimitLocales.description)}
      subCaption={formatMessage(ReservationCoverLimitLocales.subCaption)}
      contentWhenCollapsed={<CollapsedReservationCoverLimit field={field} />}
      defaultSettings={defaultSettings}
      sectionControllerName="reservationCoverLimit"
      field={field}
    >
      <ReservationCoverLimitEdit field={field} />
    </AccessRuleSlideoutSection>
  )
}
