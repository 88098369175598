import { type Field, useFieldArray, useWatch, useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPhoneNumberInput, IconButton, Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { oneTimeSMSCenterMessages } from '@sevenrooms/mgr-marketing-one-time-sms-center/locales'
import { SendTestSMSDefaultValues, type SendTestSMSSchema } from './TestSMSModalForm.zod'

export interface PhoneNumberInputListProps {
  field: Field<SendTestSMSSchema>
  fieldArrayName: keyof SendTestSMSSchema
  maxEntries: number
}
export function PhoneNumberInputList({ field, fieldArrayName, maxEntries }: PhoneNumberInputListProps) {
  const { formatMessage } = useLocales()

  const { fields: phoneNumberFields, remove, append } = useFieldArray(field, fieldArrayName)

  const { resetField } = useFormContext()

  const phoneNumbers = useWatch(field.prop(fieldArrayName))

  return (
    <>
      <VStack rowGap="s">
        {phoneNumberFields.map((phoneNumber, index) => (
          <HStack key={phoneNumber.id}>
            <FormPhoneNumberInput field={field.prop(`${fieldArrayName}.${index}.number`)} />
            <IconButton
              borderType="none-round"
              icon="VMSWeb-trash"
              onClick={() => {
                if (phoneNumberFields.length === 1) {
                  resetField(fieldArrayName)
                } else {
                  remove(index)
                }
              }}
            />
          </HStack>
        ))}
      </VStack>
      <VStack rowGap="m" mt="sm" alignItems="start">
        {phoneNumbers.length < maxEntries && (
          <Button
            data-test="add-phone-number-button"
            noPadding
            variant="tertiary"
            onClick={() => {
              append({ number: SendTestSMSDefaultValues.phoneNumbers[0]?.number as string })
            }}
            disabled={!phoneNumbers[phoneNumbers.length - 1]?.number}
          >
            {formatMessage(oneTimeSMSCenterMessages.sendTestModalAddButtonLabel)}
          </Button>
        )}
        <HStack columnGap="s">
          <Icon name="VMSWeb-warning" color="warning" size="sm" />
          <Text>{formatMessage(oneTimeSMSCenterMessages.sendTestModalWarning)}</Text>
        </HStack>
      </VStack>
    </>
  )
}
