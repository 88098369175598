import { defineMessages } from '@sevenrooms/core/locales'

export const iVvyTableMappingLocales = defineMessages({
  name: {
    id: 'iVvySettings.iVvyTableMapping.name',
    defaultMessage: 'iVvy',
  },
  title: {
    id: 'iVvySettings.iVvyTableMapping.title',
    defaultMessage: 'iVvy',
  },
  description: {
    id: 'iVvySettings.iVvyTableMapping.description',
    defaultMessage: 'Map iVvy Spaces to SevenRooms tables. To fetch the most up to date iVvy Spaces, refresh the page. <a>Learn More</a>',
  },
  tryAgain: {
    id: 'iVvySettings.tryAgain',
    defaultMessage: 'Try Again',
  },
  saveAndImportReservations: {
    id: 'iVvySettings.button.saveAndImportReservations',
    defaultMessage: 'Save and Import Reservations',
  },
  saveChanges: {
    id: 'iVvySettings.button.saveChanges',
    defaultMessage: 'Save Changes',
  },
  syncChanges: {
    id: 'iVvySettings.button.syncChanges',
    defaultMessage: 'Sync Changes',
  },
  notificationSyncSuccessTitle: {
    id: 'iVvySettings.notification.sync.success.title',
    defaultMessage: 'iVvy sync successful.',
  },
  notificationSyncSuccessDescription: {
    id: 'iVvySettings.notification.save.success.description',
    defaultMessage: 'No spaces were added or removed.',
  },
  notificationSyncSuccessAddedDescription: {
    id: 'iVvySettings.notification.save.success.added.description',
    defaultMessage: '{count, plural, one {{items} was added} other {{items} were added}}.',
  },
  notificationSyncSuccessRemovedDescription: {
    id: 'iVvySettings.notification.save.success.removed.description',
    defaultMessage: '{count, plural, one {{items} was removed} other {{items} were removed}}.',
  },
  notificationSyncFailedTitle: {
    id: 'iVvySettings.notification.sync.failed.title',
    defaultMessage: ' iVvy sync was unsuccessful.',
  },
  notificationSyncFailedCredentialsTitle: {
    id: 'iVvySettings.notification.sync.failed.credentials.title',
    defaultMessage: 'Sorry, we are unable to sync iVvy Spaces due to invalid credentials.',
  },
  notificationSyncFailedDescriptionPrefix: {
    id: 'iVvySettings.notification.save.failed.description',
    defaultMessage: 'Table mappings last synced {time}. ',
  },
  notificationSyncFailedDescription: {
    id: 'iVvySettings.notification.save.failed.description',
    defaultMessage: '{prefix}To fix credentials, please <a>submit a support ticket</a>.',
  },
  saveAndImportModalHeader: {
    id: 'iVvySettings.saveAndImportModal.header',
    defaultMessage: 'Warning',
  },
  saveAndImportModalTitle: {
    id: 'iVvySettings.saveAndImportModal.title',
    defaultMessage: 'Are you sure you’re ready to save and import reservations now?',
  },
  saveAndImportModalDescription: {
    id: 'iVvySettings.saveAndImportModal.description',
    defaultMessage:
      'You’ll want to make sure all tables are mapped before saving. Once reservations are imported, table mappings will need to be manually changed.',
  },
  saveAndImportModalRemapReservationTablesWarning: {
    id: 'iVvySettings.saveAndImportModal.remapReservationTablesWarning',
    defaultMessage:
      'Synchronizing current reservations could cause overbooking of tables that have already been booked in the same time slots.',
  },
  saveAndImportModalRemapReservationTablesLabel: {
    id: 'iVvySettings.saveAndImportModal.remapReservationTablesLabel',
    defaultMessage: 'Synchronize tables for current venue reservations.',
  },

  saveAndImportModalBack: {
    id: 'iVvySettings.saveAndImportModal.back',
    defaultMessage: 'Go Back to Mapping',
  },
  saveAndImportModalSave: {
    id: 'iVvySettings.saveAndImportModal.save',
    defaultMessage: 'Save and Start Import',
  },
  notificationSyncInProgressTitle: {
    id: 'iVvySettings.notification.sync.inProgress.title',
    defaultMessage: 'Reservation import in progress',
  },
  notificationSyncInProgressDescription: {
    id: 'iVvySettings.notification.sync.inProgress.description',
    defaultMessage: 'Import could take up to 4 hours. Changes cannot be made while reservation import is in progress.',
  },
} as const)
