import React from 'react'
import styled from 'styled-components'
import ManagerStyles from 'mgr/layout/Styles'

export const MarketingWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const MarketingContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: ${ManagerStyles.DayviewToolbarHeight - 2}px;
`

export const SlideoutContent = styled.div`
  padding-right: 0;
  padding-left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const DisplayArea = styled.div`
  background: #ffffff;
  box-shadow: ${props => props.theme.shadow.six};
  height: 100%;
  overflow: scroll;
  padding-bottom: 10px;
`

export const ActionArea = styled.div`
  height: 80px;
`

export const LinkButton = styled.div`
  background: ${({ disabled, theme }) => (disabled ? theme.colors.deactivated : theme.color.teal)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => disabled && 'pointer-events: none;'},
  border-radius: 5px;
  color: ${props => props.theme.color.white};
  display: block;
  font-size: ${props => props.theme.fontSize.button};
  font-weight: bold;
  height: 64px;
  line-height: 64px;
  margin: 8px;
  text-align: center;
  text-trasform: uppercase;
`
