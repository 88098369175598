import React from 'react'
import styled, { css } from 'styled-components'
import Table from 'svr/component-lib/Generic/Tables/BaseTable'
import { TextCell, platformCell, dateCell, ratingsCell } from 'svr/component-lib/Generic/Tables/Cells'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { openReview, openSearch } from 'mgr/pages/single-venue/marketing/actions/Reviews'
import { connect } from 'react-redux'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'

const ReviewsListContainer = styled.div`
  flex: 1;
`

const customTableContainerStyles = css`
  padding: 0;
  background-color: ${props => props.theme.color.white};
`

const ReviewsList = ({
  reviews,
  isLoading,
  onGuestSatisfactionPageChange,
  openReview,
  openSearch,
  page,
  pageSize,
  pages,
  smartRowClick,
  onGuestSatisfactionSort,
  locale,
}) => (
  <ReviewsListContainer>
    <Table
      data={reviews}
      loading={isLoading}
      enableTooltips
      rowClick={cellInfo => (cellInfo?.original.source === 'GOOGLEREVIEWS' ? openReview(cellInfo) : smartRowClick(cellInfo))}
      customTableContainerStyles={customTableContainerStyles}
      manual
      onSortedChange={onGuestSatisfactionSort}
      onPageChange={onGuestSatisfactionPageChange}
      page={page}
      pageSize={pageSize}
      pages={pages}
      columns={[
        {
          Header: 'PLATFORM',
          accessor: 'review_site',
          Cell: platformCell,
        },
        {
          Header: 'DATE RECEIVED',
          accessor: 'date',
          Cell: dateCell.bind(null, locale, false, null),
        },
        {
          Header: 'DETAILS',
          accessor: 'notes',
          Cell: cellInfo => (
            <TextCell>
              {cellInfo.original.reply?.comment && (
                <>
                  <Icon name="VMSWeb-reply" />
                  &nbsp;
                </>
              )}
              <span
                data-test={`${cellInfo.original.dataTestId}-${cellInfo.column.id}`}
                dangerouslySetInnerHTML={{ __html: cellInfo.value || '' }}
              />
            </TextCell>
          ),
          sortable: false,
        },
        {
          Header: 'RATING',
          accessor: 'rating',
          Cell: ratingsCell,
        },
        {
          Header: 'REVIEWER ID',
          accessor: 'author',
        },
        {
          Header: 'LINK',
          Cell: ({ original }) =>
            original.actual_name ? (
              <TextCell>
                <Anchor
                  onClick={e => {
                    e.stopPropagation()
                    smartRowClick({ original })
                  }}
                >
                  {original.actual_name}
                </Anchor>
              </TextCell>
            ) : (
              <IconButton
                borderType="none-round"
                icon="VMSWeb-link"
                onClick={e => {
                  e.stopPropagation()
                  openReview({ original })
                  openSearch(original)
                }}
              />
            ),
        },
      ]}
    />
  </ReviewsListContainer>
)

const mapDispatchToProps = {
  openSearch,
  openReview,
}

export default connect(null, mapDispatchToProps)(ReviewsList)
