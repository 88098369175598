import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { isDollarAmountType } from '@sevenrooms/core/api'
import type { PromoCode, PromoRangeApplyType } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { SimplePopover } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Spreadsheet,
  BasicCell,
  basicCellSortFn,
  Window,
  type DataTableColumn,
  type SortingRule,
} from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { usePromoCodeManagementResourcesContext } from '../hooks/usePromoCodeManagementResourcesContext'
import { promoCodeManagementMessages } from '../locales'
import { StatusCell, statusCellSortFn } from './components/StatusCell'
import { DeletePromoCodeModal } from './DeletePromoCodeModal'

const formatWidgets = (promoCode: PromoCode, platformDisplayNames: Record<string, string>) =>
  promoCode.platformApplyTypes.map(id => platformDisplayNames[id]).join(', ')

interface PromoCodeTableProps {
  venue: Venue
  promoCodeList: PromoCode[]
  sorted?: SortingRule<string>[]
  onSortedChange?: (data: SortingRule<string>[]) => void
  onPromoCodeDelete: (promoCode: PromoCode) => void
}

export function PromoCodeTable({ venue, promoCodeList, sorted, onSortedChange, onPromoCodeDelete }: PromoCodeTableProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { dateRangeDisplayNames, codeTypeDisplayNames, platformApplyTypeDisplayNames } = usePromoCodeManagementResourcesContext()
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCode>()

  const formatDateRange = useCallback(
    (from: Date | null, to: Date | 'infinite', promoType: PromoRangeApplyType) => {
      if (promoType === 'NO_EXPIRATION') {
        return formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameDateRangeDefault)
      }
      const startDateFormatted = from
        ? moment(from).format('L')
        : formatMessage(promoCodeManagementMessages.promoCodesTableDateRangeColumnValueUnknown)
      const endDateFormatted =
        to !== 'infinite'
          ? moment(to).format('L')
          : formatMessage(promoCodeManagementMessages.promoCodesTableDateRangeColumnValueIndefinite)
      return `${startDateFormatted} - ${endDateFormatted}`
    },
    [formatMessage]
  )

  const formatMinMaxDiscount = useCallback(
    (min: number | null, max: number | null): string => {
      const values = []
      if (min) {
        values.push(
          formatMessage(promoCodeManagementMessages.promoCodesTableMinSubtotalValue, {
            value: FormatService.formatCurrency(min, venue.currencyCode),
          })
        )
      }
      if (max) {
        values.push(
          formatMessage(promoCodeManagementMessages.promoCodesTablePromoValueCap, {
            value: FormatService.formatCurrency(max, venue.currencyCode),
          })
        )
      }
      return values.join(' | ')
    },
    [formatMessage, venue.currencyCode]
  )

  const formatUsage = useCallback(
    (promoCode: PromoCode) => {
      const count = `${promoCode.uses}`.toLocaleString()
      const total = promoCode.limit
        ? `${promoCode.limit}`.toLocaleString()
        : formatMessage(promoCodeManagementMessages.promoCodesTableUsageValueUnlimited)
      return formatMessage(promoCodeManagementMessages.promoCodesTableUsageValue, { count, total })
    },
    [formatMessage]
  )

  const onDelete = useCallback(
    (promoCode: PromoCode) => {
      setSelectedPromoCode(promoCode)
      nav.push(routes.manager2.marketing.promoCodesManagement.deleteModal, { params: { venueKey: venue.urlKey } })
    },
    [nav, venue.urlKey]
  )

  const columns = useMemo<DataTableColumn<PromoCode>[]>(
    () => [
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameName),
        attach: 'left',
        key: 'name',
        render: (value: PromoCode, index: number) => (
          <BasicCell
            primary={value.name || value.code}
            secondary={value.promoCodeDescription || '-'}
            data-test={`promo-code-${index}-name`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        maxWidth: 250,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameCode),
        key: 'code',
        render: (value: PromoCode, index: number) => <BasicCell primary={value.code} data-test={`promo-code-${index}-code`} />,
        textDisplay: 'flex',
        headerAlign: 'start',
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameStatus),
        key: 'status',
        render: (value: PromoCode, index: number) => <StatusCell status={value.status} data-test={`promo-code-${index}-status`} />,
        textDisplay: 'flex',
        headerAlign: 'start',
        minWidth: 90,
        maxWidth: 110,
        sortable: true,
        sortType: statusCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameDiscountAmount),
        key: 'amount',
        render: (value: PromoCode, index: number) => (
          <BasicCell
            primary={
              isDollarAmountType(value.promoType)
                ? FormatService.formatCurrency(value.promoValue, venue.currencyCode)
                : formatMessage(promoCodeManagementMessages.promoCodesTableColumnValuePercentageDiscountAmount, { value: value.promoValue })
            }
            secondary={formatMinMaxDiscount(value.minSubtotal, value.promoValueCap)}
            data-test={`promo-code-${index}-amount`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameDateRange),
        key: 'dateRange',
        render: (value: PromoCode, index: number) => (
          <BasicCell
            primary={formatDateRange(value.startDateTime, value.expires, value.promoRangeApplyType)}
            secondary={value.promoRangeApplyType !== 'NO_EXPIRATION' ? dateRangeDisplayNames[value.promoRangeApplyType] : ''}
            data-test={`promo-code-${index}-date-range`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        minWidth: 160,
        maxWidth: 210,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameUsedTotal),
        key: 'usage',
        render: (value: PromoCode, index: number) => <BasicCell primary={formatUsage(value)} data-test={`promo-code-${index}-usage`} />,
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
        minWidth: 180,
        maxWidth: 180,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameLocationUsed),
        key: 'venue',
        render: (value: PromoCode, index: number) => (
          <BasicCell
            primary={formatWidgets(value, platformApplyTypeDisplayNames)}
            secondary={codeTypeDisplayNames[value.codeType]}
            data-test={`promo-code-${index}-location`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        minWidth: 180,
        maxWidth: 180,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(promoCodeManagementMessages.promoCodesTableColumnNameAction),
        key: 'action',
        attach: 'right',
        render: (value: PromoCode, index: number) => (
          <SimplePopover
            scrollLock
            onOpen={() => {
              setIsPopoverOpen(true)
            }}
            onClose={() => {
              setIsPopoverOpen(false)
            }}
            data-test={`promo-code-menu-${index}`}
          >
            <SimplePopover.PopoverItem
              key="promo-codes-item-edit"
              onClick={() => {
                nav.push(routes.manager2.marketing.promoCode, {
                  params: { venueKey: venue.urlKey, promoCodeKey: value.key },
                })
              }}
              iconName="VMSWeb-edit"
              text={formatMessage(promoCodeManagementMessages.promoCodesTableMenuItemEdit)}
              data-test={`promo-code-menu-edit-${index}`}
            />
            <SimplePopover.PopoverItem
              key="promo-codes-item-delete"
              onClick={() => {
                onDelete(value)
              }}
              iconName="VMSWeb-trash"
              text={formatMessage(promoCodeManagementMessages.promoCodesTableMenuItemDelete)}
              data-test={`promo-code-menu-delete-${index}`}
            />
          </SimplePopover>
        ),
      },
    ],
    [
      formatMessage,
      venue.currencyCode,
      venue.urlKey,
      formatMinMaxDiscount,
      formatDateRange,
      dateRangeDisplayNames,
      formatUsage,
      platformApplyTypeDisplayNames,
      codeTypeDisplayNames,
      nav,
      onDelete,
    ]
  )

  return (
    <Box data-test="sr-promo-codes-table">
      <Spreadsheet
        data={promoCodeList}
        columns={columns}
        sorted={sorted}
        onSortedChange={onSortedChange}
        isVerticalScrollBlocked={isPopoverOpen}
      />
      <Surface destination={routes.manager2.marketing.promoCodesManagement.deleteModal}>
        <Window>
          <DeletePromoCodeModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.promoCodesManagement.deleteModal, {
              params: { venueKey: venue.urlKey },
            })}
            onConfirm={() => {
              if (selectedPromoCode) {
                onPromoCodeDelete(selectedPromoCode)
              }
            }}
          />
        </Window>
      </Surface>
    </Box>
  )
}
