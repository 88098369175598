import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  accountAndAuthentication: {
    id: 'paymentSettings.CybersourceRedux3ds.accountAndAuthentication',
    defaultMessage: 'Account & Authentication',
  },
  merchantId: {
    id: 'paymentSettings.CybersourceRedux3ds.merchantId',
    defaultMessage: 'Merchant ID',
  },
  merchantIdLabel: {
    id: 'paymentSettings.CybersourceRedux3ds.merchantId',
    defaultMessage: 'This can be found in your <a>Business Center.</a>',
  },
  apiKeyId: {
    id: 'paymentSettings.CybersourceRedux3ds.apiKeyId',
    defaultMessage: 'REST API Key',
  },
  apiKeyIdLabel: {
    id: 'paymentSettings.CybersourceRedux3ds.apiKeyIdLabel',
    defaultMessage: 'Located in your <a>Business Center</a> under Payment Configuration.',
  },
  apiKey: {
    id: 'paymentSettings.CybersourceRedux3ds.apiKey',
    defaultMessage: 'REST API Shared Secret',
  },
  apiKeyLabel: {
    id: 'paymentSettings.CybersourceRedux3ds.apiKeyLabel',
    defaultMessage: 'Located in your <a>Business Center</a> under Payment Configuration.',
  },
  cybersourceAccount: {
    id: 'paymentSettings.CybersourceRedux3ds.cybersourceAccount',
    defaultMessage: 'Cybersource Account',
  },
} as const)
