import React from 'react'
import _ from 'lodash'
import TextInput, { ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { TierTitle, Rename, TierDelete } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import { AutotagTierAdditionalOptions } from './AutotagTierAdditionalOptions.jsx'

export const dropStyle = {
  display: 'inline-block',
  margin: '0 5px',
  verticalAlign: 'middle',
  lineHeight: 1.5,
}

export const dropProps = {
  useOutsideLabel: true,
  noHeader: true,
  borderAreaStyle: {
    border: '1px solid #dedede',
  },
}

export class BaseTier extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      renaming: !this.props.tier.name_display,
    }
    this.toggleEditing = this.toggleEditing.bind(this)
    this.toggleRename = this.toggleRename.bind(this)
    this.updateTier = this.updateTier.bind(this)
    this.updateTierAttribute = this.updateTierAttribute.bind(this)
    this.removeTier = this.removeTier.bind(this)

    this.validateTitle = this.validateTitle.bind(this)
    this.titleInput = null
    this.validTitle = true
    this.originalName = this.props.tier.name_display
  }

  componentDidMount() {
    if (this.titleInput && this.state.renaming) {
      this.titleInput.focus()
    }
  }

  validateAmount() {
    this.validAmount = this.amountInput.isValid() === true
    const tierError = this.validAmount ? null : this.props.index
    this.props.setTierError(tierError)
  }

  validateTitle() {
    if (!this.titleInput || (!this.props.tier.name_display && this.originalName === '')) {
      return
    }
    this.validTitle = this.titleInput.isValid() === true
    const tierError = this.validTitle ? null : this.props.index
    this.props.setTierError(tierError)
  }

  updateTier(name, value) {
    this.props.updateTier(name, value, this.props.index)
  }

  removeTier() {
    this.props.toggleDeleteWarning()
  }

  updateTierAttribute(name, value, castNumber) {
    this.props.updateTierAttribute(name, value, this.props.index, castNumber)
  }

  updateLockedTierAttributes(name, value, castNumber) {
    this.props.updateLockedTierAttributes(name, value, castNumber)
  }

  toggleEditing() {
    this.props.selectTier(this.props.index)
    this.validateTitle()
  }

  toggleRename() {
    this.validateTitle()
    if (!this.validTitle) {
      return
    }
    this.setState(state => ({ renaming: !state.renaming }))

    window.metric.track('AutoTagging.ChangeTagName')
  }

  getSiblingTierName(tierType) {
    return _.find(this.props.siblingTiers, tier => tier.tier_type === tierType).name_display
  }

  fetchTitle() {
    const name = this.props.tier.name_display
    return this.state.renaming ? (
      <TierTitle>
        <TextInput
          value={name}
          placeholder="Enter tag name"
          showLabel={false}
          name="name_display"
          isValid={this.validTitle}
          validator={ValidatorTypes.notEmpty}
          ref={e => {
            this.titleInput = e
          }}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '250px',
            margin: '0 5px',
          }}
          onBlur={this.validateTitle}
          onChange={value => this.updateTier('name_display', value)}
        />
        <Rename onClick={this.toggleRename}>done</Rename>
      </TierTitle>
    ) : (
      <TierTitle>
        {name} <Rename onClick={this.toggleRename}>rename</Rename>
        {this.props.removeable ? (
          <TierDelete onClick={this.removeTier}>
            <StyledVmsIconS>{VmsIcons.Close}</StyledVmsIconS>
          </TierDelete>
        ) : null}
      </TierTitle>
    )
  }

  fetchAdditionalOptions() {
    return (
      <AutotagTierAdditionalOptions
        tier={this.props.tier}
        autotagPerks={this.props.autotagPerks}
        updatePerksByTag={this.props.updatePerksByTag}
        updateNotesByTag={this.props.updateNotesByTag}
        perks={this.props.perks}
        notes={this.props.notes}
      />
    )
  }
}

export default BaseTier
