import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  ineligibleLocationBannerTitle: {
    id: 'posiSettings.SquareLocationSelectionCard.ineligibleLocationBannerTitle',
    defaultMessage: 'Square for Restaurants Upgrade Needed',
  },
  ineligibleLocationBannerDescription: {
    id: 'posiSettings.SquareLocationSelectionCard.ineligibleLocationBannerDescription',
    defaultMessage:
      'Square does not support the use of this integration for the subscription package associated with the selected location. Visit your <a>Square Dashboard</a> to upgrade your subscription or disconnect and select a new location.',
  },
} as const)
