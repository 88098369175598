import { useMemo } from 'react'
import { z } from '@sevenrooms/core/form'

const userAccountsFormSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().trim().email().min(3),
  jobTitle: z.string().trim(),
  receiveFeedbackSummaryEmail: z.boolean(),
  isMfaEnabled: z.boolean(),

  accessClientTab: z.number().min(0).max(3).default(0),
  accessMarketingTools: z.number().min(0).max(3).default(0),
  accessPrivateLine: z.number().min(0).max(3).default(0),
  viewReporting: z.number().min(0).max(3).default(0),

  addRemoveClientTagsFromClientProfiles: z.number().min(0).max(3).default(0),
  manageClientsTagsList: z.number().min(0).max(3).default(0),
  exportClientData: z.number().min(0).max(3).default(0),
  manageReservationTagsLists: z.number().min(0).max(3).default(0),
  manageGlobalAutoTags: z.number().min(0).max(3).default(0),
  viewAllClientsContactInfo: z.number().min(0).max(3).default(0),
  viewClientsSpendAggregatesData: z.number().min(0).max(3).default(0),
  viewClientsAssociatedWithAllVenuesInTheGroup: z.number().min(0).max(3).default(0),
  viewPosCheckData: z.number().min(0).max(3).default(0),

  createEditEmailCampaignsWithGlobalTags: z.number().min(0).max(3).default(0),
  writeMessageInPrivateLine: z.number().min(0).max(3).default(0),

  administrateUserPermissions: z.number().min(0).max(3).default(0),
  administrateGeneralSettings: z.number().min(0).max(3).default(0),
  administrateWidgetSettings: z.number().min(0).max(3).default(0),
})
export const useUserAccountsFormSchema = () => useMemo(() => userAccountsFormSchema, [])

export type userAccountsFormSchemaType = z.infer<typeof userAccountsFormSchema>
