import type { TaxRate } from '@sevenrooms/core/domain'
import type { ChargeSettingsForm } from './chargeSettingsForm'

export interface ChargeParams {
  defaultServiceCharge: number
  defaultGratuity: number
  chargeDetails: Partial<ChargeSettingsForm>
  taxRates?: TaxRate[]
}

export interface ChargeRates {
  serviceChargeRate: number
  taxRate: number
  gratuityRate: number
}
export function getChargeRates({ chargeDetails, ...params }: ChargeParams): ChargeRates {
  let serviceChargeRate = 0
  if (chargeDetails.applyServiceCharge) {
    if (chargeDetails.serviceChargeType === 'DEFAULT_SERVICE_CHARGE') {
      serviceChargeRate = params.defaultServiceCharge
    } else {
      serviceChargeRate = chargeDetails.serviceChargePercent ?? 0
    }
  }

  let taxRate = 0
  if (chargeDetails.applyTax) {
    taxRate = params.taxRates?.find(tax => tax.id === chargeDetails.taxId)?.rate ?? 0
  }

  let gratuityRate = 0
  if (chargeDetails.applyGratuity) {
    if (chargeDetails.gratuityType === 'DEFAULT_GRATUITY') {
      gratuityRate = params.defaultGratuity
    } else if (chargeDetails.gratuityType === 'SPECIFIC_GRATUITY') {
      gratuityRate = chargeDetails.gratuityPercent ?? 0
    }
  }

  serviceChargeRate /= 100
  taxRate /= 100
  gratuityRate /= 100
  return { serviceChargeRate, taxRate, gratuityRate }
}

export function applyCharges(subTotal: number, rates: ChargeRates): number {
  let total = subTotal + subTotal * rates.serviceChargeRate
  total += total * rates.taxRate
  total += subTotal * rates.gratuityRate
  return total
}
