import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { insightsMessages } from '../locales'

export const variantTitle: { [key: string]: { id: string; defaultMessage: string } } = {
  new: insightsMessages.statusNewLabel,
  tracking: insightsMessages.statusTrackingLabel,
  optimized: insightsMessages.statusOptimizedLabel,
  declined: insightsMessages.statusDeclinedLabel,
  empty: insightsMessages.statusEmptyLabel,
}

export const variantShortTitle: { [key: string]: { id: string; defaultMessage: string } } = {
  ...variantTitle,
  new: insightsMessages.statusNewLabelShort,
}

export const variantColor: { [key: string]: ThemeColor } = {
  new: 'attentionNew',
  tracking: 'success',
  optimized: 'success',
  empty: 'success',
  declined: 'secondaryFont',
}

export const variantIcon: { [key: string]: string } = {
  new: 'new',
  tracking: 'optimized',
  optimized: 'optimized',
  declined: 'declined',
}
