import { defineMessages } from '@sevenrooms/core/locales'

export const partySizeRangePickerMessages = defineMessages({
  partySizeMin: {
    id: 'settings.reservations.partySize.min',
    defaultMessage: 'Party size min:',
  },
  partySizeMinInvalid: {
    id: 'settings.reservations.partySize.min.invalid',
    defaultMessage: 'Party size min must be less than or equal to party max',
  },
  partySizeMax: {
    id: 'settings.reservations.partySize.max',
    defaultMessage: 'Party size max:',
  },
} as const)
