import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { toggleDeactivateWarning } from 'mgr/pages/single-venue/marketing/actions/Autotags'

export const ModalFrame = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: 8px 0 25px 0 rgba(30, 37, 47, 0.12);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  left: 50%;
  margin-left: -354px;
  position: fixed;
  top: 175px;
  width: 325px;
  z-index: 501;
`

export const WarningHeader = styled.div`
  display: flex;
  align-items: center;
  background: #ff963e;
  flex-direction: row;
  justify-content: center;
  height: 106px;
  margin: 0px;
  border-radius: 6px 6px 0px 0px;
`

const ContentContainer = styled.div`
  margin: 20px;
  margin-top: 0px
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h1`
  text-align: center;
`

const Deactivate = styled.div`
  padding: ${props => props.theme.gutter.double};
  background: #ff963e;
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
`

const Cancel = styled.div`
  color: #ff963e;
  border: none;
  height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
`
const Message = styled.div`
  font-size: '13px';
  color: '#565C63';
  margin-bottom: 15px;
  text-align: center;
`
const MessageWrapper = styled.div``
const MessageTitle = styled.div`
  font-size: '13px';
  color: '#565C63';
  text-align: center;
`
const MessageBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
  color: #888c92;
`
const HeaderText = styled.div`
  margin: 0px 20px 20px;
  text-align: center;
  font-weight: bold;
`

class DeactivateConfirmation extends React.PureComponent {
  render() {
    const { confirmFunction, associatedPerks, associatedCampaigns, cancelFunction } = this.props

    return (
      <ModalFrame>
        <WarningHeader>
          <Title style={{ fontSize: '62px', color: '#FFFFFF' }}>{'\u24D8'}</Title>
        </WarningHeader>
        <ContentContainer>
          <HeaderText>
            <span style={{ fontSize: '16px', color: '#FF963E' }}>ARE YOU SURE?</span>
          </HeaderText>
          <Message>Deactivating or deleting this Auto-tag will also deactivate the following:</Message>
          {associatedCampaigns.length > 0 && (
            <MessageWrapper>
              <MessageTitle>Campaign(s):</MessageTitle>
              <MessageBody>
                {associatedCampaigns.map(name => (
                  <span key={name}>{name}</span>
                ))}
              </MessageBody>
            </MessageWrapper>
          )}
          {associatedPerks.length > 0 && (
            <MessageWrapper>
              <MessageTitle>Perk(s):</MessageTitle>
              <MessageBody>
                {associatedPerks.map(name => (
                  <span key={name}>{name}</span>
                ))}
              </MessageBody>
            </MessageWrapper>
          )}
          <Deactivate
            onClick={() => {
              confirmFunction()
            }}
          >
            Confirm Action
          </Deactivate>
          <Cancel
            onClick={() => {
              cancelFunction()
            }}
          >
            Cancel
          </Cancel>
        </ContentContainer>
      </ModalFrame>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      cancelFunction: toggleDeactivateWarning,
    },
    dispatch
  )

const mapStateToProps = (state, { isCustomAutoTag }) => {
  const associatedCampaignsAndPerks = state.autotags.editor.associatedCampaignsAndPerks.data
  const associatedPerks = associatedCampaignsAndPerks.perks
  const associatedCampaigns = associatedCampaignsAndPerks.campaigns

  return {
    associatedPerks,
    associatedCampaigns,
    confirmFunction: state.autotags.editor.deactivateFunction,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateConfirmation)
