/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

export type Site =
  | 'facebook'
  | 'foursquare'
  | 'google'
  | 'opentable'
  | 'opentableusa'
  | 'sevenrooms'
  | 'tripadvisor'
  | 'yelp'
  | 'bookatable'
  | 'openrice'
  | 'grubhub'
  | 'thefork'

export interface Totals {
  negative: number
  neutral: number
  positive: number
  total: number
  recommend: number
  notRecommend: number
}

export interface FeedbackRating {
  1?: number
  2?: number
  3?: number
  4?: number
  5?: number
}

export interface FeedbackRecommendation {
  // eslint-disable-next-line camelcase
  not_recommend?: number
  recommend?: number
}

// https://bitbucket.org/sevenrooms/sevenrooms/src/8e39bcf2d27039cdebf75d517439db547ce4284a/application/lib/sr/db/model/ndb/venueintegrationsettings.py?at=master#lines-36
export interface FeedbackBySite {
  facebook?: FeedbackRecommendation // Facebook no longer has 1 - 5 ratings. recommend or not_recommend. See MA-1002
  foursquare?: FeedbackRating
  google?: FeedbackRating
  opentable?: FeedbackRating
  opentableusa?: FeedbackRating
  sevenrooms?: FeedbackRating
  tripadvisor?: FeedbackRating
  yelp?: FeedbackRating
  bookatable?: FeedbackRating
  openrice?: FeedbackRating
  grubhub?: FeedbackRating
  thefork?: FeedbackRating
}

export const computeReviewsParams = (
  state: { reviews: { data: { stats: FeedbackBySite } } },
  sitesforStarAggregates: Site[],
  sitesForTotals: Site[]
) => ({
  reviewsByChannel: state.reviews.data.stats,
  sitesforStarAggregates,
  sitesForTotals,
})

export const getChannels = ({
  sitesforStarAggregates,
  sitesForTotals,
  totals,
}: {
  sitesforStarAggregates: Site[]
  sitesForTotals: Site[]
  totals: Totals
}): { [site: string]: Totals } => {
  const channels: { [site: string]: Totals } = {}

  const uniqueSites = [...sitesforStarAggregates, ...sitesForTotals].filter((site, index, arr) => arr.indexOf(site) === index)

  uniqueSites.forEach(site => {
    channels[site] = { ...totals }
  })

  return channels
}

export const computeReviewsStats = ({
  reviewsByChannel = {},
  sitesforStarAggregates,
  sitesForTotals,
}: {
  reviewsByChannel: FeedbackBySite
  sitesforStarAggregates: Site[]
  sitesForTotals: Site[]
}) => {
  const numStars: { [key: string]: number } = {
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  }

  const totals = {
    recommend: 0,
    notRecommend: 0,
    positive: 0,
    neutral: 0,
    negative: 0,
    total: 0,
  }

  const channels = getChannels({ sitesforStarAggregates, sitesForTotals, totals })

  for (const [channel, channelReviews] of Object.entries(reviewsByChannel)) {
    if (channels[channel] && channel === 'facebook') {
      for (const [starStr] of Object.entries(channelReviews)) {
        const numReviews = channelReviews[starStr]
        if (sitesforStarAggregates.includes('facebook')) {
          if (starStr === 'recommend') {
            totals.recommend += numReviews
            totals.positive += numReviews
            channels.facebook!.positive += numReviews
            numStars[5] += numReviews
          } else if (starStr === 'not_recommend') {
            totals.notRecommend += numReviews
            totals.negative += numReviews
            channels.facebook!.negative += numReviews
            numStars[1] += numReviews
          }
          totals.total += numReviews
        }
        if (sitesForTotals.includes('facebook')) {
          if (starStr === 'recommend' || starStr === 'not_recommend') {
            channels.facebook!.total += numReviews
          }
        }
      }
    } else if (channels[channel]) {
      const feedback: FeedbackRating | FeedbackRecommendation = reviewsByChannel[channel as Site]!
      Object.keys(feedback).forEach(starStr => {
        const star = parseInt(starStr, 10)
        const numReviews = feedback[starStr as keyof typeof feedback]

        if (sitesforStarAggregates.includes(channel as Site)) {
          if (star > 3) {
            totals.positive += numReviews
            channels[channel]!.positive += numReviews
          } else if (star === 3) {
            totals.neutral += numReviews
            channels[channel]!.neutral += numReviews
          } else {
            totals.negative += numReviews
            channels[channel]!.negative += numReviews
          }
          totals.total += numReviews
          numStars[star] += numReviews
        }
        if (sitesForTotals.includes(channel as Site)) {
          channels[channel]!.total += numReviews
        }
      })
    }
  }

  const percentages = {
    positive: parseFloat(`${(totals.positive / totals.total) * 100 || 0}`).toFixed(2),
    neutral: parseFloat(`${(totals.neutral / totals.total) * 100 || 0}`).toFixed(2),
    negative: parseFloat(`${(totals.negative / totals.total) * 100 || 0}`).toFixed(2),
  }

  const recommendations = {
    recommend: totals.recommend,
    notRecommend: totals.notRecommend,
  }

  const weightedStars = Object.keys(numStars).map(star => [star, numStars[star]! / totals.total])
  const overallAverage = parseFloat(`${weightedMean(weightedStars) || 0} `).toFixed(2)

  return {
    overall: {
      total: totals.total,
      average: overallAverage,
    },
    breakdown: {
      percentages,
      numStars,
      channels,
      recommendations,
    },
  }
}

export const filterChannelsByConfiguredSites = (
  channels: {
    [site: string]: Totals
  },
  configuredSites: Site[]
) => Object.fromEntries(Object.entries(channels).filter(([key]) => key === 'sevenrooms' || configuredSites.includes(key as Site)))

// move to util
export const weightedMean = (weightedValues: (string | number)[][] = []) => {
  const totalWeight = weightedValues.reduce((sum, weightedValue) => sum + (weightedValue[1] as number), 0)
  return weightedValues.reduce(
    (mean, weightedValue) => mean + ((weightedValue[0] as number) * (weightedValue[1] as number)) / totalWeight,
    0
  )
}

export const selectReviewsStats = createShallowEqualSelector(computeReviewsParams, computeReviewsStats)
