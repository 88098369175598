export const SUPPORTED_POS_LIST_URL = '/help?return_to=/hc/en-us/articles/360012572271'
export const SUPPORTED_PAYMENT_SYSTEMS_LIST_URL = '/help?return_to=/hc/en-us/articles/360012345972'
export const SCHEDULING_RES_SUMMARY_EMAIL_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/360005091792'
export const SCHEDULING_FEEDBACK_SUMMARY_EMAIL_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/5742831165595'
export const CREATE_EVENT_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/360007507091'
export const CREATE_UPGRADE_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/360007546972'
export const CONFIG_ACCESS_RULE_UPGRADES_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/7928546730011'
export const CONFIG_BUNDLED_UPGRADES_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/7927927091867#h_01GAVKF04RHWHD6EAF898AN6PC'
export const CONFIG_FEEDBACK_EMAIL_INSTRUCTIONS_URL = '/help?return_to=/hc/en-us/articles/360006202092'
export const GOOGLE_RESERVE_ENABLED_INSTRUCTIONS_URL =
  '/help?return_to=hc/en-us/articles/12976811778843-Including-Google-Reserve-in-Your-Access-Rules'
export const CONTACT_SUPPORT_URL = '/help?return_to=/hc/en-us/requests/new'
export const HELP_CENTER_USERS_URL = '/help?return_to=/hc/en-us/articles/360004984551'
export const LEARN_MORE_URL = '/help?return_to=/hc/en-us/articles/16119034963355'
