import React, { useCallback, type Dispatch, type SetStateAction } from 'react'
import { iVvyApi } from '@sevenrooms/core/api'
import type { IVvySpaceMapping } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { TableMappingLocales } from '../shared/TableMapping'
import { iVvyTableMappingLocales } from './iVvyTableMapping.locales'

export interface iVvySaveButtonProps {
  canImportAndSave: boolean
  isDisabled: boolean
  spaceMappingData?: IVvySpaceMapping
  setIsModalActive: Dispatch<SetStateAction<boolean>>
  setIsImport: Dispatch<SetStateAction<boolean>>
  isImport: boolean
  setIsDisabled: Dispatch<SetStateAction<boolean>>
}

export function SaveButton({
  canImportAndSave,
  isDisabled,
  spaceMappingData,
  setIsModalActive,
  setIsImport,
  setIsDisabled,
}: iVvySaveButtonProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [postMutation] = iVvyApi.usePostMutation()

  const onSubmitSave = useCallback(async () => {
    if (spaceMappingData) {
      postMutation({ venueId, spaceMappingData })
        .then(() => notify({ content: formatMessage(TableMappingLocales.notificationSaveSuccess), type: 'success' }))
        .then(() => setIsDisabled(true))
    }
  }, [formatMessage, postMutation, setIsDisabled, spaceMappingData, venueId])

  if (canImportAndSave) {
    return (
      <Button
        variant="primary"
        onClick={() => {
          setIsImport(true)
          setIsModalActive(true)
        }}
        data-test="button-save-and-import-reservations"
        disabled={isDisabled}
      >
        {formatMessage(iVvyTableMappingLocales.saveAndImportReservations)}
      </Button>
    )
  }

  return (
    <Button variant="primary" onClick={onSubmitSave} data-test="button-save-changes" disabled={isDisabled}>
      {formatMessage(iVvyTableMappingLocales.saveChanges)}
    </Button>
  )
}
