import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ExpandedButton = styled.button`
  color: #0abcc2;
  height: 40px;
  width: 100%;
  text-align: start;
  border-color: none;
  background: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
`
const Wrapper = styled.div`
  margin-top: 16px;
`

export const AutotagExpander = ({ children, title, setExpanded, expanded = false }) => (
  <Wrapper>
    {expanded ? (
      children
    ) : (
      <ExpandedButton onClick={() => setExpanded(true)} data-test="autotag-expanded-button">
        {title}
      </ExpandedButton>
    )}
  </Wrapper>
)

AutotagExpander.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
}
