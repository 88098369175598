import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import CustomAutotagSearchDropdownMulti from './CustomAutotagSearchDropdownMulti'
import { DropdownList, SelectedValuePill, SelectedValueIcon, NullValuePill } from './CustomAutotagShared'

const TagGroupSection = styled.div`
  padding: 15px 15px 0 15px;
  display: flex;
  flex-flow: wrap;
`

const TagGroupIcon = styled(StyledVmsIconS)`
  height: 20px;
  font-size: 14px;
  margin-right: 5px;
`

const TagGroupLabel = styled.span`
  height: 20px;
  line-height: 20px;
  flex-grow: 1;
  font-size: 13px;
`

const TagGroupInnerSection = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap;
`

const Tag = styled.div`
  display: inline-block;
`

const _groupOptionsByTagGroup = listOptions =>
  _.reduce(
    listOptions,
    (accum, option) => {
      if (!accum[option.tagGroupID]) {
        accum[option.tagGroupID] = []
      }
      accum[option.tagGroupID].push(option)
      return accum
    },
    {}
  )

const customSelectedItemsRender = (optionsMap, selectedValues, removeSelectionHandler) =>
  _.map(selectedValues, optionKey => {
    const clientTag = optionsMap[optionKey]
    const removeHandler = removeSelectionHandler.bind(this, optionKey)
    if (clientTag == null) {
      return <NullValuePill />
    }
    return (
      <SelectedValuePill
        key={clientTag.key}
        onClick={removeHandler}
        css={css`
          background-color: ${clientTag.colorHex};
        `}
      >
        {clientTag.displayText}
        <SelectedValueIcon>{VmsIcons.ClearLine}</SelectedValueIcon>
      </SelectedValuePill>
    )
  })

const customDropdownRender = (listOptions, selectedValuesSet, dropdownHeight, renderBelow, onChangeHandler) => {
  const tagGroups = _groupOptionsByTagGroup(listOptions)
  return (
    <DropdownList maxHeight={dropdownHeight} renderBelow={renderBelow}>
      {_.map(tagGroups, tagGroupOptions => (
        <TagGroupSection key={tagGroupOptions[0].tagGroupID}>
          <TagGroupIcon>{VmsIcons.Tag}</TagGroupIcon>
          <TagGroupLabel>{tagGroupOptions[0].tagGroupName}</TagGroupLabel>
          <TagGroupInnerSection>
            {_.map(tagGroupOptions, tag => {
              const clickHandler = onChangeHandler.bind(this, tag.key)
              const isSelected = selectedValuesSet.has(tag.key)
              return (
                <SelectedValuePill
                  key={tag.key}
                  css={css`
                    background-color: ${tag.colorHex};
                  `}
                  onClick={clickHandler}
                >
                  {tag.displayText}
                  <SelectedValueIcon>{isSelected ? VmsIcons.Checked : VmsIcons.OvalLine}</SelectedValueIcon>
                </SelectedValuePill>
              )
            })}
          </TagGroupInnerSection>
        </TagGroupSection>
      ))}
    </DropdownList>
  )
}

const CustomAutotagSearchDropdownTags = props => (
  <CustomAutotagSearchDropdownMulti
    {...props}
    customDropdownRender={customDropdownRender}
    customSelectedItemsRender={customSelectedItemsRender}
  />
)

export default CustomAutotagSearchDropdownTags
