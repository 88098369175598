import { defineMessages } from '@sevenrooms/core/locales'

export const jobsDashboardMessages = defineMessages({
  jobsDashboardTitle: {
    id: 'jobsDashboard.title',
    defaultMessage: 'Processing Jobs',
  },
  jobsDashboardDescription: {
    id: 'jobsDashboard.description',
    defaultMessage: 'A complete list of all jobs running in SevenRooms from exports, imports and deduping processors.',
  },
  jobsTitle: {
    id: 'jobs.title',
    defaultMessage: 'Processing Jobs List',
  },
} as const)

export const jobsTableHeaders = defineMessages({
  jobName: {
    id: 'jobsTableHeaders.jobName',
    defaultMessage: 'Job Name',
  },
  JobType: {
    id: 'jobsTableHeaders.jobType',
    defaultMessage: 'Job Type',
  },
  jobStatus: {
    id: 'jobsTableHeaders.jobStatus',
    defaultMessage: 'Status',
  },
  timeInProcessing: {
    id: 'jobsTableHeaders.timeInProcessing',
    defaultMessage: 'Time in Processing Status',
  },
  failReason: {
    id: 'jobsTableHeaders.failReason',
    defaultMessage: 'Reason for Failed Status',
  },
} as const)

export const jobsTableContent = defineMessages({
  notAvailable: {
    id: 'jobsTableContent.notAvailable',
    defaultMessage: 'N/A',
  },
  hours: {
    id: 'jobsTableContent.hours',
    defaultMessage: '{value} hour(s)',
  },
})

export const jobStatus = defineMessages({
  inProgress: {
    id: 'jobStatus.inProgress',
    defaultMessage: 'IN_PROGRESS',
  },
  pending: {
    id: 'jobStatus.pending',
    defaultMessage: 'PENDING',
  },
  completed: {
    id: 'jobStatus.completed',
    defaultMessage: 'COMPLETED',
  },
  failed: {
    id: 'jobStatus.failed',
    defaultMessage: 'FAILED',
  },
})
