import { useMemo } from 'react'
import type { EmailCampaign, EmailCampaignTemplate, OneTimeSMSCampaignTemplate, OneTimeSMSCampaignType } from '@sevenrooms/core/api'
import { MarketingCampaignOneTimeSendTypeEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type OneTimeSendingScheduleFormData = ZodSchema<typeof useOneTimeSendingScheduleForm>
export type OneTimeSendingScheduleFormField = Field<OneTimeSendingScheduleFormData>

export function useOneTimeSendingScheduleForm() {
  return useMemo(
    () =>
      z.object({
        scheduleType: z.nativeEnum(MarketingCampaignOneTimeSendTypeEnum),
        scheduledDay: z.date().nullable(),
        scheduledTime: z.string().nullable(),
      }),
    []
  )
}

export const getOneTimeSendingScheduleDefaultValues = (
  source?: EmailCampaign | EmailCampaignTemplate | OneTimeSMSCampaignType | OneTimeSMSCampaignTemplate
): OneTimeSendingScheduleFormData => ({
  scheduleType: source?.oneTimeSendTimeConfig?.sendType ?? MarketingCampaignOneTimeSendTypeEnum.IMMEDIATELY,
  scheduledDay: source?.oneTimeSendTimeConfig?.date ? new Date(`${source?.oneTimeSendTimeConfig?.date}T00:00:00`) : null,
  scheduledTime: source?.oneTimeSendTimeConfig?.timeOfDay ?? null,
})
