import { useState, useEffect, useCallback } from 'react'
import { type LanguageCode, useGetVenueLanguagesQuery } from '@sevenrooms/core/api'
import type { VenueLanguage } from '@sevenrooms/core/domain'

export function useVenueLanguages(venueId: string) {
  const [selectedLanguage, setSelectedLanguage] = useState<VenueLanguage>()
  const { data: languageListData, isFetching: isLanguageListFetching } = useGetVenueLanguagesQuery(
    {
      venueId,
    },
    { refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    if (languageListData) {
      setSelectedLanguage(languageListData.enabledLanguages?.[0] || languageListData.defaultLanguage)
    }
  }, [languageListData])

  const enabledLanguages = languageListData?.enabledLanguages || []
  const defaultLanguage = languageListData?.defaultLanguage ?? { name: '', value: 'en', isDefault: false, isEnabled: false }
  const selectedLanguageCode: LanguageCode = selectedLanguage?.value ?? 'en'

  const venueLanguageChanged = useCallback(
    updatedLanguageCode => {
      if (updatedLanguageCode !== selectedLanguageCode) {
        const lang = enabledLanguages.find(language => language.value === updatedLanguageCode)
        setSelectedLanguage(lang)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLanguageCode]
  )

  return {
    enabledLanguages,
    defaultLanguage,
    selectedLanguage,
    selectedLanguageCode,
    defaultLanguageCode: defaultLanguage.value,
    venueLanguageChanged,
    isLanguageListFetching,
  }
}
