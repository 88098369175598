import { defineMessages } from '@sevenrooms/core/locales'

export const subscriptionLocales = defineMessages({
  birthdayInputLabel: {
    id: 'subscriptionWidgetSettings.birthdayInputLabel',
    defaultMessage: 'Birthday Input',
  },
  buttonColor: {
    id: 'subscriptionWidgetSettings.buttonColor',
    defaultMessage: 'Button Color',
  },
  buttonPickerLabel: {
    id: 'subscriptionWidgetSettings.buttonPickerLabel',
    defaultMessage: 'Select which button you would like to use on your website',
  },
  customButtonText: {
    id: 'subscriptionWidgetSettings.customButtonText',
    defaultMessage: 'Customize Text on Button',
  },
  characters: {
    id: 'subscriptionWidgetSettings.characters',
    defaultMessage: 'Characters',
  },
  charactersAllUpperCaseLabel: {
    id: 'subscriptionWidgetSettings.charactersAllUpperCaseLabel',
    defaultMessage: 'AA',
  },
  charactersCapitalizedLabel: {
    id: 'subscriptionWidgetSettings.charactersCapitalizedLabel',
    defaultMessage: 'Aa',
  },
  colorPrimary: {
    id: 'subscriptionWidgetSettings.primaryColor',
    defaultMessage: 'Primary Color',
  },
  defaultCustomButtonText: {
    id: 'subscriptionWidgetSettings.defaultCustomButtonText',
    defaultMessage: 'Stay in touch',
  },
  dietaryRestrictionsInputLabel: {
    id: 'subscriptionWidgetSettings.dietaryRestrictionsInputLabel',
    defaultMessage: 'Dietary Restrictions Input',
  },
  directLinkLabel: {
    id: 'subscriptionWidgetSettings.directLinkLabel',
    defaultMessage: 'Direct Link to Widget',
  },
  emailSignupButtonSettingsTitle: {
    id: 'subscriptionWidgetSettings.emailSignupButtonSettingsTitle',
    defaultMessage: 'Subscription Widget General Settings',
  },
  enableRecaptchaLabel: {
    id: 'subscriptionWidgetSettings.enableRecaptchaLabel',
    defaultMessage: 'Enable reCAPTCHA',
  },
  errorImageRequired: {
    id: 'subscriptionWidgetSettings.errorImageRequired',
    defaultMessage: 'Image is required',
  },
  formattingSectionTitle: {
    id: 'subscriptionWidgetSettings.formattingSectionTitle',
    defaultMessage: 'Formatting',
  },
  headerImageRadioOptionExisting: {
    id: 'subscriptionWidgetSettings.headerImageRadioOptionExisting',
    defaultMessage: 'Use the header image uploaded on {link}',
  },
  headerImageRadioOptionExistingLink: {
    id: 'subscriptionWidgetSettings.logoHeaderRadioOptionExistingLinkLabel',
    defaultMessage: 'Reservation Widget Settings',
  },
  headerImageRadioOptionUploadNew: {
    id: 'subscriptionWidgetSettings.headerImageRadioOptionUploadNew',
    defaultMessage: 'Upload new header image',
  },
  htmlEmbedInstructions: {
    id: 'subscriptionWidgetSettings.htmlEmbedInstructions',
    defaultMessage: 'Copy and paste this code into your website',
  },
  htmlEmbedLabel: {
    id: 'subscriptionWidgetSettings.htmlEmbedLabel',
    defaultMessage: 'HTML',
  },
  htmlEmbedMessageLine1: {
    id: 'subscriptionWidgetSettings.htmlEmbedMessageLine1',
    defaultMessage: 'In order for the selected widget button settings to take effect,',
  },
  htmlEmbedMessageLine2: {
    id: 'subscriptionWidgetSettings.htmlEmbedMessageLine2',
    defaultMessage: 'you will need to be sure the snippet below matches the snippet on your website.',
  },
  logoHeader: {
    id: 'subscriptionWidgetSettings.logoHeader',
    defaultMessage: 'Logo Header',
  },
  logoHeaderRadioOptionExisting: {
    id: 'subscriptionWidgetSettings.logoHeaderRadioOptionExisting',
    defaultMessage: 'Use the logo uploaded on {link}',
  },
  logoHeaderRadioOptionExistingLink: {
    id: 'subscriptionWidgetSettings.logoHeaderRadioOptionExistingLinkLabel',
    defaultMessage: 'Transactional Email Design',
  },
  logoHeaderRadioOptionUploadNew: {
    id: 'subscriptionWidgetSettings.logoHeaderUploadLogoTitle',
    defaultMessage: 'Upload new logo',
  },
  phoneNumberInputLabel: {
    id: 'subscriptionWidgetSettings.phoneNumberInputLabel',
    defaultMessage: 'Phone Number Input',
  },
  postalCodeInputLabel: {
    id: 'subscriptionWidgetSettings.postalCodeInputLabel',
    defaultMessage: 'Postal Code Input',
  },
  radioChoiceLabelHidden: {
    id: 'subscriptionWidgetSettings.radioChoiceLabelHidden',
    defaultMessage: 'Hidden',
  },
  radioChoiceLabelOptional: {
    id: 'subscriptionWidgetSettings.radioChoiceLabelOptional',
    defaultMessage: 'Optional',
  },
  radioChoiceLabelRequired: {
    id: 'subscriptionWidgetSettings.radioChoiceLabelRequired',
    defaultMessage: 'Required',
  },
  displayLinkToTheSubscriptionWidgetOnEmailConfirmationsLabel: {
    id: 'subscriptionWidgetSettings.displayLinkToTheSubscriptionWidgetOnEmailConfirmationsLabel',
    defaultMessage: 'Display link to the Subscription Widget on Email Confirmations',
  },
  redirectUrl: {
    id: 'subscriptionWidgetSettings.redirectUrl',
    defaultMessage: 'Redirect URL after successful subscription',
  },
  salutationInputLabel: {
    id: 'subscriptionWidgetSettings.salutationInputLabel',
    defaultMessage: 'Salutation Input',
  },
  showHeaderImageLabel: {
    id: 'subscriptionWidgetSettings.showHeaderImageLabel',
    defaultMessage: 'Show Header Image',
  },
  showOptInMarketingVenuePolicyToggleLabel: {
    id: 'subscriptionWidgetSettings.showOptInMarketingVenuePolicyToggleLabel',
    defaultMessage: 'Show Email Marketing Checkbox for Venue',
  },
  showOptInMarketingVenuePolicyDefaultInfoLine1: {
    id: 'subscriptionWidgetSettings.showOptInMarketingVenuePolicyDefaultInfoLine1',
    defaultMessage:
      "Enabling this feature will result in the Venue Email Marketing Checkbox to be 'pre-checked' for Guests that are signing up through the Subscription Widget.",
  },
  showOptInMarketingVenueGroupPolicyToggleLabel: {
    id: 'subscriptionWidgetSettings.showOptInMarketingVenueGroupPolicyToggleLabel',
    defaultMessage: 'Show Email Marketing Checkbox for Group',
  },
  showOptInMarketingVenueGroupPolicyDefaultInfoLine1: {
    id: 'subscriptionWidgetSettings.showOptInMarketingVenueGroupPolicyDefaultInfoLine1',
    defaultMessage:
      "Enabling this feature will result in the Group Email Marketing Checkbox to be 'pre-checked' for Guests that are signing up through the Subscription Widget.",
  },
  showOptInMarketingDisclaimer: {
    id: 'subscriptionWidgetSettings.showOptInMarketingDisclaimer',
    defaultMessage: 'This feature should only be used in accordance with your local marketing regulations.',
  },
  showOptInMarketingDefaultLabel: {
    id: 'subscriptionWidgetSettings.showOptInMarketingDefaultLabel',
    defaultMessage: 'Opt-in is checked by default',
  },
  showOptInMarketingSMSPolicyToggleLabel: {
    id: 'subscriptionWidgetSettings.showOptInMarketingSMSPolicyToggleLabel',
    defaultMessage: 'Show Text Marketing Checkbox for Venue',
  },
  showOptInMarketingSMSDefaultInfoLine1: {
    id: 'subscriptionWidgetSettings.showOptInMarketingSMSDefaultInfoLine1',
    defaultMessage:
      "Enabling this feature will result in the Venue Text Marketing Checkbox to be 'pre-checked' for Guests that are signing up through the Subscription Widget.",
  },
  signUpConfigurationReminder: {
    id: 'subscriptionWidgetSettings.signUpConfigurationReminder',
    defaultMessage: 'Reminder: Turning on too many fields may lead to a lower conversion rate',
  },
  signUpConfigurationTitle: {
    id: 'subscriptionWidgetSettings.signUpConfigurationTitle',
    defaultMessage: 'Subscription Widget Configurations',
  },
  submitLoadingMessage: {
    id: 'subscriptionWidgetSettings.submitLoadingMessage',
    defaultMessage: 'Saving...',
  },
  submitErrorMessage: {
    id: 'subscriptionWidgetSettings.submitErrorMessage',
    defaultMessage: 'Something went wrong. Check there are no errors and try again.',
  },
  noMarketingOptInSelected: {
    id: 'subscriptionWidgetSettings.noMarketingOptInSelected',
    defaultMessage: 'Please select at lease one Marketing Checkbox above',
  },
  submitSuccessMessage: {
    id: 'subscriptionWidgetSettings.submitSuccessMessage',
    defaultMessage: 'Settings have been saved.',
  },
  title: {
    id: 'subscriptionWidgetSettings.title',
    defaultMessage: 'Subscription Widget',
  },
} as const)
