import _ from 'lodash'
import React from 'react'
import type { Perk } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { Box } from '@sevenrooms/react-components/components/Box'
import { perksMessages } from '../../locales'
import { calculateAvailabilityTimesPeriods, calculateLegacyAvailabilityTimesPeriods } from '../../utils'

interface PerkAvailabilityProps {
  perk: Perk
}

export function PerkAvailability({ perk }: PerkAvailabilityProps) {
  const { formatMessage } = useLocales()
  const { perkAvailabilityTimes, dowOffered, isRestrictedByWeekOfDayAndTimeRange } = perk
  const anytimeMessage = <>{formatMessage(perksMessages.anytime)}</>
  let availabilityTimes: {
    key: string | undefined
    value: string | null
  }[] = []

  if (!isRestrictedByWeekOfDayAndTimeRange) {
    return anytimeMessage
  }

  if (_.isEmpty(perkAvailabilityTimes)) {
    if (_.isEmpty(dowOffered)) {
      return anytimeMessage
    }

    availabilityTimes = calculateLegacyAvailabilityTimesPeriods(dowOffered)
  } else {
    availabilityTimes = calculateAvailabilityTimesPeriods(perkAvailabilityTimes)
  }

  if (_.isEmpty(availabilityTimes)) {
    return anytimeMessage
  }

  return (
    <>
      {availabilityTimes.map(({ key, value }) => (
        <Flex key={key}>
          <Box width="70px">{key}</Box> {value}
        </Flex>
      ))}
    </>
  )
}
