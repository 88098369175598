import { useState } from 'react'
import { orderingRoomNumbersApi } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Window, Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { CreateRoomNumberModal } from './CreateRoomNumberModal'
import { DeleteAllRoomNumberModal } from './DeleteAllRoomNumbersModal'
import { OrderRoomNumberLocales } from './OrderingRoomNumbers.locales'
import { RoomNumbersListTable } from './RoomNumbersListTable'

export function RoomNumbersList() {
  const [isCreateRoomNumberModalOpen, setIsCreateRoomNumberModalOpen] = useState(false)
  const [isDeleteAllRoomNumbersModalOpen, setIsDeleteAllRoomNumbersModalOpen] = useState(false)
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { data } = orderingRoomNumbersApi.useGetRoomNumbersQuery({ venueId })
  return (
    <>
      <SettingsPageMeta title={formatMessage(OrderRoomNumberLocales.orderingRoomNumbersTitle)} />
      <SettingsPageContent
        title={formatMessage(OrderRoomNumberLocales.orderingRoomNumbersTitle)}
        actions={
          <Button
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => setIsCreateRoomNumberModalOpen(true)}
            id="create-new-room-numner"
            data-test="create-new-room-numner"
          >
            {formatMessage(OrderRoomNumberLocales.addOrderingRoomNumbersTitle)}
          </Button>
        }
      >
        <Box p="m" data-test="room-numbers-container">
          <RoomNumbersListTable openDeleteAllModal={() => setIsDeleteAllRoomNumbersModalOpen(true)} />
        </Box>
      </SettingsPageContent>
      <Window active={isCreateRoomNumberModalOpen}>
        <CreateRoomNumberModal
          onDiscard={() => setIsCreateRoomNumberModalOpen(false)}
          allRoomNumbers={data ? data.map(roomNumber => roomNumber.number) : []}
        />
      </Window>
      <Window active={isDeleteAllRoomNumbersModalOpen}>
        <DeleteAllRoomNumberModal onDiscard={() => setIsDeleteAllRoomNumbersModalOpen(false)} roomNumbersCount={data ? data.length : 0} />
      </Window>
    </>
  )
}
