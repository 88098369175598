import { useMemo } from 'react'
import { getVenueTaxRates } from '@sevenrooms/core/domain'
import { Box, Loader } from '@sevenrooms/core/ui-kit/layout'
import { isSeatingAreaToTables } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.types'
import { AccessRuleContextProvider } from '@sevenrooms/mgr-access-rules-slideout/components/shared'
import { type AccessRulesSlideoutData, useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core'
import type { OfferTemplateProps } from '@sevenrooms/mgr-marketing-templates-gallery'
import { GetTemplateDataByTemplateId } from '@sevenrooms/mgr-marketing-templates-gallery/components/MarketingTemplatesGallery/utils'
import { OffersStepperFlow } from '@sevenrooms/mgr-marketing-templates-gallery/components/OffersStepperFlow'

interface CreateExperienceFromTemplateParams {
  templateId: string
  venueKey: string
}

interface CreateExperienceFromTemplateProps {
  match: { params: CreateExperienceFromTemplateParams }
}

export function CreateExperienceFromTemplate({ match }: CreateExperienceFromTemplateProps) {
  const { templateId, venueKey } = match.params
  const templateData: OfferTemplateProps | undefined = GetTemplateDataByTemplateId(templateId)
  const { data: accessRulesSlideoutData, isLoading: isAccessRuleSlideoutDataLoading } = useAccessRulesSlideoutData()

  return !templateData || isAccessRuleSlideoutDataLoading || !accessRulesSlideoutData ? (
    <Loader />
  ) : (
    <Box
      pt="m"
      pb="m"
      borderWidth="s"
      borderBottomColor="borders"
      backgroundColor="primaryBackground"
      data-test="create-experience-from-template-page"
      height="100%"
    >
      <ExperienceFromTemplate templateData={templateData} accessRulesSlideoutData={accessRulesSlideoutData} venueKey={venueKey} />
    </Box>
  )
}

function ExperienceFromTemplate({
  accessRulesSlideoutData,
  venueKey,
  templateData,
}: {
  accessRulesSlideoutData: AccessRulesSlideoutData
  venueKey: string
  templateData: OfferTemplateProps
}) {
  const { venueId, venueName } = useVenueContext()

  const seatingAreaToTables = useMemo(
    () => accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables.filter(isSeatingAreaToTables) ?? [],
    [accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables]
  )
  const tagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.reservationTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.reservationTagGroups]
  )
  const experiences = useMemo(() => Object.values(accessRulesSlideoutData.experiences ?? {}), [accessRulesSlideoutData.experiences])
  const clientTagGroups = useMemo(
    () =>
      new Map(
        accessRulesSlideoutData.clientTagGroups
          .filter(tagGroup => tagGroup.nameDisplay === 'SevenRooms App')
          .map(tagGroup => [
            tagGroup.id,
            {
              ...tagGroup,
              tags: tagGroup.tags.filter(tag => ['Room: Member', 'Room: Eligible'].includes(tagGroup.tagNameDisplays[tag] || '')),
            },
          ])
      ),
    [accessRulesSlideoutData.clientTagGroups]
  )
  const taxRates = useMemo(() => getVenueTaxRates(accessRulesSlideoutData.taxRates, venueId), [accessRulesSlideoutData, venueId])
  return (
    <AccessRuleContextProvider
      value={{
        ...accessRulesSlideoutData,
        shifts: accessRulesSlideoutData.allShifts,
        defaultBookingPolicyId: accessRulesSlideoutData.defaultPolicies.bookingPolicyId || 'default',
        defaultCancelPolicyId: accessRulesSlideoutData.defaultPolicies.cancelPolicyId || 'default',
        seatingAreaToTables,
        tagGroups,
        widgetSettings: accessRulesSlideoutData.reservationWidgetSettings,
        experiences,
        clientTagGroups,
        taxRates,
      }}
    >
      <OffersStepperFlow templateData={templateData} venueKey={venueKey} venueName={venueName} venueId={venueId} />
    </AccessRuleContextProvider>
  )
}
