import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { DisplayIfSelected } from '../../../ChargeSettings'
import { PaymentPolicyLocales } from '../../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import { AllowUnsupported } from '../AllowUnsupported'
import { ChargeAtBooking } from './ChargeAtBooking'
import { MinPartySize, RadioSection } from './RadioComponents'
import type { PaymentPolicyForm } from '../../PaymentPolicy.zod'
import type { PaymentPolicySectionProps } from '../PaymentPolicyProps'

export function PaymentRuleRadios({ field, allowChannelsWithoutCCHolds }: PaymentPolicySectionProps) {
  const { formatMessage } = useLocales()
  const choices: RadioChoice<PaymentPolicyForm['paymentRule']>[] = useMemo(
    () => [
      {
        value: 'none',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceNone),
      },
      {
        value: 'save_for_later',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceLater),
        description: <SaveForLater field={field} allowChannelsWithoutCCHolds={allowChannelsWithoutCCHolds} />,
      },
      {
        value: 'advanced_payment',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceImmediately),
        description: (
          <DisplayIfSelected field={field.prop('paymentRule')} option="advanced_payment">
            <ChargeAtBooking field={field} />
          </DisplayIfSelected>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, field]
  )
  return (
    <RadioSection
      data-test={PaymentPolicyTestId.paymentRuleRadios}
      choices={choices}
      field={field.prop('paymentRule')}
      primaryLabel={formatMessage(PaymentPolicyLocales.requirePayment)}
    />
  )
}

export function SaveForLater({ field, allowChannelsWithoutCCHolds, showDescription = true }: PaymentPolicySectionProps) {
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="s">
      {showDescription && <Text color="secondaryFont">{formatMessage(PaymentPolicyLocales.paymentChoiceLaterDescription)}</Text>}
      <DisplayIfSelected field={field.prop('paymentRule')} option="save_for_later">
        <MinPartySize field={field} />
        <AllowUnsupported field={allowChannelsWithoutCCHolds} />
      </DisplayIfSelected>
    </VStack>
  )
}
