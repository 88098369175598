import { useFormContext } from '@sevenrooms/core/form'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticPrepaymentRedemptionCard } from '../BaseAutomaticPrepaymentRedemptionCard'
import type { AutomaticPrepaymentRedemptionCardFormField } from '../AutomaticPrepaymentRedemptionCardForm.zod'

export interface LightspeedKAutomaticPrepaymentRedemptionCardProps {
  field: AutomaticPrepaymentRedemptionCardFormField
}

export function LightspeedKAutomaticPrepaymentRedemptionCard({ field }: LightspeedKAutomaticPrepaymentRedemptionCardProps) {
  const { watch } = useFormContext()
  const selectedLocationId = watch('locationCard.locationId')

  return (
    <BaseAutomaticPrepaymentRedemptionCard
      docLink={posiMetaDataMap.LIGHTSPEED_K.docLink}
      isAutomaticPrepaymentRedemptionEnabledField={field.prop('isAutomaticPrepaymentRedemptionEnabled')}
      disabled={!selectedLocationId}
    />
  )
}
