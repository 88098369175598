import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'
import { useReferralClientForm } from '../ReferralClientForm'
import { useReferredClientEmailConfigurationsForm } from '../ReferredClientEmailConfigurationsForm'

export type ReferredClientFormData = ZodSchema<typeof useReferredClientForm>
export type ReferredClientFormFields = Field<ReferredClientFormData>

export const useReferredClientForm = () => {
  const referralNewClientFormSchema = useReferralClientForm()
  const referralExistingClientFormSchema = useReferralClientForm()
  const referredClientEmailConfigurationsFormSchema = useReferredClientEmailConfigurationsForm()

  return useMemo(
    () =>
      z.object({
        referredClientReservationTags: z.tuple([referralNewClientFormSchema, referralExistingClientFormSchema]),
        referredPerkText: z.string().optional(),
        referredNewClientEmailConfigurations: referredClientEmailConfigurationsFormSchema,
        referredExistingClientEmailConfigurations: referredClientEmailConfigurationsFormSchema,
      }),
    [referralNewClientFormSchema, referralExistingClientFormSchema, referredClientEmailConfigurationsFormSchema]
  )
}
