import { defineMessages } from '@sevenrooms/core/locales'

export const perksMessages = defineMessages({
  perks: {
    id: 'perks.perks',
    defaultMessage: 'Perks',
  },
  perksDescription: {
    id: 'perks.description',
    defaultMessage: 'Create personalized experience for loyal customers by offering perks and defining standard procedures.',
  },
  createNewPerk: {
    id: 'perks.createNewPerk',
    defaultMessage: 'Create New Perk',
  },
  createPerk: {
    id: 'perk.createPerk',
    defaultMessage: 'Create Perk',
  },
  editPerk: {
    id: 'perks.editPerk',
    defaultMessage: 'Edit {perkName}',
  },
  perkDescription: {
    id: 'perks.perkDescription',
    defaultMessage: 'Perks will be shown on the client and reservation slideout and printed on the chit.',
  },
  perkNameInternalPrimary: {
    id: 'perks.perkNameInternalPrimary',
    defaultMessage: 'Internal Name of the Perk',
  },
  perkNameInternalSecondary: {
    id: 'perks.perkNameInternalSecondary',
    defaultMessage: 'This will show up on client and reservation slideout and chit for the FOH team members.',
  },
  exclusivePerkNameInternalSecondary: {
    id: 'perks.exclusivePerkNameInternalSecondary',
    defaultMessage: 'This will not show up on the chit or reservation slide-out on the App.',
  },
  perkNameInternalPlaceholder: {
    id: 'perks.perkNameInternalPlaceholder',
    defaultMessage: 'Enter internal name',
  },
  perkExternalMatchesInternalDescription: {
    id: 'perks.perkExternalMatchesInternalDescription',
    defaultMessage: 'External name is a guest facing name.',
  },
  perkExternalMatchesInternalLabel: {
    id: 'perks.perkExternalMatchesInternalLabel',
    defaultMessage: 'Guest-facing Perk name is the same as the internal name',
  },
  displayedExternallyLabel: {
    id: 'perks.displayedExternallyLabel',
    defaultMessage: 'Display this perk in guest-facing communication',
  },
  perkNameExternalPrimary: {
    id: 'perks.perkNameExternalPrimary',
    defaultMessage: 'External Name of the Perk',
  },
  perkNameExternalSecondary: {
    id: 'perks.perkNameExternalSecondary',
    defaultMessage: 'This will be shown in Marketing Emails and Automated Emails.',
  },
  perkNameExternalPlaceholder: {
    id: 'perks.perkNameExternalPlaceholder',
    defaultMessage: 'Enter external name',
  },
  perkTypeGlobalLabel: {
    id: 'perks.perkTypeGlobalLabel',
    defaultMessage: 'Global',
  },
  perkTypeGlobalDescription: {
    id: 'perks.perkTypeGlobalDescription',
    defaultMessage: 'This perk will show up on reservation slideouts in all venues across the venue group.',
  },
  perkTypeLocalLabel: {
    id: 'perks.perkTypeLocalLabel',
    defaultMessage: 'Local',
  },
  perkTypeLocalDescription: {
    id: 'perks.perkTypeLocalDescription',
    defaultMessage: 'This perk will only show up on the reservation slideout for this venue.',
  },
  perkGeneral: {
    id: 'perk.general',
    defaultMessage: 'General',
  },
  perkAdditionalInformationPrimary: {
    id: 'perks.perkAdditionalInformationPrimary',
    defaultMessage: 'Additional Information',
  },
  perkAdditionalInformationSecondary: {
    id: 'perks.perkAdditionalInformationSecondary',
    defaultMessage: '500 character limit',
  },
  perkAdditionalInformationInfo: {
    id: 'perks.additionalInformationInfo',
    defaultMessage: `@concierge displays the booking concierge's name`,
  },
  perkAvailableInConciergePortalLabel: {
    id: 'perk.availableInConciergePortalLabel',
    defaultMessage: 'Should this perk be available in concierge portal?',
  },
  perkAvailableInConciergePortalNoLabel: {
    id: 'perk.availableInConciergePortalNoLabel',
    defaultMessage: 'No',
  },
  perkAvailableInConciergePortalYesDescription: {
    id: 'perk.availableInConciergePortalYesDescription',
    defaultMessage: 'Selected concierges are able to add this perk to all reservations.',
  },
  perkAvailableInConciergePortalYesLabel: {
    id: 'perk.availableInConciergePortalYesLabel',
    defaultMessage: 'Yes',
  },
  perkAdditionalSettings: {
    id: 'perk.additionalSettings',
    defaultMessage: 'Concierge Additional Settings',
  },
  perkAvailabilityLabel: {
    id: 'perk.availabilityLabel',
    defaultMessage: 'When is this perk provided?',
  },
  perkAvailabilityAnytime: {
    id: 'perk.availabilityAnytime',
    defaultMessage: 'Anytime',
  },
  perkAvailabilityRestrictions: {
    id: 'perk.availabilityRestrictions',
    defaultMessage: 'Date/day/time restrictions',
  },
  perkAvailability: {
    id: 'perk.availability',
    defaultMessage: 'Perk Availability',
  },
  perkAvailabilityRestrictionsDateRange: {
    id: 'perk.AvailabilityRestrictionsDateRange',
    defaultMessage: 'Restricted by date range',
  },
  perkAvailabilityRestrictionedByDayAndTimeRange: {
    id: 'perk.AvailabilityRestrictionedByDayAndTimeRange',
    defaultMessage: ' Restricted by day of week and time range',
  },
  internalPerksName: {
    id: 'perks.internalPerksName',
    defaultMessage: 'Internal Perk Name',
  },
  type: {
    id: 'perks.type',
    defaultMessage: 'Type',
  },
  associatedTags: {
    id: 'perks.associatedTags',
    defaultMessage: 'Associated Tags',
  },
  createdFrom: {
    id: 'perks.createdFrom',
    defaultMessage: 'Created From',
  },
  availability: {
    id: 'perks.availability',
    defaultMessage: 'Availability',
  },
  concierge: {
    id: 'perks.concierge',
    defaultMessage: 'Concierge',
  },
  fetchErrorMessage: {
    id: 'perks.fetchErrorMessage',
    defaultMessage: 'An error occurred. Try refreshing the page',
  },
  perksTagsFilterPlaceholder: {
    id: 'perks.perksTagsFilterPlaceholder',
    defaultMessage: 'Filter by tags',
  },
  perksSearchPlaceholder: {
    id: 'perks.perksSearchPlaceholder',
    defaultMessage: 'Search for perks',
  },
  emptyTableTitleNoData: {
    id: 'perks.emptyTableTitleNoData',
    defaultMessage: 'No perks have been created yet',
  },
  emptyTableDescriptionNoDataText: {
    id: 'perks.emptyTableDescriptionNoDataText',
    defaultMessage: 'Get started by',
  },
  emptyTableDescriptionNoDataLink: {
    id: 'perks.emptyTableDescriptionNoDataLink',
    defaultMessage: 'creating a perk',
  },
  emptyTableDescriptionFilters: {
    id: 'perks.emptyTableDescriptionFilters',
    defaultMessage: 'No perks match the selected filters',
  },
  emptyTableDescriptionSearch: {
    id: 'perks.emptyTableDescriptionFilters',
    defaultMessage: 'No Perks with a name containing “{search}”',
  },
  perkConciergesPrimaryLabel: {
    id: 'perk.ConciergesPrimaryLabel',
    defaultMessage: 'Which concierge(s) can access this perk?',
  },
  perkNotFound: {
    id: 'perk.NotFound',
    defaultMessage: 'Perk {perkId} not found',
  },
  perkCreateSuccess: {
    id: 'perk.CreateSuccess',
    defaultMessage: '"{perkInternalName}" has been successfully created.',
  },
  perkUpdateSuccess: {
    id: 'perk.UpdateSuccess',
    defaultMessage: '"{perkInternalName}" has been successfully updated.',
  },
  perkCreateUpdateError: {
    id: 'perk.CreateUpdateError',
    defaultMessage: 'An error occured, try saving again.',
  },
  anytime: {
    id: 'perks.anytime',
    defaultMessage: 'Anytime',
  },
  startDateRequired: {
    id: 'perks.startDateRequired',
    defaultMessage: 'Start date is required',
  },
  endDateIsInfinite: {
    id: 'perks.endDateIsInfinite',
    defaultMessage: 'Either end date or continue indefinitely is required',
  },
  perkType: {
    id: 'perks.perkType',
    defaultMessage: 'Perk Type',
  },
  perkVisibility: {
    id: 'perks.perkVisibility',
    defaultMessage: 'Perk Visibility Description',
  },
  perkTypeInfo: {
    id: 'perk.perkTypeMessage',
    defaultMessage: 'Perk Type cannot be updated after a perk has been created.',
  },
  perkAreYouSure: {
    id: 'perk.perkAreYouSure',
    defaultMessage: 'Are you sure? Change you made may not be saved.',
  },
  perkArchiveTitle: {
    id: 'perk.perkArchiveTitle',
    defaultMessage: 'Are you sure you want to archive this perk?',
  },
  perkArchiveRemoveFromTagsDescription: {
    id: 'perk.perkArchiveRemoveFromTagsDescription',
    defaultMessage: 'Archiving this perk will remove it from the {autoTagNames} auto-tag(s) and the reservation slideout.',
  },
  perkArchiveArchiveAssociatedAccessRulesDescription: {
    id: 'perk.perkArchiveArchiveAssociatedAccessRulesDescription',
    defaultMessage: 'Archiving this perk will also archive all associated Access Rules.',
  },
  perkArchiveUpdateAssociatedAccessRulesDescription: {
    id: 'perk.perkArchiveUpdateAssociatedAccessRulesDescription',
    defaultMessage: 'Archiving this perk will also update all associated Access Rules.',
  },
  confirmArchive: {
    id: 'perk.confirmArchive',
    defaultMessage: 'Confirm Archive',
  },
  deleteErrorMessage: {
    id: 'perk.deleteErrorMessage',
    defaultMessage: 'An error has occured, try again.',
  },
  deleteSuccessMessage: {
    id: 'perk.deleteSuccessMessage',
    defaultMessage: 'Perk has been successfully deleted.',
  },
  perkEarlyAccess: {
    id: 'perk.perkEarlyAccess',
    defaultMessage: 'EARLY ACCESS',
  },
  perkEarlyAccessInfo: {
    id: 'perk.perkEarlyAccessInfo',
    defaultMessage: 'Only one early access perk can be created',
  },
  emptyEarlyAccessTableTitleNoData: {
    id: 'perk.emptyEarlyAccessTableTitleNoData',
    defaultMessage: 'No early access perk has been created yet',
  },
  emptyEarlyAccessTableDescriptionNoDataLink: {
    id: 'perk.emptyEarlyAccessTableDescriptionNoDataLink',
    defaultMessage: 'creating an Early Access Perk',
  },
  perkExclusiveAccess: {
    id: 'perk.perkExclusiveAccess',
    defaultMessage: 'EXCLUSIVE ACCESS',
  },
  perkExclusiveAccessInfo: {
    id: 'perk.perkExclusiveAccessInfo',
    defaultMessage: 'Only one exclusive access perk can be created',
  },
  perkDefaultAccess: {
    id: 'perk.perkDefaultAccess',
    defaultMessage: 'CUSTOM PERKS',
  },
  emptyExclusiveTableTitleNoData: {
    id: 'perk.emptyExclusiveTableTitleNoData',
    defaultMessage: 'No exclusive access perk has been created yet',
  },
  emptyExclusiveTableDescriptionNoDataText: {
    id: 'perk.emptyExclusiveTableDescriptionNoDataText',
    defaultMessage: 'Get started by',
  },
  emptyExclusiveTableDescriptionNoDataLink: {
    id: 'perk.emptyExclusiveTableDescriptionNoDataLink',
    defaultMessage: 'creating an Exclusive Access Perk',
  },
  emptyExclusiveTableDisabledDescription: {
    id: 'perk.emptyExclusiveTableDisabledDescription',
    defaultMessage: 'This feature is not available for your current permission level. Please contact your administrator to gain access.',
  },
  perksManagement: {
    id: 'perk.perksManagement',
    defaultMessage: 'Perks Management',
  },
  createNewEarlyAccessPerk: {
    id: 'perk.createNewEarlyAccessPerk',
    defaultMessage: 'Create Perk - Early Access',
  },
  createNewEarlyAccessPerkBreadcrumb: {
    id: 'perk.createNewEarlyAccessPerkBreadcrumb',
    defaultMessage: 'Create Early Access Perk',
  },
  createNewExclusivePerk: {
    id: 'perk.createNewExclusivePerk',
    defaultMessage: 'Create Perk - Exclusive Access',
  },
  createNewExclusivePerkBreadcrumb: {
    id: 'perk.createNewExclusivePerk',
    defaultMessage: 'Create Exclusive Access Perk',
  },
  editPerkBreadcrumb: {
    id: 'perk.editPerkBreadcrumb',
    defaultMessage: 'Edit Perk',
  },
  clientTags: {
    id: 'perk.clientTags',
    defaultMessage: 'Client Tags',
  },
  clientMultiSelectTagPlaceholder: {
    id: 'perk.clientMultiSelecTagPlaceholder',
    defaultMessage: 'Search Client Tags',
  },
  reservationTags: {
    id: 'perk.reservationTags',
    defaultMessage: 'Reservation Tags',
  },
  reservationMultiSelectTagPlaceholder: {
    id: 'perk.reservationMultiSelecTagPlaceholder',
    defaultMessage: 'Search Reservation Tags',
  },
  reservationWidetLinkPrimary: {
    id: 'perk.reservationWidetLinkPrimary',
    defaultMessage: 'Reservation Widget Link',
  },
  reservationWidetLinkSecondary: {
    id: 'perk.reservationWidetLinkSecondary',
    defaultMessage: 'Give this link to your VIPs for them to access the exclusive tables and all the other tables.',
  },
  earlyAccessReservationWidetLinkSecondary: {
    id: 'perk.earlyAccessReservationWidetLinkSecondary',
    defaultMessage: 'Provide this link to your most important guests so they can have early access to reservations.',
  },
  reservationLinkNotCreated: {
    id: 'perk.reservationLinkNotCreated',
    defaultMessage: 'A link will be generated here after this perk has been created.',
  },
  reservationLinkContentPrimary: {
    id: 'perk.reservationLinkContentPrimary',
    defaultMessage: 'Direct link to Reservation Widget',
  },
  reservationLinkContentSecondary: {
    id: 'perk.reservationLinkContentSecondary',
    defaultMessage: 'This is a separate link from the normal reservation widget link.',
  },
  accessRulesSectionTitle: {
    id: 'perk.accessRulesSectionTitle',
    defaultMessage: 'Associated Access Rules',
  },
  accessRulesCreateSectionDescription: {
    id: 'perk.accessRulesCreateSectionDescription',
    defaultMessage: 'Create one or more Access Rules to set up reserved tables and seating areas for your loyal guests.',
  },
  accessRulesSelectSectionDescription: {
    id: 'perk.accessRulesSelectSectionDescription',
    defaultMessage: 'Select at least one Access Rule to set up Early Access for your most important guests.',
  },
  createAccessRule: {
    id: 'perk.createAccessRule',
    defaultMessage: 'Create Access Rule',
  },
  selectAccessRule: {
    id: 'perk.selectAccessRule',
    defaultMessage: 'Select Access Rule',
  },
  confirm: {
    id: 'perk.confirm',
    defaultMessage: 'Confirm',
  },
  earlyAccessRuleModalCreation: {
    id: 'perk.earlyAccessRuleModalCreation',
    defaultMessage: 'Early Access Creation - <i>step {step} of {stepsCount}</i>',
  },
  earlyAccessRuleModalChooseAccessRuleTitle: {
    id: 'perk.earlyAccessRuleModalChooseAccessRuleTitle',
    defaultMessage: 'Select Access Rule(s) for your guests to have early access to:',
  },
  earlyAccessRuleModalReservationTitle: {
    id: 'perk.earlyAccessRuleModalReservationTitle',
    defaultMessage: 'Define the number of days in advance your guests can book reservations',
  },
  earlyAccessRuleModalReservationLabel: {
    id: 'perk.earlyAccessRuleModalReservationLabel',
    defaultMessage: 'Booking Start Date',
  },
  earlyAccessRuleModalEdit: {
    id: 'perk.earlyAccessRuleModalEdit',
    defaultMessage: 'Edit {ruleName}',
  },
  accessRulesTableAccessRuleName: {
    id: 'perk.accessRulesTable.accessRuleName',
    defaultMessage: 'Access Rule Name',
  },
  accessRulesTableDaysInAdvanceToBook: {
    id: 'perk.accessRulesTable.daysInAdvanceToBook',
    defaultMessage: 'Days in advance to book',
  },
  accessRulesTableReservedTablesAndSeatingAreas: {
    id: 'perk.accessRulesTable.reservedTablesAndSeatingAreas',
    defaultMessage: 'Reserved Tables & Seating Areas',
  },
  accessRulesTableReleaseInventory: {
    id: 'perk.accessRulesTable.releaseInventory',
    defaultMessage: 'Release Inventory',
  },
  accessRuleModalCreation: {
    id: 'perk.accessRuleModalCreation',
    defaultMessage: 'Exclusive Access Creation - <i>step {step} of {stepsCount}</i>',
  },
  accessRuleModalSeatingAreasTitle: {
    id: 'perk.accessRuleModalSeatingAreasTitle',
    defaultMessage: 'Select tables or seating areas that you want to be reserved only for your loyal guests',
  },
  accessRuleModalSeatingAreasDescription: {
    id: 'perk.accessRuleModalSeatingAreasDescription',
    defaultMessage: 'Seating Areas and Tables',
  },
  accessRuleModalReserveTablesTitle: {
    id: 'perk.accessRuleModalReserveTablesTitle',
    defaultMessage: 'Release reserved tables after a set time period',
  },
  accessRuleModalReserveTablesDescription: {
    id: 'perk.accessRuleModalReserveTablesDescription',
    defaultMessage: 'If not booked within a certain time frame, make reserved tables available to all booking channels.',
  },
  accessRuleModalChooseAccessRuleTitle: {
    id: 'perk.accessRuleModalChooseAccessRuleTitle',
    defaultMessage: 'Choose a starting point for creating an Access Rule',
  },
  accessRuleModalChooseAccessRuleDescription: {
    id: 'perk.accessRuleModaReserveTablesDescription',
    defaultMessage: 'Click here to learn more about Access Rules',
  },
  accessRuleModalReleaseReservedTables: {
    id: 'perk.accessRuleModalReleaseReservedTables',
    defaultMessage: 'Release reserved tables',
  },
  accessRuleModalDoNotReleaseReservedTables: {
    id: 'perk.accessRuleModalDoNotReleaseReservedTables',
    defaultMessage: 'Do not release reserved tables',
  },
  accessRuleModalCloneAccessRule: {
    id: 'perk.accessRuleModalCloneAccessRule',
    defaultMessage: 'Clone settings from an existing Access Rule',
  },
  accessRuleModalCreateAccessRule: {
    id: 'perk.accessRuleModalCreateAccessRule',
    defaultMessage: 'Create a new Access Rule',
  },
  accessRuleModalCloneAccessRuleRecommended: {
    id: 'perk.accessRuleModalCloneAccessRuleRecommended',
    defaultMessage: 'Recommended',
  },
  accessRuleModalSelectAccessRule: {
    id: 'perk.accessRuleModalSelectAccessRule',
    defaultMessage: 'Select Access Rule',
  },
  confirmDeleteAccessRuleModalDeleteConfirmationBody: {
    id: 'perk.confirmDeleteAccessRuleModal.deleteConfirmationBody',
    defaultMessage: 'Once you delete an Access Rule, it cannot be recovered. This delete will not be finalized until you save the perk.',
  },
  perkCreateUpdateExclusiveErrorTemporary: {
    id: 'perk.perkCreateUpdateExclusiveErrorTemporary',
    defaultMessage: 'There was an error saving the exclusive inventory perk and/or the associated access rules. Please contact support.',
  },
  exclusiveAccess: {
    id: 'perks.exclusiveAccess',
    defaultMessage: 'Exclusive Access',
  },
  exclusiveAccessSuffix: {
    id: 'perks.exclusiveAccessSuffix',
    defaultMessage: '{name} - Exclusive Access',
  },
  copyOf: {
    id: 'perks.copyOf',
    defaultMessage: 'Copy of {name}',
  },
  disabledEditButton: {
    id: 'perks.disabledEditButton',
    defaultMessage: 'This Perk can only be edited from the venue that originally created it.',
  },
  disabledArchiveButton: {
    id: 'perks.disabledArchiveButton',
    defaultMessage: 'This Perk can only be archived from the venue that originally created it.',
  },
} as const)
