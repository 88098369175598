import { skipToken } from '@reduxjs/toolkit/query/react'
import { useEffect, useState } from 'react'
import { useGetAccessRuleActivityLogPreviewsQuery, AccessRuleInput } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, Loader } from '@sevenrooms/core/ui-kit/layout'
import { List, ListItem, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from './AccessRules.locales'
import { toAccessRuleInput } from './toAccessRuleInput'
import type { AccessRuleForm } from './AccessRule.zod'
import type { Recurring } from './components/RecurringDecision'

type AccessRuleInputToServer = Awaited<ReturnType<typeof AccessRuleInput.toServer>>

interface AccessRuleChangesProps {
  accessRuleData: AccessRuleForm
  date: string
  accessRuleId?: string
  decision?: Recurring
  hasBorder: boolean
  introText: string
}

export function AccessRuleChanges({ accessRuleData, date, accessRuleId, decision, hasBorder, introText }: AccessRuleChangesProps) {
  const { formatMessage } = useLocales()
  const { venueId, venueSettings } = useAppContext()
  const [formattedData, setFormattedData] = useState<AccessRuleInputToServer>()
  const {
    data: activityLogs,
    isLoading,
    error,
  } = useGetAccessRuleActivityLogPreviewsQuery(
    formattedData ? { venueId, entityObjectId: accessRuleId, accessRuleData: formattedData } : skipToken,
    { refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    async function format() {
      const prepData = toAccessRuleInput(
        date,
        venueId,
        venueSettings.isGoogleBookingEnabled,
        venueSettings.isTheforkIntegrationEnabled,
        decision
      )
      const serverSideFormattedData = await AccessRuleInput.toServer({ id: accessRuleId, venueId }, prepData(accessRuleData), true)
      serverSideFormattedData.custom_pacing = JSON.parse(serverSideFormattedData.custom_pacing)
      setFormattedData(serverSideFormattedData)
    }

    format()
  }, [
    accessRuleData,
    accessRuleId,
    date,
    decision,
    venueId,
    venueSettings.isGoogleBookingEnabled,
    venueSettings.isTheforkIntegrationEnabled,
  ])

  if (error) {
    return null
  }

  if (isLoading) {
    return (
      <Box mt="m">
        <Loader />
      </Box>
    )
  }

  return (
    <Box backgroundColor="secondaryBackground" borderRadius="s" p="m" mt="m" borderColor={hasBorder ? 'borders' : undefined}>
      {Array.isArray(activityLogs) && activityLogs.length > 0 ? (
        <>
          <Text>{introText}</Text>
          <Box mt="m" data-test="access-rule-change-box">
            <List spacing="s">
              {activityLogs.map((log, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index}>
                  <Text wordBreak="break-word">{log}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <Text>{formatMessage(accessRulesMessages.activityLogNoChanges)}</Text>
      )}
    </Box>
  )
}
