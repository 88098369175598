import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useState } from 'react'
import { useGetPOSIConfigQuery, useGetPOSILandingDataQuery, useGetPOSILocationsQuery } from '@sevenrooms/core/api'
import type { POSIType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { UTCdateToTimezoneFormat } from '../../../../../application/site/static/app/common/DateTime'
import { DefaultErrorBanner } from '../../components'
import { IntegrationStatusPage } from '../../IntegrationStatus'
import { LoadingSettingsNoHeader } from '../../Loading'
import { posiDocumentationLink } from '../../ReferenceData'
import { SelectPage } from '../../Select'
import { messages } from './HomePage.locales'

export function HomePage() {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const [fetchLocations, setFetchLocations] = useState<boolean>(false)

  const {
    data: config,
    isFetching: isFetchingConfig,
    error: configError,
  } = useGetPOSIConfigQuery(venue.posActivated ? { venueId, posiType: venue.posIntegrationType as POSIType } : skipToken)

  useEffect(() => {
    if (config?.isAuthTokens) {
      setFetchLocations(true)
    }
  }, [config?.isAuthTokens])

  // We are using the fetch locations call as an authentication check
  const {
    data: _locations,
    isFetching: isFetchingLocations,
    error: locationsError,
  } = useGetPOSILocationsQuery(fetchLocations ? { venueId, posiType: venue.posIntegrationType as POSIType } : skipToken)

  const { data: landingData, isFetching: isFetchingLandingData, error: landingDataError } = useGetPOSILandingDataQuery({ venueId })

  const isFetching = isFetchingConfig || isFetchingLocations || isFetchingLandingData

  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false)

  if (isFetching || isDisconnecting) {
    return <LoadingSettingsNoHeader />
  }

  if (landingDataError || configError) {
    return (
      <SettingsPage>
        <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
        <SettingsPageContent
          title={formatMessage(messages.title)}
          description={formatMessage(messages.description, {
            a: (chunks: string[]) => <Anchor href={posiDocumentationLink}>{chunks}</Anchor>,
          })}
          secondHeaderMaxWidth="1000px"
        >
          {configError && (
            <Box width="1024px" pl="m">
              <DefaultErrorBanner error={configError as string} />
            </Box>
          )}
          {landingDataError && (
            <Box width="1024px" pl="m">
              <DefaultErrorBanner error={landingDataError as string} />
            </Box>
          )}
        </SettingsPageContent>
      </SettingsPage>
    )
  }

  return config?.isAuthTokens ? (
    <IntegrationStatusPage
      lastUpdatedDateString={
        config?.lastSaved ? DateTime.from(UTCdateToTimezoneFormat(config.lastSaved, venue.timezone)).formatNYearSMonthNDaySTime() : ''
      }
      setIsDisconnecting={setIsDisconnecting}
      locationsError={locationsError ? (locationsError as string) : undefined}
      landingData={landingData ?? []}
    />
  ) : (
    <SelectPage landingData={landingData ?? []} />
  )
}
