import type { Field } from '@sevenrooms/core/form'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'

export interface TextInputProps {
  field: Field<string>
  label: string
  description?: string
  placeholder: string
  isOptional?: boolean
}

export function TextInput({ field, label, description, placeholder, isOptional }: TextInputProps) {
  return (
    <Label
      primary={
        <>
          {label}
          {isOptional ? '' : <sup>*</sup>}
        </>
      }
      secondary={description}
    >
      <FormInput field={field} fullWidth placeholder={placeholder} />
    </Label>
  )
}
