import React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'

interface PerkActionButtonsProps {
  disabled: boolean
  onCancel: () => void
  perkId: string | undefined
}

export function PerkActionButtons({ perkId, disabled, onCancel }: PerkActionButtonsProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack spacing="s">
      <Button data-test="sr-perk-form-cancel" disabled={disabled} variant="tertiary" onClick={onCancel}>
        {formatMessage(commonMessages.cancel)}
      </Button>
      <Button data-test="sr-perk-form-submit" disabled={disabled} variant="primary" type="submit">
        {perkId ? formatMessage(commonMessages.saveChanges) : formatMessage(perksMessages.createPerk)}
      </Button>
    </HStack>
  )
}
