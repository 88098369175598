import { defineMessages } from '@sevenrooms/core/locales'

export const PacingLocales = defineMessages({
  maxCoversPerSeatingInterval: {
    id: 'ar.pacing.maxCoversPerSeatingInterval',
    defaultMessage: 'Max covers per seating interval',
  },
  customPacingOption: {
    id: 'ar.pacing.customPacingOption',
    defaultMessage: 'Set custom Pacing per seating interval',
  },
  default: {
    id: 'ar.pacing.default',
    defaultMessage: 'Default:',
  },
  defaultCollapsedContentLabel: {
    id: 'ar.pacing.defaultCollapsedContentLabel',
    defaultMessage: 'Covers per seating interval:',
  },
  defaultPacingLabel: {
    id: 'ar.pacing.defaultPacingLabel',
    defaultMessage: 'Default pacing',
  },
  description: {
    id: 'ar.pacing.description',
    defaultMessage: 'Ex: If Shift Pacing is 30, but you can only accept up to 20 covers on the Patio at any time, restrict to 20.',
  },
  label: {
    id: 'ar.pacing.label',
    defaultMessage: 'Max number of covers per seating interval',
  },
  limitRequired: {
    id: 'ar.pacing.limitRequired',
    defaultMessage: 'Default pacing is required',
  },
  moreAboutThisSetting: {
    id: 'ar.pacing.moreAboutThisSetting',
    defaultMessage: 'More about this setting',
  },
  pacingDefaultValueRequired: {
    id: 'ar.pacing.pacingDefaultValueRequired',
    defaultMessage: 'Default pacing is required',
  },
  isPacingHeldOption: {
    id: 'ar.pacing.isPacingHeldOption',
    defaultMessage: 'Reduce total Pacing available to account for these potential reservations',
  },
  isPacingHeldNote: {
    id: 'ar.pacing.isPacingHeldNote',
    defaultMessage:
      'Note that this setting will limit availability for your overall Shift. <a>View this table</a> to preview how your Pacing settings on this Access Rule will affect your overall Shift Pacing.',
  },
  subCaption: {
    id: 'ar.pacing.subCaption',
    defaultMessage: "Ensure you don't have too many people starting this experience at one time.",
  },
  timeIntervalHeading: {
    id: 'ar.pacing.timeIntervalHeading',
    defaultMessage: 'Time interval',
  },
  title: {
    id: 'ar.pacing.title',
    defaultMessage: 'Pacing (Advanced)',
  },
  treatAsBlockedTooltip: {
    id: 'ar.pacing.treatAsBlockedTooltip',
    defaultMessage:
      'For example, if overall Shift Pacing is 30 and your setting here is 10, the system will only allow 20 covers to be booked for inventory outside this Access Rule. Even if shift pacing is overbooked by users, the 10 covers for this access rule will be bookable as long as tables are available.',
  },
  unit: {
    id: 'ar.pacing.unit',
    defaultMessage: 'covers',
  },
  pacing: {
    id: 'ar.pacing.pacing',
    defaultMessage: 'Pacing',
  },
  shiftPacing: {
    id: 'ar.pacing.shiftPacing',
    defaultMessage: 'Shift Pacing',
  },
  arPacing: {
    id: 'ar.pacing.arPacing',
    defaultMessage: 'AR Pacing',
  },
  pacingRemaining: {
    id: 'ar.pacing.pacingRemaining',
    defaultMessage: 'Pacing Remaining',
  },
  allPacingReservedWarning: {
    id: 'ar.pacing.allPacingReservedWarning',
    defaultMessage: 'There are Time Slots where this Access Rule is reserving almost all shift pacing.',
  },
  shiftPacingSettings: {
    id: 'ar.pacing.shiftPacingSettings',
    defaultMessage: 'Shift Pacing Settings',
  },
  conflictModalHeading: {
    id: 'ar.pacing.conflictModalHeading',
    defaultMessage: 'How Shift Pacing is affected by this Access Rule',
  },
  conflictModalSubtext: {
    id: 'ar.pacing.conflictModalSubtext',
    defaultMessage:
      'Reducing total Pacing available to account for the potential reservations on this Access Rule will limit overall Shift Pacing in the following way:',
  },
  numCovers: {
    id: 'ar.pacing.numCovers',
    defaultMessage: '{count} covers',
  },
  excludeFromShiftPacing: {
    id: 'ar.pacing.excludeFromShiftPacing',
    defaultMessage: 'Do not count reservations booked through this Access Rule towards Shift Pacing',
  },
  excludeFromShiftPacingTooltipTitle: {
    id: 'ar.pacing.excludeFromShiftPacingTooltipTitle',
    defaultMessage: 'Do not count reservations booked through this Access Rule towards Shift Pacing',
  },
  excludeFromShiftPacingTooltipBody: {
    id: 'ar.pacing.excludeFromShiftPacingTooltipBody',
    defaultMessage:
      'This setting prevents large groups from limiting your availability. For instance, if your Shift Pacing is 30 covers per seating interval and you accept a reservation for 30 but wish to take more bookings for that seating interval, apply this setting. This excludes the reservation from Shift Pacing but doesn’t affect Access Rule Pacing.',
  },
} as const)
