import onClickOutside from 'react-onclickoutside'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { TierAttributes, AutotagConfigType, AutotagTimeFrame } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import { CURRENCIES } from 'svr/lib/Payments/Constants'
import { AutoTagConfigTypeEnum } from '@sevenrooms/core/domain'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const visitChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

const spendChoices = [
  { name: 'total spend', value: 'TOTAL' },
  { name: 'average spend per cover', value: 'AVERAGE_PER_COVER' },
  { name: 'average spend per visit', value: 'AVERAGE_PER_VISIT' },
]

const currencyChoices = CURRENCIES.map(item => ({
  name: `${item.label} (${item.symbol})`,
  value: `${item.code}`,
}))

export class SpendTier extends BaseTier {
  constructor(props) {
    super(props)

    this.validateAmount = this.validateAmount.bind(this)
    this.amountInput = null
    this.spend = null
    this.originalSpend = null
    this.validAmount = true
  }

  handleClickOutside(e) {
    if ((this.originalSpend === '' && this.spend === this.originalSpend) || (!this.props.tier.name_display && this.originalName === '')) {
      this.props.removeTier()
    }
  }

  validateAmount() {
    if (this.originalSpend === '' && this.spend === this.originalSpend) {
      return
    }
    this.validAmount = this.amountInput.isValid() === true
    const tierError = this.validAmount ? null : this.props.index
    this.props.setTierError(tierError)
  }

  componentDidMount() {
    const { tier } = this.props
    if (tier !== null && tier.attributes !== null) {
      this.updateTier('is_edited', false)
    }
    this.originalSpend = this.spend
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes, is_edited = false } = this.props.tier
    const spendTypeText = spendChoices.filter(c => c.value === attributes.SPEND_TYPE)[0].name
    const { isActive, configType, id } = this.props

    const currencyCode = attributes.LOCAL_CURRENCY ? attributes.LOCAL_CURRENCY : attributes.SPEND_CURRENCY || this.props.currencyCode
    this.spend =
      !is_edited && AutoTagConfigTypeEnum.SPEND_GLOBAL && attributes.LOCAL_SPEND ? attributes.LOCAL_SPEND : attributes.SPEND_AMOUNT
    const currencySymbol = CURRENCIES.filter(c => c.code === currencyCode)[0].symbol

    const timeFrameText = {
      [AutotagTimeFrame.WHOLE_DINING_HISTORY]: ' ever.',
      [AutotagTimeFrame.TRAILING_NUMBER_OF_MONTHS]: ` in the past ${this.props.trailingMonths} month(s).`,
      [AutotagTimeFrame.CALENDAR_YEAR]: ' from January 1st to December 31st yearly.',
    }

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            <TierEditor>
              Tag clients with at least
              <DropdownArrowsPicker
                {...dropProps}
                choices={visitChoices}
                value={attributes.MIN_VISITS}
                style={{ ...dropStyle, width: '60px', minWidth: '60px' }}
                optionsContainerStyle={{ maxHeight: 200 }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_VISITS, value)}
              />
              visits and a
              <DropdownArrowsPicker
                {...dropProps}
                choices={spendChoices}
                value={attributes.SPEND_TYPE}
                style={{ ...dropStyle, width: '195px', minWidth: '195px' }}
                onChangeHandler={value => this.updateTierAttribute(TierAttributes.SPEND_TYPE, value)}
              />
              of
              {configType === AutotagConfigType.SPEND_GLOBAL ? (
                <span>
                  <br />
                  <DropdownArrowsPicker
                    {...dropProps}
                    disabled
                    choices={currencyChoices}
                    value={currencyCode}
                    style={{ ...dropStyle, width: '100px', minWidth: '100px' }}
                    optionsContainerStyle={{ maxHeight: 200 }}
                    onChangeHandler={value => this.updateLockedTierAttributes(TierAttributes.SPEND_CURRENCY, value)}
                  />
                </span>
              ) : null}
              <TextInput
                value={String(this.spend)}
                placeholder="Amount"
                showLabel={false}
                name="SPEND_AMOUNT"
                isValid={this.validAmount}
                prefixSymbol={currencySymbol}
                inputRestriction={InputRestrictions.integer}
                validator={ValidatorTypes.notEmpty}
                ref={e => {
                  this.amountInput = e
                }}
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  width: '90px',
                  margin: '0 5px',
                }}
                onBlur={this.validateAmount}
                onChange={value => {
                  this.updateTier('is_edited', true)
                  this.updateTierAttribute(TierAttributes.SPEND_AMOUNT, value, true)
                  if (configType === AutotagConfigType.SPEND_GLOBAL) {
                    this.updateLockedTierAttributes(TierAttributes.SPEND_CURRENCY, this.props.currencyCode)
                  }
                  this.props.siblingTiers.forEach(tier => {
                    if (
                      tier.attributes.LOCAL_SPEND &&
                      name !== tier.name_display &&
                      tier.tier_type === 'SPEND_GLOBAL_TIER' &&
                      !tier.is_edited
                    ) {
                      tier.attributes.SPEND_AMOUNT = tier.attributes.LOCAL_SPEND // eslint-disable-line no-param-reassign
                    }
                  })
                }}
              />
              {configType === AutotagConfigType.SPEND_GLOBAL && timeFrameText[this.props.timeFrame]}
            </TierEditor>
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>
              Tag clients with at least <strong>{attributes.MIN_VISITS}</strong> visit(s) and a <strong>{spendTypeText}</strong> of{' '}
              <strong>
                {currencySymbol}
                {this.spend}
              </strong>
            </TierContent>
          </Tier>
        )}
      </div>
    )
  }
}
export default onClickOutside(SpendTier)
