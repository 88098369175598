import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReferrersEmailConfigurationsFormData = ZodSchema<ReturnType<typeof useReferrersEmailConfigurationsForm>>
export type ReferrersEmailConfigurationsFormFields = Field<ReferrersEmailConfigurationsFormData>

export const useReferrersEmailConfigurationsForm = () =>
  useMemo(
    () =>
      z.object({
        bookingConfirmationEmail: z.boolean(),
        feedbackEmail: z.boolean(),
        feedbackSuccessPage: z.boolean(),
      }),
    []
  )
