import React from 'react'
import { AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import { Tier, TierTitle, TierContent } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier from './BaseTier'

export class BookedByThirdPartyTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getContent() {
    const type = this.props.tier.tier_type
    switch (type) {
      case AutotagTierType.BOOKED_THROUGH_OPENTABLE:
        return <div>Tag guests who booked through OpenTable and visited at least once in the last day.</div>
      default:
        return null
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { isActive, id } = this.props

    return (
      <div id={id}>
        <Tier onClick={this.toggleEditing} isActive={isActive}>
          <TierTitle>{name}</TierTitle>
          <TierContent>{this.getContent()}</TierContent>
          {this.fetchAdditionalOptions()}
        </Tier>
      </div>
    )
  }
}

export default BookedByThirdPartyTier
