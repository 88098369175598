import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly, addDays, nextMonday } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { ScheduleTestId } from '../Schedule.testIds'
import { formatDates, formatShifts, formatTimes } from '../utils'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function ScheduleReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()
  const { allShifts } = useAccessRuleContext()

  const daysOfWeek = getDaysOfWeekLocales()

  const {
    selectedDays,
    dateRange: { startDate, endDate, isInfinite },
    accessTimeType,
    startTime,
    endTime,
    specificTimes,
    shiftCategories,
  } = accessRule.schedule

  const formattedDateRange = formatDates(formatMessage, startDate ?? new Date(0), isInfinite, endDate)
  const formattedShifts = formatShifts(shiftCategories, allShifts, formatMessage)
  const formattedTime = formatTimes(
    accessTimeType,
    specificTimes,
    allShifts,
    shiftCategories,
    startTime ? TimeOnly.fromSafe(startTime) : undefined,
    endTime ? TimeOnly.fromSafe(endTime) : undefined
  )

  return (
    <ReportPart caption={formatMessage(accessRulesMessages.scheduleHeader)} data-test={ScheduleTestId.report}>
      {!accessRule.isOverride && (
        <ReportLine name={formatMessage(commonMessages.days)}>
          <Text>{selectedDays.map(day => daysOfWeek[day]).join(', ')}</Text>
        </ReportLine>
      )}
      <ReportLine name={formatMessage(commonMessages.dateRange)}>
        <Text>{formattedDateRange}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(commonMessages.times)}>
        <Text>{formattedTime}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(accessRulesMessages.shiftCategories)}>
        <Text>{formattedShifts}</Text>
      </ReportLine>
    </ReportPart>
  )
}

function getDaysOfWeekLocales() {
  const startOfWeek = nextMonday(new Date())
  return [0, 1, 2, 3, 4, 5, 6].map(daysDiff => {
    const day = addDays(startOfWeek, daysDiff)
    return DateOnly.fromDate(day).formatSWeek()
  })
}
