import { useCallback, useMemo } from 'react'
import { AdditionalInfoEnum, type EmailTemplate } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { ColorPicker, DEFAULT_COLOR, Label, Radio, RangeSlider, ImageUploader } from '@sevenrooms/core/ui-kit/form'
import { Box, EmailPreview, HStack, useBoundary, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { guestEmailSMSMessages } from './GuestEmailSMS.locales'
import type { GuestEmailSMSForm } from './GuestEmailSMS.zod'

export interface EmailPreviewContaierProps {
  field: Field<GuestEmailSMSForm>
  emailTemplate: EmailTemplate
}

export function EmailTemplateEditor({ field, emailTemplate }: EmailPreviewContaierProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()

  const logoValue = field.prop('logo').watch()
  const emailLogoHeightValue = field.prop('emailLogoHeight').watch()
  const venueColorShowValue = field.prop('venueColorShow').watch()
  const venueColorValue = field.prop('venueColor').watch()
  const altColorShowValue = field.prop('altColorShow').watch()
  const altColorValue = field.prop('altColor').watch()

  const boundary = useBoundary(
    {
      maxHeight: emailTemplate.logoSize.height,
      maxWidth: emailTemplate.logoMaxWidth,
      currentHeight: emailLogoHeightValue,
    },
    logoValue?.crop
  )

  const minPercentage = Math.round((boundary.byHeight.min * 100) / emailTemplate.logoSize.height)

  const percentageToRange = useCallback(
    (value: number) => {
      const percentageByRange = (value * (boundary.byHeight.max - boundary.byHeight.min)) / 100
      return Math.round(percentageByRange)
    },
    [boundary]
  )

  const rangeToPercentage = useCallback(
    (value: number) => {
      const rangeByPercentage = (value * 100) / (boundary.byHeight.max - boundary.byHeight.min)
      return Math.round(rangeByPercentage)
    },
    [boundary]
  )

  const venueColor = venueColorShowValue === AdditionalInfoEnum.YES ? venueColorValue : DEFAULT_COLOR

  const previewVariables = useMemo(
    () => ({
      logo_height: boundary.byHeight.calculatedHeight,
      logo_width: boundary.byHeight.calculatedWidth,
      venue_logo: logoValue ? logoValue.preview || logoValue.rawUrl : '',
      venue_color: venueColor,
      alt_color: altColorShowValue === AdditionalInfoEnum.YES ? altColorValue : venueColor,
    }),
    [altColorShowValue, altColorValue, boundary, logoValue, venueColor]
  )

  return (
    <HStack spacing="lm" pt="s">
      <VStack spacing="m" pb="m">
        <ImageUploader label={formatMessage(guestEmailSMSMessages.uploadLogoTitle)} field={field.prop('logo')} data-test="logo" />
        <Label primary={formatMessage(guestEmailSMSMessages.emailLogoHeightTitle)}>
          <RangeSlider
            min={minPercentage}
            value={rangeToPercentage(emailLogoHeightValue)}
            onChange={value => field.prop('emailLogoHeight').set(percentageToRange(value))}
            data-test="emailLogoHeight"
            suffix="%"
          />
        </Label>
        <VStack spacing="s">
          <Label primary={formatMessage(guestEmailSMSMessages.venueColorTitle)} noMargin />
          <Radio
            field={field.prop('venueColorShow')}
            data-test="venueColorShow-NO"
            value={AdditionalInfoEnum.NO}
            checked={venueColorShowValue === AdditionalInfoEnum.NO}
          >
            {formatMessage(guestEmailSMSMessages.venueColorDefault)}
          </Radio>
          <Radio
            field={field.prop('venueColorShow')}
            data-test="venueColorShow-YES"
            value={AdditionalInfoEnum.YES}
            checked={venueColorShowValue === AdditionalInfoEnum.YES}
          >
            {formatMessage(guestEmailSMSMessages.customColor)}
          </Radio>
          {venueColorShowValue === AdditionalInfoEnum.YES && (
            <Box pl="lm">
              <ColorPicker field={field.prop('venueColor')} data-test="venueColor" />
            </Box>
          )}
        </VStack>
        <VStack spacing="s">
          <Label primary={formatMessage(guestEmailSMSMessages.altColorTitle)} noMargin />
          <Radio
            field={field.prop('altColorShow')}
            data-test="altColorShow-NO"
            value={AdditionalInfoEnum.NO}
            checked={altColorShowValue === AdditionalInfoEnum.NO}
          >
            {formatMessage(guestEmailSMSMessages.altColorDefault)}
          </Radio>
          <Radio
            field={field.prop('altColorShow')}
            data-test="altColorShow-YES"
            value={AdditionalInfoEnum.YES}
            checked={altColorShowValue === AdditionalInfoEnum.YES}
          >
            {formatMessage(guestEmailSMSMessages.customColor)}
          </Radio>
          {altColorShowValue === AdditionalInfoEnum.YES && (
            <Box pl="lm">
              <ColorPicker field={field.prop('altColor')} data-test="altColor" />
            </Box>
          )}
        </VStack>
      </VStack>
      <VStack flexGrow={1}>
        <Label primary={formatMessage(guestEmailSMSMessages.emailPreviewTitle)} />
        <EmailPreview
          scale={0.85}
          template={emailTemplate.template}
          variablesFromTemplate={emailTemplate.variables}
          variables={previewVariables}
          venueName={venue?.name}
        />
      </VStack>
    </HStack>
  )
}
