import type { AccessRule } from '@sevenrooms/core/domain'
import type { MultiSelectTagOptionsForm } from '@sevenrooms/core/ui-kit/form'
import type { TagGroup } from '../../AccessRule.types'

export function getInitialReservationTags(initParams: {
  accessRule?: AccessRule
  tagGroups: Map<string, TagGroup>
}): MultiSelectTagOptionsForm {
  const { accessRule, tagGroups } = initParams
  const availableTags: string[] = []
  tagGroups.forEach(tagGroup =>
    tagGroup.tags.forEach(tag => availableTags.push(generateTagHash(tagGroup.privacy, tagGroup.id, tagGroup.name, tag)))
  )
  return accessRule ? parseTagHash(accessRule.reservationTags.filter(tag => availableTags.includes(tag))) : []
}

export function parseTagHash(reservationTags: string[]) {
  const selectedTags: { id: string; categoryId: string; label: string }[] = []
  for (const tagHash of reservationTags) {
    const tagParts = tagHash.split('##')
    const tagGroupId = tagParts[1]
    const tagName = tagParts[3]
    if (tagGroupId && tagName) {
      selectedTags.push({ id: tagHash, categoryId: tagGroupId, label: tagName })
    }
  }
  return selectedTags
}

export function generateTagHash(privacy: string, groupId: string, groupName: string, tagName: string) {
  return [privacy, groupId, groupName, tagName].join('##')
}
