import { Fragment } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { FormattedTimeBefore } from '../../shared/FormattedTimeBefore'
import { BookingChannelsLocales } from '../BookingChannels.locales'
import { BookingChannelsTestId } from '../BookingChannels.testIds'
import { BookingChannelAudience } from './BookingChannelAudience'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function BookingsChannelsReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()
  const { bookingChannels } = accessRule
  return (
    <ReportPart caption={formatMessage(BookingChannelsLocales.title)} data-test={BookingChannelsTestId.report}>
      {bookingChannels.map((tier, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={idx}>
          {venueSettings.isExclusiveArAccessEnabled ? (
            <>
              <ReportLine name={formatMessage(BookingChannelsLocales.tierAndBookingChannelsLabel, { count: idx + 1 })}>
                <Text>{tier.selected.map(({ label }) => label).join(', ')}</Text>
              </ReportLine>
              <ReportLine name={formatMessage(BookingChannelsLocales.tierAndAudiencesLabel, { count: idx + 1 })}>
                <BookingChannelAudience tier={tier} />
              </ReportLine>
            </>
          ) : (
            <ReportLine name={formatMessage(BookingChannelsLocales.tierAndAudiencesLabel, { count: idx + 1 })}>
              <Text>{tier.selected.map(({ label }) => label).join(', ')}</Text>
            </ReportLine>
          )}
          {bookingChannels.length > 1 && (
            <ReportLine name={formatMessage(BookingChannelsLocales.tierAndStartTimeLabel, { count: idx + 1 })}>
              <FormattedTimeBefore {...tier.startTime} />
            </ReportLine>
          )}
        </Fragment>
      ))}
    </ReportPart>
  )
}
