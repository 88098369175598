import { memo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { MultiSelectTree, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import type { PerkCreateAccessRuleModalFormField, TreeItemValue } from './PerkCreateAccessRuleModalForm.zod'

interface PerkCreateAccessRuleModalSeatingAreasProps {
  field: PerkCreateAccessRuleModalFormField
  seatingAreaCodesToTables: TreeNode<TreeItemValue>[]
}

export const PerkCreateAccessRuleModalSeatingAreas = memo(PerkCreateAccessRuleModalSeatingAreasComponent)

export function PerkCreateAccessRuleModalSeatingAreasComponent({
  field,
  seatingAreaCodesToTables,
}: PerkCreateAccessRuleModalSeatingAreasProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="s" mt="s" height="320px">
      <SecondaryText>{formatMessage(perksMessages.accessRuleModalSeatingAreasDescription)}</SecondaryText>
      <MultiSelectTree<TreeItemValue>
        initialIsOpen
        alwaysOpen
        treeData={seatingAreaCodesToTables ?? []}
        displayAsTextWhenCollapsed
        containerWidth="100%"
        treeMaxHeight={200}
        field={field.prop('seatingAreasTables')}
      />
    </VStack>
  )
}
