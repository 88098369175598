import type { Disabled } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TreeNode } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'
import { PerkConciergesSelectForm } from '../PerkConciergesSelectForm'
import { PerkIsConciergePortalForm } from '../PerkIsConciergePortalForm'
import { PerkSettingsAdditionalInformationForm } from '../PerkSettingsAdditionalInformationForm'
import type { PerkConciergeSettingsFormField } from './PerkConciergeSettingsForm.zod'

interface PerkConciergeSettingsFormProps {
  field: PerkConciergeSettingsFormField
  disabled: Disabled
  collapsed: boolean
  onToggleCollapse: () => void
  conciergeTreeData: TreeNode[]
}

export function PerkConciergeSettingsForm({
  disabled,
  field,
  onToggleCollapse,
  collapsed,
  conciergeTreeData,
}: PerkConciergeSettingsFormProps) {
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(perksMessages.perkAdditionalSettings)} collapsed={collapsed} onToggleCollapse={onToggleCollapse}>
      <Box p="lm">
        <PerkIsConciergePortalForm conciergeEnabledField={field.prop('conciergeEnabled')} disabled={disabled} />
      </Box>
      <>
        <Box p="lm">
          <PerkSettingsAdditionalInformationForm
            conciergeEnabledField={field.prop('conciergeEnabled')}
            disabled={disabled}
            additionalInformationField={field.prop('additionalInformation')}
          />
        </Box>
        <Box p="lm" maxWidth="500px">
          <PerkConciergesSelectForm
            conciergeEnabledField={field.prop('conciergeEnabled')}
            disabled={disabled}
            conciergesField={field.prop('concierges')}
            conciergeTreeData={conciergeTreeData}
          />
        </Box>
      </>
    </BaseSection>
  )
}
