import { defineMessages } from '@sevenrooms/core/locales'

export const messagingIntegrationMessages = defineMessages({
  tabTitle: {
    id: 'messagingIntegrationMessages.tabTitle',
    defaultMessage: 'Messaging Providers',
  },
  title: {
    id: 'messagingIntegrationMessages.title',
    defaultMessage: 'Messaging Providers',
  },
  description: {
    id: 'messagingIntegrationMessages.description',
    defaultMessage: 'Tra la la',
  },
  whatsapp: {
    id: 'messagingIntegrationMessages.whatsapp',
    defaultMessage: 'WhatsApp',
  },
  whatsappLogoAlt: {
    id: 'messagingIntegrationMessages.whatsappLogoAlt',
    defaultMessage: 'WhatsApp Logo',
  },
} as const)
