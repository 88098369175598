import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../AccessRules.locales'
import { SharedTestId } from './shared.testIds'
import type { ReactNode } from 'react'

export interface AccessRuleModalProps {
  onClose: () => void
  onConfirm: () => void
  modalBody?: ReactNode
}

export function AccessRuleModal({ onClose, onConfirm, modalBody }: AccessRuleModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal data-test={SharedTestId.defaultSettingsModal} ariaLabel="main" width={700} minWidth="700px">
      <ModalHeader
        onClose={e => {
          e.preventDefault()
          onClose()
        }}
      >
        <ModalTitle
          title={formatMessage(accessRulesMessages.defaultSettingsModalTitle)}
          subTitle={formatMessage(accessRulesMessages.defaultSettingsModalSubtext)}
        />
      </ModalHeader>

      <ModalBody>{modalBody}</ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button
            data-test={SharedTestId.defaultSettingsCancel}
            variant="secondary"
            onClick={e => {
              e.preventDefault()
              onClose()
            }}
          >
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            data-test={SharedTestId.defaultSettingsYes}
            variant="primary"
            onClick={e => {
              e.preventDefault()
              onConfirm()
            }}
          >
            {formatMessage(accessRulesMessages.defaultSettingsYes)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
