import { useCallback, useMemo } from 'react'
import { useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { FieldErrors, MultiSelectTree, RadioGroup, type FlattenTreeNode, type RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AccessRuleTooltip } from '../../shared'
import { MissingPayments } from '../../shared/MissingPayments'
import { UpgradesLocales } from '../Upgrades.locales'
import { UpgradesTestId } from '../Upgrades.testIds'
import type { UpgradesFieldsProps, UpgradesValue } from './UpgradesProps'
import type { UpgradesForm } from '../Upgrades.zod'

export function UpgradesFields({ field, options }: UpgradesFieldsProps) {
  const { formatMessage } = useLocales()
  const { paymentsEnabled } = useAppContext().venueSettings

  const includeUpgrades = useWatch(field.prop('includeUpgrades'))

  const choices: RadioChoice<UpgradesForm['includeUpgrades']>[] = useMemo(
    () => [
      {
        value: 'excluded',
        label: formatMessage(UpgradesLocales.excludedRadioLabel),
      },
      {
        value: 'included',
        label: formatMessage(UpgradesLocales.includedRadioLabel),
        description: formatMessage(UpgradesLocales.includedRadioDescription),
      },
    ],
    [formatMessage]
  )

  const tooltip = (
    <AccessRuleTooltip data-test={UpgradesTestId.bundledUpgradesTooltip}>
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(UpgradesLocales.tooltipHeading)}
      </Text>
      <Text color="lightFont">{formatMessage(UpgradesLocales.tooltipContent)}</Text>
    </AccessRuleTooltip>
  )

  const optionInfo = useCallback(
    (option: FlattenTreeNode<UpgradesValue>) => {
      const { isRequired } = option.value.value
      return isRequired != null ? (
        <Text>
          <Text>{'- '}</Text>
          <Text color="secondaryFont">{isRequired ? formatMessage(commonMessages.required) : formatMessage(commonMessages.optional)}</Text>
        </Text>
      ) : null
    },
    [formatMessage]
  )

  return (
    <>
      {!paymentsEnabled && <MissingPayments />}
      <VStack spacing="s">
        <RadioGroup data-test={UpgradesTestId.includeUpgradesRadios} choices={choices} field={field.prop('includeUpgrades')} />
        <div hidden={includeUpgrades === 'excluded'}>
          <Box pl="lm">
            <MultiSelectTree
              data-test={UpgradesTestId.upgradesMultiSelect}
              id="ar-upgrades__multi-select-tree"
              placeholder={formatMessage(UpgradesLocales.multiSelectPlaceholder)}
              treeData={options}
              field={field.prop('selection')}
              extraNodeContent={optionInfo}
              hideErrorMessage
            />
            <FieldErrors data-test={UpgradesTestId.upgradesErrors} field={field} />
          </Box>
        </div>
        <Text color="secondaryFont" fontStyle="italic">
          {formatMessage(UpgradesLocales.asterisk, {
            strong: (chunks: string[]) => (
              <Text color="secondaryFont" fontStyle="italic" fontWeight="bold">
                {chunks} {tooltip}
              </Text>
            ),
          })}
        </Text>
      </VStack>
    </>
  )
}
