import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleSlideoutSection } from '../shared'
import { CollapsedPartySize, DefaultPartySize, PartySizeFields, type PartySizeProps } from './components'
import { PartySizeLocales } from './PartySize.locales'
import { PartySizeTestId } from './PartySize.testIds'
import type { DurationsForm } from '../Durations/Durations.zod'

interface ExtendedPartySizeProps extends PartySizeProps {
  durationsField: Field<DurationsForm>
}

export function PartySize({ field, durationsField, isPrivateEventAccessRule }: ExtendedPartySizeProps) {
  const { formatMessage } = useLocales()

  const { useShiftDurations, durationsConfigType, durationsByPartySize } = useWatch(durationsField)
  let durationsMinPartySize
  let durationsMaxPartySize
  if (!useShiftDurations && durationsConfigType === 'BY_PARTY_SIZE') {
    const keys = Object.entries(durationsByPartySize)
      .filter(x => x[1])
      .map(x => parseInt(x[0]))
    durationsMinPartySize = Math.min(...keys)
    durationsMaxPartySize = Math.max(...keys)
  }

  return (
    <AccessRuleSlideoutSection
      data-test={PartySizeTestId.section}
      title={formatMessage(PartySizeLocales.title)}
      description={formatMessage(PartySizeLocales.description)}
      subCaption={formatMessage(PartySizeLocales.subCaption)}
      contentWhenCollapsed={
        <CollapsedPartySize field={field} durationsMinPartySize={durationsMinPartySize} durationsMaxPartySize={durationsMaxPartySize} />
      }
      defaultSettings={<DefaultPartySize />}
      sectionControllerName="partySize"
      field={field}
    >
      <PartySizeFields field={field} isPrivateEventAccessRule={isPrivateEventAccessRule} />
    </AccessRuleSlideoutSection>
  )
}
