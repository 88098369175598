import React from 'react'
import type { LightspeedKLocation } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseLocationSelectionCard } from '../BaseLocationSelectionCard'
import { locationSelectOption, type LocationSelectionCardProps } from '../LocationSelectionCard'
import { messages } from '../LocationSelectionCard.locales'

export interface LightspeedKLocationSelectionCardProps extends LocationSelectionCardProps {
  locations: LightspeedKLocation[]
}
export function LightspeedKLocationSelectionCard({ field, locations, disabled }: LightspeedKLocationSelectionCardProps) {
  const { formatMessage } = useLocales()
  const selectOptions: SelectOption[] = locations.map(location => locationSelectOption(location))
  return (
    <BaseLocationSelectionCard docLink={posiMetaDataMap.LIGHTSPEED_K.docLink}>
      <Label width="50%" primary={formatMessage(messages.locationLabel)}>
        <FormSelect field={field.prop('locationId')} options={selectOptions} disabled={disabled} />
      </Label>
    </BaseLocationSelectionCard>
  )
}
