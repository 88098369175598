/* eslint-disable react/sort-comp */
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import DataHeavyLayout from 'mgr/pages/shared/layouts/DataHeavy'
import {
  onChangeVenue,
  tryLoadAutotagsData,
  tryLoadAutotag,
  toggleDeleteWarning,
  removeTier,
  toggleCustomAutotagSlideout,
  getAutoTagsList,
  fetchAutotagCampaignsAndPerks,
} from 'mgr/pages/single-venue/marketing/actions/Autotags'
import { onAutoTagsDateFilterChange, onCustomDateRangeChange } from 'mgr/pages/single-venue/marketing/actions/Filters'
import { getPerksList } from 'mgr/pages/single-venue/marketing/actions/Perks'
import AutoTagsActivityChart from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagsActivityChart'
import AutoTagsFilters from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagsFilters'
import { DeleteWarning } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import AutoTagsList from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagsList'
import DeactivateConfirmation from 'mgr/pages/single-venue/marketing/components/auto-tags/ConfirmDeactivate'
import { ActionButton } from 'mgr/pages/single-venue/marketing/components/shared/ActionButton'
import CustomAutotagEditor from 'mgr/pages/single-venue/marketing/containers/CustomAutotagEditor'
import ShowAutotag from 'mgr/pages/single-venue/marketing/containers/ShowAutotag'
import { dateFilters } from 'mgr/pages/single-venue/marketing/reducers/Filters'
import { selectAutotagsActivityReportValues, selectAutotagsListValues } from 'mgr/pages/single-venue/marketing/selectors/Autotags'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'

class Autotags extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onChangeVenue = this.onChangeVenue.bind(this)
    this.listRowClick = this.listRowClick.bind(this)
    this.firstThClick = this.firstThClick.bind(this)
    this.state = {
      defaultSort: {
        id: 'total_clients',
        desc: true,
      },
    }
  }

  componentDidMount() {
    const { tryLoadAutotagsData, getAutoTagsList, venue, getPerksList } = this.props

    tryLoadAutotagsData(venue)
    getAutoTagsList(venue.id)
    getPerksList(venue.id)

    window.metric.track('AutoTaggging.ClickedTab')
  }

  listRowClick(data) {
    if (data.original.autotag_config_is_global && !this.props.canManageGlobalAutoTags) {
      return
    }
    this.props.tryLoadAutotag(this.props.venue, data.original)
    this.props.fetchAutotagCampaignsAndPerks(this.props.venue, data.original.tag_id, data.original.autotag_config_id)
  }

  onChangeVenue(venue) {
    this.props.onChangeVenue(venue)
  }

  firstThClick(columnAccessor) {
    this.setState({
      defaultSort: {
        id: columnAccessor,
        desc: !this.state.defaultSort.desc,
      },
    })
  }

  render() {
    const {
      venue,
      dateValue,
      onAutoTagsDateFilterChange,
      formattedActivityReport,
      isLoading,
      activeTags,
      inactiveTags,
      warning,
      removeTier,
      toggleDeleteWarning,
      currencyCode,
      locale,
      lastUpdated,
      rebuildState,
      startDate,
      endDate,
      onCustomDateRangeChange,
      tryLoadAutotagsData,
      venues,
      showCustomAutotagEditor,
      customTier,
      customAutotagsEnabled,
      confirmDeactivate,
    } = this.props
    const dateRange = dateFilters.find(filter => filter.value === dateValue).name

    return (
      <ContentLayout
        title="Auto-tags"
        venue={venue}
        venues={venues}
        onChangeVenue={this.onChangeVenue}
        Actions={
          customAutotagsEnabled && (
            <ActionButton id="sr-create-autotag" onClick={this.props.toggleCustomAutotagSlideout}>
              &#65291; Create Auto-tag
            </ActionButton>
          )
        }
      >
        <DataHeavyLayout
          DataFilter={
            <AutoTagsFilters
              onDateFilterChange={onAutoTagsDateFilterChange}
              dateValue={dateValue}
              startDate={startDate}
              endDate={endDate}
              locale={locale}
              onCustomDateRangeChange={(type, val) => {
                onCustomDateRangeChange(type, val)
                tryLoadAutotagsData(venue)
              }}
            />
          }
          DataSummary={
            <AutoTagsActivityChart
              data={formattedActivityReport}
              dateRange={dateRange}
              lastUpdated={lastUpdated}
              rebuildState={rebuildState}
              isLoading={isLoading}
            />
          }
          DataList={
            <AutoTagsList
              isLoading={isLoading}
              activeTags={activeTags}
              inactiveTags={inactiveTags}
              rowClick={this.listRowClick}
              dateRange={dateRange}
              currencyCode={currencyCode}
              locale={locale}
              firstThClick={this.firstThClick}
              defaultSort={this.state.defaultSort}
            />
          }
        />
        <ShowAutotag />
        {showCustomAutotagEditor && <CustomAutotagEditor key={`sr-custom-autotag-editor-${customTier}`} />}
        {warning ? <DeleteWarning proceed={removeTier} cancel={toggleDeleteWarning} /> : null}
        {confirmDeactivate && <DeactivateConfirmation isCustomAutoTag={showCustomAutotagEditor} />}
      </ContentLayout>
    )
  }
}

const mapStateToProps = state => {
  const formattedActivityReport = selectAutotagsActivityReportValues(state)
  const tagLists = selectAutotagsListValues(state)
  const { venue } = state.appState
  const { userDomain } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  const editState = state.autotags.editor
  const customAutotagsEnabled = !!_.get(state, ['appState', 'venueSettings', 'is_custom_autotags_enabled'])
  const showCustomAutotagEditor =
    customAutotagsEnabled && (editState.isCustomAutotagEditorVisible || _.get(editState, ['tag', 'is_config_type_custom'], null))
  const customTier = _.isNumber(editState.customTier) ? String(editState.customTier) : '*'
  return {
    venue,
    venues,
    autotagPerks: state.autotagPerks,
    isLoading: state.autotags.data.isLoading,
    dateValue: state.autotags.filters.date,
    autotagsList: state.autotags.data.list,
    inactiveTags: tagLists.inactiveTags,
    activeTags: tagLists.activeTags,
    warning: state.autotags.editor.warning,
    formattedActivityReport,
    currencyCode: state.appState.venue.currencyCode,
    locale: state.appState.venue.locale,
    lastUpdated: state.autotags.data.lastUpdated,
    rebuildState: state.autotags.data.rebuildState,
    startDate: state.autotags.filters.startDate,
    endDate: state.autotags.filters.endDate,
    confirmDeactivate: state.autotags.editor.confirmDeactivate,
    canManageGlobalAutoTags: state.appState.userDomain.can_manage_global_auto_tags,
    showCustomAutotagEditor,
    customTier,
    customAutotagsEnabled,
  }
}

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      onChangeVenue,
      onAutoTagsDateFilterChange,
      tryLoadAutotagsData,
      tryLoadAutotag,
      getAutoTagsList,
      getPerksList,
      removeTier,
      toggleDeleteWarning,
      onCustomDateRangeChange,
      toggleCustomAutotagSlideout,
      fetchAutotagCampaignsAndPerks,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Autotags))
