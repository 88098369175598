import { useCallback, useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AccessRuleSlideoutSection, useAccessRuleContext } from '../shared'
import { CollapsedUpgrades, DefaultUpgrades, UpgradesFields, type UpgradesProps } from './components'
import { UpgradesLocales } from './Upgrades.locales'
import { UpgradesTestId } from './Upgrades.testIds'
import { getMultiSelectOptions } from './utils'

export function Upgrades({ field }: UpgradesProps) {
  const { accessRule, upsells } = useAccessRuleContext()
  const { venueCurrencyCode } = useAppContext()
  const { formatMessage } = useLocales()

  const options = useMemo(() => getMultiSelectOptions(upsells, venueCurrencyCode, accessRule), [upsells, accessRule, venueCurrencyCode])
  const onEnterEditMode = useCallback(() => {
    field.prop('edited').set(true)
  }, [field])

  return (
    <AccessRuleSlideoutSection
      data-test={UpgradesTestId.section}
      title={formatMessage(UpgradesLocales.title)}
      description={formatMessage(UpgradesLocales.description, {
        a: (chunks: string[]) => (
          <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360007546972-Upgrades-Allow-Guests-to-Customize-Their-Reservation">
            {chunks}
          </Anchor>
        ),
      })}
      subCaption={formatMessage(UpgradesLocales.subCaption)}
      contentWhenCollapsed={<CollapsedUpgrades field={field} options={options} />}
      defaultSettings={<DefaultUpgrades />}
      sectionControllerName="upgrades"
      field={field}
      onEnterEditMode={onEnterEditMode}
    >
      <UpgradesFields field={field} options={options} />
    </AccessRuleSlideoutSection>
  )
}
