import type { Disabled } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { DayOfWeekPicker } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/react-components/components/Box'

export interface PerkDayOfWeekProps {
  dowOfferedField: Field<number[]>
  disabled: Disabled
}

export function PerkDayOfWeek({ dowOfferedField, disabled }: PerkDayOfWeekProps) {
  return (
    <Box width="100%">
      <DayOfWeekPicker
        disabled={disabled}
        data-test="sr-perk-days-of-the-week"
        field={dowOfferedField}
        name="dowOffered"
        hideSelectAllDays
      />
    </Box>
  )
}
