import type { AccessRule } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { perksMessages } from '../../locales'

export type Recurring = 'override' | 'following' | 'all'

export interface CreateEditExclusivePerkConfirmDeleteAccessRuleModalProps {
  accessRuleToDelete?: AccessRule
  setAccessRuleToDelete: (value: AccessRule | undefined) => void
  onConfirm: (accessRule: AccessRule) => void
}

export function CreateEditExclusivePerkConfirmDeleteAccessRuleModal({
  accessRuleToDelete,
  setAccessRuleToDelete,
  onConfirm,
}: CreateEditExclusivePerkConfirmDeleteAccessRuleModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Window active={!!accessRuleToDelete}>
      {accessRuleToDelete && (
        <Modal ariaLabel="main" data-test="create-edit-exclusive-perk-confirm-delete-access-rule-modal">
          <ModalHeader
            onClose={e => {
              e.preventDefault()
              setAccessRuleToDelete(undefined)
            }}
          >
            <ModalTitle
              title={formatMessage(accessRulesMessages.deleteConfirmationTitle, {
                name: accessRuleToDelete.name,
                decision: 'all',
              })}
            />
          </ModalHeader>

          <ModalBody>
            <Text>{formatMessage(perksMessages.confirmDeleteAccessRuleModalDeleteConfirmationBody)}</Text>
          </ModalBody>

          <ModalFooter>
            <ModalActions>
              <Button
                data-test="create-edit-exclusive-perk-confirm-delete-access-rule-modal-cancel"
                variant="secondary"
                onClick={e => {
                  e.preventDefault()
                  setAccessRuleToDelete(undefined)
                }}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button
                type="button"
                data-test="create-edit-exclusive-perk-confirm-delete-access-rule-modal-confirm"
                variant="primary-warning"
                onClick={() => {
                  onConfirm(accessRuleToDelete)
                  setAccessRuleToDelete(undefined)
                }}
              >
                {formatMessage(commonMessages.delete)}
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      )}
    </Window>
  )
}
