import { AccountTypes } from '@sevenrooms/payments'
import AdyenLogo from './images/adyen-logo.svg'
import CybersourceLogo from './images/cybersource-logo.svg'
import FreedompayLogo from './images/freedompay-logo.svg'
import NILogo from './images/network-international-logo.svg'
import PropertiesBackground from './images/properties_backround.svg'
import SaferpayLogo from './images/saferpay-logo.svg'
import Shift4Logo from './images/shift4-logo.svg'
import StripeLogo from './images/stripe-logo.svg'

export interface IntegrationMappingInterface {
  logo: string
  title: string
  doc: string
  errorResolveLink: string
  isSelfServeDisabled?: boolean
  [key: string]: string | boolean | undefined
}

export const IntegrationsMapping: { [key: string]: IntegrationMappingInterface } = {
  [AccountTypes.STRIPE]: {
    logo: StripeLogo,
    title: 'Stripe',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/360005011531',
    errorResolveLink: 'https://support.stripe.com/',
  },
  [AccountTypes.ADYEN]: {
    logo: AdyenLogo,
    title: 'Adyen',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/10038338617627',
    errorResolveLink: 'https://help.adyen.com/',
  },
  [AccountTypes.FREEDOMPAY]: {
    logo: FreedompayLogo,
    title: 'FreedomPay',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/7871115981211',
    errorResolveLink: 'https://corporate.freedompay.com/my-freedompay-support/',
  },
  [AccountTypes.CYBERSOURCE_3DS_REDUX]: {
    logo: CybersourceLogo,
    title: 'Cybersource',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/8895600840347',
    errorResolveLink: 'https://support.cybersource.com/',
  },
  [AccountTypes.SAFERPAY]: {
    logo: SaferpayLogo,
    title: 'Saferpay',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/11955436685083',
    errorResolveLink: 'https://docs.saferpay.com/home/integration-guide/support',
  },
  [AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE]: {
    logo: NILogo,
    title: 'Network International',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/15985495050651-Payments-Overview',
    errorResolveLink: 'https://www.network.ae/en/contactus',
  },
  [AccountTypes.SHIFT_4]: {
    logo: Shift4Logo,
    title: 'Shift4',
    doc: 'https://help.sevenrooms.com/hc/en-us/articles/23206612216219-Setting-Up-Your-Shift4-Integration',
    errorResolveLink: 'https://www.shift4.com/support',
    isSelfServeDisabled: true,
  },
} as const

export const SelfServePaymentProviders: string[] = [
  AccountTypes.STRIPE,
  AccountTypes.CYBERSOURCE_3DS_REDUX,
  AccountTypes.SAFERPAY,
  AccountTypes.FREEDOMPAY,
  AccountTypes.ADYEN,
  AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE,
  AccountTypes.PLACEHOLDER,
]

export const PropertiesBackgroundImage = PropertiesBackground
export const GeneralDocumentationLink = 'https://help.sevenrooms.com/hc/en-us/articles/15985495050651-Payments-Overview'
export const CustomerSupportLink = 'https://sevenrooms.com/en/company/lets-talk/'
