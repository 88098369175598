import { defineMessages } from '@sevenrooms/core/locales'

export const backwriterSettingsMessages = defineMessages({
  header: {
    id: 'settings.general.backwriter.header',
    defaultMessage: '{icon} SevenRooms AI Responses',
  },
  enabledTitle: {
    id: 'settings.general.backwriter.enabledTitle',
    defaultMessage: 'Enabled',
  },
  enabledDescription: {
    id: 'settings.general.backwriter.enabledDescription',
    defaultMessage:
      'Our tool to help you save time while writing more consistent replies to the incoming messages, reviews, and feedback from your guests.',
  },
  emailResponseHeader: {
    id: 'settings.general.backwriter.email.header',
    defaultMessage: 'Email Response Settings',
  },
  emailSignature: {
    id: 'settings.general.backwriter.email.signature',
    defaultMessage: '"Sign emails as..."',
  },
  emailGeneralTuning: {
    id: 'settings.general.backwriter.email.generalTuning',
    defaultMessage: '"The general tone of voice for the response should be..."',
  },
  smsHeader: {
    id: 'settings.general.backwriter.sms.header',
    defaultMessage: 'SMS Response Settings',
  },
  smsGeneralTuning: {
    id: 'settings.general.backwriter.sms.generalTuning',
    defaultMessage: '"The general tone of voice for the response should be..."',
  },
  reviewsHeader: {
    id: 'settings.general.backwriter.reviews.header',
    defaultMessage: 'Review Response Settings',
  },
  reviewsGeneralTuning: {
    id: 'settings.general.backwriter.reviews.generalTuning',
    defaultMessage: '"The general tone of voice for the response should be..."',
  },
  clientNotesHeader: {
    id: 'settings.general.backwriter.clientNotes.header',
    defaultMessage: 'Client Notes Clean-up Settings',
  },
  clientNotesMobileOnly: {
    id: 'settings.general.backwriter.clientNotes.mobileOnly',
    defaultMessage: 'Mobile app only',
  },
  clientNotesGeneralTuning: {
    id: 'settings.general.backwriter.clientNotes.generalTuning',
    defaultMessage: '"This is the structure I want the Client Notes to be in..."',
  },
  clientNotesInstructions: {
    id: 'settings.general.backwriter.clientNotes.instructions',
    defaultMessage: '(Below is a placeholder template; feel free to edit it to fit your needs)',
  },
})
