import type { PaymentRuleType, CancelTransactionType, CancelCutoffType, Policy } from '@sevenrooms/core/domain'
import { commonMessages, type FormatMessage } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import type { PaymentPolicyForm } from '../PaymentPolicy.zod'

export interface PaymentPolicyInfo {
  bookingPolicyId?: string
  cancellationPolicyId?: string
  ccPaymentRule?: PaymentRuleType | 'none'
  refundType?: CancelTransactionType
  cancelCutoffHour?: string
  cancelCutoffNum?: number
  cancelCutoffType?: CancelCutoffType | 'ANY_TIME'
  bundledUpgrades?: PaymentPolicyForm['bundledUpgrades']
}

export function getPaymentPolicySummary(
  paymentPolicy: PaymentPolicyForm,
  policies: readonly Policy[],
  formatMessage: FormatMessage,
  isCollapsedView?: boolean
) {
  const info: PaymentPolicyInfo = {
    bookingPolicyId: paymentPolicy.bookingPolicy ?? undefined,
    cancellationPolicyId: paymentPolicy.cancelPolicy ?? undefined,
    ccPaymentRule: paymentPolicy.paymentRule,
    refundType: paymentPolicy.paymentRule === 'save_for_later' ? paymentPolicy.cancelCharge : paymentPolicy.refund,
    cancelCutoffHour: paymentPolicy.cancelCutoff.beforeTime ?? undefined,
    cancelCutoffNum: paymentPolicy.cancelCutoff.count ?? undefined,
    cancelCutoffType: paymentPolicy.cancelCutoffChoice === 'CUTOFF' ? paymentPolicy.cancelCutoff.unit : paymentPolicy.cancelCutoffChoice,
    bundledUpgrades: paymentPolicy.bundledUpgrades,
  }
  return getPaymentPolicyTable(info, policies, formatMessage, isCollapsedView)
}

export function getPaymentPolicyTable(
  info: PaymentPolicyInfo,
  policies: readonly Policy[],
  formatMessage: FormatMessage,
  isCollapsedView?: boolean
): [string, string][] {
  const policiesWithCustom = policies
    .map(({ id, name }) => ({ id, name }))
    .concat([
      {
        id: 'custom',
        name: formatMessage(PaymentPolicyLocales.customPolicy),
      },
    ])

  const [bookingPolicy, cancellationPolicy] = [info.bookingPolicyId, info.cancellationPolicyId]
    .map(id => policiesWithCustom.find(policy => policy.id === id))
    .map(policy => policy?.name)

  let requirePayment
  if (info.ccPaymentRule === 'save_for_later') {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceLater)
  } else if (info.ccPaymentRule === 'advanced_payment') {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceImmediately)
  } else {
    requirePayment = formatMessage(PaymentPolicyLocales.paymentChoiceNone)
  }

  let automaticRefund
  let cancelCharge
  if (info.refundType === 'NO_CHARGE') {
    cancelCharge = formatMessage(PaymentPolicyLocales.penaltyChoiceNone)
  } else if (info.refundType === 'CANCELLATION_NO_SHOW_CHARGE') {
    cancelCharge = formatMessage(PaymentPolicyLocales.penaltyChoiceCharge)
  } else if (info.refundType === 'FULL_REFUND') {
    automaticRefund = formatMessage(PaymentPolicyLocales.refundChoiceFull)
  } else if (info.refundType === 'PARTIAL_REFUND') {
    automaticRefund = formatMessage(PaymentPolicyLocales.refundChoicePartial)
  } else {
    automaticRefund = formatMessage(PaymentPolicyLocales.refundChoiceNone)
  }

  let customerCanCancel
  if (info.cancelCutoffType === 'NEVER') {
    customerCanCancel = formatMessage(PaymentPolicyLocales.cancelChoiceNever)
  } else if (info.cancelCutoffType == null || info.cancelCutoffType === 'ANY_TIME') {
    customerCanCancel = formatMessage(PaymentPolicyLocales.cancelChoiceAnyTime)
  } else {
    customerCanCancel = formatMessage(PaymentPolicyLocales.upUntilGivenCutoff, {
      num: info.cancelCutoffNum ?? 0,
      unit: info.cancelCutoffType,
      time: TimeOnly.fromSafe(info.cancelCutoffHour ?? '')?.formatSTime() ?? '0',
    })
  }

  let labels: { [key: string]: string } = {
    requirePaymentLabel: formatMessage(PaymentPolicyLocales.requirePayment),
    automaticRefundLabel: formatMessage(PaymentPolicyLocales.automaticRefund),
    cancelChargeLabel: formatMessage(PaymentPolicyLocales.cancelCharge),
    customerCanCancelLabel: formatMessage(PaymentPolicyLocales.customerCanCancel),
    bookingPolicyLabel: formatMessage(PaymentPolicyLocales.bookingPolicy),
    cancellationPolicyLabel: formatMessage(PaymentPolicyLocales.cancellationPolicy),
    bundledUpgradesLabel: formatMessage(PaymentPolicyLocales.bundledUpgrades),
  }
  if (isCollapsedView) {
    labels = {
      requirePaymentLabel: formatMessage(PaymentPolicyLocales.requirePaymentCollapsed),
      automaticRefundLabel: formatMessage(PaymentPolicyLocales.automaticRefundCollapsed),
      cancelChargeLabel: formatMessage(PaymentPolicyLocales.cancelChargeCollapsed),
      customerCanCancelLabel: formatMessage(PaymentPolicyLocales.customerCanCancelCollapsed),
      bookingPolicyLabel: formatMessage(PaymentPolicyLocales.bookingPolicyCollapsed),
      cancellationPolicyLabel: formatMessage(PaymentPolicyLocales.cancellationPolicyCollapsed),
      bundledUpgradesLabel: formatMessage(PaymentPolicyLocales.bundledUpgradesCollapsed),
    }
  }

  const table = new Map<string, string>()
  table.set(labels.requirePaymentLabel ?? '', requirePayment)
  if (automaticRefund) {
    table.set(labels.automaticRefundLabel ?? '', automaticRefund)
  }
  if (cancelCharge) {
    table.set(labels.cancelChargeLabel ?? '', cancelCharge)
  }
  table.set(labels.customerCanCancelLabel ?? '', customerCanCancel)
  table.set(labels.bookingPolicyLabel ?? '', bookingPolicy ?? formatMessage(PaymentPolicyLocales.defaultBookingPolicy))
  table.set(labels.cancellationPolicyLabel ?? '', cancellationPolicy ?? formatMessage(PaymentPolicyLocales.defaultCancellationPolicy))
  table.set(labels.bundledUpgradesLabel ?? '', getBundledUpgradesInfo(formatMessage, info.bundledUpgrades))

  return Array.from(table)
}

export function getBundledUpgradesInfo(formatMessage: FormatMessage, bundledUpgrades?: PaymentPolicyForm['bundledUpgrades']): string {
  return bundledUpgrades?.length
    ? bundledUpgrades
        .flatMap(item => item.upgrades)
        .map(upgrade => upgrade.label)
        .join(', ')
    : formatMessage(commonMessages.none)
}
