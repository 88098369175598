import React, { useCallback, type Dispatch, type SetStateAction, useState } from 'react'
import { iVvyApi } from '@sevenrooms/core/api'
import type { IVvySpaceMapping } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalBody, ModalHeader, ModalTitle, ModalActions, ModalFooter, Box, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { iVvyTableMappingLocales } from '@sevenrooms/mgr-ivvi-settings'

export interface iVvySaveAndImportModalProps {
  isActive: boolean
  isImport: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
  spaceMappingData?: IVvySpaceMapping
  setIsDisabled: () => void
}

export function SaveAndImportModal({ isActive, isImport, setIsActive, spaceMappingData, setIsDisabled }: iVvySaveAndImportModalProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [isRemapReservationTables, setIsRemapReservationTables] = useState(false)

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsRemapReservationTables(e.target.checked)
  }

  const [postMutation] = iVvyApi.usePostMutation()
  const onSubmitSaveAndImport = useCallback(async () => {
    if (spaceMappingData) {
      setIsDisabled()
      postMutation({ venueId, spaceMappingData, isImport, isSyncChanges: !isImport, isRemapReservationTables })
      setIsActive(false)
    }
  }, [isImport, postMutation, setIsActive, setIsDisabled, spaceMappingData, venueId, isRemapReservationTables])

  return (
    <Window active={isActive} setIsActive={setIsActive}>
      <Modal ariaLabel="saveAndImportReservationModal">
        <ModalHeader
          onClose={() => {
            setIsActive(false)
            setIsRemapReservationTables(false)
          }}
        >
          <VStack spacing="s">
            <Status kind="warning">{formatMessage(iVvyTableMappingLocales.saveAndImportModalHeader)}</Status>
            <ModalTitle title={formatMessage(iVvyTableMappingLocales.saveAndImportModalTitle)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="s">
            <Text>{formatMessage(iVvyTableMappingLocales.saveAndImportModalDescription)}</Text>
            <Box mb="m">
              <Checkbox
                onChange={onChange}
                info={<>{formatMessage(iVvyTableMappingLocales.saveAndImportModalRemapReservationTablesLabel)}</>}
              >
                {formatMessage(iVvyTableMappingLocales.saveAndImportModalRemapReservationTablesLabel)}
              </Checkbox>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Box mr="xs">
              <Button data-test="back-to-mapping-button" variant="secondary" onClick={() => setIsActive(false)}>
                {formatMessage(iVvyTableMappingLocales.saveAndImportModalBack)}
              </Button>
            </Box>
            <Button data-test="save-and-start-import-button" variant="primary" onClick={onSubmitSaveAndImport}>
              {formatMessage(iVvyTableMappingLocales.saveAndImportModalSave)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
