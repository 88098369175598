import type { Disabled, PerkAvailabilityType } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { perksMessages } from '../../locales'
import type { ReactNode } from 'react'

export interface PerkRestrictionTypeFormProps {
  dateRange: ReactNode
  dayOfWeek: ReactNode
  disabled: Disabled
  isRestrictedByDateRangeField: Field<boolean>
  isRestrictedByWeekOfDayAndTimeRangeField: Field<boolean>
  perkAvailabilityTypeField: Field<PerkAvailabilityType>
  timeRanges: ReactNode
}

export function PerkRestrictionTypeForm({
  dateRange,
  dayOfWeek,
  timeRanges,
  isRestrictedByDateRangeField,
  isRestrictedByWeekOfDayAndTimeRangeField,
  disabled,
  perkAvailabilityTypeField,
}: PerkRestrictionTypeFormProps) {
  const { formatMessage } = useLocales()
  const { perkAvailabilityRestrictionsDateRange, perkAvailabilityRestrictionedByDayAndTimeRange } = perksMessages

  const perkAvailabilityType = useWatch(perkAvailabilityTypeField)
  const isRestrictedByDateRange = useWatch(isRestrictedByDateRangeField)
  const isRestrictedByWeekOfDayAndTimeRange = useWatch(isRestrictedByWeekOfDayAndTimeRangeField)

  if (perkAvailabilityType === 'ANY_TIME') {
    return null
  }

  return (
    <VStack ml="l" spacing="m">
      <Checkbox data-test="sr-perk-form-date-range" disabled={disabled} field={isRestrictedByDateRangeField}>
        {formatMessage(perkAvailabilityRestrictionsDateRange)}
      </Checkbox>
      {isRestrictedByDateRange && <Box ml="l">{dateRange}</Box>}
      <Checkbox data-test="sr-perk-form-time-range" disabled={disabled} field={isRestrictedByWeekOfDayAndTimeRangeField}>
        {formatMessage(perkAvailabilityRestrictionedByDayAndTimeRange)}
      </Checkbox>
      {isRestrictedByWeekOfDayAndTimeRange && (
        <Box ml="l">
          <>
            {dayOfWeek}
            {timeRanges}
          </>
        </Box>
      )}
    </VStack>
  )
}
