import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  cardSubHeadingLabel: {
    id: 'posiSettings.SimphonyAutomaticPrepaymentRedemptionCard.cardSubHeadingLabel',
    defaultMessage:
      'Reservation prepayments seamlessly synced to the POS. Automatic Check Creation required for enablement. <a>Learn More</a>',
  },
  depositMenuItemIdLabel: {
    id: 'posiSettings.SimphonyAutomaticPrepaymentRedemptionCard.depositMenuItemIdLabel',
    defaultMessage: 'Deposit Menu Item',
  },
  depositDefinitionSequenceLabel: {
    id: 'posiSettings.SimphonyAutomaticPrepaymentRedemptionCard.depositDefinitionSequenceLabel',
    defaultMessage: 'Deposit Definition Sequence',
  },
} as const)
