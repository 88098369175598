import { defineMessages } from '@sevenrooms/core/locales'

export const upgradesBannerMessages = defineMessages({
  title: {
    id: 'settings.widget.reservation.banner.upgrades.title',
    defaultMessage: 'Generate more revenue with Upgrades',
  },
  description: {
    id: 'settings.widget.reservation.banner.upgrades.description',
    defaultMessage: 'Offer experiences or special menu items for selection or purchase while booking. ',
  },
  manageUpgradesButton: {
    id: 'settings.widget.reservation.banner.upgrades.button.manageUpgrades',
    defaultMessage: 'Manage Upgrades',
  },
})
