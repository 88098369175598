import { BaseSection, type BaseSectionProps, Box } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren } from 'react'

export interface HalfSizableBoxProps {
  halfsize?: boolean
}

export type SectionBoxProps = BaseSectionProps & HalfSizableBoxProps

export function SectionBox({ halfsize, children, ...props }: PropsWithChildren<SectionBoxProps>) {
  return (
    <BaseSection {...props}>
      <HalfSizeableBox halfsize={halfsize}>{children}</HalfSizeableBox>
    </BaseSection>
  )
}

export function HalfSizeableBox({ halfsize, children }: PropsWithChildren<HalfSizableBoxProps>) {
  return (
    <Box pt="s" pl="lm" pr={halfsize ? 'sm' : 'lm'} pb="lm" width={halfsize ? '50%' : '100%'}>
      {children}
    </Box>
  )
}
