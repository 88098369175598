import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { messages } from './EditIntegrationButton.locales'

export function EditIntegrationButton() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey } = useVenueContext()
  const onEditClick = () => {
    nav.push(routes.manager2.settings.paymentIntegration.edit, {
      params: { venueKey },
    })
  }

  return (
    <>
      <Menu>
        <MenuItem onClick={onEditClick}>
          <Icon name="VMSWeb-edit" />
          {formatMessage(messages.editIntegration)}
        </MenuItem>
      </Menu>
    </>
  )
}
