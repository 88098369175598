// /* @flow */
import * as React from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'
import 'react-table/react-table.css'

/* type TablePropTypes = {
  data: Array<mixed>,
  columns: Array<mixed>,
  customTableContainerStyles: Array<string | (() => mixed)>,
  defaultPageSize: number,
  noDataText: string,
  loadingText: string,
  rowClick: Function,
  thClick: Function,
  customTrProps: Function,
  // tooltips will be enabled by default on columns which don't have a custom Cell already
  // this might need to be extended with more props
  enableTooltips: boolean,
  loading: boolean,
  manual: boolean,
  pages: number,
  page: number,
  pageSize: number,
  onPageChange: Function,
  onSortedChange: Function,
  showPagination: boolean,
  className: string,
  defaultSorted: [{ id: string, desc: boolean }],
  useFullWidth: boolean,
  customThComponent: React.Component,
} */

const getTdProps = rowClick => (state, rowInfo) => ({
  onClick: (e, handleOriginal) => {
    rowClick(rowInfo)
    if (handleOriginal) {
      handleOriginal()
    }
  },
})

const getThProps = thClick => (state, rowInfo, column, instance) => ({
  onClick: (e, handleOriginal) => {
    const columnId = column.id.toLowerCase().replace(/ /g, '_')
    thClick(columnId)

    if (column.sortable !== false) {
      instance.sortColumn(column)
    }

    if (handleOriginal) {
      handleOriginal()
    }
  },
})

class Table extends React.PureComponent {
  render() {
    const {
      customTableContainerStyles,
      defaultPageSize,
      data,
      noDataText,
      loadingText,
      rowClick,
      thClick,
      loading,
      manual,
      pages,
      onPageChange,
      page,
      pageSize,
      sorted,
      onSortedChange,
      showPagination,
      showPageSizeOptions,
      pageSizeOptions,
      minRows,
      customPaginationComponent,
      className,
      defaultSorted,
      useFullWidth,
      customThComponent,
      customTrProps,
      sortable = true,
      resizable = true,
      customTfootComponent,
    } = this.props

    return (
      <TableContainer customTableContainerStyles={customTableContainerStyles} useFullWidth={useFullWidth}>
        <ReactTable
          defaultPageSize={defaultPageSize}
          data={data}
          className={className}
          columns={this.props.columns}
          showPagination={showPagination}
          noDataText={noDataText}
          loadingText={loadingText}
          loading={loading}
          manual={manual}
          page={page}
          pageSize={pageSize}
          pages={pages}
          onPageChange={onPageChange}
          showPageSizeOptions={showPageSizeOptions}
          pageSizeOptions={pageSizeOptions}
          minRows={minRows}
          onSortedChange={onSortedChange}
          getTdProps={getTdProps(rowClick)}
          ThComponent={customThComponent}
          defaultSorted={defaultSorted}
          getTheadThProps={getThProps(thClick)}
          getTrProps={customTrProps}
          sortable={sortable}
          sorted={sorted}
          resizable={resizable}
          PaginationComponent={customPaginationComponent}
          TfootComponent={customTfootComponent}
        />
      </TableContainer>
    )
  }
}

Table.defaultProps = {
  customTableContainerStyles: [],
  data: [],
  columns: [],
  defaultPageSize: 20,
  noDataText: 'No data found',
  loadingText: 'Loading...',
  enableTooltips: true,
  loading: false,
  manual: false,
  pages: undefined,
  sortable: true,
  sorted: undefined,
  onPageChange: null,
  page: undefined,
  pageSize: undefined,
  minRows: undefined,
  rowClick: () => {},
  thClick: () => {},
  customTrProps: () => ({}),
  onSortedChange: null,
  showPagination: true,
  showPageSizeOptions: false,
  pageSizeOptions: undefined,
  customPaginationComponent: undefined,
  className: '',
  defaultSorted: [],
  useFullWidth: false,
  customThComponent: undefined,
  customTfootComponent: undefined,
}

const TableContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.gutter.standard};
  width: ${props => props.useFullWidth && '100%'};

  // react table css overrides
  .ReactTable {
    flex: 1;

    &.active_table {
      .rt-noData {
        margin-top: 50px;
      }
    }

    &.inactive_table {
      .rt-noData {
        margin-top: 0px;
      }
    }

    &.hide-headers {
      .rt-thead {
        &.-headerGroups,
        &.-header {
          display: none;
        }
      }
    }

    &.inactive-group-header {
      .rt-thead {
        &.-headerGroups {
          .rt-th {
            text-align: left;
            background-color: ${props => props.theme.color.greyMuted};
            padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double};
            color: ${props => props.theme.color.grey};
          }
        }
      }
    }

    &.hide-header-only {
      .rt-thead {
        &.-header {
          display: none;
        }
      }
    }

    &.disabled {
      color: ${props => props.theme.color.grey};
      opacity: 0.7;
    }

    .highlighted {
      background-color: #f0f1f1;
    }

    .rt-tbody {
      overflow: visible;
      min-height: 100px;
      .rt-tr:not(.-padRow) {
        :hover {
          cursor: pointer;
          background: ${props => props.theme.color.rowHover};

          .highlighted {
            background: ${props => props.theme.color.rowHover};
          }
        }
      }
      .rt-td {
        display: flex;
        align-items: center;
        padding: ${props => props.theme.gutter.double};
        border-right: none;
        white-space: normal;
        overflow: visible;

        &.greyed {
          color: ${props => props.theme.color.greySecondary};
        }

        &.align-right {
          text-align: right;
          justify-content: flex-end;
        }
        &.rt-fixed {
          position: sticky;
          z-index: 1;
        }
        &.rt-fixed-left {
          left: 0;
        }
      }
    }

    .rt-tr {
      &.-even {
        background-color: ${props => props.theme.color.lightGrey};
      }
    }

    .rt-thead {
      &.-headerGroups {
        .rt-th {
          text-align: center;
        }
      }

      &:not(.-headerGroups) {
        .rt-th {
          border-right: none;
        }
      }

      .rt-th {
        padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.double};
        font-weight: 600;
        text-transform: uppercase;
        text-align: left;

        &.align-right {
          text-align: right;
        }
        &.rt-fixed {
          position: sticky;
          z-index: 1;
        }
        &.rt-fixed-left {
          left: 0;
        }
        &.rt-fixed-top {
          top: 0;
        }
      }

      &.-header,
      &.-headerGroups {
        background-color: ${props => props.theme.color.lightGrey};
      }
    }
  }

  ${props => props.customTableContainerStyles};
`

export default Table
