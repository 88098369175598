import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  cardLabel: {
    id: 'posiSettings.TableMappingCard.cardLabel',
    defaultMessage: 'Match Tables',
  },
  cardSubheadingLabel: {
    id: 'posiSettings.TableMappingCard.cardSubheadingLabel',
    defaultMessage:
      'Connect your POS system tables to SevenRooms tables to you get the most out of this integration, including instant check linking, accurate reporting, and hyper-tailored marketing segments.',
  },
  launchWizardButtonLabel: {
    id: 'posiSettings.TableMappingCard.launchWizardButtonLabel',
    defaultMessage: 'Begin',
  },
} as const)
