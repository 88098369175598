import { defineMessages } from '@sevenrooms/core/locales'

export const internalTeamEmailsMessages = defineMessages({
  sectionReservationSummaryEmail: {
    id: 'settings.general.reservationSummaryEmail',
    defaultMessage: 'Reservation Summary Email - Internal',
  },
  reservationSummaryEmailTitle: {
    id: 'settings.general.reservationSummaryEmail.title',
    defaultMessage: 'Automated Reservation Summary Email',
  },
  reservationSummaryEmailDescription: {
    id: 'settings.general.reservationSummaryEmail.description',
    defaultMessage: 'Send time(s) for automated Reservation Summary email of all reservations in an upcoming shift',
  },
  sectionFeedbackSummaryEmail: {
    id: 'settings.general.feedbackSummaryEmail',
    defaultMessage: 'Feedback Summary Email - Internal',
  },
  feedbackSummaryEmailTitle: {
    id: 'settings.general.feedbackSummaryEmail.title',
    defaultMessage: 'Automated Daily Feedback Summary Email - Internal',
  },
  feedbackSummaryEmailDescription: {
    id: 'settings.general.feedbackSummaryEmail.description',
    defaultMessage: 'Automated reservation Feedback Summary email, including all feedback received in the last 24 hours',
  },
  aiFeedbackSummaryEmailTitle: {
    id: 'settings.general.aiFeedbackSummaryEmailTitle.title',
    defaultMessage: 'AI Weekly Summary Email - Internal',
  },
  aiFeedbackSummaryEmailDescription: {
    id: 'settings.general.aiFeedbackSummaryEmailTitle.description',
    defaultMessage: 'Automated AI generated Feedback Summary of the past week, sent out every Monday',
  },
  resolutionReportTitle: {
    id: 'settings.general.resolutionReport.title',
    defaultMessage: 'Resolution Report Email - Internal',
  },
  resolutionReportDescription: {
    id: 'settings.general.resolutionReport.description',
    defaultMessage: 'Automatically send third party reservation Resolution Report email; specific to OpenTable users only',
  },
  reservationEmailNotifications: {
    id: 'settings.general.reservationEmailNotifications',
    defaultMessage: 'Reservation Email Notifications - Internal',
  },
  reservationReminderEmailTitle: {
    id: 'settings.general.reservationReminderEmail.title',
    defaultMessage: 'New Reservation Reminder Email',
  },
  reservationReminderEmailDescription: {
    id: 'settings.general.reservationReminderEmail.description',
    defaultMessage: 'Reminder to take action on reservations booked through SevenRooms',
  },
  weeklySummaryEmailTitle: {
    id: 'settings.general.weeklySummaryEmail.title',
    defaultMessage: 'Weekly Summary Email',
  },
  reservationReminderTextFirstLine: {
    id: 'settings.general.reservationReminderTextFirstLine',
    defaultMessage: 'If new reservation time is less than {fasterHours} away, send me a reminder every {slowerHours}',
  },
  reservationReminderTextSecondLine: {
    id: 'settings.general.reservationReminderTextSecondLine',
    defaultMessage: 'Otherwise, send me a reminder every {hours}',
  },
  weeklySummaryEmailTextLine: {
    id: 'settings.general.weeklySummaryEmailTextLine',
    defaultMessage: 'Send every {dow} at {tod}',
  },
})
