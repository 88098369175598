import { useMemo } from 'react'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, MultiSelectTag, generateTagsData } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import { PerkAssociatedTagsFormTestId } from './PerkAssociatedTagsForm.testIds'
import type { PerkAssociatedTagsFormField } from './PerkAssociatedTagsForm.zod'

interface PerkAssociatedTagsFormProps {
  field: PerkAssociatedTagsFormField
  clientTagGroups: GenericTagGroup[]
  reservationTagGroups?: GenericTagGroup[]
  disabled: boolean
  disableReservationTags?: boolean
}

export function PerkAssociatedTagsForm({
  field,
  clientTagGroups,
  reservationTagGroups = [],
  disabled,
  disableReservationTags = false,
}: PerkAssociatedTagsFormProps) {
  const { formatMessage } = useLocales()

  const { clientTagOptions, clientTagCategories, reservationTagOptions, reservationTagCategories } = useMemo(() => {
    const { tagOptions: clientTagOptions, tagCategories: clientTagCategories } = generateTagsData(clientTagGroups)
    const { tagOptions: reservationTagOptions, tagCategories: reservationTagCategories } = generateTagsData(reservationTagGroups)
    return {
      clientTagOptions,
      clientTagCategories,
      reservationTagOptions,
      reservationTagCategories,
    }
  }, [clientTagGroups, reservationTagGroups])

  return (
    <Label primary={<Text fontWeight="bold">{formatMessage(perksMessages.associatedTags)}</Text>}>
      <HStack spacing="lm">
        <VStack spacing="lm" width="50%" justifyContent="flex-start">
          <Label primary={formatMessage(perksMessages.clientTags)}>
            <MultiSelectTag
              data-test={PerkAssociatedTagsFormTestId.perkAssociatedClientTagsMultiSelectTag}
              searchable
              field={field.prop('clientTags')}
              categories={clientTagCategories}
              options={clientTagOptions}
              placeholder={formatMessage(perksMessages.clientMultiSelectTagPlaceholder)}
              disabled={disabled}
            />
          </Label>
        </VStack>
        {!disableReservationTags && (
          <VStack spacing="lm" width="50%" justifyContent="flex-start">
            <Label primary={formatMessage(perksMessages.reservationTags)}>
              <MultiSelectTag
                data-test={PerkAssociatedTagsFormTestId.perkAssociatedReservationTagsMultiSelectTag}
                searchable
                field={field.prop('reservationTags')}
                categories={reservationTagCategories}
                options={reservationTagOptions}
                placeholder={formatMessage(perksMessages.reservationMultiSelectTagPlaceholder)}
                disabled={disabled}
              />
            </Label>
          </VStack>
        )}
      </HStack>
    </Label>
  )
}
