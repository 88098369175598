import { useMemo } from 'react'
import type { CreditCardCollectionOptions } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useCostOptionsValues } from './ChargesNightlife.zod'

export type ChargesDiningForm = ZodSchema<typeof useChargesDiningForm>
export type CreditCardCollectionOptionsValues = ZodSchema<typeof useCreditCardCollection>

export function useCreditCardCollection() {
  return useMemo(() => z.custom<CreditCardCollectionOptions>(), [])
}

export function useChargesDiningForm() {
  const costOptionsValues = useCostOptionsValues()
  const creditCardCollection = useCreditCardCollection()

  return useMemo(
    () =>
      z.object({
        serviceCharge: z.string(),
        gratuity: z.string(),
        costOptions: z.array(costOptionsValues),
        creditCardCollection,
        incompletePaylinkAutoCancelMinutesLong: z.number(),
        incompletePaylinkAutoCancelMinutesShort: z.number(),
      }),
    [costOptionsValues, creditCardCollection]
  )
}
