import React from 'react'
import type { Status } from '@sevenrooms/core/domain'
import { Cell, type Row } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { usePromoCodeManagementResourcesContext } from '../../hooks/usePromoCodeManagementResourcesContext'

export interface StatusCellProps extends React.InputHTMLAttributes<HTMLInputElement> {
  status: Status
}

export function StatusCell({ status, ...props }: StatusCellProps) {
  const { statusDisplayNames } = usePromoCodeManagementResourcesContext()
  return (
    <Cell {...props}>
      <StatusLabel variant={status === 'INACTIVE' ? 'inactive' : 'success'}>{statusDisplayNames[status]}</StatusLabel>
    </Cell>
  )
}

export const statusCellSortFn = (rowA: Row<StatusCellProps>, rowB: Row<StatusCellProps>, columnId: string) => {
  const rowAValue = rowA.values[columnId as string]?.props.status
  const rowBValue = rowB.values[columnId as string]?.props.status
  return rowAValue > rowBValue ? 1 : -1
}
