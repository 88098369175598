import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  configurationLabel: {
    id: 'posiSettings.CourseStatusMappingCard.configurationLabel',
    defaultMessage: 'Self-Updating Table Statuses',
  },
  configurationSubHeadingLabel: {
    id: 'posiSettings.CourseStatusMappingCard.configurationSubHeadingLabel',
    defaultMessage: 'Table statuses updated instantly when items are fired to the kitchen. <a>Learn more</a>.',
  },
  mappingTypeLabel: {
    id: 'posiSettings.CourseStatusMappingCard.mappingTypeLabel',
    defaultMessage: 'Type',
  },
  mappingNameLabel: {
    id: 'posiSettings.CourseStatusMappingCard.mappingNameLabel',
    defaultMessage: 'Name',
  },
  mappingStatusLabel: {
    id: 'posiSettings.CourseStatusMappingCard.mappingStatusLabel',
    defaultMessage: 'Transition to Status',
  },
  addMappingLabel: {
    id: 'posiSettings.CourseStatusMappingCard.addMappingLabel',
    defaultMessage: '+ Add Mapping',
  },
  locationRequiredBannerTitle: {
    id: 'posiSettings.CourseStatusMappingCard.locationRequiredBannerTitle',
    defaultMessage: 'Location Required',
  },
  locationRequiredBannerDescription: {
    id: 'posiSettings.CourseStatusMappingCard.locationRequiredBannerDescription',
    defaultMessage: 'To enable self-updating table statuses, please select a location and save your selection.',
  },
} as const)
