import _ from 'lodash'
import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { referralsMessages } from '../../locales'
import { useReferredClientForm } from '../ReferredClientForm/ReferredClientForm.zod'
import { useReferrersForm } from '../ReferrersForm/ReferrersForm.zod'

export type ReferralsFormData = ZodSchema<ReturnType<typeof useReferralsForm>>
export type ReferralsFormFields = Field<ReferralsFormData>

export interface UseReferralsFormParams {
  isDraft?: boolean
}

export const useReferralsForm = ({ isDraft }: UseReferralsFormParams) => {
  const { formatMessage } = useLocales()
  const referrersFormSchema = useReferrersForm()
  const referredClientFormSchema = useReferredClientForm()

  return useMemo(
    () =>
      isDraft
        ? (z.object({}) as unknown as z.ZodAny)
        : referrersFormSchema.merge(referredClientFormSchema).superRefine((data, ctx) => {
            if ('referrerAutotagTiers' in data) {
              const { referrerAutotagTiers } = data

              if (!_.isEmpty(referrerAutotagTiers)) {
                _.forEach(referrerAutotagTiers, (item, index, arr) => {
                  const tier = arr.findIndex(
                    (prevItem, prevIndex) =>
                      prevIndex < index &&
                      !_.isNil(item.conditionThreshold) &&
                      !_.isNil(prevItem.conditionThreshold) &&
                      item.conditionThreshold <= prevItem.conditionThreshold
                  )

                  if (item && tier >= 0) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: formatMessage(referralsMessages.conditionThresholdError, { tier: tier + 1 }),
                      path: [`referrerAutotagTiers.${index}.conditionThreshold`],
                    })
                  }
                })
              }
            }
          }),
    [formatMessage, isDraft, referrersFormSchema, referredClientFormSchema]
  )
}
