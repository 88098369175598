import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetPerkQuery, useGetConciergesQuery, useGetClientTagGroupsQuery, useGetReservationTagGroupsQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation, useParams } from '@sevenrooms/core/navigation'
import { Loader, notify, VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { perksMessages } from '../../locales'
import { CreateEditPerkForm } from './CreateEditPerkForm'

export function CreateEditPerk() {
  const { perkId } = useParams<{ perkId?: string }>()
  const { venue, venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  // Only fetch perk on update
  const { data: perk, isFetching: isFetchingPerk, isError: isErrorFetchingPerk } = useGetPerkQuery(perkId ? { venueId, perkId } : skipToken)

  // Only fetch concierges if enabled
  const { data: venueConcierges, isFetching: isFetchingConcierges } = useGetConciergesQuery(
    venue.isNoConciergeAccess ? skipToken : { venueId }
  )
  // Fetch client and reservation tag groups
  const { data: clientTagGroups, isFetching: isFetchingClientTagGroups } = useGetClientTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })
  const { data: reservationTagGroups, isFetching: isFetchingReservationTagGroups } = useGetReservationTagGroupsQuery({
    venueKey: venueId,
    includeRebuildState: false,
  })

  const isFetching = isFetchingPerk || isFetchingConcierges || isFetchingReservationTagGroups || isFetchingClientTagGroups

  // Show an error if fetching the perk fails
  if (isErrorFetchingPerk) {
    notify({
      content: formatMessage(perksMessages.perkNotFound, { perkId: perkId || '' }),
      type: 'error',
    })
    nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
  }

  return isFetching || !clientTagGroups || !reservationTagGroups ? (
    <Box p="lm" width="100%">
      <VStack spacing="lm">
        <Box width="968px">
          <Loader />
        </Box>
      </VStack>
    </Box>
  ) : (
    <CreateEditPerkForm
      perk={perk}
      venueConcierges={venueConcierges}
      reservationTagGroups={reservationTagGroups}
      clientTagGroups={clientTagGroups}
    />
  )
}
