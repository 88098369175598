import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import { getEmailCampaignActivity, getEmailCampaigns } from 'mgr/lib/services/EmailCampaignServices'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { CAMPAIGN_SORT_CHANGE } from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { getAutoTagsList } from 'mgr/pages/single-venue/marketing/actions/Autotags'
import { convertFilters } from 'mgr/pages/single-venue/marketing/utils/data'

export const onChangeVenue = venue => dispatch => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })
}

export const onCampaignsSort = sorts => dispatch => {
  const sort = sorts && sorts[0]

  dispatch({
    type: CAMPAIGN_SORT_CHANGE,
    sortKey: sort.id,
    sortDirection: sort.desc ? 'desc' : 'asc',
  })
}

export const tryGetEmailCampaignActivity =
  (venue, filterCampaignIds = true) =>
  (dispatch, getState) => {
    dispatch({ type: ActionTypes.LOAD_EMAIL_CAMPAIGN_ACTIVITY_START })

    const filterParams = ['start_date', 'end_date']
    if (filterCampaignIds) {
      filterParams.push('campaign_ids')
    }
    const filters = convertFilters(getState().emails.filters, filterParams)

    return getEmailCampaignActivity(venue, filters).then(response => {
      dispatch({
        type: ActionTypes.LOAD_EMAIL_CAMPAIGN_ACTIVITY_SUCCESS,
        data: response.data,
      })
    })
  }

export const tryGetEmailCampaigns = venue => dispatch => {
  dispatch({ type: ActionTypes.LOAD_EMAIL_CAMPAIGNS_START })

  return getEmailCampaigns(venue).then(response => {
    dispatch({
      type: ActionTypes.LOAD_EMAIL_CAMPAIGNS_SUCCESS,
      data: response.data,
    })
  })
}

export const tryGetEmailCampaignsData = venue => dispatch => {
  dispatch({
    type: ActionTypes.LOAD_EMAILS_START,
  })

  // Take a look at perf of loading autotags separately, list is prepared for it if necessary
  const promises = [
    dispatch(tryGetEmailCampaignActivity(venue)),
    dispatch(tryGetEmailCampaigns(venue)),
    dispatch(getAutoTagsList(venue.id)),
  ]

  return Promise.all(promises)
    .then(() => {
      dispatch({
        type: ActionTypes.LOAD_EMAILS_SUCCESS,
      })
    })
    .catch(e => {
      dispatch(GlobalActions.showErrorMessage('Unable to fetch email campaigns, please try again later.'))
      dispatch({
        type: ActionTypes.LOAD_EMAILS_FAIL,
      })
      return e
    })
}

export const setOneTimeCampaignParams = (params, numberOfClientsFound) => ({
  type: ActionTypes.SET_ONE_TIME_CAMPAIGN_PARAMS,
  params,
  numberOfClientsFound,
})
