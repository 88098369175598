import React, { useState } from 'react'
import { useGetCustomAudiencesQuery, useDeleteCustomAudienceMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  HStack,
  Loader,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  notify,
  Table,
  TableBody,
  TableCell,
  TableRow,
  VStack,
  Window,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { copyToClipboard } from '@sevenrooms/core/ui-kit/utils'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { messages } from '../locales/ApiAudienceList.locale'

const getLinkText = (venue: string, audience: string) =>
  `${window.location.protocol}//${window.location.host}/reservations/${venue}/?client_id=${audience}`

export function ApiAudienceList() {
  const { venue } = useVenueContext()
  const { data, isFetching } = useGetCustomAudiencesQuery({ venueId: venue.id })
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [showDeleteAudienceModal, setShowDeleteAudienceModal] = useState(false)
  const [deleteAudienceId, setDeleteAudienceId] = useState('')

  const handleFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.target instanceof HTMLInputElement) {
      event.target.select()
    }
  }
  const [deleteCustomAudience, { isLoading: isDeleting }] = useDeleteCustomAudienceMutation()

  if (isFetching) {
    return <Loader />
  }

  return (
    <>
      <Window active={showDeleteAudienceModal} setIsActive={setShowDeleteAudienceModal}>
        <Modal ariaLabel="Base">
          <ModalHeader
            onClose={() => {
              setShowDeleteAudienceModal(false)
            }}
          >
            <VStack spacing="s">
              <ModalTitle title={formatMessage(messages.deleteWarningHeader)} />
            </VStack>
          </ModalHeader>
          <ModalBody>
            <Text>{formatMessage(messages.deleteWarning)}</Text>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <Button
                data-test="btn-dismiss"
                onClick={() => {
                  setShowDeleteAudienceModal(false)
                }}
              >
                {formatMessage(messages.dismiss)}
              </Button>
              <Button
                data-test="btn-delete"
                onClick={async () => {
                  const result = await deleteCustomAudience({ venueId: venue.id, id: deleteAudienceId })
                  setShowDeleteAudienceModal(false)
                  if ('data' in result && result.data?.status) {
                    notify({ type: 'success', content: formatMessage(messages.deleteSuccess) })
                  } else {
                    notify({ type: 'error', content: formatMessage(messages.deleteError) })
                  }
                }}
                variant="primary-warning"
              >
                {formatMessage(messages.delete)}
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>

      <SettingsPageMeta title={formatMessage(messages.title)} venue={venue?.name} />
      <SettingsPageContent
        actions={
          <Button
            data-test="btn-add-new"
            href={nav.href(routes.manager2.settings.customAudiences.add, { params: { venueKey: venue?.urlKey } })}
            variant="primary"
          >
            {formatMessage(messages.addNew)}
          </Button>
        }
        title={formatMessage(messages.title)}
        description={formatMessage(messages.titleDescription)}
      >
        <VStack m="m" p="m" backgroundColor="primaryBackground">
          <Table>
            <thead>
              <tr>
                <TableCell isHeader>{formatMessage(messages.tableHeaderName)}</TableCell>
                <TableCell isHeader>{formatMessage(messages.tableHeaderClientId)}</TableCell>
                <TableCell isHeader>{formatMessage(messages.tableHeaderWidgetUrl)}</TableCell>
                <TableCell isHeader>{formatMessage(messages.tableHeaderActive)}</TableCell>
                <TableCell isHeader />
              </tr>
            </thead>
            <TableBody>
              {data?.map(app => (
                <TableRow key={app.clientId}>
                  <TableCell>{app.name}</TableCell>
                  <TableCell>
                    <pre style={{ fontSize: '10px', maxWidth: '200px', lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>{app.clientId}</pre>
                    <Button data-test="btn-copy" variant="secondary" size="xs" onClick={() => copyToClipboard(app.clientId)}>
                      {formatMessage(messages.copy)}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Input defaultValue={getLinkText(venue.urlKey, app.clientId)} readOnly fullWidth onClick={e => handleFocus(e)} />
                  </TableCell>
                  <TableCell>
                    {app.isActive ? (
                      <Icon color="primaryIcons" size="2x" name="VMSWeb-check-circle-outline" />
                    ) : (
                      <Icon color="primaryIcons" size="2x" name="VMSWeb-close-circle-outline" />
                    )}
                  </TableCell>
                  <TableCell>
                    {isDeleting && app.clientId === deleteAudienceId ? (
                      <Loader />
                    ) : (
                      <HStack spacing="m">
                        <Button
                          data-test={`btn-editq-${app.clientId}`}
                          href={nav.href(routes.manager2.settings.customAudiences.edit, {
                            params: { venueKey: venue?.urlKey, id: app.clientId },
                          })}
                          variant="secondary"
                        >
                          {formatMessage(messages.edit)}
                        </Button>
                        <Button
                          data-test={`btn-delete-${app.clientId}`}
                          variant="primary-warning"
                          onClick={() => {
                            setDeleteAudienceId(app.clientId)
                            setShowDeleteAudienceModal(true)
                          }}
                        >
                          {formatMessage(messages.delete)}
                        </Button>
                      </HStack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </VStack>
      </SettingsPageContent>
    </>
  )
}
