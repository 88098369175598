import * as React from 'react'
import { useMemo } from 'react'
import type { CodeType, PlatformApplyType, PromoRangeApplyType, Status } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { promoCodeManagementMessages } from '../locales'

export function usePromoCodeManagementResourcesContext() {
  const { formatMessage } = useLocales()

  const dateRangeDisplayNames: Record<PromoRangeApplyType, string> = useMemo(
    () => ({
      NO_EXPIRATION: formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnValueNoExpiration),
      BOOKING_DATE: formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnValueBookingDate),
      RESERVATION_DATE: formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnValueReservationDate),
    }),
    [formatMessage]
  )
  const dateRangeDescriptions: Record<PromoRangeApplyType, React.ReactNode> = useMemo(
    () => ({
      NO_EXPIRATION: formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnDescriptionNoExpiration),
      BOOKING_DATE: (
        <>
          {formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnDescriptionBookingDate)}
          <br />
          <SecondaryText fontStyle="italic">
            {formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnDescriptionExampleBookingDate)}
          </SecondaryText>
        </>
      ),
      RESERVATION_DATE: (
        <>
          {formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnDescriptionReservationDate)}&nbsp;
          <SecondaryText fontStyle="italic">
            {formatMessage(promoCodeManagementMessages.promoCodesTablePromoRangeColumnDescriptionExampleReservationDate)}
          </SecondaryText>
        </>
      ),
    }),
    [formatMessage]
  )

  const codeTypeDisplayNames: Record<CodeType, string> = useMemo(
    () => ({
      VENUE: formatMessage(promoCodeManagementMessages.promoCodesTableUsageColumnValueVenue),
      VENUE_GROUP: formatMessage(promoCodeManagementMessages.promoCodesTableUsageColumnValueVenueGroup),
    }),
    [formatMessage]
  )

  const platformApplyTypeDisplayNames: Record<PlatformApplyType, string> = useMemo(
    () => ({
      ORDERING: formatMessage(promoCodeManagementMessages.promoCodesTablePlatformApplyTypeColumnValueOrdering),
      RESERVATION_WIDGET: formatMessage(promoCodeManagementMessages.promoCodesTablePlatformApplyTypeColumnValueReservation),
      EVENT_WIDGET: formatMessage(promoCodeManagementMessages.promoCodesTablePlatformApplyTypeColumnValueEvent),
    }),
    [formatMessage]
  )

  const statusDisplayNames: Record<Status, string> = useMemo(
    () => ({
      ACTIVE: formatMessage(promoCodeManagementMessages.promoCodesTableStatusColumnValueActive),
      INACTIVE: formatMessage(promoCodeManagementMessages.promoCodesTableStatusColumnValueInactive),
      UPCOMING: formatMessage(promoCodeManagementMessages.promoCodesTableStatusColumnValueUpcoming),
    }),
    [formatMessage]
  )

  const discountTypeDisplayNames: Record<'DOLLAR_DISCOUNT' | 'PERCENT_DISCOUNT', string> = useMemo(
    () => ({
      DOLLAR_DISCOUNT: formatMessage(promoCodeManagementMessages.promoCodesTableDiscountTypeColumnValueFixed),
      PERCENT_DISCOUNT: formatMessage(promoCodeManagementMessages.promoCodesTableDiscountTypeColumnValuePercent),
    }),
    [formatMessage]
  )
  return {
    dateRangeDisplayNames,
    dateRangeDescriptions,
    codeTypeDisplayNames,
    platformApplyTypeDisplayNames,
    statusDisplayNames,
    discountTypeDisplayNames,
  }
}
