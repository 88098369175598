import React from 'react'
import styled from 'styled-components'

const ConfigButton = styled.button`
  position: relative;
  width: 153px;
  line-height: 30px;
  height: 30px;
  background-color: #e6f2f3;
  color: #555b63;
  border-radius: 4px;
  border: none;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: space-evenly;
  padding: 1px 0 0 19px;
  outline: none;
  cursor: pointer;
`

const ConfigButtonIcon = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  display: flex;
  left: 9px;
  justify-content: center;
  align-items: center;
  background-color: #0abcc2;
  color: white;
  border-radius: 20px;
  line-height: 12px;
  font-size: 12px;
  font-weight: 100;
  top: 10px;
`

const CustomAutotagConfigButton = ({ id, text, onClick }) => (
  <ConfigButton id={id} onClick={onClick}>
    <ConfigButtonIcon>+</ConfigButtonIcon>
    {text}
  </ConfigButton>
)

export default CustomAutotagConfigButton
