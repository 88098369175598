import { useEffect } from 'react'
import { useGetReferralQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { ReferralsForm } from '../../components'
import { referralsMessages } from '../../locales/referralsMessages'

export interface ReferralsProps {}

export function Referrals() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { data: referral, isFetching, isError } = useGetReferralQuery({ venueId })

  useEffect(() => {
    if (isError) {
      notify({
        content: formatMessage(referralsMessages.fetchError),
        type: 'error',
      })
    }
  }, [isError, formatMessage])

  return isFetching ? (
    <Box pb="m">
      <Loader />
    </Box>
  ) : (
    <ReferralsForm referral={referral} />
  )
}
