import { useMemo } from 'react'
import type { POSIConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type AutomaticCheckCreationCardFormData = ZodSchema<typeof useAutomaticCheckCreationCardForm>
export type AutomaticCheckCreationCardFormField = Field<AutomaticCheckCreationCardFormData>

export const AutomaticCheckCreationCardFormSchema = z.object({
  isAutomaticCheckCreationEnabled: z.boolean(),
})

export function useAutomaticCheckCreationCardForm() {
  return useMemo(() => AutomaticCheckCreationCardFormSchema, [])
}

export const getAutomaticCheckCreationCardFormDefaultValues = ({
  isAutomaticCheckCreationEnabled,
}: {
  isAutomaticCheckCreationEnabled: boolean
}): AutomaticCheckCreationCardFormData => ({
  isAutomaticCheckCreationEnabled,
})

export const convertAutomaticCheckCreationCardFormDataToPOSIConfig = (
  data: AutomaticCheckCreationCardFormData
): Pick<POSIConfig, 'checkCreationEnabled'> => {
  const { isAutomaticCheckCreationEnabled: checkCreationEnabled } = data
  return {
    checkCreationEnabled,
  }
}
