import { useMemo } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { TreeNode } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { UpgradesValue } from './UpgradesProps'
import type { UpgradesForm } from '../Upgrades.zod'

export interface SelectedCategoriesViewProps {
  selection: UpgradesForm['selection']
  options: TreeNode<UpgradesValue>[]
}

export function SelectedCategoriesView({ selection, options }: SelectedCategoriesViewProps) {
  const { formatMessage } = useLocales()

  const selectedCategories = useMemo(() => getSelectedCategories(selection, options), [selection, options])

  return (
    <VStack spacing="s">
      {selectedCategories.length === 0 && <Text>{formatMessage(commonMessages.none)}</Text>}
      {selectedCategories.length > 0 &&
        selectedCategories.map(({ id, label, count, isRequired }) => (
          <Text key={id}>
            <Text>{`${label} (${count}) - `}</Text>
            <Text color="secondaryFont">
              {isRequired ? formatMessage(commonMessages.required) : formatMessage(commonMessages.optional)}
            </Text>
          </Text>
        ))}
    </VStack>
  )
}

function getSelectedCategories(selection: UpgradesForm['selection'], options: TreeNode<UpgradesValue>[]) {
  const selectedItems = selection.map(({ id }) => id) ?? []
  return (
    options
      .filter(({ id }) => id && selectedItems.includes(id))
      ?.map(({ id, label, children, value }) => ({
        id,
        label,
        isRequired: value.isRequired,
        count: children?.length ?? 0,
      })) ?? []
  )
}
