import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo, useState } from 'react'
import { useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, MultiSelectTag, generateTagsData, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { Box, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { extractAutoTagIds } from '@sevenrooms/marketing'
import { priorityAlertsTiersMessages } from './PriorityAlertsTiers.messages'
import type { ReservationsDiningForm } from '../../Reservations'

interface PriorityAlertsTiersProps {
  field: Field<ReservationsDiningForm>
  venueUrlKey: string | null
}

export function PriorityAlertsTiers({ field, venueUrlKey }: PriorityAlertsTiersProps) {
  const { formatMessage } = useLocales()
  const { data: clientAutotags, isFetching: isClientAutotagsFetching } = useGetClientTagGroupsQuery(
    venueUrlKey ? { venueKey: venueUrlKey, includeRebuildState: true } : skipToken
  )

  const priorityAlertsTierOneTags = useWatch(field.prop('priorityAlertsTierOneTags'))
  const priorityAlertsTierTwoTags = useWatch(field.prop('priorityAlertsTierTwoTags'))
  const { tagCategories, tagOptions } = useMemo(() => generateTagsData(clientAutotags), [clientAutotags])

  const [selectedTierOneTagIds, setSelectedTierOneTagIds] = useState<Set<string>>(new Set(extractAutoTagIds(priorityAlertsTierOneTags)))
  const [selectedTierTwoTagIds, setSelectedTierTwoTagIds] = useState<Set<string>>(new Set(extractAutoTagIds(priorityAlertsTierTwoTags)))
  const handleTierOneSelectTagOnChange = (selectedTags: TagOption[]) => {
    setSelectedTierOneTagIds(new Set(extractAutoTagIds(selectedTags)))
  }

  const handleTierTwoSelectTagOnChange = (selectedTags: TagOption[]) => {
    setSelectedTierTwoTagIds(new Set(extractAutoTagIds(selectedTags)))
  }

  return (
    <Box>
      {isClientAutotagsFetching ? (
        <Loader />
      ) : (
        <VStack spacing="m" mt="m">
          <Label primary={formatMessage(priorityAlertsTiersMessages.tierOneLabel)} data-test="tier-one-tags-label">
            <MultiSelectTag
              searchable
              field={field.prop('priorityAlertsTierOneTags')}
              categories={tagCategories}
              options={tagOptions.filter(option => !selectedTierTwoTagIds.has(option.id))}
              onChange={handleTierOneSelectTagOnChange}
              data-test="tier-one-tags-selector"
            />
          </Label>
          <Label primary={formatMessage(priorityAlertsTiersMessages.tierTwoLabel)} data-test="tier-two-tags-label">
            <MultiSelectTag
              searchable
              field={field.prop('priorityAlertsTierTwoTags')}
              categories={tagCategories}
              options={tagOptions.filter(option => !selectedTierOneTagIds.has(option.id))}
              onChange={handleTierTwoSelectTagOnChange}
              data-test="tier-two-tags-selector"
            />
          </Label>
          <Label
            primary={formatMessage(priorityAlertsTiersMessages.tierThreeLabel)}
            secondary={formatMessage(priorityAlertsTiersMessages.everyoneElseTierLabel)}
            data-test="tier-three-label"
          />
        </VStack>
      )}
    </Box>
  )
}
