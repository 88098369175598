import { defineMessages } from '@sevenrooms/core/locales'

export const PartySizeLocales = defineMessages({
  minRequired: {
    id: 'ar.partySize.minRequired',
    defaultMessage: 'Min party size is required.',
  },
  maxRequired: {
    id: 'ar.partySize.maxRequired',
    defaultMessage: 'Max party size is required.',
  },
  maxGreaterThanMin: {
    id: 'ar.partySize.maxGreaterThanMin',
    defaultMessage: 'Max party size must be greater than or equal to Min party size.',
  },
  minNonZero: {
    id: 'ar.partySize.minNonZero',
    defaultMessage: 'Minimum party size cannot be 0.',
  },
  maxNonZero: {
    id: 'ar.partySize.maxNonZero',
    defaultMessage: 'Maximum party size cannot be 0.',
  },
  title: {
    id: 'ar.partySize.title',
    defaultMessage: 'Party Size',
  },
  description: {
    id: 'ar.partySize.description',
    defaultMessage: 'Ex: Only allow parties of 2 to 6 to book online.',
  },
  subCaption: {
    id: 'ar.partySize.subCaption',
    defaultMessage: 'Limit online reservations through this Access Rule to specific party sizes.',
  },
  partySizeLabel: {
    id: 'ar.partySize.partySizeLabel',
    defaultMessage: 'Party size:',
  },
  minLabel: {
    id: 'ar.partySize.minLabel',
    defaultMessage: 'Min Party Size',
  },
  minPlaceholder: {
    id: 'ar.partySize.minPlaceholder',
    defaultMessage: 'Enter min party size',
  },
  maxLabel: {
    id: 'ar.partySize.maxLabel',
    defaultMessage: 'Max Party Size',
  },
  maxPlaceholder: {
    id: 'ar.partySize.maxPlaceholder',
    defaultMessage: 'Enter max party size',
  },
  privateEventNotice: {
    id: 'ar.partySize.privateEventNotice',
    defaultMessage: 'Reservations booked on this Access Rule are considered Group Bookings (special large party bookings).',
  },
  missingDurationWarning: {
    id: 'ar.durations.missingDurationWarning',
    defaultMessage: 'Set a Duration for this Party Size under the Durations section below.',
  },
  buttonToDurations: {
    id: 'ar.durations.buttonToDurations',
    defaultMessage: 'Take me to Durations',
  },
} as const)
