import { useMemo, type PropsWithChildren } from 'react'
import type { AccessRuleAccessTime } from '@sevenrooms/core/domain'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, DateRangePicker, DayOfWeekPicker, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Grid, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAccessRuleContext } from '../shared'
import { CustomTimeRangeSelector, ShiftSelector, SpecificTimeSlotsSelector } from './components'
import { ScheduleLocales } from './Schedule.locales'
import { ScheduleTestId } from './Schedule.testIds'
import type { ScheduleProps } from './ScheduleProps'

export function Schedule({ field, showTimeOnly }: ScheduleProps) {
  return (
    <VStack spacing="sm">
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 8">
          {!showTimeOnly && <DaysPart field={field} />}
          <TimePart field={field} showTimeOnly={showTimeOnly} />
        </Box>
      </Grid>
    </VStack>
  )
}

function DaysPart({ field }: ScheduleProps) {
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="sm" mb="sm">
      <DetachedLabel text={formatMessage(ScheduleLocales.datesLabel)}>
        <DateRangePicker
          data-test={ScheduleTestId.dateRangePicker}
          id={ScheduleTestId.dateRangePicker}
          field={field.prop('dateRange')}
          infinite
        />
      </DetachedLabel>
      <DetachedLabel text={formatMessage(ScheduleLocales.daysOfWeekLabel)}>
        <DayOfWeekPicker
          data-test={ScheduleTestId.dateRangePicker}
          name={ScheduleTestId.dayOfWeekPicker}
          field={field.prop('selectedDays')}
        />
      </DetachedLabel>
    </VStack>
  )
}

function TimePart({ field, showTimeOnly }: ScheduleProps) {
  const { shifts } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const accessTimeChoices: SelectOption<AccessRuleAccessTime>[] = useMemo(
    () => [
      {
        id: 'ALL',
        label: formatMessage(ScheduleLocales.timeSlotAll),
      },
      {
        id: 'TIME_RANGE',
        label: formatMessage(ScheduleLocales.timeSlotCustom),
      },
      {
        id: 'SPECIFIC',
        label: formatMessage(ScheduleLocales.timeSlotSpecific),
      },
    ],
    [formatMessage]
  )

  const accessTimeType = useWatch(field.prop('accessTimeType'))

  const accessTimeSelect = (
    <FormSelect
      data-test={ScheduleTestId.timeSlotFilterType}
      searchable={false}
      options={accessTimeChoices}
      field={field.prop('accessTimeType')}
    />
  )

  return (
    <VStack spacing="sm">
      {showTimeOnly ? (
        accessTimeSelect
      ) : (
        <Label primary={formatMessage(ScheduleLocales.timeSlotsLabel)} secondary={formatMessage(ScheduleLocales.timeSlotsDescription)}>
          {accessTimeSelect}
        </Label>
      )}
      {accessTimeType === 'ALL' && <ShiftSelector field={field} />}
      {accessTimeType === 'TIME_RANGE' && <CustomTimeRangeSelector field={field} />}
      {accessTimeType === 'SPECIFIC' && <SpecificTimeSlotsSelector field={field} />}
      {shifts.length === 0 && (
        <HStack data-test={ScheduleTestId.noOverlapsWarning} spacing="s">
          <Icon name="VMSWeb-warning" color="warning" size="lg" />
          <Text>{formatMessage(ScheduleLocales.noOverlapsWarning)}</Text>
        </HStack>
      )}
    </VStack>
  )
}

type DetachedLabelProps = PropsWithChildren<{
  text: string
}>

function DetachedLabel({ text, children }: DetachedLabelProps) {
  return (
    <Box>
      <Box mb="s">
        <Text>{text}</Text>
      </Box>
      {children}
    </Box>
  )
}
