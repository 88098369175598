export interface ReferralCardPreviewProp {
  referralLinkSubject: string
  referralLinkBody: string
  referralLink: string
}

export function ReferralCardPreview({ referralLinkSubject, referralLinkBody, referralLink }: ReferralCardPreviewProp) {
  return (
    <table
      style={{
        width: '480px',
        height: '123px',
        borderRadius: '4px',
        border: '24px solid #F7F7F7',
      }}
    >
      <tr>
        <td align="center" style={{ fontFamily: 'Avenir-Book, Tahoma, Geneva, sans-serif', width: '400px', height: '44px' }}>
          <div style={{ width: '400px', marginBottom: '12px' }}>
            <div
              className="card-content"
              style={{
                width: '350px',
                fontSize: '14px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#535353',
                lineHeight: '19px',
                marginTop: '16px',
              }}
            >
              {referralLinkSubject}
            </div>
            <div
              style={{ width: '400px', textAlign: 'center', color: '#878787', lineHeight: '19.62px', marginTop: '7px', fontSize: '14px' }}
            >
              {referralLinkBody}
            </div>
          </div>
          <div style={{ width: '400px', height: '19px', textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
            <span style={{ color: '#427066', fontWeight: '400', lineHeight: '19.62px', fontSize: '14px' }}>{referralLink}</span>
          </div>
        </td>
      </tr>
    </table>
  )
}
