import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { HStack, Pair, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useAccessRuleContext } from '../../shared'
import { customPacingKeysSort } from '../../ViewMode/utils'
import { PacingLocales } from '../Pacing.locales'
import { isArReservingHighPacing } from '../utils'
import { PacingWarning } from './PacingWarning'
import type { PacingProps } from './PacingProps'

export function CollapsedPacing({ field }: PacingProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()
  const { shifts } = useAccessRuleContext()
  const { maxCoversPerSeatingInterval, customPacing, isPacingHeld, setCustomPacing, excludeFromShiftPacing } = useWatch(field) ?? {}
  const { venueStartOfDayHour } = useAppContext()
  const customPacingKeysSorted = customPacingKeysSort(customPacing, venueStartOfDayHour)
  const showPacingWarning = isArReservingHighPacing(shifts, maxCoversPerSeatingInterval, customPacing)

  const pacingHeldMessage = (
    <HStack spacing="xs">
      <Text fontSize="m">{formatMessage(PacingLocales.isPacingHeldOption)}</Text>
      {showPacingWarning && <PacingWarning maxCoversPerSeatingInterval={maxCoversPerSeatingInterval} customPacing={customPacing ?? {}} />}
    </HStack>
  )

  const excludeFromPacingMessage = (
    <HStack spacing="xs">
      <Text fontSize="m">{formatMessage(PacingLocales.excludeFromShiftPacing)}</Text>
    </HStack>
  )

  return setCustomPacing && Object.keys(customPacing).length > 0 ? (
    <VStack spacing="s">
      <Pair
        left={<Text fontWeight="bold">{formatMessage(PacingLocales.timeIntervalHeading)}</Text>}
        right={<Text fontWeight="bold">{formatMessage(PacingLocales.maxCoversPerSeatingInterval)}</Text>}
      />
      <Pair left={formatMessage(PacingLocales.default)} right={`${maxCoversPerSeatingInterval}`} />
      {customPacingKeysSorted.map(
        minutes =>
          minutes !== undefined &&
          customPacing?.[minutes] != null && (
            <Pair key={minutes} left={`${TimeOnly.from({ minutes }).formatSTime()}:`} right={customPacing?.[minutes]} />
          )
      )}
      {isPacingHeld && pacingHeldMessage}
      {venueSettings?.excludeFromShiftPacingEnabled && excludeFromShiftPacing && excludeFromPacingMessage}
    </VStack>
  ) : (
    <VStack spacing="s">
      {maxCoversPerSeatingInterval && (
        <Pair
          left={formatMessage(PacingLocales.defaultCollapsedContentLabel)}
          right={`${maxCoversPerSeatingInterval} ${formatMessage(PacingLocales.unit)}`}
        />
      )}
      {isPacingHeld && pacingHeldMessage}
      {venueSettings?.excludeFromShiftPacingEnabled && excludeFromShiftPacing && excludeFromPacingMessage}
    </VStack>
  )
}
