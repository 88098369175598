import { useMemo } from 'react'
import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { Category, TagOption } from '@sevenrooms/core/ui-kit/form'
import { Pair } from '@sevenrooms/core/ui-kit/layout'
import { Tag, Text } from '@sevenrooms/core/ui-kit/typography'
import { getTagDisplayName, useAccessRuleContext } from '../../shared'
import { ReservationTagsLocales } from '../ReservationTags.locales'
import type { ReservationTagsEditProps } from './ReservationTagsEditProps'

export function CollapsedReservationTags({ field, categories }: ReservationTagsEditProps) {
  const { formatMessage } = useLocales()
  const reservationTags = useWatch(field)

  return (
    <Text fontSize="m">
      <Pair
        left={formatMessage(ReservationTagsLocales.label)}
        right={<SelectedReservationTags tags={reservationTags} categories={categories} />}
      />
    </Text>
  )
}

interface SelectedReservationTagsProps {
  tags: TagOption[]
  categories: Category[]
}

function SelectedReservationTags(props: SelectedReservationTagsProps) {
  const { tagGroups } = useAccessRuleContext()
  const categoryColorMap = useMemo(() => new Map(props.categories.map(category => [category.id, category.color])), [props.categories])
  return (
    <>
      {props.tags.map(tag => (
        <Tag key={tag.id} backgroundColor={categoryColorMap.get(tag.categoryId) ?? ''}>
          {getTagDisplayName(tag, tagGroups)}
        </Tag>
      ))}
    </>
  )
}
