import React from 'react'
import { Banner, type BannerProps, Box } from '@sevenrooms/core/ui-kit/layout'

export interface SettingsBannerProps extends BannerProps {}

export function SettingsBanner({ ...props }: SettingsBannerProps) {
  return (
    <Box width="100%">
      <Banner {...props} />
    </Box>
  )
}
