import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  valuePropHeader: {
    id: 'paymentSettings.PaymentsValueProp.valuePropHeader',
    defaultMessage: 'Discover all the ways to grow your sales online',
  },
  valuePropDescription: {
    id: 'paymentSettings.PaymentsValueProp.valuePropDescription',
    defaultMessage: 'Easily generate revenue 24/7 by making your offerings available to purchase online. Get started today.',
  },
  valueProp1Header: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Header',
    defaultMessage: 'Experiences',
  },
  valueProp1Description: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Description',
    defaultMessage: 'Bookable recurring experiences sold through reservation widget and offers page.',
  },
  valueProp1Item1: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Item1',
    defaultMessage: 'Wine or Chef Tasting',
  },
  valueProp1Item2: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Item2',
    defaultMessage: 'Prix Fixe Menu',
  },
  valueProp1Item3: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Item3',
    defaultMessage: 'Bottomless Brunch',
  },
  valueProp1Amount: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Amount',
    defaultMessage: '$4,212',
  },
  valueProp1AmountDescription: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1AmountDescription',
    defaultMessage: 'Average earnings per month from Experiences',
  },
  valueProp1Button: {
    id: 'paymentSettings.PaymentsValueProp.valueProp1Button',
    defaultMessage: 'Create an Experience',
  },
  valueProp2Header: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Header',
    defaultMessage: 'Upgrades',
  },
  valueProp2Description: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Description',
    defaultMessage: 'Offer add-ons while the guest is booking a reservation.',
  },
  valueProp2Item1: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Item1',
    defaultMessage: 'Desserts and Drinks',
  },
  valueProp2Item2: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Item2',
    defaultMessage: 'Premium Seating Areas',
  },
  valueProp2Item3: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Item3',
    defaultMessage: 'Merchandise',
  },
  valueProp2Amount: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Amount',
    defaultMessage: '36%',
  },
  valueProp2AmountDescription: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2AmountDescription',
    defaultMessage: 'Increase in average check spend with prepayments',
  },
  valueProp2Button: {
    id: 'paymentSettings.PaymentsValueProp.valueProp2Button',
    defaultMessage: 'Create an Upgrade',
  },
  valueProp3Header: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Header',
    defaultMessage: 'Events',
  },
  valueProp3Description: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Description',
    defaultMessage: 'Host and promote ticketed events at your venue.',
  },
  valueProp3Item1: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Item1',
    defaultMessage: 'Holiday Party',
  },
  valueProp3Item2: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Item2',
    defaultMessage: 'Live Music',
  },
  valueProp3Item3: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Item3',
    defaultMessage: 'Corporate Event',
  },
  valueProp3Amount: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Amount',
    defaultMessage: '$100+',
  },
  valueProp3AmountDescription: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3AmountDescription',
    defaultMessage: 'Savings per month vs using a third-party software',
  },
  valueProp3Button: {
    id: 'paymentSettings.PaymentsValueProp.valueProp3Button',
    defaultMessage: 'Create an Event',
  },
  valueProp4Header: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Header',
    defaultMessage: 'Policy Settings',
  },
  valueProp4Description: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Description',
    defaultMessage: 'Completely customizable and easy to implement based on time, party size or seating area.',
  },
  valueProp4Item1: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Item1',
    defaultMessage: 'Deposits',
  },
  valueProp4Item2: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Item2',
    defaultMessage: 'Minimum Spend',
  },
  valueProp4Item3: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Item3',
    defaultMessage: 'Cancellation Fee',
  },
  valueProp4Amount: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Amount',
    defaultMessage: '1%',
  },
  valueProp4AmountDescription: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4AmountDescription',
    defaultMessage: 'Help reduce no-show and cancellation rates as low as 1%',
  },
  valueProp4Button: {
    id: 'paymentSettings.PaymentsValueProp.valueProp4Button',
    defaultMessage: 'Create a Policy Setting',
  },
  valuePropRequestSupport: {
    id: 'paymentSettings.PaymentsValueProp.valuePropRequestSupport',
    defaultMessage: 'Not available on all packages.',
  },
  valuePropLearnHow: {
    id: 'paymentSettings.PaymentsValueProp.valuePropLearnHow',
    defaultMessage: 'Learn How',
  },
} as const)
