import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  account: {
    id: 'paymentSettings.Saferpay.account',
    defaultMessage: 'Saferpay Account',
  },
  authentication: {
    id: 'paymentSettings.Saferpay.authentication',
    defaultMessage: 'Authentication',
  },
  customerId: {
    id: 'paymentSettings.Saferpay.customerId',
    defaultMessage: 'Customer ID',
  },
  terminalId: {
    id: 'paymentSettings.Saferpay.terminalId',
    defaultMessage: 'Terminal ID',
  },
  motoTerminalId: {
    id: 'paymentSettings.Saferpay.motoTerminalId',
    defaultMessage: 'MOTO Terminal ID',
  },
  token: {
    id: 'paymentSettings.Saferpay.Token',
    defaultMessage: 'Saferpay Token',
  },
  urlFields: {
    id: 'paymentSettings.Saferpay.urlFields',
    defaultMessage: 'URL Fields',
  },
  apiUser: {
    id: 'paymentSettings.Saferpay.apiUser',
    defaultMessage: 'API User',
  },
  apiPassword: {
    id: 'paymentSettings.Saferpay.apiPassword',
    defaultMessage: 'API Password',
  },
  motoToken: {
    id: 'paymentSettings.Saferpay.motoToken',
    defaultMessage: 'MOTO Saferpay Token',
  },
  motoUrlFields: {
    id: 'paymentSettings.Saferpay.motoUrlFields',
    defaultMessage: 'MOTO URL Fields',
  },
} as const)
