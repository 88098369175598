import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  setupLabel: {
    id: 'posiSettings.POSICard.setupLabel',
    defaultMessage: '<a>Set Up Integration</a>',
  },
  contactSupportLabel: {
    id: 'posiSettings.POSICard.contactSupport',
    defaultMessage: '<a>Talk with us today</a>',
  },
  learnMoreLabel: {
    id: 'posiSettings.POSICard.learnMoreLabel',
    defaultMessage: 'Learn more',
  },
} as const)
