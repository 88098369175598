import { defineMessages } from '@sevenrooms/core/locales'

export const FeesLocales = defineMessages({
  feesTitle: {
    id: 'fees.title',
    defaultMessage: 'Group Booking Fees',
  },
  feesDescription: {
    id: 'fees.feesDescription',
    defaultMessage:
      'Manage Group Booking Fees for your venue. You will be able to apply these Fees when configuring Spaces on the Group Bookings page. Doing so will display the Fee requirement to guests. Make sure to give Fees unique names so that guests will understand what they are agreeing to at the time of booking.',
  },
  activeFees: {
    id: 'fees.activeFees',
    defaultMessage: '> Active Fees ',
  },
  feeAmountPercentage: {
    id: 'fees.feeAmountPercentage',
    defaultMessage: 'Amount',
  },
  feeTypeDescription: {
    id: 'fees.feeTypeDescription',
    defaultMessage: 'Type',
  },
  FeeTaxType: {
    id: 'fees.feeTaxType',
    defaultMessage: 'Percentage',
  },
  FeeFixedValueType: {
    id: 'fees.FeeFixedValueType',
    defaultMessage: 'Fixed Value',
  },
  feeName: {
    id: 'fees.feeName',
    defaultMessage: 'Name',
  },
  feeAmount: {
    id: 'fees.amount',
    defaultMessage: 'Amount',
  },
  feeTaxes: {
    id: 'fees.taxes',
    defaultMessage: 'Apply Tax',
  },
  feeNameSecondary: {
    id: 'fees.feeName',
    defaultMessage: "This is how you'll refer to this fee internally and externally",
  },
  feeAddNewItem: {
    id: 'fees.feeAddNewItem',
    defaultMessage: '(+) Add Fee',
  },
  feesUpdated: {
    id: 'fees.feesUpdated',
    defaultMessage: 'Fees were updated successfully',
  },
} as const)
