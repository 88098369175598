// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'

export function CampaignProgressLine({ performance, standard }: { performance: number; standard: number }) {
  const { formatMessage } = useLocales()

  return (
    <Box p="lm" mt="m" mb="lm" minWidth="363px">
      <ProgressLine>
        <Campaign progress={performance}>
          {formatMessage(campaignCenterMessages.campaignPerformanceOpenRateBenchmark, { performance })}
          <ProgressArrow />
        </Campaign>
        <IndustryStandart progress={standard}>
          {formatMessage(campaignCenterMessages.campaignPerformanceOpenRateStandard, { standard })}
          <ProgressArrow isTop />
        </IndustryStandart>
      </ProgressLine>
    </Box>
  )
}

const ProgressLine = styled.div`
  position: relative;
  width: 100%;
  height: 9px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(124, 177, 223, 1) 0%, rgba(124, 123, 193, 1) 100%);
`
const IndustryStandart = styled.div<{ progress: number }>`
  left: calc(${props => (props.progress * 100) / 100}% - 75px);
  position: absolute;
  top: 18px;
  color: ${props => props.theme.colors.inactive};
`
const Campaign = styled.div<{ progress: number }>`
  left: calc(${props => (props.progress * 100) / 100}% - 75px);
  position: absolute;
  bottom: 18px;
  color: ${props => props.theme.colors.darkFont};
  font-weight: bold;
`
const ProgressArrow = styled.div<{ isTop?: boolean }>`
  width: 3px;
  height: 14px;
  background: currentColor;
  position: absolute;
  left: calc(50% - 2px);
  display: flex;
  justify-content: center;
  border-radius: 7px;
  top: ${props => (props.isTop ? '-15px' : '19px')};
  &:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: currentColor;
    top: ${props => (props.isTop ? '-2px' : '10px')};
  }
`
