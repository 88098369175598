import React from 'react'
import { css } from 'styled-components'
import PositiveIntInput from 'svr/component-lib/Generic/TextInputs/PositiveIntInput'
import { ValueWrapper, InlineText } from './CustomAutotagShared'

const CustomAutotagDuration = ({ id, value, onChange }) => {
  const onChangeHandler = newValue => onChange(newValue || 1)
  return (
    <ValueWrapper
      id={`${id}-value-wrapper`}
      css={`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 120px;
      `}
    >
      <PositiveIntInput
        inputId={id}
        value={value}
        onChange={onChangeHandler}
        customInputWrapper={css`
          height: 35px;
          width: 59px;
        `}
        customInput={css`
          height: 35px;
          box-sizing: border-box;
          border-width: 1px;
          border-color: #dbdcde;
          outline: none;
          font-size: 14px;
        `}
      />
      <InlineText>min(s)</InlineText>
    </ValueWrapper>
  )
}

export default CustomAutotagDuration
