import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Label, TextArea } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { autotagSlideOutMessages } from '../../locales'
import { AutotagExpander } from './AutotagExpander'

export interface AutotagNotesProps {
  notes: string
  onChange: (value: string) => void
  disabled: boolean
  errorMessage: string
  isCustomAutotag?: boolean
}

const ActionIcon = styled(StyledIcons.XS)``

const CustomAutotagNotes = styled.div`
  width: 392px;
  height: 92px;
`

export function AutotagNotes({ notes, onChange, disabled, errorMessage, isCustomAutotag = false }: AutotagNotesProps) {
  const { formatMessage } = useLocales()
  const [expanded, setExpanded] = useState(Boolean(notes))
  const uuid = v4()

  const handleOnChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = event.target as HTMLTextAreaElement
    onChange(value)
  }

  const handleOnClickDelete = () => {
    // collapse expander
    setExpanded(false)
    // delete notes
    onChange('')
  }

  if (isCustomAutotag) {
    return (
      <CustomAutotagNotes data-test="custom-autotag-notes">
        <TextArea
          id={uuid}
          resize="none"
          value={notes}
          onChange={handleOnChange}
          disabled={disabled}
          placeholder={formatMessage(autotagSlideOutMessages.additionalNotesDescription)}
          errorMessage={errorMessage}
          data-test="autotag-additional-notes"
        />
      </CustomAutotagNotes>
    )
  }

  return (
    <AutotagExpander
      title={
        <>
          <ActionIcon>{VmsIcons.Plus}</ActionIcon>
          <span>&nbsp;{formatMessage(autotagSlideOutMessages.addAdditionalNotes)}</span>
        </>
      }
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <>
        <HStack justifyContent="space-between">
          <Label
            htmlFor={uuid}
            primary={formatMessage(autotagSlideOutMessages.additionalNotesLabelPrimary)}
            info={<>{formatMessage(autotagSlideOutMessages.additionalNotesLabelInfo)}</>}
          />
          <Box mb="s">
            <Button
              data-test="delete-button"
              onClick={handleOnClickDelete}
              variant="tertiary-warning"
              size="s"
              icon="VMSWeb-trash"
              disabled={disabled}
            >
              Delete
            </Button>
          </Box>
        </HStack>
        <TextArea
          id={uuid}
          resize="none"
          value={notes}
          onChange={handleOnChange}
          disabled={disabled}
          placeholder={formatMessage(autotagSlideOutMessages.additionalNotesDescription)}
          errorMessage={errorMessage}
          data-test="autotag-additional-notes"
        />
      </>
    </AutotagExpander>
  )
}

AutotagNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
}
