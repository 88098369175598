import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { SimphonyAuthenticationCardFormSchema } from '../../Edit/AuthenticationCard/SimphonyAuthenticationCard/SimphonyAuthenticationCardForm.zod'

export type SimphonyAuthenticatePageFormData = ZodSchema<typeof useSimphonyAuthenticatePageForm>
export type SimphonyAuthenticatePageFormField = Field<SimphonyAuthenticatePageFormData>

export function useSimphonyAuthenticatePageForm() {
  return useMemo(
    () =>
      SimphonyAuthenticationCardFormSchema.extend({
        username: z.string().min(1, ''),
        password: z.string().min(1, ''),
      }),
    []
  )
}

export const getSimphonyAuthenticatePageFormDefaultValues = (config?: SimphonyConfig): SimphonyAuthenticatePageFormData => ({
  username: '',
  password: '',
  orgShortName: config?.orgShortName ?? '',
  openidUrl: config?.openidUrl ?? '',
  simphonyBaseUrl: config?.simphonyBaseUrl ?? '',
  openidClientId: config?.openidClientId ?? '',
})
