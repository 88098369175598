import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { List, ListItem, Text, type TextProps } from '@sevenrooms/core/ui-kit/typography'

export const formatBold = (chunks: string[], color?: ThemeColor) => (
  <Text fontWeight="bold" color={color}>
    {chunks}
  </Text>
)

export const formatParagraph = (chunks: string[], type: TextProps['type'] = 'p') => (
  <Text type={type} color="inherit">
    {chunks}
  </Text>
)

export const formatList = (chunks: string[]) => <List>{chunks}</List>
export const formatListItem = (chunks: string[]) => <ListItem>{chunks}</ListItem>

export const formatTitleCase = (s: string) =>
  s
    .split(/(\s)/)
    .map(w => w.charAt(0).toUpperCase() + w.substring(1))
    .join('')
