import * as React from 'react'
import { z } from 'zod'
import type { ZodSchema } from '@sevenrooms/core/form'

export type FormValues = ZodSchema<typeof useBackwriterSettingsSchema>

export function useBackwriterSettingsSchema() {
  return React.useMemo(
    () =>
      z.object({
        enabled: z.boolean(),
        promptSettings: z.object({
          emailGeneralTuning: z.string().optional(),
          emailSignature: z.string().optional(),
          reviewsGeneralTuning: z.string().optional(),
          smsGeneralTuning: z.string().optional(),
          clientNotesGeneralTuning: z.string().optional(),
        }),
      }),
    []
  )
}
