import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'
import { InvalidMessage } from './CustomAutotagShared'

const TextInputWrapper = styled.div``

class CustomAutotagStringComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isFocused: false }
  }

  focusHandler = () => {
    this.setState({ isFocused: true })
  }

  blurHandler = () => {
    this.setState({ isFocused: false })
  }

  render() {
    const { id, value, field, onChange, isConfigStateValid, invalidMessage } = this.props
    const isValid = isConfigStateValid || value || this.state.isFocused
    return (
      <TextInputWrapper>
        <TextInput
          inputId={id}
          value={value}
          field={field}
          onChange={onChange}
          invalidMessage={invalidMessage}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          disableAutoComplete
          placeholder="enter text"
          customInputWrapper={css`
            width: 212px;
          `}
          customInput={css`
            border: 1px solid ${isValid ? '#DBDCDE' : '#D01A20'} !important;
            font-size: 14px !important;
            color: black !important;
            padding: 5px 10px !important;
            height: 35px;
            box-sizing: border-box;
            outline: none;
            width: 100% !important;
            ::placeholder {
              color: #dbdcde;
            }
          `}
        />
        {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
      </TextInputWrapper>
    )
  }
}

export default CustomAutotagStringComponent
