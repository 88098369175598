import { defineMessages } from '@sevenrooms/core/locales'

export const marketingUpsellMessages = defineMessages({
  smsMarketingLead: {
    id: 'marketingUpsellMessages.smsMarketingLead',
    defaultMessage: 'Send text messages guests will crave.',
  },
  smsMarketingStat0: {
    id: 'marketingUpsellMessages.smsMarketingStat0',
    defaultMessage: 'Open Rate',
  },
  smsMarketingStat1: {
    id: 'marketingUpsellMessages.smsMarketingStat1',
    defaultMessage: 'Revenue per Text<footnote_idx>',
  },
  smsMarketingStat1Footnote0: {
    id: 'marketingUpsellMessages.smsMarketingStat1Footnote0',
    defaultMessage:
      'Revenue per Text: (Total Reservation Revenue generated by Beta Customers) divided by (Total Number of Messages sent by Beta Customers)',
  },
  smsMarketingStat2: {
    id: 'marketingUpsellMessages.smsMarketingStat2',
    defaultMessage: 'ROI<footnote_idx> for Beta Customers',
  },
  smsMarketingStat2Footnote0: {
    id: 'marketingUpsellMessages.smsMarketingStat2Footnote0',
    defaultMessage:
      'ROI: (Total Reservation Revenue generated by Beta Customers) divided by (Total Message Costs. Total Message Cost = Cost per Message * Total Number of Messages sent by Beta Customers)',
  },
  smsMarketingBullets: {
    id: 'marketingUpsellMessages.smsMarketingBullets',
    defaultMessage:
      '<li>Send personalized Text Marketing campaigns at scale, segmented by guest data.</li>' +
      '<li>Create and send campaigns in minutes with data-backed hospitality templates that are proven to convert.</li>' +
      '<li>Measure the impact of text campaigns with a real-time view of the revenue, reservations, covers, and orders attributed to each campaign.</li>',
  },
  smsMarketingImageAlt: {
    id: 'marketingUpsellMessages.smsMarketingImageAlt',
    defaultMessage: 'Preview of a text message received by a guest, with projected revenue.',
  },
  emailMarketingLead: {
    id: 'marketingUpsellMessages.emailMarketingLead',
    defaultMessage: 'Craft and send revenue-generating emails with ease.',
  },
  emailMarketingStat0: {
    id: 'marketingUpsellMessages.emailMarketingStat0',
    defaultMessage: 'Industry-leading Open Rates',
  },
  emailMarketingStat1: {
    id: 'marketingUpsellMessages.emailMarketingStat1',
    defaultMessage: 'Average Revenue Per Venue',
  },
  emailMarketingStat2: {
    id: 'marketingUpsellMessages.emailMarketingStat2',
    defaultMessage: 'Revenue Increase<footnote_idx>',
  },
  emailMarketingStat2Footnote0: {
    id: 'marketingUpsellMessages.emailMarketingStat2Footnote0',
    defaultMessage: 'SevenRooms restaurants that had Email Marketing saw 70% more revenue than restaurants without it',
  },
  emailMarketingBullets: {
    id: 'marketingUpsellMessages.emailMarketingBullets',
    defaultMessage:
      '<li>Track Email Marketing ROI metrics that matter to your business, like reservations and revenue.</li>' +
      '<li>Create an email in minutes with a drag-and-drop editor and time-saving templates.</li>' +
      '<li>Reach your audience with just the right message using data-driven segmentations.</li>',
  },
  emailMarketingImageAlt: {
    id: 'marketingUpsellMessages.emailMarketingImageAlt',
    defaultMessage: 'Preview of a drag and drop email editor, with projected revenue.',
  },
} as const)
