import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { referralsMessages } from '../../locales'

export interface ReferralProgramSaveModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onSave: Function
}
export function ReferralProgramSaveModal({ closeHref, onSave }: ReferralProgramSaveModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle title={formatMessage(referralsMessages.referralProgramSaveModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(referralsMessages.referralProgramSaveModalSubTitle)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onSave()
            }}
            data-test="apply-changes-button"
          >
            {formatMessage(commonMessages.applyChanges)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
