import Fuse from 'fuse.js'
import _ from 'lodash'
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import {
  ValueWrapper,
  ValueBox,
  SearchIcon,
  DropdownTextInput,
  DropdownSelectText,
  DropdownList,
  DropdownListItem,
  DropdownCaret,
  InvalidMessage,
} from './CustomAutotagShared'

const DROPDOWN_HEIGHT = 210

const FUSE_OPTIONS = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.1,
  keys: ['key', 'displayText'],
}

const _withCustomAutotagSearchDropdownContainer = WrappedComponent => {
  class CustomAutotagSearchDropdownInner extends React.PureComponent {
    constructor(props) {
      super(props)
      const { options, isSearchable } = this.props
      this.state = {
        isActive: false,
        listOptions: [...options],
        searchValue: '',
        renderBelow: true,
      }
      this.fuse = null
      if (isSearchable) {
        this.fuse = new Fuse(this.state.listOptions, FUSE_OPTIONS)
      }
      this.textInputID = 'sr-searchable-dropdown'
      this.optionsMap = this.transformOptionsListToMap(options)
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (this.props.isSearchable && !prevState.isActive && this.state.isActive) {
        const element = document.getElementById(this.textInputID)
        if (element) {
          element.focus()
        }
      }
      if (!prevState.isActive && this.state.isActive) {
        try {
          const componentRect = document.getElementById(this.props.id).getBoundingClientRect()
          const viewRect = document.getElementById(this.props.viewID).getBoundingClientRect()
          /* eslint-disable react/no-did-update-set-state */
          if (componentRect.bottom + DROPDOWN_HEIGHT >= viewRect.bottom) {
            this.setState({ renderBelow: false })
          } else {
            this.setState({ renderBelow: true })
          }
          /* eslint-enable */
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('Failed to set dropdown direction', err)
        }
      }
    }

    transformOptionsListToMap = optionsList => _.reduce(optionsList, (accum, option) => ({ ...accum, [option.key]: option }), {})

    getDisplayText = optionKey => _.get(this.optionsMap, [optionKey, 'displayText'], null)

    handleClickOutside = () => this.setState({ isActive: false })

    onClickHandler = () => this.setState({ isActive: !this.state.isActive })

    onChangeHandler = (optionKey, isActive) => {
      this.setState({ isActive })
      this.props.onChange(optionKey)
    }

    searchForListOptions = _.throttle(
      searchValue => {
        const listOptions = this.fuse.search(searchValue)
        this.setState({ listOptions })
      },
      3,
      { trailing: true }
    )

    onSearchTextChange = event => {
      const searchValue = event.target.value
      const searchValueChanged = searchValue !== this.state.searchValue
      if (searchValueChanged) {
        if (!searchValue) {
          this.setState({
            searchValue,
            listOptions: _.values(this.optionsMap),
          })
        } else {
          this.setState({ searchValue })
          this.searchForListOptions(searchValue)
        }
      }
    }

    render() {
      const dropdownProps = {
        ...this.props,
        ...this.state,
        dropdownHeight: DROPDOWN_HEIGHT,
        textInputID: this.textInputID,
        optionsMap: this.optionsMap,
        getDisplayText: this.getDisplayText,
        onChangeHandler: this.onChangeHandler,
        onClickHandler: this.onClickHandler,
        onSearchTextChange: this.onSearchTextChange,
        isValid: this.props.isValid || this.state.isActive,
      }
      return <WrappedComponent {...dropdownProps} />
    }
  }
  CustomAutotagSearchDropdownInner.defaultProps = { isValid: true }
  return CustomAutotagSearchDropdownInner
}

export const withCustomAutotagSearchDropdownContainer = WrappedComponent =>
  onClickOutside(_withCustomAutotagSearchDropdownContainer(WrappedComponent))

const CustomAutotagSearchDropdownSingleBody = ({
  id,
  isSearchable,
  selectedValue,
  placeholder,
  isActive,
  listOptions,
  searchValue,
  getDisplayText,
  customWrapper,
  valueBoxCss,
  dropdownListCss,
  caretCss,
  dropdownSelectTextCss,
  dropdownIcon,
  onChangeHandler,
  onClickHandler,
  onSearchTextChange,
  textInputID,
  dropdownHeight,
  renderBelow,
  isValid,
  invalidMessage,
}) => {
  let val = selectedValue
  if (selectedValue === true) {
    val = 'true'
  } else if (selectedValue === false) {
    val = 'false'
  }
  return (
    <ValueWrapper css={customWrapper} id={id}>
      {isSearchable && isActive ? (
        <ValueBox
          onClick={onClickHandler}
          isValid={isValid}
          css={css`
            height: 35px;
            flex-flow: nowrap;
          `}
        >
          <SearchIcon>{VmsIcons.Search}</SearchIcon>
          <DropdownTextInput
            id={textInputID}
            value={searchValue}
            placeholder="Search..."
            onChange={onSearchTextChange}
            autoComplete="off"
          />
          <DropdownCaret isActive={isActive}>{VmsIcons.Chevron}</DropdownCaret>
        </ValueBox>
      ) : (
        <ValueBox onClick={onClickHandler} isValid={isValid} css={valueBoxCss}>
          <DropdownSelectText css={dropdownSelectTextCss} selectedValue={val}>
            {val ? getDisplayText(val) : placeholder}
          </DropdownSelectText>
          <DropdownCaret isActive={isActive} css={caretCss}>
            {dropdownIcon || VmsIcons.Chevron}
          </DropdownCaret>
        </ValueBox>
      )}
      {!isValid && <InvalidMessage>{invalidMessage}</InvalidMessage>}
      {isActive && !!listOptions.length && (
        <DropdownList id={`${id}-dropdown`} maxHeight={dropdownHeight} renderBelow={renderBelow} css={dropdownListCss}>
          {_.map(listOptions, option => (
            <DropdownListItem id={option.key} key={option.key} onClick={() => onChangeHandler(option.key, false)}>
              {option.displayText}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </ValueWrapper>
  )
}

export const CustomAutotagSearchDropdownSingle = withCustomAutotagSearchDropdownContainer(CustomAutotagSearchDropdownSingleBody)
