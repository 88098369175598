import { type ReactNode, useMemo } from 'react'
import { AccessRuleTimeUnitEnum, type AccessRuleTimeUnitType } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { FormSelect, FieldErrors, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { SharedTestId } from './shared.testIds'
import { generateTimeSlots } from './utils'
import type { TimeBeforeForm } from './timeBeforeForm'

export interface TimeBeforeProps {
  units?: AccessRuleTimeUnitType[]
  field: Field<TimeBeforeForm>
  extra?: ReactNode
  disabled?: boolean
  hideErrorMessage?: boolean
  'data-test': string
}

export function TimeBefore({ field, units, 'data-test': testId, extra, ...props }: TimeBeforeProps) {
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useAppContext().venueSettings

  const unitOptions = useMemo(
    () =>
      [
        { id: AccessRuleTimeUnitEnum.MINUTES, label: formatMessage(commonMessages.minutesUnitBefore) },
        { id: AccessRuleTimeUnitEnum.HOURS, label: formatMessage(commonMessages.hoursUnitBefore) },
        { id: AccessRuleTimeUnitEnum.DAYS, label: formatMessage(commonMessages.daysUnitBefore) },
        { id: AccessRuleTimeUnitEnum.WEEKS, label: formatMessage(commonMessages.weeksUnitBefore) },
        { id: AccessRuleTimeUnitEnum.MONTHS, label: formatMessage(commonMessages.monthsUnitBefore) },
      ].filter(item => !units || units.includes(item.id)),
    [formatMessage, units]
  )
  const timeOptions = useMemo(
    () => [{ id: '0', label: formatMessage(commonMessages.reservationTime) }, ...generateTimeSlots(startOfDayTime)],
    [formatMessage, startOfDayTime]
  )

  return (
    <VStack spacing="xs" data-test={testId}>
      <HStack spacing="s">
        <Box maxWidth="145px">
          <FormNumberInput width="50px" field={field.prop('count')} {...props} data-test={SharedTestId.timeBeforeCount} hideErrorMessage />
        </Box>
        <FormSelect
          field={field.prop('unit')}
          options={unitOptions}
          searchable={false}
          {...props}
          data-test={SharedTestId.timeBeforeUnit}
          hideErrorMessage
        />
        <FormSelect
          field={field.prop('beforeTime')}
          options={timeOptions}
          searchable={false}
          {...props}
          data-test={SharedTestId.timeBeforeTime}
          hideErrorMessage
        />
        {extra}
      </HStack>
      <FieldErrors data-test={SharedTestId.timeBeforeError} field={field} />
    </VStack>
  )
}
