import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  setupLabel: {
    id: 'paymentsSettings.AdditionalPaymentIntegrations.setupLabel',
    defaultMessage: '<a>Set Up Integration</a>',
  },
  contactSupport: {
    id: 'paymentsSettings.AdditionalPaymentIntegrations.contactSupport',
    defaultMessage: '<a>Talk with us today</a>',
  },
} as const)
