import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { AutotagTierType, TierAttributes } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
  TighterTierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const dayChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

const monthChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

const venueChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class WeMissYouTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getContent(attributes) {
    const type = this.props.tier.tier_type
    let siblingTierName
    switch (type) {
      case AutotagTierType.WE_MISS_YOU:
        return (
          <div>
            Tag clients whose visit was between <strong>{attributes.PAST_MONTHS_MIN}</strong> months and{' '}
            <strong>{attributes.PAST_MONTHS_MAX}</strong> months ago.
          </div>
        )
      case AutotagTierType.WE_MISS_YOU_GLOBAL:
        return (
          <div>
            Tag clients whose visit was between <strong>{attributes.PAST_MONTHS_MIN}</strong> months and{' '}
            <strong>{attributes.PAST_MONTHS_MAX}</strong> months ago and who have visited at least <strong>{attributes.MIN_VENUES}</strong>{' '}
            venue(s) in the venue group.
          </div>
        )
      case AutotagTierType.WELCOME_BACK:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WE_MISS_YOU)
        return <div>Tag clients who had a "{siblingTierName}" tag and now have an upcoming reservation.</div>
      case AutotagTierType.WELCOME_BACK_GLOBAL:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WE_MISS_YOU_GLOBAL)
        return <div>Tag clients who had a "{siblingTierName}" tag and now have an upcoming reservation at any venue in the group.</div>
      case AutotagTierType.WELCOME_BACK_COMPLETED:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WELCOME_BACK)
        return (
          <div>
            This tag will show up after a client tagged with a "{siblingTierName}" tag has completed a visit and will be removed
            automatically after <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
          </div>
        )
      case AutotagTierType.WELCOME_BACK_COMPLETED_GLOBAL:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WELCOME_BACK_GLOBAL)
        return (
          <div>
            This tag will show up after a client tagged with a "{siblingTierName}" tag has completed a visit and will be removed
            automatically after <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
          </div>
        )
      default:
        return null
    }
  }

  getEditor(attributes) {
    let siblingTierName
    const type = this.props.tier.tier_type
    const { index } = this.props
    switch (type) {
      case AutotagTierType.WE_MISS_YOU:
        return (
          <TighterTierEditor>
            Tag clients whose last visit was between
            <DropdownArrowsPicker
              {...dropProps}
              choices={monthChoices}
              value={attributes.PAST_MONTHS_MIN}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MIN, value)}
            />
            months and
            <DropdownArrowsPicker
              {...dropProps}
              choices={monthChoices}
              value={attributes.PAST_MONTHS_MAX}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MAX, value)}
            />
            months ago.
          </TighterTierEditor>
        )
      case AutotagTierType.WE_MISS_YOU_GLOBAL:
        return (
          <TierEditor>
            Tag clients whose last visit was between
            <DropdownArrowsPicker
              {...dropProps}
              choices={monthChoices}
              value={attributes.PAST_MONTHS_MIN}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MIN, value)}
            />
            months and
            <DropdownArrowsPicker
              {...dropProps}
              choices={monthChoices}
              value={attributes.PAST_MONTHS_MAX}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.PAST_MONTHS_MAX, value)}
            />
            months ago and who have visited at least
            <DropdownArrowsPicker
              {...dropProps}
              choices={venueChoices}
              value={attributes.MIN_VENUES}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.MIN_VENUES, value)}
            />
            venue(s) in the venue group.
          </TierEditor>
        )
      case AutotagTierType.WELCOME_BACK:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WE_MISS_YOU)
        return <TighterTierEditor>Tag clients who had a "{siblingTierName}" tag and now have an upcoming reservation.</TighterTierEditor>
      case AutotagTierType.WELCOME_BACK_GLOBAL:
        siblingTierName = this.getSiblingTierName(AutotagTierType.WE_MISS_YOU_GLOBAL)
        return (
          <TighterTierEditor>
            Tag clients who had a "{siblingTierName}" tag and now have an upcoming reservation at any venue in the group.
          </TighterTierEditor>
        )
      case AutotagTierType.WELCOME_BACK_COMPLETED:
      case AutotagTierType.WELCOME_BACK_COMPLETED_GLOBAL:
        siblingTierName = this.getSiblingTierName(
          type === AutotagTierType.WELCOME_BACK_COMPLETED ? AutotagTierType.WELCOME_BACK : AutotagTierType.WELCOME_BACK_GLOBAL
        )
        return (
          <TighterTierEditor>
            This tag will show up after a client tagged with a "{siblingTierName}" tag has completed a visit and will be removed
            automatically after
            <DropdownArrowsPicker
              {...dropProps}
              choices={dayChoices}
              value={attributes.REMOVE_AFTER_DAYS}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.REMOVE_AFTER_DAYS, value)}
            />
            day(s).
          </TighterTierEditor>
        )
      default:
        return null
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier

    const { isActive, id } = this.props

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            {this.getEditor(attributes)}
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>{this.getContent(attributes)}</TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default WeMissYouTier
