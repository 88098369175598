export const ReservationCoverLimitTestId = {
  section: 'reservation-cover-limit',
  zeroCoversTooltip: 'zero-covers-tooltip',
  count: 'reservation-cover-limit-count',
  type: 'reservation-cover-limit-type',
  errors: 'reservation-cover-limiterrors',
  report: 'reservation-cover-limit-report',
  guaranteeBookings: 'reservation-cover-limit-guarantee-bookings',
  guaranteeBookingsTooltip: 'reservation-cover-limit-guarantee-bookings-tooltip',
} as const
