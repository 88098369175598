import { useMemo } from 'react'
import type { Policy } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, TextArea, type TextAreaProps } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'

export interface PolicyPicker {
  choice: Field<string | null>
  freeform: Field<string | null>
  policies: Policy[]
  label: string
  'data-test': string
}

export function PolicyPicker(props: PolicyPicker) {
  const { 'data-test': testId, policies } = props
  const { formatMessage } = useLocales()
  const options = useMemo(
    () => [
      ...policies.map(policy => ({ id: policy.id, label: policy.name })),
      { id: 'custom', label: formatMessage(PaymentPolicyLocales.customPolicy) },
    ],
    [formatMessage, policies]
  )
  return (
    <Box width="448px">
      <VStack data-test={testId} spacing="s">
        <Text fontWeight="bold">{props.label}</Text>
        <FormSelect data-test={`${testId}-select`} field={props.choice} withEmpty options={options} />
        <PolicyText {...props} />
      </VStack>
    </Box>
  )
}

function PolicyText({ 'data-test': testId, ...props }: PolicyPicker) {
  const { venueSettings } = useAppContext()
  const languageCode = venueSettings?.defaultLanguageCode ?? 'en'
  const selection = useWatch(props.choice)
  const preview = props.policies.find(policy => policy.id === selection)?.localizedText?.[languageCode]
  return selection !== 'custom' ? (
    <TextBox data-test={`${testId}-preview`} value={preview} disabled />
  ) : (
    <TextBox data-test={`${testId}-editor`} field={props.freeform} />
  )
}

function TextBox(props: TextAreaProps<string | null>) {
  const { 'data-test': testId } = props
  return <TextArea key={testId} resize="none" {...props} />
}
