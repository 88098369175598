import React, { type PropsWithChildren } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormToggle } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { Box } from '@sevenrooms/react-components/components/Box/index'
import { messages } from './BaseAutomaticPrepaymentRedemptionCard.locales'

export interface BaseAutomaticPrepaymentRedemptionCardProps {
  docLink: string
  isAutomaticPrepaymentRedemptionEnabledField: Field<boolean>
  disabled?: boolean
  cardSubHeadingLabel?: string
}

export function BaseAutomaticPrepaymentRedemptionCard({
  children,
  docLink,
  isAutomaticPrepaymentRedemptionEnabledField,
  disabled,
  cardSubHeadingLabel,
}: PropsWithChildren<BaseAutomaticPrepaymentRedemptionCardProps>) {
  const { formatMessage } = useLocales()

  return (
    <VStack backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
      <HStack spacing="m" pt="m" pb="l" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
        <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
          <HStack width="100%" justifyContent="space-between" alignItems="center">
            <VStack spacing="s">
              <Text textStyle="h2">{formatMessage(messages.cardLabel)}</Text>{' '}
              <SecondaryText>
                {cardSubHeadingLabel ||
                  formatMessage(messages.cardSubHeadingLabel, {
                    a: (chunks: string[]) => <Anchor href={docLink}>{chunks}</Anchor>,
                  })}
              </SecondaryText>
            </VStack>
            <FormToggle field={isAutomaticPrepaymentRedemptionEnabledField} disabled={disabled} />
          </HStack>
          {children && (
            <>
              <Box width="100%">
                <DividerLine mr="none" ml="none" />
              </Box>
              {children}
            </>
          )}
        </VStack>
      </HStack>
    </VStack>
  )
}
