import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPercentageInput, type RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { StandardWidthBox, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { DisplayIfSelected } from '../../../ChargeSettings'
import { AccessRuleTooltip } from '../../../shared'
import { PaymentPolicyLocales } from '../../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../../PaymentPolicy.testIds'
import { Cutoff, RadioSection } from './RadioComponents'
import type { PaymentPolicyForm } from '../../PaymentPolicy.zod'
import type { PaymentPolicyProps } from '../PaymentPolicyProps'

export function RefundRadios({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()

  const refundLabel = (
    <HStack spacing="xs">
      <Text fontWeight="bold">{formatMessage(PaymentPolicyLocales.automaticRefund)}</Text>
      <AccessRuleTooltip data-test={PaymentPolicyTestId.refundTooltip} title={formatMessage(PaymentPolicyLocales.refundTooltipHeading)}>
        <Text color="lightFont">{formatMessage(PaymentPolicyLocales.refundTooltipContent)}</Text>
      </AccessRuleTooltip>
    </HStack>
  )

  const choices: RadioChoice<PaymentPolicyForm['refund']>[] = useMemo(
    () => [
      {
        value: 'NO_REFUND',
        label: formatMessage(PaymentPolicyLocales.refundChoiceNone),
      },
      {
        value: 'FULL_REFUND',
        label: formatMessage(PaymentPolicyLocales.refundChoiceFull),
        description: (
          <DisplayIfSelected field={field.prop('refund')} option="FULL_REFUND">
            <RefundCutoff field={field} />
          </DisplayIfSelected>
        ),
      },
      {
        value: 'PARTIAL_REFUND',
        label: formatMessage(PaymentPolicyLocales.refundChoicePartial),
        description: (
          <DisplayIfSelected field={field.prop('refund')} option="PARTIAL_REFUND">
            <PartialRefund field={field} />
          </DisplayIfSelected>
        ),
      },
    ],
    [formatMessage, field]
  )
  return (
    <RadioSection data-test={PaymentPolicyTestId.refundRadios} choices={choices} field={field.prop('refund')} primaryLabel={refundLabel} />
  )
}

function RefundCutoff({ field }: PaymentPolicyProps) {
  const { formatMessage } = useLocales()
  return (
    <Cutoff
      field={field}
      label={formatMessage(PaymentPolicyLocales.setRefundCutoff)}
      description={formatMessage(PaymentPolicyLocales.setRefundCutoffDescription)}
    />
  )
}

function PartialRefund({ field }: PaymentPolicyProps) {
  return (
    <VStack spacing="s">
      <StandardWidthBox>
        <FormPercentageInput data-test={PaymentPolicyTestId.partialRefund} field={field.prop('autoCharge').prop('amount')} />
      </StandardWidthBox>
      <RefundCutoff field={field} />
    </VStack>
  )
}
