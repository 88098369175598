import type { VenueClassEnum, VenueSettingsOptions } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Label, TextArea } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { SectionBox, ToggleToggler } from '../components'
import { backwriterSettingsMessages } from './Backwriter.locales'
import type { FormValues } from './Backwriter.zod'

export interface BackwriterProps {
  field: Field<FormValues>
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
}

export function Backwriter({ field }: BackwriterProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useAppContext()

  const enabled = useWatch(field.prop('enabled'))
  const smsEnabled = false

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox
        title={formatMessage(backwriterSettingsMessages.header, {
          icon: <Icon name="VMSWeb-action-stars" size="sm" color="attentionNew" />,
        })}
        description={formatMessage(backwriterSettingsMessages.enabledDescription)}
      >
        <ToggleToggler
          title={formatMessage(backwriterSettingsMessages.enabledTitle)}
          field={field.prop('enabled')}
          data-test="backwriterSettingEnabled"
        />
      </SectionBox>
      {enabled && (
        <>
          <SectionBox halfsize title={formatMessage(backwriterSettingsMessages.emailResponseHeader)}>
            <Box mt="s">
              <Label primary={formatMessage(backwriterSettingsMessages.emailGeneralTuning)}>
                <TextArea field={field.prop('promptSettings.emailGeneralTuning')} rows={4} resize="none" />
              </Label>
            </Box>
            <Box mt="s">
              <Label primary={formatMessage(backwriterSettingsMessages.emailSignature)}>
                <TextArea field={field.prop('promptSettings.emailSignature')} rows={4} resize="none" />
              </Label>
            </Box>
          </SectionBox>
          {smsEnabled && (
            <SectionBox halfsize title={formatMessage(backwriterSettingsMessages.smsHeader)}>
              <Box mt="s">
                <Label primary={formatMessage(backwriterSettingsMessages.smsGeneralTuning)}>
                  <TextArea field={field.prop('promptSettings.smsGeneralTuning')} rows={4} resize="none" />
                </Label>
              </Box>
            </SectionBox>
          )}
          <SectionBox halfsize title={formatMessage(backwriterSettingsMessages.reviewsHeader)}>
            <Box mt="s">
              <Label primary={formatMessage(backwriterSettingsMessages.reviewsGeneralTuning)}>
                <TextArea field={field.prop('promptSettings.reviewsGeneralTuning')} rows={4} resize="none" />
              </Label>
            </Box>
          </SectionBox>
          {venueSettings.ffAiClientNotesCleanupEnabled && (
            <SectionBox
              halfsize
              title={formatMessage(backwriterSettingsMessages.clientNotesHeader)}
              subCaption={formatMessage(backwriterSettingsMessages.clientNotesMobileOnly)}
            >
              <Box mt="s">
                <Label
                  primary={
                    <>
                      {formatMessage(backwriterSettingsMessages.clientNotesGeneralTuning)}
                      <br />
                      {formatMessage(backwriterSettingsMessages.clientNotesInstructions)}
                    </>
                  }
                >
                  <TextArea field={field.prop('promptSettings.clientNotesGeneralTuning')} rows={32} resize="none" />
                </Label>
              </Box>
            </SectionBox>
          )}
        </>
      )}
    </VStack>
  )
}
