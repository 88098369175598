import { useMemo } from 'react'
import type { SimphonyConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type SimphonyAuthenticationCardFormData = ZodSchema<typeof useSimphonyAuthenticationCardForm>
export type SimphonyAuthenticationCardFormField = Field<SimphonyAuthenticationCardFormData>

export const SimphonyAuthenticationCardFormSchema = z.object({
  orgShortName: z.string().min(1),
  openidUrl: z.string().url(),
  simphonyBaseUrl: z.string().url(),
  openidClientId: z.string().min(1),
})

export function useSimphonyAuthenticationCardForm() {
  return useMemo(() => SimphonyAuthenticationCardFormSchema, [])
}

export const getSimphonyAuthenticationCardFormDefaultValues = (config?: SimphonyConfig): SimphonyAuthenticationCardFormData => ({
  orgShortName: config?.orgShortName ?? '',
  openidUrl: config?.openidUrl ?? '',
  simphonyBaseUrl: config?.simphonyBaseUrl ?? '',
  openidClientId: config?.openidClientId ?? '',
})

export const convertSimphonyAuthenticationCardFormDataToSimphonyConfig = (
  data: SimphonyAuthenticationCardFormData
): Pick<SimphonyConfig, 'orgShortName' | 'openidUrl' | 'simphonyBaseUrl' | 'openidClientId'> => data
