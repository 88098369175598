import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { AutotagNotes } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutotagNotes'
import { AutotagPerksSelect } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutotagPerksSelect'
import { VmsIcons } from 'svr/common/VmsIcons'
import VmsCheckbox from 'svr/component-lib/Generic/Checkbox/VmsCheckbox'
import type { Perk } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { autotagSlideOutMessages } from '../../../locales'

export interface CustomAutotagTierAdditionalOptionsProps {
  autotagPerks: Perk[]
  changeFieldHandler: (value: unknown, field: string) => void
  perkIds: string[]
  additionalNotes: string
  tagName: string
}

const AdditionalOptionWrapper = styled.div`
  padding-top: 20px;
  display: flex;
`
const AdditionalOption = styled.div`
  padding-top: 8px;
  padding-left: 28px;
  display: flex;
`

export function CustomAutotagTierAdditionalOptions({
  autotagPerks,
  changeFieldHandler,
  perkIds,
  additionalNotes,
  tagName,
}: CustomAutotagTierAdditionalOptionsProps) {
  const { formatMessage } = useLocales()
  const [isPerksSelectEnabled, setIsPerksSelectEnabled] = useState(perkIds.length > 0)
  const [isNotesEnabled, setIsNotesEnabled] = useState(additionalNotes.length > 0 && additionalNotes[0] !== '')

  const additionalPerksChangeHandler = (value: string[]) => {
    changeFieldHandler({ [tagName]: value }, 'perksByTagName')
  }

  const additionalNotesChangeHandler = (value: string) => {
    changeFieldHandler({ [tagName]: [value] }, 'notesByTagName')
  }

  return (
    <>
      <AdditionalOptionWrapper>
        <VmsCheckbox
          checkboxId="autotagAdditionalOptionsPerkSelect"
          field={isPerksSelectEnabled}
          onClick={(value: boolean) => {
            additionalPerksChangeHandler([])
            setIsPerksSelectEnabled(!value)
          }}
          isSelected={isPerksSelectEnabled}
          label={formatMessage(autotagSlideOutMessages.addPerks)}
          selectedIcon={VmsIcons.CheckBoxSelected}
          unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
          checkedColor="#0ABCC2"
        />
      </AdditionalOptionWrapper>
      {isPerksSelectEnabled && (
        <AdditionalOption>
          <AutotagPerksSelect
            errorMessage=""
            selectedPerkIds={perkIds || []}
            autotagPerks={autotagPerks}
            onChange={additionalPerksChangeHandler}
            disabled={false}
            isCustomAutotag
          />
        </AdditionalOption>
      )}
      <AdditionalOptionWrapper>
        <VmsCheckbox
          checkboxId="autotagAdditionalOptionsNotes"
          field={isNotesEnabled}
          onClick={(value: boolean) => {
            additionalNotesChangeHandler('')
            setIsNotesEnabled(!value)
          }}
          isSelected={isNotesEnabled}
          label={formatMessage(autotagSlideOutMessages.addAdditionalNotes)}
          selectedIcon={VmsIcons.CheckBoxSelected}
          unselectedIcon={VmsIcons.CheckBoxOutlineBlank}
          tooltipIcon={VmsIcons.Info}
          tooltipText={formatMessage(autotagSlideOutMessages.additionalNotesLabelInfo)}
          checkedColor="#0ABCC2"
          tooltipColor="#6A6A6A"
        />
      </AdditionalOptionWrapper>
      {isNotesEnabled && (
        <AdditionalOption>
          <AutotagNotes
            errorMessage=""
            notes={additionalNotes.length > 0 ? additionalNotes[0] ?? '' : ''}
            onChange={additionalNotesChangeHandler}
            disabled={false}
            isCustomAutotag
          />
        </AdditionalOption>
      )}
    </>
  )
}
