import { useMemo } from 'react'
import { useForm, type UseForm, useFormContext, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingsBlock, SettingsBlockInputs } from '../SettingsBlock'
import { SettingValue } from '../SettingValue'
import { TextInput } from '../TextInput'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './Saferpay.locales'
import type { PaymentIntegration, PaymentIntegrationConfiguration, PaymentIntegrationSettings } from '../interfaces'

function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()

  return (
    <ViewSettingsBlock>
      <HStack spacing="m">
        <VStack spacing="m" flex={1}>
          <Text textStyle="body1Bold">{formatMessage(messages.account)}</Text>
          <SettingValue name={(currentConfiguration?.customerId as string) || '-'} title={formatMessage(messages.customerId)} />
          <SettingValue name={(currentConfiguration?.terminalId as string) || '-'} title={formatMessage(messages.terminalId)} />
          <SettingValue name={(currentConfiguration?.motoTerminalId as string) || '-'} title={formatMessage(messages.motoTerminalId)} />
        </VStack>
        <VStack spacing="m" flex={1}>
          <Text textStyle="body1Bold">{formatMessage(messages.authentication)}</Text>
          <SettingValue name={(currentConfiguration?.apiUser as string) || '-'} title={formatMessage(messages.apiUser)} />
          <SettingValue name={(currentConfiguration?.apiPass as string) || '-'} title={formatMessage(messages.apiPassword)} />
          <SettingValue name={(currentConfiguration?.token as string) || '-'} title={formatMessage(messages.token)} />
          <SettingValue name={(currentConfiguration?.urlFields as string) || '-'} title={formatMessage(messages.urlFields)} />
          <SettingValue name={(currentConfiguration?.motoToken as string) || '-'} title={formatMessage(messages.motoToken)} />
          <SettingValue name={(currentConfiguration?.motoUrlFields as string) || '-'} title={formatMessage(messages.motoUrlFields)} />
        </VStack>
      </HStack>
    </ViewSettingsBlock>
  )
}

export function useSaferpayForm(formDefault?: PaymentIntegrationSettings) {
  const schema = useMemoSchemaSaferpay() as unknown as z.ZodObject<{
    [key: string]: z.ZodDefault<z.ZodOptional<z.ZodString>> | z.ZodString | z.ZodBoolean
  }>
  return useForm(schema, { defaultValues: formDefault })
}

function SettingsForm() {
  const { formatMessage } = useLocales()
  const { field } = useFormContext() as UseForm<z.ZodType<SchemaSaferpay>>

  return (
    <>
      <SettingsBlock header={formatMessage(messages.account)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('customerId')}
            label={formatMessage(messages.customerId)}
            placeholder={formatMessage(messages.customerId)}
            data-test="payment-settings-saferpay-customer-id-input"
          />
          <TextInput
            field={field.prop('terminalId')}
            label={formatMessage(messages.terminalId)}
            placeholder={formatMessage(messages.terminalId)}
            data-test="payment-settings-saferpay-terminal-id-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('motoTerminalId')}
            label={formatMessage(messages.motoTerminalId)}
            placeholder={formatMessage(messages.motoTerminalId)}
            data-test="payment-settings-saferpay-moto-terminal-id-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
      <SettingsBlock header={formatMessage(messages.authentication)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('apiUser')}
            label={formatMessage(messages.apiUser)}
            placeholder={formatMessage(messages.apiUser)}
            data-test="payment-settings-saferpay-api-user-input"
          />
          <TextInput
            field={field.prop('apiPass')}
            label={formatMessage(messages.apiPassword)}
            placeholder={formatMessage(messages.apiPassword)}
            data-test="payment-settings-saferpay-api-pass-input"
          />
          <TextInput
            field={field.prop('token')}
            label={formatMessage(messages.token)}
            placeholder={formatMessage(messages.token)}
            data-test="payment-settings-saferpay-token-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('urlFields')}
            label={formatMessage(messages.urlFields)}
            placeholder={formatMessage(messages.urlFields)}
            data-test="payment-settings-saferpay-url-fields-input"
          />
          <TextInput
            field={field.prop('motoToken')}
            label={formatMessage(messages.motoToken)}
            placeholder={formatMessage(messages.motoToken)}
            data-test="payment-settings-saferpay-moto-token-input"
          />
          <TextInput
            field={field.prop('motoUrlFields')}
            label={formatMessage(messages.motoUrlFields)}
            placeholder={formatMessage(messages.motoUrlFields)}
            data-test="payment-settings-saferpay-moto-url-fields-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
    </>
  )
}

function createSchemaSaferpay(requiredErrorMessage: string) {
  return z.object({
    customerId: z.string().trim().min(1, requiredErrorMessage).default(''),
    terminalId: z.string().trim().min(1, requiredErrorMessage).default(''),
    motoTerminalId: z.string().trim().min(1, requiredErrorMessage).default(''),
    apiUser: z.string().trim().min(1, requiredErrorMessage).default(''),
    apiPass: z.string().trim().min(1, requiredErrorMessage).default(''),
    token: z.string().trim().min(1, requiredErrorMessage).default(''),
    urlFields: z.string().trim().min(1, requiredErrorMessage).default(''),
    motoToken: z.string().trim().min(1, requiredErrorMessage).default(''),
    motoUrlFields: z.string().trim().min(1, requiredErrorMessage).default(''),
  })
}
type SchemaSaferpay = z.infer<ReturnType<typeof createSchemaSaferpay>>

function useMemoSchemaSaferpay() {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(commonMessages.required)

  return useMemo(() => createSchemaSaferpay(requiredErrorMessage), [requiredErrorMessage])
}

export const Saferpay: PaymentIntegration = {
  currentConfiguration: {},
  integrationName: AccountTypes.SAFERPAY,
  ShowCurrentSettings,
  SettingsForm,
  formDefault: {},
  useFormHook: useSaferpayForm,
}
