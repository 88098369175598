import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { googleReviewLocales } from './locales'

interface SendStatusProps {
  deletingReply: boolean
  deleteSuccess: boolean
  errorReason?: string
  sendingReply: boolean
  sendFail: boolean
  sendSuccess: boolean
}

export function SendStatus({ deletingReply, deleteSuccess, errorReason, sendingReply, sendFail, sendSuccess }: SendStatusProps) {
  const { venue } = useVenueContext()
  const nav = useNavigation()
  const { formatMessage } = useLocales()

  if (sendingReply) {
    return (
      <>
        <Icon name="VMSWeb-refresh" animation="spin" />
        {formatMessage(googleReviewLocales.sendStatusLoading)}
      </>
    )
  } else if (deletingReply) {
    return (
      <>
        <Icon name="VMSWeb-refresh" animation="spin" />
        {formatMessage(googleReviewLocales.sendStatusDeletingReply)}
      </>
    )
  } else if (errorReason) {
    return (
      <>
        <Icon name="VMSWeb-status-failed" color="error" />
        &nbsp;{errorReason}
        {errorReason.includes('access') && (
          <>
            &nbsp;
            <Anchor
              target=""
              href={nav.href(routes.manager2.settings.reviewChannels, {
                params: { venueKey: venue.urlKey },
              })}
            >
              {formatMessage(googleReviewLocales.checkAuth)}
            </Anchor>
          </>
        )}
      </>
    )
  } else if (sendFail) {
    return (
      <>
        <Icon name="VMSWeb-status-failed" color="error" />
        {formatMessage(googleReviewLocales.sendStatusFailure)}
      </>
    )
  } else if (deleteSuccess) {
    return (
      <>
        <Icon name="VMSWeb-checkbox-marked-circle" color="success" />
        {formatMessage(googleReviewLocales.sendStatusDeleteSuccess)}
      </>
    )
  } else if (sendSuccess) {
    return (
      <>
        <Icon name="VMSWeb-checkbox-marked-circle" color="success" />
        {formatMessage(googleReviewLocales.sendStatusReplySuccess)}
      </>
    )
  }
  return null
}
