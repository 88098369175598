import { useCallback, useMemo, useState, useRef } from 'react'
import {
  useCreateSMSCampaignMutation,
  useUpdateSMSCampaignsMutation,
  type OneTimeSMSCampaignConfig,
  type OneTimeSMSCampaignTemplate,
  type OneTimeSMSCampaignType,
} from '@sevenrooms/core/api'
import {
  type GenericTagGroup,
  MarketingCampaignOneTimeSendTypeEnum,
  MarketingCampaignTypeEnum,
  type ExperiencesData,
  type VenueProfile,
  OneTimeSMSCampaignStatusEnum,
} from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { useBeforeUnload } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import {
  AudienceV2,
  smsBuilderMessages,
  TIMES_CHOICES,
  extractAutoTagIds,
  packOneTimeSendConfig,
  campaignBuilderMessages,
  type AudienceV2FormField,
} from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { OneTimeSendingSchedule, type OneTimeSendingScheduleFormField } from '@sevenrooms/mgr-marketing-one-time-email-center'
import { CancelSMSEditModal } from '@sevenrooms/mgr-marketing-one-time-sms-center/CancelSMSEditModal'
import { SMSContentFromScratch } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaign/SMSContentFromScratch/SMSContentFromScratch'
import { SMSDetails } from '@sevenrooms/mgr-marketing-one-time-sms-center/SMSDetails'
import { routes } from '@sevenrooms/routes'
import { oneTimeSMSCenterMessages } from '../locales'
import { ConfirmSendSMSModal } from './ConfirmSendSMSModal'
import {
  getOneTimeSMSBuilderFormDefaultValues,
  useOneTimeSMSCampaignForm,
  type OneTimeSMSCampaignFormData,
  htmlToSMS,
} from './OneTimeSMSCampaignForm.zod'
import { SendTestSMSModal } from './SendTestSMSModal'

export interface OneTimeSMSCampaignFormProps {
  smsCampaign?: OneTimeSMSCampaignType
  experiencesData: ExperiencesData
  venueProfile: VenueProfile
  autoTags: GenericTagGroup[]
  isDuplicate: Boolean
  isEdit: Boolean
  parentTemplate?: OneTimeSMSCampaignTemplate
}

const SMS_AVAILABILITY_WINDOW_START = new Date(0, 0, 0, 8, 0, 0)
const SMS_AVAILABILITY_WINDOW_END = new Date(0, 0, 0, 21, 0, 0)

export function OneTimeSMSCampaignForm({
  smsCampaign,
  experiencesData,
  venueProfile,
  autoTags,
  isDuplicate,
  isEdit,
  parentTemplate,
}: OneTimeSMSCampaignFormProps) {
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const { formatMessage, formatList } = useLocales()
  const nav = useNavigation()

  const { venue, venueId } = useVenueContext()
  const venueKey = venue?.urlKey || ''
  let cancelModalCloseHref = null
  if (isDuplicate && smsCampaign) {
    cancelModalCloseHref =
      nav
        .href(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS, {
          params: { venueKey: venue.urlKey },
          query: { campaignToDuplicateId: smsCampaign.id, parentTemplateId: '' },
        })
        .split('&')[0] || ''
  } else {
    cancelModalCloseHref = smsCampaign?.id
      ? nav.href(routes.manager2.marketing.oneTimeSMSCenter.editOneTimeSMS, {
          params: { venueKey: venue.urlKey, campaignId: smsCampaign.id },
          queryMode: 'clear',
        })
      : nav.href(routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS, {
          params: { venueKey: venue.urlKey },
          queryMode: 'clear',
        })
  }
  const goToOffers = useRef<boolean>(false)
  const setGoToOffers = () => {
    goToOffers.current = true
  }

  const getDuplicateCampaignName = useCallback(
    () => `${formatMessage(campaignBuilderMessages.copyOf)} ${smsCampaign?.campaignName}`,
    [smsCampaign?.campaignName, formatMessage]
  )

  const campaignName = useMemo(
    () => (isDuplicate ? getDuplicateCampaignName() : smsCampaign?.campaignName ?? ''),
    [isDuplicate, getDuplicateCampaignName, smsCampaign?.campaignName]
  )

  const defaultValues = useMemo(
    () => getOneTimeSMSBuilderFormDefaultValues(smsCampaign ? { ...smsCampaign, campaignName } : parentTemplate, autoTags),
    [smsCampaign, campaignName, autoTags, parentTemplate]
  )

  const form = useForm(useOneTimeSMSCampaignForm(), {
    defaultValues,
  })

  const {
    field,
    formState: { isDirty },
    setValue,
    getValues,
  } = form

  const [messageBody, messageMedia] = useWatchMany(field, ['messageBody', 'messageMedia'])
  const formattedMessageBody = useMemo(() => (messageBody ? htmlToSMS(messageBody) : messageBody), [messageBody])

  useBeforeUnload(isDirty && !isCancelled && !isSaving)

  const [createSMSCampaign, { isLoading: isCreating, isSuccess: isCreateSuccess }] = useCreateSMSCampaignMutation()
  const [updateSMSCampaignsQuery, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = useUpdateSMSCampaignsMutation()

  const isActionDisabled =
    isCreating || isCreateSuccess || isUpdating || isUpdateSuccess || (isEdit && smsCampaign?.status !== OneTimeSMSCampaignStatusEnum.DRAFT)

  const packOneTimeSMSBuilderFormData = useCallback(
    (formData: OneTimeSMSCampaignFormData): OneTimeSMSCampaignConfig => {
      const messageBody = formData.messageBody ? htmlToSMS(formData.messageBody) : formData.messageBody

      return {
        oneTimeSendTimeConfig: packOneTimeSendConfig(formData),
        campaignType: MarketingCampaignTypeEnum.ONE_TIME,
        isAnyClientTags: true,
        status: formData.campaignState,
        campaignName: formData.campaignName,
        messageBody,
        messageMedia: formData.messageMedia?.length ? formData.messageMedia : undefined,
        recipientClientTags: formData.useRecipientAutotags ? extractAutoTagIds(formData.recipientAutotags) : [],
        recipientClientTagsExclude: extractAutoTagIds(formData.excludedAutotags),
        parentTemplateId: parentTemplate?.id ?? smsCampaign?.parentTemplateId,
      }
    },
    [smsCampaign?.parentTemplateId, parentTemplate?.id]
  )

  async function trySaveSMSCampaign(formData: OneTimeSMSCampaignFormData) {
    const campaignData = packOneTimeSMSBuilderFormData(formData)
    if (formData.campaignState === OneTimeSMSCampaignStatusEnum.ACTIVE) {
      setIsSaving(true)
      nav.push(routes.manager2.marketing.oneTimeSMSCenter.confirmSendModal, {
        params: { venueKey },
      })
    } else {
      const createUpdatePromise =
        isEdit && smsCampaign
          ? updateSMSCampaignsQuery({ smsCampaignIds: [smsCampaign.id], smsCampaignUpdate: campaignData, venueId })
          : createSMSCampaign({ smsCampaign: campaignData, venueId })

      try {
        setIsSaving(true)
        await createUpdatePromise
        notify({
          content: formatMessage(campaignBuilderMessages.saveCampaignSuccessMessage),
          type: 'success',
        })
        window.location.assign(
          nav.href(routes.manager2.marketing.oneTimeSMSCenter, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params: { venueKey: venue.urlKey },
          })
        )
        // eslint-disable-next-line no-warning-comments
      } catch {
        notify({
          content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
          type: 'error',
        })
      }
    }
  }

  const getRecipientTagNames = useCallback(() => {
    const recipientAutoTags = form.getValues().recipientAutotags
    if (recipientAutoTags?.length) {
      return formatList(
        recipientAutoTags.map((autoTag?: TagOption) => getAutoTagName(autoTag, autoTags) || ''),
        { style: 'long', type: 'conjunction' }
      )
    }

    return formatMessage(oneTimeSMSCenterMessages.confirmSendSMSNoTagsRecipients)
  }, [autoTags, form, formatList, formatMessage])

  const onSendConfirm = useCallback(async () => {
    const campaignData = packOneTimeSMSBuilderFormData(getValues())
    const campaignId = isDuplicate ? undefined : smsCampaign?.id
    const recipientTagNames = getRecipientTagNames()
    const isScheduledCampaign = campaignData?.oneTimeSendTimeConfig.sendType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED
    const successMessage = isScheduledCampaign
      ? formatMessage(campaignBuilderMessages.scheduleSuccess, {
          campaign: campaignData?.campaignName,
          date: campaignData?.oneTimeSendTimeConfig.date || '',
          time: TIMES_CHOICES[TIMES_CHOICES.findIndex(o => o.id === campaignData?.oneTimeSendTimeConfig.timeOfDay)]?.label || '',
        })
      : formatMessage(campaignBuilderMessages.sendSuccess, { campaign: campaignData?.campaignName, recipientTagNames })

    const createUpdatePromise = campaignId
      ? updateSMSCampaignsQuery({ smsCampaignIds: [campaignId], smsCampaignUpdate: campaignData, venueId: venueId || '' })
      : createSMSCampaign({ smsCampaign: campaignData, venueId })

    nav.closeSurface(
      nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.confirmSendModal, {
        params: { venueKey },
      })
    )

    try {
      await createUpdatePromise

      notify({
        content: successMessage,
        type: 'success',
      })
      window.location.assign(
        nav.href(routes.manager2.marketing.oneTimeSMSCenter, {
          params: { venueKey },
          queryMode: 'clear',
        })
      )
    } catch {
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
        type: 'error',
      })
    }
  }, [
    createSMSCampaign,
    smsCampaign?.id,
    formatMessage,
    getRecipientTagNames,
    getValues,
    isDuplicate,
    nav,
    packOneTimeSMSBuilderFormData,
    updateSMSCampaignsQuery,
    venueId,
    venueKey,
  ])

  if (!venue) {
    return null
  }

  let cancelEditRedirect: Parameters<typeof CancelSMSEditModal>[0]['redirectRoute'] = routes.manager2.marketing.oneTimeSMSCenter
  if (goToOffers.current) {
    cancelEditRedirect = routes.manager2.marketing.offers
  } else if (parentTemplate) {
    cancelEditRedirect = routes.manager2.marketing.oneTimeSMSCenter.smsCampaignTemplates
  }

  return (
    <>
      <SettingsPageMeta
        venue={venue.name}
        title={formatMessage(isEdit ? smsBuilderMessages.editSMSCampaign : smsBuilderMessages.createSMSCampaign)}
      />
      <Form {...form} onSubmit={data => trySaveSMSCampaign(data)} onInvalid={() => {}}>
        <SettingsPageContent
          title={formatMessage(isEdit ? smsBuilderMessages.editOneTimeSMS : smsBuilderMessages.createOneTimeSMS)}
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          actions={
            <HStack spacing="s">
              <Button
                variant="tertiary"
                data-test="sr-one-time-sms-builder-cancel-button"
                onClick={() => {
                  setIsCancelled(true)
                  if (isDirty) {
                    goToOffers.current = false
                    nav.push(routes.manager2.marketing.oneTimeSMSCenter.cancelSMSEditModal, {
                      params: { venueKey: venue.urlKey },
                    })
                  } else {
                    window.location.assign(
                      nav.href(routes.manager2.marketing.oneTimeSMSCenter, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
                    )
                  }
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>

              <Button
                data-test="sr-one-time-sms-builder-save-as-draft-button"
                variant="secondary"
                type="submit"
                onClick={() => {
                  setValue('campaignState', OneTimeSMSCampaignStatusEnum.DRAFT, { shouldDirty: false })
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(commonMessages.saveAsDraft)}
              </Button>
              <Button
                data-test="sr-one-time-sms-builder-save-button"
                variant="primary"
                type="submit"
                onClick={() => {
                  setValue('campaignState', OneTimeSMSCampaignStatusEnum.ACTIVE, { shouldDirty: false })
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(
                  form.watch().scheduleType === MarketingCampaignOneTimeSendTypeEnum.IMMEDIATELY
                    ? campaignBuilderMessages.send
                    : campaignBuilderMessages.schedule
                )}
              </Button>
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              <SMSDetails autoFocus fields={field} data-test="sr-one-time-sms-builder-details-section" />
              <AudienceV2
                campaignContent={smsCampaign}
                venueProfile={venueProfile}
                field={field as unknown as AudienceV2FormField}
                venue={venue}
                isOneTimeCampaign
                messages={smsBuilderMessages}
                data-test="sr-one-time-sms-builder-audience-section"
              />
              <OneTimeSendingSchedule
                field={field as unknown as OneTimeSendingScheduleFormField}
                startTime={SMS_AVAILABILITY_WINDOW_START}
                endTime={SMS_AVAILABILITY_WINDOW_END}
                isSMSBuilder
              />
              <SMSContentFromScratch
                fields={field}
                data-test="sr-one-time-sms-builder-content-section"
                venueProfile={venueProfile}
                experiencesData={experiencesData}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
                disabled={isActionDisabled}
                campaignId={smsCampaign?.id}
              />
            </VStack>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.confirmSendModal}>
        <Window>
          <ConfirmSendSMSModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.oneTimeSMSCenter.confirmSendModal, {
              params: { venueKey: venue.urlKey },
            })}
            onCancel={() => setIsSaving(false)}
            onConfirm={onSendConfirm}
            oneTimeSendType={getValues().scheduleType}
            disabled={isActionDisabled}
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.cancelSMSEditModal}>
        <Window>
          <CancelSMSEditModal
            onCancel={() => setIsCancelled(false)}
            onConfirm={() => setIsCancelled(true)}
            closeHref={cancelModalCloseHref}
            redirectRoute={cancelEditRedirect}
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.oneTimeSMSCenter.sendTestSMSModal}>
        <Window>
          <SendTestSMSModal
            venueId={venueId}
            messageBody={formattedMessageBody}
            messageMedia={messageMedia}
            closeHref={cancelModalCloseHref}
          />
        </Window>
      </Surface>
    </>
  )
}

const getAutoTagName = (autoTag?: TagOption, autoTags?: GenericTagGroup[]) => {
  const category = autoTags?.find(category => category.id === autoTag?.categoryId)

  return autoTag?.label && (category?.tagNameDisplays?.[autoTag?.label] || autoTag?.label)
}
