import { v4 } from 'uuid'
import { TheForkMenuCourseEnum } from '@sevenrooms/core/domain'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, FormInput, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, Box, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ExperiencesLocales } from '../../Experiences.locales'
import type { TheForkDetailsMenuFormData } from './TheForkDetailsFrom.zod'

interface TheForkDetailsMenuProps {
  field: Field<TheForkDetailsMenuFormData>
  removeMenu?: () => void
}

export function TheForkDetailsMenu({ field, removeMenu }: TheForkDetailsMenuProps) {
  const items = useWatch(field.prop('items'))
  const controller = useController(field)
  const removeMenuItem = (idx: number) => {
    const items = [...controller.field.value.items]
    items.splice(idx, 1)
    controller.field.onChange({
      ...controller.field.value,
      items,
    })
  }

  const { formatMessage } = useLocales()
  return (
    <Box>
      <HStack spacing="s">
        <Flex flexDirection="column" flex={10}>
          <Label primary={`${formatMessage(ExperiencesLocales.menuCourseLabel)}${!removeMenu ? '*' : ''}`} />
          <FormSelect
            field={field.prop('dish') as Field<string | null>}
            options={Object.entries(TheForkMenuCourseEnum).map(([_id, label]) => ({
              id: _id,
              label,
            }))}
            withEmpty
          />
        </Flex>
        {controller.field.value.dish === 'CUSTOM_NAME' && (
          <Flex flexDirection="column" flex={10}>
            <Label primary={formatMessage(ExperiencesLocales.customMenuCourseNameLabel)} />
            <FormInput field={field.prop('customName')} placeholder={formatMessage(ExperiencesLocales.customMenuCourseNamePlaceHolder)} />
          </Flex>
        )}
        {removeMenu && (
          <Flex justifyContent="space-between" flex={controller.field.value.dish === 'CUSTOM_NAME' ? 1 : 10}>
            <Button data-test="remove-menu-item-button" onClick={removeMenu} variant="tertiary-warning" size="s">
              <Box pt="lm">
                <Icon size="lg" name="VMSWeb-trash" color="primaryIcons" />
              </Box>
            </Button>
          </Flex>
        )}
        {!removeMenu && controller.field.value.dish !== 'CUSTOM_NAME' && <Flex flex={10} />}
      </HStack>
      <Box mt="sm">
        <BaseSection backgroundColor="secondaryBackground">
          <VStack p="m">
            <Text textStyle="body1"> {formatMessage(ExperiencesLocales.menuItemsLabel)}</Text>
            <VStack mt="s" spacing="sm">
              {items.map((item, idx) => (
                <HStack key={item.id}>
                  <FormInput field={field.prop(`items.${idx}.name`)} fullWidth />
                  <Button
                    data-test="delete-menu-item-button"
                    onClick={() => removeMenuItem(idx)}
                    variant="tertiary"
                    size="s"
                    disabled={idx === 0}
                  >
                    <Icon size="lg" name="VMSWeb-trash" color={idx === 0 ? 'info' : 'primaryIcons'} />
                  </Button>
                </HStack>
              ))}
            </VStack>
            <Box pt="sm">
              <Button
                data-test="add-menu-item-button"
                onClick={() =>
                  controller.field.onChange({
                    ...controller.field.value,
                    items: [...controller.field.value.items, { name: null, id: v4() }],
                  })
                }
                variant="tertiary"
                noPadding
              >
                {formatMessage(ExperiencesLocales.addMenuItem)}
              </Button>
            </Box>
          </VStack>
        </BaseSection>
      </Box>
    </Box>
  )
}
