import { useMemo } from 'react'
import {
  type PDRTimeSlots,
  ExperienceStatusEnum,
  type Experience,
  type ExperienceStatus,
  type PDRAmenitiesEnumOption,
  type PDRRoomTypeEnumOption,
  type PDRUnitOfMeasurementEnumOption,
  type HeroImage,
  type PDRDeadlineTimeFromEnumOption,
  type PDRDeadlineTimeUnitEnumOption,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { formSchema, useImageForm } from '@sevenrooms/core/ui-kit/form'
import { spacesMessages } from '../../spaces.locales'
import type { DepositCalcFromOption } from './selectOptions'

export const useSpaceForm = () => {
  const { formatMessage } = useLocales()
  const image = useImageForm()
  const minimumSpendMatrix = useMinimumSpendMatrixForm()
  return useMemo(
    () =>
      z
        .object({
          status: z.custom<ExperienceStatus>(),
          name: z.string().trim().min(1),
          description: z.string().trim().min(1),
          heroImage: image.nullable(),
          imageList: z.array(image.nullable()),
          pdrMaxSeatedCapacity: z.number().nullable(),
          pdrMinSeatedCapacity: z.number().nullable(),
          pdrMaxStandingCapacity: z.number().nullable(),
          pdrMinStandingCapacity: z.number().nullable(),
          pdrRoomType: z.custom<PDRRoomTypeEnumOption>().nullable(),
          pdrSquareFootage: z.number().nullable(),
          pdrUnitOfMeasurement: z.custom<PDRUnitOfMeasurementEnumOption>().nullable(),
          depositCalcFrom: z.custom<DepositCalcFromOption>().nullable(),
          pdrAmenityList: z.custom<PDRAmenitiesEnumOption[]>(),
          menu: formSchema,
          eventType: z.array(z.string()),
          pdrFees: z.array(z.string()),
          pdrAdminFee: z.number().nullable(),
          pdrTaxRateId: z.string().nullable(),
          pdrMinSpendMin: z.number().nullable(),
          pdrMinSpendMax: z.number().nullable(),
          pdrMinSpend: z.number().nullable(),
          pdrMinimumSpendMatrix: z.array(minimumSpendMatrix),
          pdrTripleSeatRoomId: z.string().nullable(),
          pdrDepositFee: z.number().nullable(),
          requirePreApproval: z.boolean().optional(),
          requireContract: z.boolean().optional(),
          unlinkedAccessRuleIDs: z.array(z.string()),
          linkedAccessRuleIDs: z.array(z.string()),
          pdrDeadlineNum: z.number().nullable(),
          pdrDeadlineTimeUnit: z.custom<PDRDeadlineTimeUnitEnumOption>().nullable(),
          pdrDeadlineTimeFrom: z.custom<PDRDeadlineTimeFromEnumOption>().nullable(),
          pdrCancellationPolicy: z.string().nullable(),
          pdrCustomCancellationPolicy: z.string().nullable(),
        })
        .refine(data => !data.requireContract || data.pdrCancellationPolicy, {
          message: formatMessage(spacesMessages.pdrCancellationPolicyRequired),
          path: ['pdrCancellationPolicy'],
        })
        .refine(data => data.pdrCancellationPolicy !== 'custom' || data.pdrCustomCancellationPolicy, {
          message: formatMessage(spacesMessages.pdrCustomCancellationPolicyRequired),
          path: ['pdrCustomCancellationPolicy'],
        }),
    [image, minimumSpendMatrix, formatMessage]
  )
}

export type SpaceFormType = ZodSchema<typeof useSpaceForm>

const useMinimumSpendMatrixForm = () =>
  useMemo(
    () =>
      z.object({
        startDay: z.string(),
        endDay: z.string(),
        startTime: z.custom<PDRTimeSlots>(),
        endTime: z.custom<PDRTimeSlots>(),
        minimumSpend: z.number(),
      }),
    []
  )

export function getDefaultValues(experience?: Experience) {
  if (experience) {
    return {
      status: experience.status,
      name: experience.name,
      description: experience.description,
      heroImage: experience.heroImage?.rawUrlKey ? getHeroImageForm(experience.heroImage) : null,
      imageList: experience.imageList?.map(getHeroImageForm) ?? [],
      pdrMaxSeatedCapacity: experience.pdrMaxSeatedCapacity,
      pdrMinSeatedCapacity: experience.pdrMinSeatedCapacity,
      pdrMaxStandingCapacity: experience.pdrStandingCapacity,
      pdrMinStandingCapacity: experience.pdrStandingMinCapacity ?? null,
      pdrRoomType: experience.pdrRoomType,
      pdrSquareFootage: experience.pdrSquareFootage,
      pdrUnitOfMeasurement: experience.pdrUnitOfMeasurement,
      pdrAmenityList: experience.pdrAmenityList,
      menu: experience.menuBlobId ? { rawUrl: experience.menuBlobId, name: experience.menuFilename } : { rawUrl: '', name: '' },
      eventType: experience.eventType,
      pdrFees: experience.pdrFees,
      pdrAdminFee: experience.pdrAdminFee,
      pdrTaxRateId: experience.pdrTaxRateId,
      pdrMinSpendMin: experience.pdrMinSpendMin,
      pdrMinSpendMax: experience.pdrMinSpendMax,
      pdrMinSpend: experience.pdrMinSpend,
      pdrMinimumSpendMatrix: experience.pdrMinimumSpendMatrix,
      pdrTripleSeatRoomId: experience.pdrTripleSeatRoomId,
      pdrDepositFee: experience.pdrDepositFee,
      requirePreApproval: experience.requirePreApproval ?? false,
      depositCalcFrom: experience.depositCalcFrom,
      requireContract: experience.requireContract ?? false,
      unlinkedAccessRuleIDs: [],
      linkedAccessRuleIDs: [],
      pdrDeadlineNum: experience.pdrDeadlineNum,
      pdrDeadlineTimeUnit: experience.pdrDeadlineTimeUnit,
      pdrDeadlineTimeFrom: experience.pdrDeadlineTimeFrom,
      pdrCancellationPolicy: experience.pdrCancellationPolicy,
      pdrCustomCancellationPolicy: experience.pdrCustomCancellationPolicy,
    } as SpaceFormType
  }
  return {
    status: ExperienceStatusEnum.DRAFT,
    name: '',
    description: '',
    heroImage: null,
    imageList: [],
    pdrMaxSeatedCapacity: undefined,
    pdrMinSeatedCapacity: null,
    pdrMaxStandingCapacity: null,
    pdrMinStandingCapacity: null,
    pdrRoomType: null,
    pdrSquareFootage: null,
    pdrUnitOfMeasurement: null,
    pdrAmenityList: [],
    menu: null,
    pdrFees: [],
    pdrAdminFee: null,
    pdrTaxRateId: null,
    pdrMinSpendMin: null,
    pdrMinSpendMax: null,
    pdrMinSpend: null,
    pdrMinimumSpendMatrix: [],
    pdrTripleSeatRoomId: null,
    pdrDepositFee: null,
    unlinkedAccessRuleIDs: [],
    linkedAccessRuleIDs: [],
    pdrDeadlineNum: null,
    pdrDeadlineTimeUnit: null,
    pdrDeadlineTimeFrom: null,
    eventType: [],
    pdrCancellationPolicy: null,
    pdrCustomCancellationPolicy: null,
  }
}

function getHeroImageForm(image: HeroImage) {
  return {
    name: ' ',
    rawUrl: `/.h/download/${image.rawUrlKey}`,
    crop: image.cropInfo,
  }
}
