import { useEffect, useMemo, useState } from 'react'
import { useLazyGetPOSITenderItemsQuery } from '@sevenrooms/core/api'
import {
  type SimphonyRevenueCenter,
  SimphonyTenderItemTypeEnum,
  type POSIType,
  type SimphonyConfig,
  type SimphonyTenderItem,
} from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormNumberInput, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { BaseAutomaticCheckCreationCard } from '../BaseAutomaticCheckCreationCard'
import { messages } from './SimphonyAutomaticCheckCreationCard.locales'
import type { SimphonyAutomaticCheckCreationCardFormField } from './SimphonyAutomaticCheckCreationCardForm.zod'

export interface SimphonyAutomaticCheckCreationCardProps {
  field: SimphonyAutomaticCheckCreationCardFormField
  config: SimphonyConfig
  revenueCenters: SimphonyRevenueCenter[]
}

export function SimphonyAutomaticCheckCreationCard({ field, config, revenueCenters }: SimphonyAutomaticCheckCreationCardProps) {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()

  const { watch, setValue } = useFormContext()
  const watchIsAutomaticCheckCreationEnabled = watch('automaticCheckCreationCard.isAutomaticCheckCreationEnabled')
  const watchCheckCreationRvc = watch('automaticCheckCreationCard.checkCreationRvc')
  const watchSelectedLocationId = watch('locationCard.locationId')

  const [selectedCheckCreationRvc, setSelectedCheckCreationRvc] = useState<string>(watchCheckCreationRvc)
  const [tenderItems, setTenderItems] = useState<SimphonyTenderItem[]>([])

  // Field disabled conditions
  const isCheckCreationRvcDisabled = useMemo(
    () => revenueCenters.length === 0 || !watchIsAutomaticCheckCreationEnabled,
    [revenueCenters.length, watchIsAutomaticCheckCreationEnabled]
  )
  const isOrderTypeRefIdAndTenderIdDisabled = useMemo(
    () => isCheckCreationRvcDisabled || !watchCheckCreationRvc,
    [isCheckCreationRvcDisabled, watchCheckCreationRvc]
  )
  const isEmployeeRefIdDisabled = !watchIsAutomaticCheckCreationEnabled

  // Lazy query to fetch tender items for a given revenue center
  const [
    fetchTenderItems,
    { data: rawTenderItems, isSuccess: isFetchTenderItemsSuccess, isFetching: isTenderItemsFetching, isError: isFetchTenderItemsError },
  ] = useLazyGetPOSITenderItemsQuery()

  useEffect(() => {
    // Set tender items in state upon successful retrieval
    if (isFetchTenderItemsSuccess && rawTenderItems && !isTenderItemsFetching) {
      setTenderItems(rawTenderItems as SimphonyTenderItem[])
    }
    // Show a toast notification and clear tender items in state if fetch fails
    if (isFetchTenderItemsError) {
      notify({
        type: 'error',
        content: formatMessage(messages.fetchTenderItemsError),
      })
      setTenderItems([])
    }
  }, [formatMessage, isFetchTenderItemsError, isFetchTenderItemsSuccess, isTenderItemsFetching, rawTenderItems, setValue])

  // If the config has a locationId and checkCreationRvc on page load, fetch tenderItems so that the dropdown is populated
  useEffect(() => {
    if (config) {
      if (config.locationId && config.checkCreationRvc) {
        fetchTenderItems({
          venueId,
          posiType: venue.posIntegrationType as POSIType,
          locationId: config.locationId,
          subLocationId: config.checkCreationRvc,
        })
      }
    }
  }, [config, fetchTenderItems, venue.posIntegrationType, venueId])

  // If the revenue center is changed, update state and fetch tender items for the revenue center
  useEffect(() => {
    if (watchCheckCreationRvc !== selectedCheckCreationRvc) {
      setValue('automaticCheckCreationCard.orderTypeRefId', '')
      setValue('automaticCheckCreationCard.tenderId', '')
      setSelectedCheckCreationRvc(watchCheckCreationRvc)
    }

    // Fetch tender items for the new check creation revenue center
    if (watchSelectedLocationId && watchCheckCreationRvc) {
      fetchTenderItems({
        venueId,
        posiType: venue.posIntegrationType as POSIType,
        locationId: watchSelectedLocationId,
        subLocationId: watchCheckCreationRvc,
      })
    }
  }, [
    config,
    fetchTenderItems,
    selectedCheckCreationRvc,
    setValue,
    venue.posIntegrationType,
    venueId,
    watchCheckCreationRvc,
    watchSelectedLocationId,
  ])

  // Build the revenue center and order type select options
  const { checkCreationRvcSelectOptions, orderTypeRefIdSelectOptions } = useMemo(() => {
    const checkCreationRvcSelectOptions: SelectOption[] = []
    const orderTypeRefIdSelectOptions: SelectOption[] = []

    revenueCenters.forEach(revenueCenter => {
      checkCreationRvcSelectOptions.push({ id: revenueCenter.id, label: revenueCenter.name })
      if (revenueCenter.id === watchCheckCreationRvc) {
        orderTypeRefIdSelectOptions.push(
          ...revenueCenter.orderTypes.map(orderType => ({
            id: orderType.orderTypeRef,
            label: `${orderType.name} (${orderType.orderTypeRef})`,
          }))
        )
      }
    })
    return { checkCreationRvcSelectOptions, orderTypeRefIdSelectOptions }
  }, [revenueCenters, watchCheckCreationRvc])

  // Build the tender id select options
  const tenderIdSelectOptions = useMemo(() => {
    const tenderIdSelectOptions: SelectOption[] = []
    if (tenderItems) {
      tenderItems
        .filter(tenderItem => tenderItem.type === SimphonyTenderItemTypeEnum.SERVICE_TOTAL)
        .forEach(tenderItem => {
          tenderIdSelectOptions.push({
            id: `${tenderItem.tenderId}`,
            label: `${tenderItem.name} (${tenderItem.tenderId})`,
          })
        })
    }
    return tenderIdSelectOptions
  }, [tenderItems])

  return (
    <BaseAutomaticCheckCreationCard
      docLink="https://help.sevenrooms.com/hc/en-us/articles/19062449880347-Oracle-MICROS-Simphony-Cloud-v19-2-or-newer-Configuring-Automatic-Check-Creation-and-Check-Tag-Display"
      isAutomaticCheckCreationEnabledField={field.prop('isAutomaticCheckCreationEnabled')}
    >
      <VStack width="100%" spacing="s" justifyContent="center" alignItems="start">
        <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
          <Label width="50%" primary={formatMessage(messages.checkCreationRvcLabel)}>
            <FormSelect
              field={field.prop('checkCreationRvc')}
              disabled={isCheckCreationRvcDisabled}
              options={checkCreationRvcSelectOptions}
              hideErrorMessage
            />
          </Label>
          <Label width="50%" primary={formatMessage(messages.orderTypeRefIdLabel)}>
            <FormSelect
              field={field.prop('orderTypeRefId')}
              disabled={isOrderTypeRefIdAndTenderIdDisabled}
              options={orderTypeRefIdSelectOptions}
              hideErrorMessage
            />
          </Label>
        </HStack>
        <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
          <Label width="50%" primary={formatMessage(messages.tenderIdLabel)}>
            <FormSelect
              field={field.prop('tenderId')}
              disabled={isOrderTypeRefIdAndTenderIdDisabled}
              options={tenderIdSelectOptions}
              hideErrorMessage
            />
          </Label>
          <Label width="50%" primary={formatMessage(messages.employeeRefIdLabel)}>
            <FormNumberInput field={field.prop('employeeRefId')} disabled={isEmployeeRefIdDisabled} fullWidth hideErrorMessage />
          </Label>
        </HStack>
      </VStack>
    </BaseAutomaticCheckCreationCard>
  )
}
