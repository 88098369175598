import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  authenticatedLabel: {
    id: 'posiSettings.IntegrationStatusCard.authenticationLabel',
    defaultMessage: 'AUTHENTICATED',
  },
  actionRequiredLabel: {
    id: 'posiSettings.IntegrationStatusCard.actionRequiredLabel',
    defaultMessage: 'ACTION REQUIRED',
  },
  lastUpdatedDate: {
    id: 'posiSettings.IntegrationStatusCard.lastUpdatedDate',
    defaultMessage: 'Last updated on {dateString}',
  },
  disconnect: {
    id: 'posiSettings.IntegrationStatusCard.disconnect',
    defaultMessage: 'Disconnect',
  },
  reauthenticate: {
    id: 'posiSettings.IntegrationStatusCard.reauthenticate',
    defaultMessage: 'Reauthenticate',
  },
} as const)
