import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { getAccessRuleDefaults } from '../../../AccessRule.zod'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { FormattedTimeBefore } from '../../shared/FormattedTimeBefore'
import { BookingWindowLocales } from '../BookingWindow.locales'
import type { BookingChannelsForm } from '../../BookingChannels/BookingChannels.zod'

export interface DefaultBookingWindowProps {
  bookingChannelsField: Field<BookingChannelsForm>
}

export function DefaultBookingWindow({ bookingChannelsField }: DefaultBookingWindowProps) {
  const { formatMessage } = useLocales()
  const context = useAccessRuleContext()
  const AccessRuleDefaults = getAccessRuleDefaults(context)

  return (
    <DefaultSettingsReportSection data-test="booking-channels-defaults">
      <Report>
        <ReportPart>
          <ReportLine name={formatMessage(BookingWindowLocales.startTimeLabel)}>
            <>
              {bookingChannelsField.size() > 1 ? (
                <Text fontStyle="italic">{formatMessage(BookingWindowLocales.setInBookingChannels)}</Text>
              ) : (
                <FormattedTimeBefore {...AccessRuleDefaults.bookingWindow.startTime} />
              )}
            </>
          </ReportLine>
          <ReportLine name={formatMessage(BookingWindowLocales.cutoffTimeLabel)}>
            <FormattedTimeBefore {...AccessRuleDefaults.bookingWindow.cutoffTime} />
          </ReportLine>
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
