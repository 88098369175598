import { useMemo } from 'react'
import { z } from '@sevenrooms/core/form'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'

export const useFiltersSchema = () => {
  const selectedTags = useMultiSelectTagOptionForm()
  return useMemo(
    () =>
      z.object({
        search: z.string().nullable(),
        selectedTags,
      }),
    [selectedTags]
  )
}
