import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Box, Image, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface SMSPreviewCardProps {
  mediaUrl?: string
  messageBody?: string
}

export function SMSPreviewCard({ mediaUrl, messageBody }: SMSPreviewCardProps) {
  return (
    <VStack alignItems="flex-start" p="s">
      {mediaUrl && <Image src={mediaUrl} alt="" centered borderRadius="m" width="70%" height="120px" />}
      {messageBody && (
        <Box
          backgroundColor="dividerLines"
          borderRadius="m"
          width="100%"
          p="m"
          mt={mediaUrl ? 's' : 'none'}
          onClick={evt => {
            evt.preventDefault()
            return false
          }}
        >
          <Text textStyle="body1" lineHeight="l">
            <HTMLContent content={messageBody} allowedTags={['a', 'br']} />
          </Text>
        </Box>
      )}
    </VStack>
  )
}
