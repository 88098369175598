import React from 'react'
import styled from 'styled-components'

const filterHeight = 46

const DataHeavyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.color.white};
`

const DataFilterContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: ${props => props.theme.gutter.double} ${props => props.theme.gutter.triple} ${props => props.theme.gutter.double}
    ${props => props.theme.gutter.double};
  border-top: 1px solid ${props => props.theme.color.greyMuted};
`

const DataSummaryContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  background-color: ${props => props.theme.color.white};
  padding: ${props => props.theme.gutter.double};
`

const DataSummaryInnerContainer = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${props => props.theme.color.greyMuted};
  box-shadow: 0 0 1px ${props => props.theme.color.grey};
  background-color: ${props => props.theme.color.white};
  border-radius: 4px;
  min-height: 434px;
`
const DataListContainer = styled.div`
  display: flex;
  flex: 1;
`

class DataHeavyLayout extends React.PureComponent {
  render() {
    const { DataFilter, DataSummary, DataList } = this.props
    return (
      <DataHeavyContainer>
        {DataFilter && <DataFilterContainer>{DataFilter}</DataFilterContainer>}
        {DataSummary && (
          <DataSummaryContainer>
            <DataSummaryInnerContainer>{DataSummary}</DataSummaryInnerContainer>
          </DataSummaryContainer>
        )}
        <DataListContainer>{DataList}</DataListContainer>
      </DataHeavyContainer>
    )
  }
}

export default DataHeavyLayout
