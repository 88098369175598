import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { messages } from './TableMappingCard.locales'
import { TableMappingWizardModal } from './TableMappingWizardModal'

export function TableMappingCard() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  const handleLaunchTableMappingWizard = () => {
    nav.push(routes.manager2.settings.pointOfSaleIntegration.edit.tableMappingWizardModal, { params: { venueKey: venue.urlKey } })
  }

  return (
    <>
      <VStack backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
        <HStack spacing="m" pt="m" pb="m" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
          <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
            <Text textStyle="h2">{formatMessage(messages.cardLabel)}</Text>
            <SecondaryText>{formatMessage(messages.cardSubheadingLabel)}</SecondaryText>
          </VStack>
          <Button data-test="settings-launch-table-mapping-wizard" variant="primary" onClick={handleLaunchTableMappingWizard}>
            {formatMessage(messages.launchWizardButtonLabel)}
          </Button>
        </HStack>
      </VStack>

      <Surface destination={routes.manager2.settings.pointOfSaleIntegration.edit.tableMappingWizardModal}>
        <Window>
          <TableMappingWizardModal
            closeHref={nav.closeSurfaceHref(routes.manager2.settings.pointOfSaleIntegration.edit.tableMappingWizardModal, {})}
          />
        </Window>
      </Surface>
    </>
  )
}
