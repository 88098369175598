import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  stripeSpeed: {
    id: 'paymentsSettings.RecommendedIntegration.stripeSpeed',
    defaultMessage: 'Quick',
  },
  stripeSpeedLabel: {
    id: 'paymentsSettings.RecommendedIntegration.stripeSpeedLabel',
    defaultMessage: 'Up and running in as little as 5 minutes',
  },
  stripeReliability: {
    id: 'paymentsSettings.RecommendedIntegration.stripeReliability',
    defaultMessage: 'Easy',
  },
  stripeReliabilityLabel: {
    id: 'paymentsSettings.RecommendedIntegration.stripeReliabilityLabel',
    defaultMessage: 'Seamless merchant account setup',
  },
  stripeSupport: {
    id: 'paymentsSettings.RecommendedIntegration.stripeSupport',
    defaultMessage: 'Simple',
  },
  stripeSupportLabel: {
    id: 'paymentsSettings.RecommendedIntegration.stripeSupportLabel',
    defaultMessage: 'Rate negotiations not required',
  },
} as const)
