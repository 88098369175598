import _ from 'lodash'
import type { PerkAvailabilityTimes } from '@sevenrooms/core/api'
import { DateTime, TimeInterval } from '@sevenrooms/core/timepiece'
import { START_TIME, END_TIME } from '../constants'

const WEEK_DAYS = [1, 2, 3, 4, 5, 6, 0]

export const calculateAvailabilityTimesPeriods = (
  perkAvailabilityTimes: PerkAvailabilityTimes
): {
  key: string | undefined
  value: string | null
}[] => {
  // Calculate string for week day and also format time for each week day
  const formattedAvailabilityTimes = WEEK_DAYS.map((_day, key) => {
    const value = perkAvailabilityTimes[key + 1]

    if (value) {
      const { startTime, endTime } = value
      return { key, value: TimeInterval.fromIso(startTime || START_TIME, endTime || END_TIME)?.formatSTime() || null }
    }

    return { key, value: null }
  })

  return generateTimePeriods(formattedAvailabilityTimes)
}

export const calculateLegacyAvailabilityTimesPeriods = (
  dowOffered: boolean[]
): {
  key: string | undefined
  value: string | null
}[] => {
  const value = TimeInterval.fromIso(START_TIME, END_TIME)?.formatSTime() || null

  const fullAvailabilityTimes = WEEK_DAYS.map((_day, key) => {
    if (dowOffered[key]) {
      return { key, value }
    }

    return { key, value: null }
  })

  return generateTimePeriods(fullAvailabilityTimes)
}

const generateTimePeriods = (
  availabilityTimes: Array<{
    key: number
    value: string | null
  } | null>
) => {
  const availabilityTimesArr = [...availabilityTimes]
  const result: Array<{ key: string | undefined; value: string | null }> = []

  // Calculate periods of week which have the same time period
  availabilityTimesArr.forEach((cur, index) => {
    if (cur && cur.value) {
      const weekDay = getWeekDay(cur.key)

      result[index] = {
        key: weekDay,
        value: cur.value,
      }

      for (let j = index + 1; j < _.size(availabilityTimesArr); j += 1) {
        const nextValue = availabilityTimesArr[j]?.value
        const nextWeekDay = getWeekDay(availabilityTimesArr[j]?.key || 0)

        // If time of the next week day is the same we combine them into one period
        if (nextValue && cur?.value === nextValue && nextWeekDay) {
          result[index] = {
            value: cur.value,
            key: `${weekDay} - ${nextWeekDay}`,
          }

          availabilityTimesArr[j] = null
        } else {
          break
        }
      }
    }
  })

  const anytime = { key: `${getWeekDay(0)} - ${getWeekDay(6)}`, value: TimeInterval.fromIso(START_TIME, END_TIME)?.formatSTime() }

  if (_.size(result) === 1 && _.isEqual(_.head(result), anytime)) {
    return []
  }

  return _.compact(result)
}

export const getWeekDay = (weekDay: number) => {
  const dayNumber = WEEK_DAYS[weekDay] || 0
  const date = DateTime.fromJsDateSafe(new Date())?.setDay(dayNumber)
  return date?.formatSWeek()
}
