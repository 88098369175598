export const SeatingAreasTestId = {
  section: 'seating-areas',
  conflictModal: 'conflict-modal',
  conflictTooltip: 'conflict-tooltip',
  seatingAreasMultiSelect: 'seating-areas-multi-select',
  seatingAreasErrors: 'seating-areas-errors',
  treatAsBlockedCheckbox: 'treat-as-blocked-checkbox',
  treatAsBlockedTooltip: 'treat-as-blocked-tooltip',
  theForkField: 'thefork-field',
  googleReserveField: 'google-reserve-field',
  report: 'seating-areas-report',
  helpTooltip: 'help-tooltip',
} as const
