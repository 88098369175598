import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  save: {
    id: 'ApiAudienceList.save',
    defaultMessage: 'Save',
  },
  goBack: {
    id: 'ApiAudienceList.goBack',
    defaultMessage: 'Go back',
  },
  titleAdd: {
    id: 'ApiAudienceList.titleAdd',
    defaultMessage: 'Add custom audience',
  },
  titleEdit: {
    id: 'ApiAudienceList.titleEdit',
    defaultMessage: 'Edit custom audience',
  },
  titleDescription: {
    id: 'ApiAudienceList.titleDescription',
    defaultMessage: 'Custom Audiences are used to share availability via API to other applications or websites',
  },
  createdSuccessfully: {
    id: 'ApiAudienceList.createdSuccessfully',
    defaultMessage: 'Custom audience created successfully',
  },
  updatedSuccessfully: {
    id: 'ApiAudienceList.updatedSuccessfully',
    defaultMessage: 'Custom audience updated successfully',
  },
  errorCreating: {
    id: 'ApiAudienceList.errorCreating',
    defaultMessage: 'Error creating custom audience',
  },
  errorUpdating: {
    id: 'ApiAudienceList.errorUpdating',
    defaultMessage: 'Error updating custom audience',
  },
} as const)
