import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { getGuestFacingContactFormDefaultValues, useGuestFacingContactForm } from '../GuestFacingContactForm'
import { getReviewSitesFormDefaultValues, useReviewSitesForm } from '../ReviewSitesForm'
import { getSocialMediaFormDefaultValues, useSocialMediaForm } from '../SocialMediaForm'
import { getVenueInformationFormDefaultValues, useVenueInformationForm } from '../VenueInformationForm'

export const useVenueProfileForm = () => {
  const venueInformationFormSchema = useVenueInformationForm()
  const guestFacingFormSchema = useGuestFacingContactForm()
  const reviewSitesFormSchema = useReviewSitesForm()
  const socialMediaFormSchema = useSocialMediaForm()

  return useMemo(
    () => venueInformationFormSchema.merge(guestFacingFormSchema).merge(reviewSitesFormSchema).merge(socialMediaFormSchema),
    [venueInformationFormSchema, guestFacingFormSchema, reviewSitesFormSchema, socialMediaFormSchema]
  )
}

export const getVenueProfileDefaultValues = (venueProfile: VenueProfile) => {
  const venueInformationDefaultValues = getVenueInformationFormDefaultValues(venueProfile)
  const guestFacingnDefaultValues = getGuestFacingContactFormDefaultValues(venueProfile)
  const reviewSitesDefaultValues = getReviewSitesFormDefaultValues(venueProfile)
  const socialMediaDefaultValues = getSocialMediaFormDefaultValues(venueProfile)

  return {
    ...venueInformationDefaultValues,
    ...guestFacingnDefaultValues,
    ...reviewSitesDefaultValues,
    ...socialMediaDefaultValues,
  }
}
