import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import {
  fetchAutotagsList,
  fetchAutotagsActivityReport,
  fetchCustomAutotagsObjects,
  fetchAutotag,
  saveAutotag,
  deleteAutotag,
  fetchTagCampaignsAndPerks,
} from 'mgr/lib/services/AutotagServices'
import GenericTagsServices from 'mgr/lib/services/GenericTagsServices'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { transformConfigStateForServer } from 'mgr/pages/single-venue/marketing/utils/custom-autotags'
import { convertFilters } from 'mgr/pages/single-venue/marketing/utils/data'
import { selectAutotagsListValues } from 'mgr/pages/single-venue/marketing/selectors/Autotags'

export const onChangeVenue = venue => (dispatch, getState) => {
  dispatch({
    type: CHANGE_VENUE,
    venue,
  })
}

export const tryLoadAutotagsList = venue => dispatch => {
  dispatch({ type: ActionTypes.LOAD_AUTOTAGS_LIST_START })

  return fetchAutotagsList(venue).then(response => {
    if (response.data) {
      dispatch({
        type: ActionTypes.LOAD_AUTOTAGS_LIST_SUCCESS,
        autotags: response.data.results,
        rebuildState: response.data.rebuild_state,
      })
    }
  })
}

const tryLoadAutotagsActivityReport = venue => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOAD_AUTOTAGS_ACTIVITY_REPORT_START })

  const filters = convertFilters(getState().autotags.filters, ['start_date', 'end_date'])

  return fetchAutotagsActivityReport(venue, filters).then(response => {
    dispatch({
      type: ActionTypes.LOAD_AUTOTAGS_ACTIVITY_REPORT_SUCCESS,
      data: response.data,
    })
  })
}

const tryLoadCustomAutotagsObjects = venue => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOAD_CUSTOM_AUTOTAGS_OBJECTS_START })
  return fetchCustomAutotagsObjects(venue).then(response => {
    dispatch({
      type: ActionTypes.LOAD_CUSTOM_AUTOTAGS_OBJECTS_SUCCESS,
      data: response.data,
    })
  })
}

// TODO error handling and messaging
export const tryLoadAutotagsData = venue => dispatch => {
  dispatch({
    type: ActionTypes.LOAD_AUTOTAGS_START,
  })

  const promises = [
    dispatch(tryLoadAutotagsList(venue)),
    dispatch(tryLoadAutotagsActivityReport(venue)),
    dispatch(tryLoadCustomAutotagsObjects(venue)),
  ]

  return Promise.all(promises).then(() => {
    dispatch({
      type: ActionTypes.LOAD_AUTOTAGS_SUCCESS,
    })
  })
}

export const tryLoadAutotag = (venue, tag) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.LOAD_AUTOTAG_START,
  })
  return fetchAutotag(venue, tag.autotag_config_id).then(response => {
    dispatch({
      type: ActionTypes.LOAD_AUTOTAG_SUCCESS,
    })
    dispatch(
      openAutotag(response.data.autotag_config, tag.tag_name_display, tag.tag_name, response.data.perks_by_tag, response.data.notes_by_tag)
    )
  })
}

export const openAutotag = (tag, tierName, tagName, perksByTagName, notesByTagName) => dispatch => {
  dispatch({
    type: ActionTypes.OPEN_AUTOTAG,
    tag,
    tierName,
    tagName,
    perksByTagName,
    notesByTagName,
  })
}

export const removeInitialTier = () => dispatch => {
  dispatch({
    type: ActionTypes.REMOVE_INITIAL_TIER,
  })
}

export const selectTier = tier => dispatch => {
  dispatch({
    type: ActionTypes.SELECT_TIER,
    tier,
  })
}

export const toggleTagActive = () => dispatch => {
  dispatch({ type: ActionTypes.TOGGLE_TAG_ACTIVE })
}

export const toggleTierActive = tierConfigIndex => dispatch => {
  dispatch({ type: ActionTypes.TOGGLE_TIER_ACTIVE, index: tierConfigIndex })
}

export const updateTier = (name, value, tier) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_TIER,
    tier,
    name,
    value,
  })
}

export const updateTierAttribute = (name, value, tier, castNumber) => dispatch => {
  let updateValue = value
  if (castNumber) {
    updateValue = Number(value) || ''
  }
  dispatch({
    type: ActionTypes.UPDATE_TIER_ATTRIBUTE,
    tier,
    name,
    value: updateValue,
  })
}

export const updateLockedTierAttributes = (name, value, castNumber) => dispatch => {
  let updateValue = value
  if (castNumber) {
    updateValue = Number(value) || ''
  }
  dispatch({
    type: ActionTypes.UPDATE_LOCKED_TIER_ATTRIBUTES,
    name,
    value: updateValue,
  })
}

export const updatePerksByTag = (tierNameDisplay, value) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_AUTOTAG_PERKS_BY_TAG,
    tierNameDisplay,
    value,
  })
}

export const updateNotesByTag = (tierNameDisplay, value) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_AUTOTAG_NOTES_BY_TAG,
    tierNameDisplay,
    value,
  })
}

export const toggleDeleteWarning = () => dispatch => {
  dispatch({
    type: ActionTypes.TOGGLE_DELETE_WARNING,
  })
}

export const removeTier = tier => dispatch => {
  dispatch({
    type: ActionTypes.REMOVE_TIER,
  })

  window.metric.track('AutoTagging.RemoveTier')
}

export const addTier = () => dispatch => {
  dispatch({
    type: ActionTypes.ADD_TIER,
  })

  window.metric.track('AutoTagging.AddTier')
}

export const setTierError = tier => dispatch => {
  dispatch({
    type: ActionTypes.SET_TIER_ERROR,
    tier,
  })
}

const saveAutotagSuccess = result => dispatch => {
  dispatch({
    type: ActionTypes.SAVE_AUTOTAG_SUCCESS,
    tag: result.data.autotag_config,
  })
}

const saveAutotagFail = () => dispatch => {
  dispatch({
    type: ActionTypes.SAVE_AUTOTAG_FAIL,
  })
}

const deleteAutotagSuccess = result => ({
  type: ActionTypes.DELETE_AUTOTAG_SUCCESS,
  tag: result.data.autotag_config,
})

const deleteAutotagFail = () => ({
  type: ActionTypes.DELETE_AUTOTAG_FAIL,
})

export const trySaveAutotag = (venue, tag, editingTier, perksByTagName, notesByTagName) => (dispatch, getState) => {
  const state = getState()
  if (state.autotags.editor.tierError !== null) {
    return null
  }
  dispatch({
    type: ActionTypes.SAVE_AUTOTAG_START,
  })

  window.metric.track('AutoTagging.AppliedTagChanges')

  if (tag.is_active) {
    _.each(tag.tier_configs, (tier_config, idx) => {
      if (!tier_config.is_active) {
        return
      }
      if (!_.isNumber(editingTier) || editingTier === idx) {
        tier_config.is_edited = true // eslint-disable-line no-param-reassign
      }
    })
  }
  return saveAutotag(venue, tag, perksByTagName, notesByTagName)
    .then(result => {
      dispatch(saveAutotagSuccess(result))
      dispatch(GlobalActions.showSuccessMessage('Changes saved'))
      dispatch(tryLoadAutotagsList(venue))
    })
    .catch(response => {
      dispatch(saveAutotagFail())
      dispatch(GlobalActions.showErrorMessage(String(response)))
    })
}

export const tryDeleteAutotag = (venue, tag) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.DELETE_AUTOTAG_START,
  })
  return deleteAutotag(venue, tag)
    .then(result => {
      dispatch(deleteAutotagSuccess(result))
      dispatch(GlobalActions.showSuccessMessage('Auto-tag Deleted'))
      dispatch(tryLoadAutotagsList(venue))
    })
    .catch(response => {
      dispatch(deleteAutotagFail())
      dispatch(GlobalActions.showErrorMessage('AutoTag could not be deleted, please try again or contact administrator.'))
    })
}

export const fetchAutotagCampaignsAndPerks = (venue, tagId, tagConfigId) => (dispatch, getState) =>
  fetchTagCampaignsAndPerks(venue, tagId, tagConfigId)
    .then(result => {
      dispatch(updateAutotagCampaigns(result, tagId))
    })
    .catch(response => {
      dispatch(
        GlobalActions.showErrorMessage('Associated campaigns for this tag could not be fetched, please try again or contact administrator.')
      )
    })

export const getAutoTagsList = venueId => dispatch => {
  GenericTagsServices.fetchVenueClientTagGroups(venueId).then(
    response =>
      dispatch({
        type: ActionTypes.AUTO_TAGS_FULL_LIST_SUCCESS,
        autoTags: response.tagGroups.filter(tagGroup => tagGroup.is_autotag),
        clientTags: response.tagGroups.filter(tagGroup => !tagGroup.is_autotag),
      }),
    () => dispatch(GlobalActions.showErrorMessage('An error occurred, please try again or contact support'))
  )
}

export const toggleCustomAutotagSlideout = () => ({
  type: ActionTypes.TOGGLE_CUSTOM_AUTOTAG_SLIDEOUT,
})

export const toggleDeactivateWarning = () => ({
  type: ActionTypes.TOGGLE_AUTOTAG_DEACTIVATE_WARNING,
})

export const trySaveCustomAutotag =
  (venue, customAutotagState, customTierID = null) =>
  (dispatch, getState) => {
    const state = getState()
    const autoTagList = _.get(state, ['autotags', 'data', 'autotagsList'])
    const clientTagList = _.get(state, ['autotags', 'data', 'clientTagsList'])

    const custom_config_tree_client = transformConfigStateForServer(customAutotagState.configState)
    const customAutotagConfigs = _.get(state, ['autotags', 'data', 'customAutotag', 'custom_autotag_configs'])
    let configType
    if (customAutotagState.scope === 'LOCAL') {
      configType = customAutotagState.isForMarketingSegmentation ? 'CUSTOM_LOCAL_MARKETING_SEGMENTATION' : 'CUSTOM_LOCAL'
    } else {
      configType = customAutotagState.isForMarketingSegmentation ? 'CUSTOM_GLOBAL_MARKETING_SEGMENTATION' : 'CUSTOM_GLOBAL'
    }

    const autotagConfig = _.find(customAutotagConfigs, config => config.config_type === configType)
    const tags = autoTagList.concat(clientTagList, autotagConfig.tier_configs)
    let successMessage
    if (customTierID) {
      successMessage = 'Changes saved'
      autotagConfig.tier_configs = _.map(autotagConfig.tier_configs, tierConfig =>
        tierConfig.custom_tier_id === customTierID
          ? {
              ...tierConfig,
              needs_saving: true,
              name_display: customAutotagState.tagName,
              is_active: customAutotagState.is_active,
              is_for_marketing_segmentation: customAutotagState.isForMarketingSegmentation,
              custom_config_tree_client,
            }
          : tierConfig
      )
    } else {
      successMessage =
        "You have successfully created your custom Auto-tag. We've started the " +
        'process of applying this tag, please wait as we search through your client database. ' +
        '(This can take some time)'
      autotagConfig.tier_configs.push({
        name_display: customAutotagState.tagName,
        custom_config_tree_client,
        tier_type: 'CUSTOM',
        is_active: true,
        is_for_marketing_segmentation: customAutotagState.isForMarketingSegmentation,
      })
    }

    const tagNames = tags
      .concat(clientTagList)
      .map(tag => tag.name_display?.toLowerCase())
      .filter(displayName => displayName)
    const hasDuplicateName = tagNames.includes(customAutotagState.tagName.toLowerCase())
    if (hasDuplicateName && !customTierID) {
      return dispatch(GlobalActions.showErrorMessage(`Another tag with the name ${customAutotagState.tagName} already exists`))
    }
    dispatch({
      type: ActionTypes.SAVE_AUTOTAG_START,
    })
    return saveAutotag(venue, autotagConfig, customAutotagState.perksByTagName, customAutotagState.notesByTagName)
      .then(result => {
        dispatch(saveAutotagSuccess(result))
        dispatch(GlobalActions.showSuccessMessage(successMessage))
        dispatch(tryLoadAutotagsList(venue))
      })
      .catch(response => {
        dispatch(saveAutotagFail())
        dispatch(GlobalActions.showErrorMessage(String(response)))
      })
  }

export const updateDeactivateFunction = deactivateFunction => ({
  type: ActionTypes.UPDATE_AUTOTAG_DEACTIVATE_FUNCTION,
  deactivateFunction,
})

export const updateAutotagCampaigns = (associatedCampaignsAndPerks, tagID) => ({
  type: ActionTypes.UPDATE_AUTO_TAG_CAMPAIGNS,
  associatedCampaignsAndPerks,
  currentTagID: tagID,
})

export const updateAutotagConfig = (name, value) => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_AUTOTAG_CONFIG,
    name,
    value,
  })
}
