import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  paymentIntegration: {
    id: 'paymentSettings.ViewPaymentIntegration.paymentIntegration',
    defaultMessage: 'Payment Processor',
  },
  readMore: {
    id: 'paymentSettings.readMore',
    defaultMessage: 'Accept payments directly through your Booking Widget, Events Widget and Paylinks. <a>Learn more.</a>',
  },
  integrationDetails: {
    id: 'paymentSettings.ViewPaymentIntegration.integrationDetails',
    defaultMessage: 'Processor details',
  },
  testIntegration: {
    id: 'paymentSettings.ViewPaymentIntegration.testIntegration',
    defaultMessage: 'Test integration',
  },
  success: {
    id: 'paymentSettings.ViewPaymentIntegration.success',
    defaultMessage: 'Integration test successful',
  },
  error: {
    id: 'paymentSettings.ViewPaymentIntegration.error',
    defaultMessage: 'Integration offline',
  },
  lastSynced: {
    id: 'paymentSettings.ViewPaymentIntegration.lastSynced',
    defaultMessage: 'Last synced',
  },
  integrationOffline: {
    id: 'paymentSettings.ViewPaymentIntegration.integrationOffline',
    defaultMessage: 'Integration offline',
  },
  integrationOfflineError: {
    id: 'paymentSettings.ViewPaymentIntegration.integrationOfflineError',
    defaultMessage: "Please visit your integration provider's <a>website to resolve</a>.",
  },
  offlineLabel: {
    id: 'paymentSettings.ViewPaymentIntegration.offlineLabel',
    defaultMessage: 'offline',
  },
  onlineLabel: {
    id: 'paymentSettings.ViewPaymentIntegration.onlineLabel',
    defaultMessage: 'online',
  },
} as const)
