import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  tabTitle: {
    id: 'posiSettings.HomePage.tabTitle',
    defaultMessage: 'Point of Sale',
  },
  title: {
    id: 'posiSettings.HomePage.title',
    defaultMessage: 'Point of Sale Integrations',
  },
  description: {
    id: 'posiSettings.HomePage.description',
    defaultMessage: 'Manage and configure your Point of Sale Integrations. <a>Read more</a>',
  },
} as const)
