import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  account: {
    id: 'paymentSettings.Adyen.account',
    defaultMessage: 'Adyen Account',
  },
  accountCode: {
    id: 'paymentSettings.Adyen.accountCode',
    defaultMessage: 'Account Code',
  },
  accountCodeLabel: {
    id: 'paymentSettings.Adyen.accountCodeLabel',
    defaultMessage: 'This can be found in your Adyen portal under Settings > Merchant Account.',
  },
  store: {
    id: 'paymentSettings.Adyen.store',
    defaultMessage: 'Store',
  },
  storeLabel: {
    id: 'paymentSettings.Adyen.storeLabel',
    defaultMessage: 'This can be found in the Customer Area on the merchant account under Point of Sale > Stores.',
  },
  authentication: {
    id: 'paymentSettings.Adyen.authentication',
    defaultMessage: 'Authentication',
  },
  apiKey: {
    id: 'paymentSettings.Adyen.apiKey',
    defaultMessage: 'API Key',
  },
  apiKeyLabel: {
    id: 'paymentSettings.Adyen.apiKeyLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > API credentials.',
  },
  clientKey: {
    id: 'paymentSettings.Adyen.clientKey',
    defaultMessage: 'Client Key',
  },
  clientKeyLabel: {
    id: 'paymentSettings.Adyen.clientKeyLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > API credentials.',
  },
  liveEndpointPrefix: {
    id: 'paymentSettings.Adyen.liveEndpointPrefix',
    defaultMessage: 'API URL Live Endpoint Prefix',
  },
  liveEndpointPrefixLabel: {
    id: 'paymentSettings.Adyen.liveEndpointPrefixLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > API URLs.',
  },
  webhooks: {
    id: 'paymentSettings.Adyen.webhooks',
    defaultMessage: 'Webhooks',
  },
  webhookUsername: {
    id: 'paymentSettings.Adyen.webhookUsername',
    defaultMessage: 'Username',
  },
  webhookUsernameLabel: {
    id: 'paymentSettings.Adyen.webhookUsernameLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > Webhooks.',
  },
  webhookPassword: {
    id: 'paymentSettings.Adyen.webhookPassword',
    defaultMessage: 'Password',
  },
  webhookPasswordLabel: {
    id: 'paymentSettings.Adyen.webhookPasswordLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > Webhooks.',
  },
  webhookHmac: {
    id: 'paymentSettings.Adyen.webhookHmac',
    defaultMessage: 'HMAC Key',
  },
  webhookHmacLabel: {
    id: 'paymentSettings.Adyen.webhookHmacLabel',
    defaultMessage: 'This can be generated in your Adyen portal under Developers > Webhooks.',
  },
  settings: {
    id: 'paymentSettings.Adyen.settings',
    defaultMessage: 'Settings',
  },
  settingsLabel: {
    id: 'paymentSettings.Adyen.settingsLabel',
    defaultMessage: 'Payment methods must also be configured in your Adyen account. ',
  },
  isBcmcEnabled: {
    id: 'paymentSettings.Adyen.isBcmcEnabled',
    defaultMessage: 'Enable Bancontact',
  },
  isGiropayEnabled: {
    id: 'paymentSettings.Adyen.isGiropayEnabled',
    defaultMessage: 'Enable Giropay',
  },
  isPaypalEnabled: {
    id: 'paymentSettings.Adyen.isPaypalEnabled',
    defaultMessage: 'Enable PayPal',
  },
} as const)
