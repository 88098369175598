import { useCallback, useState } from 'react'
import snakecaseKeys from 'snakecase-keys'
import { type CreateEditExperienceApi, FileService, useCreateExperienceMutation, useEditExperienceMutation } from '@sevenrooms/core/api'
import type { ImageObject, HeroImage, Experience } from '@sevenrooms/core/domain'
import type { Dirtied } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { spacesMessages } from '../../spaces.locales'
import type { SpaceFormType } from './Space.zod'

interface UseSubmitProps {
  spaceId?: string
  dirtyFields: Dirtied<SpaceFormType>
  experience?: Experience
}

export function useSubmit({ spaceId, dirtyFields, experience }: UseSubmitProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [isImageUploading, setImageUploading] = useState(false)
  const [createExperience, { isLoading: isCreateExperienceLoading }] = useCreateExperienceMutation()
  const [updateExperience, { isLoading: isEditExperienceLoading }] = useEditExperienceMutation()

  const isSubmitting = isEditExperienceLoading || isCreateExperienceLoading || isImageUploading

  const onSubmit = useCallback(
    async (spaceForm: SpaceFormType) => {
      try {
        if (spaceId) {
          setImageUploading(true)
          const updateExperienceData = await formToExperience({
            spaceForm,
            dirtyFields,
            experience,
            onError: () => {
              notify({
                content: formatMessage(spacesMessages.errorAPI),
                type: 'error',
              })
            },
          })
          setImageUploading(false)
          await updateExperience({
            experience: updateExperienceData,
            experienceId: spaceId,
            venueId,
          }).unwrap()
          notify({
            content: formatMessage(spacesMessages.spaceUpdated, { name: spaceForm.name }),
            type: 'success',
          })
          return undefined
        }
        setImageUploading(true)
        const createExperienceData = await formToExperience({
          spaceForm,
          dirtyFields,
          experience,
          onError: () => {
            notify({
              content: formatMessage(spacesMessages.errorAPI),
              type: 'error',
            })
          },
        })
        setImageUploading(false)
        const createdExperience = await createExperience({
          experience: createExperienceData,
          venueId,
        }).unwrap()
        notify({
          content: formatMessage(spacesMessages.spaceCreated, { name: spaceForm.name }),
          type: 'success',
        })
        return createdExperience
      } catch {
        notify({
          content: formatMessage(spacesMessages.errorAPI),
          type: 'error',
        })
        return undefined
      }
    },
    [spaceId, dirtyFields, experience, createExperience, venueId, formatMessage, updateExperience]
  )
  return { onSubmit, isSubmitting }
}

interface FormToExperienceProps {
  spaceForm: SpaceFormType
  dirtyFields: Dirtied<SpaceFormType>
  experience?: Experience
  onError: () => void
}

async function formToExperience({ spaceForm, dirtyFields, experience, onError }: FormToExperienceProps): Promise<CreateEditExperienceApi> {
  const [heroImage, menu, ...imageNames] = await Promise.all([
    uploadFile(onError, !!dirtyFields.heroImage, spaceForm.heroImage?.fileData),
    uploadFile(onError, !!dirtyFields.menu, spaceForm.menu?.fileData),
    ...spaceForm.imageList.map(async (image, i) => {
      if (!dirtyFields.imageList?.[i]) {
        return image?.rawUrl.replace('/.h/download/', '') ?? undefined
      }
      return uploadFile(onError, !!dirtyFields.imageList?.[i], image?.fileData)
    }),
  ])
  return {
    ...snakecaseKeys(spaceForm, { deep: true }),
    offer_type: 'PDR',
    template_id: 'default',
    visibility: 'PUBLIC',
    price_description: '',
    hide_on_directory: true,
    pdr_standing_capacity: spaceForm.pdrMaxStandingCapacity,
    pdr_standing_min_capacity: spaceForm.pdrMinStandingCapacity,
    menu_blob_id: dirtyFields.menu ? menu : undefined,
    menu_filename: dirtyFields.menu ? spaceForm.menu?.name : undefined,
    hero_image: getImage(heroImage, spaceForm.heroImage, experience?.heroImage) ?? {},
    image_list: imageNames.map((image, i) => getImage(image, spaceForm.imageList[i], experience?.imageList[i])).filter(image => image),
    unlinked_access_rule_ids: spaceForm.unlinkedAccessRuleIDs,
    linked_access_rule_ids: spaceForm.linkedAccessRuleIDs,
    unlinked_event_ids: [],
    linked_event_ids: [],
  }
}

async function uploadFile(onError: () => void, isDirty: boolean, fileData?: File) {
  try {
    if (isDirty && fileData) {
      const { urlKey: menuUrl } = await FileService.uploadFile({
        file: fileData,
        rurl: (Math.random() * 100 + 1).toString(),
      })
      return menuUrl
    }
  } catch {
    onError()
  }
  return undefined
}

function getImage(url?: string, formImage?: ImageObject | null, oldValue?: HeroImage) {
  if (url && formImage?.fileData) {
    return {
      crop_info: formImage.crop,
      name: formImage.fileData.name,
      raw_url_key: url,
    }
  }
  if (formImage?.crop) {
    return {
      raw_url_key: oldValue?.rawUrlKey.replace('/.h/download/', ''),
      crop_info: formImage.crop,
      photo_dict: snakecaseKeys(oldValue?.photoDict || {}),
    }
  }
  return null
}
