import { useEffect } from 'react'
import { type FeesData, FeeTypeEnum } from '@sevenrooms/core/domain'
import { type Field, useFieldArray, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Label, FormSelect, FormNumberInput, Button, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { DividerLine, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { FeesLocales } from '../Fees.locales'
import type { FeeAPISchema, FeeType } from '../schemas/Fee.zod'

export interface TaxItem {
  id: string
  label: string
}

export interface FeeListProps {
  feesData: FeesData | undefined
  fields: Field<ZodSchema<typeof FeeAPISchema>>
  serializedTaxes: TaxItem[] | undefined
  venueId: string
  watch: (name?: string | string[]) => void
}

export function FeeList({ feesData, fields, serializedTaxes, venueId, watch }: FeeListProps) {
  const { formatMessage } = useLocales()

  const { fields: fees, append, remove } = useFieldArray(fields, 'fees')

  const sortedFees: (typeof fees[0] & { originalIndex: number; isNew: boolean })[] = fees
    ?.map((fee, index) => ({
      ...fee,
      originalIndex: index,
      isNew: 'isNew' in fee,
    }))
    .sort((feeA, feeB) => {
      if (feeA.isNew && !feeB.isNew) {
        return 1
      }
      if (!feeA.isNew && feeB.isNew) {
        return -1
      }
      if (feeA.isNew && feeB.isNew) {
        return feeA.originalIndex - feeB.originalIndex
      }
      return (feeA.name ?? '').localeCompare(feeB.name ?? '')
    })

  useEffect(() => {
    feesData?.fees?.forEach((fee, index) => {
      append({ ...fee, originalIndex: index } as FeeType & { originalIndex: number }, { shouldFocus: false })
    })
  }, [append, feesData])

  const addNewItem = () => {
    const newItem = {
      id: null,
      name: 'New Fee',
      taxRateId: '',
      feeAmount: 0,
      feeType: FeeTypeEnum.PERCENTAGE,
      taxInfo: null,
      venueId,
      originalIndex: fees.length,
      isNew: true,
    }

    append(newItem, { shouldFocus: false })
  }

  watch('fees')

  return (
    <VStack>
      {sortedFees?.map(fee => (
        <div key={fee.id}>
          <HStack pr="xl" pl="xl">
            <Flex flexDirection="column" flex="2" mr="m">
              <Label primary={formatMessage(FeesLocales.feeTypeDescription)} />
              <FormSelect
                field={fields.prop(`fees.${fee.originalIndex}.feeType`) as Field<string>}
                searchable={false}
                options={Object.entries(FeeTypeEnum).map(([unitId, label]) => ({
                  id: unitId,
                  label,
                }))}
              />
            </Flex>
            <Flex flexDirection="column" flex="2" mr="m">
              <Label primary={formatMessage(FeesLocales.feeName)} />
              <FormInput field={fields.prop(`fees.${fee.originalIndex}.name`)} fullWidth />
            </Flex>

            <Flex flexDirection="column" flex="2" mr="m">
              <Label
                primary={
                  fee.feeType === FeeTypeEnum.FIXED_AMOUNT
                    ? formatMessage(FeesLocales.feeAmount)
                    : formatMessage(FeesLocales.feeAmountPercentage)
                }
              />
              <FormNumberInput field={fields.prop(`fees.${fee.originalIndex}.feeAmount`) as Field<number | null>} fullWidth />
            </Flex>

            <Flex flexDirection="column" flex="3">
              <Label primary={formatMessage(FeesLocales.feeTaxes)}>
                <FormSelect
                  field={fields.prop(`fees.${fee.originalIndex}.taxRateId`)}
                  options={serializedTaxes as SelectOption[]}
                  withEmpty
                />
              </Label>
            </Flex>
            <Flex flexDirection="column" flex="1" pt="xl" ml="xxl" pl="xxl" pr="xxs" mr="xxs">
              <Button data-test="remove-fee-button" onClick={() => remove(fee.originalIndex)} size="s" variant="tertiary">
                <Icon size="lg" name="VMSWeb-trash" />
              </Button>
            </Flex>
          </HStack>
          <DividerLine mt="s" mb="m" />
        </div>
      ))}
      <Flex flexDirection="column" flex="12" mt="xl" mb="xl">
        <Button data-test="add-fee-button" onClick={addNewItem} variant="tertiary">
          {formatMessage(FeesLocales.feeAddNewItem)}
        </Button>
      </Flex>
    </VStack>
  )
}
