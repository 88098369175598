import { useMemo } from 'react'
import type { POSIConfig, POSILocation } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type LocationSelectionCardFormData = ZodSchema<typeof useLocationSelectionCardForm>
export type LocationSelectionCardFormField = Field<LocationSelectionCardFormData>

export const LocationSelectionCardFormSchema = z.object({
  locationId: z.string().min(1, 'Required'),
})

export function useLocationSelectionCardForm() {
  return useMemo(() => LocationSelectionCardFormSchema, [])
}

export const getLocationSelectionCardFormDefaultValues = (locationId?: string): LocationSelectionCardFormData => ({
  locationId: locationId ?? '',
})

export const convertLocationSelectionCardFormDataToPOSIConfig = ({
  locations,
  data,
}: {
  locations: POSILocation[]
  data: LocationSelectionCardFormData
}): Pick<POSIConfig, 'location'> => {
  const locationsById: { [key: string]: POSILocation } = Object.fromEntries(locations.map(location => [location.id, location]))
  const location = locationsById[data.locationId]
  return { location }
}
