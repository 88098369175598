import { useEffect, useState } from 'react'
import { useLazyGetSMSCampaignSegmentCountQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, Flex, notify, type BoxSizes } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { smsBuilderMessages } from '@sevenrooms/marketing'
import { htmlToSMS } from '../OneTimeSMSCampaignForm.zod'

export interface SMSSegmentCounterProps {
  venueId: string
  campaignId?: string
  messageBody: string
  mt?: BoxSizes
}

export function SMSSegmentCounter({ messageBody, mt, ...props }: SMSSegmentCounterProps) {
  const { formatMessage } = useLocales()
  const [currentCount, setCurrentCount] = useState<number | null>(null)

  const [getSegmentCount, { isLoading }] = useLazyGetSMSCampaignSegmentCountQuery()
  const areSegmentsEstimated = typeof currentCount === 'number'

  useEffect(() => {
    setCurrentCount(null)
  }, [messageBody])

  return (
    <HStack alignItems="center" width="100%" mt={mt}>
      <Button
        variant="tertiary"
        disabled={!messageBody || isLoading || areSegmentsEstimated}
        onClick={() => {
          getSegmentCount({ messageBody: htmlToSMS(messageBody), ...props })
            .then(data => {
              if (!data.data) {
                throw new Error()
              } else {
                setCurrentCount(data.data.segmentCount)
              }
            })
            .catch(_err => {
              notify({
                content: formatMessage(smsBuilderMessages.estimateSegmentsError),
                type: 'error',
              })
            })
        }}
        noPadding
        data-test="button-segments"
      >
        {isLoading ? formatMessage(smsBuilderMessages.estimateSegmentsInProgress) : formatMessage(smsBuilderMessages.estimateSegments)}
      </Button>
      {areSegmentsEstimated && (
        <Flex justifyContent="flex-end" flex="1">
          <Text>{formatMessage(smsBuilderMessages.estimatedSegments, { count: currentCount })}</Text>
        </Flex>
      )}
    </HStack>
  )
}
