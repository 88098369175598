import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  locationRequiredBannerTitle: {
    id: 'posiSettings.SquareAutomaticCheckCreationCard.locationRequiredBannerTitle',
    defaultMessage: 'Location Required',
  },
  locationRequiredBannerDescription: {
    id: 'posiSettings.SquareAutomaticCheckCreationCard.locationRequiredBannerDescription',
    defaultMessage: 'To enable automatic check creation, please select a location and save your selection.',
  },
} as const)
