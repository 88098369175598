import { useMemo } from 'react'
import { type CreateEditExperienceApi, FileService } from '@sevenrooms/core/api'
import {
  ExperienceVisibilityEnum,
  type ImageObject,
  DaysOfTheWeekEnum,
  ShiftCategoryEnum,
  type Policy,
  PaymentRuleTypeEnum,
  ExperienceLinkTypeEnum,
  type SeatingAreaToTables,
  type Shift,
  ServiceChargeEnum,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import type { TagGroup, Upsells } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.types'
import { PartySizeLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PartySize/PartySize.locales'
import { usePartySizeForm } from '@sevenrooms/mgr-access-rules-slideout/components/PartySize/PartySize.zod'
import { PaymentPolicyLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.locales'
import {
  getInitialPaymentPolicy,
  getPaymentPolicyDefaults,
  usePaymentPolicyForm,
} from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.zod'
import { getInitialSchedule, useScheduleForm } from '@sevenrooms/mgr-access-rules-slideout/components/Schedule/Schedule.zod'
import {
  getSeatingAreasByDefaultAreasList,
  useSeatingAreasForm,
} from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/SeatingAreas.zod'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import type { OfferTemplateProps } from '../MarketingTemplatesGallery'

export const useCreateOfferFromTemplateForm = () => {
  const image = useImageForm()
  const schedule = useScheduleForm()
  const paymentPolicy = usePaymentPolicyForm()
  const seatingAreas = useSeatingAreasForm()
  const partySize = usePartySizeForm()

  const { formatMessage } = useLocales()
  const fieldLengthError = formatMessage(messages.fieldLengthError, { minSize: 1 })

  return useMemo(
    () =>
      z.object({
        templateId: z.string(),
        name: z.string().trim().min(1, fieldLengthError),
        description: z.string().trim().min(1, fieldLengthError),
        previewDescription: z.string().trim(),
        price: z.string().trim(),
        partySize: partySize.superRefine(({ min, max }, ctx) => {
          if (min && max && max < min) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PartySizeLocales.maxGreaterThanMin),
              path: ['max'],
            })
          }
        }),
        heroImage: image,
        linkedAccessRuleIds: z.array(z.string()),
        schedule: schedule.superRefine(({ selectedDays }, ctx) => {
          if (selectedDays.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(messages.daysOfTheWeekRequired),
              path: ['selectedDays'],
            })
          }
        }),
        paymentPolicy: paymentPolicy.superRefine(({ paymentRule, partySizeType, partySizeMin, charges }, ctx) => {
          if (paymentRule === PaymentRuleTypeEnum.save_for_later) {
            if (partySizeType === 'gt' && partySizeMin == null) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.partySizeRequired),
                path: ['partySizeMin'],
              })
            }
          }
          if (
            charges.applyServiceCharge &&
            charges.serviceChargeType === ServiceChargeEnum.SPECIFIC_SERVICE_CHARGE &&
            !charges.serviceChargePercent
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.serviceChargeRequired),
              path: ['charges', 'serviceChargePercent'],
            })
          }
          if (charges.applyGratuity && charges.gratuityType === 'SPECIFIC_GRATUITY' && !charges.gratuityPercent) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.gratuityRequired),
              path: ['charges', 'gratuityPercent'],
            })
          }
          if (charges.applyTax && !charges.taxId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.taxRequired),
              path: ['charges', 'taxId'],
            })
          }
        }),
        allowChannelsWithoutCCHolds: z.boolean(),
        accessType: z.number(),
        seatingAreas,
      }),
    [fieldLengthError, formatMessage, image, partySize, paymentPolicy, schedule, seatingAreas]
  )
}
export type CreateOfferFromTemplateFormData = ZodSchema<typeof useCreateOfferFromTemplateForm>

export async function formToExperience({
  formData,
  onError,
}: {
  formData: CreateOfferFromTemplateFormData
  onError: () => void
}): Promise<CreateEditExperienceApi> {
  const heroImageUrlKey = async () => {
    try {
      const fileData = formData.heroImage?.fileData
      if (fileData) {
        const { urlKey: heroImageUrlKey } = await FileService.uploadFile({
          file: fileData,
          rurl: Math.random().toString(),
        })
        return heroImageUrlKey
      }
      return undefined
    } catch {
      onError()
      return undefined
    }
  }

  return {
    name: formData.name,
    description: formData.description || '',
    price_description: formData?.price || '',
    template_id: 'default',
    title: formData.name,
    default_party_size: formData.partySize?.min || DefaultPartySize,
    hero_image: formData.heroImage ? getImage(await heroImageUrlKey(), formData.heroImage) : {},
    image_list: [],
    visibility: ExperienceVisibilityEnum.PUBLIC,
    unlinked_access_rule_ids: [],
    linked_access_rule_ids: formData.linkedAccessRuleIds,
    unlinked_event_ids: [],
    linked_event_ids: [],
    offer_type: 'EXPERIENCE',
    status: 'ACTIVE',
    hide_on_directory: false,
  }
}

function getImage(url: string | undefined, formImage: ImageObject | null) {
  if (formImage) {
    const imageUrl = url || formImage.rawUrl
    return {
      crop_info: formImage.crop,
      name: formImage.name,
      raw_url_key: imageUrl,
      photo_dict: { large: imageUrl, small: imageUrl },
    }
  }
  return null
}

export function useDefaultValues(
  offerTemplate: OfferTemplateProps,
  venueName: string,
  venueCurrency: string,
  policies: Policy[],
  upsells: Upsells,
  clientTagGroups: Map<string, TagGroup>,
  allShifts: Shift[],
  seatingAreaToTables: SeatingAreaToTables[]
) {
  const offerName = offerTemplate.offerName?.replace('(name)', venueName)
  const partySize = offerTemplate.partySize?.split(',')
  const minPartySize = partySize ? partySize[0]?.split(':')[1] : DefaultPartySize
  const maxPartySize = partySize ? partySize[1]?.split(':')[1] : DefaultPartySize
  const defaultSeatingAreas = offerTemplate.seatingAreas
    ?.toLowerCase()
    .split(',')
    .map(item => item.trim())

  const daysOfTheWeek = offerTemplate.daysOfTheWeek
    ?.split(',')
    .map(item => {
      const day = item.trim().toUpperCase()
      return Object.keys(DaysOfTheWeekEnum).indexOf(day)
    })
    .filter(i => i + 1)

  const shifts = Object.keys(ShiftCategoryEnum)
  const shiftCategories = offerTemplate.shifts
    ?.split(',')
    .map(item => {
      const shift = item.trim().toUpperCase()
      if (shifts.includes(shift)) {
        return shift
      }
      return undefined
    })
    .filter(i => !!i)

  const todayDate = new Date()

  const schedule = getInitialSchedule({
    startDate: todayDate,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shiftCategories: shiftCategories?.length ? shiftCategories : shifts,
    startOfDayTime: '',
    selectedDays: daysOfTheWeek,
  })

  const paymentPolicyDefaults = getPaymentPolicyDefaults({
    defaultBookingPolicyId: 'default',
    defaultCancelPolicyId: 'default',
    policies,
    clientTagGroups,
  })
  const paymentPolicy = getInitialPaymentPolicy({
    currencyCode: venueCurrency,
    policies,
    upsells,
    paymentPolicyDefaults,
  })
  const seatingAreas = getSeatingAreasByDefaultAreasList(allShifts, seatingAreaToTables, defaultSeatingAreas)

  return useMemo(
    () => ({
      templateId: offerTemplate.id,
      name: offerName,
      description: offerTemplate.offerDescription,
      previewDescription: '',
      price: '',
      partySize: { min: minPartySize ? +minPartySize : DefaultPartySize, max: maxPartySize ? +maxPartySize : DefaultPartySize },
      heroImage: offerTemplate.offerImage
        ? { rawUrl: offerTemplate.offerImage, name: offerTemplate?.offerImageFileName || ' ', crop: '' }
        : null,
      linkedAccessRuleIds: [],
      schedule,
      paymentPolicy: {
        ...paymentPolicy,
        paymentRule: 'none',
      },
      allowChannelsWithoutCCHolds: false,
      newAccessRule: null,
      accessType: ExperienceLinkTypeEnum.OPEN_ACCESS,
      seatingAreas,
    }),
    [
      maxPartySize,
      minPartySize,
      offerName,
      offerTemplate.id,
      offerTemplate.offerDescription,
      offerTemplate.offerImage,
      offerTemplate?.offerImageFileName,
      paymentPolicy,
      schedule,
      seatingAreas,
    ]
  )
}

export type Availabilities = 'linkToExistingAccessRule' | 'createNewAccessRule'
const DefaultPartySize = 2
