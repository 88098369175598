import { defineMessages } from '@sevenrooms/core/locales'

export const accessRulesSettingsMessages = defineMessages({
  title: {
    id: 'accessRulesSettings.title',
    defaultMessage: 'Access Rules',
  },
  subtitle: {
    id: 'accessRulesSettings.subtitle',
    defaultMessage:
      'Control your external availability on your Reservation Widget and Third Party Booking Channels and ensure that they are driving covers and revenue. <a>Learn More</a>',
  },
  createAccessRule: {
    id: 'accessRulesSettings.createAccessRule',
    defaultMessage: 'Create Access Rule',
  },
  viewReservationWidget: {
    id: 'accessRulesSettings.viewReservationWidget',
    defaultMessage: 'View Reservation Widget',
  },
  viewAccessLog: {
    id: 'accessRulesSettings.viewAccessLog',
    defaultMessage: 'View Access Log',
  },
} as const)
