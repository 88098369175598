import { formattedDayRangeDates } from 'mgr/lib/utils/MomentUtils'
import { srGet } from '@sevenrooms/core/api'
import { BEGIN_BOOKING_SEARCH_RESULT_FETCH, END_BOOKING_SEARCH_RESULT_FETCH, BOOKING_SEARCH_RESULT_FETCH_ERROR } from './ActionTypes'

const searchResponseHandlerFactory = path => (venueKey, venueId, searchQuery, searchShift, searchTime, errHandler, activeRequestId) =>
  srGet(path, {
    venue: venueKey,
    venues_filter: [venueId],
    q: searchQuery,
    rid: activeRequestId,
    do_query_notes: true,
    shift_code: searchShift == 'ALL' ? '' : searchShift,
    ...formattedDayRangeDates(searchTime),
  }).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response)
    }
    return response.data
  })

const fetchSearchResponse = searchResponseHandlerFactory('/api-yoa/search')

export const fetchBookingSearchResults = searchQuery => (dispatch, getState) => {
  const state = getState()
  const searchState = state.search

  searchQuery = searchQuery || searchState.searchQuery
  if (!searchQuery) {
    return
  }

  const { reservationRequestId } = searchState
  const newReservationRequestId = reservationRequestId + 1

  dispatch({
    type: BEGIN_BOOKING_SEARCH_RESULT_FETCH,
    reservationRequestId: newReservationRequestId,
  })

  const errHandler = () => {
    dispatch({ type: BOOKING_SEARCH_RESULT_FETCH_ERROR })
  }

  fetchSearchResponse(
    searchState.venueKey,
    state.appState.venue.id,
    searchQuery,
    searchState.searchShift,
    searchState.searchTime,
    errHandler,
    newReservationRequestId
  ).then(data => {
    const { pastBookings, futureBookings, numFound, rid, isSingleVenue } = data
    dispatch({
      type: END_BOOKING_SEARCH_RESULT_FETCH,
      pastBookings,
      futureBookings,
      numFound,
      isSingleVenue,
      reservationRequestId: rid,
    })
  })
}
