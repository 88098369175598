import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'

export interface AllowUnsupportedProps {
  field: Field<boolean>
}
export function AllowUnsupported({ field }: AllowUnsupportedProps) {
  const { formatMessage } = useLocales()
  return (
    <Checkbox data-test={PaymentPolicyTestId.allowOnUnsupportedChannels} field={field}>
      {formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)}
    </Checkbox>
  )
}
