import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { DefaultSettingsReportSection } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'

export function DefaultGuestFacingSettings() {
  const { formatMessage } = useLocales()
  const defaultSettings = [
    formatMessage(GuestFacingLocales.widgetDescriptionLabel),
    formatMessage(GuestFacingLocales.titleLabel),
    formatMessage(GuestFacingLocales.descriptionLabel),
    formatMessage(GuestFacingLocales.photoLabel),
  ]
  return (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart>
          {defaultSettings.map(label => (
            <ReportLine key={label} name={label}>
              {formatMessage(commonMessages.none)}
            </ReportLine>
          ))}
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
