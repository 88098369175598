import React, { useEffect, useMemo, useState } from 'react'
import type { SimphonyMenuItem } from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Label, FormSelect } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { BaseAutomaticPrepaymentRedemptionCard } from '../BaseAutomaticPrepaymentRedemptionCard'
import { messages } from './SimphonyAutomaticPrepaymentRedemptionCard.locales'
import type { SimphonyAutomaticPrepaymentRedemptionCardFormField } from './SimphonyAutomaticPrepaymentRedemptionCardForm.zod'

export interface SimphonyAutomaticPrepaymentRedemptionCardProps {
  field: SimphonyAutomaticPrepaymentRedemptionCardFormField
  menuItems: SimphonyMenuItem[]
}

export function SimphonyAutomaticPrepaymentRedemptionCard({ field, menuItems }: SimphonyAutomaticPrepaymentRedemptionCardProps) {
  const { formatMessage } = useLocales()
  const { watch, setValue } = useFormContext()
  const watchSelectedDepositMenuItemId = watch('automaticPrepaymentRedemptionCard.depositMenuItemId')
  const [selectedDepositMenuItem, setSelectedDepositMenuItem] = useState<string>(watchSelectedDepositMenuItemId)

  // Disable this card's inputs if it or automated check creation is disabled
  const isAutomaticCheckCreationEnabled = watch('automaticCheckCreationCard.isAutomaticCheckCreationEnabled')
  const isAutomaticPrepaymentRedemptionEnabled = watch('automaticPrepaymentRedemptionCard.isAutomaticPrepaymentRedemptionEnabled')
  const disabled = !(isAutomaticCheckCreationEnabled && isAutomaticPrepaymentRedemptionEnabled)

  useEffect(() => {
    if (!isAutomaticCheckCreationEnabled) {
      setValue('automaticPrepaymentRedemptionCard.isAutomaticPrepaymentRedemptionEnabled', isAutomaticCheckCreationEnabled)
    }
  }, [isAutomaticCheckCreationEnabled, setValue])

  const { depositMenuItemIdSelectOptions, depositDefinitionSequenceOptions } = useMemo(() => {
    const depositMenuItemIdSelectOptions: SelectOption[] = []
    const depositDefinitionSequenceOptions: SelectOption[] = []
    menuItems.forEach(menuItem => {
      depositMenuItemIdSelectOptions.push({
        id: menuItem.menuItemId,
        label: `${menuItem.name} (${menuItem.menuItemId})`,
      })

      if (menuItem.menuItemId === watchSelectedDepositMenuItemId) {
        menuItem.definitions.forEach(definition => {
          depositDefinitionSequenceOptions.push({
            id: definition.definitionSequence,
            label: `${definition.name} (${definition.definitionSequence})`,
          })
        })
      }
    })
    return { depositMenuItemIdSelectOptions, depositDefinitionSequenceOptions }
  }, [menuItems, watchSelectedDepositMenuItemId])

  useEffect(() => {
    // If the selected deposit menu item changes, clear the deposit definition sequence
    if (watchSelectedDepositMenuItemId !== selectedDepositMenuItem) {
      setValue('automaticPrepaymentRedemptionCard.depositDefinitionSequence', '')
      setSelectedDepositMenuItem(watchSelectedDepositMenuItemId)
    }

    if (depositDefinitionSequenceOptions.length === 1) {
      setValue('automaticPrepaymentRedemptionCard.depositDefinitionSequence', depositDefinitionSequenceOptions[0]?.id ?? '')
    }
  }, [depositDefinitionSequenceOptions, selectedDepositMenuItem, setValue, watchSelectedDepositMenuItemId])

  const docLink =
    'https://help.sevenrooms.com/hc/en-us/articles/19063804164123-Oracle-MICROS-Simphony-Cloud-v19-2-or-newer-Configuring-Prepayment-Sync'

  return (
    <BaseAutomaticPrepaymentRedemptionCard
      docLink={docLink}
      isAutomaticPrepaymentRedemptionEnabledField={field.prop('isAutomaticPrepaymentRedemptionEnabled')}
      disabled={!isAutomaticCheckCreationEnabled}
      cardSubHeadingLabel={formatMessage(messages.cardSubHeadingLabel, {
        a: (chunks: string[]) => <Anchor href={docLink}>{chunks}</Anchor>,
      })}
    >
      <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
        <Label width="50%" primary={formatMessage(messages.depositMenuItemIdLabel)}>
          <FormSelect
            field={field.prop('depositMenuItemId')}
            disabled={disabled}
            options={depositMenuItemIdSelectOptions}
            hideErrorMessage
          />
        </Label>
        <Label width="50%" primary={formatMessage(messages.depositDefinitionSequenceLabel)}>
          <FormSelect
            field={field.prop('depositDefinitionSequence')}
            disabled={disabled || !selectedDepositMenuItem || depositDefinitionSequenceOptions.length === 1}
            options={depositDefinitionSequenceOptions}
            hideErrorMessage
          />
        </Label>
      </HStack>
    </BaseAutomaticPrepaymentRedemptionCard>
  )
}
