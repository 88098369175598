import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import { openAutotag, toggleCustomAutotagSlideout } from 'mgr/pages/single-venue/marketing/actions/Autotags'

const mapStateToProps = state => ({
  isEditSlideoutOpen: !!state.autotags.editor.tag,
  isCreateSlideoutOpen: !!state.autotags.editor.isCustomAutotagEditorVisible,
})

const mapDispatchToProps = dispatch => ({
  closeEditAutotagSlideout: () => {
    dispatch(openAutotag(null))
  },
  closeCreateAutotagSlideout: () => {
    dispatch(toggleCustomAutotagSlideout())
  },
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onCloseHandler: stateProps.isEditSlideoutOpen ? dispatchProps.closeEditAutotagSlideout : dispatchProps.closeCreateAutotagSlideout,
  isSlideoutOpen: !!(stateProps.isEditSlideoutOpen || stateProps.isCreateSlideoutOpen),
  maxWidth: ownProps.maxWidth || '398px',
})

const AutotagSlideout = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SlideoutComponent)

export default AutotagSlideout
