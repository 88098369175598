export const GuestFacingTestId = {
  section: 'guest-facing',
  includesLabel: 'includes-label',
  notBookableTooltip: 'not-bookable-tooltip',
  offerLabel: 'offer-label',
  timeSlotButton: 'time-slot-button',
  timeSlotDescription: 'time-slot-description',
  timeSlotTitle: 'time-slot-title',
  longDescription: 'long-description',
  imageUpload: 'image-upload',
  offerDropdown: 'offer-dropdown',
  paidUpgrades: 'paid-upgrades',
  allowOnUnsupportedChannels: 'allow-on-unsupported-channels',
  report: 'guest-facing-report',
  htmlDescription: 'html-formatted-description',
  minSpend: 'min-spend',
} as const
