import { useMemo } from 'react'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'

export type ExcludedAudienceFormData = ZodSchema<typeof useExcludedAudienceForm>
export type ExcludedAudienceFormField = Field<ExcludedAudienceFormData>

export function useExcludedAudienceForm() {
  const multiSelectTagOptionForm = useMultiSelectTagOptionForm()
  return useMemo(
    () =>
      z.object({
        excludedAutotags: multiSelectTagOptionForm,
      }),
    [multiSelectTagOptionForm]
  )
}
