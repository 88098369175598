import type { SellingPoint } from '@sevenrooms/core/api'
import { Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { AccountTypes } from '@sevenrooms/payments'
import { PropertiesBackgroundImage } from '../../Integrations'
import { RecommendedIntegrationCard, RecommendedIntegrationStripeCard } from '../RecommendedIntegrationCard'
import { RecommendedIntegrationStripe } from '../RecommendedIntegrationStripe'

export function RecommendedIntegration({ integration }: { integration: SellingPoint | undefined }) {
  if (!integration) {
    return null
  }

  return (
    <HStack flexWrap="wrap">
      <Flex flex={2} flexWrap="wrap" p="s" backgroundColor="primaryBackground" borderTopLeftRadius="s" borderBottomLeftRadius="s">
        {integration.gateway === AccountTypes.STRIPE ? (
          <RecommendedIntegrationStripeCard />
        ) : (
          <RecommendedIntegrationCard integration={integration} />
        )}
      </Flex>
      {integration.gateway === AccountTypes.STRIPE && (
        <Flex
          flex={2}
          flexWrap="wrap"
          backgroundImage={PropertiesBackgroundImage}
          borderBottomRightRadius="s"
          borderTopRightRadius="s"
          justifyContent="center"
        >
          <Flex p="m" alignItems="center">
            <Flex p="l" backgroundColor="primaryBackground" borderRadius="m" flexWrap="wrap" boxShadow="primary">
              <RecommendedIntegrationStripe />
            </Flex>
          </Flex>
        </Flex>
      )}
    </HStack>
  )
}
