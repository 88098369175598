import type { Field } from '@sevenrooms/core/form'
import { FormTimeRangePicker, generateTimeSlots, type TimeRangePickerForm, Label } from '@sevenrooms/core/ui-kit/form'
import { END_TIME, numberToDayOfTheWeek } from '../../constants'

interface PerkTimeRangeFormProps {
  disabled: boolean
  timeRangeField: Field<TimeRangePickerForm>
  dayOfTheWeek: number
}

export const timeSlots: string[] = generateTimeSlots(0, 5).concat(END_TIME)

export function PerkTimeRangeForm({ dayOfTheWeek, disabled, timeRangeField }: PerkTimeRangeFormProps) {
  return (
    <Label key={dayOfTheWeek} primary={numberToDayOfTheWeek[dayOfTheWeek]}>
      <FormTimeRangePicker
        disabled={disabled}
        data-test={`sr-perk-time-range-${numberToDayOfTheWeek[dayOfTheWeek]}`}
        field={timeRangeField}
        timeSlots={timeSlots}
      />
    </Label>
  )
}
