import React, { useCallback, useMemo, useState } from 'react'
import { type OrderingRoomNumbers, orderingRoomNumbersApi } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { VerticalDragAndDrop } from '@sevenrooms/core/ui-kit/core'
import { Button, IconButton, Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  BaseSection,
  HStack,
  Loader,
  notify,
  VStack,
  Window,
  Flex,
  Modal,
  ModalActions,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { Header, Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { OrderRoomNumberLocales } from './OrderingRoomNumbers.locales'

export interface RoomNumbersListTableProps {
  openDeleteAllModal: () => void
}
export function RoomNumbersListTable({ openDeleteAllModal }: RoomNumbersListTableProps) {
  const { venueId } = useVenueContext()
  const { data, isFetching } = orderingRoomNumbersApi.useGetRoomNumbersQuery({ venueId })
  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedRoomNumber, setSelectedRoomNumber] = useState<OrderingRoomNumbers>()
  const onDelete = useCallback(
    (roomNumber: OrderingRoomNumbers) => {
      setSelectedRoomNumber(roomNumber)
      setModalVisible(true)
    },
    [setSelectedRoomNumber, setModalVisible]
  )
  const { formatMessage } = useLocales()
  const [_postOrderingRoomNumber, saveStatus] = orderingRoomNumbersApi.useCreateRoomNumbersMutation()
  const [deleteOrderingRoomNumber, deleteStatus] = orderingRoomNumbersApi.useDeleteRoomNumberByIdMutation()
  const isLoading = useMemo(
    () => saveStatus.isLoading || deleteStatus.isLoading || isFetching,
    [deleteStatus.isLoading, isFetching, saveStatus.isLoading]
  )
  const removeRoomNumber = useCallback(async () => {
    if (selectedRoomNumber) {
      const response = await deleteOrderingRoomNumber({ venueId, roomNumberId: selectedRoomNumber.id })
      if ('error' in response) {
        notify({ content: formatMessage(OrderRoomNumberLocales.notificationDeleteError), type: 'error' })
      } else {
        notify({
          content: formatMessage(OrderRoomNumberLocales.notificationDeleteSuccess, { roomNumber: selectedRoomNumber.number }),
          type: 'success',
        })
      }
    }
    setModalVisible(false)
  }, [deleteOrderingRoomNumber, formatMessage, selectedRoomNumber, venueId])

  const [sortedNumbers, setSortedNumbers] = useState<OrderingRoomNumbers[] | null>(null)
  const roomNumbersList = useMemo(
    () =>
      sortedNumbers ??
      (data ?? [])
        .map((roomNumber, index) => ({ ...roomNumber, sortOrder: roomNumber.sortOrder ?? index }))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [data, sortedNumbers]
  )

  const [updateRoomNumbers] = orderingRoomNumbersApi.useUpdateRoomNumbersMutation()
  const handleDrag = useCallback(
    (roomNumbers: OrderingRoomNumbers[]) => {
      setSortedNumbers(roomNumbers)
      updateRoomNumbers({ venueId, roomNumbers })
    },
    [setSortedNumbers, updateRoomNumbers, venueId]
  )

  return (
    <BaseSection>
      <Box p="m" data-test="sr-ordering-room-number-inventory-table">
        {!isLoading ? (
          <>
            <Window active={isModalVisible}>
              <Modal ariaLabel="Modal">
                <ModalHeader onClose={() => setModalVisible(false)}>
                  <VStack spacing="s">
                    <Status kind="error">{formatMessage(OrderRoomNumberLocales.deleteModalWarning)}</Status>
                    <ModalTitle
                      title={formatMessage(OrderRoomNumberLocales.deleteText, { roomNumber: selectedRoomNumber?.number || '' })}
                    />
                  </VStack>
                </ModalHeader>
                <ModalFooter>
                  <ModalActions>
                    <Button variant="secondary" onClick={() => setModalVisible(false)} data-test="sr-create-room-number-modal-discard">
                      {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button variant="primary-warning" onClick={() => removeRoomNumber()} data-test="sr-create-room-number-modal-add">
                      {formatMessage(commonMessages.delete)}
                    </Button>
                  </ModalActions>
                </ModalFooter>
              </Modal>
            </Window>
            <VStack borderColor="borders" borderRadius="s">
              <Flex justifyContent="space-between" alignItems="center">
                <Box ml="s">
                  <Header type="h4">{formatMessage(OrderRoomNumberLocales.orderingRoomNumbersTableHeader)}</Header>
                </Box>
                <Box>
                  <Menu anchor={<IconButton borderType="none-round" icon="VMSWeb-more-vertical" />} disabled={roomNumbersList.length === 0}>
                    <MenuItem onClick={openDeleteAllModal}>
                      <Icon name="VMSWeb-trash" />
                      {formatMessage(OrderRoomNumberLocales.deleteAllOrderingRoomNumbersTitle)}
                    </MenuItem>
                  </Menu>
                </Box>
              </Flex>
              <VerticalDragAndDrop
                items={roomNumbersList}
                handleDrag={handleDrag}
                rowProps={{
                  borderTopColor: 'borders',
                  p: 'm',
                  spacing: 'm',
                }}
              >
                {roomNumbersList.map((roomNumber, index) => (
                  <HStack height="16px" key={roomNumber.id} justifyContent="space-between">
                    <Box data-test={`room-number-${index}-number`}>{roomNumber.number}</Box>
                    <Box
                      data-test={`room-number-inventory-delete-link-${roomNumber.id}`}
                      cursor="pointer"
                      onClick={() => onDelete(roomNumber)}
                    >
                      <Icon name="VMSWeb-trash" />
                    </Box>
                  </HStack>
                ))}
              </VerticalDragAndDrop>
              {!roomNumbersList.length && (
                <Box width="100%" textAlign="center" borderTopColor="borders" pt="l" pb="l">
                  <Text textStyle="body2Medium">{formatMessage(OrderRoomNumberLocales.noRooms)}</Text>
                </Box>
              )}
            </VStack>
          </>
        ) : (
          <Loader>
            <Text textStyle="body1">{formatMessage(OrderRoomNumberLocales.loadingTitle)}</Text>
          </Loader>
        )}
      </Box>
    </BaseSection>
  )
}
