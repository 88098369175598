import moment from 'moment'
import { useMemo } from 'react'
import type { CreateEditExperienceTheForkApi } from '@sevenrooms/core/api'
import { TheForkMenuCourseEnum, type TheForkMenuCourseOption } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useDateRangePickerOptionalForm } from '@sevenrooms/core/ui-kit/form'

export const useTheForkDetailsMenuForm = () =>
  useMemo(
    () =>
      z.object({
        id: z.string(),
        dish: z.custom<TheForkMenuCourseOption>().nullable().default(null),
        customName: z.string().nullable().default(null),
        items: z.array(z.object({ name: z.string().nullable().default(null), id: z.string() })),
      }),
    []
  )

export type TheForkDetailsMenuFormData = ZodSchema<typeof useTheForkDetailsMenuForm>
export const useTheForkDetailsForm = () => {
  const dateRange = useDateRangePickerOptionalForm()
  const theForkMenu = useTheForkDetailsMenuForm()
  return useMemo(
    () =>
      z
        .object({
          name: z.string().nullable().default(null),
          price: z.number().nullable().default(null),
          dateRange,
          bookable: z.boolean().default(false),
          allowEarlyMenuPreview: z.boolean().default(false),
          previewStartDate: z.date().nullable().default(null),
          menus: z.array(theForkMenu),
        })
        .nullable(),
    [dateRange, theForkMenu]
  )
}
export type TheForkDetailsFormData = ZodSchema<typeof useTheForkDetailsForm>

export const formToTheForkDetails = (theForkDetails: TheForkDetailsFormData): CreateEditExperienceTheForkApi => {
  const menus = theForkDetails?.menus
    .filter(menu => menu.dish)
    .map(menu => ({
      menu_course: {
        id: menu.dish || '',
        label: TheForkMenuCourseEnum[menu.dish || 'STARTER'],
      },
      custom_menu_title: menu.customName || '',
      items: menu.items.map(item => item.name || ''),
    }))
  return {
    thefork_name: theForkDetails?.name || undefined,
    thefork_price: theForkDetails?.price ? `${theForkDetails?.price}` : undefined,
    thefork_start_date: theForkDetails?.dateRange?.startDate
      ? moment(theForkDetails?.dateRange?.startDate).format('YYYY-MM-DD')
      : undefined,
    thefork_end_date: theForkDetails?.dateRange?.endDate ? moment(theForkDetails?.dateRange?.endDate).format('YYYY-MM-DD') : undefined,
    thefork_active: theForkDetails?.bookable || false,
    thefork_early_preview: theForkDetails?.name ? theForkDetails?.allowEarlyMenuPreview : undefined,
    thefork_early_preview_date: theForkDetails?.previewStartDate
      ? moment(theForkDetails?.previewStartDate).format('YYYY-MM-DD')
      : undefined,
    thefork_menu: menus?.length !== 0 ? menus : undefined,
  }
}
