import { defineMessages } from '@sevenrooms/core/locales'

export const ChargeSettingsLocales = defineMessages({
  applyCharge: {
    id: 'ar.paymentPolicy.applyCharge',
    defaultMessage: 'Apply Service Charge (taxed)',
  },
  chargeChoiceDefault: {
    id: 'ar.paymentPolicy.chargeChoiceDefault',
    defaultMessage: 'Default Service charge ({charge, number, percent})',
  },
  chargeChoiceSpecific: {
    id: 'ar.paymentPolicy.chargeChoiceSpecific',
    defaultMessage: 'Specific service charge',
  },
  applyTax: {
    id: 'ar.paymentPolicy.applyTax',
    defaultMessage: 'Apply Tax',
  },
  taxDescription: {
    id: 'ar.paymentPolicy.taxDescription',
    defaultMessage: 'To configure tax rates, visit the <a>Taxes Rates</a> settings page',
  },
  applyGratuity: {
    id: 'ar.paymentPolicy.applyGratuity',
    defaultMessage: 'Apply Gratuity (not taxed)',
  },
  gratuityChoiceDefault: {
    id: 'ar.paymentPolicy.gratuityChoiceDefault',
    defaultMessage: 'Default gratuity ({charge, number, percent})',
  },
  gratuityChoiceSpecific: {
    id: 'ar.paymentPolicy.gratuityChoiceSpecific',
    defaultMessage: 'Specific gratuity',
  },
  gratuityChoiceClient: {
    id: 'ar.paymentPolicy.gratuityChoiceClient',
    defaultMessage: 'Allow client to select gratuity',
  },
  requireClientGratuity: {
    id: 'ar.paymentPolicy.requireClientGratuity',
    defaultMessage: 'Require client to select gratuity',
  },
  gratuityRequired: {
    id: 'ar.paymentPolicy.gratuityRequired',
    defaultMessage: 'Enter a gratuity over $0',
  },
} as const)
