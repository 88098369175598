import { useMemo, useState } from 'react'
import type { PlatformApplyType, PromoCode, Status } from '@sevenrooms/core/domain'
import type { TreeNode } from '@sevenrooms/core/ui-kit/form'
import type { SortingRule } from '@sevenrooms/core/ui-kit/layout'
import { usePromoCodeManagementResourcesContext } from './usePromoCodeManagementResourcesContext'

export function usePromoCodeManagementContext(list: PromoCode[]) {
  const { discountTypeDisplayNames, statusDisplayNames, platformApplyTypeDisplayNames } = usePromoCodeManagementResourcesContext()
  const [filterSearchValue, setFilterSearchValue] = useState<string>('')
  const [widgetChoices, setWidgetChoices] = useState<TreeNode<PlatformApplyType>[]>(
    Object.entries(platformApplyTypeDisplayNames).map(([id, label]) => ({
      id,
      value: id as PlatformApplyType,
      label,
    }))
  )
  const [statusChoices, setStatusChoices] = useState<TreeNode<Status>[]>(
    Object.entries(statusDisplayNames).map(([id, label]) => ({
      id,
      label,
      value: id as Status,
    }))
  )
  const [discountChoices, setDiscountChoices] = useState<TreeNode<string>[]>(
    Object.entries(discountTypeDisplayNames).map(([id, label]) => ({
      id,
      label,
      value: id,
    }))
  )

  const [sortedColumns, setSortedColumns] = useState<SortingRule<string>[]>([
    {
      id: 'name',
      desc: false,
    },
  ])

  const filteredList = useMemo(() => {
    const searchValue = filterSearchValue.toLowerCase()
    const selectedStatuses = statusChoices.filter(status => status.checked).map(choice => choice.value)
    const selectedWidgets = widgetChoices.filter(widget => widget.checked).map(choice => choice.value)
    const selectedDiscounts = discountChoices.filter(discount => discount.checked).map(choice => choice.value)
    return list.filter(
      (promoCode: PromoCode) =>
        (promoCode.name.toLowerCase().includes(searchValue) || promoCode.code.toLowerCase().includes(searchValue)) &&
        (!selectedStatuses.length || selectedStatuses.some(status => status === promoCode.status)) &&
        (!selectedDiscounts.length || selectedDiscounts.some(selectedDiscountChoice => selectedDiscountChoice === promoCode.promoType)) &&
        (!selectedWidgets.length || promoCode.platformApplyTypes.some(widget => selectedWidgets.includes(widget)))
    )
  }, [discountChoices, filterSearchValue, list, statusChoices, widgetChoices])

  return {
    filterSearchValue,
    setFilterSearchValue,
    widgetChoices,
    setWidgetChoices: (nodes: TreeNode<string>[]) => {
      setWidgetChoices(
        widgetChoices.map((choice: TreeNode<PlatformApplyType>) => ({
          ...choice,
          checked: nodes.some(node => node.value === choice.value),
        }))
      )
    },
    statusChoices,
    setStatusChoices: (nodes: TreeNode<string>[]) => {
      setStatusChoices(
        statusChoices.map((choice: TreeNode<Status>) => ({
          ...choice,
          checked: nodes.some(node => node.value === choice.value),
        }))
      )
    },
    discountChoices,
    setDiscountChoices: (nodes: TreeNode<string>[]) => {
      setDiscountChoices(
        discountChoices.map((choice: TreeNode<string>) => ({
          ...choice,
          checked: nodes.some(node => node.value === choice.value),
        }))
      )
    },
    sortedColumns,
    setSortedColumns,
    filteredList,
  }
}
