import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Switch, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OneTimeEmails } from '../../components'
import { oneTimeEmailCenterMessages } from '../../locales'

export function OneTimeEmailCampaignCenter() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const isTopolEnabled = !!venueSettings?.topol_editor_enabled

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterTitle)} />

      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterTitle)}
        description={formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterDescription)}
        actions={
          <Button
            data-test="one-time-email-center-create-email-button"
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => {
              if (isTopolEnabled) {
                nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
                  params: { venueKey: venue.urlKey },
                  queryMode: 'clear',
                })
              } else {
                nav.push(routes.manager2.marketing.oneTimeEmailCenter.emailCampaignTemplates, {
                  params: { venueKey: venue.urlKey },
                  queryMode: 'clear',
                })
              }
            }}
          >
            {formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterNewEmail)}
          </Button>
        }
      >
        <Box pl="lm" pr="lm" mb="xxl" width="100%">
          <Switch>
            <Route path={routes.manager2.marketing.oneTimeEmailCenter.path}>
              <OneTimeEmails />
            </Route>
            <Redirect to={routes.manager2.marketing.oneTimeEmailCenter.path} />
          </Switch>
        </Box>
      </SettingsPageContent>
    </>
  )
}
