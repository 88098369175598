import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { posiCommonMessages } from '../../../locales/posiCommonMessages.locales'
import { SettingsBanner } from '../SettingsBanner'

export interface DefaultErrorBannerProps {
  error: string
}

export function DefaultErrorBanner({ error }: DefaultErrorBannerProps) {
  const { formatMessage } = useLocales()
  return (
    <SettingsBanner
      title={formatMessage(posiCommonMessages.error)}
      description={formatMessage(posiCommonMessages.errorBannerDescription, { error })}
      type="error"
      action={formatMessage(posiCommonMessages.tryAgain)}
      onAction={() => window.location.reload()}
    />
  )
}
