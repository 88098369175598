import {
  useGetCustomAudienceQuery,
  useUpdateCustomAudienceMutation,
  useCreateCustomAudienceMutation,
  type CustomAudienceO,
} from '@sevenrooms/core/api'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button, Checkbox, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Loader, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { messages } from '../locales/ApiAudienceAddEdit.locale'

const addEditForm = z.object({
  name: z.string(),
  isActive: z.boolean(),
})

function ApiAudienceAddEditForm(props: { add: boolean; customAudience?: CustomAudienceO }) {
  const { customAudience } = props
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const defaultValues = { isActive: true }
  const [updateCustomAudience, { isLoading: isUpdating }] = useUpdateCustomAudienceMutation()
  const [addCustomAudience, { isLoading: isAdding }] = useCreateCustomAudienceMutation()
  const form = useForm(addEditForm, {
    defaultValues: props.add ? defaultValues : customAudience,
    mode: 'onChange',
  })
  const { field, handleSubmit } = form
  const onSubmit = async (data: { name: string; isActive: boolean }) => {
    if (props.add) {
      const response = await addCustomAudience({
        venueId: venue.id,
        data,
      })

      if ('data' in response) {
        if (response?.data?.status) {
          nav.push(routes.manager2.settings.customAudiences.view, { params: { venueKey: venue.urlKey } })
          notify({ type: 'success', content: formatMessage(messages.createdSuccessfully) })
        } else {
          notify({ type: 'error', content: formatMessage(messages.errorCreating) })
        }
      } else {
        notify({ type: 'error', content: formatMessage(messages.errorCreating) })
      }
    } else if (customAudience) {
      const response = await updateCustomAudience({
        venueId: venue.id,
        id: customAudience.clientId,
        data,
      })
      if ('data' in response) {
        if (response?.data?.status) {
          nav.push(routes.manager2.settings.customAudiences.view, { params: { venueKey: venue.urlKey } })
          notify({ type: 'success', content: formatMessage(messages.updatedSuccessfully) })
        } else {
          notify({ type: 'error', content: formatMessage(messages.errorUpdating) })
        }
      } else {
        notify({ type: 'error', content: formatMessage(messages.errorUpdating) })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsPageContent
        actions={
          <HStack spacing="s">
            <Button
              data-test="btn-go-back"
              href={nav.href(routes.manager2.settings.customAudiences.view, { params: { venueKey: venue?.urlKey } })}
              variant="secondary"
            >
              {formatMessage(messages.goBack)}
            </Button>
            {isUpdating || isAdding ? (
              <Loader />
            ) : (
              <Button data-test="btn-save" variant="primary" type="submit">
                {formatMessage(messages.save)}
              </Button>
            )}
          </HStack>
        }
        title={formatMessage(props.add ? messages.titleAdd : messages.titleEdit)}
        description={formatMessage(messages.titleDescription)}
      >
        <VStack m="m" p="m" backgroundColor="primaryBackground">
          <Box minHeight="90px">
            <Label primary="Name">
              <FormInput fullWidth field={field.prop('name')} />
            </Label>
          </Box>
          <HStack minHeight="40px">
            <Checkbox id="isActive" field={field.prop('isActive')} />
            <Label htmlFor="isActive" primary="Active" />
          </HStack>
        </VStack>
      </SettingsPageContent>
    </form>
  )
}

export function ApiAudienceAddEdit(props: { add: boolean }) {
  const isAdd = props.add
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  const params = nav.matchParams(routes.manager2.settings.customAudiences.edit)

  const customAudienceId = (params as { id?: string })?.id

  const { data, isFetching } = useGetCustomAudienceQuery({
    venueId: venue.id,
    id: customAudienceId,
  })

  if (isFetching || (!data && !props.add)) {
    return <Loader />
  }

  return (
    <>
      <SettingsPageMeta title={formatMessage(isAdd ? messages.titleAdd : messages.titleEdit)} venue={venue?.name} />
      <ApiAudienceAddEditForm add={isAdd} customAudience={data} />
    </>
  )
}
