import React from 'react'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { AutotagTierType, TierAttributes } from 'mgr/pages/shared/utils/Constants'
import {
  Tier,
  TierTitle,
  TierEditing,
  TierContent,
  TierEditor,
  TighterTierEditor,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier, { dropStyle, dropProps } from './BaseTier'

const dayChoices = Array(100)
  .fill()
  .map((_, i) => ({ name: String(i + 1), value: i + 1 }))

export class BirthdayTier extends BaseTier {
  getContent(attributes) {
    const type = this.props.tier.tier_type
    let siblingTierName
    switch (type) {
      case AutotagTierType.BIRTHDAY_LOCAL:
      case AutotagTierType.BIRTHDAY:
        siblingTierName = this.getSiblingTierName(
          type === AutotagTierType.BIRTHDAY_LOCAL ? AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL : AutotagTierType.CELEBRATED_BIRTHDAY
        )
        return (
          <div>
            Tag Clients with a birthday within a <strong>{attributes.DAY_DELTA}</strong> day window of before & after their birth date. This
            tag will get applied at the start of this window. If a client visits at any point with the tag active, this tag will transition
            to {siblingTierName}. If a client does not visit within this window, this tag will remove itself after the window ends.
          </div>
        )
      case AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL:
      case AutotagTierType.CELEBRATED_BIRTHDAY:
        siblingTierName = this.getSiblingTierName(
          type === AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL ? AutotagTierType.BIRTHDAY_LOCAL : AutotagTierType.BIRTHDAY
        )
        return (
          <div>
            Tag clients who have completed a visit while having a &quot;{siblingTierName}&quot; tag, and remove this tag automatically after{' '}
            <strong>{attributes.REMOVE_AFTER_DAYS}</strong> days.
          </div>
        )
      default:
        return null
    }
  }

  getEditor(attributes) {
    let siblingTierName
    const type = this.props.tier.tier_type
    const { index } = this.props
    switch (type) {
      case AutotagTierType.BIRTHDAY_LOCAL:
      case AutotagTierType.BIRTHDAY:
        siblingTierName = this.getSiblingTierName(
          type === AutotagTierType.BIRTHDAY_LOCAL ? AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL : AutotagTierType.CELEBRATED_BIRTHDAY
        )
        return (
          <TighterTierEditor>
            Tag Clients with a birthday within a
            <DropdownArrowsPicker
              {...dropProps}
              choices={dayChoices}
              value={attributes.DAY_DELTA}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.DAY_DELTA, value)}
            />
            day window of before & after their birth date. This tag will get applied at the start of this window. If a client visits at any
            point with the tag active, this tag will transition to {siblingTierName}. If a client does not visit within this window, this
            tag will remove itself after the window ends
          </TighterTierEditor>
        )
      case AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL:
      case AutotagTierType.CELEBRATED_BIRTHDAY:
        siblingTierName = this.getSiblingTierName(
          type === AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL ? AutotagTierType.BIRTHDAY_LOCAL : AutotagTierType.BIRTHDAY
        )
        return (
          <TighterTierEditor>
            Tag clients who have completed a visit while having a &quot;{siblingTierName}&quot; tag, and remove this tag automatically after{' '}
            <DropdownArrowsPicker
              {...dropProps}
              choices={dayChoices}
              value={attributes.REMOVE_AFTER_DAYS}
              isDropUp={index > 3}
              style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
              optionsContainerStyle={{ maxHeight: 200 }}
              onChangeHandler={value => this.updateTierAttribute(TierAttributes.REMOVE_AFTER_DAYS, value)}
            />
            days.
          </TighterTierEditor>
        )
      default:
        return null
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { attributes } = this.props.tier

    const { isActive, id } = this.props

    return (
      <div id={id}>
        {this.props.editing ? (
          <TierEditing error={this.props.error}>
            {this.fetchTitle()}
            {this.getEditor(attributes)}
            {this.fetchAdditionalOptions()}
          </TierEditing>
        ) : (
          <Tier onClick={this.toggleEditing} isActive={isActive}>
            <TierTitle>{name}</TierTitle>
            <TierContent>{this.getContent(attributes)}</TierContent>
          </Tier>
        )}
      </div>
    )
  }
}

export default BirthdayTier
