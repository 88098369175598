import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetSMSCampaignByIdQuery, useGetVenueProfileQuery, type OneTimeSMSCampaignType } from '@sevenrooms/core/api'
import { useDestination } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OneTimeSMSCampaignViewerForm } from './OneTimeSMSCampaignViewerForm'

export function OneTimeSMSCampaignViewer() {
  const { venueId } = useVenueContext()
  const { params } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.viewOneTimeSMS)
  const { campaignId } = params

  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data, isFetching: isSMSCampaignFetching } = useGetSMSCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const smsCampaign = data?.data
  const isFetching = isVenueProfileFetching || isSMSCampaignFetching

  return isFetching || !venueProfile || !smsCampaign ? (
    <Loader />
  ) : (
    <OneTimeSMSCampaignViewerForm smsCampaign={smsCampaign as OneTimeSMSCampaignType} venueProfile={venueProfile} />
  )
}
