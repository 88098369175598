import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { PartySizeLocales } from '../PartySize.locales'
import { PartySizeTestId } from '../PartySize.testIds'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function PartySizeReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()

  const { min, max } = accessRule.partySize

  return (
    <ReportPart caption={formatMessage(PartySizeLocales.title)} data-test={PartySizeTestId.report}>
      <ReportLine name={formatMessage(PartySizeLocales.title)}>
        <Text>{min && max ? `${min} - ${max}` : formatMessage(accessRulesMessages.followShift)}</Text>
      </ReportLine>
    </ReportPart>
  )
}
