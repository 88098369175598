import type { Experience } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { spacesMessages } from '../../spaces.locales'

interface StatusProps {
  experience?: Experience
}

export function Status({ experience }: StatusProps) {
  const { formatMessage } = useLocales()
  if (experience?.isDraft) {
    return <StatusLabel variant="status">{formatMessage(spacesMessages.draft)}</StatusLabel>
  }
  if (experience?.isActive) {
    return <StatusLabel variant="success">{formatMessage(commonMessages.active)}</StatusLabel>
  }
  if (experience?.isInactive) {
    return <StatusLabel variant="inactive">{formatMessage(commonMessages.inactive)}</StatusLabel>
  }
  return null
}
