import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { FormattedTimeBefore } from '../../shared/FormattedTimeBefore'
import { BookingWindowLocales } from '../BookingWindow.locales'
import { BookingWindowTestId } from '../BookingWindow.testIds'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function BookingWindowReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()

  const {
    bookingWindow: { startTime, cutoffTime },
    bookingChannels,
  } = accessRule

  return (
    <ReportPart caption={formatMessage(BookingWindowLocales.title)} data-test={BookingWindowTestId.report}>
      <ReportLine name={formatMessage(BookingWindowLocales.startTimeLabel)}>
        <Text>
          {bookingChannels.length > 1 ? (
            <Text fontStyle="italic">{formatMessage(BookingWindowLocales.setInBookingChannels)}</Text>
          ) : (
            <FormattedTimeBefore {...startTime} />
          )}
        </Text>
      </ReportLine>
      <ReportLine name={formatMessage(BookingWindowLocales.cutoffTimeLabel)}>
        <Text>
          <FormattedTimeBefore {...cutoffTime} />
        </Text>
      </ReportLine>
    </ReportPart>
  )
}
