import { useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingValue } from '../SettingValue'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './Stripe.locales'
import type { PaymentIntegration, PaymentIntegrationConfiguration } from '../interfaces'

export function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()
  return (
    <ViewSettingsBlock>
      <Text textStyle="h3">{formatMessage(messages.accountAndAuthentication)}</Text>
      <SettingValue name={(currentConfiguration?.accountId as string) || '-'} title={formatMessage(messages.accountId)} />
    </ViewSettingsBlock>
  )
}

export const Stripe: PaymentIntegration = {
  integrationName: AccountTypes.STRIPE,
  ShowCurrentSettings,
}
