import { subscriptionWidgetSettingsApi } from '@sevenrooms/core/api'
import type { SubscriptionWidgetSettings } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, useVenueContext } from '@sevenrooms/mgr-core'
import { SubscriptionForm } from './SubscriptionForm'
import { useSubscriptionForm } from './SubscriptionFormSchema.zod'
import { subscriptionLocales } from './SubscriptionLocales'

interface MainContentProps {
  subscriptionWidgetSettings?: SubscriptionWidgetSettings
}

export function MainContent({ subscriptionWidgetSettings }: MainContentProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const [saveSettings, { isLoading }] = subscriptionWidgetSettingsApi.useSaveSubscriptionWidgetSettingsMutation()

  const {
    handleSubmit,
    field,
    formState: { isDirty, dirtyFields },
    reset,
    setError,
  } = useSubscriptionForm({
    customButtonText: formatMessage(subscriptionLocales.defaultCustomButtonText),
    defaultSubscriptionFormValues: subscriptionWidgetSettings,
  })

  const onSubmit = handleSubmit(
    async formData => {
      const missingHeader = formData.showHeaderImage && !formData.headerImageIsResWidgetImage && !formData.headerImage
      const missingLogo = !formData.logoIsVenueLogo && !formData.logo
      if (missingHeader) {
        setError('headerImage', { message: formatMessage(subscriptionLocales.errorImageRequired) })
      }
      if (missingLogo) {
        setError('logo', { message: formatMessage(subscriptionLocales.errorImageRequired) })
      }
      if (
        !formData.showOptInMarketingVenueGroupPolicy &&
        !formData.showOptInMarketingVenuePolicy &&
        !formData.showOptInSmsMarketingVenuePolicy
      ) {
        return
      }
      if (missingHeader || missingLogo) {
        return
      }

      const response = await saveSettings({
        venueId,
        formData,
        dirtyFields,
      })
      if ('data' in response && response.data.status === 200) {
        notify({ content: formatMessage(subscriptionLocales.submitSuccessMessage), type: 'success' })
        reset(undefined, { keepValues: true })
      } else {
        notify({ content: formatMessage(subscriptionLocales.submitErrorMessage), type: 'error' })
      }
    },
    () => notify({ content: formatMessage(subscriptionLocales.submitErrorMessage), type: 'error' })
  )

  return (
    <SettingsPageContent
      title={formatMessage(subscriptionLocales.title)}
      actions={
        <Button variant="primary" disabled={!isDirty || isLoading} onClick={onSubmit} data-test="button-save-changes">
          {formatMessage(isLoading ? subscriptionLocales.submitLoadingMessage : commonMessages.saveChanges)}
        </Button>
      }
    >
      <SubscriptionForm field={field} subscriptionWidgetSettings={subscriptionWidgetSettings} />
    </SettingsPageContent>
  )
}
