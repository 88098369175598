import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { DefaultSettingsReportSection } from '../../shared'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import type { ShiftSeatingAreas } from './ShiftSeatingAreas'

export interface DefaultSeatingAreasProps {
  shiftSeatingAreas: ShiftSeatingAreas[]
}

export function DefaultSeatingAreas({ shiftSeatingAreas }: DefaultSeatingAreasProps) {
  const { formatMessage } = useLocales()

  return (
    <DefaultSettingsReportSection>
      <Report>
        <ReportPart caption={[formatMessage(accessRulesMessages.shift), formatMessage(SeatingAreasLocales.modalSeatingAreas)]}>
          {shiftSeatingAreas.map(row => (
            <ReportLine key={row.id} name={row.name}>
              <>
                {row.seatingAreas.length === 0
                  ? formatMessage(SeatingAreasLocales.allSeatingAreas)
                  : row.seatingAreas.map(({ name }) => name).join(', ')}
              </>
            </ReportLine>
          ))}
        </ReportPart>
      </Report>
    </DefaultSettingsReportSection>
  )
}
