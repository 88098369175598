import { useMemo } from 'react'
import type { POSIConfig } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { buildInServiceStatusSelectOptionId, getStatusAndLabelFromInServiceStatusSelectOptionId } from './CourseStatusMappingCard'

export type CourseStatusMappingCardFormData = ZodSchema<typeof useCourseStatusMappingCardForm>
export type CourseStatusMappingCardFormField = Field<CourseStatusMappingCardFormData>

export const CourseStatusMappingFieldSchema = z.object({
  checkTriggerType: z.string().refine(value => !!value),
  checkTriggers: z.array(z.string()).min(1),
  transitionToStatus: z.string().refine(value => !!value),
})

export const CourseStatusMappingCardFormSchema = z.object({
  isCourseStatusMappingEnabled: z.boolean(),
  courseStatusMappings: z.array(CourseStatusMappingFieldSchema),
})

export function useCourseStatusMappingCardForm() {
  return useMemo(() => CourseStatusMappingCardFormSchema, [])
}

export const getCourseStatusMappingCardFormDefaultValues = (config: POSIConfig) => {
  const courseStatusMappings = config.courseStatusMappings ?? []
  return {
    isCourseStatusMappingEnabled: config.courseStatusMappingEnabled,
    courseStatusMappings: courseStatusMappings.map(courseStatusMapping => {
      const checkTriggerTypes = new Set<string>()
      const checkTriggers: string[] = []
      courseStatusMapping.checkTriggers.forEach(checkTrigger => {
        checkTriggerTypes.add(checkTrigger.type)
        checkTriggers.push(checkTrigger.label)
      })
      if (checkTriggerTypes.size !== 1) {
        // This should never happen
        throw new Error(
          checkTriggerTypes.size > 1 ? 'Course status mapping contains check triggers of different types.' : 'Missing check trigger type.'
        )
      }
      const checkTriggerType = [...checkTriggerTypes.values()][0] as string
      return {
        checkTriggerType,
        checkTriggers,
        transitionToStatus: buildInServiceStatusSelectOptionId(courseStatusMapping.transitionToStatus),
      }
    }),
  }
}

export const convertCourseStatusMappingCardFormDataToPOSIConfig = (
  data: CourseStatusMappingCardFormData
): Pick<POSIConfig, 'courseStatusMappings' | 'courseStatusMappingEnabled'> => {
  const { courseStatusMappings, isCourseStatusMappingEnabled } = data
  return {
    courseStatusMappings: courseStatusMappings.map((courseStatusMapping, idx) => {
      const { status: transitionToStatusStatus, label: transitionToStatusLabel } = getStatusAndLabelFromInServiceStatusSelectOptionId(
        courseStatusMapping.transitionToStatus
      )
      return {
        mappingOrder: idx + 1,
        transitionToStatus: {
          label: transitionToStatusLabel,
          status: transitionToStatusStatus,
        },
        checkTriggers: courseStatusMapping.checkTriggers.map(checkTrigger => ({
          label: checkTrigger,
          type: courseStatusMapping.checkTriggerType,
        })),
      }
    }),
    courseStatusMappingEnabled: isCourseStatusMappingEnabled,
  }
}
