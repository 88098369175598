import { useGetConfigurationQuery, type ConfigurationInterface } from '@sevenrooms/core/api'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { AccountTypes } from '@sevenrooms/payments'
import { Adyen } from '../Adyen'
import { CybersourceRedux3ds } from '../CybersourceRedux3ds'
import { FreedomPay } from '../FreedomPay'
import { NetworkInternationalDirectPurchase } from '../NetworkInternationalDirectPurchase'
import { Saferpay } from '../Saferpay'
import { Stripe } from '../Stripe'
import type { PaymentIntegration } from '../interfaces'

export interface usePaymentIntegrationReturn {
  isFetching: boolean
  integration: PaymentIntegration | null
}

export function usePaymentIntegration(preselected?: string | undefined): usePaymentIntegrationReturn {
  const { venue } = useVenueContext()
  const { data, isFetching } = useGetConfigurationQuery({ venueId: venue.id })
  const { configurationData } = getConfigurationFromReturn(data)

  if (preselected === AccountTypes.CYBERSOURCE_3DS_REDUX || configurationData?._meta?.gateway === AccountTypes.CYBERSOURCE_3DS_REDUX) {
    const integration = CybersourceRedux3ds
    integration.formDefault = {
      merchantId: configurationData?.configuration?.merchantId ?? '',
      apiKeyId: configurationData?.configuration?.apiKeyId ?? '',
    }

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }
    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  if (
    preselected === AccountTypes.STRIPE ||
    (configurationData?._meta?.gateway === AccountTypes.STRIPE && configurationData?.configuration?.accountId)
  ) {
    const integration = Stripe

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }
    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  if (preselected === AccountTypes.SAFERPAY || configurationData?._meta?.gateway === AccountTypes.SAFERPAY) {
    const integration = Saferpay
    integration.formDefault = {
      customerId: configurationData?.configuration?.customerId ?? '',
      terminalId: configurationData?.configuration?.terminalId ?? '',
      motoTerminalId: configurationData?.configuration?.motoTerminalId ?? '',
      apiUser: configurationData?.configuration?.apiUser ?? '',
      urlFields: configurationData?.configuration?.urlFields ?? '',
      motoUrlFields: configurationData?.configuration?.motoUrlFields ?? '',
    }

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }
    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  if (preselected === AccountTypes.FREEDOMPAY || configurationData?._meta?.gateway === AccountTypes.FREEDOMPAY) {
    const integration = FreedomPay
    integration.formDefault = {
      storeId: configurationData?.configuration?.storeId ?? '',
      isAppleAndGooglePayEnabled: configurationData?.configuration?.isAppleAndGooglePayEnabled ?? true,
    }

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }
    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  if (preselected === AccountTypes.ADYEN || configurationData?._meta?.gateway === AccountTypes.ADYEN) {
    const integration = Adyen
    integration.formDefault = {
      accountCode: configurationData?.configuration?.accountCode ?? '',
      store: configurationData?.configuration?.store ?? '',
      liveEndpointPrefix: configurationData?.configuration?.liveEndpointPrefix ?? '',
      webhookUsername: configurationData?.configuration?.webhookUsername ?? '',
      isBcmcEnabled: configurationData?.configuration?.isBcmcEnabled ?? false,
      isGiropayEnabled: configurationData?.configuration?.isGiropayEnabled ?? false,
      isPaypalEnabled: configurationData?.configuration?.isPaypalEnabled ?? false,
    }

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }
    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  if (
    preselected === AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE ||
    configurationData?._meta?.gateway === AccountTypes.NETWORK_REDUX_DIRECT_PURCHASE
  ) {
    const integration = NetworkInternationalDirectPurchase
    integration.formDefault = {
      outletReference: configurationData?.configuration?.outletReference ?? '',
    }

    if (configurationData?.configuration) {
      integration.currentConfiguration = configurationData.configuration
    }

    if (configurationData?._meta) {
      integration.meta = configurationData._meta
    }

    return { isFetching, integration }
  }

  return {
    isFetching,
    integration: null,
  }
}

function getConfigurationFromReturn(data: ConfigurationInterface | undefined) {
  return { configurationData: data }
}
