import { useLocales } from '@sevenrooms/core/locales'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages } from '@sevenrooms/marketing'

const mockData = [
  {
    id: 1,
    linkTitle: 'https://www.sevenrooms.com/events/lakfjelamfelcwiiuwhciuwhcuhiwehciu',
    linkDescription: 'Button text: “Book Your Table”',
    uniqueClicks: 46,
    totalClicks: 46,
  },
  {
    id: 2,
    linkTitle: 'https://www.sevenrooms.com/reservations/weflwknefkwfkeal',
    linkDescription: `Button text: “View Our Valentine’s Menu”`,
    uniqueClicks: 16,
    totalClicks: 46,
  },
  {
    id: 3,
    linkTitle: 'https://www.hq.ushg.com',
    linkDescription: 'Image Link',
    uniqueClicks: 9,
    totalClicks: 46,
  },
  {
    id: 4,
    linkTitle: 'https://www.link.chtbl.com/yowT2201',
    linkDescription: 'Image Link',
    uniqueClicks: 2,
    totalClicks: 46,
  },
  {
    id: 5,
    linkTitle: 'https://www.link.chtbl.com/boeN9281',
    linkDescription: 'Image Link',
    uniqueClicks: 2,
    totalClicks: 46,
  },
]

export function TopClicksPerformance() {
  const { formatMessage } = useLocales()

  return (
    <VStack p="lm">
      <HStack display="flex" spacing="m">
        <HStack flex="1">
          <Text fontWeight={700} width="100%">
            {formatMessage(campaignCenterMessages.link)}
          </Text>
        </HStack>
        <Text fontWeight={700} width="90px" textAlign="end">
          {formatMessage(campaignCenterMessages.campaignPerformanceUniqueClicks)}
        </Text>
        <Text fontWeight={700} width="90px" textAlign="end">
          {formatMessage(campaignCenterMessages.campaignPerformanceTotalClicks)}
        </Text>
      </HStack>
      {mockData.map(row => (
        <HStack key={row.id} borderTopColor="margin" pt="m" mt="m" spacing="m">
          <VStack flex={1} spacing="s" overflow="hidden">
            <Text textOverflow="ellipsis">{row.linkTitle}</Text>
            <Text color="secondaryFont" textOverflow="ellipsis">
              {row.linkDescription}
            </Text>
          </VStack>
          <Text width="90px" textAlign="end">
            {row.uniqueClicks}
          </Text>
          <Text width="90px" textAlign="end">
            {row.totalClicks}
          </Text>
        </HStack>
      ))}
    </VStack>
  )
}
