import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { AccessRuleSelectorLocales } from './AccessRuleSelector.locales'

export interface UnlinkAccessRuleModalProps {
  onDiscard: () => void
  onSubmit: () => void
  accessRuleName: string
}
export function UnlinkAccessRuleModal({ onDiscard, onSubmit, accessRuleName }: UnlinkAccessRuleModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
      </ModalHeader>
      <ModalBody>
        <ModalTitle title={formatMessage(AccessRuleSelectorLocales.unlinkTitle, { name: accessRuleName })} />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onDiscard} data-test="sr-experience-unlink-access-roole-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary-warning" onClick={onSubmit} data-test="sr-experience-unlink-access-roole">
            {formatMessage(AccessRuleSelectorLocales.unlinkAccessRule)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
