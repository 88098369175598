import { useMemo, useState } from 'react'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import { PerkSelectAccessRuleModalAccessRules } from './PerkSelectAccessRuleModalAccessRules'
import { PerkSelectAccessRuleModalBooking } from './PerkSelectAccessRuleModalBooking'
import {
  type PerkSelectAccessRuleModalFormData,
  getPerkSelectAccessRuleModalDefaultValues,
  usePerkSelectAccessRuleModalForm,
} from './PerkSelectAccessRuleModalForm.zod'

interface Step {
  title: string
  component: JSX.Element
  button: JSX.Element
}

interface PerkSelectAccessRuleModalProps {
  closeHref: string
  onSubmit: (perkAccessRuleModalFormData: PerkSelectAccessRuleModalFormData) => void
  onClose?: () => void
  accessRulesOptions: SelectOption[]
}

const formatItalic = {
  i: (chunks: string[]) => (
    <SecondaryText fontStyle="italic" fontWeight={700}>
      {chunks}
    </SecondaryText>
  ),
}

export function PerkSelectAccessRuleModal({ closeHref, onClose, onSubmit, accessRulesOptions }: PerkSelectAccessRuleModalProps) {
  const { formatMessage } = useLocales()
  const [step, setStep] = useState<number>(0)

  const defaultValues = useMemo(getPerkSelectAccessRuleModalDefaultValues, [])

  const form = useForm(usePerkSelectAccessRuleModalForm(), {
    defaultValues,
  })

  const { field, watch, getValues } = form
  const currentFormValues = watch()

  const steps: Step[] = useMemo(
    () => [
      {
        title: formatMessage(perksMessages.earlyAccessRuleModalChooseAccessRuleTitle),
        component: <PerkSelectAccessRuleModalAccessRules field={field} accessRulesOptions={accessRulesOptions} />,
        button: (
          <Button
            data-test="perk-select-access-rule-next-button"
            variant="primary"
            onClick={() => setStep(prevStep => prevStep + 1)}
            disabled={!currentFormValues.accessRuleToCloneIds.length}
          >
            {formatMessage(commonMessages.next)}
          </Button>
        ),
      },
      {
        title: formatMessage(perksMessages.earlyAccessRuleModalReservationTitle),
        component: <PerkSelectAccessRuleModalBooking field={field} />,
        button: (
          <Button
            data-test="perk-select-access-rule-confirm-button"
            variant="primary"
            href={closeHref}
            onClick={() => {
              onSubmit(getValues())
            }}
            disabled={!currentFormValues.releaseTableTimeData.count}
          >
            {formatMessage(perksMessages.confirm)}
          </Button>
        ),
      },
    ],
    [
      formatMessage,
      field,
      currentFormValues.releaseTableTimeData.count,
      currentFormValues.accessRuleToCloneIds,
      accessRulesOptions,
      closeHref,
      onSubmit,
      getValues,
    ]
  )

  const currentPage = steps[step]

  return (
    <Modal ariaLabel={formatMessage(perksMessages.selectAccessRule)} minWidth="560px">
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <ModalHeader closeHref={closeHref} onClick={onClose}>
          <VStack spacing="s" mt="s">
            <SecondaryText fontWeight={700}>
              {formatMessage(perksMessages.earlyAccessRuleModalCreation, { ...formatItalic, step: step + 1, stepsCount: steps.length })}
            </SecondaryText>
            <ModalTitle title={currentPage?.title ?? ''} />
          </VStack>
        </ModalHeader>
        <ModalBody>{currentPage?.component}</ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button data-test="perk-select-access-rule-cancel-button" variant="tertiary" href={closeHref} onClick={onClose}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            {currentPage?.button}
          </ModalActions>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
