import { defineMessages } from '@sevenrooms/core/locales'

export const GuestFacingLocales = defineMessages({
  title: {
    id: 'ar.guestFacing.title',
    defaultMessage: 'Guest-Facing Display',
  },
  description: {
    id: 'ar.guestFacing.description',
    defaultMessage: 'Ex: Allow guests to explicitly choose Rooftop seating by labeling your widget time slot “Rooftop”.',
  },
  subCaption: {
    id: 'ar.guestFacing.subCaption',
    defaultMessage: 'Customize your guest journey by adding details about their reservation or experience.',
  },
  widgetDescriptionHeader: {
    id: 'ar.guestFacing.widgetDescriptionHeader',
    defaultMessage: 'Widget Time Slot Description',
  },
  widgetDescriptionLabel: {
    id: 'ar.guestFacing.widgetDescriptionLabel',
    defaultMessage: 'Time Slot Description',
  },
  widgetDescriptionPlaceholder: {
    id: 'ar.guestFacing.widgetDescriptionPlaceholder',
    defaultMessage: 'Enter a time slot description',
  },
  widgetDetailHeader: {
    id: 'ar.guestFacing.widgetDetailHeader',
    defaultMessage: '<strong>Widget Time Slot Detail</strong> (optional)',
  },
  titleLabel: {
    id: 'ar.guestFacing.titleLabel',
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: 'ar.guestFacing.titlePlaceholder',
    defaultMessage: 'Enter a title',
  },
  descriptionLabel: {
    id: 'ar.guestFacing.descriptionLabel',
    defaultMessage: 'Long Description',
  },
  descriptionPlaceholder: {
    id: 'ar.guestFacing.descriptionPlaceholder',
    defaultMessage: 'Enter a description',
  },
  includesLabel: {
    id: 'ar.guestFacing.includesLabel',
    defaultMessage: 'Included in the reservation',
  },
  includesTooltip: {
    id: 'ar.guestFacing.includesTooltip',
    defaultMessage:
      "When a guest clicks on a time slot in the reservation widget, they will be informed of any Bundled Upgrades you've configured in the Payment and Policy section.",
  },
  uploadLabel: {
    id: 'ar.guestFacing.uploadLabel',
    defaultMessage: 'Upload an image',
  },
  photoLabel: {
    id: 'ar.guestFacing.photoLabel',
    defaultMessage: 'Photo',
  },
  offerLabel: {
    id: 'ar.guestFacing.offerLabel',
    defaultMessage: 'Link to Offer',
  },
  offerOrGroupBookingSpaceLabel: {
    id: 'ar.guestFacing.offerOrGroupBookingSpaceLabel',
    defaultMessage: 'Link to Offer or Group Booking Space',
  },
  offerPlaceholder: {
    id: 'ar.guestFacing.offerPlaceholder',
    defaultMessage: 'Choose an offer',
  },
  offerTooltip1: {
    id: 'ar.guestFacing.offerTooltip1',
    defaultMessage: 'Offers are bookable promotions that you can use to help entice guests to dine with you.',
  },
  offerTooltip2: {
    id: 'ar.guestFacing.offerTooltip2',
    defaultMessage:
      'Use this setting to define availability for an Offer. An Offer will not show any availability unless it is linked to an Access Rule here.',
  },
  offerLink: {
    id: 'ar.guestFacing.offerLink',
    defaultMessage: 'Learn more about Offers',
  },
  bookOnUnsupportedLabel: {
    id: 'ar.guestFacing.bookOnUnsupportedLabel',
    defaultMessage: 'Allow this rule to be booked on channels that do not support any Guest-Facing Display fields',
  },
  bookOnUnsupportedDescription: {
    id: 'ar.guestFacing.bookOnUnsupportedDescription',
    defaultMessage: 'Third party booking channels and Concierge do not support Guest-Facing Display fields.',
  },
  notBookableTooltip: {
    id: 'ar.guestFacing.notBookableTooltip',
    defaultMessage:
      'This Access Rule will not be bookable for some Audiences because they do not support Guest-Facing Display fields. To make them bookable on unsupported channels, enable this setting.',
  },
  notBookableLink: {
    id: 'ar.guestFacing.notBookableLink',
    defaultMessage: 'View unsupported audiences',
  },
  notBookableSummary: {
    id: 'ar.guestFacing.notBookableSummary',
    defaultMessage: '<strong>Do not</strong> allow this rule to be booked on channels that do not support any Guest-Facing Display fields',
  },
  timeSlotPreviewLabel: {
    id: 'ar.guestFacing.timeSlotPreviewLabel',
    defaultMessage: 'Reservation Widget Time Slot preview',
  },
  widgetPreviewLabel: {
    id: 'ar.guestFacing.widgetPreviewLabel',
    defaultMessage: 'Example of Time Slot Detail when time slot is clicked',
  },
  minSpendPreviewLabel: {
    id: 'ar.guestFacing.minSpendPreviewLabel',
    defaultMessage: 'Example of Min Spend in Selected Timeslot modal:',
  },
  minSpendLabel: {
    id: 'ar.guestFacing.minSpendLabel',
    defaultMessage: 'Group Booking Minimum Spend',
  },
  minSpendDescription: {
    id: 'ar.guestFacing.minSpendDescription',
    defaultMessage: 'This is display only. Set other fees in the Space page. Set required payments at booking in Payments Settings below.',
  },
  perPerson: {
    id: 'ar.guestFacing.perPerson',
    defaultMessage: 'Per Person:',
  },
  perReservation: {
    id: 'ar.guestFacing.perReservation',
    defaultMessage: 'Per Reservation:',
  },
} as const)
