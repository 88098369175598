import { POSITypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SideNavigationCategoryItem } from '@sevenrooms/core/ui-kit/layout'
import { accessRuleBulkEditLocales } from '@sevenrooms/mgr-access-rule-bulk-edit'
import { useNavigationPermissionsContext, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { iVvyTableMappingLocales } from '@sevenrooms/mgr-ivvi-settings'
import { routes } from '@sevenrooms/routes'
import { settingsMessages } from '../settings.locales'

export const useNavigationItems = (isAllReload?: boolean): Array<SideNavigationCategoryItem> => {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const permissions = useNavigationPermissionsContext()
  const showNavigation =
    venue &&
    permissions &&
    (permissions.canAccessSettingsNav ||
      permissions.canAdministrateShiftsAccessRules ||
      permissions.canAdministrateBlackoutDatesDailyProgram)

  if (!showNavigation) {
    return []
  }
  const venueKeyParams = { params: { venueKey: venue.urlKey } }
  const restrictedIntegrations = venueSettings?.is_whatsapp_enabled
    ? [
        {
          id: 'settings-link-messaging-integrations',
          title: formatMessage(settingsMessages.messagingProviders),
          to: nav.href(routes.manager2.settings.messagingIntegration, venueKeyParams),
          allowed: true,
          reload: true,
          items: [],
        },
      ]
    : []
  return [
    {
      id: 'general-group',
      title: formatMessage(settingsMessages.generalGroup),
      allowed:
        permissions.canManageVenueSettings ||
        permissions.canAdministrateGeneralSettings ||
        permissions.canManageTagList ||
        permissions.canManageClientTagList ||
        permissions.canManageReservationTagList ||
        permissions.canManageMembershipGroups,
      items: [
        {
          id: 'settings-link-general-new',
          title: formatMessage(settingsMessages.venueSettings),
          to: nav.href(routes.manager2.settings.venue, venueKeyParams),
          allowed:
            !permissions.isOnboardingSpecialist && (permissions.canManageVenueSettings || permissions.canAdministrateGeneralSettings),
          reload: isAllReload,
          items: [],
        },
        {
          id: 'settings-link-general-tags',
          title: formatMessage(settingsMessages.clientTags),
          to: nav.href(routes.manager.manage.clientTags, venueKeyParams),
          allowed: permissions.canManageClientTagList,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-reservation-tags',
          title: formatMessage(settingsMessages.reservationTags),
          to: nav.href(routes.manager.manage.reservationTags, venueKeyParams),
          allowed: permissions.canManageReservationTagList,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-general-membership-types',
          title: formatMessage(settingsMessages.membershipGroups),
          to: nav.href(routes.manager.manage.membershipGroups, venueKeyParams),
          allowed: permissions.canManageMembershipGroups,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-general-tax-rates',
          title: formatMessage(settingsMessages.taxRates),
          to: nav.href(routes.manager.manage.taxRates, venueKeyParams),
          allowed: permissions.canAdministrateGeneralSettings,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-general-fees',
          title: formatMessage(settingsMessages.fees),
          to: nav.href(routes.manager2.settings.fees, venueKeyParams),
          allowed: !!venueSettings?.is_flexible_fees_integration_enabled,
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'integrations-group',
      title: formatMessage(settingsMessages.integrationsGroup),
      allowed: permissions.canAccessSettingsNav && permissions.canManageRestrictedSettings && !permissions.isOnboardingSpecialist,
      items: [
        {
          id: 'settings-booking-channels',
          title: formatMessage(settingsMessages.bookingChannels),
          to: nav.href(routes.manager2.settings.bookingChannels, venueKeyParams),
          allowed: permissions.canManageBookingChannels,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-general-payment-integrations',
          title: formatMessage(settingsMessages.paymentIntegration),
          to: nav.href(routes.manager2.settings.paymentIntegration.view, venueKeyParams),
          allowed: permissions.canAdministratePaymentGateways,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-email-serviceproviders',
          title: formatMessage(settingsMessages.emailServiceProviders),
          to: nav.href(routes.manager2.settings.emailServiceProviders, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-ivvy-table-mapping',
          title: formatMessage(iVvyTableMappingLocales.title),
          to: nav.href(routes.manager2.settings.iVvyTableMapping, venueKeyParams),
          allowed: venueSettings?.is_ivvy_enabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-review-channels',
          title: formatMessage(settingsMessages.reviewChannels),
          to: nav.href(routes.manager2.settings.reviewChannels, venueKeyParams),
          allowed: venueSettings?.direct_review_channels_enabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-pos',
          title: formatMessage(settingsMessages.pointofsale),
          to: nav.href(routes.manager2.settings.pointofsale, venueKeyParams),
          allowed:
            venue.posActivated &&
            (venue.posIntegrationType === POSITypeEnum.LIGHTSPEED || venue.posIntegrationType === POSITypeEnum.LIGHTSPEED_KOUNTA),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-posi',
          title: formatMessage(settingsMessages.pointofsale),
          to: nav.href(routes.manager2.settings.pointOfSaleIntegration.view, venueKeyParams),
          allowed:
            !venue.posActivated ||
            (venue.posActivated &&
              [POSITypeEnum.SQUARE, POSITypeEnum.LIGHTSPEED_K, POSITypeEnum.SIMPHONY].includes(venue.posIntegrationType as POSITypeEnum)),
          reload: true,
          items: [],
        },
        ...restrictedIntegrations,
      ],
    },
    {
      id: 'availability-group',
      title: formatMessage(settingsMessages.availabilityGroup),
      allowed: permissions.canAdministrateShiftsAccessRules || permissions.canAdministrateBlackoutDatesDailyProgram,
      items: [
        {
          id: 'settings-link-daysofoperation',
          title: formatMessage(settingsMessages.daysOfOperation),
          to: nav.href(routes.manager.manage.daysOfOperation, venueKeyParams),
          allowed: permissions.canAdministrateShiftsAccessRules && venue.isNightlifeClass,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-schedules',
          title: formatMessage(settingsMessages.shifts),
          to: nav.href(routes.manager.manage.shifts, venueKeyParams),
          allowed: permissions.canAdministrateShiftsAccessRules && venue.isDiningClass,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-access',
          title: formatMessage(settingsMessages.accessRules),
          to: nav.href(routes.manager.manage.accessRules, venueKeyParams),
          allowed: permissions.canAdministrateShiftsAccessRules && venue.isDiningClass,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-new-access',
          title: formatMessage(settingsMessages.newAccessRules),
          to: nav.href(routes.manager2.settings.availability.accessRulesSettings, venueKeyParams),
          allowed:
            permissions.isSuperhero ||
            (venueSettings?.ff_is_new_access_rules_settings_enabled && permissions.canAdministrateShiftsAccessRules && venue.isDiningClass),
          reload: true,
          items: [],
        },
        {
          id: 'settings-access-rule-bulk-edit',
          title: formatMessage(accessRuleBulkEditLocales.title),
          to: nav.href(routes.manager2.settings.accessRuleBulkEdit, venueKeyParams),
          allowed: venueSettings?.is_bulk_ar_edit_enabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-avail-events',
          title: formatMessage(settingsMessages.dailyProgram),
          to: nav.href(routes.manager.manage.dailyProgram, venueKeyParams),
          allowed: permissions.canAdministrateBlackoutDatesDailyProgram && venue.isDiningClass,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-avail-blackout',
          title: formatMessage(settingsMessages.blackoutDates),
          to: nav.href(routes.manager.manage.blackoutDates, venueKeyParams),
          allowed: permissions.canAdministrateBlackoutDatesDailyProgram,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-avail-perks',
          title: formatMessage(settingsMessages.perks),
          to: nav.href(routes.manager.manage.perks, venueKeyParams),
          allowed:
            permissions.canAdministrateBlackoutDatesDailyProgram && !venue.isNoConciergeAccess && !permissions.isLoyaltyAndPerksEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-availability-quick-view',
          title: formatMessage(settingsMessages.availabilityQuickView),
          to: nav.href(routes.manager2.availability, venueKeyParams),
          allowed: venue.isDiningClass,
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'widget-group',
      title: formatMessage(settingsMessages.widgetGroup),
      allowed: permissions.canAdministrateWidgetSettings,
      items: [
        {
          id: 'settings-link-widget-reservation-v2',
          title: formatMessage(settingsMessages.reservationWidget),
          to: nav.href(routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2, venueKeyParams),
          allowed: venue.newResWidgetEnabled && venueSettings?.force_redirects_on_legacy_widget && permissions.isDiningWidgetEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-widget-reservation',
          title: formatMessage(settingsMessages.reservationWidget),
          to: nav.href(routes.manager.settings.widgets.reservationWidgetSettings, venueKeyParams),
          allowed: !venueSettings?.force_redirects_on_legacy_widget && permissions.isDiningWidgetEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-widget-event',
          title: formatMessage(settingsMessages.eventWidget),
          to: nav.href(routes.manager.settings.widgets.eventWidgetSettings, venueKeyParams),
          allowed: permissions.isEventWidgetEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-widget-waitlist',
          title: formatMessage(settingsMessages.waitlistWidget),
          to: nav.href(routes.manager.settings.widgets.waitlistWidgetSettings, venueKeyParams),
          allowed: permissions.isWaitlistWidgetEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-widget-subscription',
          title: formatMessage(settingsMessages.subscriptionWidget),
          to: nav.href(routes.manager2.settings.subscriptionPage, venueKeyParams),
          allowed: permissions.isEmailMarketingEnabled || permissions.isSmsMarketingEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-widget-landing-page',
          title: formatMessage(settingsMessages.landingPage),
          to: nav.href(routes.manager2.settings.landingPage, venueKeyParams),
          allowed: permissions.isLandingPageEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-custom-audiences',
          title: formatMessage(settingsMessages.customAudiences),
          to: nav.href(routes.manager2.settings.customAudiences.view, venueKeyParams),
          allowed: permissions.canAdministrateClientAudiences,
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'ordering-group',
      title: formatMessage(settingsMessages.orderingGroup),
      allowed: venue.isSevenRoomsOrderingEnabled && permissions.canAccessSettingsNav && permissions.canManageRestrictedSettings,
      items: [
        {
          id: 'settings-link-ordering-sites',
          title: formatMessage(settingsMessages.orderingSites),
          to: nav.href(routes.manager2.settings.ordering.sites, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-menu-management',
          title: formatMessage(settingsMessages.menuManagement),
          to: nav.href(routes.manager2.settings.ordering.menus, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-product-inventory',
          title: formatMessage(settingsMessages.productInventory),
          to: nav.href(routes.manager2.settings.ordering.inventoryProducts, venueKeyParams),
          allowed: venue.isSevenRoomsOrderingMiddleware,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-86products',
          title: formatMessage(settingsMessages.eightySixProducts),
          to: nav.href(routes.manager2.settings.ordering.eightySixProduct, venueKeyParams),
          allowed: venue.isSevenRoomsOrderingMiddleware && !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-checkout-settings',
          title: formatMessage(settingsMessages.checkoutSettings),
          to: nav.href(routes.manager2.settings.ordering.checkout, venueKeyParams),
          allowed: !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-brand-settings',
          title: formatMessage(settingsMessages.brandSettings),
          to: nav.href(routes.manager2.settings.ordering.brand, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-guest-notifications',
          title: formatMessage(settingsMessages.guestNotifications),
          to: nav.href(routes.manager2.settings.ordering.notificationSettings, venueKeyParams),
          allowed: permissions.isOrderingSmsEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-order-pacing',
          title: formatMessage(settingsMessages.orderPacing),
          to: nav.href(routes.manager2.settings.ordering.pacingRules, venueKeyParams),
          allowed: !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-service-fees',
          title: formatMessage(settingsMessages.serviceFees),
          to: nav.href(routes.manager2.settings.ordering.serviceFees, venueKeyParams),
          allowed: !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-delivery-integrations',
          title: formatMessage(settingsMessages.deliveryIntegrations),
          to: nav.href(routes.manager2.settings.ordering.deliveryIntegrations, venueKeyParams),
          allowed: !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-ordering-room-numbers',
          title: formatMessage(settingsMessages.roomNumbers),
          to: nav.href(routes.manager2.settings.ordering.roomNumbers, venueKeyParams),
          allowed: !permissions.isOnboardingSpecialist,
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'floorplan-group',
      title: formatMessage(settingsMessages.floorplanGroup),
      allowed: permissions.canEditFloorplanLayout,
      items: [
        {
          id: 'settings-link-floor-rooms',
          title: formatMessage(settingsMessages.rooms),
          to: nav.href(routes.manager.manage.rooms, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-floor-seating-areas',
          title: formatMessage(settingsMessages.seatingAreas),
          to: nav.href(routes.manager.manage.seatingAreas, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-floor-tables',
          title: formatMessage(settingsMessages.tables),
          to: nav.href(routes.manager.manage.tables, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-floor-table-combinations',
          title: formatMessage(settingsMessages.tableCombinations),
          to: nav.href(routes.manager.manage.tableCombinations, venueKeyParams),
          allowed: venue.isDiningClass,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-floor-reservation-statuses',
          title: formatMessage(settingsMessages.reservationStatuses),
          to: nav.href(routes.manager.manage.reservationStatuses, venueKeyParams),
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'people-group',
      title: formatMessage(settingsMessages.peopleGroup),
      allowed:
        (permissions.canManageRestrictedSettings && !permissions.isOnboardingSpecialist) ||
        permissions.canAdministrateGeneralSettings ||
        permissions.canManageUserConfig,
      items: [
        {
          id: 'settings-link-people-user-accounts',
          title: formatMessage(settingsMessages.userAccounts),
          to: nav.href(routes.manager.userAccounts, venueKeyParams),
          allowed: permissions.canManageUserConfig,
          reload: true,
          items: [],
        },
        {
          id: 'settings-user-accounts',
          title: `7R Superheroes View Only: ${formatMessage(settingsMessages.userAccounts)}`,
          to: nav.href(routes.manager2.settings.userAccountsSettings.list, venueKeyParams),
          allowed: permissions.canManageUserAccountsCrm,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-people-bbn',
          title: formatMessage(settingsMessages.bookedByNames),
          to: nav.href(routes.manager.manage.bookedByNames, venueKeyParams),
          allowed: permissions.canAdministrateGeneralSettings,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-people-server-names',
          title: formatMessage(settingsMessages.serverNames),
          to: nav.href(routes.manager.manage.serverNames, venueKeyParams),
          allowed: permissions.canAdministrateGeneralSettings,
          reload: true,
          items: [],
        },
      ],
    },
    {
      id: 'guest-facing-group',
      title: formatMessage(settingsMessages.guestFacingSettingsGroup),
      allowed: permissions.canManageRestrictedSettings && !permissions.isOnboardingSpecialist,
      items: [
        {
          id: 'settings-link-gfl-widgets-and-pages',
          title: formatMessage(settingsMessages.widgets),
          to: nav.href(routes.manager2.settings.widgets, venueKeyParams),
          reload: true,
          items: [],
          allowed: venue.isDiningClass,
        },
        {
          id: 'settings-link-gfl-pages',
          title: formatMessage(settingsMessages.pages),
          to: nav.href(routes.manager2.settings.pages, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-gfl-emails',
          title: formatMessage(settingsMessages.emails),
          to: nav.href(routes.manager2.settings.emails, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-gfl-SMS',
          title: formatMessage(settingsMessages.SMS),
          to: nav.href(routes.manager2.settings.SMS, venueKeyParams),
          allowed:
            permissions.isReservationSmsEnabled ||
            permissions.isWaitlistSMSEnabled ||
            permissions.isOrderingSmsEnabled ||
            permissions.isRemindersSmsEnabled,
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-gfl-policies',
          title: formatMessage(settingsMessages.policies),
          to: nav.href(routes.manager2.settings.policies, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-gfl-language-settings',
          title: formatMessage(settingsMessages.languageSettings),
          to: nav.href(routes.manager2.settings.language, venueKeyParams),
          reload: true,
          items: [],
          allowed: venue.isDiningClass,
        },
      ],
    },
    {
      id: 'reservation-client-import-group',
      title: formatMessage(settingsMessages.reservationClientImportGroup),
      allowed: permissions.canAccessFlatfileImports,
      items: [
        {
          id: 'settings-link-reservation-import',
          title: formatMessage(settingsMessages.reservationImport),
          to: nav.href(routes.manager2.settings.reservationImport, venueKeyParams),
          reload: true,
          items: [],
        },
        {
          id: 'settings-link-client-import',
          title: formatMessage(settingsMessages.clientImport),
          to: nav.href(routes.manager2.settings.clientImport, venueKeyParams),
          reload: true,
          items: [],
        },
      ],
    },
  ]
}
