import type { DaysOfTheWeek } from '@sevenrooms/core/domain'

export const dayOfTheWeekToNumber: Record<DaysOfTheWeek, number> = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
}
