import { type PropsWithChildren, useCallback } from 'react'
import { VenueClassEnum, type VenueSettingsOptions } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales, shiftMessages, commonMessages } from '@sevenrooms/core/locales'
import { getNumberOptions, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { SimpleSection, Box, type BoxProps, HStack, MultiLine, PaddedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { CheckboxToggler } from '../components/CheckboxToggler'
import { SectionBox, HalfSizeableBox } from '../components/SectionBox'
import { ToggleToggler } from '../components/ToggleToggler'
import { useSelectDaysOptions } from '../hooks'
import { venueSettingsMessages } from '../VenueSettings.locales'
import { internalTeamEmailsMessages } from './InternalTeamEmails.locales'
import type { InternalTeamEmailsForm } from './InternalTeamEmails.zod'

export interface InternalTeamEmailsProps {
  field: Field<InternalTeamEmailsForm>
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
}

const numberOptions = getNumberOptions({ max: 11 })

export function InternalTeamEmails({ field, options, venueClass }: InternalTeamEmailsProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()
  const daysBeforeOptions = useSelectDaysOptions(2, 'before')

  const prepareHoursOptions = useCallback(
    (options: SelectOption[]): SelectOption[] =>
      options.map(option => ({ id: option.id, label: formatMessage(commonMessages.hours, { count: option.label }) })),
    [formatMessage]
  )

  return (
    <VStack spacing="lm" mt="lm">
      {options.sendDailyEmailSummary && (
        <SectionBox halfsize title={formatMessage(internalTeamEmailsMessages.sectionReservationSummaryEmail)}>
          {venueClass === VenueClassEnum.DINING && (
            <ToggleToggler
              title={formatMessage(internalTeamEmailsMessages.reservationSummaryEmailTitle)}
              description={formatMessage(internalTeamEmailsMessages.reservationSummaryEmailDescription)}
              field={field.prop('reservationSummarySendTimeShow')}
              data-test="reservationSummarySendTimeShow"
            >
              <VStack spacing="s">
                {field.array('reservationSummarySendTime').map((field, value) => (
                  <CheckboxToggler
                    data-test={`reservationSummarySendTime-${value.shift}`}
                    key={value.shift}
                    title={formatMessage(shiftMessages[value.shift])}
                    checked={field.array('dayTime').size() > 0}
                    onShow={() => {
                      field.array('dayTime').append({
                        sendTime: options.time[0]?.id as string,
                        sendDays: daysBeforeOptions[0]?.id as string,
                      })
                    }}
                    onHide={() => field.array('dayTime').empty()}
                  >
                    <MultiLine
                      label={formatMessage(venueSettingsMessages.addAnotherSendTime)}
                      fieldArray={field.array('dayTime')}
                      data-test="dayTime"
                      item={{ sendTime: options.time[0]?.id as string, sendDays: daysBeforeOptions[0]?.id as string }}
                      renderItem={field => (
                        <>
                          <FormSelect
                            options={options.time}
                            searchable={false}
                            hideErrorMessage
                            field={field.prop('sendTime')}
                            data-test="sendTime"
                          />
                          <FormSelect
                            options={daysBeforeOptions}
                            searchable={false}
                            hideErrorMessage
                            field={field.prop('sendDays')}
                            data-test="sendDays"
                          />
                        </>
                      )}
                    />
                  </CheckboxToggler>
                ))}
              </VStack>
            </ToggleToggler>
          )}
          {venueClass === VenueClassEnum.NIGHTLIFE && (
            <>
              <Label
                primary={formatMessage(internalTeamEmailsMessages.reservationSummaryEmailTitle)}
                secondary={formatMessage(internalTeamEmailsMessages.reservationSummaryEmailDescription)}
              />
              <MultiLine
                label={formatMessage(venueSettingsMessages.addAnotherSendTime)}
                fieldArray={field.array('reservationSummarySendTimeNightlife')}
                data-test="reservationSummarySendTimeNightlife"
                item={{ time: options.time[0]?.id as string }}
                renderItem={field => <FormSelect options={options.time} searchable={false} hideErrorMessage field={field.prop('time')} />}
              />
            </>
          )}
        </SectionBox>
      )}
      {venueClass === VenueClassEnum.DINING && options.useSupafly && (
        <SectionBox halfsize title={formatMessage(internalTeamEmailsMessages.sectionFeedbackSummaryEmail)}>
          <VStack spacing="s">
            <ToggleToggler
              title={formatMessage(internalTeamEmailsMessages.feedbackSummaryEmailTitle)}
              description={formatMessage(internalTeamEmailsMessages.feedbackSummaryEmailDescription)}
              field={field.prop('feedbackSummarySendTimeShow')}
              data-test="feedbackSummarySendTimeShow"
              onShow={() =>
                field.array('feedbackSummarySendTime').size() === 0 &&
                field.array('feedbackSummarySendTime').append({ time: options.time[0]?.id as string })
              }
            >
              <MultiLine
                label={formatMessage(venueSettingsMessages.addAnotherSendTime)}
                fieldArray={field.array('feedbackSummarySendTime')}
                data-test="feedbackSummarySendTime"
                item={{ time: options.time[0]?.id as string }}
                renderItem={field => (
                  <FormSelect options={options.time} searchable={false} hideErrorMessage field={field.prop('time')} data-test="time" />
                )}
              />
            </ToggleToggler>
            {venueSettings?.ff_ai_review_summary_enabled && (
              <ToggleToggler
                title={formatMessage(internalTeamEmailsMessages.aiFeedbackSummaryEmailTitle)}
                description={formatMessage(internalTeamEmailsMessages.aiFeedbackSummaryEmailDescription)}
                field={field.prop('aiFeedbackSummaryEmailEnabled')}
                data-test="aiFeedbackSummaryEmailEnabled"
              />
            )}
          </VStack>
        </SectionBox>
      )}
      {venueClass === VenueClassEnum.DINING && !options.useFullDiningBackend && (
        <SectionBox title={formatMessage(internalTeamEmailsMessages.reservationEmailNotifications)}>
          <Label
            primary={formatMessage(internalTeamEmailsMessages.reservationReminderEmailTitle)}
            secondary={formatMessage(internalTeamEmailsMessages.reservationReminderEmailDescription)}
          />
          <PaddedBox mb="lm">
            <VStack spacing="xs">
              <HStack alignItems="center" flexWrap="wrap">
                {formatMessage(internalTeamEmailsMessages.reservationReminderTextFirstLine, {
                  fasterHours: (
                    <SelectContainer>
                      <FormSelect
                        options={prepareHoursOptions(numberOptions)}
                        searchable={false}
                        hideErrorMessage
                        withEmpty
                        hideEmptyOption
                        field={field.prop('reminderCutoff')}
                        data-test="reminderCutoff"
                      />
                    </SelectContainer>
                  ),
                  slowerHours: (
                    <SelectContainer>
                      <FormSelect
                        options={prepareHoursOptions(options.reminderFasterFrequency)}
                        searchable={false}
                        hideErrorMessage
                        withEmpty
                        hideEmptyOption
                        field={field.prop('reminderFasterFrequency')}
                        data-test="reminderFasterFrequency"
                      />
                    </SelectContainer>
                  ),
                })}
              </HStack>
              <HStack alignItems="center" flexWrap="wrap">
                {formatMessage(internalTeamEmailsMessages.reservationReminderTextSecondLine, {
                  hours: (
                    <SelectContainer>
                      <FormSelect
                        options={prepareHoursOptions(options.reminderSlowFrequency)}
                        searchable={false}
                        hideErrorMessage
                        withEmpty
                        hideEmptyOption
                        field={field.prop('reminderSlowFrequency')}
                        data-test="reminderSlowFrequency"
                      />
                    </SelectContainer>
                  ),
                })}
              </HStack>
            </VStack>
          </PaddedBox>
          <Label primary={formatMessage(internalTeamEmailsMessages.weeklySummaryEmailTitle)} />
          <PaddedBox>
            <HStack alignItems="center" flexWrap="wrap">
              {formatMessage(internalTeamEmailsMessages.weeklySummaryEmailTextLine, {
                dow: (
                  <SelectContainer>
                    <FormSelect
                      options={options.weeklySummaryDow}
                      searchable={false}
                      hideErrorMessage
                      withEmpty
                      hideEmptyOption
                      field={field.prop('weeklySummaryDow')}
                      data-test="weeklySummaryDow"
                    />
                  </SelectContainer>
                ),
                tod: (
                  <SelectContainer>
                    <FormSelect
                      options={options.time}
                      searchable={false}
                      hideErrorMessage
                      withEmpty
                      hideEmptyOption
                      field={field.prop('weeklySummaryTod')}
                      data-test="weeklySummaryTod"
                    />
                  </SelectContainer>
                ),
              })}
            </HStack>
          </PaddedBox>
        </SectionBox>
      )}
      {venueClass === VenueClassEnum.DINING && options.otGcEnabled && (
        <SimpleSection
          initiallyCollapsed
          localStorageKey="generalSettingsInternalTeamEmails"
          data-test="internalTeamEmailsSection"
          title={formatMessage(venueSettingsMessages.additionalConfig)}
        >
          <HalfSizeableBox halfsize>
            <ToggleToggler
              title={formatMessage(internalTeamEmailsMessages.resolutionReportTitle)}
              description={formatMessage(internalTeamEmailsMessages.resolutionReportDescription)}
              field={field.prop('resolutionReportSendTimeShow')}
              data-test="resolutionReportSendTimeShow"
              onShow={() =>
                field.array('resolutionReportSendTime').size() === 0 &&
                field.array('resolutionReportSendTime').append({ time: options.time[0]?.id as string })
              }
            >
              <MultiLine
                label={formatMessage(venueSettingsMessages.addAnotherSendTime)}
                fieldArray={field.array('resolutionReportSendTime')}
                data-test="resolutionReportSendTime"
                item={{ time: options.time[0]?.id as string }}
                renderItem={field => (
                  <FormSelect options={options.time} searchable={false} hideErrorMessage field={field.prop('time')} data-test="time" />
                )}
              />
            </ToggleToggler>
          </HalfSizeableBox>
        </SimpleSection>
      )}
    </VStack>
  )
}

function SelectContainer({ children }: PropsWithChildren<BoxProps>) {
  return (
    <Box ml="s" mr="s" width="145px">
      {children}
    </Box>
  )
}
