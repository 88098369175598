import { useMemo } from 'react'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReferredClientEmailConfigurationsFormData = ZodSchema<ReturnType<typeof useReferredClientEmailConfigurationsForm>>
export type ReferredClientEmailConfigurationsFormFields = Field<ReferredClientEmailConfigurationsFormData>

export const useReferredClientEmailConfigurationsForm = () =>
  useMemo(
    () =>
      z.object({
        bookingConfirmationEmail: z.boolean(),
        bookingReminderEmail: z.boolean(),
      }),
    []
  )
