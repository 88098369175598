import { v4 } from 'uuid'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  Checkbox,
  DateRangePicker,
  FormInput,
  Label,
  SingleDatePicker,
  Button,
  FormToggle,
  FormNumberInput,
} from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, DividerLine, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ExperiencesLocales } from '../../Experiences.locales'
import { TheForkDetailsMenu } from './TheForkDetailsMenu'
import type { TheForkDetailsFormData } from './TheForkDetailsFrom.zod'

interface TheForkDetailsProps {
  field: Field<TheForkDetailsFormData>
}
export function TheForkDetails({ field }: TheForkDetailsProps) {
  const { formatMessage } = useLocales()
  const menuController = useController(field.prop('menus'))
  const previewStartDateController = useController(field.prop('allowEarlyMenuPreview'))
  const bookableController = useController(field.prop('bookable'))
  const menus = useWatch(field.prop('menus'))
  const removeMenu = (idx: number) => {
    const menu = [...menuController.field.value]

    menu.splice(idx, 1)
    menuController.field.onChange(menu)
  }

  return (
    <Box>
      <BaseSection title={formatMessage(ExperiencesLocales.theForkDetails)}>
        <VStack p="lm" spacing="lm">
          <HStack spacing="lm">
            <VStack flex="1">
              <Label primary={`${formatMessage(ExperiencesLocales.theForkName)}*`} />
              <FormInput field={field.prop('name')} />
            </VStack>
            <VStack flex="1">
              <Label primary={formatMessage(ExperiencesLocales.price)} />
              <FormNumberInput field={field.prop('price')} />
            </VStack>
          </HStack>
          <HStack spacing="lm" alignItems="center">
            <Flex flex="1">
              <DateRangePicker
                id="the-fork-details-date-range"
                field={field.prop('dateRange')}
                startDateProps={{ appendToBody: false }}
                endDateProps={{ appendToBody: false }}
              />
            </Flex>
            <HStack alignItems="center" spacing="xs" flex="1">
              <VStack alignItems="center">
                <Label primary={formatMessage(ExperiencesLocales.bookStatus)} />
                <FormToggle field={field.prop('bookable')} />
              </VStack>
              {!bookableController.field.value && (
                <Box mt="m">
                  <Text fontStyle="italic">{formatMessage(ExperiencesLocales.notBookable)}</Text>
                </Box>
              )}
            </HStack>
          </HStack>
          <VStack spacing="lm">
            <Checkbox field={field.prop('allowEarlyMenuPreview')} info={<>{formatMessage(ExperiencesLocales.allowEarlyPreviewInfo)}</>}>
              {formatMessage(ExperiencesLocales.allowEarlyPreview)}
            </Checkbox>
            {previewStartDateController.field.value && (
              <Label primary={formatMessage(ExperiencesLocales.previewStartDate)}>
                <Box width="50%" pr="xxs">
                  <SingleDatePicker id="the-fork-details-early-preview-date" field={field.prop('previewStartDate')} appendToBody={false} />
                </Box>
              </Label>
            )}
          </VStack>
          <DividerLine />
          <VStack spacing="lm">
            {menus.map((menu, idx) => (
              <TheForkDetailsMenu
                key={menu.id}
                field={field.prop(`menus.${idx}`)}
                removeMenu={idx !== 0 ? () => removeMenu(idx) : undefined}
              />
            ))}
          </VStack>
          <Box>
            <Button
              data-test="add-menu-course-button"
              onClick={() =>
                menuController.field.onChange([...menuController.field.value, { items: [{ name: null, id: v4() }], id: v4() }])
              }
              variant="tertiary"
              noPadding
            >
              {formatMessage(ExperiencesLocales.addMenuCourse)}
            </Button>
          </Box>
        </VStack>
      </BaseSection>
    </Box>
  )
}
