import { useMemo } from 'react'
import { MarketingCampaignOneTimeSendTypeEnum } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { FormSelect, SingleDatePicker, Radio } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import {
  TextWrapper,
  SelectContainer,
  campaignBuilderMessages,
  emailBuilderMessages,
  getTimeChoices,
  smsBuilderMessages,
} from '@sevenrooms/marketing'
import type { OneTimeSendingScheduleFormField } from './OneTimeSendingScheduleForm.zod'

export interface OneTimeSendingScheduleProps {
  field: OneTimeSendingScheduleFormField
  disabled?: boolean
  startTime?: Date
  endTime?: Date
  isSMSBuilder?: boolean
}

export function OneTimeSendingSchedule({ field, endTime, disabled, startTime, isSMSBuilder }: OneTimeSendingScheduleProps) {
  const { formatMessage } = useLocales()
  const timesChoices = useMemo(() => getTimeChoices(startTime, endTime), [startTime, endTime])

  const isScheduled = useWatch(field.prop('scheduleType')) === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED

  return (
    <BaseSection
      title={formatMessage(campaignBuilderMessages.sendingScheduleTitle)}
      description={formatMessage(
        isSMSBuilder ? smsBuilderMessages.sendingScheduleDescription : emailBuilderMessages.sendingScheduleDescription
      )}
    >
      <Box p="lm">
        <Box>
          <VStack alignItems="start">
            <Box pb="s">
              <Radio
                checked={!isScheduled}
                value={MarketingCampaignOneTimeSendTypeEnum.IMMEDIATELY}
                field={field.prop('scheduleType')}
                data-test="one-time-scheduler-immediately-send-type-radio"
                disabled={disabled}
              >
                {formatMessage(campaignBuilderMessages.immediately)}
              </Radio>
            </Box>
            <Box pb="m">
              <Radio
                checked={isScheduled}
                value={MarketingCampaignOneTimeSendTypeEnum.SCHEDULED}
                field={field.prop('scheduleType')}
                data-test="one-time-scheduler-scheduled-send-type-radio"
                disabled={disabled}
              >
                {formatMessage(campaignBuilderMessages.scheduleForFuture)}
              </Radio>
              {isScheduled && (
                <SelectContainer>
                  <HStack alignItems="start" spacing="xs">
                    <TextWrapper>
                      <Text>&nbsp;{formatMessage(campaignBuilderMessages.sendOn)}&nbsp;</Text>
                    </TextWrapper>
                    <SingleDatePicker disabled={disabled} field={field.prop('scheduledDay')} data-test="one-time-scheduler-date-field" />
                    <TextWrapper>
                      <Text>&nbsp;{formatMessage(commonMessages.at).toLowerCase()}&nbsp;</Text>
                    </TextWrapper>
                    <FormSelect
                      options={timesChoices}
                      placeholder={formatMessage(campaignBuilderMessages.selectTime)}
                      searchable={false}
                      field={field.prop('scheduledTime') as Field<string>}
                      data-test="one-time-scheduler-time-field"
                      disabled={disabled}
                    />
                  </HStack>
                </SelectContainer>
              )}
            </Box>
          </VStack>
        </Box>
      </Box>
    </BaseSection>
  )
}
