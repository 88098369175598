import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { AccessRuleSlideoutSection, Divider, useAccessRuleContext } from '../shared'
import { Note } from '../shared/Note'
import { DurationsCollapsed } from './components/DurationsCollapsed'
import { DurationsDefault } from './components/DurationsDefault'
import { DurationsRadioButtons } from './components/DurationsRadioButtons'
import { FollowShift } from './components/FollowShift'
import { DurationsLocales } from './Durations.locales'
import type { DurationsFieldProps } from './DurationsFieldProps'
import type { GuestDurationPickerForm } from '../GuestDurationPicker/GuestDurationPicker.zod'
import type { PartySizeForm } from '../PartySize/PartySize.zod'

export interface DurationsEditProps extends DurationsFieldProps {
  guestDurationPickerField: Field<GuestDurationPickerForm>
  partySizeField: Field<PartySizeForm>
}

export function Durations({ field, guestDurationPickerField, partySizeField }: DurationsEditProps) {
  const { formatMessage } = useLocales()
  const { guestMustSpecifyDuration } = useWatch(guestDurationPickerField)

  const useShiftDurations = useWatch(field.prop('useShiftDurations'))

  const { min, max } = useWatch(partySizeField)

  const { shifts } = useAccessRuleContext()
  const shiftsHaveBuffers = shifts.some(x => x.bufferMinutesByPartySize && Object.keys(x.bufferMinutesByPartySize).length)

  const defaultSettings = (
    <DurationsDefault showGuestDurationPickerEnabledWarning={guestMustSpecifyDuration} minPartySize={min} maxPartySize={max} />
  )

  return (
    <AccessRuleSlideoutSection
      data-test="access-rules-slideout-section-durations"
      title={formatMessage(DurationsLocales.title)}
      description={formatMessage(DurationsLocales.description)}
      subCaption={formatMessage(DurationsLocales.subCaption)}
      contentWhenCollapsed={<DurationsCollapsed field={field} partySizeField={partySizeField} />}
      defaultSettings={defaultSettings}
      sectionControllerName="durations"
      field={field}
      disableEdit={guestMustSpecifyDuration}
      overrideIsDefault={durations => durations.useShiftDurations}
    >
      <FollowShift field={field.prop('useShiftDurations')}>{defaultSettings}</FollowShift>
      {shiftsHaveBuffers && <Note>{formatMessage(DurationsLocales.buffersNote)}</Note>}
      <Box mt="s" mb="s">
        <Divider />
      </Box>
      {!useShiftDurations && <DurationsRadioButtons field={field} partySizeField={partySizeField} />}
    </AccessRuleSlideoutSection>
  )
}
