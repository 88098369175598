import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export function Note({ children, isWarning }: React.PropsWithChildren<{ isWarning?: boolean }>) {
  return (
    <Box p="m" mt="s" mb="s" borderRadius="s" backgroundColor={isWarning ? 'warningBackground' : 'secondaryBackground'}>
      <Text fontStyle="italic">{children}</Text>
    </Box>
  )
}
