import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './StripeDisconnectDialog.locales'

interface StripeDisconnectDialogProps {
  onClose: () => void
  onSubmit: () => void
}

export function StripeDisconnectDialog({ onClose, onSubmit }: StripeDisconnectDialogProps) {
  const { formatMessage } = useLocales()
  return (
    <Modal ariaLabel="main" width="100%">
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(messages.title)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(messages.body)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onClose} data-test="payment-settings-disconnect-dialog-cancel-btn">
            Cancel
          </Button>
          <Button variant="primary-warning" onClick={onSubmit} data-test="payment-settings-disconnect-dialog-disconnect-btn">
            {formatMessage(messages.disconnect)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
