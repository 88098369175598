import { useMemo } from 'react'
import { useForm, type UseForm, useFormContext, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { FormToggle } from '@sevenrooms/core/ui-kit/form'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingsBlock, SettingsBlockInputs } from '../SettingsBlock'
import { SettingValue } from '../SettingValue'
import { TextInput } from '../TextInput'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './FreedomPay.locales'
import type { PaymentIntegration, PaymentIntegrationConfiguration, PaymentIntegrationSettings } from '../interfaces'

function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()

  return (
    <ViewSettingsBlock>
      <Text textStyle="body1Bold">{formatMessage(messages.accountAndAuthentication)}</Text>
      <SettingValue name={(currentConfiguration?.esKey as string) || '-'} title={formatMessage(messages.esKey)} />
      <SettingValue name={(currentConfiguration?.storeId as string) || '-'} title={formatMessage(messages.storeId)} />
      <SettingValue name={(currentConfiguration?.terminalId as string) || '-'} title={formatMessage(messages.terminalId)} />
      <SettingValue
        name={
          currentConfiguration?.isAppleAndGooglePayEnabled ? formatMessage(commonMessages.enabled) : formatMessage(commonMessages.disabled)
        }
        title={formatMessage(messages.isAppleAndGooglePayEnabled)}
      />
    </ViewSettingsBlock>
  )
}

export function useFreedomPayForm(formDefault?: PaymentIntegrationSettings) {
  const schema = useMemoSchemaFreedomPay() as unknown as z.ZodObject<{
    [key: string]: z.ZodDefault<z.ZodOptional<z.ZodString>> | z.ZodString | z.ZodBoolean
  }>
  return useForm(schema, { defaultValues: formDefault })
}

function SettingsForm() {
  const { formatMessage } = useLocales()
  const { field } = useFormContext() as UseForm<z.ZodType<SchemaFreedomPay>>
  return (
    <>
      <SettingsBlock header={formatMessage(messages.accountAndAuthentication)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('storeId')}
            label={formatMessage(messages.storeId)}
            placeholder={formatMessage(messages.storeId)}
            data-test="payment-settings-fp-storeid-input"
          />
          <TextInput
            field={field.prop('terminalId')}
            label={formatMessage(messages.terminalId)}
            placeholder={formatMessage(messages.terminalId)}
            data-test="payment-settings-fp-terminalid-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('esKey')}
            label={formatMessage(messages.esKey)}
            placeholder={formatMessage(messages.esKey)}
            data-test="payment-settings-fp-eskey-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
      <SettingsBlock header={formatMessage(messages.settings)}>
        <SettingsBlockInputs>
          <SecondaryText>{formatMessage(messages.settingsLabel)}</SecondaryText>
          <FormToggle
            label={formatMessage(messages.isAppleAndGooglePayEnabled)}
            field={field.prop('isAppleAndGooglePayEnabled')}
            data-test="payment-settings-freedompay-apple-and-google-pay-toggle"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
    </>
  )
}

function createSchemaFreedomPay(requiredErrorMessage: string) {
  return z.object({
    esKey: z.string().trim().min(1, requiredErrorMessage).default(''),
    storeId: z.string().trim().min(1, requiredErrorMessage).default(''),
    terminalId: z.string().trim().min(1, requiredErrorMessage).default(''),
    isAppleAndGooglePayEnabled: z.boolean().default(true),
  })
}
type SchemaFreedomPay = z.infer<ReturnType<typeof createSchemaFreedomPay>>

function useMemoSchemaFreedomPay() {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(commonMessages.required)

  return useMemo(() => createSchemaFreedomPay(requiredErrorMessage), [requiredErrorMessage])
}

export const FreedomPay: PaymentIntegration = {
  currentConfiguration: {},
  integrationName: AccountTypes.FREEDOMPAY,
  ShowCurrentSettings,
  SettingsForm,
  formDefault: {},
  useFormHook: useFreedomPayForm,
}
