import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { Tab } from '@sevenrooms/react-components/components/Tab'
import { Tabs } from '@sevenrooms/react-components/components/Tabs'
import { spacesMessages } from '../../spaces.locales'

interface SpacesTabProps {
  value: number
  onTabChange: (_event: React.SyntheticEvent, newValue: number) => void
}

function SpacesTab({ value, onTabChange }: SpacesTabProps) {
  const { formatMessage } = useLocales()

  const tabs: { key: string; title: string }[] = useMemo(
    () => [
      {
        key: 'groupBookingSettings',
        title: formatMessage(spacesMessages.settingsTabTitle),
      },
      {
        key: 'dinewiseOnlySettings',
        title: formatMessage(spacesMessages.dineWiseTabTitle),
      },
    ],
    [formatMessage]
  )

  return (
    <HStack pl="lm" spacing="lm" pb="lm">
      <Tabs value={value} onChange={onTabChange} aria-label="basic tabs example">
        {tabs.map(tab => (
          <Tab key={tab.key} label={tab.title} />
        ))}
      </Tabs>
    </HStack>
  )
}

export { SpacesTab }
