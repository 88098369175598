import { defineMessages } from '@sevenrooms/core/locales'

export const customSmsMessages = defineMessages({
  customSmsTitle: {
    id: 'customSms.title',
    defaultMessage: 'Custom Messages',
  },
  customSmsSaveSuccessMessage: {
    id: 'customSms.save.message.success',
    defaultMessage: 'Successfully saved changes.',
  },
  customSmsSaveErrorMessage: {
    id: 'customSms.save.message.error',
    defaultMessage: 'Unable to save changes. Please try again later or contact customer support.',
  },
  customSmsSaveEmptyValueErrorMessage: {
    id: 'customSms.save.message.error.emptyValue',
    defaultMessage: 'Empty values are not allowed for modified policies.',
  },
  customSmsSaveNonUniqueNameErrorMessage: {
    id: 'customSms.save.message.error.nonUniqueName',
    defaultMessage: 'Custom SMS names must be unique.',
  },
  customSmsDeleteModalTitle: {
    id: 'customSms.delete.modal.title',
    defaultMessage: 'Delete Custom SMS?',
  },
  customSmsDeleteModalMessage: {
    id: 'customSms.delete.modal.message',
    defaultMessage: 'Are you sure you want to delete this custom SMS?',
  },
  customSmsDeleteModalButtonLabel: {
    id: 'customSms.delete.modal.buttonLabel',
    defaultMessage: 'Delete',
  },
  customSmsDeleteSuccessMessage: {
    id: 'customSms.delete.message.success',
    defaultMessage: 'Custom SMS has been successfully deleted.',
  },
  customSmsDeleteErrorMessage: {
    id: 'customSms.delete.message.error',
    defaultMessage: 'There was an error when deleting the promo code. Please try again later or contact customer support.',
  },
  customSmsTableHeaderText: {
    id: 'customSms.table.headerText',
    defaultMessage: 'Custom SMS',
  },
  customSmsTableNamePlaceholderText: {
    id: 'customSms.table.namePlaceholderText',
    defaultMessage: 'Enter Custom SMS Name',
  },
  customSmsTableSmsMessagePlaceholderText: {
    id: 'customSms.table.smsMessagePlaceholderText',
    defaultMessage: 'Enter Custom SMS Message',
  },
  customSmsTableCreateNewLabel: {
    id: 'customSms.table.createNewLabel',
    defaultMessage: '+ Create Custom SMS',
  },
} as const)
