import { useCallback } from 'react'
// eslint-disable-next-line no-restricted-imports
import { FormattedNumber } from 'react-intl'
import { CampaignImageService } from '@sevenrooms/core/api'
import type { VenueProfile, ExperiencesData, ImageObject } from '@sevenrooms/core/domain'
import { useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button, ImageUploader, sizes, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, RelatedSettings, Box, VStack, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { smsBuilderMessages } from '@sevenrooms/marketing'
import { SMSEditor, BodyContentWrapper } from '@sevenrooms/marketing/components/SMSEditor'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { formatList, formatListItem } from '@sevenrooms/mgr-insights/utils'
import { routes } from '@sevenrooms/routes'
import { SMSSegmentCounter } from './SMSSegmentCounter'

interface SMSContentFromScratchProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any
  disabled?: boolean
  venueProfile: VenueProfile
  experiencesData?: ExperiencesData
  isDirty?: boolean
  setGoToOffers?: Function
  campaignId?: string
}
const MEDIA_SIZE_LIMIT_KILOBYTES = 500
const MEDIA_SIZE_BYTES_TO_KILOBYTES = 1000
const MEDIA_SIZE_LIMIT_BYTES = MEDIA_SIZE_LIMIT_KILOBYTES * MEDIA_SIZE_BYTES_TO_KILOBYTES

export function SMSContentFromScratch({
  fields,
  disabled,
  venueProfile,
  experiencesData,
  isDirty,
  setGoToOffers,
  campaignId,
}: SMSContentFromScratchProps) {
  const { formatMessage } = useLocales()
  const { venue, venueKey } = useVenueContext()
  const nav = useNavigation()
  const [messageBody, messageMedia] = useWatchMany(fields, ['messageBody', 'messageMedia'])

  const uploadFile = useCallback(
    async (e?: ImageObject) => {
      if (!e?.fileData) {
        fields.form.setValue('messageMedia', null)
      } else {
        const response = await CampaignImageService.getUploadUrl({ venueKey, campaignType: 'sms' })
        const url = response?.data?.uploadUrl

        if (!url) {
          throw Error('Could not start upload')
        }

        const imageResponse = await CampaignImageService.upload({ url, file: e.fileData })
        fields.form.setValue('messageMedia', [imageResponse?.payload?.content?.image_url])
      }
    },
    [venueKey, fields]
  )

  if (!venue) {
    return null
  }

  return (
    <BaseSection
      title={formatMessage(smsBuilderMessages.messageContentTitle)}
      description={formatMessage(smsBuilderMessages.designYourMessageContent)}
      actions={
        <Button
          variant="secondary"
          data-test="send-test-sms"
          disabled={!(messageBody || messageMedia)}
          onClick={() => {
            nav.push(routes.manager2.marketing.oneTimeSMSCenter.sendTestSMSModal, {
              params: { venueKey },
            })
          }}
        >
          {formatMessage(smsBuilderMessages.smsSendMeATest)}
        </Button>
      }
    >
      <Box pl="lm" pr="lm" pb="lm" mt="lm">
        <VStack mb="lm" width="100%">
          <HStack spacing="lm" alignItems="start">
            <VStack flex={1}>
              <BodyContentWrapper disable={disabled} width={sizes.normal}>
                <SMSEditor
                  field={fields.prop('messageBody')}
                  destination={routes.manager2.marketing.oneTimeSMSCenter.smsBuilderFromScratch}
                  disabled={disabled}
                  experiencesData={experiencesData}
                  venueProfile={venueProfile}
                  isDirty={isDirty}
                  setGoToOffers={setGoToOffers}
                  venue={venue}
                  data-test="smsMessageBody"
                />
              </BodyContentWrapper>
              {venue.isMMSEnabled ? (
                <Flex mt="lm">
                  <ImageUploader
                    label={
                      <Label
                        primary={formatMessage(smsBuilderMessages.mediaUploadLabel)}
                        secondary={formatMessage(smsBuilderMessages.mediaUploadDescription, {
                          limit: <FormattedNumber style="unit" unit="kilobyte" unitDisplay="narrow" value={MEDIA_SIZE_LIMIT_KILOBYTES} />,
                        })}
                      />
                    }
                    data-test="smsMessageMedia"
                    maxSize={MEDIA_SIZE_LIMIT_BYTES}
                    maxSizeUnit="kilobyte"
                    maxSizeBytesToUnit={MEDIA_SIZE_BYTES_TO_KILOBYTES}
                    field={fields.prop('stagedMedia')}
                    onChange={uploadFile}
                    disableEdit
                    variant="normal"
                  />
                </Flex>
              ) : (
                !disabled && <SMSSegmentCounter venueId={venue.id} messageBody={messageBody} campaignId={campaignId} mt="sm" />
              )}
            </VStack>
            <RelatedSettings
              primary={formatMessage(smsBuilderMessages.builderAdviceTitle)}
              secondary={formatMessage(
                venue.isMMSEnabled ? smsBuilderMessages.builderMMSAdviceText : smsBuilderMessages.builderSMSAdviceText,
                {
                  link_to_twilio_compliance: (chunks: string[]) => (
                    <a
                      href="https://support.twilio.com/hc/en-us/articles/360045004974-Forbidden-Message-Categories-in-the-US-and-Canada-Short-Code-Toll-Free-and-Long-Code-"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                  bold: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
                  ul: (chunks: string[]) => formatList(chunks),
                  li: (chunks: string[]) => formatListItem(chunks),
                }
              )}
              size="l"
              data-test="sms-editor-tips"
            />
          </HStack>
        </VStack>
      </Box>
    </BaseSection>
  )
}
