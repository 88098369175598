import { type AccessRule, AccessRuleTimeUnitEnum, type AccessRuleTimeUnitType } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { timeBeforeForm } from '../shared/timeBeforeForm'

export type BookingWindowForm = ZodSchema<typeof bookingWindow>

export const bookingWindow = z.object({
  startTime: timeBeforeForm,
  cutoffTime: timeBeforeForm,
})

export function getInitialBookingWindow({
  accessRule,
  defaultBookingWindow,
}: {
  accessRule?: AccessRule
  defaultBookingWindow: BookingWindowForm
}): BookingWindowForm {
  if (!accessRule) {
    return defaultBookingWindow
  }

  const tier = accessRule.audienceTiers[0]
  const startTime =
    accessRule.audienceTiers.length > 1
      ? {
          count: null,
          unit: AccessRuleTimeUnitEnum.DAYS,
          beforeTime: '0',
        }
      : {
          count: tier?.startNum ?? 90,
          unit: (tier?.startType as AccessRuleTimeUnitType) ?? AccessRuleTimeUnitEnum.HOURS,
          beforeTime: tier?.startHour ?? '0',
        }

  const defaults: BookingWindowForm = {
    startTime,
    cutoffTime: {
      count: accessRule?.cutoffNum ?? 0,
      unit: (accessRule?.cutoffType as AccessRuleTimeUnitType) ?? AccessRuleTimeUnitEnum.MINUTES,
      beforeTime: accessRule?.cutoffHour ?? '0',
    },
  }

  return defaults
}
