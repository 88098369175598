import camelcaseKeys from 'camelcase-keys'
import { type RequestResponse, srGet, throwFetchError } from '@sevenrooms/core/api'
import type { FetchJobsProps, Job } from './types/Jobs.types'

interface FetchJobsResponse {
  // eslint-disable-next-line camelcase
  long_running_state_jobs: Job[]
  // eslint-disable-next-line camelcase
  long_running_state_jobs_count: number
}

export const fetchJobs = async (venueId: string, data: FetchJobsProps) => {
  const url = `/api-yoa/${venueId}/jobs`
  const response = await srGet<FetchJobsResponse>(url, data, { json: true })
    .then(throwFetchError)
    .then((response: RequestResponse<FetchJobsResponse>) => ({
      longRunningStateJobs: camelcaseKeys(response.data.long_running_state_jobs),
      longRunningStateJobsCount: response.data.long_running_state_jobs_count,
    }))
  return response
}
