import { useMemo } from 'react'
import { type UseForm, useForm, useFormContext, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { FormToggle } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { AccountTypes } from '@sevenrooms/payments'
import { SettingsBlock, SettingsBlockInputs } from '../SettingsBlock'
import { SettingValue } from '../SettingValue'
import { TextInput } from '../TextInput'
import { ViewSettingsBlock } from '../ViewSettingsBlock'
import { messages } from './Adyen.locales'
import type { PaymentIntegration, PaymentIntegrationConfiguration, PaymentIntegrationSettings } from '../interfaces'

function ShowCurrentSettings({ currentConfiguration }: { currentConfiguration?: PaymentIntegrationConfiguration }) {
  const { formatMessage } = useLocales()

  return (
    <ViewSettingsBlock>
      <HStack spacing="m" justifyContent="space-between" flexWrap="wrap">
        <VStack spacing="m" flex={1} overflow="auto">
          <Text textStyle="body1Bold">{formatMessage(messages.account)}</Text>
          <SettingValue name={(currentConfiguration?.accountCode as string) || '-'} title={formatMessage(messages.accountCode)} />
          <SettingValue name={(currentConfiguration?.store as string) || '-'} title={formatMessage(messages.store)} />
        </VStack>
        <VStack spacing="m" flex={1} overflow="auto">
          <Text textStyle="body1Bold">{formatMessage(messages.authentication)}</Text>
          <SettingValue
            name={(currentConfiguration?.liveEndpointPrefix as string) || '-'}
            title={formatMessage(messages.liveEndpointPrefix)}
          />
        </VStack>
        <VStack spacing="m" flex={1} overflow="auto">
          <Text textStyle="body1Bold">{formatMessage(messages.webhooks)}</Text>
          <SettingValue name={(currentConfiguration?.webhookUsername as string) || '-'} title={formatMessage(messages.webhookUsername)} />
          <SettingValue name={(currentConfiguration?.webhookPassword as string) || '-'} title={formatMessage(messages.webhookPassword)} />
          <SettingValue name={(currentConfiguration?.webhookHmac as string) || '-'} title={formatMessage(messages.webhookHmac)} />
        </VStack>
        <VStack spacing="m" flex={1} overflow="auto">
          <Text textStyle="body1Bold">{formatMessage(messages.settings)}</Text>
          <SettingValue
            name={currentConfiguration?.isBcmcEnabled ? formatMessage(commonMessages.enabled) : formatMessage(commonMessages.disabled)}
            title={formatMessage(messages.isBcmcEnabled)}
          />
          <SettingValue
            name={currentConfiguration?.isGiropayEnabled ? formatMessage(commonMessages.enabled) : formatMessage(commonMessages.disabled)}
            title={formatMessage(messages.isGiropayEnabled)}
          />
          <SettingValue
            name={currentConfiguration?.isPaypalEnabled ? formatMessage(commonMessages.enabled) : formatMessage(commonMessages.disabled)}
            title={formatMessage(messages.isPaypalEnabled)}
          />
        </VStack>
      </HStack>
    </ViewSettingsBlock>
  )
}

export function useAdyenForm(formDefault?: PaymentIntegrationSettings) {
  const schema = useMemoSchemaAdyen() as unknown as z.ZodObject<{
    [key: string]: z.ZodDefault<z.ZodOptional<z.ZodString>> | z.ZodString | z.ZodDefault<z.ZodBoolean> | z.ZodBoolean
  }>
  return useForm(schema, { defaultValues: formDefault })
}

function SettingsForm() {
  const { formatMessage } = useLocales()
  const { field } = useFormContext() as UseForm<z.ZodType<SchemaAdyen>>

  return (
    <>
      <SettingsBlock header={formatMessage(messages.account)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('accountCode')}
            label={formatMessage(messages.accountCode)}
            description={formatMessage(messages.accountCodeLabel)}
            placeholder={formatMessage(messages.accountCode)}
            data-test="payment-settings-adyen-account-code-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('store')}
            label={formatMessage(messages.store)}
            description={formatMessage(messages.storeLabel)}
            placeholder={formatMessage(messages.store)}
            data-test="payment-settings-adyen-store-input"
            isOptional
          />
        </SettingsBlockInputs>
      </SettingsBlock>

      <SettingsBlock header={formatMessage(messages.authentication)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('apiKey')}
            label={formatMessage(messages.apiKey)}
            description={formatMessage(messages.apiKeyLabel)}
            placeholder={formatMessage(messages.apiKey)}
            data-test="payment-settings-adyen-api-key-input"
          />
          <TextInput
            field={field.prop('liveEndpointPrefix')}
            label={formatMessage(messages.liveEndpointPrefix)}
            description={formatMessage(messages.liveEndpointPrefixLabel)}
            placeholder={formatMessage(messages.liveEndpointPrefix)}
            data-test="payment-settings-adyen-live-endpoint-prefix-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('clientKey')}
            label={formatMessage(messages.clientKey)}
            description={formatMessage(messages.clientKeyLabel)}
            placeholder={formatMessage(messages.clientKey)}
            data-test="payment-settings-adyen-client-key-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>

      <SettingsBlock header={formatMessage(messages.webhooks)}>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('webhookUsername')}
            label={formatMessage(messages.webhookUsername)}
            description={formatMessage(messages.webhookUsernameLabel)}
            placeholder={formatMessage(messages.webhookUsername)}
            data-test="payment-settings-adyen-webhooks-username-input"
          />
          <TextInput
            field={field.prop('webhookHmac')}
            label={formatMessage(messages.webhookHmac)}
            description={formatMessage(messages.webhookHmacLabel)}
            placeholder={formatMessage(messages.webhookHmac)}
            data-test="payment-settings-adyen-webhooks-hmac-input"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <TextInput
            field={field.prop('webhookPassword')}
            label={formatMessage(messages.webhookPassword)}
            description={formatMessage(messages.webhookPasswordLabel)}
            placeholder={formatMessage(messages.webhookPassword)}
            data-test="payment-settings-adyen-webhooks-pass-input"
          />
        </SettingsBlockInputs>
      </SettingsBlock>

      <SettingsBlock header={formatMessage(messages.settings)}>
        <SettingsBlockInputs>
          <SecondaryText>{formatMessage(messages.settingsLabel)}</SecondaryText>
          <FormToggle
            label={formatMessage(messages.isBcmcEnabled)}
            field={field.prop('isBcmcEnabled')}
            data-test="payment-settings-adyen-bcmc-toggle"
          />
          <FormToggle
            label={formatMessage(messages.isPaypalEnabled)}
            field={field.prop('isPaypalEnabled')}
            data-test="payment-settings-adyen-paypal-toggle"
          />
        </SettingsBlockInputs>
        <SettingsBlockInputs>
          <FormToggle
            label={formatMessage(messages.isGiropayEnabled)}
            field={field.prop('isGiropayEnabled')}
            data-test="payment-settings-adyen-giropay-toggle"
          />
        </SettingsBlockInputs>
      </SettingsBlock>
    </>
  )
}

function createSchemaAdyen(requiredErrorMessage: string) {
  return z.object({
    apiKey: z.string().trim().min(1, requiredErrorMessage).default(''),
    accountCode: z.string().trim().min(1, requiredErrorMessage).default(''),
    store: z.string().default(''),
    clientKey: z.string().trim().min(1, requiredErrorMessage).default(''),
    liveEndpointPrefix: z.string().trim().min(1, requiredErrorMessage).default(''),
    webhookUsername: z.string().trim().min(1, requiredErrorMessage).default(''),
    webhookPassword: z.string().trim().min(1, requiredErrorMessage).default(''),
    webhookHmac: z.string().trim().min(1, requiredErrorMessage).default(''),
    isBcmcEnabled: z.boolean().default(false),
    isGiropayEnabled: z.boolean().default(false),
    isPaypalEnabled: z.boolean().default(false),
  })
}
type SchemaAdyen = z.infer<ReturnType<typeof createSchemaAdyen>>

function useMemoSchemaAdyen() {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(commonMessages.required)

  return useMemo(() => createSchemaAdyen(requiredErrorMessage), [requiredErrorMessage])
}

export const Adyen: PaymentIntegration = {
  currentConfiguration: {},
  integrationName: AccountTypes.ADYEN,
  ShowCurrentSettings,
  SettingsForm,
  formDefault: {},
  useFormHook: useAdyenForm,
}
