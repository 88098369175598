import React from 'react'
import styled from 'styled-components'
import VenueDropDown from 'mgr/lib/components/VenueDropDown'
import VenueGroupDropDown from 'mgr/lib/components/VenueGroupDropDown'
import Breadcrumbs from 'svr/component-lib/Manager/Breadcrumb'

const LocationBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutter.medium} ${props => props.theme.gutter.triple} ${props => props.theme.gutter.medium}
    ${props => props.theme.gutter.double};
`

class LocationBar extends React.PureComponent {
  constructor(props) {
    super(props)
    // If switching from react venue portal page
    if (this.props.userDomain && !this.props.userDomain.venueGroups) {
      Pmp.Manager.Global._loadUserDomainVenueGroups(this.props.userDomain.session_key)
      if (!this.props.userDomain.venues) {
        Pmp.Manager.Global._loadUserDomainVenues()
      }
    }
  }

  render() {
    const {
      venue,
      venues,
      onChangeVenue,
      venueGroup,
      userDomain,
      onChangeVenueGroup,
      routes,
      history,
      overrideChangeVenue = false,
      overrideRefreshOnVenueChange = false,
    } = this.props

    return (
      <LocationBarContainer>
        <Breadcrumbs routes={routes} history={history} id="breadcrumbs" data-test="breadcrumbs" />
        {venue && (
          <VenueDropDown
            venueId={venue.id}
            venues={venues}
            onChange={venueNew => {
              const urlSplit = history.location.pathname.split('/')
              urlSplit[2] = venueNew.urlKey

              onChangeVenue(venueNew, routes)

              if (overrideChangeVenue) {
                return
              }

              history.push(`${urlSplit.join('/')}${history.location.search}`)
              if (!overrideRefreshOnVenueChange) {
                window.location.reload()
              }
            }}
            borderAreaStyle={{
              border: '1px solid #dedede',
            }}
            style={{
              marginRight: '0',
            }}
            id="venue-picker"
          />
        )}
        {venueGroup && (
          <VenueGroupDropDown
            venueGroupId={venueGroup.id}
            venueGroups={userDomain && userDomain.venueGroups ? userDomain.venueGroups : [venueGroup]}
            onChange={venueGroupNew => {
              const urlSplit = history.location.pathname.split('/')
              urlSplit[2] = venueGroupNew.id
              if (onChangeVenueGroup) {
                onChangeVenueGroup(venueGroupNew)
              }

              if (overrideChangeVenue) {
                return
              }

              window.location = `${urlSplit.join('/')}${history.location.search}`
            }}
          />
        )}
      </LocationBarContainer>
    )
  }
}

export default LocationBar
