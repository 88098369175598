import React, { useCallback, useState, useEffect } from 'react'
import { BackwriterMessageLoader } from 'mgr/lib/components/BackwriterMessageLoader'
import { BRAND_MAPPER, BrandMap, BrandVmsIconS } from 'svr/common/BrandIcon'
import { useVenue } from 'svr/common/hooks/useVenue'
import { googleReviewApi, type ExternalReview } from '@sevenrooms/core/api'
import { BackwriterPromptType } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales, Locale } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { TextArea, QuickButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, SlideOut, SlideOutSubsection, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useReviewBackwriter } from '@sevenrooms/mgr-core/hooks/useBackwriter'
import { NumRating, StarSlide } from '../../Review'
import { SingleStarRow } from '../StarsData'
import { ConfirmModal } from './ConfirmModal'
import { GoogleReviewSlideoutFooter } from './GoogleReviewSlideoutFooter'
import { googleReviewLocales } from './locales'
import { useReviewReplyFormSchema } from './ReviewReplyFormSchema.zod'
import { SendStatus } from './SendStatus'

export const formatDate = (date: string, locale: string) => DateOnly.from(date).formatNYearSMonthNDay(Locale.getLocale(locale))

interface GoogleReviewSlideoutProps {
  isLinking: boolean
  onClose: () => void
  review: ExternalReview
  setReview: (review: ExternalReview) => void
  showError: (err: string) => void
}

export function GoogleReviewSlideout({ isLinking, onClose, review, setReview, showError }: GoogleReviewSlideoutProps) {
  const { venue } = useVenue()
  const { formatMessage } = useLocales()
  const [errorReason, setErrorReason] = useState<string | undefined>()
  const [tryDelete, setTryDelete] = useState(false)
  const [tryDiscard, setTryDiscard] = useState(false)

  const [replyMutation, { isSuccess: sendSuccess, isError: sendFail, isLoading: sendingReply }] = googleReviewApi.useReplyMutation()
  const [deleteMutation, { isSuccess: deleteSuccess, isLoading: deletingReply }] = googleReviewApi.useDeleteReplyMutation()

  const { field, handleSubmit, setValue, reset, watch } = useForm(useReviewReplyFormSchema(), { mode: 'onChange' })
  const watchReplyText = watch('replyText')

  const { isEnabled, isLoading, generatedText, errorMessage, setGeneratedText, generateResponse } = useReviewBackwriter()

  const [responseHistory, setResponseHistory] = React.useState<string[]>([])
  const generateReviewResponse = (promptType: BackwriterPromptType) => {
    const lastText = responseHistory.slice(-1)[0]
    if (!responseHistory.length || watchReplyText !== lastText) {
      setResponseHistory([...responseHistory, watchReplyText || ''])
    }
    generateResponse?.({ venueId: venue.id, reviewId: review.id, promptType, currentMessage: watchReplyText })
  }

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage)
    }
  }, [showError, errorMessage])

  if (generatedText) {
    setValue('replyText', generatedText)
    setResponseHistory([...responseHistory, generatedText])
    setGeneratedText?.('')
  }

  const undo = useCallback(() => {
    const lastText = responseHistory.slice(-1)[0]
    if (lastText && watchReplyText !== lastText) {
      setValue('replyText', lastText)
    } else if (responseHistory.length > 1) {
      setValue('replyText', responseHistory.slice(-2)[0] || '')
      setResponseHistory(responseHistory.slice(0, -1))
    }
  }, [responseHistory, setValue, watchReplyText])

  const submitReply = useCallback(
    async (formState: { replyText: string }) => {
      replyMutation({
        ...formState,
        reviewId: review.id,
        venueId: venue.id,
      })
        .unwrap()
        .then(reply => {
          setReview({ ...review, reply })
          reset()
        })
        .catch(reason => setErrorReason(reason))
    },
    [replyMutation, reset, review, setReview, venue.id]
  )

  const deleteReply = useCallback(async () => {
    setTryDelete(false)
    deleteMutation({
      reviewId: review.id,
      venueId: venue.id,
    })
      .unwrap()
      .then(() => {
        setReview({ ...review, reply: undefined })
        reset()
      })
      .catch(reason => setErrorReason(reason))
  }, [deleteMutation, reset, review, setReview, venue.id])

  const onBeforeClose = useCallback(() => {
    // only care to show the discard modal if the reply text is not empty
    if (watchReplyText) {
      setTryDiscard(true)
    }
    return !watchReplyText
  }, [watchReplyText])

  const deleteAction = review.reply?.comment ? () => setTryDelete(true) : undefined
  const primaryActionLabel = formatMessage(
    review.reply?.comment ? googleReviewLocales.editReplyButtonLabel : googleReviewLocales.replyButtonLabel
  )

  return (
    <SlideOut
      background="infoBackground"
      enableBackdrop={!isLinking}
      onBeforeClose={onBeforeClose}
      onCloseComplete={onClose}
      show
      title={formatMessage(googleReviewLocales.slideoutTitle)}
      width="398px"
      footer={
        <GoogleReviewSlideoutFooter
          primaryAction={handleSubmit(submitReply)}
          primaryActionLabel={primaryActionLabel}
          deleteAction={deleteAction}
          sendingReply={sendingReply}
        />
      }
    >
      <SlideOutSubsection height="99%">
        <HStack justifyContent="space-between" p="m" height="52px">
          <Box>
            <Text fontWeight="bold">
              <Flex alignItems="center">
                <BrandVmsIconS company={BrandMap.Google} pngSrc={null} />
                <Box ml="m">{BRAND_MAPPER.google}</Box>
              </Flex>
            </Text>
          </Box>
          <Box>
            <Text>
              <StarSlide>
                <NumRating>{review.rating} of 5</NumRating>
                <SingleStarRow numStars={5} starsChecked={review.rating} />
              </StarSlide>
            </Text>
          </Box>
        </HStack>
        <VStack p="m" borderTopColor="borders" justifyContent="space-between" height="calc(100% - 52px)">
          <Box overflow="auto">
            <Text fontSize="s" color="description" data-test="google-review-submitted-by">
              {formatMessage(googleReviewLocales.submittedBy, {
                author: (
                  <Text fontSize="s" color="secondaryFont" fontWeight="bold">
                    {review.author}
                  </Text>
                ),
                date: formatDate(review.date, venue.locale),
              })}
            </Text>
            <Box>
              <Text textStyle="body2" data-test="google-review-notes">
                {review.notes}
              </Text>
            </Box>
            {review.reply?.comment && (
              <>
                <Box ml="m" mt="m" backgroundColor="infoBackground" p="m">
                  <Text fontSize="xs" color="description">
                    <Icon name="VMSWeb-reply" />
                    {formatMessage(googleReviewLocales.repliedDate, {
                      date: formatDate(review.reply.update_time, venue.locale),
                    })}
                  </Text>
                  <Box data-test="google-review-reply">
                    <Text textStyle="body2">{review.reply.comment}</Text>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box textAlign="right" pt="s">
            {isEnabled ? (
              <Box mb="s">
                <QuickButton
                  data-test="google-review-generate-response"
                  icon="VMSWeb-action-stars"
                  onClick={() => generateReviewResponse(BackwriterPromptType.RespondToReview)}
                  disabled={isLoading}
                >
                  Generate a response to review
                </QuickButton>
              </Box>
            ) : null}
            <Box height="96px">
              {isLoading ? (
                <BackwriterMessageLoader />
              ) : (
                <TextArea
                  fullWidth
                  field={field.prop('replyText')}
                  inputMode="text"
                  placeholder={formatMessage(
                    review.reply?.comment ? googleReviewLocales.updateReplyPlaceholder : googleReviewLocales.replyPlaceholder
                  )}
                  resize="none"
                />
              )}
            </Box>
            {isEnabled ? (
              <HStack mt="s" spacing="s">
                <QuickButton
                  data-test="google-review-undo"
                  disabled={responseHistory.length < 2 || isLoading}
                  icon="VMSWeb-action-stars"
                  onClick={undo}
                >
                  Undo
                </QuickButton>
                <QuickButton
                  disabled={!watchReplyText?.length || isLoading}
                  data-test="google-review-rephrase"
                  icon="VMSWeb-action-stars"
                  onClick={() => generateReviewResponse(BackwriterPromptType.Rephrase)}
                >
                  Rephrase
                </QuickButton>
              </HStack>
            ) : null}
            <Text fontSize="xs" color="description">
              <SendStatus
                deletingReply={deletingReply}
                deleteSuccess={deleteSuccess}
                errorReason={errorReason}
                sendingReply={sendingReply}
                sendFail={sendFail}
                sendSuccess={sendSuccess}
              />
            </Text>
          </Box>
        </VStack>
      </SlideOutSubsection>
      <Window active={tryDelete}>
        <ConfirmModal
          confirmLabel={formatMessage(googleReviewLocales.confirmDeleteButtonLabel)}
          description={formatMessage(googleReviewLocales.confirmDeleteReplyDescription)}
          onConfirm={deleteReply}
          onDiscard={() => setTryDelete(false)}
          title={formatMessage(googleReviewLocales.confirmDeleteReplyTitle)}
        />
      </Window>
      <Window active={tryDiscard}>
        <ConfirmModal
          confirmLabel={formatMessage(googleReviewLocales.confirmDiscardButtonLabel)}
          description={formatMessage(googleReviewLocales.confirmDiscardReplyDescription)}
          onConfirm={() => {
            onClose()
            setTryDiscard(false)
          }}
          onDiscard={() => setTryDiscard(false)}
          title={formatMessage(googleReviewLocales.confirmDiscardReplyTitle)}
        />
      </Window>
    </SlideOut>
  )
}
