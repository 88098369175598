import { useState } from 'react'
import FroalaReactEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BorderedBox, SimpleOverlay, Box, Flex, ScalableBox, SlideOut } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { generateEmailCampaignPreviewTemplate } from '@sevenrooms/marketing'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { oneTimeEmailCenterMessages } from '../../locales'
import { EmailTemplatePreviewFooter } from './EmailTemplatePreviewFooter'

export interface EmailTemplatePreviewProps {
  template: EmailCampaignTemplate
  cardBackgroudColor: ThemeColor
  cardHeight: string
  cardWidth: string
  scrollable: boolean
}

export function EmailTemplatePreview({ template, scrollable, cardBackgroudColor, cardHeight, cardWidth }: EmailTemplatePreviewProps) {
  const { formatMessage } = useLocales()
  const [showSlideOut, setShowSlideOut] = useState(false)
  const [showPreviewBtn, setShowPreviewBtn] = useState(false)
  const permissions = useNavigationPermissionsContext()
  const templateString = generateEmailCampaignPreviewTemplate(
    template.greeting ?? '',
    template.emailBodyParts?.join('<br/>') ?? '',
    template.signature ?? '',
    template.signoff ?? '',
    template.footer ?? ''
  )

  return (
    <BorderedBox
      backgroundColor={cardBackgroudColor}
      maxWidth={cardWidth}
      minWidth={cardWidth}
      height={cardHeight}
      maxHeight={cardHeight}
      position="relative"
      overflow={scrollable ? 'scroll' : 'hidden'}
      pt="m"
      pb="m"
      pl="sm"
      pr="sm"
      onMouseEnter={() => setShowPreviewBtn(true)}
      onMouseLeave={() => setShowPreviewBtn(false)}
      data-test="template-preview"
    >
      <Box>
        <ScalableBox scaleWidth>
          <FroalaReactEditorView model={templateString} />
        </ScalableBox>
      </Box>

      <SimpleOverlay
        flexDirection="column"
        justifyContent="center"
        show={showPreviewBtn}
        alignItems="center"
        flexGrow="1"
        onClick={() => {
          setShowSlideOut(true)
          setShowPreviewBtn(false)
        }}
      >
        <Button data-test="email-preview-template">{formatMessage(oneTimeEmailCenterMessages.previewTemplateTitle)}</Button>
      </SimpleOverlay>
      <SlideOut
        title={permissions?.isSuperhero ? template.campaignName : formatMessage(oneTimeEmailCenterMessages.previewTemplateTitle)}
        onCloseComplete={() => setShowSlideOut(false)}
        show={showSlideOut}
        enableAutoFocus
        enableFocusLock
        enableReturnFocus
        enableBackdrop
        width={550}
        footer={<EmailTemplatePreviewFooter templateId={template.id} />}
      >
        <Flex justifyContent="center" width="100%">
          <BorderedBox minHeight="100%" width="100%">
            <ScalableBox scaleWidth updateSize>
              <FroalaReactEditorView model={templateString} />
            </ScalableBox>
          </BorderedBox>
        </Flex>
      </SlideOut>
    </BorderedBox>
  )
}
