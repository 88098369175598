import confetti from '../../assets/confetti.png'

export interface ReferredPerkPreviewProps {
  referredPerkText: string
}

export function ReferredPerkPreview({ referredPerkText }: ReferredPerkPreviewProps) {
  return (
    <table
      style={{
        marginTop: '5px',
        width: '480px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        backgroundColor: '#FFFFFF',
        backgroundImage: `url(${confetti})`,
        backgroundSize: 'cover',
        borderRadius: '4px',
        border: '24px solid #F7F7F7',
      }}
    >
      <tr>
        <td
          style={{
            textAlign: 'center',
            fontSize: '16px',
            fontFamily: 'Avenir-Book, Tahoma, Geneva, sans-serif',
            padding: '24px 40px',
            paddingTop: '40px',
          }}
        >
          <p style={{ fontWeight: 'bold', color: '#272626', backgroundColor: '#FFF' }}>{referredPerkText}</p>
        </td>
      </tr>
    </table>
  )
}
