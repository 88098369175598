import { useMemo } from 'react'
import type {
  AccessRule,
  AccessRuleBundledUpgradeQuantityType,
  PaymentRuleType,
  CancelTransactionType,
  ChargePerType,
  Policy,
  PolicyCategory,
  AccessRuleTimeUnitType,
} from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { useMultiSelectTreeForm, getSelectionByIds, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { chargeSettingsForm } from '../ChargeSettings'
import { timeBeforeForm } from '../shared/timeBeforeForm'
import { PaymentPolicyLocales } from './PaymentPolicy.locales'
import type { AccessRuleDefaultParams, Upsells } from '../../AccessRule.types'

export type PaymentPolicyForm = ZodSchema<typeof usePaymentPolicyForm>
export type CancelChargeType = 'NO_CHARGE' | 'CANCELLATION_NO_SHOW_CHARGE'
export type CancelCutoffChoice = 'ANY_TIME' | 'NEVER' | 'CUTOFF'
export type PartySizeType = 'all' | 'gt'
export interface BundledUpgradesValue {
  price?: number
}

export type ChargeChoicesForm = ZodSchema<typeof chargeChoices>
const chargeChoices = z.object({
  amount: z.number().nullable(),
  chargeType: z.custom<ChargePerType>(),
})

export function usePaymentPolicyForm() {
  const { formatMessage } = useLocales()
  const upgrades = useMultiSelectTreeForm<BundledUpgradesValue>()

  return useMemo(
    () =>
      z
        .object({
          useShiftPaymentAndPolicy: z.boolean(),
          paymentRule: z.custom<PaymentRuleType | 'none'>(),
          bookingCharge: chargeChoices,
          cancelCharge: z.custom<CancelChargeType>(),
          refund: z.custom<Exclude<CancelTransactionType, CancelChargeType>>(),
          cancelCutoffChoice: z.custom<CancelCutoffChoice>(),
          bundledUpgrades: z.array(
            z.object({
              upgrades,
              quantityType: z.custom<AccessRuleBundledUpgradeQuantityType>(),
              quantity: z.number().nullable(),
            })
          ),
          bookingPolicy: z.string().nullable(),
          cancelPolicy: z.string().nullable(),
          customBookingPolicy: z.string().nullable(),
          customCancelPolicy: z.string().nullable(),
          cancelCutoff: timeBeforeForm,
          chargeCutoff: timeBeforeForm,
          setChargeCutoff: z.boolean(),
          autoCharge: chargeChoices,
          partySizeMin: z.number().nullable(),
          partySizeType: z.custom<PartySizeType>(),
          charges: chargeSettingsForm,
        })
        .superRefine((fields, ctx) => {
          if (fields.paymentRule === 'advanced_payment') {
            if (fields.partySizeType === 'gt' && fields.partySizeMin == null) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.partySizeRequired),
                path: ['partySizeMin'],
              })
            }
            if (fields.bookingCharge.amount === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.chargeGt0),
                path: ['bookingCharge.amount'],
              })
            } else if (!fields.bookingCharge.amount) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.chargeRequired),
                path: ['bookingCharge.amount'],
              })
            }
            if (fields.charges.applyGratuity && fields.charges.gratuityType === 'SPECIFIC_GRATUITY' && !fields.charges.gratuityPercent) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.gratuityRequired),
                path: ['gratuityPercent'],
              })
            }
            if (
              fields.charges.applyServiceCharge &&
              fields.charges.serviceChargeType === 'SPECIFIC_SERVICE_CHARGE' &&
              !fields.charges.serviceChargePercent
            ) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.serviceChargeRequired),
                path: ['serviceChargePercent'],
              })
            }
          }
          if (fields.refund === 'PARTIAL_REFUND') {
            if (fields.autoCharge.amount === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.refundGte1),
                path: ['autoCharge.amount'],
              })
            } else if (!fields.autoCharge.amount) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.refundRequired),
                path: ['autoCharge.amount'],
              })
            }
          }
          if (
            (fields.paymentRule !== 'save_for_later' && fields.refund !== 'NO_REFUND') ||
            (fields.paymentRule === 'save_for_later' && fields.cancelCharge !== 'NO_CHARGE')
          ) {
            if (fields.setChargeCutoff && fields.chargeCutoff.count == null) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.cutoffRequired),
                path: ['chargeCutoff.count'],
              })
            }
          }
          if (fields.cancelCutoffChoice === 'CUTOFF' && fields.cancelCutoff.count == null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(PaymentPolicyLocales.cutoffRequired),
              path: ['cancelCutoff.count'],
            })
          }
          if (fields.bundledUpgrades.length > 0) {
            const inConflict = new Set<number>()
            fields.bundledUpgrades.forEach((item, index) => {
              const ids = item.upgrades.map(({ id }) => id)
              fields.bundledUpgrades.forEach((other, otherIndex) => {
                if (index === otherIndex) {
                  return
                }
                if (other.upgrades.some(({ id }) => ids.includes(id))) {
                  inConflict.add(index)
                  inConflict.add(otherIndex)
                }
              })
            })
            inConflict.forEach(index => {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(PaymentPolicyLocales.bundledUpgradesNoOverlapAllowed),
                path: ['bundledUpgrades', index, 'upgrades'],
              })
            })
          }
        }),
    [formatMessage, upgrades]
  )
}

export interface InitialPaymentPolicyParams {
  accessRule?: AccessRule
  currencyCode: string
  policies: Policy[]
  upsells: Upsells
  paymentPolicyDefaults: PaymentPolicyForm
}
export function getInitialPaymentPolicy(params: InitialPaymentPolicyParams): PaymentPolicyForm {
  const { accessRule, paymentPolicyDefaults } = params
  if (accessRule?.useShiftPaymentAndPolicy && accessRule?.selectedAutomaticUpsells.length) {
    return {
      ...paymentPolicyDefaults,
      useShiftPaymentAndPolicy: true,
      bundledUpgrades: getBundledUpgrades(params),
    }
  } else if (accessRule?.useShiftPaymentAndPolicy) {
    return paymentPolicyDefaults
  }

  const bookingPolicyId = accessRule?.bookingPolicyId ?? paymentPolicyDefaults.bookingPolicy

  const paymentRule = accessRule?.ccPaymentRule ?? 'none'
  const bookingPolicy = accessRule?.policyType === 'custom' ? 'custom' : bookingPolicyId
  const customBookingPolicy = accessRule?.policy ?? null
  const cancelRefundFields = getCancelRefundFields(accessRule)

  let cancelCutoffChoice: CancelCutoffChoice = 'ANY_TIME'
  let cancelCutoffType: AccessRuleTimeUnitType = 'HOURS'
  if (accessRule?.cancelCutoffType === 'NEVER') {
    cancelCutoffChoice = 'NEVER'
  } else if (accessRule?.cancelCutoffType != null) {
    cancelCutoffChoice = 'CUTOFF'
    cancelCutoffType = accessRule.cancelCutoffType
  }

  const { bookingCharge, partySizeMin, partySizeType, charges, cancelPolicy, customCancelPolicy } =
    paymentRule === 'none' ? paymentPolicyDefaults : getPaymentFields(paymentPolicyDefaults, accessRule)

  return {
    useShiftPaymentAndPolicy: accessRule?.useShiftPaymentAndPolicy ?? true,
    paymentRule,
    bundledUpgrades: getBundledUpgrades(params),
    bookingPolicy,
    cancelPolicy,
    customBookingPolicy,
    customCancelPolicy,
    cancelCutoffChoice,
    cancelCutoff: {
      count: accessRule?.cancelCutoffNum ?? null,
      unit: cancelCutoffType,
      beforeTime: accessRule?.cancelCutoffHour ?? '0',
    },
    ...cancelRefundFields,
    bookingCharge,
    partySizeMin,
    partySizeType,
    charges,
  }
}

function getBundledUpgrades({ accessRule, upsells, currencyCode }: InitialPaymentPolicyParams) {
  const options = getMultiSelectOptions(upsells, currencyCode)
  return (
    accessRule?.selectedAutomaticUpsells.map(({ ids, quantity, quantityType }) => ({
      upgrades: getSelectionByIds(options, ids),
      quantity,
      quantityType,
    })) ?? []
  )
}

function getCancelRefundFields(
  accessRule?: AccessRule
): Pick<PaymentPolicyForm, 'autoCharge' | 'cancelCharge' | 'refund' | 'setChargeCutoff' | 'chargeCutoff'> {
  const cancelCharge =
    accessRule?.ccPaymentRule === 'save_for_later' && isCancelChargeType(accessRule.autoChargeType)
      ? accessRule.autoChargeType
      : 'NO_CHARGE'
  const refund =
    accessRule?.ccPaymentRule !== 'save_for_later' && !isCancelChargeType(accessRule?.autoChargeType)
      ? accessRule?.autoChargeType ?? 'NO_REFUND'
      : 'NO_REFUND'
  return {
    cancelCharge,
    refund,
    chargeCutoff: {
      count: accessRule?.autoCutoffNum ?? null,
      unit: accessRule?.autoCutoffType ?? 'HOURS',
      beforeTime: accessRule?.autoCutoffHour ?? '0',
    },
    setChargeCutoff: !!accessRule?.autoCutoffNum,
    autoCharge: {
      amount: accessRule?.autoChargeAmount ?? null,
      chargeType: accessRule?.autoChargeAmountType ?? 'person',
    },
  }
}

function getPaymentFields(
  paymentPolicyDefaults: PaymentPolicyForm,
  accessRule?: AccessRule
): Pick<PaymentPolicyForm, 'bookingCharge' | 'partySizeMin' | 'partySizeType' | 'charges' | 'cancelPolicy' | 'customCancelPolicy'> {
  const cancelPolicyId = accessRule?.paymentPolicyId ?? paymentPolicyDefaults.cancelPolicy

  return {
    cancelPolicy: accessRule?.cancellationPolicyType === 'custom' ? 'custom' : cancelPolicyId,
    customCancelPolicy: accessRule?.cancellationPolicy ?? null,
    bookingCharge: {
      amount: accessRule?.ccCost ?? null,
      chargeType: accessRule?.ccChargeType ?? 'person',
    },
    partySizeMin: accessRule?.ccPartySizeMin ?? null,
    partySizeType: accessRule?.ccPartySizeMin != null ? 'gt' : 'all',
    charges: {
      applyServiceCharge: accessRule?.applyServiceCharge ?? false,
      serviceChargeType: accessRule?.serviceChargeType ?? 'DEFAULT_SERVICE_CHARGE',
      applyTax: accessRule?.ccApplyTaxRate ?? false,
      taxId: accessRule?.taxGroupId ?? null,
      applyGratuity: accessRule?.applyGratuityCharge ?? false,
      serviceChargePercent: accessRule?.serviceCharge ?? null,
      gratuityType: accessRule?.gratuityType ?? 'DEFAULT_GRATUITY',
      gratuityPercent: accessRule?.ccGratuity ?? null,
      requireGratuity: accessRule?.requireGratuityCharge ?? false,
    },
  }
}

export function partitionPolicies(policies: Policy[], firstCategory: PolicyCategory): [Policy[], Policy[]] {
  // sort mutates the list...
  return [...policies]
    .sort((lhs, rhs) => lhs.created.valueOf() - rhs.created.valueOf())
    .reduce(
      ([match, rest], policy) => (policy.policyCategory === firstCategory ? [[...match, policy], rest] : [match, [...rest, policy]]),
      [[], []] as [Policy[], Policy[]]
    )
}

export function getMultiSelectOptions(upsells: Upsells, currencyCode: string): TreeNode<BundledUpgradesValue>[] {
  return Object.entries(upsells.categories).map(([id, { label }]) => {
    const items = upsells.inventory.filter(({ categoryId }) => categoryId === id)
    const children = items.map(item => ({
      id: item.id,
      label: `${item.name} - ${Intl.NumberFormat(TimeLocale.getLocale(), { style: 'currency', currency: currencyCode }).format(
        item.price
      )}`,
      value: { price: item.price },
    }))

    return {
      id,
      label,
      value: {
        price: children.map(item => item.value.price).reduce((acc, curr) => acc + curr, 0),
      },
      expanded: true,
      children,
    }
  })
}

export function getPaymentPolicyDefaults({
  defaultBookingPolicyId,
  defaultCancelPolicyId,
  policies,
}: AccessRuleDefaultParams): PaymentPolicyForm {
  const [bookingPolicies] = partitionPolicies(policies, 'BOOKING')

  return {
    useShiftPaymentAndPolicy: true,
    paymentRule: 'none',
    cancelCharge: 'NO_CHARGE',
    refund: 'NO_REFUND',
    cancelCutoffChoice: 'ANY_TIME',
    bundledUpgrades: [],
    bookingPolicy: defaultBookingPolicyId === 'default' ? bookingPolicies[0]?.id || '' : defaultBookingPolicyId,
    cancelPolicy: defaultCancelPolicyId,
    customBookingPolicy: null,
    customCancelPolicy: null,
    cancelCutoff: {
      count: null,
      unit: 'HOURS',
      beforeTime: '0',
    },
    chargeCutoff: {
      count: null,
      unit: 'HOURS',
      beforeTime: '0',
    },
    setChargeCutoff: false,
    autoCharge: {
      amount: null,
      chargeType: 'person',
    },
    bookingCharge: {
      amount: null,
      chargeType: 'person',
    },
    partySizeMin: null,
    partySizeType: 'all',
    charges: {
      applyServiceCharge: false,
      serviceChargeType: 'DEFAULT_SERVICE_CHARGE',
      applyTax: false,
      taxId: null,
      applyGratuity: false,
      serviceChargePercent: null,
      gratuityType: 'DEFAULT_GRATUITY',
      gratuityPercent: null,
      requireGratuity: false,
    },
  }
}

function isCancelChargeType(chargeType?: CancelTransactionType): chargeType is CancelChargeType {
  return chargeType === 'NO_CHARGE' || chargeType === 'CANCELLATION_NO_SHOW_CHARGE'
}
