import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import StarsDataView from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'

const SummaryBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`

const SummaryTitle = styled.h1`
  margin-top: 0;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${props => props.theme.gutter.double};
`

const MessageContainer = styled.div`
  display: flex;
  border-top: 1px solid ${props => props.theme.color.greyMuted};
  padding: ${props => props.theme.gutter.double};
  color: ${props => props.theme.color.grey};
  font-size: 14px;
`

const EmptyStateScreen = styled.div`
  position: absolute;
  opacity: 0.5;
  background-color: ${props => props.theme.color.greyMuted};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

// TODO split out animation into some kind of mixin?
const BlockingBanner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: ${props => props.theme.color.grey};
  font-size: 14px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0;
    }
    100% {
      background-position: 200% 100%;
    }
  }

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;

  background: ${props => props.theme.color.lightGrey};
  background-image: linear-gradient(
    to right,
    ${props => props.theme.color.lightGrey} 0%,
    ${props => props.theme.color.default} 20%,
    ${props => props.theme.color.lightGrey} 40%,
    ${props => props.theme.color.lightGrey} 100%
  );
  background-repeat: no-repeat;
  background-position: -800px 0;
  background-size: 800px 100%;
`

class SummaryBar extends React.PureComponent {
  render() {
    const { hasData, isLoading, title, children, rebuildState, additionalMessage } = this.props

    return (
      <SummaryBarContainer>
        {!hasData && <EmptyStateScreen />}
        <InnerContainer>
          <SummaryTitle>{title}</SummaryTitle>
          {isLoading ? <div>Loading...</div> : children}
        </InnerContainer>
        {rebuildState && rebuildState.is_rebuild_running ? (
          <div>
            <BlockingBanner>
              <div>Applying changes from {moment.utc(rebuildState.start_timestamp).local().format('MMMM Do, YYYY h:mm A')}</div>
              <div>{rebuildState.rebuild_percentage}% complete</div>
            </BlockingBanner>
          </div>
        ) : additionalMessage ? (
          <MessageContainer>
            <div>{additionalMessage}</div>
          </MessageContainer>
        ) : null}
      </SummaryBarContainer>
    )
  }
}

export default SummaryBar
