import { useSelector } from 'react-redux'

export const useVenue = () => {
  const venue = useSelector(state => state.appState.venue)

  return {
    venueId: venue.id,
    venue,
  }
}
