import React, { useMemo } from 'react'
import type { SimphonyLocation, SimphonyRevenueCenter } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormMultiSelect, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseLocationSelectionCard } from '../BaseLocationSelectionCard'
import { locationSelectOption } from '../LocationSelectionCard'
import { messages } from './SimphonyLocationSelectionCard.locales'
import type { SimphonyLocationSelectionCardFormField } from './SimphonyLocationSelectionCardForm.zod'

export interface SimphonyLocationSelectionCardProps {
  field: SimphonyLocationSelectionCardFormField
  locations: SimphonyLocation[]
  revenueCenters: SimphonyRevenueCenter[]
  selectedLocationId: string
}

export function SimphonyLocationSelectionCard({
  field,
  locations,
  revenueCenters,
  selectedLocationId,
}: SimphonyLocationSelectionCardProps) {
  const { formatMessage } = useLocales()

  // Build location select options
  const locationSelectOptions = useMemo(() => {
    const locationSelectOptions: SelectOption[] = []
    locations.forEach(location => {
      locationSelectOptions.push(locationSelectOption(location))
    })
    return locationSelectOptions
  }, [locations])

  // Build revenue center select options
  const revenueCenterSelectOptions = useMemo(() => {
    const revenueCentersSelectOptions: SelectOption[] = []
    revenueCenters.forEach(revenueCenter => {
      revenueCentersSelectOptions.push(locationSelectOption(revenueCenter))
    })
    return revenueCentersSelectOptions
  }, [revenueCenters])

  // Disable revenue center selections if a location is not selected
  const isRevenueCenterSelectDisabled = useMemo(() => !selectedLocationId, [selectedLocationId])
  return (
    <BaseLocationSelectionCard docLink={posiMetaDataMap.SIMPHONY.docLink}>
      <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
        <Label width="50%" primary={formatMessage(messages.locationIdLabel)}>
          <FormSelect field={field.prop('locationId')} options={locationSelectOptions} hideErrorMessage />
        </Label>
        <Label width="50%" primary={formatMessage(messages.checkPollingRvcsLabel)}>
          <FormMultiSelect
            options={revenueCenterSelectOptions}
            field={field.prop('checkPollingRvcs')}
            disabled={isRevenueCenterSelectDisabled}
            hideErrorMessage
            placeholder={formatMessage(
              isRevenueCenterSelectDisabled ? messages.checkPollingRvcsDisabledPlaceholder : messages.checkPollingRvcsPlaceholder
            )}
            searchable
          />
        </Label>
      </HStack>
    </BaseLocationSelectionCard>
  )
}
