import { useMemo } from 'react'
import { type AdditionalConfig, ContactInfoEnum } from '@sevenrooms/core/domain'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'
import { usePartySizeRangePickerForm } from '../components/PartySizeRangePicker'

export type ContactInfoDiningValues = InferZodSchema<typeof useContactInfoDiningValues>
const useContactInfoDiningValues = createZodSchema(z =>
  z.union([
    z.literal(ContactInfoEnum.phone),
    z.literal(ContactInfoEnum.email),
    z.literal(ContactInfoEnum.phoneoremail),
    z.literal(ContactInfoEnum.lastname),
    z.literal(ContactInfoEnum.salutation),
  ])
)

export type AdditionalConfigValues = InferZodSchema<typeof useAdditionalConfigValues>
const useAdditionalConfigValues = createZodSchema(z => z.custom<AdditionalConfig>())

export type ReservationsDiningForm = InferZodSchema<typeof useReservationsDiningForm>
export const useReservationsDiningForm = createZodSchema(z => {
  const contactInfoDiningValues = useContactInfoDiningValues()
  const additionalConfigValues = useAdditionalConfigValues()
  const priorityAlertsPartySize = usePartySizeRangePickerForm()
  const multiSelectTagOptionForm = useMultiSelectTagOptionForm()

  return useMemo(
    () =>
      z.object({
        contactInfo: z.array(contactInfoDiningValues),
        additionalConfig: z.array(additionalConfigValues),
        defaultSeatingArea: z.string(),
        showCovers: z.boolean().optional(),
        maxWaitTime: z.string(),
        withInterval: z.string(),
        multiReservation: z.boolean(),
        multiReservationOption: z.string(),
        autoRemoveTiming: z.string(),
        priorityAlertsEnabled: z.boolean(),
        priorityAlertsQuietHoursEnabled: z.boolean(),
        priorityAlertsTieringEnabled: z.boolean(),
        priorityAlertsTierOneTags: multiSelectTagOptionForm,
        priorityAlertsTierTwoTags: multiSelectTagOptionForm,
        priorityAlertsPartySize,
        autoConfirmAllowed: z.boolean().optional(),
        autoConfirm: z.string().optional(),
        showKeepOrRemoveAccessRule: z.boolean(),
      }),
    [z, contactInfoDiningValues, additionalConfigValues, multiSelectTagOptionForm, priorityAlertsPartySize]
  )
})
