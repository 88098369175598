import React from 'react'
import { AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import { Tier, TierTitle, TierContent } from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import BaseTier from './BaseTier'

export class MissedVisitsTier extends BaseTier {
  constructor(props) {
    super(props)
  }

  getContent() {
    const type = this.props.tier.tier_type
    switch (type) {
      case AutotagTierType.NO_SHOW:
        return <div>Tag guests who didn't show up for their reservation in the last 7 days.</div>
      case AutotagTierType.WAITLIST_ABANDON:
        return <div>Tag guests who abandoned waitlist (canceled or didn't show up) in the last 7 days.</div>
      case AutotagTierType.CANCELLATION:
        return <div>Tag guests who canceled their reservation in the last 7 days.</div>
      default:
        return null
    }
  }

  render() {
    const name = this.props.tier.name_display
    const { isActive, id } = this.props

    return (
      <div id={id}>
        <Tier onClick={this.toggleEditing} isActive={isActive}>
          <TierTitle>{name}</TierTitle>
          <TierContent>{this.getContent()}</TierContent>
          {this.fetchAdditionalOptions()}
        </Tier>
      </div>
    )
  }
}

export default MissedVisitsTier
