export const SharedTestId = {
  defaultSettingsDone: 'default-settings-done',
  defaultSettingsCancel: 'default-settings-cancel',
  defaultSettingsModal: 'default-settings-modal',
  defaultSettingsYes: 'default-settings-yes',
  defaultSettingsIcon: 'default-settings-icon',

  collapsedContent: 'collapsed-content',
  defaultSettings: 'default-settings',
  editButton: 'edit-button',
  restoreDefaultSettings: 'restore-default-settings',
  cancelButton: 'cancel-button',
  doneButton: 'done-button',
  missingPayments: 'missing-payments',

  statusEdited: 'status-edited',
  statusRestoredDefaults: 'status-restored-defaults',
  subsectionCollapse: 'subsection-collapse',

  timeBeforeCount: 'time-before-count',
  timeBeforeUnit: 'time-before-unit',
  timeBeforeTime: 'time-before-time',
  timeBeforeError: 'time-before-error',

  cutoffTimeWarning: 'cutoff-time-warning',

  // subsectionClickableHeader: 'ui-kit-slideout-subsection-clickable
  // defaultSettingsClose: 'ui-kit-modal-close',
} as const
