import { platformFilters, ratingFilters, feedbackTypeFilters } from 'mgr/pages/single-venue/marketing/reducers/Filters'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeReviewsFiltersParams = state => ({
  configuredReviewSites: state.appState.venueSettings.guest_satisfaction_configured_review_sites,
  filters: state.reviews.filters,
  total: state.reviews.data.total,
})

const computeReviewsFiltersValues = ({ filters, total, configuredReviewSites }) => {
  const platform =
    filters.platform === 'all' ? platformFilters(configuredReviewSites).map(filter => filter.value) : filters.platform.split(',')
  const rating = filters.rating === 'all' ? ratingFilters.map(filter => filter.value) : filters.rating.split(',')
  const feedbackType = filters.feedbackType === 'all' ? feedbackTypeFilters.map(filter => filter.value) : filters.feedbackType.split(',')
  return {
    values: {
      platform,
      rating,
      feedbackType,
      date: filters.date,
      search: filters.search,
      page: filters.page,
      pageSize: filters.pageSize,
      pages: Math.ceil(total / filters.pageSize),
      sortKey: filters.sortKey,
      sortDirection: filters.sortDirection,
    },
  }
}

export const selectReviewsFiltersValues = createShallowEqualSelector(computeReviewsFiltersParams, computeReviewsFiltersValues)
