import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Banner, Box, Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { marketingHomeMessages } from '../locales'

export interface TrialDaysRemainingBannerProps {
  trialDaysRemaining: number
}

export function TrialDaysRemainingBanner({ trialDaysRemaining }: TrialDaysRemainingBannerProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack alignItems="center">
      <Flex flexShrink={0}>
        <Banner
          canDismiss={false}
          type="attention"
          title={formatMessage(marketingHomeMessages.trialDaysRemainingBannerTitle)}
          description={formatMessage(marketingHomeMessages.trialDaysRemainingBannerDescription)}
          icon={<Icon name="VMSWeb-calendar" size="2x" />}
        />
      </Flex>
      <HStack backgroundColor="highlight" alignSelf="stretch" width="100%" alignItems="center">
        <Box pr="l" textAlign="right" width="100%">
          <Text fontSize="xxl">{formatMessage(marketingHomeMessages.trialDaysRemainingBannerDaysRemaining, { trialDaysRemaining })}</Text>
        </Box>
      </HStack>
    </HStack>
  )
}
