import * as React from 'react'
import { useGetRecommendedIntegrationsQuery, type SellingPoint } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack, Box, Loader, NotificationBanner } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { GeneralDocumentationLink } from '../../Integrations'
import { AdditionalPaymentIntegrationCard } from '../AdditionalPaymentIntegrationCard'
import { AdditionalPaymentIntegrations } from '../AdditionalPaymentIntegrations'
import { DocumentationBanner } from '../DocumentationBanner'
import { RecommendedIntegration } from '../RecommendedIntegrationList'
import { messages } from './PaymentIntegrations.locales'

export function integrationCards(providedSellingPoints: SellingPoint[]) {
  const cards = []
  for (const item of providedSellingPoints) {
    cards.push(<AdditionalPaymentIntegrationCard provider={item.gateway} key={item.gateway} providerData={item.features || []} />)
  }
  return cards
}

export function PaymentIntegrations({ venueId }: { venueId: string }) {
  const { data: paymentIntegrations, isFetching } = useGetRecommendedIntegrationsQuery({ venueId })
  const { formatMessage } = useLocales()
  if (isFetching) {
    return <Loader />
  }
  if (!paymentIntegrations) {
    return null
  }
  if (!paymentIntegrations.suggestedProvider.gateway) {
    return (
      <Box>
        <VStack spacing="l" flexWrap="wrap" p="m">
          <NotificationBanner type="warning" showIcon content={formatMessage(messages.noGateways)} />
        </VStack>
      </Box>
    )
  }
  const additionalIntegrationsCards = integrationCards(paymentIntegrations.sellingPoints)

  return (
    <>
      <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
      <SettingsPageContent
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description, {
          a: (chunks: string[]) => <Anchor href={GeneralDocumentationLink}>{chunks}</Anchor>,
        })}
        secondHeaderMaxWidth="968px"
      >
        <Box maxWidth="968px">
          <VStack spacing="l" flexWrap="wrap" p="m">
            <RecommendedIntegration integration={paymentIntegrations.suggestedProvider} />
            {paymentIntegrations.sellingPoints.length > 0 && (
              <AdditionalPaymentIntegrations>{additionalIntegrationsCards}</AdditionalPaymentIntegrations>
            )}
            <DocumentationBanner />
          </VStack>
        </Box>
      </SettingsPageContent>
    </>
  )
}
