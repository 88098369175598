import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'

export interface ConfirmModalProps {
  confirmLabel: string
  description: string
  onConfirm: () => void
  onDiscard: () => void
  title: string
}

export function ConfirmModal({ confirmLabel, description, onConfirm, onDiscard, title }: ConfirmModalProps) {
  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={onDiscard}>
        <VStack spacing="s">
          <Status kind="warning">{title}</Status>
          <ModalTitle title={description} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button data-test="confirm-modal-cancel-button" variant="tertiary" onClick={onDiscard}>
            Cancel
          </Button>
          <Button data-test="confirm-modal-confirm-button" variant="primary-warning" onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
