import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { Note } from '../../shared/Note'
import { MAX_PARTY_SIZE, type PartySizeKey } from '../Constants'
import { DurationsLocales } from '../Durations.locales'
import { useBookablePartySizeRange } from '../utils'
import type { PartySizeForm } from '../../PartySize/PartySize.zod'
import type { DurationsFieldProps } from '../DurationsFieldProps'

interface DurationsByPartySizeFieldsProps extends DurationsFieldProps {
  partySizeField: Field<PartySizeForm>
}

export function DurationsByPartySizeFields({ field, partySizeField }: DurationsByPartySizeFieldsProps) {
  const { formatMessage } = useLocales()

  const { minPartySize, maxPartySize, partySizeIsRestricted } = useBookablePartySizeRange(partySizeField)

  const partySizeDisplayGroups = [
    { left: 1, right: 6 },
    { left: 2, right: 7 },
    { left: 3, right: 8 },
    { left: 4, right: 9 },
    { left: 5, right: 10 },
  ]

  return (
    <VStack spacing="m">
      {partySizeDisplayGroups.map(group => (
        <HStack key={group.left} width="100%" justifyContent="space-between">
          <DurationForPartySizeField partySize={group.left} field={field} min={minPartySize} max={maxPartySize} />
          <DurationForPartySizeField partySize={group.right} field={field} min={minPartySize} max={maxPartySize} />
        </HStack>
      ))}
      {partySizeIsRestricted && <Note>{formatMessage(DurationsLocales.partySizeNote)}</Note>}
    </VStack>
  )
}

interface DurationForPartySizeFieldProps extends DurationsFieldProps {
  partySize: number
  min: number | null
  max: number | null
}

function DurationForPartySizeField({ partySize, field, min, max }: DurationForPartySizeFieldProps) {
  const { formatMessage } = useLocales()
  const durationOptions = useDurationOptions()

  const durationsByPartySizeField = field.prop('durationsByPartySize')

  const disabled = Boolean(min && partySize < min) || Boolean(max && partySize > max)

  return (
    <HStack spacing="m">
      <VStack minWidth="70px" justifyContent="center">
        <Text>{formatMessage(DurationsLocales.numGuests, { partySize, plusGreaterThan: partySize === MAX_PARTY_SIZE })}</Text>
      </VStack>
      <FormSelect
        withEmpty
        field={durationsByPartySizeField.prop(partySize.toString() as PartySizeKey)}
        displayValue={disabled ? '' : undefined}
        options={durationOptions}
        searchable={false}
        data-test={`access-rule-duration-party-size-${partySize}`}
        disabled={disabled}
      />
    </HStack>
  )
}
