import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CoreLocales } from '@sevenrooms/mgr-core/core.locales'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { Note } from '../../shared/Note'
import { MAX_PARTY_SIZE } from '../Constants'
import { DurationsLocales } from '../Durations.locales'
import { getDurationsForPartySizeRanges } from '../utils'

interface DurationsDefaultProps {
  showGuestDurationPickerEnabledWarning: boolean
  minPartySize: number | null
  maxPartySize: number | null
}

export function DurationsDefault({ showGuestDurationPickerEnabledWarning, minPartySize, maxPartySize }: DurationsDefaultProps) {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()

  const ruleRestrictsPartySize =
    Boolean(minPartySize && minPartySize > Math.min(...shifts.map(x => x.minPartySize))) ||
    Boolean(maxPartySize && maxPartySize < Math.max(...shifts.map(x => x.maxPartySize)))

  const someShiftHasTimeRanges = shifts.some(x => x.durationDictRanges && Object.keys(x.durationDictRanges).length > 1)

  return (
    <>
      {showGuestDurationPickerEnabledWarning && <Note isWarning>{formatMessage(DurationsLocales.guestDurationPickerEnabledWarning)}</Note>}
      <DefaultSettingsReportSection>
        <Report>
          <ReportPart
            caption={[
              someShiftHasTimeRanges
                ? formatMessage(DurationsLocales.headerShiftTimeRanges, {
                    span: (chunks: string[]) => <Text color="secondaryFont">{chunks}</Text>,
                  })
                : formatMessage(DurationsLocales.headerShift),
              formatMessage(DurationsLocales.headerPartySize),
              formatMessage(DurationsLocales.headerPartyDuration),
            ]}
          >
            {shifts.map(shift => (
              <ShiftDurationDefaults key={shift.id} shift={shift} minPartySize={minPartySize} maxPartySize={maxPartySize} />
            ))}
          </ReportPart>
        </Report>
      </DefaultSettingsReportSection>
      {ruleRestrictsPartySize && <Note>{formatMessage(DurationsLocales.partySizeNote)}</Note>}
    </>
  )
}

interface ShiftDurationDefaultsProps {
  shift: Shift
  minPartySize: number | null
  maxPartySize: number | null
}

function ShiftDurationDefaults({ shift, minPartySize, maxPartySize }: ShiftDurationDefaultsProps) {
  const { formatMessage } = useLocales()

  const shiftMaxPartySize = Math.min(MAX_PARTY_SIZE, shift.maxPartySize)
  const enabledMin = minPartySize ?? shift.minPartySize
  const enabledMax = maxPartySize ?? shiftMaxPartySize

  const hasTimeRanges = shift.durationDictRanges && Object.keys(shift.durationDictRanges).length > 1

  const allRanges =
    hasTimeRanges && shift.durationDictRanges
      ? Object.entries(shift.durationDictRanges).map(([startTime, durationMinutesByPartySize]) => ({
          header: <Text color="secondaryFont">{startTime}</Text>,
          ranges: getDurationsForPartySizeRanges(shift.minPartySize, shiftMaxPartySize, enabledMin, enabledMax, durationMinutesByPartySize),
        }))
      : [
          {
            header: shift.name,
            ranges: getDurationsForPartySizeRanges(
              shift.minPartySize,
              shiftMaxPartySize,
              enabledMin,
              enabledMax,
              shift.durationMinutesByPartySize
            ),
          },
        ]

  const minBufferPartySize = shift.bufferMinutesByPartySize
    ? Math.min(...Object.keys(shift.bufferMinutesByPartySize).map(x => parseInt(x)))
    : undefined
  const buffer = minBufferPartySize ? shift.bufferMinutesByPartySize?.[minBufferPartySize] : undefined

  return (
    <>
      {hasTimeRanges && (
        <ReportLine key={`${shift.id}-header`} name={shift.name}>
          <></>
        </ReportLine>
      )}
      {allRanges.map(({ header, ranges }) =>
        ranges.map((range, index) => {
          const textColor = range.enabled ? undefined : 'secondaryFont'

          return (
            <ReportLine key={`${shift.id}-${range.start}-${range.end}`} name={index === 0 ? header : null}>
              <Text color={textColor}>
                {range.start !== range.end ? (
                  <>
                    {formatMessage(DurationsLocales.partySizeRange, {
                      start: range.start,
                      end: range.end,
                      plusGreaterThan: range.plusGreaterThan,
                    })}
                  </>
                ) : (
                  formatMessage(DurationsLocales.partySizeRangeSingle, { partySize: range.start, plusGreaterThan: range.plusGreaterThan })
                )}
              </Text>
              <Text color={textColor}>
                {range.duration ? formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(range.duration)) : '-'}
              </Text>
            </ReportLine>
          )
        })
      )}
      {minBufferPartySize && buffer && (
        <ReportLine
          key={`${shift.id}-buffers`}
          name={
            <Text fontStyle="italic">{formatMessage(DurationsLocales.bufferTimeAfterReservation, { buffer, minBufferPartySize })}</Text>
          }
        >
          <></>
        </ReportLine>
      )}
    </>
  )
}
