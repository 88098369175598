import { useLocales } from '@sevenrooms/core/locales'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { BookingWindowLocales } from '../BookingWindow/BookingWindow.locales'
import { SharedTestId } from './shared.testIds'
import { AccessRuleTooltip } from '.'

export interface CutoffTimeWarningTooltipProps {
  hasBookingWindowWarning?: boolean
  hasBookingChannelsWarning?: boolean
}

export function CutoffTimeWarningTooltip({ hasBookingWindowWarning, hasBookingChannelsWarning }: CutoffTimeWarningTooltipProps) {
  const { formatMessage } = useLocales()

  return hasBookingWindowWarning || hasBookingChannelsWarning ? (
    <AccessRuleTooltip data-test={SharedTestId.cutoffTimeWarning} variant="warning">
      <Text color="lightFont">
        {hasBookingWindowWarning
          ? formatMessage(BookingWindowLocales.cutoffTimeBasicWarning)
          : formatMessage(BookingWindowLocales.cutoffTimeChannelsWarning)}
      </Text>
    </AccessRuleTooltip>
  ) : null
}
