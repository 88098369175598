import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { TreeNode } from '@sevenrooms/core/ui-kit/form'
import { AccessRuleSlideoutSection, useAccessRuleContext } from '../shared'
import { BookingChannelsLocales } from './BookingChannels.locales'
import { BookingChannelsTestId } from './BookingChannels.testIds'
import { DefaultBookingChannels, BookingChannelsFields, CollapsedBookingChannels } from './components'
import type { BookingChannelsForm } from './BookingChannels.zod'
import type { AudienceHierarchy } from '../../AccessRule.types'
import type { BookingWindowForm } from '../BookingWindow/BookingWindow.zod'

export interface BookingChannelsProps {
  field: Field<BookingChannelsForm>
  bookingWindowField: Field<BookingWindowForm>
  disabled?: boolean
}

export function BookingChannels({ field, bookingWindowField, disabled = false }: BookingChannelsProps) {
  const { formatMessage } = useLocales()
  const { accessRule, audienceHierarchy } = useAccessRuleContext()
  const audienceOptions = useMemo(() => getAudienceOptions(audienceHierarchy), [audienceHierarchy])
  const disableEdit = !!accessRule?.exclusiveAccessPerkId || disabled

  return (
    <AccessRuleSlideoutSection
      title={formatMessage(BookingChannelsLocales.title)}
      description={formatMessage(BookingChannelsLocales.description)}
      subCaption={formatMessage(BookingChannelsLocales.subCaption)}
      contentWhenCollapsed={<CollapsedBookingChannels field={field} bookingWindowField={bookingWindowField} />}
      defaultSettings={<DefaultBookingChannels />}
      sectionControllerName="bookingChannels"
      field={field}
      data-test={BookingChannelsTestId.section}
      disableEdit={disableEdit}
    >
      <BookingChannelsFields
        field={field}
        bookingWindowField={bookingWindowField}
        audienceOptions={audienceOptions}
        disableEdit={disableEdit}
      />
    </AccessRuleSlideoutSection>
  )
}

function getAudienceOptions(audienceHierarchy: AudienceHierarchy[] | null): TreeNode[] {
  return (
    audienceHierarchy?.map(({ name, value, children }) => ({
      id: value,
      value,
      label: name,
      children: getAudienceOptions(children),
      checked: false,
    })) ?? []
  )
}
