import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  employeeRefIdLabel: {
    id: 'posiSettings.SimphonyAutomaticCheckCreationCard.employeeRefIdLabel',
    defaultMessage: 'Employee Ref ID',
  },
  orderTypeRefIdLabel: {
    id: 'posiSettings.SimphonyAutomaticCheckCreationCard.orderTypeRefIdLabel',
    defaultMessage: 'Order Type',
  },
  tenderIdLabel: {
    id: 'posiSettings.SimphonyAutomaticCheckCreationCard.tenderIdLabel',
    defaultMessage: 'Tender Type',
  },
  checkCreationRvcLabel: {
    id: 'posiSettings.SimphonyAutomaticCheckCreationCard.checkCreationRvcLabel',
    defaultMessage: 'Revenue Center',
  },
  fetchTenderItemsError: {
    id: 'posiSettings.SimphonyAutomaticCheckCreationCard.fetchTenderItemsError',
    defaultMessage: 'Error fetching Tender Items',
  },
} as const)
