import { useLocales } from '@sevenrooms/core/locales'
import { useDestination } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { oneTimeSMSCenterMessages } from '../../locales'

export interface ConfirmArchiveOneTimeSMSModalProps {
  onConfirm: (campaignId: string) => void
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function ConfirmArchiveOneTimeSMSModal({ closeHref, onConfirm }: ConfirmArchiveOneTimeSMSModalProps) {
  const { formatMessage } = useLocales()
  const { params } = useDestination(routes.manager2.marketing.oneTimeSMSCenter.confirmArchiveModal)
  const campaignId = params?.campaignId
  const { confirmArchiveSMSModalTitle, confirmArchiveSMSModalInformation, confirmArchiveSMSModalHistoryInfo, cancelArchiveSMSModal } =
    oneTimeSMSCenterMessages

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle title={formatMessage(confirmArchiveSMSModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(confirmArchiveSMSModalInformation)} </Text>
        <Text>{formatMessage(confirmArchiveSMSModalHistoryInfo)} </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(cancelArchiveSMSModal)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm(campaignId)
            }}
            data-test="confirm-button"
          >
            {formatMessage(oneTimeSMSCenterMessages.confirmArchiveSMSModal)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
