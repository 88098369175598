import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  tabTitle: {
    id: 'posiSettings.EditPage.tabTitle',
    defaultMessage: 'Point of Sale',
  },
  title: {
    id: 'posiSettings.EditPage.title',
    defaultMessage: 'Edit Integration',
  },
  description: {
    id: 'posiSettings.EditPage.description',
    defaultMessage: 'View or edit your integration',
  },
  saveSuccess: {
    id: 'posiSettings.EditPage.saveSuccess',
    defaultMessage: 'Successfully saved {posiType} settings.',
  },
  saveError: {
    id: 'posiSettings.EditPage.saveError',
    defaultMessage: 'Error saving {posiType} settings.',
  },
} as const)
