import { useMemo } from 'react'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'

export type MessageForm = InferZodSchema<typeof useMessageForm>

export const useMessageForm = createZodSchema(z =>
  useMemo(
    () =>
      z.object({
        message: z.string(),
      }),
    [z]
  )
)

export type PoliciesAndVerbiageForm = InferZodSchema<typeof usePoliciesAndVerbiageForm>

export const usePoliciesAndVerbiageForm = createZodSchema(z => {
  const message = useMessageForm()
  return useMemo(
    () =>
      z.object({
        reservationRequest: z.string().nullable(),
        reservationSms: z.string().nullable(),
        waitlistAdditionSms: z.string().nullable(),
        waitlistTableReadySms: z.string().nullable(),
        reservationTableReadySms: z.string().nullable(),
        customSmsMessages: z.array(message),
        reservationFeedbackHeader: z.string().nullable(),
        reservationFeedbackText: z.string().nullable(),
        reservationFiveStar: z.string().nullable(),
        reservationFiveStarAdditional: z.string().nullable(),
        reservationFourToOne: z.string().nullable(),
        reservationFourToOneAdditional: z.string().nullable(),
        reservationRecommendation: z.string().nullable(),
        reservationFeedbackDescription: z.string().nullable(),
        guestlistHeader: z.string().nullable(),
        guestlistParagraph: z.string().nullable(),
        guestlistPolicy: z.string().nullable(),
        bookingPolicy: z.string().nullable(),
        cancellationPolicy: z.string().nullable(),
        customCheckoutPolicy: z.string().nullable(),
        waitlistPolicy: z.string().nullable(),
        groupMarketingPolicy: z.string().nullable(),
        specificMarketingPolicy: z.string().nullable(),
        specialAttentionHeader: z.string().nullable(),
        specialAttentionText: z.string().nullable(),
      }),
    [z, message]
  )
})
