import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { EventSelectorLocales } from './EventSelector.locales'

export interface UnlinkEventModalProps {
  onDiscard: () => void
  onSubmit: () => void
  eventName: string
}
export function UnlinkEventModal({ onDiscard, onSubmit, eventName }: UnlinkEventModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={() => onDiscard()}>
        <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
      </ModalHeader>
      <ModalBody>
        <ModalTitle title={formatMessage(EventSelectorLocales.unlinkTitle, { name: eventName })} />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onDiscard} data-test="sr-experience-unlink-event-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary-warning" onClick={onSubmit} data-test="sr-experience-unlink-event">
            {formatMessage(EventSelectorLocales.unlinkEvent)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
