import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { Box, Pair } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CoreLocales } from '@sevenrooms/mgr-core/core.locales'
import { Note } from '../../shared/Note'
import { MAX_PARTY_SIZE, MIN_PARTY_SIZE } from '../Constants'
import { DurationsLocales } from '../Durations.locales'
import {
  getDurationMinutesByPartySizeFromField,
  getDurationsForPartySizeRanges,
  type PartySizeRangeDuration,
  useBookablePartySizeRange,
} from '../utils'
import { MissingDurationErrorTooltip } from './MissingDurationErrorTooltip'
import type { PartySizeForm } from '../../PartySize/PartySize.zod'
import type { DurationsFieldProps } from '../DurationsFieldProps'

interface DurationsCollapsedProps extends DurationsFieldProps {
  partySizeField: Field<PartySizeForm>
}

export function DurationsCollapsed({ field, partySizeField }: DurationsCollapsedProps) {
  const { formatMessage } = useLocales()
  const data = useWatch(field)

  const { minPartySize, maxPartySize, partySizeIsRestricted } = useBookablePartySizeRange(partySizeField)

  const durationMinutesByPartySize = getDurationMinutesByPartySizeFromField(data)
  const durationsForPartySizeRanges = getDurationsForPartySizeRanges(
    MIN_PARTY_SIZE,
    MAX_PARTY_SIZE,
    minPartySize,
    maxPartySize,
    durationMinutesByPartySize
  )

  return (
    <Text fontSize="m">
      <Text fontWeight="bold">
        <Pair
          left={<Text fontWeight="bold">{formatMessage(DurationsLocales.headerPartySize)}</Text>}
          right={<Text fontWeight="bold">{formatMessage(DurationsLocales.headerPartyDuration)}</Text>}
        />
      </Text>
      <DurationsForPartySizeRanges durationsForPartySizeRanges={durationsForPartySizeRanges} />
      {partySizeIsRestricted && <Note>{formatMessage(DurationsLocales.partySizeNote)}</Note>}
    </Text>
  )
}

function DurationsForPartySizeRanges({ durationsForPartySizeRanges }: { durationsForPartySizeRanges: PartySizeRangeDuration[] }) {
  const { formatMessage } = useLocales()

  function getRightContents(enabled: boolean, duration?: number) {
    if (enabled && duration) {
      return formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(duration))
    } else if (enabled && !duration) {
      return <MissingDurationErrorTooltip />
    }

    return <Text color="deactivated">-</Text>
  }

  return (
    <>
      {durationsForPartySizeRanges.map(({ start, end, duration, enabled, plusGreaterThan }) => {
        const key =
          start === end
            ? formatMessage(DurationsLocales.partySizeRangeSingle, { partySize: start, plusGreaterThan })
            : formatMessage(DurationsLocales.partySizeRange, { start, end, plusGreaterThan })

        return (
          <Box key={key} mt="m">
            <Pair left={<Text color={!enabled ? 'deactivated' : undefined}>{key}</Text>} right={getRightContents(enabled, duration)} />
          </Box>
        )
      })}
    </>
  )
}
