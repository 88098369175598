import * as React from 'react'
import { DividerLine, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SMSCampaignPerformanceContext } from '@sevenrooms/marketing'
import { smsCenterMessages } from '../locales/smsCenterMessages'
import { SMSCampaignPerformance } from '../SMS/SMSCampaignPerformance'
import { OneTimeSMSCampaigns } from './OneTimeSMSCampaigns'

export function OneTimeSMS() {
  const [campaignPerformanceCollapsed, setCampaignPerformanceCollapsed] = React.useState<boolean>(true)

  return (
    <VStack backgroundColor="primaryBackground" borderColor="borders" borderRadius="s">
      <SMSCampaignPerformanceContext isOneTimeCampaignList>
        <SMSCampaignPerformance
          collapsed={campaignPerformanceCollapsed}
          onCollapsedChange={setCampaignPerformanceCollapsed}
          isOneTimeCampaignList
          messages={smsCenterMessages}
        />

        <DividerLine margin="none" />

        <OneTimeSMSCampaigns />
      </SMSCampaignPerformanceContext>
    </VStack>
  )
}
