import { useMemo } from 'react'
import type { Perk } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type PerkVisibilityFormData = ZodSchema<typeof usePerkVisibilityForm>
export type PerkVisibilityFormField = Field<PerkVisibilityFormData>

export const usePerkVisibilityForm = () =>
  useMemo(
    () =>
      z
        .object({
          externalName: z.string(),
          usingInternalName: z.boolean(),
          displayedExternally: z.boolean(),
        })
        .superRefine(
          (
            { externalName, usingInternalName, displayedExternally },

            ctx
          ) => {
            if (displayedExternally && !usingInternalName && !externalName) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                inclusive: true,
                minimum: 1,
                path: ['externalName'],
                type: 'string',
              })
            }
          }
        ),
    []
  )

export const getPerkVisibilityFormDefaultValue = (perk?: Perk): PerkVisibilityFormData => ({
  externalName: perk?.externalName || '',
  displayedExternally: perk?.displayedExternally || false,
  usingInternalName: perk?.usingInternalName === undefined ? true : Boolean(perk?.usingInternalName),
})
