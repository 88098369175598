import { DaysOfTheWeekEnum, type DaysOfTheWeek } from '@sevenrooms/core/domain'

export const numberToDayOfTheWeek: Record<number, DaysOfTheWeek> = {
  0: DaysOfTheWeekEnum.MONDAY,
  1: DaysOfTheWeekEnum.TUESDAY,
  2: DaysOfTheWeekEnum.WEDNESDAY,
  3: DaysOfTheWeekEnum.THURSDAY,
  4: DaysOfTheWeekEnum.FRIDAY,
  5: DaysOfTheWeekEnum.SATURDAY,
  6: DaysOfTheWeekEnum.SUNDAY,
}
