import { useCallback } from 'react'
import type { OneTimeEmailCampaignConfig } from '@sevenrooms/core/api'
import { MarketingCampaignOneTimeSendTypeEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, type ModalHeaderProps, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignBuilderMessages } from '@sevenrooms/marketing'
import { oneTimeEmailCenterMessages } from '@sevenrooms/mgr-marketing-one-time-email-center/locales'

export interface ConfirmSendEmailModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  recipientTagNames: string
  onCancel: () => void
  onConfirm: () => void
  campaignData: OneTimeEmailCampaignConfig
  disabled: boolean
}

export function ConfirmSendEmailModal({
  closeHref,
  recipientTagNames,
  onCancel,
  onConfirm,
  campaignData,
  disabled,
}: ConfirmSendEmailModalProps) {
  const { formatMessage } = useLocales()
  const history = useHistory()
  const isScheduledCampaign = campaignData?.oneTimeSendTimeConfig.sendType === MarketingCampaignOneTimeSendTypeEnum.SCHEDULED

  const confirmationText = isScheduledCampaign
    ? formatMessage(oneTimeEmailCenterMessages.confirmScheduleEmailText, { recipientTagNames })
    : formatMessage(oneTimeEmailCenterMessages.confirmSendEmailText, { recipientTagNames })

  const buttonText = isScheduledCampaign ? formatMessage(campaignBuilderMessages.schedule) : formatMessage(campaignBuilderMessages.send)

  const onCancelClick = useCallback(() => {
    onCancel()
    history.push(closeHref)
  }, [closeHref, history, onCancel])

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle title={formatMessage(oneTimeEmailCenterMessages.confirmSendEmailTitle)} />
      </ModalHeader>
      <ModalBody>
        <Text>{confirmationText}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button onClick={onCancelClick} variant="secondary" data-test="send-email-modal-cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>

          <Button data-test="send-email-modal-confirm-button" onClick={onConfirm} disabled={disabled}>
            {buttonText}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
