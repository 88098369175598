import type { AccessRuleTimeUnitType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { BookingWindowLocales } from '../BookingWindow/BookingWindow.locales'

export interface FormattedTimeBeforeProps {
  count: number | null
  unit: AccessRuleTimeUnitType | null
  beforeTime: string | null
}

/**
 * Formats the input from a TimeBefore component into a nicely readable string
 * @param {FormattedTimeBeforeProps} props Values from a TimeBeforeForm
 * @returns {ReactElement}
 */
export function FormattedTimeBefore({ count, unit, beforeTime }: FormattedTimeBeforeProps) {
  const { formatMessage } = useLocales()

  return (
    <Text>
      {count
        ? formatMessage(BookingWindowLocales.timeBefore, { count, unit, before: TimeOnly.fromSafe(beforeTime ?? '')?.formatSTime() ?? '0' })
        : formatMessage(BookingWindowLocales.upToReservationTime)}
    </Text>
  )
}
