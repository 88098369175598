import React, { useRef } from 'react'
import { accessRuleApi } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { accessRuleBulkEditLocales } from './AccessRuleBulkEditLocales'
import { ArSpreadsheetContainer } from './ARSpreadsheetContainer'
import { fromSpreadsheet, getSheetConfig } from './spreadsheetHelpers'
import type { WorkbookInstance } from '@fortune-sheet/react'

export function AccessRuleBulkEdit() {
  const { formatMessage } = useLocales()
  const spreadsheetRef = useRef<WorkbookInstance>(null)

  const { venueId } = useVenueContext()
  const { data: inputData = [], isFetching, refetch } = accessRuleApi.useGetAccessRulesListQuery({ venueId })
  const [bulkEditAccessRules, { isLoading: isSubmitting }] = accessRuleApi.useBulkEditAccessRulesMutation()

  const handleSubmit = () => {
    const output = fromSpreadsheet(spreadsheetRef.current?.getAllSheets() ?? [])
    if (output.length) {
      bulkEditAccessRules({ venueId, data: output })
        .then(response => {
          if ('error' in response) {
            notify({ content: 'Save failed, verify all the input are valid.', type: 'error' })
          }
          refetch()
        })
        .catch(error => {
          notify({ content: error, type: 'error' })
        })
    }
  }

  return (
    <>
      <SettingsPageMeta title={formatMessage(accessRuleBulkEditLocales.title)} />
      <SettingsPageContent
        title={formatMessage(accessRuleBulkEditLocales.title)}
        actions={
          <HStack spacing="s" justifyContent="right">
            <Button variant="secondary" onClick={() => refetch()} data-test="button-reload-data">
              {formatMessage(accessRuleBulkEditLocales.reloadData)}
            </Button>
            <Button onClick={() => handleSubmit()} data-test="button-save-changes">
              {formatMessage(commonMessages.saveChanges)}
            </Button>
          </HStack>
        }
      >
        {isFetching || isSubmitting ? (
          <Loader />
        ) : (
          <ArSpreadsheetContainer spreadsheetRef={spreadsheetRef} data={getSheetConfig(inputData)} />
        )}
      </SettingsPageContent>
    </>
  )
}
