import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { GuestFacingLocales } from '../GuestFacing.locales'
import type { PaymentPolicyForm } from '../../PaymentPolicy/PaymentPolicy.zod'

export function useUpgradesSummary(bundledUpgrades: PaymentPolicyForm['bundledUpgrades']) {
  const { formatMessage } = useLocales()

  const perPerson = bundledUpgrades
    .filter(bundle => bundle.quantityType === 'PARTY_SIZE')
    .flatMap(bundle => bundle.upgrades)
    .map(upgrade => `1x ${upgrade.label}`)
    .join('\n')

  const perReservation = bundledUpgrades
    .filter(bundle => bundle.quantityType === 'SPECIFIC_NUMBER')
    .flatMap(bundle => bundle.upgrades.map(upgrade => [bundle.quantity, upgrade.label] as const))
    .map(([quantity, upgrade]) => `${quantity}x ${upgrade}`)
    .join('\n')

  return [
    perPerson ? `${formatMessage(GuestFacingLocales.perPerson)}\n${perPerson}` : null,
    perReservation ? `${formatMessage(GuestFacingLocales.perReservation)}\n${perReservation}` : null,
  ]
    .filter(s => s)
    .join('\n\n')
}

export function useUpgradesSummaryFromField(field: Field<PaymentPolicyForm['bundledUpgrades']>) {
  const bundledUpgrades = useWatch(field)
  return useUpgradesSummary(bundledUpgrades)
}
