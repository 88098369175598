import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { scroller } from 'react-scroll'
import styled from 'styled-components'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import Theme, { standardGutter } from 'mgr/layout/Theme'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import {
  TagDescriptions,
  AutotagConfigType,
  AutotagTimeFrame,
  AutotagConfigAttributes,
  TRAILING_NUMBER_OF_MONTHS,
} from 'mgr/pages/shared/utils/Constants'
import {
  openAutotag,
  selectTier,
  updateTier,
  updateTierAttribute,
  addTier,
  toggleTagActive,
  trySaveAutotag,
  toggleDeleteWarning,
  removeTier,
  setTierError,
  removeInitialTier,
  updateLockedTierAttributes,
  updateDeactivateFunction,
  toggleDeactivateWarning,
  updateAutotagConfig,
  updatePerksByTag,
  updateNotesByTag,
} from 'mgr/pages/single-venue/marketing/actions/Autotags'
import AnniversaryTier from 'mgr/pages/single-venue/marketing/components/auto-tags/AnniversaryTier'
import {
  Content,
  Heading,
  SubHead,
  Title,
  Description,
  TextIndicator,
  Tiers,
  AddTier,
  LastUpdated,
} from 'mgr/pages/single-venue/marketing/components/auto-tags/AutoTagSlideout'
import { dropProps, dropStyle } from 'mgr/pages/single-venue/marketing/components/auto-tags/BaseTier'
import BirthdayTier from 'mgr/pages/single-venue/marketing/components/auto-tags/BirthdayTier'
import BookedByThirdPartyTier from 'mgr/pages/single-venue/marketing/components/auto-tags/BookedByThirdPartyTier'
import FeedbackTier from 'mgr/pages/single-venue/marketing/components/auto-tags/FeedbackTier'
import FirstTimerTier from 'mgr/pages/single-venue/marketing/components/auto-tags/FirstTimerTier'
import MarketingTier from 'mgr/pages/single-venue/marketing/components/auto-tags/MarketingTier'
import MissedVisitsTier from 'mgr/pages/single-venue/marketing/components/auto-tags/MissedVisitsTier'
import ReferrerTier from 'mgr/pages/single-venue/marketing/components/auto-tags/ReferrerTier'
import ReservationRequestsTier from 'mgr/pages/single-venue/marketing/components/auto-tags/ReservationRequestsTier'
import SpendTier from 'mgr/pages/single-venue/marketing/components/auto-tags/SpendTier'
import UpcomingReservationsTier from 'mgr/pages/single-venue/marketing/components/auto-tags/UpcomingReservationsTier'
import VisitsMarketingSegmentationTier from 'mgr/pages/single-venue/marketing/components/auto-tags/VisitsMarketingSegmentationTier'
import VisitsTier from 'mgr/pages/single-venue/marketing/components/auto-tags/VisitsTier'
import WeMissYouTier from 'mgr/pages/single-venue/marketing/components/auto-tags/WeMissYouTier'
import { DisplayArea, ActionArea, LinkButton } from 'mgr/pages/single-venue/marketing/components/MarketingWrap'
import { VmsIcons, StyledVmsIconSH } from 'svr/common/VmsIcons'
import { Radio } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import AutotagSlideout from './AutotagSlideout'

const trailingNumberOfMonthsChoices = Array.from({ length: 12 }, (_, i) => ({ name: String((i + 1) * 3), value: (i + 1) * 3 }))

class ShowAutotag extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      timeFrameChoice: AutotagTimeFrame.WHOLE_DINING_HISTORY,
      trailingNumberOfMonths: TRAILING_NUMBER_OF_MONTHS,
    }
  }

  componentDidMount() {
    window.document.body.addEventListener('keydown', event => {
      if (event.keyCode === 27) {
        this.props.openAutotag(null)
      }
    })
  }

  componentDidUpdate(prevProps) {
    const editing = this.props.initialTier
    const position = editing > 1 ? `tier-${this.props.initialTier}` : 'tagSlideoutHeader'
    if (editing !== null) {
      scroller.scrollTo(position, {
        containerId: 'displayArea',
        offset: -standardGutter,
      })
      this.props.removeInitialTier()
    }
    if (prevProps.tag?.time_frame !== this.props.tag?.time_frame || prevProps.tag?.trailing_months !== this.props.tag?.trailing_months) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        timeFrameChoice: this.props.tag?.time_frame || AutotagTimeFrame.WHOLE_DINING_HISTORY,
        trailingNumberOfMonths: this.props.tag?.trailing_months || TRAILING_NUMBER_OF_MONTHS,
      })
    }
    if (prevProps.tag?.id !== this.props.tag?.id) {
      this.initialEditableValues = _.cloneDeep({
        tag: this.props.tag,
        perksByTagName: this.props.perksByTagName,
        notesByTagName: this.props.notesByTagName,
      })
    }
  }

  getTierContainer(type) {
    switch (type) {
      case AutotagConfigType.SPEND_LOCAL:
      case AutotagConfigType.SPEND_GLOBAL:
        return SpendTier
      case AutotagConfigType.VISITS_LOCAL:
      case AutotagConfigType.VISITS_GLOBAL:
        return VisitsTier
      case AutotagConfigType.REVIEWER:
        return FeedbackTier
      case AutotagConfigType.FIRST_TIMER:
      case AutotagConfigType.FIRST_TIMER_GLOBAL:
        return FirstTimerTier
      case AutotagConfigType.WE_MISS_YOU:
      case AutotagConfigType.WE_MISS_YOU_GLOBAL:
        return WeMissYouTier
      case AutotagConfigType.BIRTHDAY_LOCAL:
      case AutotagConfigType.BIRTHDAY:
        return BirthdayTier
      case AutotagConfigType.ANNIVERSARY_LOCAL:
      case AutotagConfigType.ANNIVERSARY:
        return AnniversaryTier
      case AutotagConfigType.ALL_GUESTS:
      case AutotagConfigType.SUBSCRIBED_GUESTS:
      case AutotagConfigType.ALL_GUESTS_GLOBAL:
      case AutotagConfigType.MARKETING_OPT_INS_GLOBAL:
        return MarketingTier
      case AutotagConfigType.BOOKED_BY_THIRD_PARTY:
        return BookedByThirdPartyTier
      case AutotagConfigType.MISSED_VISITS:
        return MissedVisitsTier
      case AutotagConfigType.UPCOMING_RESERVATIONS:
        return UpcomingReservationsTier
      case AutotagConfigType.REFERRER:
        return ReferrerTier
      case AutotagConfigType.RESERVATION_REQUESTS:
        return ReservationRequestsTier
      case AutotagConfigType.VISITS_LOCAL_MARKETING_SEGMENTATION:
        return VisitsMarketingSegmentationTier
      default:
        return null
    }
  }

  saveAutotag = (is_active, venue, editingTier, perksByTagName, notesByTagName) => {
    const { associatedCampaignsAndPerks } = this.props

    if (!is_active && (associatedCampaignsAndPerks.data.campaigns.length > 0 || associatedCampaignsAndPerks.data.perks.length > 0)) {
      this.props.toggleDeactivateWarning()
      this.props.updateDeactivateFunction(() => {
        this.props.trySaveAutotag(venue, this.props.tag, editingTier, perksByTagName, notesByTagName)
      })
    } else {
      this.props.trySaveAutotag(venue, this.props.tag, editingTier, perksByTagName, notesByTagName)
    }
  }

  render() {
    const {
      tag,
      venue,
      saving,
      editingTier,
      tierError,
      selectTier,
      updateTier,
      updateTierAttribute,
      updateLockedTierAttributes,
      updatePerksByTag,
      updateNotesByTag,
      addTier,
      toggleTagActive,
      toggleDeleteWarning,
      removeTier,
      setTierError,
      autotagPerks,
      perksByTagName,
      notesByTagName,
    } = this.props

    if (!tag || tag.is_config_type_custom) {
      return null
    }

    const { is_multi_tier_type, name_display, config_type, is_global, is_active, tier_configs, updated, updated_by_user_name } = tag

    const editableValues = {
      tag,
      perksByTagName,
      notesByTagName,
    }
    const hasNoChanges = _.isEqual(this.initialEditableValues, editableValues)
    const TierContainer = this.getTierContainer(config_type)
    const lastUpdated =
      venue.locale === 'en_US'
        ? moment.utc(updated).local().format('MMM D, YYYY h:mmA')
        : moment.utc(updated).local().format('MMM D, YYYY HH:mm')

    return (
      <AutotagSlideout title="Edit">
        <Content>
          <DisplayArea id="displayArea">
            <Heading id="tagSlideoutHeader">
              <SubHead>
                <StyledVmsIconSH>{is_global ? VmsIcons.SafariLine : VmsIcons.LocationLine}</StyledVmsIconSH>
                <Title>{name_display}</Title>
              </SubHead>
              {config_type === AutotagConfigType.REFERRER && venue.groupReferralProgramEnabled ? (
                <SubHead>
                  <StyledVmsIconSH style={{ color: Theme.color.grey }}>
                    {is_active ? VmsIcons.ToggleOn : VmsIcons.ToggleOff}
                  </StyledVmsIconSH>
                  <TextIndicator on={is_active} />
                </SubHead>
              ) : (
                <SubHead onClick={toggleTagActive} style={{ cursor: 'pointer' }}>
                  <StyledVmsIconSH
                    style={{
                      color: is_active ? Theme.color.teal : Theme.color.grey,
                    }}
                  >
                    {is_active ? VmsIcons.ToggleOn : VmsIcons.ToggleOff}
                  </StyledVmsIconSH>
                  <TextIndicator on={is_active} />
                </SubHead>
              )}
            </Heading>
            <LastUpdated>
              Last updated {lastUpdated} {updated_by_user_name ? `by ${updated_by_user_name}` : ''}
            </LastUpdated>
            <Description dangerouslySetInnerHTML={{ __html: TagDescriptions[config_type] }} />

            {config_type === AutotagConfigType.SPEND_GLOBAL && (
              <Box pt="lm" pb="lm" pl="lm" pr="lm">
                <Text>
                  Select a custom time frame to collect your guests&#39; total spend and visit data (this applies to all Auto-tags in this
                  category):
                </Text>
                <VStack pt="s" spacing="s">
                  <Radio
                    checked={this.state.timeFrameChoice === AutotagTimeFrame.WHOLE_DINING_HISTORY}
                    onChange={() => {
                      this.setState({ timeFrameChoice: AutotagTimeFrame.WHOLE_DINING_HISTORY })
                      this.props.updateAutotagConfig(AutotagConfigAttributes.TIME_FRAME, AutotagTimeFrame.WHOLE_DINING_HISTORY)
                    }}
                    description="There is no cut off time frame for guests' spend and visit data."
                  >
                    Whole dining history
                  </Radio>
                  <Radio
                    checked={this.state.timeFrameChoice === AutotagTimeFrame.TRAILING_NUMBER_OF_MONTHS}
                    onChange={() => {
                      this.setState({ timeFrameChoice: AutotagTimeFrame.TRAILING_NUMBER_OF_MONTHS })
                      this.props.updateAutotagConfig(AutotagConfigAttributes.TIME_FRAME, AutotagTimeFrame.TRAILING_NUMBER_OF_MONTHS)
                      this.props.updateAutotagConfig(AutotagConfigAttributes.TRAILING_MONTHS, this.state.trailingNumberOfMonths)
                    }}
                    description="Guests' spend and visit data will be collected in the past number of consecutive months."
                  >
                    Trailing number of months
                  </Radio>
                  {this.state.timeFrameChoice === AutotagTimeFrame.TRAILING_NUMBER_OF_MONTHS && (
                    <HStack alignItems="center" pl="lm">
                      <DropdownArrowsPicker
                        {...dropProps}
                        choices={trailingNumberOfMonthsChoices}
                        onChangeHandler={value => {
                          this.setState({ trailingNumberOfMonths: value })
                          this.props.updateAutotagConfig(AutotagConfigAttributes.TRAILING_MONTHS, value)
                        }}
                        style={{ ...dropStyle, width: '80px', minWidth: '80px' }}
                        optionsContainerStyle={{ maxHeight: 200 }}
                        value={this.state.trailingNumberOfMonths}
                      />
                      <Text> month(s)</Text>
                    </HStack>
                  )}
                  <Radio
                    checked={this.state.timeFrameChoice === AutotagTimeFrame.CALENDAR_YEAR}
                    onChange={() => {
                      this.setState({ timeFrameChoice: AutotagTimeFrame.CALENDAR_YEAR })
                      this.props.updateAutotagConfig(AutotagConfigAttributes.TIME_FRAME, AutotagTimeFrame.CALENDAR_YEAR)
                    }}
                    description="The stats will reset every January 1st."
                  >
                    Calendar year (January 1st to December 31st)
                  </Radio>
                </VStack>
              </Box>
            )}
            <Tiers>
              {tier_configs.map((tier, index, { length }) => {
                const removeable = is_multi_tier_type && index === length - 1 && index !== 0 && config_type !== AutotagConfigType.REFERRER
                const editing = editingTier === index
                const error = tierError === index
                const id = `tier-${index}`

                return (
                  <TierContainer
                    id={id}
                    key={id}
                    index={index}
                    tier={tier}
                    siblingTiers={tier_configs}
                    configType={config_type}
                    removeable={removeable}
                    selectTier={selectTier}
                    editing={editing}
                    currencyCode={venue.currencyCode}
                    updateTier={updateTier}
                    updateTierAttribute={updateTierAttribute}
                    updateLockedTierAttributes={updateLockedTierAttributes}
                    updatePerksByTag={updatePerksByTag}
                    updateNotesByTag={updateNotesByTag}
                    toggleDeleteWarning={toggleDeleteWarning}
                    removeTier={removeTier}
                    setTierError={setTierError}
                    isActive={is_active}
                    error={error}
                    autotagPerks={autotagPerks}
                    timeFrame={this.state.timeFrameChoice}
                    trailingMonths={this.state.trailingNumberOfMonths}
                    perks={perksByTagName[tier.tier_tag_name]}
                    notes={notesByTagName[tier.tier_tag_name]}
                    venueProductProvisionPackage={venue.productProvisionPackage}
                  />
                )
              })}
              {
                // TODO: this will have different handling for different tags
                is_multi_tier_type &&
                tier_configs.length < 5 &&
                is_active &&
                config_type !== AutotagConfigType.REFERRER &&
                config_type !== AutotagConfigType.VISITS_LOCAL_MARKETING_SEGMENTATION ? (
                  <AddTier onClick={addTier}>Add tier</AddTier>
                ) : null
              }
            </Tiers>
          </DisplayArea>
          <ActionArea>
            <LinkButton
              disabled={hasNoChanges}
              onClick={() => {
                if (saving) {
                  return
                }
                this.saveAutotag(is_active, venue, editingTier, perksByTagName, notesByTagName)
              }}
            >
              {saving ? <PulsatingDots /> : 'Apply changes'}
            </LinkButton>
          </ActionArea>
        </Content>
      </AutotagSlideout>
    )
  }
}

const mapStateToProps = state => {
  const editState = state.autotags.editor
  return {
    venue: state.appState.venue,
    tag: editState.tag,
    editingTier: editState.editingTier,
    initialTier: editState.initialTier,
    tierError: editState.tierError,
    saving: editState.saving,
    associatedCampaigns: editState.associatedCampaigns,
    autotagPerks: state.autotagPerks,
    associatedCampaignsAndPerks: editState.associatedCampaignsAndPerks,
    perksByTagName: editState.perksByTagName,
    notesByTagName: editState.notesByTagName,
  }
}

const mapDispatchToProps = {
  openAutotag,
  selectTier,
  updateTier,
  updateTierAttribute,
  addTier,
  toggleTagActive,
  trySaveAutotag,
  toggleDeleteWarning,
  removeTier,
  setTierError,
  removeInitialTier,
  updateLockedTierAttributes,
  updateDeactivateFunction,
  toggleDeactivateWarning,
  updateAutotagConfig,
  updatePerksByTag,
  updateNotesByTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowAutotag)
