import _ from 'lodash'
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider, type DefaultTheme } from 'styled-components'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { Box, Grid, HStack } from '@sevenrooms/core/ui-kit/layout'
import { generateGX, gxBaseTheme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'

const FIRST_COLUMN_WIDTH = '220px'

const xsPadding = { pt: 's', pr: 'm', pb: 's', pl: 'm' } as const
const sPadding = { pt: 'sm', pr: 'm', pb: 'sm', pl: 'm' } as const
const mPadding = { pt: 'm', pr: 'm', pb: 'm', pl: 'm' } as const

export const buttonOptions = [
  { id: 1, size: 'xs', textColor: 'lightFont', variant: 'primary', iconSize: 'sm', padding: { ...xsPadding } },
  { id: 2, size: 'xs', textColor: 'brandColor', variant: 'secondary', iconSize: 'sm', padding: { ...xsPadding } },
  { id: 3, size: 's', textColor: 'lightFont', variant: 'primary', iconSize: 'lg', padding: { ...sPadding } },
  { id: 4, size: 's', textColor: 'brandColor', variant: 'secondary', iconSize: 'lg', padding: { ...sPadding } },
  { id: 5, size: 'm', textColor: 'lightFont', variant: 'primary', iconSize: 'lg', padding: { ...mPadding } },
  { id: 6, size: 'm', textColor: 'brandColor', variant: 'secondary', iconSize: 'lg', padding: { ...mPadding } },
] as const

interface ButtonPickerProps {
  color: string
  text: string
  field: Field<number>
  label: string
}

export function ButtonPicker({ color, text, field, label }: ButtonPickerProps) {
  const controller = useController(field)
  const selectedId = useWatch(field)
  const vxTheme = useTheme()
  const mergedTheme = generateGX(
    _.merge(gxBaseTheme, {
      colors: {
        brandColor: color,
        borderFocus: vxTheme.colors.borderFocus,
        secondaryFont: color,
        primaryIcons: color,
      },
      borderRadius: {
        ...vxTheme.borderRadius,
      },
    })
  )

  return (
    <ThemeProvider theme={mergedTheme as unknown as DefaultTheme}>
      <Box>
        <Label primary={label} />
        <Grid gridTemplateColumns={`${FIRST_COLUMN_WIDTH} 1fr`} gap="s" justifyItems="left">
          {buttonOptions.map(({ id, size, textColor, variant, iconSize, padding }) => (
            <Box key={id} borderColor={selectedId === id ? 'borderFocus' : 'iconBackground'} borderRadius="s" borderWidth="m">
              <Button
                data-test={`button-picker-${id}`}
                noPadding
                size={size}
                variant={variant}
                onClick={() => controller.field.onChange(id)}
              >
                <HStack {...padding} spacing={size}>
                  <Box>
                    <Icon name={Icons['VMSWeb-7r-logo']} color={textColor} size={iconSize} />
                  </Box>
                  <Box>
                    <Text color={textColor} fontSize={size}>
                      {text}
                    </Text>
                  </Box>
                </HStack>
              </Button>
            </Box>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  )
}
