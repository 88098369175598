import { type ReactNode, useMemo, useState } from 'react'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, Form, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { perksMessages } from '../../locales'
import { PerkCreateAccessRuleModalAccessRules } from './PerkCreateAccessRuleModalAccessRules'
import {
  type PerkCreateAccessRuleModalFormData,
  getPerkCreateAccessRuleModalDefaultValues,
  usePerkCreateAccessRuleModalForm,
} from './PerkCreateAccessRuleModalForm.zod'
import { PerkCreateAccessRuleModalReleaseTables } from './PerkCreateAccessRuleModalReleaseTables'
import { PerkCreateAccessRuleModalSeatingAreas } from './PerkCreateAccessRuleModalSeatingAreas'

interface Step {
  title: string
  description?: ReactNode
  component: JSX.Element
  buttons: JSX.Element
}

interface PerkCreateAccessRuleModalProps {
  closeHref: string
  onSubmit: (perkAccessRuleModalFormData: PerkCreateAccessRuleModalFormData) => void
  onClose?: () => void
  accessRulesOptions: SelectOption[]
  seatingAreaCodesToTables: TreeNode<{ isTable: boolean }>[]
}

const formatItalic = {
  i: (chunks: string[]) => (
    <SecondaryText fontStyle="italic" fontWeight={700}>
      {chunks}
    </SecondaryText>
  ),
}

export function PerkCreateAccessRuleModal({
  closeHref,
  onClose,
  onSubmit,
  accessRulesOptions,
  seatingAreaCodesToTables,
}: PerkCreateAccessRuleModalProps) {
  const { formatMessage } = useLocales()
  const [step, setStep] = useState<number>(0)

  const defaultValues = useMemo(() => getPerkCreateAccessRuleModalDefaultValues(accessRulesOptions.length > 0), [accessRulesOptions.length])

  const form = useForm(usePerkCreateAccessRuleModalForm(), {
    defaultValues,
  })

  const { field, watch, getValues } = form
  const currentFormValues = watch()

  const steps: Step[] = useMemo(
    () => [
      {
        title: formatMessage(perksMessages.accessRuleModalSeatingAreasTitle),
        component: <PerkCreateAccessRuleModalSeatingAreas field={field} seatingAreaCodesToTables={seatingAreaCodesToTables} />,
        buttons: (
          <Button
            data-test="perk-create-access-rule-next-button"
            variant="primary"
            onClick={() => setStep(prevStep => prevStep + 1)}
            disabled={!currentFormValues.seatingAreasTables.length}
          >
            {formatMessage(commonMessages.next)}
          </Button>
        ),
      },
      {
        title: formatMessage(perksMessages.accessRuleModalReserveTablesTitle),
        description: formatMessage(perksMessages.accessRuleModalReserveTablesDescription),
        component: <PerkCreateAccessRuleModalReleaseTables field={field} />,
        buttons: (
          <>
            <Button data-test="perk-create-access-rule-back-button" variant="secondary" onClick={() => setStep(prevStep => prevStep - 1)}>
              {formatMessage(commonMessages.back)}
            </Button>
            <Button
              data-test="perk-create-access-rule-next-button"
              variant="primary"
              onClick={() => setStep(prevStep => prevStep + 1)}
              disabled={!!currentFormValues.enableReleaseTables && !currentFormValues.releaseTableTimeData.count}
            >
              {formatMessage(commonMessages.next)}
            </Button>
          </>
        ),
      },
      {
        title: formatMessage(perksMessages.accessRuleModalChooseAccessRuleTitle),
        description: (
          <Anchor href="https://help.sevenrooms.com/hc/en-us/sections/6983831130523-Access-Rules">
            {formatMessage(perksMessages.accessRuleModalChooseAccessRuleDescription)}
          </Anchor>
        ),
        component: <PerkCreateAccessRuleModalAccessRules field={field} accessRulesOptions={accessRulesOptions} />,
        buttons: (
          <>
            <Button data-test="perk-create-access-rule-back-button" variant="secondary" onClick={() => setStep(prevStep => prevStep - 1)}>
              {formatMessage(commonMessages.back)}
            </Button>
            <Button
              data-test="perk-create-access-rule-create-button"
              variant="primary"
              href={closeHref}
              onClick={() => {
                onSubmit(getValues())
              }}
              disabled={!!currentFormValues.cloneExistingAccessRule && !currentFormValues.accessRuleToCloneId}
            >
              {formatMessage(commonMessages.create)}
            </Button>
          </>
        ),
      },
    ],
    [
      formatMessage,
      field,
      seatingAreaCodesToTables,
      currentFormValues.seatingAreasTables.length,
      currentFormValues.enableReleaseTables,
      currentFormValues.releaseTableTimeData.count,
      currentFormValues.cloneExistingAccessRule,
      currentFormValues.accessRuleToCloneId,
      accessRulesOptions,
      closeHref,
      onSubmit,
      getValues,
    ]
  )

  const currentPage = steps[step]

  return (
    <Modal ariaLabel={formatMessage(perksMessages.createAccessRule)} minWidth="560px">
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <ModalHeader closeHref={closeHref} onClick={onClose}>
          <VStack spacing="s" mt="s">
            <SecondaryText fontWeight={700}>
              {formatMessage(perksMessages.accessRuleModalCreation, { ...formatItalic, step: step + 1, stepsCount: steps.length })}
            </SecondaryText>
            <ModalTitle title={currentPage?.title ?? ''} subTitle={currentPage?.description} />
          </VStack>
        </ModalHeader>
        <ModalBody>{currentPage?.component}</ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button data-test="perk-create-access-rule-cancel-button" variant="tertiary" href={closeHref} onClick={onClose}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            {currentPage?.buttons}
          </ModalActions>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
